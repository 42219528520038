import { environment } from '@env';
import { Route } from '@angular/router';
import json5 from 'json5';

export const APP_DEFAULT_URL = '/home/welcome';

export const APP_EMPTY_PATH_REDIRECT_TO_DEFAULT_ROUTE: Route = {
  path: '',
  redirectTo: APP_DEFAULT_URL,
  pathMatch: 'full',
};
export enum LenguageDateFormatType {
  DDMMYYYY = 'es-AR',
  MMDDYYYY = 'en-US',
}
let _cache_dateFormat = null;
export const getDateFormat = () => {
  const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';
  if (!_cache_dateFormat) _cache_dateFormat = localStorage.getItem(_keyVer + 'dateFormat') || LenguageDateFormatType.DDMMYYYY;
  return _cache_dateFormat;
};
let _cache_PermissionName = null;
export const getShowPermissionName = () => {
  const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';
  if (!_cache_PermissionName) _cache_PermissionName = localStorage.getItem(_keyVer + 'showPermissionName') || false;
  return json5.parse(_cache_PermissionName);
};
