import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'quick-panel',
  templateUrl: './quick-panel.component.html',
  styleUrls: ['./quick-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuickPanelComponent {
  name: string;
  events: Array<any>;
  notes: Array<any>;
  settings: any;
  speech: SpeechSynthesisUtterance;
  recognition: any;
  voices: Array<any>;
  isRecording: boolean;

  messages: Array<any> = [];
  userData: any;
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   */
  constructor(
    private _fuseSidebarService: FuseSidebarService,
    private oidcSecurityService: OidcSecurityService,
    private http: HttpClient,
    private cd: ChangeDetectorRef
  ) {
    // Set the defaults
    // this.date = new Date().toLocaleDateString();
    this.name = 'Prisma Assistant';
    this.isRecording = false;
    this.settings = {
      notify: true,
      cloud: false,
      retro: true,
    };

    this.messages = [];

    this._unsubscribeAll = new Subject();

    this.oidcSecurityService
      .getUserData()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(userData => {
        this.userData = userData;
      });

    this.initializeRecognitionAPI();
  }

  recordCommand() {
    console.log(this.recognition);
    this.isRecording = true;
    this.recognition.start();
  }

  stopRecording() {
    this.recognition.stop();
    this.isRecording = false;
  }

  abortRecording() {
    this.recognition.abort();
    this.isRecording = false;
  }

  initializeRecognitionAPI() {
    let that = this;
    let w: any = window;

    try {
      this.recognition = new w.webkitSpeechRecognition();
    } catch (e) {
      return;
    }

    this.recognition.lang = 'es-ES';
    this.recognition.continuous = false;
    this.recognition.interimResults = false;

    this.recognition.onresult = function (event) {
      let results = event.results;
      that.abortRecording();
      if (results) {
        let phrase = results[0][0].transcript;
        that.runCommand(phrase);
      }
    };

    this.recognition.onspeechend = function () {
      that.stopRecording();
    };

    this.recognition.onnomatch = function (event) {
      console.log('No reconoci el comando');
    };

    this.recognition.onerror = function (event) {
      that.stopRecording();
      console.log('Ocurrio un error durante el reconociento de voz: ' + event.error);
    };

    this.speech = new SpeechSynthesisUtterance();
    window.speechSynthesis.onvoiceschanged = event => {
      this.voices = window.speechSynthesis.getVoices().filter(x => x.lang.indexOf('es') > -1);
      if (false) console.log(this.voices);

      this.speech.lang = 'es-ES';
      this.speech.volume = 1;
    };
  }

  say(text) {
    this.messages.push({
      title: text,
      detail: `Prisma Assistant`,
    });

    this.cd.detectChanges();

    // let speech = new SpeechSynthesisUtterance();
    this.speech.text = text;
    this.speech.voice = this.voices[3]; //Hay del 0 al 8 voces en español
    this.speech.rate = 0.8;

    window.speechSynthesis.speak(this.speech);
  }

  runCommand(voiceText) {
    this.messages.push({
      title: voiceText,
      detail: this.userData.preferred_username || 'You',
    });

    this.cd.detectChanges();

    voiceText = voiceText.toLowerCase();

    if (voiceText.indexOf('buscar') > -1) {
      let searchTerm = voiceText.replace('buscar ', '').replace('en google', '');
      window.open('https://www.google.com/search?q=' + searchTerm, '_blank').focus();

      this.say(`Claro! Buscando ${searchTerm} en Google...`);
    } else if (voiceText.indexOf('reproducir') > -1) {
      let searchTerm = voiceText.replace('reproducir ', '').replace('en youtube', '');
      window.open('https://www.youtube.com/results?search_query=' + searchTerm, '_blank').focus();

      this.say(`Claro! Reproduciendo ${searchTerm} en YouTube...`);
    } else if (voiceText.indexOf('ubicación') > -1) {
      let s = voiceText;
      let searchTerm = s.substring(s.indexOf('de') + 3).trim();

      window.open(`https://www.google.com.ar/maps/search/${searchTerm}`, '_blank').focus();
      this.say(`Claro! Buscando ${searchTerm} en Google Maps`);
    } else if (voiceText.indexOf('jumbo') > -1) {
      let s = voiceText;
      let itemName = '';
      if (s.indexOf('precio de la') > -1) itemName = s.substring(s.indexOf('precio de la') + 12, s.indexOf('en')).trim();
      else itemName = s.substring(s.indexOf('precio del') + 10, s.indexOf('en')).trim();

      let storeName = s.substring(s.indexOf('en') + 3).trim();

      window.open(`https://www.jumbo.com.ar/busca/?ft=${itemName}`, '_blank').focus();
      this.say(`Claro! Buscando el precio de ${itemName} en ${storeName}`);
    } else if (voiceText.indexOf('como') > -1 || voiceText.indexOf('cómo') > -1) {
      let s = voiceText;
      let searchTerm = s.substring(s.indexOf('como') + 5).trim();

      window.open(`https://prismaretail.atlassian.net/wiki/search?text=/${searchTerm}`, '_blank').focus();
      this.say(`Eso puedes buscarlo en la documentación de Prisma. Te llevo!`);
    } else if (voiceText.indexOf('hora') > -1) {
      let date = new Date();
      this.say(`Son las ${date.toTimeString()}`);
    } else if (voiceText.indexOf('fecha') > -1 || voiceText.indexOf('que dia') > -1) {
      let date = new Date();
      this.say(`Hoy es ${date.toDateString()}`);
    } else if (voiceText.indexOf('mejor categoria') > -1 || voiceText.indexOf('mejor categoría') > -1) {
      this.http.get('http://localhost:5201/api/assistant/get-best-category-by-sells').subscribe(result => {
        let data: any = result;
        if (data) {
          this.say(`${data.categoryName} fue la categoria con mas ventas del mes. Con un total de ${data.sales} pesos`);
        }
      });
    } else if (voiceText.indexOf('peor categoria') > -1 || voiceText.indexOf('peor categoría') > -1) {
      this.http.get('http://localhost:5201/api/assistant/get-worst-category-by-sells').subscribe(result => {
        let data: any = result;
        if (data) {
          this.say(`${data.categoryName} fue la categoria con menos ventas del mes. Con un total de ${data.sales} pesos`);
        }
      });
    } else if (voiceText.indexOf('categoria con mas ventas') > -1 || voiceText.indexOf('categoría con más ventas') > -1) {
      this.http.get('http://localhost:5201/api/assistant/get-best-category-by-sells').subscribe(result => {
        let data: any = result;
        if (data) {
          this.say(`${data.categoryName} fue la categoria con mas ventas del mes. Con un total de ${data.sales}`);
        }
      });
    } else if (voiceText.indexOf('categoria con mas unidades') > -1 || voiceText.indexOf('categoría con más unidades') > -1) {
      this.http.get('http://localhost:5201/api/assistant/get-best-category-by-units').subscribe(result => {
        let data: any = result;
        if (data) {
          this.say(`${data.categoryName} fue la categoria con mas unidades del mes. Con un total de ${data.units} unidades`);
        }
      });
    } else if (voiceText.indexOf('precio del ítem') > -1 && voiceText.indexOf('en la tienda')) {
      let s = voiceText;
      let itemName = s.substring(s.indexOf('ítem') + 5, s.indexOf('en')).trim();
      let storeName = s.substring(s.indexOf('en la tienda') + 12).trim();

      this.http
        .get(`http://localhost:5201/api/assistant/get-item-price-in-store?itemName=${itemName}&storeName=${storeName}`)
        .subscribe(result => {
          let data: any = result;
          if (data) {
            console.log(data);
            this.say(`El precio de ${data[0].itemName} es ${data[0].price} en punto de venta ${data[0].storeName}`);
          }
        });
    } else if (voiceText.indexOf('precio de la') > -1 && voiceText.indexOf('en') > -1) {
      let s = voiceText;
      let itemName = s.substring(s.indexOf('precio de la') + 12, s.indexOf('en')).trim();
      let storeName = s.substring(s.indexOf('en') + 3).trim();

      this.http
        .get(`http://localhost:5201/api/assistant/get-item-price-in-store?itemName=${itemName}&storeName=${storeName}`)
        .subscribe(result => {
          let data: any = result;
          if (data) {
            console.log(data);
            this.say(`El precio de ${data[0].itemName} es $ ${data[0].price} en punto de venta ${data[0].storeName}`);
          }
        });
    } else if (voiceText.indexOf('precio del') > -1 && voiceText.indexOf('en') > -1) {
      let s = voiceText;
      let itemName = s.substring(s.indexOf('precio del') + 12, s.indexOf('en')).trim();
      let storeName = s.substring(s.indexOf('en') + 3).trim();

      this.http
        .get(`http://localhost:5201/api/assistant/get-item-price-in-store?itemName=${itemName}&storeName=${storeName}`)
        .subscribe(result => {
          let data: any = result;
          if (data) {
            console.log(data);
            this.say(`El precio de ${data[0].itemName} es $ ${data[0].price} en punto de venta ${data[0].storeName}`);
          }
        });
    } else {
      this.say('Disculpa! No te he entendido.');
    }
  }

  closeQuickPanel(): void {
    if (true) {
      setTimeout(() => {
        this._fuseSidebarService.getSidebar('quickPanel') && this._fuseSidebarService.getSidebar('quickPanel').close();
      });
    }
  }
}
