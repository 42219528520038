<mat-form-field
  [id]="rootId"
  class="form-filter generic-lookup"
  [ngClass]="rootCustomClass + (disabled ? ' _disabled' : '')"
>
  <span
    class="mat-slectdummy-label-wrapp"
    [ngClass]="readOnly ? 'mat-slectdummy-label-wrapp-readOnly' : ''"
    [attr.data-test-id]="__dataTestId ? __dataTestId : dataTestId ? (dataTestId + '-lookup-wrapp') : 'generic-lookup-wrapp'"
  >
    <input
      matInput
      [placeholder]="placeholder"
      [ngModel]="valuesAndSelectedTextStr"
      type="text"
      class="mat-slectdummy-label"
      [required]="required"
      *ngIf="true"
      (focus)="onSlectdummyFocus($event)"
      [attr.data-test-id]="__dataTestId ? __dataTestId : dataTestId ? (dataTestId + '-input') : 'generic-lookup-text-input'"
      attr.data-test-id-placeholder="placeholder"
    />
    <mat-icon matSuffix class="generic-lookup-loader" *ngIf="showLoadWhenNoValue && !values">
      <mat-spinner [diameter]="20" color="accent" attr.data-test-id="generic-lookup-spinner"></mat-spinner>
    </mat-icon>
    <mat-icon
      *ngIf="canShowToolTipValues()"
      [matTooltip]="valuesAndSelectedTextStrTtip"
      matTooltipClass="generic-lookup-panel-ttip"
      matTooltipPosition="below"
      class="iconOnSelect iconOnSelectHelp"
      [attr.data-test-id]="__dataTestId ? __dataTestId : dataTestId ? (dataTestId + '-tooltip') : 'generic-lookup-tooltip'"
      [attr.data-test-selected-length]="valuesAndSelectedText.length"
    >
      error
    </mat-icon>
  </span>
  <!-- --- -->
  <mat-select
    #matSelect
    (openedChange)="openChange($event)"
    [tabindex]="tabindex"
    [multiple]="multiple"
    class="generic-lookup-mat-select"
    disableRipple="true"
    [disabled]="disabled || !(values && _Array.isArray(values))"
    [panelClass]="'generic-lookup-panel ' + panelClass + (panelClassCustom ? (' ' + panelClassCustom + ' ') : ' ') + (multiple ? 'generic-lookup-panel-multiple' : '')"
    [attr.data-test-id]="__dataTestId ? (__dataTestId+'-select') : dataTestId ? (dataTestId + '-select') : 'generic-lookup-select'"
    [attr.data-test-selected-obj]="valuesAndSelected | json"
    [attr.data-test-selected-length]="valuesAndSelected.length"
    [matTooltip]="showItemsToolTips && valuesAndSelectedTextStrTtip ? (valuesAndSelectedTextStrTtip || null) : null"
    [matTooltipShowDelay]="600"
    disableOptionCentering
  >
    <ng-container
      *ngIf="(!asyncFlag && values && _Array.isArray(values) && values.length && !(readOnly && selected?.length < 2) && optionHeight) || asyncFlag"
    >
      <div class="lookUpInput generic-lookup-lookUpInput" *ngIf="showLookup">
        <input
          type="text"
          [placeholder]="placeholderLookup"
          [value]="_lookupBuffer"
          #inputLookup
          [attr.data-test-id]="'generic-lookup-text-input-search'"
          (input)="!asyncFlag ? setLookUp($event.target.value) : null"
          (change)="asyncFlag ? setLookUp($event.target.value) : null"
          (keydown)="$event.stopPropagation();"
        />
        <div class="generic-lookup-optionbar" *ngIf="showOptionsBtns">
          <mat-icon
            *ngIf="asyncFlag"
            (click)="setLookUp(_lookupBuffer)"
            (keyup.enter)="setLookUp(_lookupBuffer)"
            class="generic-lookup-doSearch"
            [matTooltip]="('GLOBAL.Search'  | pTranslate)"
            [attr.data-test-id]="__dataTestId ? __dataTestId : dataTestId ? (dataTestId + 'do-search') : 'generic-lookup-toggle-select'"
            [attr.data-test-only-selected]="'do-search'"
          >
            search
          </mat-icon>
          <mat-icon
            *ngIf="asyncFlag"
            (click)="_lookupBuffer= ''; _lookup = ''; resetLookUp(true);"
            class="generic-lookup-deleteSearch"
            [matTooltip]="('GENERIC_CLEAN'  | pTranslate)"
            [ngClass]="{ disabled: !_lookupBuffer?.length && !valuesAndSelected?.length}"
            [attr.data-test-id]="__dataTestId ? __dataTestId : dataTestId ? (dataTestId + 'delete-search') : 'generic-lookup-toggle-select'"
            [attr.data-test-only-selected]="'delete-search'"
          >
            close
          </mat-icon>
          <mat-icon
            *ngIf="showClearAllBtn && !readOnly"
            (click)="clearAllByQuery(_lookupBuffer)"
            [matTooltip]="multiple ?  ('GENERIC_COMP.LOOKUP.clearAll' | pTranslate) : ('GENERIC_COMP.LOOKUP.clear'  | pTranslate)"
            class="generic-lookup-clearAll"
            [ngClass]="{ disabled: !selected?.length }"
            [attr.data-test-clear-all]="'clear-all'"
            [attr.data-test-id]="__dataTestId ? __dataTestId : dataTestId ? (dataTestId + '-clear-all') : 'generic-lookup-clear-all'"
          >
            remove_circle_outline
          </mat-icon>
          <mat-icon
            *ngIf="showSelectAllBtn && !readOnly && multiple"
            (click)="selectAllByQuery(_lookupBuffer)"
            [matTooltip]="('GENERIC_COMP.LOOKUP.selectAll' | pTranslate)  + (!(_lookupBuffer?.length > 0) ?' (' + valuesSize + ')': '')"
            class="generic-lookup-selectAll"
            [ngClass]="{ disabled: selected?.length === values?.length }"
            [attr.data-test-id]="__dataTestId ? __dataTestId : dataTestId ? (dataTestId + '-select-all') : 'generic-lookup-select-all'"
            [attr.data-test-options-length]="valuesSize"
          >
            control_point
          </mat-icon>
          <mat-icon
            *ngIf="showSelectAllBtn && multiple"
            (click)="toggleOnlySelected()"
            [matTooltip]="('GENERIC_COMP.LOOKUP.onlySelected' | pTranslate) + ' (' + valuesAndSelectedText.length + ')'"
            class="generic-lookup-onlySelected"
            [ngClass]="{ selected: _onlySelected, disabled: !selected?.length }"
            [attr.data-test-id]="__dataTestId ? __dataTestId : dataTestId ? (dataTestId + 'toggle-select') : 'generic-lookup-toggle-select'"
            [attr.data-test-only-selected]="'only-selected'"
          >
            check_box
          </mat-icon>
        </div>
      </div>
      <ng-container *ngIf="!avoidRender && reserLookupResults() && !asyncSearch ">
        <!-- - Simple- -->
        <ng-container *ngIf="!isGroup">
          <cdk-virtual-scroll-viewport
            [itemSize]="itemSize"
            [style.height.px]="itemSize * optionHeight"
            [attr.__minBufferPx]="2 * itemSize"
            [attr.__maxBufferPx]="3 * itemSize"
          >
            <ng-container
              *cdkVirtualFor="let option of (!_onlySelected ? values : valuesAndSelected) || []
                    | _auxfilterlookUp: (showLookup ? _lookup : ''):asyncFlag ? null : propValue:true; trackBy:trackByPropId"
            >
              <mat-option
                *ngIf="option && option[propId] != undefined && option._hide !== true && incrementLookupResults()"
                [value]="option[propId]"
                [attr.data-test-id]="__dataTestId ? __dataTestId : dataTestId ? (dataTestId + '-option-select-'+option[propId]) :'option-select-'+option[propId]"
                class="generic-lookup-option"
                (onSelectionChange)="onSelectionChange($event, option)"
                [ngClass]="[optCustomClass ? optCustomClass : '', readOnly ? '__readOnly' : '', _onlySelected ? '__onlySelected' : '',  option._customClass ? option._customClass : '' ]"
                [matTooltip]="showItemsToolTips && option && propValue && option[propValue] && option[propValue].length > minCharLengthToShowTooltip ? (option[propValue] || null) : null"
                [matTooltipShowDelay]="400"
                [disabled]="(!option.active && disabledOption === true) || disabledAllOption === true "
              >
                <ng-container *ngIf="!optionComponent">{{ option[propValue] }}</ng-container>
                <ng-container *ngIf="optionComponent">
                  <ng-container *ngTemplateOutlet="optionComponent; context: { option: option }"></ng-container>
                </ng-container>
              </mat-option>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </ng-container>
        <!-- - Agrupado- -->
        <ng-container *ngIf="isGroup">
          <cdk-virtual-scroll-viewport
            [itemSize]="itemSize"
            [style.height.px]="itemSize * optionHeight"
            [attr.__minBufferPx]="2 * itemSize"
            [attr.__maxBufferPx]="3 * itemSize"
          >
            <ng-container
              *cdkVirtualFor="let optionGrp of (groupParents || [])
                                | _auxfilterlookUp: (showLookup ? _lookup : ''):'_searchField':true ; trackBy:trackByPropId"
            >
              <mat-optgroup
                *ngIf="optionGrp && optionGrp._hide !== true && (!_onlySelected ? true : parentHasSomeChildSelected(optionGrp) ) && incrementLookupResults()"
                [label]="optionGrp[propValue] + ' (' + optionGrp._childsIds.length + ')'"
                class="generic-lookup-optgroup"
                [ngClass]="[optCustomClass ? optCustomClass : '', readOnly ? '__readOnly' : '', optionGrp._customClass ? optionGrp._customClass : '' ]"
              >
                <div class="generic-lookup-optgroup-selectall">
                  <mat-icon
                    *ngIf="canShowSelectAllGroup(optionGrp)"
                    (click)="selectSome(optionGrp._childsIds)"
                    [matTooltip]="('GENERIC_COMP.LOOKUP.selectAllChilds' | pTranslate) + ' (' + optionGrp._childsIds.length + ')'"
                    class="generic-lookup-select-some"
                  >
                    control_point
                  </mat-icon>
                </div>
                <ng-container
                  *ngFor="let option of optionGrp._childs | _auxfilterlookUp: (showLookup ? _lookup : ''):'_searchField':true"
                >
                  <mat-option
                    *ngIf="option && option[propId] != undefined && option._hide !== true &&
                                  (!_onlySelected ? true : selected.indexOf(option[propId]) > -1 ) && incrementLookupResults()"
                    [value]="option[propId]"
                    [attr.data-test-id]="__dataTestId ? __dataTestId : dataTestId ? (dataTestId + '-option-select-'+ (option[propValue] || 'none')) :'option-select-'+ (option[propValue] || 'none')"
                    class="generic-lookup-option generic-lookup-option-group"
                    (onSelectionChange)="onSelectionChange($event, option)"
                    [ngClass]="[optCustomClass ? optCustomClass : '', readOnly ? '__readOnly' : '', _onlySelected ? '__onlySelected' : '', option._customClass ? option._customClass : '' ]"
                    [matTooltip]="showItemsToolTips && option && propValue && option[propValue] && option[propValue].length > minCharLengthToShowTooltip ? (option[propValue] || null) : null"
                    [matTooltipShowDelay]="400"
                  >
                    <ng-container *ngIf="!optionComponent">{{ option[propValue] }}</ng-container>
                    <ng-container *ngIf="optionComponent">
                      <ng-container *ngTemplateOutlet="optionComponent; context: { option: option }"></ng-container>
                    </ng-container>
                  </mat-option>
                </ng-container>
              </mat-optgroup>
            </ng-container>
          </cdk-virtual-scroll-viewport>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="addCustomOption">
        <mat-divider></mat-divider>
        <ng-container *ngTemplateOutlet="addCustomOption;"></ng-container>
      </ng-container>
      <!-- loader de busqueda -->
      <div class="generic-lookup-notfound">
        <mat-spinner
          *ngIf="asyncFlag && asyncSearch"
          [diameter]="20"
          color="accent"
          attr.data-test-id="generic-lookup-container"
        ></mat-spinner>
      </div>
      <!-- - sin Resultados- -->
      <div
        *ngIf="!asyncSearch && showLookup && showItemsNotFound && _lookup.length && !values.length"
        class="generic-lookup-notfound"
      >
        {{_lookupResults === 0 ? itemsNotFound : ''}}
      </div>
    </ng-container>
  </mat-select>
</mat-form-field>
