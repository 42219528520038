import { Component, Input, OnInit } from '@angular/core';
import { getEnabledBlockVal } from '@shared/disabled_blocks';

@Component({
  selector: 'custom-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class CustomLoaderComponent implements OnInit {
  useCustomLoader = true;

  @Input() diameter: number = undefined;
  @Input() color: string = undefined;
  @Input() size = 1.2;
  @Input() top = 'auto';
  @Input() text: string = null;

  ngOnInit(): void {}
}
