import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpCancelService {
  private cancelPendingRequests$ = new Subject<void>();

  /** Cancels all pending Http requests. */
  cancelPendingRequests(): void {
    this.cancelPendingRequests$.next();
    this.cancelPendingRequests$.complete();
  }

  onCancelPendingRequests(): Observable<void> {
    return this.cancelPendingRequests$.asObservable();
  }
}
