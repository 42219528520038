import { State, Action, Selector, StateContext, Store } from '@ngxs/store';
import { InsightsStoreStateModel, InsightsListModel, InsightTypesModel, InsightFilterModel } from './insights-store.model';
import { PagedList } from 'core/models/paged-list.model';
import { catchError, tap } from 'rxjs/operators';
import { InsightsService } from '../insights.service';
import { Injectable } from '@angular/core';
import { _cloneDeep } from '@shared/aux_helper_functions';
import { forkJoin } from 'rxjs';
import { GenericEntity } from 'core/models/generic-entity.model';
import { ModulesTagId } from '@prisma/models/filter-attribute.enums';
import { TagsService } from '@prisma/services/tags.service';
import { PayloadToSendTags } from '@prisma/components/dinamic-tags/model/dinamicTagsModel';
import { AttributeItemModel } from 'core/models/attribute.model';
import { StoreV2_list_service } from '../../stores-v2/list/STOREV2_list.service';

export const FEATURE_KEY = 'InsightsListStore';
export class InsightsGetListAction {
  static readonly type = `${FEATURE_KEY} Get list`;
}

export class InsightsGetAttributesAction {
  static readonly type = `${FEATURE_KEY} Get attributes for filter`;
}

export class InsightsGetListSuccessAction {
  static readonly type = `${FEATURE_KEY} Get list succes`;
  constructor(readonly payload: PagedList<InsightsListModel>) {}
}

export class InsightsSetQueryAction {
  static readonly type = `${FEATURE_KEY} set query`;
  constructor(readonly payload: string) {}
}

export class InsightsSetPageEventAction {
  static readonly type = `${FEATURE_KEY} set pageEvent`;
  constructor(readonly payload: { pageIndex: number; pageSize: number }) {}
}

export class InsightsApplyFilterAction {
  static readonly type = `${FEATURE_KEY} set InsightsApplyFilter`;
  constructor(readonly payload: InsightFilterModel) {}
}

export class InsightsResetFilterAction {
  static readonly type = `${FEATURE_KEY} reset InsightsResetFilterAction`;
}

export class InsightsSetErrorAction {
  static readonly type = `[${FEATURE_KEY}] Set Errors`;
  constructor(readonly payload: any) {}
}

export const InsightsdefaultAdvancedFilter = (): InsightFilterModel => {
  return {
    onlyOwner: false,
    query: null,
    types: [],
    categories: [],
    storeTags: null,
    stores: [],
    dateFrom: null,
  };
};

@Injectable({
  providedIn: 'root',
})
@State<InsightsStoreStateModel>({
  name: FEATURE_KEY,
  defaults: {
    items: {
      pageIndex: 0,
      pageSize: 0,
      totalItemCount: 0,
      items: [],
    },
    selectedItems: [],
    excludedItems: [],
    hasBulkSelection: false,
    pageIndex: 0,
    pageSize: 10,
    activePageIndex: 0,
    activePageSize: 10,
    filters: {
      types: [],
      stores: [],
      storeTags: [],
    },
    advancedFilterSelection: InsightsdefaultAdvancedFilter(),
  },
})
export class InsightsStoreState {
  @Selector()
  public static getState(state: InsightsStoreStateModel) {
    return state;
  }

  @Selector()
  public static getTypeAttributes(state: InsightsStoreStateModel): Array<InsightTypesModel> {
    return state.filters.types;
  }

  @Selector()
  public static getStoreAttributes(state: InsightsStoreStateModel): Array<GenericEntity> {
    return state.filters.stores;
  }

  @Selector()
  public static getStoreTagsAttributes(state: InsightsStoreStateModel): Array<AttributeItemModel> {
    return state.filters.storeTags;
  }

  @Selector()
  public static getQuery(state: InsightsStoreStateModel): string {
    return state.advancedFilterSelection.query;
  }

  @Selector()
  public static onlyOwnerSelected(state: InsightsStoreStateModel): boolean {
    return state.advancedFilterSelection.onlyOwner;
  }

  @Selector()
  public static typesSelected(state: InsightsStoreStateModel): Array<number> {
    return state.advancedFilterSelection.types;
  }

  @Selector()
  public static categoriesSelected(state: InsightsStoreStateModel): Array<number> {
    return state.advancedFilterSelection.categories;
  }

  @Selector()
  public static storeTagsSelected(state: InsightsStoreStateModel): Array<PayloadToSendTags> {
    return state.advancedFilterSelection.storeTags;
  }

  @Selector()
  public static storeSelected(state: InsightsStoreStateModel): Array<number> {
    return state.advancedFilterSelection.stores;
  }

  @Selector()
  public static dateFormSelected(state: InsightsStoreStateModel): string {
    return state.advancedFilterSelection.dateFrom;
  }

  @Selector()
  static getInsights(state: InsightsStoreStateModel): PagedList<InsightsListModel> {
    return _cloneDeep(state.items);
  }

  constructor(
    private store: Store,
    private service: InsightsService,
    private storeService: StoreV2_list_service,
    private tagService: TagsService
  ) {}

  @Action(InsightsGetListAction)
  getList(context: StateContext<InsightsStoreStateModel>, action: InsightsGetListAction): any {
    const currentState = context.getState();
    const service = currentState.advancedFilterSelection.onlyOwner
      ? this.service.getListOwner(currentState.advancedFilterSelection, currentState.activePageIndex, currentState.activePageSize)
      : this.service.getList(currentState.advancedFilterSelection, currentState.activePageIndex, currentState.activePageSize);

    return service.pipe(
      tap(data => {
        const newAction = new InsightsGetListSuccessAction(data);
        return this.store.dispatch(newAction);
      }),

      catchError(errors => {
        const newAction = new InsightsSetErrorAction(errors);

        return this.store.dispatch(newAction);
      })
    );
  }

  @Action(InsightsGetAttributesAction)
  GetAttributesForFilter(context: StateContext<InsightsStoreStateModel>, action: InsightsGetAttributesAction): any {
    let arrayOfData = [];
    arrayOfData.push(this.service.getTypes());
    arrayOfData.push(this.storeService.getStoreList());
    arrayOfData.push(this.tagService.getDinamicttribute(ModulesTagId.STORES));

    forkJoin(arrayOfData).subscribe(
      ([typeList, storeList, tags]) => {
        let _typeList = _cloneDeep(typeList);
        let _storeList = _cloneDeep(storeList);
        let _tags = _cloneDeep(tags);
        context.patchState({
          filters: {
            types: _typeList,
            stores: _storeList,
            storeTags: this.tagService.createHierarchicalAttributesModel(_tags),
          },
        });
      },
      error => {
        this.store.dispatch(new InsightsSetErrorAction(error));
      }
    );
  }

  @Action(InsightsGetListSuccessAction)
  getListSuccess(context: StateContext<InsightsStoreStateModel>, action: InsightsGetListSuccessAction): any {
    context.patchState({
      items: action.payload,
    });
  }

  @Action(InsightsApplyFilterAction)
  setInsightsApplyFilter(context: StateContext<InsightsStoreStateModel>, action: InsightsApplyFilterAction): any {
    const currentContext = context.getState();
    context.patchState({
      advancedFilterSelection: { ...action.payload, query: currentContext.advancedFilterSelection.query },
    });
    this.store.dispatch(new InsightsGetListAction());
  }

  @Action(InsightsResetFilterAction)
  resetInsightsResetFilterAction(context: StateContext<InsightsStoreStateModel>, action: InsightsResetFilterAction): any {
    context.patchState({
      advancedFilterSelection: InsightsdefaultAdvancedFilter(),
    });
    this.store.dispatch(new InsightsGetListAction());
  }

  @Action(InsightsSetQueryAction)
  setQuery(context: StateContext<InsightsStoreStateModel>, action: InsightsSetQueryAction): any {
    const currentContext = context.getState();
    context.patchState({
      advancedFilterSelection: { ...currentContext.advancedFilterSelection, query: action.payload },
    });
    this.store.dispatch(new InsightsGetListAction());
  }

  @Action(InsightsSetPageEventAction)
  setPageEvent(context: StateContext<InsightsStoreStateModel>, action: InsightsSetPageEventAction): any {
    context.patchState({
      activePageIndex: action.payload.pageIndex,
      activePageSize: action.payload.pageSize,
    });
    this.store.dispatch(new InsightsGetListAction());
  }
}
