<div class="error-404" fxLayout="column" fxLayoutAlign="center center">
  <div class="content" fxLayout="column" fxLayoutAlign="center center">
    <div class="error-code">404</div>

    <div class="message">P&aacute;gina no encontrada</div>

    <div class="search" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="icon s-24">buscar</mat-icon>
      <input placeholder="Realizar una b&uacute;squeda" fxFlex />
    </div>

    <a class="back-link" [routerLink]="'/home/dashboard'">Ir al dashboard</a>
  </div>
</div>
