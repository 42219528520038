import { Injectable } from '@angular/core';

export enum ColumnTypeEnum {
  def = 1,
  csv = 2,
}

/**
 * Objeto a implementar para la organización entre la tabla .html y archivo .csv
 */
export interface DisplayedColumnsObject {
  /**
   * Nombre de columna a la que se hará referencia en el archivo .html
   */
  columnDef: string;

  /**
   * Nombre de la columna que se mostrará en el archivo .csv
   */
  columnCsv: string;

  /**
   * Nombre de la property de donde se obtendrá el valor en de la columna correspondiente en el archivo .csv
   */
  columnValue: string;

  /**
   * Define si la columna será visualizada en el archivo .csv
   */
  showColumnCsv: boolean;

  dynamic?: boolean;
}

@Injectable()
export class DisplayedColumnsService {
  displayedColumnsObject: Array<DisplayedColumnsObject> = new Array<DisplayedColumnsObject>();

  /**
   * Provider of columns for HTML or CSV format
   * @param tipo Tipo def (HTML) or csv (Excel)
   */
  getDisplayedColumns(tipo: ColumnTypeEnum = ColumnTypeEnum.def): Array<DisplayedColumnsObject> {
    const col = this.displayedColumnsObject.map(item => {
      if (tipo === ColumnTypeEnum.def) {
        return item;
      } else if (item.showColumnCsv) {
        return item;
      }
    });

    return this.displayedColumnsObject;
  }

  /**
   * Populate header columns for HTML and CSV format and specifies the columns tha will show in CVS.
   * @param columns Columns for HTML and CSV.
   */
  PopulateColumnsHtmlCsv(columns: Array<DisplayedColumnsObject>) {
    this.displayedColumnsObject = columns;
  }
}
