const list = {
  items: [
    {
      id: 0,
      name: 'Gaseosas Principal',
      type: 'Góndola',
      lastModif: '5/7/2019',
      categories: 2,
      charge: 75,
      front: 4.9,
      linealMeters: 24.5,
      layout: 3,
      store: 81,
      sku: 192,
      sales: 2698.2,
      var: 0.3,
    },
    {
      id: 0,
      name: 'Almacén Principal',
      type: 'Góndola',
      lastModif: '7/5/2019',
      categories: 6,
      charge: 51,
      front: 3.3,
      linealMeters: 13,
      layout: 2,
      store: 48,
      sku: 38,
      sales: 10117.8,
      var: -0.02,
    },
  ],
  totalItemCount: 2,
  pageSize: 10,
};

const getPlanogramById = {
  id: 1,
  categoriesSelected: [102],
  planogramName: 'Planogram TEST',
  storesSelected: [2],
};

const getCategoryOptions = [
  { id: 1, value: 'Agua Mineral' },
  { id: 2, value: 'Gaseosas' },
  { id: 3, value: 'Alfajores' },
  { id: 4, value: 'Articulos de Limpieza' },
];

const getStoreOptions = [
  { itemId: 2, itemCode: 'F002', itemName: 'Alvarez Thomas I' },
  { itemId: 16, itemCode: 'F016', itemName: 'Bahía Blanca I ' },
];

const getStoreOptions2 = [
  { itemId: 2, itemCode: 'F002', itemName: 'Alvarez Thomas I' },
  { itemId: 16, itemCode: 'F016', itemName: 'Bahía Blanca I ' },
  { itemId: 17, itemCode: 'F017', itemName: 'Bahía Blanca II' },
  { itemId: 19, itemCode: 'F019', itemName: 'Balcarce Centro' },
  { itemId: 12, itemCode: 'F012', itemName: 'Basílica Luján' },
  { itemId: 5, itemCode: 'F005', itemName: 'Belgrano I' },
  { itemId: 3, itemCode: 'F003', itemName: 'Carranza ' },
  { itemId: 24, itemCode: 'F024', itemName: 'Concordia' },
  { itemId: 21, itemCode: 'F021', itemName: 'Córdoba I' },
];

export const dummyPlanogramsTab = {
  list,
  getPlanogramById,
  getCategoryOptions,
  getStoreOptions,
  getStoreOptions2,
};
