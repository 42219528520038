import { PagedList } from 'core/models/paged-list.model';
import { FloorPlanogramListModel, FloorPlanRootModel, PlanogramsAnalyticsData } from './floorplans-editor_formstate_model';

export const floorplan_obj_dummyData_A: FloorPlanRootModel = {
  id: 10,
  levels: [
    {
      id: 1,
      order: 1,
      width: 2000,
      depth: 2000 * (1032 / 1778),
      sellingAreaSpace: 919,
      totalSpace: 919,
      photoUri: './assets/tmp-dev/spaces/test_floorplan_img.png',
      floorPlanograms: [
        {
          id: 1,
          categoryId: 1,
          categoryName: 'DESAYUNO Y MERIENDA',
          planogramId: 1,
          name: 'Yerbas Cluster A 1 módulos por MARCA',
          color: null,
          width: 350,
          depth: 80,
          x: 545.1900007862852,
          y: 205.00000282103093,
          rotation: 90,
        },
        {
          id: 2,
          categoryId: 2,
          categoryName: 'DESAYUNO Y MERIENDA',
          planogramId: 1,
          name: 'Yerbas Cluster A 2 módulos por MARCA',
          color: null,
          width: 350,
          depth: 80,
          x: 729.620002249624,
          y: 207.27000552618503,
          rotation: 90,
        },
        {
          id: 3,
          categoryId: 3,
          categoryName: 'DESAYUNO Y MERIENDA',
          planogramId: 1,
          name: 'Yerbas Cluster A 3 módulos por MARCA ',
          color: null,
          width: 350,
          depth: 80,
          x: 910.6200091751127,
          y: 207.16000409781583,
          rotation: 90,
        },
        {
          categoryId: 4,
          categoryName: 'DESAYUNO Y MERIENDA',
          color: null,
          depth: 80,
          id: 4,
          name: 'Yerbas Cluster A 5 módulos por MARCA',
          rotation: 90,
          planogramId: 2,
          width: 350,
          x: 1099.69,
          y: 207,
        },
      ],
    },
    {
      id: 2,
      order: 2,
      width: 2000,
      depth: 2000 * (1032 / 1778),
      sellingAreaSpace: 919,
      totalSpace: 919,
      photoUri: './assets/tmp-dev/spaces/test_floorplan_img.png',
      floorPlanograms: [
        {
          id: 11,
          categoryId: 1,
          categoryName: 'DESAYUNO Y MERIENDA',
          planogramId: 2,
          name: 'Yerbas Cluster A 1 módulos por MARCA',
          color: null,
          width: 350,
          depth: 80,
          x: 545.1900007862852,
          y: 205.00000282103093,
          rotation: 90,
        },
        {
          id: 12,
          categoryId: 2,
          categoryName: 'DESAYUNO Y MERIENDA',
          planogramId: 2,
          name: 'Yerbas Cluster A 2 módulos por MARCA',
          color: null,
          width: 350,
          depth: 80,
          x: 729.620002249624,
          y: 207.27000552618503,
          rotation: 90,
        },
        {
          id: 19,
          categoryId: 3,
          categoryName: 'DESAYUNO Y MERIENDA',
          planogramId: 3,
          name: 'Yerbas Cluster A 3 módulos por MARCA ',
          color: null,
          width: 350,
          depth: 80,
          x: 910.6200091751127,
          y: 207.16000409781583,
          rotation: 90,
        },
      ],
    },
  ],
  generalData: {
    name: 'Local 146',
    code: 'LOC146',
    description: 'Description Test',
    storesSelected: {
      selectedItems: [14],
      dinamicFilterActive: [],
    },
  },
};

export const floorplanPlanogramList_dummyData_A_ITEMS: FloorPlanogramListModel[] = [
  {
    id: 'planogram-1',
    categoryId: 1,
    categoryName: 'test',
    planogramId: 1,
    name: 'Planograma SodaPop Heladera',
    color: '#f2c94c',
    width: 350,
    depth: 50,
  },
  {
    categoryId: 1,
    categoryName: 'test',
    planogramId: 1,
    id: 'planogram-2',
    name: 'Heladera Tier 2',
    color: '#56ccf2',
    width: 350,
    depth: 20,
  },
  {
    categoryId: 1,
    categoryName: 'test',
    planogramId: 1,
    id: 'planogram-3',
    name: 'Heladera Tier 3',
    color: '#13cb7c',
    width: 500,
    depth: 60,
  },
  {
    categoryId: 1,
    categoryName: 'test',
    planogramId: 1,
    id: 'planogram-4',
    name: 'Heladera Tier 4',
    color: '#9437ff',
    width: 80,
    depth: 80,
  },
  {
    categoryId: 1,
    categoryName: 'test',
    planogramId: 1,
    id: 'planogram-5',
    name: 'Heladera Tier 5',
    color: '#0c7c1f',
    width: 180,
    depth: 180,
  },
  {
    id: 'planogram-1',
    categoryId: 1,
    categoryName: 'test',
    planogramId: 1,
    name: 'Planograma SodaPop Heladera',
    color: '#f2c94c',
    width: 350,
    depth: 50,
  },
  {
    categoryId: 1,
    categoryName: 'test',
    planogramId: 1,
    id: 'planogram-2',
    name: 'Heladera Tier 2',
    color: '#56ccf2',
    width: 350,
    depth: 20,
  },
  {
    categoryId: 1,
    categoryName: 'test',
    planogramId: 1,
    id: 'planogram-3',
    name: 'Heladera Tier 3',
    color: '#13cb7c',
    width: 500,
    depth: 60,
  },
  {
    categoryId: 1,
    categoryName: 'test',
    planogramId: 1,
    id: 'planogram-4',
    name: 'Heladera Tier 4',
    color: '#9437ff',
    width: 80,
    depth: 80,
  },
  {
    categoryId: 1,
    categoryName: 'test',
    planogramId: 1,
    id: 'planogram-5',
    name: 'Heladera Tier 5',
    color: '#0c7c1f',
    width: 180,
    depth: 180,
  },
].filter(Boolean);

export const floorplanPlanogramList_dummyData_A: PagedList<FloorPlanogramListModel> = {
  items: floorplanPlanogramList_dummyData_A_ITEMS,
  totalItemCount: 3,
  pageIndex: 0,
  pageSize: 10,
};

export const dummyDataFloorplansAnalytics: PlanogramsAnalyticsData = {
  planograms: [
    {
      id: 1,
      code: null,
      planogramName: 'DES-2028',
      planogramDescription: '',
      categoriesSelected: [785],
      storesSelected: [1],
      status: 'pending-endorsed',
      calc_TotalLineSpace: 200,
      calc_TotalItems: 46,
      calc_TotalFacings: 6,
      publishDate: '2022-07-19T15:47:35.458653+00:00',
      horizontalGuidesMeasure: 0,
      constraints: [],
      sellingArea: false,
      hasInverseTraffic: false,
      planogramArea: 10,
      prods: [
        {
          id: 133,
          position: {
            x: null,
            y: null,
          },
          order: 0,
          totalItems: 0,
          calc_Stacks: 1,
          calc_TotalItems: 4,
          calc_TotalItemsMax: 4,
          calc_TotalWidth: 10.9,
          facings: 1,
          rotation: 0,
          stacksLyingDown: false,
          itemId: 19,
          unitOfMeasureId: 20,
          limitLoadSize: null,
          limitStackSize: null,
          rotateY: false,
          parentShelfDepth: 60,
        },
        {
          id: 134,
          position: {
            x: null,
            y: null,
          },
          order: 0,
          totalItems: 0,
          calc_Stacks: 1,
          calc_TotalItems: 4,
          calc_TotalItemsMax: 4,
          calc_TotalWidth: 10.9,
          facings: 1,
          rotation: 0,
          stacksLyingDown: false,
          itemId: 21,
          unitOfMeasureId: 20,
          limitLoadSize: null,
          limitStackSize: null,
          rotateY: false,
          parentShelfDepth: 60,
        },
      ],
    },
  ],
  itemsData: [
    {
      itemId: 19,
      unitOfMeasureId: 20,
      name: '7UP Caffeine-Free Cherry Flavored Soda, 2 L',
      code: '9501237',
      ean: '9501237',
      categoryId: '73',
      categoryName: 'Beverages',
      subCategoryId: '785',
      subCategoryCode: 'FFB312303',
      subCategoryName: 'Soda Pop',
      brand: null,
      canLayDown: false,
      canRotate: false,
      canRotateY: false,
      hasExhibition: true,
      imagePlanogramFront: '/assets/images/items-demo/9501237.png',
      imagePlanogramLateral: null,
      imagePlanogramTop: null,
      width: 10.9,
      height: 30.5,
      depth: 10.9,
      size: {
        w: 10.9,
        h: 30.5,
        d: 10.9,
      },
      maxStacks: null,
      stackable: null,
      tags: [
        {
          tagId: 9,
          includedValues: [16],
        },
        {
          tagId: 11,
          includedValues: [32],
        },
        {
          tagId: 12,
          includedValues: [34],
        },
        {
          tagId: 13,
          includedValues: [39],
        },
        {
          tagId: 10,
          includedValues: [42],
        },
        {
          tagId: 8,
          includedValues: [56],
        },
        {
          tagId: 14,
          includedValues: [73],
        },
      ],
      storesInfo: [
        {
          itemId: 19,
          unitOfMeasureId: 20,
          unitOfMeasureCode: null,
          unitOfMeasureName: null,
          storeId: 3,
          storeCode: 'S006',
          storeName: 'Prismart Aloma',
          clusterId: -1,
          clusterName: null,
          availability: 0,
          cost: 10,
          currentInventory: 0,
          currentInventoryUnits: 0,
          daysOutOfStock: 0,
          daysInStock: 0,
          gmroi: 0,
          gmros: 0,
          isListed: true,
          margin: 3,
          marginP: 0,
          price: 20,
          sales: 100,
          salesUnits: 0,
          stock: 0,
          stockUnits: 0,
          units: 0,
          isBlockedForSales: false,
        },
        {
          itemId: 21,
          unitOfMeasureId: 20,
          unitOfMeasureCode: null,
          unitOfMeasureName: null,
          storeId: 2,
          storeCode: 'S006',
          storeName: 'Prismart Aloma',
          clusterId: -1,
          clusterName: null,
          availability: 0,
          cost: 10,
          currentInventory: 0,
          currentInventoryUnits: 0,
          daysOutOfStock: 0,
          daysInStock: 0,
          gmroi: 0,
          gmros: 0,
          isListed: true,
          margin: 3,
          marginP: 0,
          price: 10,
          sales: 100,
          salesUnits: 0,
          stock: 0,
          stockUnits: 0,
          units: 0,
          isBlockedForSales: false,
        },
      ],
      isListed: true,
    },
    {
      itemId: 21,
      unitOfMeasureId: 22,
      name: '7UP Caffeine-Free Cherry Flavored Soda, 2 L',
      code: '9501237',
      ean: '95012372',
      categoryId: '73',
      categoryName: 'Beverages',
      subCategoryId: '785',
      subCategoryCode: 'FFB312303',
      subCategoryName: 'Soda Pop',
      brand: null,
      canLayDown: false,
      canRotate: false,
      canRotateY: false,
      hasExhibition: true,
      imagePlanogramFront: '/assets/images/items-demo/9501237.png',
      imagePlanogramLateral: null,
      imagePlanogramTop: null,
      width: 10.9,
      height: 30.5,
      depth: 10.9,
      size: {
        w: 10.9,
        h: 30.5,
        d: 10.9,
      },
      maxStacks: null,
      stackable: null,
      tags: [
        {
          tagId: 9,
          includedValues: [16],
        },
        {
          tagId: 11,
          includedValues: [32],
        },
        {
          tagId: 12,
          includedValues: [34],
        },
        {
          tagId: 13,
          includedValues: [39],
        },
        {
          tagId: 10,
          includedValues: [42],
        },
        {
          tagId: 8,
          includedValues: [56],
        },
        {
          tagId: 14,
          includedValues: [73],
        },
      ],
      storesInfo: [
        {
          itemId: 19,
          unitOfMeasureId: 20,
          unitOfMeasureCode: null,
          unitOfMeasureName: null,
          storeId: 3,
          storeCode: 'S006',
          storeName: 'Prismart Aloma',
          clusterId: -1,
          clusterName: null,
          availability: 0,
          cost: 10,
          currentInventory: 0,
          currentInventoryUnits: 0,
          daysOutOfStock: 0,
          daysInStock: 0,
          gmroi: 0,
          gmros: 0,
          isListed: true,
          margin: 3,
          marginP: 0,
          price: 20,
          sales: 200,
          salesUnits: 0,
          stock: 0,
          stockUnits: 0,
          units: 0,
          isBlockedForSales: false,
        },
        {
          itemId: 21,
          unitOfMeasureId: 20,
          unitOfMeasureCode: null,
          unitOfMeasureName: null,
          storeId: 2,
          storeCode: 'S006',
          storeName: 'Prismart Aloma',
          clusterId: -1,
          clusterName: null,
          availability: 0,
          cost: 10,
          currentInventory: 0,
          currentInventoryUnits: 0,
          daysOutOfStock: 0,
          daysInStock: 0,
          gmroi: 0,
          gmros: 0,
          isListed: true,
          margin: 3,
          marginP: 0,
          price: 10,
          sales: 200,
          salesUnits: 0,
          stock: 0,
          stockUnits: 0,
          units: 0,
          isBlockedForSales: false,
        },
      ],
      isListed: true,
    },
  ],
};
