import { _isFeDev, _log } from '@shared/aux_helper_environment';
import { _toNumber } from '@shared/aux_helper_functions';

const _get = require('dlv');

/*
    DEPRECAR
*/
export const _auxReduceSingleProdDataStoresInfo_OLD = (
  prodData,
  abortInStoreInfoProcessed = true,
  filterStores: (string | number)[] = null
) => {
  if (abortInStoreInfoProcessed && prodData && prodData._StoreInfoProcessed && prodData.price) return prodData;

  if (!_get(prodData, 'storesInfo.length', null)) {
    return { ...prodData, unMed: prodData.unMed || 1, ...getInitialStoreDataAverages(prodData, 0) };
  }

  const stores = (prodData.storesInfo || [])
    .filter(p => p && p.price)
    .filter(s => {
      {
        if (!filterStores || !filterStores.length) return true;
        return filterStores.map(s => String(s)).includes(String(s.storeId));
      }
    });

  const storesSize = stores.length;

  /*calcula promedios*/
  const itemDataAverages = stores.reduce((acum, actual) => {
    if (!actual) return acum;

    const price = actual.price || 0;
    const cost = actual.cost || 0;
    const sales = actual.sales || 0;
    const salesUnits = actual.salesUnits || (price ? Math.round(sales / price) : 0) || actual.units || 0;

    const margin = salesUnits ? actual.margin / salesUnits : 0;
    const marginP = actual.marginP != null ? actual.marginP || 0 : margin && price ? margin / price : 0;

    acum.price += price / storesSize;
    acum.cost += cost / storesSize;
    acum.margin += margin / storesSize;
    acum.marginP += marginP / storesSize;
    acum.sales += sales / storesSize;
    acum.salesUnits += salesUnits / storesSize;

    acum.currentInventory += (actual.currentInventory || 0) / storesSize;
    acum.currentInventoryUnits += (actual.currentInventoryUnits || 0) / storesSize;
    acum.daysOutOfStock += (actual.daysOutOfStock || 0) / storesSize;
    acum.daysInStock += (actual.daysInStock || 0) / storesSize;
    acum.gmroi += (actual.gmroi || 0) / storesSize;
    acum.gmros += (actual.gmros || 0) / storesSize;
    acum.inventory += (actual.inventory || 0) / storesSize;
    acum.inventoryDays += (actual.inventoryDays || 0) / storesSize;
    acum.inventoryUnits += (actual.inventoryUnits || 0) / storesSize;
    acum.regularPrice += (actual.regularPrice || 0) / storesSize;
    acum.stock += (actual.stock || 0) / storesSize;
    acum.stockUnits += (actual.stockUnits || 0) / storesSize;
    acum.units += (actual.units || 0) / storesSize;
    /**/
    acum.isNew = acum.isNew || actual.isNew;
    /**/
    acum.isListed = acum.isListed || actual.isListed;
    if (!actual.isListed) acum.isAllListed = false;

    acum.isBlockedForSales = acum.isBlockedForSales || actual.isBlockedForSales;
    if (!actual.isBlockedForSales) acum.isAllBlockedForSales = false;

    return acum;
  }, getInitialStoreDataAverages(prodData, storesSize));

  const rv = { ...prodData, unMed: prodData.unMed || 1, ...itemDataAverages, _StoreInfoProcessed: true };
  return rv;
};

/*
    funcion identica a _auxReduceSingleProdDataStoresInfo
    pero no filtrando por items con precio 0 o nulo para calcular los promedios.
    Se agrega storesSizeWithPrice para no distorcionar los promedios con algun item con no price pero
    que se ejecute el reduce (pq tmb calcula isNew / isListed / isBlockedForSales )
*/
export const _auxReduceSingleProdDataStoresInfo_NEW_DES_2009 = (
  prodData,
  abortInStoreInfoProcessed = true,
  filterStores: (string | number)[] = null,
  salesRatio: number = 1
) => {
  if (prodData == null) return prodData;

  if (abortInStoreInfoProcessed && prodData._StoreInfoProcessed && prodData.price > 0) return prodData;
  if (prodData?.isEmptySpace) return prodData; //CAT-19

  const prodDataStoresInfo = prodData.storesInfo || [];

  if (!(prodDataStoresInfo.length > 0)) {
    const rv = Object.assign({}, prodData, getInitialStoreDataAverages(prodData, 0));

    //DES-3891
    rv.unMed = prodData.conversion || prodData.unMed || 1;
    rv.unMedName = prodData.unit || '';

    return rv;
  }

  const stores = prodDataStoresInfo
    .filter(p => p != null)
    .filter(s => {
      {
        if (!filterStores || !filterStores.length) return true;
        return filterStores.map(s => String(s)).includes(String(s.storeId));
      }
    });

  const storesSize = stores.length;
  const storesSizeWithPrice = stores.filter(p => p.price > 0).length;

  /*calcula promedios*/
  const itemDataAverages = stores.reduce((acum, actual) => {
    if (!actual) return acum;

    const price = actual.price || 0;
    if (price > 0) {
      const cost = actual.cost || 0;

      const _salesNoDiv = actual.sales || 0;
      const sales = _salesNoDiv * salesRatio;

      const _salesUnitsNoDiv = actual.salesUnits || (price ? (actual.sales || 0) / price : 0) || 0;
      const salesUnits = _salesUnitsNoDiv * salesRatio;

      const margin = _salesUnitsNoDiv ? actual.margin / _salesUnitsNoDiv : 0;

      const _marginPNoDiv = actual.marginP != null ? actual.marginP || 0 : margin && price ? margin / price : 0;
      const marginP = _marginPNoDiv * salesRatio;

      acum.price += price / storesSizeWithPrice;
      acum.cost += cost / storesSizeWithPrice;
      acum.margin += margin / storesSizeWithPrice;
      acum.marginP += marginP / storesSizeWithPrice;
      acum.sales += sales / storesSizeWithPrice;
      acum.salesUnits += salesUnits / storesSizeWithPrice;

      acum.currentInventory += (actual.currentInventory || 0) / storesSizeWithPrice;
      acum.currentInventoryUnits += (actual.currentInventoryUnits || 0) / storesSizeWithPrice;
      acum.daysOutOfStock += (actual.daysOutOfStock || 0) / storesSizeWithPrice;
      acum.daysInStock += (actual.daysInStock || 0) / storesSizeWithPrice;
      acum.gmroi += (actual.gmroi || 0) / storesSizeWithPrice;
      acum.gmros += (actual.gmros || 0) / storesSizeWithPrice;
      acum.inventory += (actual.inventory || 0) / storesSizeWithPrice;
      acum.inventoryDays += (actual.inventoryDays || 0) / storesSizeWithPrice;
      acum.inventoryUnits += (actual.inventoryUnits || 0) / storesSizeWithPrice;
      acum.regularPrice += (actual.regularPrice || 0) / storesSizeWithPrice;
      acum.stock += (actual.stock || 0) / storesSizeWithPrice;
      acum.stockUnits += (actual.stockUnits || 0) / storesSizeWithPrice;
      acum.units += (actual.units || 0) / storesSizeWithPrice;
    }

    acum.isNew = acum.isNew || actual.isNew;
    acum.isListed = acum.isListed || actual.isListed;
    if (!actual.isListed) acum.isAllListed = false;

    acum.isBlockedForSales = acum.isBlockedForSales || actual.isBlockedForSales; /*DES-1486*/
    if (!actual.isBlockedForSales) acum.isAllBlockedForSales = false;

    return acum;
  }, getInitialStoreDataAverages(prodData, storesSize));

  const rv = Object.assign({}, prodData, itemDataAverages);

  //DES-3891 //DES-3945 -> Unidad de medida mayor a 1
  rv.unMed = _toNumber(prodData.conversion || prodData.unMed || 1);
  if (false && _isFeDev()) rv.unMed = 10;
  rv.unMedName = prodData.unit || '';
  rv._StoreInfoProcessed = true;

  return rv;
};

const getInitialStoreDataAverages = (prodData, storesSize) => {
  return {
    cost: 0,
    currentInventory: 0,
    currentInventoryUnits: 0,
    daysOutOfStock: 0,
    daysInStock: 0,
    gmroi: 0,
    gmros: 0,
    inventory: 0,
    inventoryDays: 0,
    inventoryUnits: 0,
    margin: 0,
    marginP: 0,
    price: 0,
    regularPrice: 0,
    sales: 0,
    salesUnits: 0,
    stock: 0,
    stockUnits: 0,
    units: 0,

    isNew: false,
    isListed: false,

    hasExhibition: prodData.hasExhibition,
    isAllListed: storesSize > 0 ? true : false,

    isBlockedForSales: storesSize > 0 ? false : false,
    isAllBlockedForSales: storesSize > 0 ? true : false,
  };
};
