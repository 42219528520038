import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FuseSharedModule } from '@fuse/shared.module';
import { NotificationsComponent } from 'app/layout/components/notifications-panel/notifications-panel.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared.module';

@NgModule({
  declarations: [NotificationsComponent],
  imports: [
    TranslateModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    FuseSharedModule,
    MatIconModule,
    MatMenuModule,
    CommonModule,
    SharedModule,
  ],
  exports: [TranslateModule, NotificationsComponent, MatIconModule, MatMenuModule, MatTooltipModule],
  providers: [],
})
export class NotificationsModule {}
