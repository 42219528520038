<div id="notification-panel-wrapp" *ngIf="insightsPanelOpened" fxLayout="column" fxFlex>
  <div>
    <mat-list class="date" cdkFocusRegionStart>
      <mat-icon (click)="closeInsights()" class="closeInsights">close</mat-icon>
      <h3 matSubheader cdkFocusInit>
        <b class="notification-title">{{ translates?.TITLE }}</b>
        &nbsp;
        <b class="notification-title-number" *ngIf="(insightsSize$ | async) !== null">({{(insightsSize$ | async)}})</b>
      </h3>
    </mat-list>

    <mat-list class="body-notification">
      <ng-container *ngIf="$isLoading === true">
        <div class="_noItems">
          <br />
          <br />
          <custom-loader [size]="0.8"></custom-loader>
        </div>
      </ng-container>

      <!-- NO SE ENCONTRARON INSIGHTS -->
      <ng-container *ngIf="$isLoading === false && insightsPanel !== null && (insightsSize$ | async) === 0">
        <div class="_noItems">
          <img src="assets/emtyState/not-notifications.png" alt="No se encontraron Notificaciones" width="138" />
          <b>{{translates?.NO_NEW_ITEMS}}</b>
        </div>
      </ng-container>

      <!-- MIS INSIGHTS -->
      <div id="notification-panel-list-wrapp-new">
        <ng-container>
          <mat-list-item *ngFor="let insight of insightsPanel;" class="notifPanelItem">
            <!-- En el panel solo voy a tenes visible los insights en los que estoy suscripto -->
            <prisma-context-menu-insights
              *ngIf="$typeIsLoading === false"
              [insight]="insight"
              [alreadySubscripted]="true"
              [typeList]="typeList$"
              (subscriptionDone)="$event"
            ></prisma-context-menu-insights>

            <div matTooltip="{{ insight.description }}">
              <p matLine class="secondary-text secondary-text-date">{{insight.date | dateLenguage}}</p>
              <h4 matLine style="margin-top: 10px">{{insight.name}}</h4>

              <p matLine class="secondary-text-large">{{insight.description | slice:0:48 }}...</p>
            </div>
            <ng-container *ngIf="!insight?.actionUrl">
              <prisma-route-to-another-page
                *ngIf="!$hasReport(insight)"
                [pathFx]="$navigateToAnotherPage"
                [paramsFx]="$getParams"
                [data]="insight"
              >
                <mat-icon>keyboard_arrow_right</mat-icon>
              </prisma-route-to-another-page>
              <prisma-route-to-another-page
                *ngIf="$hasReport(insight)"
                path="home/dashboard/"
                [params]="{ hasUrl: true }"
                (navigationDone)="setDashBoardUrl(insight)"
              >
                <mat-icon>keyboard_arrow_right</mat-icon>
              </prisma-route-to-another-page>
            </ng-container>
          </mat-list-item>
        </ng-container>
      </div>
    </mat-list>
  </div>

  <div class="container-footer">
    <!-- Ir a pantalla de Insights -->
    <a mat-menu-item routerLink="/admin/master/insights/list">
      <img
        _ngcontent-gyv-c554=""
        src="assets/icons/lamp.svg"
        style="vertical-align: middle; padding-right: 6px; margin-left: 0; zoom: 0.85"
        class="lamp_svg"
      />
      <span>{{translates?.GO_TO_INSIGHTS}}</span>
    </a>
  </div>
</div>
