import { HttpErrorResponse } from '@angular/common/http';
import { PrismaServerErrorMessage } from 'core/models/prisma-server-error-message.model';

const FEATURE_KEY = '[App]';

export class AppShowProgressBarAction {
  static readonly type = `${FEATURE_KEY} Show progress bar`;
  constructor(readonly payload = { timeout: undefined }) {}
}

export class AppTimeOutProgressBarAction {
  static readonly type = `${FEATURE_KEY} Time Out progress bar`;
}

export class AppHideProgressBarAction {
  static readonly type = `${FEATURE_KEY} Hide progress bar`;
}

export class AppOpenAdvancedSearchAction {
  static readonly type = `${FEATURE_KEY} Open advanced search`;
}

export class AppCloseAdvancedSearchAction {
  static readonly type = `${FEATURE_KEY} Close advanced search`;
}

export class AppAddErrorAction {
  static readonly type = `${FEATURE_KEY} Add error`;
  constructor(readonly payload: PrismaServerErrorMessage | HttpErrorResponse) {}
}

export class AppARemoveErrorAction {
  static readonly type = `${FEATURE_KEY} Remove error`;
  constructor(readonly payload: PrismaServerErrorMessage | HttpErrorResponse) {}
}

export class AppShowSuccessModal {
  static readonly type = `${FEATURE_KEY} Show success dialog`;
}

export class AppInSave {
  static readonly type = `${FEATURE_KEY} App is in save`;
  constructor(readonly payload: boolean, readonly timeOut: number = null) {}
}

export class AppInSaveTimeOut {
  static readonly type = `${FEATURE_KEY} App is in save time out`;
}
