<div fxFlex="100" fxLayout="column">
  <mat-form-field
    (click)="focusInput(clipboard)"
    class="w-100-p input-clipboard"
    [ngClass]="{'mat-form-field-should-float mat-focused': inputFocused}"
  >
    <mat-label>{{title | pTranslate }}</mat-label>
    <textarea matInput class="w-100-p inputTextArea" [ngModel]="inputTextArea" rows="3" [disabled]="true"></textarea>
  </mat-form-field>

  <div fxFlex="100" fxLayout="row" fxLayoutAlign="start center">
    <span class="color-negative" *ngIf="maxCodes">{{ MAXCODES_MESSAGE }}</span>
    <span class="color-green" *ngIf="!maxCodes && codes && codes.length > 0 ">
      {{codes.length }} {{'M_SHOPPER.MISSIONS.CLIPBOARD_COUNT' | pTranslate }}
    </span>
    <span *ngIf="!maxCodes && (!codes || codes && codes.length === 0)">
      {{'M_SHOPPER.MISSIONS.CLIPBOARD_NONE' | pTranslate }}
    </span>
  </div>

  <input
    type="text"
    class="clipBoardHidden"
    #clipboard
    aria-hidden="true"
    [(ngModel)]="input"
    name="input"
    (paste)="formatearData($event)"
    (blur)="blurInput()"
    autocomplete="off"
  />
</div>
