import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { _warn } from '@shared/aux_helper_environment';
import { _cloneDeep } from '@shared/aux_helper_functions';
import {
  InsightsListModel,
  InsightSuscriptionModel,
  InsightTypesModel,
} from 'app/admin/master/insights/insights-store/insights-store.model';
import { InsightsService } from 'app/admin/master/insights/insights.service';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';

@Component({
  selector: 'prisma-context-menu-insights',
  templateUrl: './context-menu-insights.component.html',
  styleUrls: ['./context-menu-insights.component.scss'],
})
export class ContextMenuInsightsComponent implements OnInit, OnChanges {
  /**
   * Data con el insight seleccionado
   * @param insight
   */
  @Input() insight: InsightsListModel;
  /**
   * @param alreadySubscripted = true entonces muestro icono para desuscribir | false: muestro icono para subscribir
   */
  @Input() alreadySubscripted: boolean;
  /**
   * //listado de opciones de tipos para poder agregar a los nombres de los botones de type
   * @param typeList
   */
  @Input() typeList = null;

  /**
   * devuelve true o false si se completo la subscripcion correctamente
   * @param subscriptionDone = boolean
   */
  @Output() subscriptionDone = new EventEmitter<boolean>();

  _alreadySubscripted: boolean;
  existInsight = false;
  subscriptionType = null;
  subscriptionEntities = null;
  subscriptionInsight = null;
  $isLoading = false;
  typeList$ = [];

  constructor(private insightService: InsightsService, private translate: IanTranslateService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    // alreadySubscripted con data decide el icono a utilizar
    if (changes['alreadySubscripted'] && this.alreadySubscripted !== null) this.setWhichIconUse(this.alreadySubscripted);

    // insight con data genera las opciones del menu contextual
    if (changes['insight']) {
      if (this.insight) {
        const _insight = _cloneDeep(this.insight);

        // sin alreadySubscripted se setea el estado que tiene el insight
        if (this.alreadySubscripted !== null) this.setWhichIconUse(_insight.isSubscribed);
        // sin listado desde componente que lo invoca
        if (!this.typeList || this.typeList.length === 0) {
          this.$isLoading = true;
          this.insightService.getTypes().subscribe((data: Array<InsightTypesModel>) => {
            this.init(data, _insight);
            this.$isLoading = false;
          });
        } else {
          this.init(this.typeList, _insight);
        }
      }
    }
  }

  init(typeList, _insight) {
    this.typeList$ = typeList;
    this.generateSubscriptionEntities(_insight);
    this.showComponent();
  }

  // mostrar html porque tenemos data y opciones
  showComponent() {
    this.existInsight = true;
  }

  /**
   *
   * @param chooseIcon cambiar de icono.
   */
  setWhichIconUse(chooseIcon) {
    this._alreadySubscripted = chooseIcon;
  }

  /**
   *
   * Genero las opciones del menu contextual en base a los datos que tiene el insight
   * tenemos 3 tipos de subscripciones, de tipo, de entidad (puede generar multiples botones), subscripcion al insight en si
   * si elijo la primera subscribe a todos los insight de ese tipo, la segunda a los de esa entidad, la tercer al que actualmente se esta accionando
   * @param _insight objeto con la data del insight
   *
   */
  generateSubscriptionEntities(_insight: InsightsListModel) {
    // creo las diferentes opciones en base a la data
    //opt 1 - subscripcion a tipo de insight
    const _typeName = this.getTypeName(_insight);
    this.subscriptionType =
      _insight?.type && _typeName
        ? this.createSubscriptionEntity(
            { type: _insight.type },
            'COMP.INSIGHT.CONTEXT_MENU.TYPE',
            _typeName,
            _insight.hasAllSuscriptionTypes
          )
        : null;
    //opt 2 puede tener multiples entidades o nulas, se separan para hacer una opcion cada una - subscripcion a entidad
    this.subscriptionEntities = this.createSubscriptionEntities(_insight);
    //opt 3 subscripcion a insight actual
    this.subscriptionInsight = this.createSubscriptionInsightForRow(_insight);
  }

  createSubscriptionEntity(params, traduction = null, val2 = '', suscribedToAllOfHisTypeOrEntity = true) {
    if (!params) _warn('[createSubscriptionEntity] sin parametros para generar subscripción');
    const actionUnsubscribe = this._alreadySubscripted && suscribedToAllOfHisTypeOrEntity;
    const _val1 = actionUnsubscribe
      ? this.translate.instant('COMP.INSIGHT.CONTEXT_MENU.UNSUBSCRIBE')
      : this.translate.instant('COMP.INSIGHT.CONTEXT_MENU.SUBSCRIBE');
    // si no viene traduccion entonces solo utilizo los valores ya procesados para agregar en la traduccion principal CONCATENATE
    const _val2 = traduction ? this.translate.instant(traduction, val2) : val2;
    const traductionVariables = [_val1, _val2];
    const btn_description = this.translate.instant('COMP.INSIGHT.CONTEXT_MENU.CONCATENATE_MESSAGE', traductionVariables);
    return new SubscriptionEntityModel(btn_description, params, actionUnsubscribe);
  }

  createSubscriptionEntities(_insight: InsightsListModel): Array<SubscriptionEntityModel> {
    let _subscription = [];

    if (_insight.category)
      _subscription.push(
        this.createSubscriptionEntity(
          { category: _insight.category },
          'COMP.INSIGHT.CONTEXT_MENU.CATEGORY',
          _insight.categoryName,
          _insight.hasAllSuscriptionCategories
        )
      );

    if (_insight.store)
      _subscription.push(
        this.createSubscriptionEntity(
          { store: _insight.store },
          'COMP.INSIGHT.CONTEXT_MENU.STORE',
          _insight.storeName,
          _insight.hasAllSuscriptionStore
        )
      );

    if (_insight.storeTag)
      _subscription.push(
        this.createSubscriptionEntity(
          { storeTag: _insight.storeTag },
          'COMP.INSIGHT.CONTEXT_MENU.STORETAG',
          _insight.storeTagName,
          _insight.hasAllSuscriptionStoreTag
        )
      );

    return _subscription;
  }

  /**
   * Genera traducciones concatenadas con los entities que tenga, si no tiene entidades no se crea este boton ya que existiria el de tipo. Debe tener tipo tambien.
   * @param _insight
   * @returns devuelve el objeto con la traduccion y los parametros para el servicio
   */
  createSubscriptionInsightForRow(_insight: InsightsListModel) {
    let _subscriptionVals = '';
    let hasEntity = false;
    let hasType = false;
    const allParams: InsightSuscriptionModel = _insight;

    if (_insight.type) {
      hasType = true;
      const _typeName = this.getTypeName(_insight);
      if (_typeName) _subscriptionVals += this.translate.instant('COMP.INSIGHT.CONTEXT_MENU.TYPE', _typeName);
    }

    if (_insight.category) {
      hasEntity = true;
      _subscriptionVals += this.translate.instant('COMP.INSIGHT.CONTEXT_MENU.CATEGORY', _insight.categoryName);
    }

    if (_insight.store) {
      hasEntity = true;
      _subscriptionVals += this.translate.instant('COMP.INSIGHT.CONTEXT_MENU.STORE', _insight.storeName);
    }

    if (_insight.storeTag) {
      hasEntity = true;
      _subscriptionVals += this.translate.instant('COMP.INSIGHT.CONTEXT_MENU.STORETAG', _insight.storeTagName);
    }

    return hasEntity && hasType ? this.createSubscriptionEntity(allParams, null, _subscriptionVals) : null;
  }

  getTypeName(_insight) {
    let typeName = null;
    if (_insight?.type) {
      const _typeOption = this.typeList$.find(t => t.id === _insight.type);
      typeName = _typeOption !== undefined ? _typeOption.type : '';
      if (typeName === '') _warn('[generateSubscriptionEntities] no se encontro el nombre para el tipo de subscripción');
    }
    return typeName;
  }

  changeSubscription(params, actionUnsubscribe) {
    let service;
    if (this._alreadySubscripted === true && actionUnsubscribe === true) service = this.insightService.unsubscribe(params);
    if (this._alreadySubscripted === false) service = this.insightService.subscription(params);
    if (this._alreadySubscripted === null || this._alreadySubscripted === undefined) {
      _warn('[try to suscribe] no se encuentra definida la suscripción para este item, valor recibido: ' + this._alreadySubscripted);
      service = this.insightService.subscription(params);
    }
    service.subscribe(
      data => this.subscriptionDone.emit(true),
      error => this.subscriptionDone.emit(false)
    );
  }
}

export class SubscriptionEntityModel {
  btn_description: string;
  params: InsightSuscriptionModel;
  conditionalAction: boolean;

  constructor(_btn_description: string, _paramas: InsightSuscriptionModel, _conditionalAction: boolean) {
    this.btn_description = _btn_description;
    this.params = _paramas;
    this.conditionalAction = _conditionalAction;
  }
}
