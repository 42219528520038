import { _isFeDev, _log } from '@shared/aux_helper_environment';
import { _objToHash, _roundDec } from '@shared/aux_helper_functions';
import { _addProdPrecalcDataArrayProdsMinMax, _checkProdIsFiltered } from '../../static_common/spaces_aux_calc_prod_data';
import { _auxReduceSingleProdDataStoresInfo } from '../../static_common/spaces_aux_helpers';
import { FloorPlanRootModel, PlanogramsAnalyticsData } from '../stores/floorplans-editor_formstate_model';

/*
    Convierte la data de un producto de layoutAnalitica al modelo svgSimpleProd
    (basicamente le anexa la metadata procesada/reducida/promediada) para poder usarse en
    _auxReduceSingleProdDataStoresInfo y _addProdPrecalcDataArrayProdsMinMax
*/
const _FPA_layoutProd2SvgProd = (layoutProd, itemDataProd, itemDataPlanogram, $filterStores: (string | number)[], globalTotalItems?) => {
  let filterStores = $filterStores?.length > 0 ? $filterStores : null;

  if (layoutProd == null) {
    console.error('no layoutProd', layoutProd, itemDataProd);
    return null;
  }

  if (itemDataProd == null) {
    console.error('no metadata prod', layoutProd, itemDataProd);
    return null;
  }

  if (itemDataPlanogram == null) {
    console.error('no metadata planogram', layoutProd, itemDataProd);
    return null;
  }

  //Calculo del ratio de ventas (parcial/total)
  let salesRatio = globalTotalItems > 0 ? layoutProd?.calc_TotalItems / globalTotalItems : 1;

  if (salesRatio < 0) {
    console.error('salesRatio < 0', { layoutProd, itemDataProd, totalItems: globalTotalItems, salesRatio });
    salesRatio = 0;
  }

  if (salesRatio > 1) {
    console.error('salesRatio > 1', { layoutProd, itemDataProd, totalItems: globalTotalItems, salesRatio });
    salesRatio = 1;
  }

  return {
    ...layoutProd,
    itemData: _auxReduceSingleProdDataStoresInfo(itemDataProd, false, filterStores, salesRatio),
    itemDataPlanogram,
  };
};

/*
    Convierte la data de layoutAnalitica itemsData a un map para luego hacer búsquedas rápidas (por key/value)
*/
const _FPA_getMapItemsDataIndexed = (floorplansAnalyticsObj: any): Map<any, any> => {
  const rv = new Map();

  (floorplansAnalyticsObj?.itemsData || []).forEach(data => data?.unitOfMeasureId != null && rv.set(data.unitOfMeasureId, data));

  return rv;
};

const _FPA_getMapItemsDataPlanogramIndexed = (floorplansAnalyticsObj: any): Map<any, any> => {
  const rv = new Map();

  (floorplansAnalyticsObj?.planograms || []).forEach(planogram => {
    (planogram?.prods || []).forEach(prod => {
      rv.set(prod.id, planogram);
    });
  });

  return rv;
};

/*
   Devuelve totales calculados para un grupo de productos
*/
let __metadataProdsIndexedCache = null;
let __metadataPlanogramsIndexedCache = null;
let __FPA_getMetaDataTotalsCache = new Map();
const _FPA_getMetaDataTotals = (
  layoutProds,
  floorplansAnalyticsObj,
  filterStores: (string | number)[] = null,
  filterProds = null,
  unitOfMeasureIdsDataPerPlanogram: {} = null,
  useCache = true,
  maxCacheObjs = 256
) => {
  const timeStart = Date.now();

  const paramsHashCacheKey = useCache
    ? _objToHash({
        layoutProds: (layoutProds || []).map(prod => prod.unitOfMeasureId).toString(),
        filterStores: filterStores.toString(),
        filterProds,
        unitOfMeasureIdsDataPerPlanogram,
      })
    : null;

  const cacheRv =
    paramsHashCacheKey != null && layoutProds?.length > 0 && floorplansAnalyticsObj != null
      ? __FPA_getMetaDataTotalsCache.get(paramsHashCacheKey)
      : null;

  if (cacheRv != null) return cacheRv;

  //

  if (__metadataProdsIndexedCache == null) __metadataProdsIndexedCache = _FPA_getMapItemsDataIndexed(floorplansAnalyticsObj);
  if (__metadataPlanogramsIndexedCache == null)
    __metadataPlanogramsIndexedCache = _FPA_getMapItemsDataPlanogramIndexed(floorplansAnalyticsObj);

  const metadataProdsIndexed = __metadataProdsIndexedCache;
  const metadataPlanogramIndexed = __metadataPlanogramsIndexedCache;

  const prodsConverted = (layoutProds || [])
    .map(prod => {
      let totalProdItems = unitOfMeasureIdsDataPerPlanogram?.[prod.unitOfMeasureId]?.totalItems || undefined;

      return _FPA_layoutProd2SvgProd(
        prod,
        metadataProdsIndexed.get(prod.unitOfMeasureId),
        metadataPlanogramIndexed.get(prod.id),
        filterStores,
        totalProdItems
      );
    })
    .filter(e => e != null);

  const prodsConvertedAndFiltered = prodsConverted.filter(prod => !_checkProdIsFiltered(prod, filterProds));

  const totalsRv: any = _addProdPrecalcDataArrayProdsMinMax(prodsConvertedAndFiltered, false, true);

  totalsRv.__hasValidItemsDataRaw = floorplansAnalyticsObj != null && Array.isArray(floorplansAnalyticsObj?.itemsData);

  if (useCache && paramsHashCacheKey != null && totalsRv.__hasValidItemsDataRaw) {
    //Si el cache tiene más de n(maxCacheObjs) borra el último
    if (__FPA_getMetaDataTotalsCache.size >= maxCacheObjs) {
      let keyToDel = __FPA_getMetaDataTotalsCache.entries().next()?.value?.[0];
      if (keyToDel) __FPA_getMetaDataTotalsCache.delete(keyToDel);
    }
    __FPA_getMetaDataTotalsCache.set(paramsHashCacheKey, totalsRv);
  }

  if (false && _isFeDev()) {
    _log('[_FPA_getMetaDataTotals]', {
      layoutProds: layoutProds?.map?.(prod => String(prod.unitOfMeasureId)),
      filterStores,
      filterProds,
    });
  }

  const timeEnd = Date.now();

  if (true && _isFeDev() && layoutProds?.length) {
    _log([], '[_FPA_getMetaDataTotals] time/ms:', _roundDec(timeEnd - timeStart), 'prods:', layoutProds?.length);
  }

  return totalsRv;
};

//Cuando cambia los planogramas del layout hay que resetear toda la data pre-calculada como caches
const _FPA_clearAllCaches = () => {
  if (_isFeDev()) _log('[_FPA_clearAllCaches]');

  __metadataProdsIndexedCache = null;
  __metadataPlanogramsIndexedCache = null;
  __FPA_getMetaDataTotalsCache?.clear?.();
  __FPA_getMetaDataTotalsByPlanogramsCache?.clear?.();
};

let __FPA_getMetaDataTotalsByPlanogramsCache = new Map();
const _FPA_getMetaDataTotalsByPlanograms = (
  floorplanData: FloorPlanRootModel,
  floorplansAnalyticsObj: PlanogramsAnalyticsData,
  planogramIds: number | number[], //Puede ser uno o un array
  filterStores: (string | number)[] = null,
  filterProds = null,
  usecalculatedArea = true,
  useCache = true,
  maxCacheObjs = 512
) => {
  let _planogramIds: String[] = (Array.isArray(planogramIds) ? planogramIds : [planogramIds]).map(p => String(p));

  if (true) _planogramIds = [...new Set(_planogramIds)]; //Unique;

  if (true && _isFeDev()) _log('[_FPA_getMetaDataTotalsByPlanograms]', [_planogramIds]);

  let paramsCacheKey = useCache
    ? _objToHash({
        planogramIds: planogramIds.toString(),
        filterStores: filterStores.toString(),
        filterProds,
        usecalculatedArea,
      })
    : null;

  let cache = __FPA_getMetaDataTotalsByPlanogramsCache.get(paramsCacheKey);
  if (useCache && cache != null) return cache;

  const rv = {
    planogramsData: {},
    total: {},
  };

  let __totalProds = [];
  let __totalPlanogramsArea = 0;
  let __levelIndex = null;
  let __unitOfMeasureIdsDataPerLevelPlanogram = {};

  (floorplanData?.levels || []).forEach((level, i) => {
    (level.floorPlanograms || []).forEach(planogram => {
      const planogramAnalyticsObj = (floorplansAnalyticsObj?.planograms || []).find(p => String(p?.id) === String(planogram?.planogramId));

      const planogramProds = planogramAnalyticsObj?.prods || [];

      const levelKey = level?.id || 'null';

      planogramProds.forEach(prod => {
        const unitOfMeasureId = prod.unitOfMeasureId;
        const totalWidth = prod.calc_TotalWidth || 0;
        const totalItems = prod.calc_TotalItems || 0;

        if (__unitOfMeasureIdsDataPerLevelPlanogram[levelKey] == null) {
          __unitOfMeasureIdsDataPerLevelPlanogram[levelKey] = {};
        }

        if (__unitOfMeasureIdsDataPerLevelPlanogram[levelKey][unitOfMeasureId] == null) {
          __unitOfMeasureIdsDataPerLevelPlanogram[levelKey][unitOfMeasureId] = { totalWidth, totalItems };
        } else {
          __unitOfMeasureIdsDataPerLevelPlanogram[levelKey][unitOfMeasureId].totalWidth += totalWidth;
          __unitOfMeasureIdsDataPerLevelPlanogram[levelKey][unitOfMeasureId].totalItems += totalItems;
        }
      });
    });
  });

  (floorplanData?.levels || []).forEach((level, i) => {
    (level.floorPlanograms || []).forEach(planogram => {
      if (_planogramIds?.length > 0 && !_planogramIds.includes(String(planogram?.id))) return;

      const planogramAnalyticsObj = (floorplansAnalyticsObj?.planograms || []).find(p => String(p?.id) === String(planogram?.planogramId));
      const planogramProds = planogramAnalyticsObj?.prods || [];

      let planogramAreaWithShelfs = planogramAnalyticsObj?.planogramArea || 0;
      let planogramAreaOnlyBase = (planogramAnalyticsObj?.width || 0) * (planogramAnalyticsObj?.depth || 0);

      const useBaseArea = true;

      __totalPlanogramsArea += useBaseArea ? planogramAreaOnlyBase : planogramAreaWithShelfs;
      __totalProds = [...__totalProds, ...planogramProds];

      const planogramData = _FPA_getMetaDataTotals(
        planogramProds,
        floorplansAnalyticsObj,
        filterStores,
        filterProds,
        __unitOfMeasureIdsDataPerLevelPlanogram?.[level?.id]
      );

      if (planogramData?.totalItems > 0 && __levelIndex == null) __levelIndex = i;

      if (planogramData?.totalItems > 0 || filterProds == null) {
        rv.planogramsData[planogram?.id] = { ...planogramData, planogramArea: planogramAnalyticsObj?.planogramArea || 0 };
      }
    });
  });

  const getTotalArea = () => {
    let hardArea = (floorplanData?.levels?.[__levelIndex]?.sellingAreaSpace || 0) * 1000;

    let calculatedArea = __totalPlanogramsArea;

    return usecalculatedArea ? calculatedArea : hardArea;
  };

  rv.total = {
    ..._FPA_getMetaDataTotals(__totalProds, floorplansAnalyticsObj, filterStores, filterProds, undefined),
    __totalPlanogramsArea: getTotalArea(),
  };

  if (__FPA_getMetaDataTotalsByPlanogramsCache.size >= maxCacheObjs) {
    let keyToDel = __FPA_getMetaDataTotalsByPlanogramsCache.entries().next()?.value?.[0];
    if (keyToDel) __FPA_getMetaDataTotalsByPlanogramsCache.delete(keyToDel);
  }

  if (useCache) __FPA_getMetaDataTotalsByPlanogramsCache.set(paramsCacheKey, rv);

  return rv;
};

const _FPA_getMetaDataTotalsByLevel = (
  floorplanData: FloorPlanRootModel,
  floorplansAnalyticsObj: PlanogramsAnalyticsData,
  levelId: number = null,
  filterStores: (string | number)[] = null,
  filterProds = null,
  usecalculatedArea = true
) => {
  let planogramsTmp = (floorplanData?.levels || []).find(l => l?.id != null && String(l?.id) === String(levelId))?.floorPlanograms;

  let planogramsInLevelIds = planogramsTmp.map?.(p => p?.id)?.filter(p => p != null) || [];

  let rv = _FPA_getMetaDataTotalsByPlanograms(
    floorplanData,
    floorplansAnalyticsObj,
    planogramsInLevelIds,
    filterStores,
    filterProds,
    usecalculatedArea
  );

  return rv.total;
};

export { _FPA_getMetaDataTotals, _FPA_getMetaDataTotalsByLevel, _FPA_getMetaDataTotalsByPlanograms, _FPA_clearAllCaches };
