import { _cloneDeep, _krange } from '@shared/aux_helper_functions';
import { _log, _isDev } from '@shared/aux_helper_environment';
import {
  _calcSizeProd,
  _round,
  _getTmpProdId,
  _getTmpModuleId,
  _getTmpShelfId,
  _getTmpFloatfId,
  _clonePlanogram,
} from './static_common/spaces_aux_helpers';
import seedrandom from 'seedrandom';
import { dummyPlanogramsTab } from './planograms-editor/general-tab/store/dummyPlanogramsTab';

let rng = seedrandom('spc.5');

function _shuffle(array) {
  return array.sort(() => rng() - 0.5);
}

function _randomInteger(min, max) {
  return Math.floor(rng() * (max - min + 1)) + min;
}

function _getGeneralDataDummy() {
  return dummyPlanogramsTab.getPlanogramById;
}

const TEST_PRICE_ZERO = false && _isDev;

const metadataDW = () => {
  let salesUnits = 30 * _round(_krange(rng() * rng(), 0, 1, 10, 30), 0); //TODO: checkear si viene mensual o diaria
  let stockUnits = (salesUnits / 30) * _round(_krange(rng(), 0, 1, 5, 30), 0);

  let cost = _round(_krange(rng(), 0, 1, 40, 120), 2);
  let margin = _round(_krange(rng(), 0, 1, 10, 60), 2);
  let price = _round(cost + margin, 0);

  //2v5gkb / para testear con precio 0
  if (TEST_PRICE_ZERO) price = 0;

  let daysInStock = stockUnits / (salesUnits / 30);
  let daysOutOfStock = stockUnits / (salesUnits / 30);

  let _catSubCatPairs = [
    { c: 100, s: 202 },
    { c: 38, s: 187 },
    { c: 38, s: 186 },
  ];

  let _catSubCatPairsShuffle = _shuffle(_catSubCatPairs)[0];

  //Testea productos sin store
  if (!true && Math.random() > 0.5) return [];

  return {
    ean: _round(_krange(rng(), 0, 1, 10000000, 100000000), 0),
    code: _round(_krange(rng(), 0, 1, 100000000, 1000000000), 0),
    unMed: 1,
    brand: 'Coca Cola',
    categoryName: 'Bebidas',
    canRotate: true,
    canRotateY: false,
    maxStacks: null,
    canLayDown: false,

    itemId: _round(_krange(rng(), 0, 1, 10000000, 100000000), 0),
    unitOfMeasureId: _round(_krange(rng(), 0, 1, 10000000, 100000000), 0),

    categoryId: _catSubCatPairsShuffle.c,
    subCategoryId: _catSubCatPairsShuffle.s,

    tags: [
      {
        tagId: 4,
        includedValues: [_shuffle([4, 2])[0]],
      },
      {
        tagId: 2,
        includedValues: [_shuffle([9, 10, 11, 12])[0]],
      },
      {
        tagId: 5,
        includedValues: [_shuffle([19, 20])[0]],
      },
      {
        tagId: 3,
        // includedValues: [_shuffle([5, 6, 7, 8])[0]],
        // para testear 2n45m8
        includedValues: [5],
      },
      {
        tagId: 1,
        // includedValues: [_shuffle([1, 15, 16, 17, 18])[0]],
        includedValues: [],
      },
    ],

    storesInfo: [
      {
        units: salesUnits,
        sales: salesUnits * price, //TODO: checkear si viene mensual o diaria
        margin: margin * salesUnits,
        price: price,
        daysOutOfStock: daysOutOfStock,
        cost: cost,
        stockUnits: stockUnits,
        daysInStock: daysInStock,

        /**/
        storeId: 35,
        clusterId: 2,
        gmros: 0,
        gmroi: null,
        isListed: false,
        hasExhibition: false,
        inventory: 0,
        inventoryUnits: 0,
        inventoryDays: null,
        currentInventory: null,
        currentInventoryUnits: null,
        regularPrice: null,
        storeName: 'Tienda A',
      },
      {
        units: salesUnits * 0.8,
        price: price * 0.8,
        sales: salesUnits * 0.8 * (price * 0.8), //TODO: checkear si viene mensual o diaria
        margin: margin * salesUnits * 0.8,
        daysOutOfStock: daysOutOfStock,
        cost: cost,
        stockUnits: stockUnits,
        daysInStock: daysInStock,
        /**/
        storeId: 36,
        clusterId: 2,
        gmros: 0,
        gmroi: null,
        isListed: false,
        hasExhibition: false,
        inventory: 0,
        inventoryUnits: 0,
        inventoryDays: null,
        currentInventory: null,
        currentInventoryUnits: null,
        regularPrice: null,
        storeName: 'Tienda B',
      },
    ],
  };
};

const spcDummyProdsPopMetaData = () => {
  return {
    lays: {
      itemData: {
        ...metadataDW(),
        size: { w: 14, h: 14 * 1.355 },
        imagePlanogramFront: './assets/tmp-dev/spaces/lays.png',
        itemId: 2001,
        unitOfMeasureId: 2001,
        name: 'Lays Clasicas 270g.',
      },
    },
    laysb: {
      itemData: {
        ...metadataDW(),
        size: { w: 14, h: 14 * 1.355 },
        imagePlanogramFront: './assets/tmp-dev/spaces/laysb.png',
        itemId: 2001,
        unitOfMeasureId: 2001,
        name: 'Lays Ketchup 270g.',
      },
    },
  };
};

const spcDummyProdsMetaData = () => {
  return {
    coca: {
      itemData: {
        ...metadataDW(),
        size: { w: 12 * 0.55, h: 12 },
        imagePlanogramFront: './assets/tmp-dev/spaces/coca2.png',
        imagePlanogramTop: './assets/tmp-dev/spaces/coca2b.png',
        canLayDown: true,
        canRotateY: false,
        itemId: 1001,
        unitOfMeasureId: 1001,
        maxStacks: 4,
        stackable: true,
        name: 'Coca Cola Lata',
      },
    },
    cepita: {
      itemData: {
        ...metadataDW(),
        size: { w: 25 * 0.73, h: 25, d: 25 * 0.33 },
        imagePlanogramFront: './assets/tmp-dev/spaces/cepitaFont.png',
        imagePlanogramLeft: './assets/tmp-dev/spaces/cepitaLeft.png',
        canLayDown: true,
        canRotateY: true,
        itemId: 10050,
        unitOfMeasureId: 10050,
        maxStacks: 0,
        stackable: true,
        name: 'Cepita',
        _rotateY: true,
      },
    },
    shwepps: {
      itemData: {
        ...metadataDW(),
        size: { w: 25 * 0.3, h: 25 },
        imagePlanogramFront: './assets/tmp-dev/spaces/7730197000325.png',
        itemId: 1010,
        unitOfMeasureId: 1010,
        name: 'Schweppes',
        maxStacks: null,
      },
    },
    gatorade: {
      itemData: {
        ...metadataDW(),
        size: { w: 18 * 0.31, h: 18 },
        imagePlanogramFront: './assets/tmp-dev/spaces/7792170042258.png',
        itemId: 1003,
        unitOfMeasureId: 1003,
        maxStacks: 2,
        name: 'Gatorade Ananá',
      },
    },
    salus: {
      itemData: {
        ...metadataDW(),
        size: { w: 24 * 0.25, h: 24 },
        imagePlanogramFront: './assets/tmp-dev/spaces/7730400002351.png',
        itemId: 1002,
        unitOfMeasureId: 1002,
        name: 'Salus Frute',
      },
    },
    smallcoca: {
      itemData: {
        ...metadataDW(),
        size: { w: 17 * 0.29, h: 17 },
        imagePlanogramFront: './assets/tmp-dev/spaces/7730197301958.png',
        itemId: 1004,
        unitOfMeasureId: 1004,
        name: 'Coca Cola Test',
        stackable: true,
        maxstacks: 0,
      },
    },
    pasotoros: {
      itemData: {
        ...metadataDW(),
        size: { w: 18 * 0.29, h: 18 },
        imagePlanogramFront: './assets/tmp-dev/spaces/77304971.png',
        itemId: 1005,
        unitOfMeasureId: 1005,
        name: 'Paso de los toros',
        maxstacks: null,
      },
    },
    bigcoca: {
      itemData: {
        ...metadataDW(),
        size: { w: 26.5 * 0.3, h: 26.5 },
        imagePlanogramFront: './assets/tmp-dev/spaces/7730197000202.png',
        itemId: 1006,
        unitOfMeasureId: 1006,
        name: 'Coca Cola 2l.',
      },
    },
    teem: {
      itemData: {
        ...metadataDW(),
        size: { w: 27 * 0.33, h: 27 },
        imagePlanogramFront: './assets/tmp-dev/spaces/7734284001516.png',
        itemId: 1007,
        unitOfMeasureId: 1007,
        name: 'Teen',
      },
    },
    pasonaranja: {
      itemData: {
        ...metadataDW(),
        size: { w: 26 * 0.3, h: 26 },
        imagePlanogramFront: './assets/tmp-dev/spaces/7734284001608.png',
        itemId: 1008,
        unitOfMeasureId: 1008,
        name: 'Paso de los Toros Naranja Ligth 1.5lt',
      },
    },
    hdoso: {
      itemData: {
        ...metadataDW(),
        size: { w: 24 * 0.26, h: 24 },
        imagePlanogramFront: './assets/tmp-dev/spaces/7791813421238.png',
        itemId: 1009,
        unitOfMeasureId: 1009,
        name: 'H2o',
      },
    },
    sprite: {
      itemData: {
        ...metadataDW(),
        size: { w: 26 * 0.28, h: 26 },
        imagePlanogramFront: './assets/tmp-dev/spaces/7730197001117.png',
        itemId: 1011,
        unitOfMeasureId: 1011,
        name: 'Sprite',
      },
    },
  };
};

export const _getShufleSpcDummyProdsGroup = () => {
  let sufleProd = [];
  let sufleProd2 = [];

  Object.entries(spcDummyProdsMetaData()).forEach(([key, value]) => {
    sufleProd.push(value);
  });

  _shuffle(sufleProd).forEach(value => {
    let cant = _randomInteger(2, 4);
    for (let i = 0; i < cant; i++) sufleProd2.push(value);
  });

  return [...sufleProd2, ...sufleProd2, ...sufleProd2];
};

export const _getShufleSpcDummyProds = (shufle = true) => {
  let sufleProd = [];

  Object.entries(spcDummyProdsMetaData()).forEach(([key, value]) => {
    sufleProd.push(value);
  });

  sufleProd = sufleProd.map(obj => {
    obj.itemData.size = {
      w: _round(obj.itemData.size.w),
      h: _round(obj.itemData.size.h),
      d: _round(obj.itemData.size.d || obj.itemData.size.w),
    };
    return _clonePlanogram(obj);
  });

  return shufle ? _shuffle(sufleProd) : sufleProd;
};

export const _getShufleSpcDummyPops = (shufle = true) => {
  let sufleProd = [];

  Object.entries(spcDummyProdsMetaData()).forEach(([key, value]) => {
    sufleProd.push(value);
  });

  sufleProd = sufleProd.map((obj, i) => {
    obj.itemData.size = {
      w: _round(obj.itemData.size.w),
      h: _round(obj.itemData.size.h),
      d: _round(obj.itemData.size.d || obj.itemData.size.w),
    };

    let pop = {
      id: 'FLOAT-10717411',
      type: 'float-pop-impulse-strip-simple',
      name: 'Tira de impulso ' + (i + 1),
      image: './assets/images/spc_assets/tira_de_impulso.png',
      prods: [obj, obj, obj],
    };

    return _cloneDeep(pop);
  });

  let pops = shufle ? _shuffle(sufleProd) : sufleProd;

  return pops;
};

const _randomFillShelf = (w, h, type) => {
  let rv = [];

  let prods = type !== 'normalShelf-level' ? _getShufleSpcDummyProdsGroup() : _getShufleSpcDummyProds();

  let offset = 0.5;
  let xAcc = offset;

  for (let i = 0; i < prods.length; i++) {
    const prod = _cloneDeep(prods[i]);
    if (type === 'normalShelf-level') prod.facings = _randomInteger(2, 4);
    prod.id = _getTmpProdId();
    xAcc += offset;
    prod.size = _calcSizeProd(prod);
    prod.position = { x: _round(xAcc), y: _round(h - prod.size.h) };
    prod.rotateY = prod.itemData._rotateY === true;
    xAcc += _round(prod.size.w);
    if (xAcc > w - offset) break;
    rv.push(prod);
  }

  return rv;
};

const _randomFillModule = (w, $cant = null, fill = true) => {
  let rv = [];

  let cant = $cant || _randomInteger(4, 5);
  let h = _randomInteger(30, 50);

  for (let i = 0; i < cant; i++) {
    let shelfType = i !== 0 ? 'normalShelf-level' : 'simplePeg-level';
    if (!true && i === 1) shelfType = 'pitShelf-level';
    rv.push({
      type: shelfType,
      height: _round(i === 0 ? h + 15 : h),
      id: _getTmpShelfId(),
      order: i,
      prods: fill && i !== 1 ? _randomFillShelf(w, i === 0 ? h + 15 : h, shelfType) : [],
      marginLeft: 10, //DES-1576
      marginRight: 10, //DES-1576
    });
  }

  return rv;
};

const _randomModules = (cant, shelfs = null, fillWithProds = true, $w = null) => {
  let rv = [];

  cant = cant || _randomInteger(4, 5);
  let type = 'normalShelf-module';

  for (let i = 0; i < cant; i++) {
    let w = $w || _randomInteger(10, 24) * 10;
    rv.push({
      width: w,
      type: type,
      depth: 60,
      order: i,
      id: _getTmpModuleId(),
      shelfs: _randomFillModule(w, shelfs, fillWithProds),
      horizontalGuidesMeasure: 40,
    });
  }

  return rv;
};

const OBJ_DATA_DUMMY_EMPTY = () => {
  return {
    planogramName: 'Conveniencia Premium Heladera Empty',
    id: -1,
    modules: _randomModules(1, 3, false),
    floats: [],
  };
};

const OBJ_DATA_DUMMY_DEV = () => {
  return !true
    ? OBJ_DATA_DUMMY_EMPTY
    : {
        ..._getGeneralDataDummy(),
        planogramName: 'Conveniencia Premium Heladera - DEV',
        id: -1,
        modules: _randomModules(3, 4),
        floats: getDummyFloats(),
        // modules: _randomModules(2, 1, true, 100),
      };
};

const OBJ_DATA_DUMMY_DEV_SIMPLE = () => {
  return !true
    ? OBJ_DATA_DUMMY_EMPTY
    : {
        ..._getGeneralDataDummy(),
        planogramName: 'Conveniencia Premium Heladera - DEV',
        id: -1,
        modules: _randomModules(1),
        floats: getDummyFloats(),
        // modules: _randomModules(2, 1, true, 100),
      };
};

const OBJ_DATA_DUMMY1 = () => {
  return {
    ..._getGeneralDataDummy(),
    planogramName: 'Sodas Demo',
    id: -1,
    modules: _randomModules(4),
    floats: getDummyFloats(),
  };
};

const OBJ_DATA_DUMMY_DEV_TEST = () => {
  return !true
    ? OBJ_DATA_DUMMY_EMPTY
    : {
        ..._getGeneralDataDummy(),
        planogramName: 'Conveniencia Base Heladera - DEV',
        id: 103,
        modules: [
          {
            width: 220,
            type: 'normalShelf-module',
            depth: 60,
            order: 0,
            id: 'MOD-19835159',
            shelfs: [
              {
                id: 'MOD-16046815',
                height: 100,
                order: 0,
                type: 'normalShelf-level',
                width: 220,
                prods: [
                  {
                    id: 'PRD-31993715',
                    isList: true,
                    itemData: {
                      ...metadataDW(),
                      size: {
                        w: 6.6,
                        h: 12,
                        d: 6.6,
                      },
                      imagePlanogramFront: './assets/tmp-dev/spaces/coca2.png',
                      imagePlanogramTop: './assets/tmp-dev/spaces/coca2b.png',
                      canLayDown: true,
                      itemId: 1001,
                      unitOfMeasureId: 1001,
                      maxStacks: 3,
                      name: 'Coca Cola',
                    },
                    size: {
                      w: 6.6,
                      h: 36,
                    },
                    order: 0,
                    stacksLyingDown: false,
                    stacks: 3,
                    position: {
                      x: 0.5,
                      y: 64,
                    },
                  },
                ],
                _position: {
                  x: 3.5,
                  y: 3.5,
                },
                _size: {
                  w: 220,
                  h: 100,
                },
              },
              {
                id: 'MOD-518062512',
                height: 100,
                order: 0,
                type: 'pitShelf-level',
                width: 220,
                prods: [
                  {
                    id: 'PRD-582060312',
                    isList: true,
                    itemData: {
                      ...metadataDW(),
                      size: {
                        w: 50,
                        h: 20,
                        d: 6.6,
                      },
                      imagePlanogramFront: './assets/tmp-dev/spaces/coca2c.png',
                      imagePlanogramTop: './assets/tmp-dev/spaces/coca2b.png',
                      canLayDown: true,
                      itemId: 2001,
                      unitOfMeasureId: 2001,
                      maxStacks: 3,
                      name: 'Coca Cola',
                    },
                    size: {
                      w: 6.6,
                      h: 12,
                    },
                    position: {
                      x: 111.35,
                      y: 41.02,
                    },
                    order: 0,
                  },
                ],
                _position: {
                  x: 3.5,
                  y: 3.5,
                },
                _size: {
                  w: 220,
                  h: 100,
                },
              },
              {
                id: 'MOD-51806251',
                height: 100,
                order: 0,
                type: 'simplePeg-level',
                width: 220,
                prods: [
                  {
                    id: 'PRD-58206031',
                    isList: true,
                    itemData: {
                      ...metadataDW(),
                      size: {
                        w: 50,
                        h: 20,
                        d: 6.6,
                      },
                      imagePlanogramFront: './assets/tmp-dev/spaces/coca2c.png',
                      imagePlanogramTop: './assets/tmp-dev/spaces/coca2b.png',
                      canLayDown: true,
                      itemId: 3001,
                      unitOfMeasureId: 3001,
                      maxStacks: 3,
                      name: 'Coca Cola',
                    },
                    size: {
                      w: 6.6,
                      h: 12,
                    },
                    position: {
                      x: 111.35,
                      y: 41.02,
                    },
                    order: 0,
                  },
                ],
                _position: {
                  x: 3.5,
                  y: 3.5,
                },
                _size: {
                  w: 220,
                  h: 100,
                },
              },
            ],
            offsetShelf: 2.5,
            offsetBox: 3.5,
            boxData: {
              size: {
                w: 227,
                h: 107,
              },
              position: {
                x: 0,
                y: 0,
              },
              fill: '#d4d4d4',
            },
            bottomData: {
              padding: 6,
              size: {
                w: 215,
                h: 4,
              },
              position: {
                x: 6,
                y: 107,
              },
              fill: '#a4a4a4',
            },
            _size: {
              w: 227,
              h: 111,
            },
            _position: {
              x: 40,
              y: 40,
            },
          },
        ],
        floats: getDummyFloats(),
      };
};

let timeLineFake = null;

export const getTimeLineFake = () => {
  return timeLineFake;
};

const _distortDate = pog => {
  //Resta X semanas
  let weeksToSubstract = _round(_krange(Math.random(), 0, 1, 1, 3), 1);
  let dateOffset = 24 * 60 * 60 * 1000 * weeksToSubstract * 7; //n weeks
  pog.publishedDate = new Date(pog.publishedDate - dateOffset);
  return pog;
};

const _distortPogStruct = pog => {
  let rnd = _krange(Math.random(), 0, 1, Math.random() < 0.4 ? 4.2 : 1.2, Math.random() < 0.4 ? 1.2 : 0.7);
  let rnd2 = _krange(Math.random(), 0, 1, Math.random() < 0.2 ? 0.4 : 0.9, Math.random() < 0.2 ? 1.5 : 1.1);

  pog.modules = (pog.modules || []).map(mod => {
    mod.shelfs = (mod.shelfs || []).map(shelf => {
      shelf.prods = (shelf.prods || [])
        .map(prod => {
          if (Math.random() > 0.99) return null;

          if (!prod || !prod.itemData || !prod.itemData.storesInfo) return prod;
          if (Math.random() < 0.2) return prod;

          prod.itemData.storesInfo = (prod.itemData.storesInfo || []).map(store => {
            store.sales = Math.abs(store.sales * 1 - rnd);
            store.units = Math.abs(store.units * 1 - rnd);
            store.margin = Math.abs(store.margin * 1 - rnd2);
            store.price *= 1;
            return store;
          });

          return prod;
        })
        .filter(Boolean);
      return shelf;
    });
    return mod;
  });
  return pog;
};

const distortPog = pog => {
  pog = _distortDate(pog);
  pog = _distortPogStruct(pog);
  return pog;
};

const fillTimeLine = pog => {
  if (timeLineFake && timeLineFake.length) return;

  timeLineFake = [];
  let lastTimeLine = pog;

  [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].forEach(i => {
    let tmpTimeLine = distortPog(_cloneDeep(lastTimeLine));
    lastTimeLine = _cloneDeep(tmpTimeLine);
    timeLineFake.push(tmpTimeLine);
  });

  if (!false) _log('[timeLineFake]', timeLineFake);
};

export const OBJ_DATA_DUMMY = () => {
  //   let rv: any = _isDev() ? OBJ_DATA_DUMMY_DEV() : OBJ_DATA_DUMMY1();
  let rv: any = OBJ_DATA_DUMMY_DEV_SIMPLE();

  rv.publishedDate = new Date();
  fillTimeLine(rv);

  return _clonePlanogram(rv);
};

export const getDummyFloats = () => {
  if (!true) return [];
  return [
    {
      id: _getTmpFloatfId(),
      prods: [dummyProdPop('lays'), dummyProdPop('laysb'), dummyProdPop('lays'), dummyProdPop('laysb')],
      position: { x: 171, y: 92 },
      type: 'float-pop-impulse-strip-simple',
    },
    {
      id: _getTmpFloatfId(),
      prods: [dummyProdPop('laysb'), dummyProdPop('lays'), dummyProdPop('laysb'), dummyProdPop('lays')],
      position: { x: 424, y: 84 },
      type: 'float-pop-impulse-strip-simple',
    },
  ];
};

export const dummyProdPop = (key = 'bigcoca') => {
  return {
    itemData: spcDummyProdsPopMetaData()[key].itemData,
    id: _getTmpProdId(),
  };
};

const _devTestImg = window.location.origin + '/assets/tmp-dev/spaces/Sekt-im-supermarkt2.jpg';

export const auditFakeData = [
  { storeId: 2, storeName: 'Alvarez Thomas I', img: _devTestImg, uploadDate: new Date(), userId: 5, userName: 'Damian Barleta' },
  { storeId: 3, storeName: 'Alvarez Thomas II', img: _devTestImg, uploadDate: new Date(), userId: 5, userName: 'Damian Barleta' },
  { storeId: 4, storeName: 'Alvarez Thomas III' },
];

export const commentFakeData = [
  {
    id: 1,
    commentDate: new Date(),
    userName: 'Damian Barleta',
    comment: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
  enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
  in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.`,
  },
  {
    id: 2,
    commentDate: new Date(),
    userName: 'Damian Barleta',
    comment: `Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
  enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
  in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
  proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  },
  {
    id: 3,
    commentDate: new Date(),
    userName: 'Damian Barleta',
    comment: `Incididunt ut labore et dolore magna aliqua. Ut
  enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
  in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
  proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  },
  {
    id: 4,
    commentDate: new Date(),
    userName: 'Damian Barleta',
    comment: `Duis aute irure dolor
  in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
  proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`,
  },
];
