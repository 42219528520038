import { PromotionActionTypeV2 } from '@prisma/components/generic-select-promotion-types/services/generic-select-promotion-types.service';
import { ActionsV2_StatusEnum } from '../actions-edit/store/actionsV2-FormStateModel';
import {
  PromotionActionAssortmentMethodType,
  PromotionActionDiscountType,
  PromotionActionFrequencyType,
} from './stores/actionsV2_list.model';

const ActionsV2ListWithoutMap = {
  items: [
    {
      id: 1,
      name: '3x2 Bon o Bon',
      code: '3x2bb',
      status: ActionsV2_StatusEnum.Current,
      type: PromotionActionTypeV2?.BuyAPayB,
      assortmentMethod: PromotionActionAssortmentMethodType.ActivateOnlyDuringPromotion,
      discountType: PromotionActionDiscountType.AbsoluteValue,
      scopeStore: 12,
      frecuency: PromotionActionFrequencyType.EveryDay,
      validFrom: '2021-11-25T03:00:00+00:00',
      validTo: '2021-11-28T03:00:00+00:00',
      publishEnable: true,
      promotionTypeId: 1,
      promotionPeriod: null,
    },
    {
      id: 2,
      name: '4x3 Bon o Bon',
      code: '4x3bb',
      status: ActionsV2_StatusEnum.Modified,
      type: PromotionActionTypeV2?.Discount,
      assortmentMethod: PromotionActionAssortmentMethodType.ActivateIndefinitely,
      discountType: PromotionActionDiscountType.Percentage,
      scopeStore: 16,
      frecuency: PromotionActionFrequencyType.CertainDaysOfTheWeek,
      validFrom: '2021-11-25T03:00:00+00:00',
      validTo: '2021-11-28T03:00:00+00:00',
      publishEnable: false,
      promotionTypeId: 1,
      promotionPeriod: null,
    },
    {
      id: 3,
      name: '2x1 Bon o Bon',
      code: '2x1bb',
      status: ActionsV2_StatusEnum.New,
      type: PromotionActionTypeV2?.BuyAPayB,
      assortmentMethod: PromotionActionAssortmentMethodType.ActivateOnlyDuringPromotion,
      discountType: PromotionActionDiscountType.AbsoluteValue,
      scopeStore: 1,
      frecuency: PromotionActionFrequencyType.EveryDay,
      validFrom: '2021-11-25T03:00:00+00:00',
      validTo: '2021-11-28T03:00:00+00:00',
      publishEnable: true,
      promotionTypeId: 1,
      promotionPeriod: null,
    },
    {
      id: 4,
      name: '6x2 Bon o Bon',
      code: '5x2bb',
      status: ActionsV2_StatusEnum.Cancelled,
      type: PromotionActionTypeV2?.BuyAPayB,
      assortmentMethod: PromotionActionAssortmentMethodType.ActivateOnlyDuringPromotion,
      discountType: PromotionActionDiscountType.AbsoluteValue,
      scopeStore: 44,
      frecuency: PromotionActionFrequencyType.EveryDay,
      validFrom: '2021-12-25T03:00:00+00:00',
      validTo: '2021-12-28T03:00:00+00:00',
      publishEnable: true,
      promotionTypeId: 1,
      promotionPeriod: null,
    },
  ],
  totalItemCount: 4,
  pageIndex: 0,
  pageSize: 10,
};

const filters = {
  promotionTypes: [1, 2],
  campaignsId: [1],
  query: '',
  status: [1, 5],
  alreadyToPublish: [],
  pageIndex: 0,
  pageSize: 10,
};

export const ActionsV2ListDummyData = {
  ActionsV2ListWithoutMap,
  filters,
};
