import { PagedList } from 'core/models/paged-list.model';
import { FormState } from 'core/form-plugin/public_api';
import { CheckBoxData } from '@prisma/components/check-box/check-box-v2/model/checkBoxModel';
import { PayloadToSendTags } from '@prisma/components/dinamic-tags/model/dinamicTagsModel';

export interface PlanogramsListModel {
  list: PagedList<PlanogramsListItemModel>;
  itemsForExport: Array<PlanogramsListItemModel>;
  excelUrl: string;
  codeToClone: string;
  highlightedRow: number | null;
  activeFilter: PlanogramsListFilterModel;
  checkBoxData: CheckBoxData;
}

export interface PlanogramsListFilterModel {
  query: string;
  pageIndex: number;
  pageSize: number;
  status: Array<PlanogramStatusType>;
  createdOn: Date;
  modifiedOn: Date;
  storeTags: Array<PayloadToSendTags>;
  storeId: number;
  itemId: number;
  categories: Array<number>;
  subcategories: Array<number>;
  itemTags: Array<PayloadToSendTags>;
  actionableType?: string;
  isDeleted?: boolean; //no se usa
  _isDraft?: boolean; //CAT-6
  planogramTemplateIds?: number[]; //CAT-6
  generateFor?: string[]; //CAT-6
  planogramType?: 'All' | 'Structure' | 'Planogram' | 'GenericSpace' | String; //DES-2806
}

export interface MasiveAction {
  id: string;
  value: string;
  allowed: boolean;
}

export interface MasiveActionOption {
  id: string;
  value: string;
  icon: string;
  tooltip?: string;
}
export interface MasiveActionOptions {
  [key: string]: MasiveActionOption[];
}

export interface PlanogramsListItemModel {
  id: number;
  name: string;
  code: string;
  type: string;
  lastModif: Date;
  categories: number;
  charge: number;
  front: number;
  linealMeters: number;
  layout: number;
  stores: number;
  sku: number;
  sales: number;
  var: number;
  structureOnly?: boolean;
  canDeleteByCurrentUser?: boolean;
}

export interface PlanogramsExportModel {
  code: string;
  planogramCode: string;
  description: string;
  name: string;
  minimumLoad: number;
  maxmumLoad: number;
}

export enum PlanogramStatusType {
  New = 'New',
  Current = 'Current',
  PendingEndorsement = 'PendingEndorsement',
  Endorsed = 'Endorsed',
  Deleting = 'Deleting',
  Draft = 'Draft',
}
