import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { _isDev } from '@shared/aux_helper_environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { getShowPermissionName } from 'app/app.config';
@Injectable({
  providedIn: 'root',
})
export class PermissionsManagerService {
  constructor(private permissionService: NgxPermissionsService, private translate: TranslateService) {}

  public langWithoutPermission = this.translate.instant('GENERIC_WITHOUT_PERMISSION', null);

  showPermission = getShowPermissionName();

  private generatePermission(module, typePermission) {
    const type = `_${typePermission}`;
    module = module.replace(type, '');
    const permission = `${module}${type}`;
    return permission;
  }

  private getMessageHasNotPermission(permission) {
    const hasPermission = !!this.getPermission(permission);
    if (!!hasPermission) return null; //si tengo permiso no tengo mensaje, devuelvo null
    return this.showPermission ? `${this.langWithoutPermission} | ${permission}` : this.langWithoutPermission;
  }

  public getPermission(permission) {
    return this.permissionService.getPermission(permission);
  }

  //se utiliza como flag y como mensaje. Si tengo mensaje no tengo permiso sino tengo permiso.
  public messageHasNotPermission(permissionModule, typePermission = null) {
    const permission = !!typePermission ? this.generatePermission(permissionModule, typePermission) : permissionModule;
    return this.getMessageHasNotPermission(permission);
  }
}

export const byPassPermissionsAccessModule = false && _isDev();

export enum TypePermission {
  view = 'view',
  save = 'save',
  publish = 'publish',
  delete = 'delete',
}
