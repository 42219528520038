import { SharedModule } from 'app/shared.module';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { FuseSharedModule } from '@fuse/shared.module';

import { QuickPanelComponent } from 'app/layout/components/quick-panel/quick-panel.component';

import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [QuickPanelComponent],
  imports: [
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    SharedModule,
    FuseSharedModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
  ],
  exports: [QuickPanelComponent],
  providers: [],
})
export class QuickPanelModule {}
