import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { _log } from '@shared/aux_helper_environment';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { BehaviorSubject } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { _cloneDeep } from '@shared/aux_helper_functions';
import { ColumnsType } from '../table-components/prisma-table.model';
import { IMV2_list_service } from 'app/item-master-v2/list/IMV2_list.service';
import { StoreV2_list_service } from 'app/admin/master/stores-v2/list/STOREV2_list.service';
import { EntityEnumType } from '@prisma/containers/model/scope.models';

export interface TypeCode {
  value: number;
  name: string;
  disabled?: boolean;
}
const _hiddenMainRow = (row): boolean => {
  return row.notfound;
};
@Component({
  selector: 'general-clipboard-list-modal',
  templateUrl: './general-clipboard-list-modal.component.html',
  styleUrls: ['./general-clipboard-list-modal.component.scss'],
})
export class ClipboardModalComponent implements OnInit, OnDestroy {
  @Input('typeList') typeList: EntityEnumType;
  @Input('checkUserStores') checkUserStores = false;
  @Input('title') title = 'M_SHOPPER.MISSIONS.CLIPBOARD_PRODUCT_TITLE';

  private _typeCodesItems: Array<TypeCode> = [
    { value: 1, name: this.translate.instant('M_SHOPPER.MISSIONS.INTERNAL_CODE_INPUT'), disabled: false },
    { value: 2, name: this.translate.instant('M_SHOPPER.MISSIONS.EXTERNAL_CODE_INPUT'), disabled: true },
  ];

  private _typeCodesStores: Array<TypeCode> = [this._typeCodesItems[0]];

  typeCodes: Array<TypeCode>;

  type = null;
  textarea = null;
  codes = new Array<any>();
  elementFounded = new Array<any>();
  maxCodes = false;
  columnsType: Array<ColumnsType> = [];
  ColumnsTypeNotFound: Array<ColumnsType> = [];
  hiddenMainRow = _hiddenMainRow;
  sinEndpointTerminado = true;

  config_normalizeAndUniqueCodes = true;
  config_normalizeCompareCodes = false;

  constructor(
    public dialogRef: MatDialogRef<ClipboardModalComponent>,
    private translate: IanTranslateService,
    private serviceItemMaster: IMV2_list_service,
    private serviceStoreMaster: StoreV2_list_service
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  addCodes() {
    const _elementFounded = this.elementFounded.map(d => d.id);
    const _data = _elementFounded && _elementFounded.length > 0 ? _elementFounded : [];
    if (_data) this.dialogRef.close(_data);
  }

  ngOnInit() {
    this.typeCodes = this._typeCodesStores;
    this.type = 1;
    this.setColumnsTypes();
  }

  setColumnsTypes() {
    if (this.typeList === 'items')
      this.columnsType = [
        {
          id: 0,
          name: '', // null para que no se muestre ni titulo ni se renderice el diseño del titulo en la vista
          type: [
            {
              column: 'code',
              markOnNull: true,
              type: 'text',
              class: '',
              headerTitle: this.type === 2 ? this.translate.instant('GLOBAL.externalCode') : this.translate.instant('GLOBAL.internalCode'),
              isChecked: true,
              isEditable: true,
            },
            {
              column: 'name',
              markOnNull: true,
              type: 'text',
              class: '',
              headerTitle: 'SKU',
              isChecked: true,
              isEditable: true,
            },
            {
              column: 'brand',
              markOnNull: true,
              type: 'text',
              class: '',
              headerTitle: this.translate.instant('GLOBAL.brand'),
              isChecked: false,
              isEditable: true,
              hidden: true,
            },
            {
              column: 'manufacturer',
              markOnNull: true,
              type: 'text',
              class: '',
              headerTitle: this.translate.instant('GLOBAL.manufacturer'),
              isChecked: false,
              isEditable: true,
              hidden: true,
            },
          ],
        },
      ];
    if (this.typeList === 'stores' || this.typeList === 'competitors')
      this.columnsType = [
        {
          id: 0,
          name: '', // null para que no se muestre ni titulo ni se renderice el diseño del titulo en la vista
          type: [
            {
              column: 'code',
              markOnNull: true,
              type: 'text',
              colSpan: 0.2,
              class: '',
              headerTitle: this.type === 2 ? this.translate.instant('GLOBAL.externalCode') : this.translate.instant('GLOBAL.internalCode'),
              isChecked: true,
              isEditable: true,
            },
            {
              column: 'name',
              markOnNull: true,
              type: 'text',
              colSpan: 1,
              class: '',
              headerTitle: 'SKU',
              isChecked: true,
              isEditable: true,
            },
          ],
        },
      ];
    this.ColumnsTypeNotFound = [
      {
        id: 0,
        name: '', // null para que no se muestre ni titulo ni se renderice el diseño del titulo en la vista
        type: [
          {
            column: 'code',
            markOnNull: true,
            type: 'text',
            colSpan: 0.2,
            class: 'color-negative',
            headerTitle: 'internal code',
            fxLayoutAlign: 'start center',
            isChecked: true,
            isEditable: true,
          },
          {
            column: 'notfoundtext',
            markOnNull: true,
            type: 'text',
            colSpan: 1,
            class: 'color-negative',
            fxLayoutAlign: 'start center',
            headerTitle: '',
            isChecked: true,
            isEditable: true,
          },
        ],
      },
    ];
  }

  scroll$ = new BehaviorSubject<number>(0);
  onScroll($event): void {
    const target = $event.srcElement;
    if (target.scrollHeight - target.clientHeight > 64) {
      const value = Math.round(target.scrollTop);
      this.scroll$.next(value);
    }
  }

  private _normalizeAndUniqueCodes($arr) {
    let rv = (_cloneDeep($arr) || [])
      .map(c =>
        String(c || '')
          .trim()
          .replace(/,/g, '')
      )
      .filter(c => c.length > 1);

    rv = [...new Set(rv)];

    return rv;
  }

  private _compareCodes(code1, code2) {
    if (this.config_normalizeCompareCodes) {
      if (!code1 || !code2) return false;
      if (!code1.length || !code2.length) return false;
      return String(code1).toLowerCase() === String(code2).toLowerCase();
    } else {
      if (!code1 || !code2) return false;
      if (!code1.length || !code2.length) return false;
      return String(code1) === String(code2);
    }
  }

  dataSource = new MatTableDataSource<any>();
  loading = false;
  getDataStore(event: Array<any>) {
    this.codes = event;

    // Normaliza y hace únicos los códigos
    if (this.config_normalizeAndUniqueCodes) {
      this.codes = this._normalizeAndUniqueCodes(this.codes);
    }

    if (true) _log('\n[this.codes]', this.codes);

    const _data = {
      ids: [],
      externalCodes: this.type === 2 ? this.codes : [],
      internalCodes: this.type === 1 ? this.codes : [],
      checkUserStores: this.checkUserStores,
    };

    const service =
      this.typeList === 'items' ? this.serviceItemMaster.getItemsIdByCode(_data) : this.serviceStoreMaster.getStoresIdByCode(_data);
    this.loading = true;
    service.subscribe(data => {
      if (data && data.items) {
        let notFoundCodes = this.codes;
        if (data.items.length > 0) {
          notFoundCodes = this.codes.filter(
            c =>
              !data.items.some(d => {
                if (this.sinEndpointTerminado) {
                  if (this.type === 1) {
                    return this._compareCodes(c, d.code);
                  } else if (this.type === 2) {
                    return this._compareCodes(c, d.code);
                  }
                }
              })
          );
        }

        const text =
          this.typeList === 'items'
            ? this.translate.instant('M_SHOPPER.MISSIONS.NOT_FOUND_CODE_ITEM')
            : this.translate.instant('M_SHOPPER.MISSIONS.NOT_FOUND_CODE_STORE');

        const notFoundElements = notFoundCodes.map(n => {
          return { notfound: true, notFoundElement: [{ code: n, notfoundtext: text }] };
        });

        this.elementFounded = data.items.map(d => {
          return { ...d, notfound: false };
        });

        const _dataWithNoElementsFound = [...notFoundElements, ...this.elementFounded];
        this.dataSource.data = _dataWithNoElementsFound;
      }
      this.loading = false;
    });
  }

  ngOnDestroy() {}
}

export const _mapPayloadClipBoard = (payload: GenericListPayloadFE): GenericListPayloadBE => {
  const data = _cloneDeep(payload);
  return { ...data, externalCodes: data.externalCodes.map(d => d.toString()), internalCodes: data.internalCodes.map(d => d.toString()) };
};

export interface GenericListPayloadFE {
  ids: Array<number>;
  externalCodes: Array<number>;
  internalCodes: Array<number>;
  checkUserStores?: boolean;
}
export interface GenericListPayloadBE {
  ids: Array<number>;
  externalCodes: Array<string>;
  internalCodes: Array<string>;
}
