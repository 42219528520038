import { NgModule } from '@angular/core';

/** Material Modules */
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorIntlEs } from './shared/mat-paginator-int.es';
import { MdePopoverModule } from '@material-extended/mde';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatGridListModule } from '@angular/material/grid-list';

import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTreeModule } from '@angular/material/tree';
import { MatSliderModule } from '@angular/material/slider';

const modules = [
  MatToolbarModule,
  MatCardModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatTabsModule,
  MatCheckboxModule,
  MatDividerModule,
  MatRippleModule,
  MatTooltipModule,
  MatMenuModule,
  MatStepperModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  MatSidenavModule,
  MatSelectModule,
  MatChipsModule,
  MatDialogModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatListModule,
  MatBottomSheetModule,
  MatSlideToggleModule,
  MatNativeDateModule,
  MdePopoverModule,
  DragDropModule,
  MatExpansionModule,
  MatGridListModule,
  MatTreeModule,
  MatSliderModule,
];

@NgModule({
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlEs,
    },
    {
      provide: OverlayContainer,
      useClass: FullscreenOverlayContainer,
    },
  ],
  imports: [modules],
  exports: [modules],
})
export class MaterialModule {}
