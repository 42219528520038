<mat-toolbar>
  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>
    Prisma v3.0 | Información actualizada al {{ date | date:'EEEE' | titlecase }} {{date | date:'d'}} de {{ date |
    date:'MMMM' | titlecase }} del {{ date | date:'y' }}

    <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
      Powered by
      <a mat-button target="_blank" href="http://www.iantech.net/">IANTECH</a>
    </div>

    <!-- <a href="https://1.envato.market/c/1257954/275988/4415?u=https%3A%2F%2Fthemeforest.net%2Fitem%2Ffuse-angularjs-material-design-admin-template%2F12931855"
           target="_blank" mat-button class="pink" fxFlex="0 0 auto" fxLayout="row"
           fxLayoutAlign="start center">
            <mat-icon class="s-16 mr-sm-4">shopping_cart</mat-icon>
            <span>Purchase FUSE (Angular 7+)</span>
        </a> -->

    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxHide fxShow.gt-xs>
            <a mat-button routerLink="/documentation/getting-started/introduction">Documentation</a>
            <span>&bull;</span>
            <a mat-button routerLink="/documentation/changelog">Changelog</a>
        </div> -->
  </div>
</mat-toolbar>
