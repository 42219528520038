import { environment } from '@env';
import { _info, _isDev, _log } from '@shared/aux_helper_environment';
import { _cloneDeep, _get, _mem } from '@shared/aux_helper_functions';
import { _getStaticBrandCustomization } from 'core/services/ian-core-singleton.service';

let initied = false;
let _blocks = {};

const initEnabledBlocks = $blocks => {
  if (initied) {
    _info('%c[initEnabledBlocks is already init]', 'color: #f00');

    return null;
  }
  if ($blocks) {
    if (false) {
      _log('%c[initEnabledBlocks initied]', 'color: #bada55');
    }
    _blocks = _cloneDeep($blocks);
    initied = true;

    // DebugInfo
    if (window) {
      (window as any)._info = (window as any)._info ? (window as any)._info : {};
      (window as any)._info._enabledBlocks = _blocks;
    }
  }
};

const __getEnabledBlockVal = value => {
  const rv = _get(_blocks, value);

  return rv;
};
const memEnabeldBlockVal = _mem(__getEnabledBlockVal);

const getEnabledBlockVal = value => {
  if (!initied) {
    return null;
  }

  return memEnabeldBlockVal(value);
};

const getConfigLeafletConfigMap = () => {
  if (!initied) {
    console.warn('[getConfigLeafletConfigMap] no initied');
    return null;
  }

  let LEAFLET_MAP_CONFIG_OSM = environment.leafletConfigs.OSM;
  let LEAFLET_MAP_CONFIG_MAPBOX = environment.leafletConfigs.MAP_BOX;
  let brandCustomConfigMap = _getStaticBrandCustomization().leafletConfigs;
  let rv = null;

  //Si existe el config en brandCustomization prioriza ese (brandCustomization.leafletConfigs)
  if (brandCustomConfigMap?.url !== null && rv == null) {
    rv = brandCustomConfigMap;
  }

  //Si es dev usa MAPBOX
  if (_isDev() && LEAFLET_MAP_CONFIG_MAPBOX?.url !== null && rv == null) {
    rv = LEAFLET_MAP_CONFIG_MAPBOX;
  }

  //Si es prod y no hay brandConfig usa OSM como siempre
  if (rv == null) {
    rv = LEAFLET_MAP_CONFIG_OSM;
  }

  //Log
  if (_isDev() && true) {
    console.log('[getConfigLeafletConfigMap]', { rv, LEAFLET_MAP_CONFIG_OSM, LEAFLET_MAP_CONFIG_MAPBOX, brandCustomConfigMap });
  }

  return _cloneDeep(rv);
};

export { initEnabledBlocks, getEnabledBlockVal, getConfigLeafletConfigMap };
