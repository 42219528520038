import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Data, Route, Router, RouterStateSnapshot } from '@angular/router';
import { DataAnauthorizedService } from '@prisma/unauthorized/unauthorized.component';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthorizationGuard implements CanActivate, CanLoad {
  constructor(
    private router: Router,
    private oidcSecurityService: OidcSecurityService,
    private dataAnauthorizedService: DataAnauthorizedService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const data = route.data?.permissions?.only || null;
    this.dataAnauthorizedService.setCustomData(data);
    return this.checkUser(route.data);
  }

  canLoad(state: Route): Observable<boolean> {
    const data = state.data?.permissions?.only || null;
    this.dataAnauthorizedService.setCustomData(data);
    return this.checkUser(state.data);
  }

  private checkUser(routeData: Data): Observable<boolean> {
    if (!routeData.permission) {
      return this.oidcSecurityService.getIsAuthorized().pipe(
        map((isAuthorized: boolean) => {
          if (isAuthorized) {
            return true;
          } else {
            this.router.navigate(['/unauthorized']);

            return false;
          }
        })
      );
    }

    return this.oidcSecurityService.getUserData().pipe(
      map(userData => {
        if (!userData) {
          return false;
          this.router.navigate(['/unauthorized']);
        } else {
          let authorized = false;
          const routePermissions = routeData.permission;
          if (userData.permission) {
            authorized = routePermissions.some(routePerm => userData.permission.some(userPerm => userPerm === routePerm));
          }

          if (authorized) {
            return true;
          } else {
            this.router.navigate(['/unauthorized']);

            return false;
          }
        }
      })
    );

    // return this.oidcSecurityService.getIsAuthorized().pipe(
    //     map((isAuthorized: boolean) => {
    //         if (isAuthorized) {
    //             if (routeData.permission) {
    //                 this.oidcSecurityService.getUserData().pipe(
    //                     map(userData => {
    //                         let authorized = false;
    //                         var routePermissions = routeData.permission;
    //                         if (userData.permission) {
    //                             authorized = routePermissions.some(routePerm => userData.permission.some(userPerm => userPerm === routePerm));
    //                         }

    //                         if (authorized) {
    //                             return true;
    //                         }
    //                         else {
    //                             this.router.navigate(['/unauthorized']);
    //                             return false;
    //                         }
    //                     }));
    //             }
    //             else {
    //                 return true;
    //             }
    //         }
    //         else {
    //             this.router.navigate(['/unauthorized']);
    //             return false;
    //         }
    //     })
    // );
  }
}
