import { Component, OnInit } from '@angular/core';
import { _log } from '@shared/aux_helper_environment';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  ngOnInit(): void {
    _log(`${PageNotFoundComponent.name}::ngOnInit`);
  }
}
