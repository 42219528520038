import { ItemTagsSelection } from '@prisma/components/dinamic-tags/model/dinamicTagsModel';
import { GenericCategorySelectionMiniV2Service } from '@prisma/components/generic-category-selection-mini-v2/generic-category-selection-mini-v2.service';
import { genericCategorysSelection } from '@prisma/components/generic-category-selection/generic-category-selection.service';
import { _isFeDev, _log } from '@shared/aux_helper_environment';
import { _cloneDeep } from '@shared/aux_helper_functions';
import { AppInjector } from 'app/app.module';

/* ENUMS */

export enum EntityEnumType {
  items = 'items',
  stores = 'stores',
  brands = 'brands',
  competitors = 'competitors',
  categories = 'categories',
  subcategorie = 'subcategories',
  users = 'users',
}

export enum GreenButtonEnumOption {
  ManualSelection = 1,
  FilterSelection = 2,
}

/* INTERFACES */

export interface FormCompetitorsScopeModel {
  competitorIds: Array<number>;
  tagsCompetitors: Array<ItemTagsSelection>;
  query: string;
  tradeAreaInMeters?: number;
}

export interface FormModel_scopeItems {
  categoryIdsToInclude: number[];
  categoryIdsToExclude: number[];
  tagValueIdsToInclude: number[];
  tagValueIdsToExclude: number[];
  itemIdsToExclude: number[];
  itemIds: number[];
  onlyItemsAllowedForSelling?: boolean;
}

export interface FormModel_scopeStores {
  tagValueIdsToInclude: number[];
  tagValueIdsToExclude: number[];
  storeIdsToExclude: number[];
  storeIds: number[];
}

export interface FormModel_scopeItems_FE {
  itemIds: number[];
  selectionType?: GreenButtonEnumOption;
  genericCategorysSelection?: genericCategorysSelection;
  tagValueIdsToInclude: number[];
  tagValueIdsToExclude: number[];
  onlyItemsAllowedForSelling?: boolean;
}

export interface FormModel_scopeStores_FE {
  storeIds: number[];
  selectionType?: GreenButtonEnumOption;
  tagValueIdsToInclude: number[];
  tagValueIdsToExclude: number[];
  tags?: ItemTagsSelection[];
  itemScopes?: FormModel_scopeItems_FE[];
}

/* MAPS */

export const mapItemScopeFromBE = (data): any => {
  let _data: any = {
    itemIds: (data?.itemIds ?? []).sort((a, b) => a - b) || [],
    selectionType: data?.itemIds
      ? data?.itemIds?.length > 0
        ? GreenButtonEnumOption.ManualSelection
        : GreenButtonEnumOption.FilterSelection
      : null,
    genericCategorysSelection: {
      categorys: [],
      categorysExcluded: [],
      subCategorys: data?.categoryIdsToInclude || [],
      subCategorysExcluded: data?.categoryIdsToExclude || [],
    },
    tagValueIdsToInclude: data?.tagValueIdsToInclude || [],
    tagValueIdsToExclude: data?.tagValueIdsToExclude || [],
  };

  return _cloneDeep(_data);
};

export const mapStoreScopeFromBE = (data): any => {
  const _data = {
    storeIds: (data?.storeIds || []).sort((a, b) => a - b) || [],
    selectionType: data?.storeIds
      ? data?.storeIds?.length > 0
        ? GreenButtonEnumOption.ManualSelection
        : GreenButtonEnumOption.FilterSelection
      : null,
    tagValueIdsToInclude: data?.tagValueIdsToInclude || [],
    tagValueIdsToExclude: data?.tagValueIdsToExclude || [],
  };

  return _data;
};

export const mapItemScopeToBE = (
  $data,
  forceOnlyItemsAllowedForSelling?: boolean,
  idsToExclude = [],
  useGetLowLevelIncludedExcludedV1 = false
): any => {
  const data = _cloneDeep($data);

  if (data == null) {
    console.warn('mapItemScopeToBE: NO DATA');
  }

  if (data?.itemScope == null) {
    console.warn('mapItemScopeToBE: NO ITEM SCOPE');
  }

  //Si la selección es por ITEMS borra los filtros
  const isItemSelection = data?.itemScope?.selectionType === GreenButtonEnumOption.ManualSelection;

  if (isItemSelection && data?.itemScope) {
    data.itemScope.genericCategorysSelection = null;
    data.itemScope.tagValueIdsToInclude = [];
    data.itemScope.tagValueIdsToExclude = [];
  }

  //CAT-111
  if (useGetLowLevelIncludedExcludedV1 && data.itemScope.genericCategorysSelection) {
    let catService = AppInjector.get(GenericCategorySelectionMiniV2Service);
    let itemScopeTmp = catService.getLowLevelIncludedExcludedV1(data?.itemScope?.genericCategorysSelection);
    data.itemScope.genericCategorysSelection = itemScopeTmp;
  }

  const _data = {
    categoryIdsToInclude: [
      ...(data?.itemScope?.genericCategorysSelection?.categorys || []),
      ...(data?.itemScope?.genericCategorysSelection?.subCategorys || []),
    ],
    categoryIdsToExclude: [
      ...(data?.itemScope?.genericCategorysSelection?.categorysExcluded || []),
      ...(data?.itemScope?.genericCategorysSelection?.subCategorysExcluded || []),
    ],

    tagValueIdsToInclude: data?.itemScope?.tagValueIdsToInclude || [],
    tagValueIdsToExclude: data?.itemScope?.tagValueIdsToExclude || [],
    itemIdsToExclude: idsToExclude || [],
    itemIds: data?.itemScope?.itemIds || [],
    onlyItemsAllowedForSelling:
      forceOnlyItemsAllowedForSelling != null ? forceOnlyItemsAllowedForSelling : !!data?.itemScope?.onlyItemsAllowedForSelling,
  };

  return _data;
};

export const mapStoreScopeToBE = ($data, idsToExclude = []): any => {
  const data = _cloneDeep($data);

  //Si la selección es por ITEMS borra los filtros
  const isItemStoreSelection = data?.storeScope?.selectionType === GreenButtonEnumOption.ManualSelection;
  if (isItemStoreSelection && data.storeScope) {
    data.storeScope.tagValueIdsToInclude = [];
    data.storeScope.tagValueIdsToExclude = [];
  }
  const _data = {
    tagValueIdsToInclude: data?.storeScope?.tagValueIdsToInclude || [],
    tagValueIdsToExclude: data?.storeScope?.tagValueIdsToExclude || [],
    storeIdsToExclude: idsToExclude || [],
    storeIds: data?.storeScope?.storeIds || [],
  };

  return _data;
};

// only promos

export const mapActionVGiftItemScopeToBE = ($data, forceOnlyItemsAllowedForSelling?: boolean): any => {
  const data = _cloneDeep($data);

  //Si la selección es por ITEMS borra los filtros
  const isItemSelection = data?.giftItemScope?.selectionType === 1;
  if (isItemSelection && data.giftItemScope) {
    data.giftItemScope.genericCategorysSelection = null;
    data.giftItemScope.tagValueIdsToInclude = [];
    data.giftItemScope.tagValueIdsToExclude = [];
  }

  //CAT-111
  let catService = AppInjector.get(GenericCategorySelectionMiniV2Service);
  let itemScopeTmp = catService.getLowLevelIncludedExcludedV1(data?.giftItemScope?.genericCategorysSelection);
  data.giftItemScope.genericCategorysSelection = itemScopeTmp;

  const _data = {
    categoryIdsToInclude: [
      ...(data?.giftItemScope?.genericCategorysSelection?.categorys || []),
      ...(data?.giftItemScope?.genericCategorysSelection?.subCategorys || []),
    ],

    categoryIdsToExclude: [
      ...(data?.giftItemScope?.genericCategorysSelection?.categorysExcluded || []),
      ...(data?.giftItemScope?.genericCategorysSelection?.subCategorysExcluded || []),
    ],

    tagValueIdsToInclude: data?.giftItemScope?.tagValueIdsToInclude || [],
    tagValueIdsToExclude: data?.giftItemScope?.tagValueIdsToExclude || [],
    itemIdsToExclude: [],
    itemIds: data?.giftItemScope?.itemIds || [],
    size: data?.giftItemScope?.size || 1,
    onlyItemsAllowedForSelling:
      forceOnlyItemsAllowedForSelling != null ? forceOnlyItemsAllowedForSelling : !!data?.itemScope?.onlyItemsAllowedForSelling,
  };

  return _data;
};

//DES-1742
export const mapActionVGiftItemScopeFromBE = (data): any => {
  let _data: any = {
    itemIds: (data?.itemIds || []).sort((a, b) => a - b) || [],
    selectionType: data?.itemIds
      ? data?.itemIds?.length > 0
        ? GreenButtonEnumOption.ManualSelection
        : GreenButtonEnumOption.FilterSelection
      : null,
    genericCategorysSelection: {
      categorys: [],
      categorysExcluded: [],
      subCategorys: data?.categoryIdsToInclude || [],
      subCategorysExcluded: data?.categoryIdsToExclude || [],
    },
    tagValueIdsToInclude: data?.tagValueIdsToInclude || [],
    tagValueIdsToExclude: data?.tagValueIdsToExclude || [],
    size: data?.size || 1,
  };

  return _data;
};
