import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { _warn } from '@shared/aux_helper_environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-videos-modal',
  templateUrl: './videos-modal.component.html',
  styleUrls: ['./videos-modal.component.scss'],
})
export class VideosModalComponent implements OnInit {
  videos = {
    marginRule: 'https://www.youtube-nocookie.com/embed/8vXe-8WTypU?autoplay=1&rel=0',
    competitionRule: 'https://www.youtube.com/embed/E1n5uT2ArnI?autoplay=1&rel=0',
    marginRuleZone: 'https://www.youtube.com/embed/RCI9vmUckhU?autoplay=1&rel=0',
    parentRuleProduct: 'https://www.youtube.com/embed/VMhbMwZhM8o?autoplay=1&rel=0',
    parentRuleStore: 'https://www.youtube.com/embed/qOgB1cAqxUE?autoplay=1&rel=0',
    competitionSuggestions: 'https://www.youtube.com/embed/d40bu0LhMa4?autoplay=1&rel=0',
    marginSuggestions: 'https://www.youtube.com/embed/cGLjocoOCN8?autoplay=1&rel=0',
    roundingRule: 'https://www.youtube.com/embed/-9YxamDYpUQ?autoplay=1&rel=0',
    guidelinesAndStrategies: 'https://www.youtube.com/embed/ZMhygL8IZVs?autoplay=1&rel=0',
    priceSuggestions: 'https://www.youtube.com/embed/oeUL6_Jrc3E?autoplay=1&rel=0',
  };

  dataVideoUrl = null;

  constructor(
    public dialogRef: MatDialogRef<VideosModalComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _sanitizer: DomSanitizer
  ) {
    let dataVideo = _data.url;

    this.dataVideoUrl = this.videos[dataVideo];

    if (!this.dataVideoUrl) {
      _warn('Video no Encontrado', { dataVideo, _data, videos: this.videos });
      this.dialogRef.close();
    }

    this.dataVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this.dataVideoUrl);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {}
}
