import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { _log } from '@shared/aux_helper_environment';
import { PromotionsV2Service } from 'app/promotionsv2/promotionsv2.service';
import { DevSettingsService } from 'core/services/dev-settings.service';
@Component({
  selector: 'year-selector-promotion',
  template: `
    <!-- //LOADER -->
    <div class="spinner-container-mini" *ngIf="$isLoading || internalLoading">
      <custom-loader></custom-loader>
    </div>

    <span *ngIf="!$isLoading && !internalLoading" fxLayoutGap="24px" fxLayout="column">
      <generic-lookup
        [values]="yearList"
        [selected]="selected"
        (onChange)="onSelectionChange($event[0])"
        [showClearAllBtn]="false"
        [multiple]="false"
        [itemSize]="4"
        [propId]="'id'"
        [propValue]="'name'"
        [attr.data-test-id]="key"
        [test-id]="key"
        [placeholder]="title"
        [required]="required"
        [orderOptionsFlag]="false"
        [disabled]="disabled"
      ></generic-lookup>
    </span>
  `,
  styles: [
    `
      form {
        width: 299px;
      }

      .content-form {
        overflow: auto;
        height: 100%;
        max-height: calc(100% - 150px) !important;
      }

      .form-filter {
        margin-top: 35px;
      }

      .title-filter {
        font-weight: 600;
        margin-bottom: 0px;
        font-size: 16px;
      }
    `,
  ],
})
export class year_selector_promotion_component implements OnInit, OnChanges {
  @Input() $isLoading: boolean;
  @Input() selected: any[];
  @Input() key: string;
  @Input() title: string;
  @Input() autoSelect = false;
  @Input() disabled = false;
  @Input() canSelectOldYears = true;
  @Input() required = true;
  @Output() onChangeFilters = new EventEmitter<any>();

  internalLoading = true;

  yearList = [];

  constructor(
    private service: PromotionsV2Service,
    private translate: TranslateService,
    private configFlags: DevSettingsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes) {
    this.getList();
  }

  async getList() {
    this.internalLoading = true;
    this.yearList = this.getAvailableYears();
    this.internalLoading = false;

    setTimeout(() => {
      if (this.autoSelect && this.selected == null) {
        this.onSelectionChange(__getActionPromoDefaultYear());
      }
    }, 0);
  }

  onSelectionChange(event) {
    if (true) _log(['onSelectionChange year'], event);

    if (String(this.selected) === String(event) || event === undefined) return;

    this.selected = event;

    this.cd.detectChanges();

    this.onChangeFilters.emit(event);
  }

  //DES-4546
  getAvailableYears() {
    let canSelectOldYears = this.canSelectOldYears;
    if (this.disabled) canSelectOldYears = true;

    return __getActionPromoYears(canSelectOldYears).map(el => {
      return { id: Number(el), name: String(el) };
    });
  }
}

export function __getActionPromoDefaultYear(forceActualYear = false) {
  let nowDate = new Date();
  let fullYear = nowDate.getFullYear();
  let month = nowDate.getMonth() + 1;

  let rv = fullYear;

  if (month >= 12 && forceActualYear === false) rv = fullYear + 1;

  return rv;
}

export function __getActionPromoYears(canSelectOldYears = true) {
  let nowDate = new Date();
  let fullYear = nowDate.getFullYear();
  let fullYearDefault = __getActionPromoDefaultYear();

  let rv = canSelectOldYears ? [fullYear - 2, fullYear - 1, fullYear] : [fullYear];

  if (!rv.includes(fullYearDefault)) rv.push(fullYearDefault);

  rv.reverse();

  return rv;
}
