<h1 mat-dialog-title id="_errorGenericModal">
  <mat-icon class="error-color">error_outline</mat-icon>
  &nbsp; {{ "COMP.ERROR_MESSAGE.TITLE" | pTranslate }}
</h1>

<div mat-dialog-content fxLayout="column">
  <div *ngIf="canShowDetailedError">{{errorExceptionMessage}}</div>

  <p>{{ getforceMsg() || ("COMP.ERROR_MESSAGE.SUBTITLE" | pTranslate) }}</p>

  <ng-container *ngIf="canDownloadErrorLog">
    <button mat-icon-button class="mt-4 mb-8" (click)="downloadConsoleLog(64, true)">
      <mat-icon class="small-icons">download</mat-icon>
      {{ "COMP.ERROR_MESSAGE.DOWNLOAD_LOG" | pTranslate }}
    </button>
  </ng-container>

  <div style="font-size: 11px" *ngIf="false">
    <br />
    {{error|json}}
  </div>
</div>

<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-button mat-raised-button color="accent" (click)="close()" cdkFocusInitial>
    {{ "COMP.ERROR_MESSAGE.ACCEPT_BTN" | pTranslate }}
  </button>
</div>
