import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env';
import { _getEnv, _isFeDev, _log, _logTap, _useDummyData, _warn } from '@shared/aux_helper_environment';
import {
  _getNewNumberId,
  _throwError,
  _timeout,
  _dateUtcToLocal,
  _prettyDateEs,
  _parese_and_normalize_notification_body_msg,
} from '@shared/aux_helper_functions';
import { Subject } from 'rxjs';
import { tmpNotifications } from './notifications-panel.auxs';
import { NotificationModel } from './notifications-panel.model';
import { catchError, tap, map, first, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { _debounceDecorator } from '@shared/aux_helper_functions';
import { ToastrService } from 'ngx-toastr';

const TOAST_DURATION = _getEnv('notifications.toastDuration', 5000);
const FORCE_DATE_GMT0 = _getEnv('notifications.forceDatesTogmt0', false);

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private items$ = new Subject<NotificationModel>();

  private config = environment.notification.api;
  private configBase = environment.apiBaseUrl_notification;

  constructor(private snackBar: MatSnackBar, private http: HttpClient, private toastr: ToastrService) {
    if (true) {
      (window as any).__testNewNotif = () => this.testNewNotif();
    }
  }

  date2dateago(date: any, format = true): string {
    const dateStr = _prettyDateEs(new Date(date || ''));
    return format ? dateStr.charAt(0).toUpperCase() + dateStr.slice(1) + '.' : dateStr;
  }

  async setNewItem(payLoad) {
    if (payLoad && payLoad.title) {
      const note = payLoad;

      note.originalID = note.id;

      if (!note.id) {
        note.id = _getNewNumberId(false);
      }

      const urlToDownload = note.urlToDownload || null;
      const urlToGo = urlToDownload ? null : note.urlToGo || null;

      if (!note.detail && note.body) {
        note.detail = _parese_and_normalize_notification_body_msg(note.body, urlToDownload, urlToGo);
      }

      if (!note.largeDetail && note.body) {
        note.largeDetail = _parese_and_normalize_notification_body_msg(note.body, urlToDownload, urlToGo);
      }

      if (!note.date) {
        note.date = new Date();
      }

      note.date = new Date(note.date);

      note.title = _parese_and_normalize_notification_body_msg(note.title, null, null);

      note._isFireBase = true;

      _log('🔔 [toastr notification]', note);

      const hasUrl = _parese_and_normalize_notification_body_msg(payLoad.body, urlToDownload, urlToGo)?.includes?.('linkInNotification');

      /*NEW NOTIF // https://github.com/scttcper/ngx-toastr#iconclasses-defaults */
      this.toastr
        .success(
          `${_parese_and_normalize_notification_body_msg(payLoad.body)}`,
          _parese_and_normalize_notification_body_msg(payLoad.title, null),
          {
            timeOut: (_isFeDev() && false) || hasUrl ? TOAST_DURATION * 100 : TOAST_DURATION,
            extendedTimeOut: (_isFeDev() && false) || hasUrl ? TOAST_DURATION * 100 : TOAST_DURATION,
            closeButton: true,
            enableHtml: true,
            toastClass: `ngx-toastr ngx-toastr-notif ${!(payLoad.fail || payLoad.error) ? 'ngx-toastr-notifOK' : 'ngx-toastr-notifFAIL'}`,
            positionClass: 'toast-top-right',
            easeTime: 300,
          }
        )
        .onTap.pipe(take(1))
        .subscribe(() => this.toasterClickedHandler(note));

      // TODO: En lugar de agregarlo a items nuevos, volver a refrescar los de la DB
      await _timeout(512);
      this.setNotifItemsFromBE();
    } else {
      _warn('🔔 [toastr bad payLoad]', payLoad);
    }
  }

  toasterClickedHandler(note) {
    if (note && note.originalID && note.id) {
      /*Si la notif vino con id desde FB se marca como leída al clickear*/
      this.clearItem(note);
    }
  }

  @_debounceDecorator(512)
  public async setNotifItemsFromBE() {
    this.items$.next(null);

    if (false && _useDummyData()) {
      await _timeout(500);
      this.items$.next(tmpNotifications as NotificationModel);
      return;
    }

    const warnNoNotif = error => {
      if (true) _warn('[NotificationService fail getItemsFromServer]', error);
      this.items$.next([]);
    };

    this.getItemsFromServer()
      .pipe(first())
      .subscribe(data => {
        if (data && Array.isArray(data)) {
          if (false) _log('[NotificationService getItemsFromServer]', data);
          this.items$.next(data as NotificationModel);
        } else {
          warnNoNotif(data);
        }
      }, warnNoNotif);
  }

  private getItemsFromServer() {
    const url = `${this.configBase}${this.config.resources.getNotifications}`;

    return this.http.get<NotificationModel>(url).pipe(
      tap(data => _logTap(`[${NotificationService.name}] getItemsFromServer (tap) / data: size: `, data?.length)),
      map(data => {
        data?.forEach?.($obj => {
          if (!$obj.id) return;
          let obj = $obj as any;

          obj._readed = obj.isCleared;
          obj.date = obj.date || FORCE_DATE_GMT0 ? _dateUtcToLocal(obj.creationDate) : obj.creationDate || new Date();
          obj._isFireBase = false;

          if (false && _isFeDev()) {
            obj.urlToDownload =
              'https://iantechdevstorage.blob.core.windows.net/megademolab/Development/Pricing/reports/PosItemsDataWithAssortment/2023-09-21_15-05-11-PosItemsDataReport.xlsx';
          }

          //DES-4382
          obj.detail = _parese_and_normalize_notification_body_msg(obj.message, obj?.urlToDownload || null, obj?.urlToGo || null);

          obj.title = _parese_and_normalize_notification_body_msg(obj.title, null);

          return obj;
        });
        return data;
      }),
      catchError(error => _throwError(error, NotificationService))
    );
  }

  public async clearItem(item) {
    if (!item || !item.id) return;

    const url = `${this.configBase}${this.config.resources.clear}?notificationId=${item.id}`;

    if (false && _useDummyData()) {
      this.items$.next(null);
      await _timeout(500);
      this.items$.next(tmpNotifications as NotificationModel);
      return;
    }

    return this.http
      .post<any>(url, null)
      .pipe(
        first(),
        tap(data => _logTap(`${NotificationService.name}::clearItem (tap)\n\tdata: %o`, data)),
        catchError(error => _throwError(error, NotificationService))
      )
      .subscribe(d => {
        this.setNotifItemsFromBE();
      });
  }

  public async clearAllItems() {
    const url = `${this.configBase}${this.config.resources.clearAll}`;

    return this.http
      .post<any>(url, null)
      .pipe(
        first(),
        tap(data => _logTap(`${NotificationService.name}::clearAllItem (tap)\n\tdata: %o`, data)),
        catchError(error => _throwError(error, NotificationService))
      )
      .subscribe(d => {
        this.setNotifItemsFromBE();
      });
  }

  getItems() {
    return this.items$;
  }

  private testNewNotif() {
    // this.setNewItem({ title: 'Notificación Nueva', body: 'Body de \n la notificación ' + (new Date() as any).toGMTString() });

    this.setNewItem({
      title: 'Notificación Nueva',
      body: "{'message':'Descargue el reporte de la tienda ST100','urlToDownload':'https://iantechdevstorage.blob.core.windows.net/megademolab/Development/Pricing/reports/PosItemsDataWithAssortment/2023-09-21_15-05-11-PosItemsDataReport.xlsx'}",
    });
  }
}
