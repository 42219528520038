import { Component, OnInit } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { _log } from '@shared/aux_helper_environment';

@Component({
  selector: 'app-help-main-content',
  templateUrl: './help-main-content.component.html',
  styleUrls: ['./help-main-content.component.scss'],
})
export class HelpMainContentComponent implements OnInit {
  canShow_priceRuleHelp = false;
  canShow_roundingRuleHelp = false;
  canShow_guidelinesAndStrategiesHelp = false;
  canShow_priceDecisionsHelp = false;
  canShow_decisionsSummaryHelp = false;

  constructor(private router: Router) {
    this.canShow_priceRuleHelp = true;
    this.canShow_roundingRuleHelp = true;
    this.canShow_guidelinesAndStrategiesHelp = true;
    this.canShow_priceDecisionsHelp = true;
    this.canShow_decisionsSummaryHelp = true;

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const newUrl = event.urlAfterRedirects;
      if (true) _log('\nHELP ROUTE:', newUrl);

      this.canShow_priceRuleHelp = newUrl.startsWith('/prices/setup/price-rules');
      this.canShow_roundingRuleHelp = newUrl.startsWith('/prices/setup/rounding');
      this.canShow_guidelinesAndStrategiesHelp = newUrl.startsWith('/prices/setup/guidelines-and-strategies');
      (this.canShow_priceDecisionsHelp = newUrl.startsWith('/prices/decisions/price-decisions')),
        (this.canShow_decisionsSummaryHelp = newUrl.startsWith('/prices/decisions/decisions-summary'));
    });
  }

  ngOnInit() {}
}
