<div class="center">
  <div class="logo">
    <img width="128" src="assets/Prisma_NewLogo_2024.svg" />
  </div>
  <h1 class="centered color-green">
    {{'GENERIC_ACCESS_PERMISSION' | pTranslate}}{{ showFlag && permissionName.length ? ' | ' + permissionName : null}}
  </h1>
  <h1 class="centered color-green">
    {{ showFlag && !permissionName.length ? 'Si desea ver los permisos de la pantalla que no puede acceder.' : null }}
  </h1>
  <h1 class="centered color-green">
    {{ showFlag && !permissionName.length ? 'No actualice esta pantalla, debe ser redireccionado manualmente al navegar
    desde el menú.' : null }}
  </h1>
</div>
