import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@env';
import { _getEnv, _logTap, _useDummyData } from '@shared/aux_helper_environment';
import { _get, _handleErrorWithDummyData } from '@shared/aux_helper_functions';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { dummyInsightsPanel } from './insights-panel.dummyData';
import { InsightsListModel } from 'app/admin/master/insights/insights-store/insights-store.model';
import { PagedList } from 'core/models/paged-list.model';
import { MAX_PAGE_SIZE } from '@prisma/models/defined-data';
import { InsightsdefaultAdvancedFilter } from 'app/admin/master/insights/insights-store/insights-store.state';
import { _InsightsMapFilterModelForBE } from 'app/admin/master/insights/insights.service';

const FORCE_DATE_GMT0 = _getEnv('notifications.forceDatesTogmt0', false);

@Injectable({
  providedIn: 'root',
})
export class InsightsPanelService {
  private configBase = _get(environment, 'apiBaseUrl_prices');
  private config = _get(environment, 'admin.masters.insights.api');

  constructor(private snackBar: MatSnackBar, private http: HttpClient, private toastr: ToastrService) {}

  getPanelList(): Observable<Array<InsightsListModel>> {
    let url;
    const filters = _InsightsMapFilterModelForBE(InsightsdefaultAdvancedFilter());
    const pageIndex = 0;
    const pageSize = MAX_PAGE_SIZE; // para que no sea paginado pero reutilizamos el servicio traemos el maximo que permite la arquitectura

    const qs: Array<string> = ['?'];
    if (pageIndex) {
      qs.push(`pageIndex=${pageIndex}`);
    }

    if (pageSize) {
      qs.push(`pageSize=${pageSize}`);
    }

    url = `${this.configBase}${this.config.resources.getPanelList}${qs.join('&')}`;

    return this.http.post<PagedList<InsightsListModel>>(url, filters).pipe(
      tap(data => _logTap(`${InsightsPanelService.name}::getPanelList (tap)\n\tdata`, data)),
      catchError(error => {
        return _handleErrorWithDummyData(
          true && _useDummyData(),
          error,
          InsightsPanelService,
          dummyInsightsPanel.insightsPanelList,
          'dummyInsightsPanel.insightsPanelList'
        );
      })
    );
  }
}
