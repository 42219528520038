import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { _isDev, _log } from '@shared/aux_helper_environment';
import { AppInSave } from 'app/store/store.actions';
import { AppState } from 'app/store/store.state';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

const GLOBAL_APPINLOAD = '_global_AppInLoad';
const _devTest = false;

@Injectable({
  providedIn: 'root',
})
export class AppIsInSaveService {
  @Select(AppState.isInSave)
  inSave$: Observable<boolean>;

  constructor(@Inject(DOCUMENT) private document: any, private store: Store) {
    if (_devTest) {
      _log('[appIsInSaveService]', document);
    }
    const body = document.getElementsByTagName('body')[0];
    const timeOut = false && _isDev() ? 5120 : 512;

    this.inSave$.pipe(distinctUntilChanged()).subscribe(isSave => {
      if (_devTest) {
        _log('[isSave]', isSave);
      }

      if (isSave) {
        // Add global Class / _global_AppInLoad
        body.classList.add(GLOBAL_APPINLOAD);
      } else {
        // Remove global Class
        setTimeout(() => {
          body.classList.remove(GLOBAL_APPINLOAD);
        }, timeOut);
      }
    });
  }

  setIsInSave(val = true, timeout: number = null): void {
    this.store.dispatch(new AppInSave(val, timeout));
  }
}
