import { _isFeDev, _log, _warn } from '@shared/aux_helper_environment';
import { _orderBy, _cloneDeep, _timeout, _toNumber } from '@shared/aux_helper_functions';
import { AddProdToShelf } from 'app/spaces/planograms-editor/store/planogram.store.state';
import {
  _getTmpProdId,
  _round,
  DEFAULT_NORMAL_SHELF_GUIDE_WIDTH,
  DEFAULT_OFFSET_MIN_PRD_SEP_X,
  DEFAULT_FINGER_SPACE,
  DEFAULT_AUTOSPACE,
  DEFAULT_MIN_RATIO_FOR_AUTOSPACE,
  _calcRotateTransforms,
  DEFAULT_PALLET_HEIGHT,
} from 'app/spaces/static_common/spaces_aux_helpers';
import { _auxGetMaxRemainingWidthSpace } from './svgBaseShelf.component';

let _stacksLyingDownFirst = true; // Variable global para prender la feature que al tumbar productos el de debajo de todo quede parado (ej: Pañales)
export const setStacksLyingDownFirst = val => (_stacksLyingDownFirst = val);

export const normalShelfDecorator = _this => {
  if (true && _this?.DES_4510_SPC_FEATURES_PARA_POC_SODIMAC === true) setStacksLyingDownFirst(false);

  _this.addElement = async (data, $position) => {
    let prodId = data.id;

    const ALWAYS_NEW_ID = true; //DES-1518
    const newId = ALWAYS_NEW_ID ? _getTmpProdId() : data && data.isList ? _getTmpProdId() : prodId;

    //Si es un item ya insertado
    let prodInShelf = _this.baseObj.findChildById(prodId);

    //Si es un item ya insertado copia la rotacion
    let rotation = prodInShelf ? prodInShelf.rotation : 0;

    //Solo rotaciones múltiplo de 90
    if (rotation % 90 !== 0) rotation = 0;
    let rotateY = prodInShelf ? !!prodInShelf.rotateY : false;

    let limitLoadSize = prodInShelf ? prodInShelf.limitLoadSize : null;
    let limitStackSize = prodInShelf ? prodInShelf.limitStackSize : null;
    let isPallet = prodInShelf ? prodInShelf.isPallet : false;

    //Si el item viene de otro shelf anula la rotación
    if (rotation && prodInShelf && prodInShelf.parentShelf && prodInShelf.parentShelf.id !== _this.id) rotation = 0;

    if (true) _log('\n\n[addElement (normal)]', { data, $position, prodId, prodInShelf });

    // Ajuste de nueva posicion
    let tmpPosition = { x: $position.x - data.size.w / 2, y: $position.y - data.size.h / 2 };
    const nearNextPosition = _this._aux_getNearNextPosition_x(tmpPosition, data.size.w, data.id);
    const newProd = {
      ...data,
      rotation,
      rotateY,
      limitLoadSize,
      limitStackSize,
      isPallet,
      order: nearNextPosition.obj ? nearNextPosition.obj.order + 0.5 : -0.5,
      id: newId,
    };

    // Posición inválida > Restea la posición del drag y aborta
    if (!_this.isValidPos(tmpPosition, data.itemData.size, prodId)) {
      let prodObj = _this.baseObj.findChildById(prodId);
      if (prodObj) prodObj.resetPosition();
      return;
    }

    if (!_this.isValidPromoItem(data, true)) {
      _this.baseObj.genericAlert({
        message: 'El item no se encuentra alcanzado por ninguna de las acciones promocionales asociadas al estante.',
        title: 'Aviso',
        isOnlyWarning: true,
      });

      let prodObj = _this.baseObj.findChildById(prodId);
      if (prodObj) prodObj.resetPosition();
      return;
    }

    // Tope de facings si el producto se suma
    if ((!prodInShelf || prodInShelf.parentShelf !== _this) && newProd.facings && newProd.facings > 1) {
      let sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;

      let tmpWidth = !newProd.rotateY ? data.itemData.size.w : data.itemData.size.d;
      let maxFacings = Math.floor(_this._getMaxRemainingWidthSpace() / (tmpWidth + sepX));

      if (_this.__hasShelfAvoidCheckSpace()) maxFacings = Math.max(newProd.facings, maxFacings);

      if (maxFacings < 1) {
        let prodObj = _this.baseObj.findChildById(prodId);
        if (prodObj) prodObj.resetPosition();
        return;
      }

      newProd.facings = Math.min(newProd.facings, maxFacings);
    }

    // Add
    // La posición de productos en normalShelf es calculada por el store y depende del orden (_auxRecalcProdsPos)
    await _this.store.dispatch(new AddProdToShelf(newProd, _this.id, prodId));

    // Selecciona
    _this.baseObj.deselectLast();
    await _timeout(0);
    _this.baseObj.selectObjBaseById(newId, _this.getThisScreenOffset());
  };

  _this.showGuides = (data, $position) => {
    if (!data || !$position) return;
    const position = { x: $position.x - data.size.w / 2, y: $position.y - data.size.h / 2 };

    const nearNextPosition = _this._aux_getNearNextPosition_x(position, data.size.w, data.id);
    const nearNextPosition_x = nearNextPosition.x;
    const shelfGuideWidth = DEFAULT_NORMAL_SHELF_GUIDE_WIDTH;
    let sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;

    _this._shelfGuideWarning = !_this.isValidPos(position, data.itemData.size, data.id);

    if (!_this._shelfGuideWarning) {
      _this._shelfGuideWarning = !_this.isValidPromoItem(data, false);
    }

    _this._shelfGuide.position = { x: (nearNextPosition_x - sepX * 0.5) * _this.scale - shelfGuideWidth * 0.5, y: 0 };
    _this._shelfGuide.size = { w: shelfGuideWidth, h: _this.size.h * _this.scale };
  };

  _this._aux_getNearNextPosition_x = (position, width, prodId = null) => {
    let sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;
    if (!_this.prods || !_this.prods.length) return { x: 0 + sepX, obj: null };

    // Busca el {tmpNearObj/tmpNearlastDiff} más cercano
    let tmpNearObj = null;
    let tmpNearlastDiff = 0;
    _this.prods.forEach((obj, i) => {
      let offsetX = obj.position.x + obj.size.w * 0.5 + sepX;
      let diff = position.x + width * 0.5 - offsetX;
      if (diff > 0 && (diff < tmpNearlastDiff || tmpNearlastDiff === 0) && obj.id !== prodId) {
        tmpNearlastDiff = diff;
        tmpNearObj = obj;
      }
    });

    let leftMargin = _this.marginLeft || 0;
    return { x: tmpNearObj ? tmpNearObj.position.x + tmpNearObj.size.w + sepX : leftMargin + sepX, obj: tmpNearObj };
  };

  _this.isValidPos = (position, size, prodId) => {
    let prod = _this.baseObj.findChildById(prodId);
    if (prod && prod.parentShelf === _this) return true;

    if (!position || !size) return false;

    let _tmpSize = _cloneDeep(size);

    if (prod && prod.rotateY) {
      _tmpSize.w = prod.itemData.size.d;
      _tmpSize.d = prod.itemData.size.w;
    }

    if (!_this._isValidHeight(position, _tmpSize, prodId)) return false;
    if (!_this._isValidWidth(position, _tmpSize, prodId)) return false;

    return true;
  };

  _this._isValidHeight = (position, size, prodId = null) => {
    if ((_this as any).__hasShelfAvoidCheckSpace()) return true;

    if (!position || !size) return false;

    // Producto más alto (tiene cuanta el fingerSpace)
    if (size.h + DEFAULT_FINGER_SPACE > _this.size.h) return false;

    let prod = _this.baseObj.findChildById(prodId);
    if (prod?.isPallet === true && size.h + DEFAULT_PALLET_HEIGHT > _this.size.h) return false;

    return true;
  };

  _this._isValidWidth = (position, size, id = null) => {
    if ((_this as any).__hasShelfAvoidCheckSpace()) return true;

    if (!position || !size) return false;
    let sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;

    // Producto más ancho q el espacio sobrante
    let remaining_space = _this._getMaxRemainingWidthSpace();
    if (size.w + sepX > remaining_space) return false;

    return true;
  };

  _this._getFreeSpaceRatio = () => {
    if (!_this.prods || !_this.prods.length) return 0;

    let shelfWidth = _this.objDataShelf.width;
    let shelfMargins = (_this.marginLeft || 0) + (_this.marginRight || 0);
    let totalShelfWidth = shelfWidth - shelfMargins; //DES-1576

    let remainingWidthSpace = _auxGetMaxRemainingWidthSpace(_this.prods, totalShelfWidth, _this.type);
    let freeSpaceRatio = 1 - remainingWidthSpace / totalShelfWidth;
    return freeSpaceRatio;
  };

  _this.isNewWidthValid = ($newW: number) => {
    let shelfWidth = _this.objDataShelf.width;
    let shelfMargins = (_this.marginLeft || 0) + (_this.marginRight || 0);
    let totalShelfWidth = shelfWidth - shelfMargins; //DES-1576
    let newW = $newW - shelfMargins;

    if (!_this.prods || !_this.prods.length || newW > (_this as any).objDataShelf.width) return true;

    let sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;

    const newRemainingWidthSpace = _auxGetMaxRemainingWidthSpace(_this.prods, newW, _this.type);
    if (newRemainingWidthSpace < sepX) return false;

    return true;
  };

  //DES-1576
  _this.isNewMarginValid = ($newValue: number, LEFT: boolean = true) => {
    let shelfWidth = _this.objDataShelf.width;
    let shelfMargins = (LEFT ? $newValue || 0 : _this.marginLeft || 0) + (!LEFT ? $newValue || 0 : _this.marginRight || 0);
    let newW = shelfWidth - shelfMargins;

    let otherMargin = LEFT ? _this.marginRight || 0 : _this.marginLeft || 0;
    if ($newValue >= _this.size.w - otherMargin) return false;

    if (!_this.prods || !_this.prods.length || newW > (_this as any).objDataShelf.width) return true;

    let sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;

    const newRemainingWidthSpace = _auxGetMaxRemainingWidthSpace(_this.prods, newW, _this.type);
    if (newRemainingWidthSpace < sepX) return false;

    return true;
  };

  _this.isNewHeightValid = (newH: number) => {
    if (!_this.prods || !_this.prods.length || newH > (_this as any).objDataShelf.height) return true;

    let rv = true;

    _this.prods.forEach(prod => {
      let _h = prod.itemData.size.h + DEFAULT_FINGER_SPACE + (prod.isPallet ? DEFAULT_PALLET_HEIGHT : 0);
      if (_h > newH) rv = false;
    });

    return rv;
  };

  _this.isNewDepthValid = (newD: number) => {
    if (!_this.prods || !_this.prods.length || newD > (_this as any).objDataShelf.depth) return true;

    let rv = true;

    _this.prods.forEach(prod => {
      let _d = prod.itemData.size.d;
      if (_d > newD) rv = false;
    });

    return rv;
  };
};

// Auxiliar para reacomodar/calcular poscion/tamaño/filtrado de items
export const _recalcPosAndSize_normalShelf = shelf => {
  // Calculo de posicion de estante normal (normalShelf)
  let prods = shelf.prods || [];

  // Ordena y filtra nulos
  prods = _orderBy(
    prods.filter(obj => !!(obj && obj.itemData && obj.itemData._internalItemId)),
    ['order']
  );

  if (!shelf.width) {
    _warn('NO shelf.width', shelf);
    return;
  }

  let shelfWidth = shelf.width;
  let shelfMargins = (shelf.marginLeft || 0) + (shelf.marginRight || 0);
  let totalShelfWidth = shelfWidth - shelfMargins; //DES-1576

  let remainingWidthSpace = _auxGetMaxRemainingWidthSpace(prods, totalShelfWidth, shelf.type);
  let freeSpaceRatio = 1 - remainingWidthSpace / totalShelfWidth;

  let sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;
  let prodsLength = prods.length;
  let marginLeftOffset = shelf.marginLeft || 0; //DES-1576

  // AUTOESPACIADO / Si la ocupación es mayor a 75% (DEFAULT_MIN_RATIO_FOR_AUTOSPACE) autoAjusta el espaciado
  if (DEFAULT_AUTOSPACE && prodsLength && freeSpaceRatio > DEFAULT_MIN_RATIO_FOR_AUTOSPACE) {
    sepX = _round((remainingWidthSpace + prodsLength * DEFAULT_OFFSET_MIN_PRD_SEP_X) / (prodsLength + 1));
    sepX = Math.max(DEFAULT_OFFSET_MIN_PRD_SEP_X, sepX);
  }

  if (shelf?.__shelfAvoidCheckSpace === true && remainingWidthSpace < 0) {
    sepX = _round(remainingWidthSpace / (prodsLength - 1));
  }

  let canDeleteNextProd = false; /*2k5pbp no agrupa para poder rotar independientemente*/
  let hasDeleteNextProd = false;
  if (canDeleteNextProd) {
    // Se fija si hay agrupaciones
    prods = prods.map((obj, i) => {
      if (hasDeleteNextProd) {
        hasDeleteNextProd = false;
        return null;
      }

      let nextProd = prods[i + 1];
      if (nextProd && nextProd.itemData._internalItemId === obj.itemData._internalItemId) {
        let totalFacings = (nextProd.facings || 1) + (obj.facings || 1);
        hasDeleteNextProd = true; // Marca el próximo para borrado
        return { ...obj, id: nextProd.id, facings: totalFacings };
      }

      return obj;
    });
  }

  // Calcula la posición usando el order y acumulando
  let _lastProd = null;

  prods = prods.map(($obj, i) => {
    if (!$obj || !$obj.itemData) return $obj;

    let obj = _cloneDeep({ ...$obj, itemData: null });
    obj.itemData = $obj.itemData;

    // Calcula tamaño
    obj.stacksLyingDown = !!obj.stacksLyingDown;
    obj.stacks = _auxGetmaxStacks(obj, shelf.height, obj.stacksLyingDown, obj.rotation);

    const subItemsAndTotalSize = _auxCalcGroupImgItemsAndTotalSize(obj, shelf);
    obj.order = i;
    obj.groupImgItems = subItemsAndTotalSize.items; //Grupo de imágenes con valores ya calculados
    obj.size = subItemsAndTotalSize.totalSize; //Tamaño total (rectángulo de selección)
    obj.imgSize = subItemsAndTotalSize.imgSize; //Si hay rotaciones, offset y size de cada img
    obj.$totalItems = (subItemsAndTotalSize as any).limitLoadSize || subItemsAndTotalSize.$totalItems || 0; //ReadOnly de cantidad de items
    obj.$totalItemsMax = subItemsAndTotalSize.$totalItemsMax || 0;
    obj.$parenShelf = shelf.id;

    // Calcula la posición
    obj.position = {
      x: Math.max(_round((_lastProd ? _lastProd.position.x + _lastProd.size.w : marginLeftOffset) + sepX), marginLeftOffset),
      y: _round(shelf.height - obj.size.h),
    };

    _lastProd = obj;
    return obj;
  });

  return prods || [];
};

//Patch Multimples rotaciones DES-4510
export const _auxCalcGroupImgItemsAndTotalSizeMultipleRotations = ($prod, shelf = null) => {
  const prod = $prod?.itemData?.size
    ? $prod
    : { ...$prod, itemData: $prod?.itemData || { ...$prod?.itemData, size: $prod?.itemData?.size || {} } };

  const sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;

  let stacks = prod.stacks || 1;
  let facings = prod.facings || 1;

  //Limite de carga (no puede haber más facings que carga limitada)
  if (prod.limitLoadSize && facings * stacks > prod.limitLoadSize) stacks = Math.min(Math.floor(prod.limitLoadSize / facings) || 1, stacks);
  if (prod.limitLoadSize && facings * stacks > prod.limitLoadSize) facings = Math.min(prod.limitLoadSize, facings);

  const defaultImage = (prod.itemData || {}).imagePlanogramFront;

  const lyingImage = (prod.itemData || {}).imagePlanogramTop;

  const stacksLyingDown = prod.stacksLyingDown; /*Rotación en X*/

  const hasRotation = prod.rotation; /*Rotación en Z*/

  const isRotateY = prod.rotateY; /*Rotación en Y*/

  const rotateYImage = (prod.itemData || {}).imagePlanogramLeft;

  let tmpWidth = prod.itemData.size.w;
  let tmpHeight = prod.itemData.size.h;
  let tmpDepth = prod.itemData.size.d || prod.itemData.size.w;
  let imgSize = { w: 0, h: 0, originX: 0, originY: 0, offsetX: 0, offsetY: 0 };

  let shelfDepth = (shelf || {}).depth;

  //Si viene el shelf como parámetro calcula los items que entran en total
  let $totalItems = shelfDepth ? 0 : null;
  let $totalItemsMax = shelfDepth ? 0 : null;

  if (hasRotation) {
    if (stacksLyingDown) {
      tmpWidth = prod.itemData.size.w;
      tmpHeight = tmpDepth;
    }

    if (isRotateY) {
      tmpWidth = tmpDepth;
      tmpHeight = prod.itemData.size.h;
    }

    //Si está rotado calcula de nuevo el width y height y completa valores para imgSize
    const totalBound = _calcRotateTransforms(tmpWidth, tmpHeight, prod.rotation || 0);

    imgSize = {
      ...totalBound,
      w: tmpWidth,
      h: tmpHeight,
    };

    tmpWidth = totalBound.w;
    tmpHeight = totalBound.h;

    if (stacksLyingDown) {
      tmpDepth = totalBound.h;
    }
    if (isRotateY) {
      tmpDepth = totalBound.w;
    }
  }

  const rvItems = [];

  // Calcula subitems
  let _accWidth = 0; /* Va acumulando el width total */
  let _accHeight = 0; /* Va acumulando el height total */
  for (let i = 0; i < facings; i++) {
    _accHeight = 0; // Por cada columna nueva resetea el total del height
    for (let j = 0; j < stacks; j++) {
      const _id = `${prod.id}${i}${j}`;
      let _img = defaultImage ? defaultImage : null;
      let _w = tmpWidth;
      let _h = tmpHeight;

      let _isLyingDown = stacksLyingDown && j > 0; // Solo están voltados los de abajo
      if (!_stacksLyingDownFirst) _isLyingDown = stacksLyingDown && true; // Todos están voltados los de abajo //DES-4510

      let _forcedImg = false;

      if (_isLyingDown) {
        // Si está tumbado la altura es la profundidad
        _h = tmpDepth;
        if (lyingImage) {
          _img = lyingImage; // Si tiene la imagen la usa
        } else {
          _forcedImg = true; // Marca la imagen como forzada
        }

        // va sumando la cantidad de items que entran en profundidad
        if (shelfDepth) $totalItems += Math.floor(shelfDepth / prod.itemData.size.h) || 1;
      } else if (isRotateY) {
        // Rotado en Y
        _w = tmpDepth;
        _img = rotateYImage;

        // va sumando la cantidad de items que entran en profundidad
        if (shelfDepth) $totalItems += Math.floor(shelfDepth / prod.itemData.size.w) || 1;
      } else {
        // va sumando la cantidad de items que entran en profundidad
        if (shelfDepth) $totalItems += Math.floor(shelfDepth / (prod.itemData.size.d || prod.itemData.size.w)) || 1;
      }

      const _x = _round(_accWidth);
      if (j === stacks - 1) _accWidth += _w + sepX; // Acumula el ancho solo con el último producto de la fila

      const _y = _round(_accHeight);
      _accHeight += _h;

      rvItems.push({
        imageSrc: _img || null,
        size: { w: _w, h: _h },
        position: { x: _x, y: _y },
        id: _id,
        isLyingDown: _isLyingDown,
        forcedImg: _forcedImg,
      });
    }
  }

  $totalItemsMax = $totalItems;

  //Limite de carga
  if (prod.limitLoadSize && $totalItems > prod.limitLoadSize) $totalItems = prod.limitLoadSize;

  let unMed = _toNumber(prod.itemData.conversion || prod.itemData.unMed || 1);

  //DES-3945 -> Unidad de medida mayor a 1
  const TOTAL_ITEMS_WHITH_CONVERSION_RATIO = true;
  if (TOTAL_ITEMS_WHITH_CONVERSION_RATIO && unMed != null && unMed > 1) {
    $totalItems *= unMed;
    $totalItemsMax *= unMed;
  }

  const totalSize = { w: _round(_accWidth - sepX), h: _round(_accHeight) };

  return { items: rvItems, totalSize, imgSize, $totalItems, $totalItemsMax, __auxCalcGroup: true };
};

/*
    Calcula grupo de imagenes y posición total para productos de normalShelf
    Es el único lugar donde deberian hacerse estos calculos
    Tiene en cuenta rotaciones / apilado y múltiples frentes
*/
export const _auxCalcGroupImgItemsAndTotalSize = ($prod, shelf = null) => {
  //DES-4510 Permitir rotaciones anidadads
  if (true && $prod.rotation && ($prod.rotateY || $prod.stacksLyingDown)) {
    return _auxCalcGroupImgItemsAndTotalSizeMultipleRotations($prod, shelf);
  }

  const prod = $prod?.itemData?.size
    ? $prod
    : { ...$prod, itemData: $prod?.itemData || { ...$prod?.itemData, size: $prod?.itemData?.size || {} } };

  const sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;

  let stacks = prod.stacks || 1;
  let facings = prod.facings || 1;

  //Limite de carga (no puede haber más facings que carga limitada)
  if (prod.limitLoadSize && facings * stacks > prod.limitLoadSize) stacks = Math.min(Math.floor(prod.limitLoadSize / facings) || 1, stacks);
  if (prod.limitLoadSize && facings * stacks > prod.limitLoadSize) facings = Math.min(prod.limitLoadSize, facings);

  const defaultImage = (prod.itemData || {}).imagePlanogramFront;
  const lyingImage = (prod.itemData || {}).imagePlanogramTop;
  const stacksLyingDown = prod.stacksLyingDown; /*Rotación en X*/
  const hasRotation = prod.rotation; /*Rotación en Z*/

  const isRotateY = prod.rotateY; /*Rotación en Y*/
  const rotateYImage = (prod.itemData || {}).imagePlanogramLeft;

  let tmpWidth = prod.itemData.size.w;
  let tmpHeight = prod.itemData.size.h;
  let tmpDepth = prod.itemData.size.d || prod.itemData.size.w;
  let imgSize = { w: 0, h: 0, originX: 0, originY: 0, offsetX: 0, offsetY: 0 };

  let shelfDepth = (shelf || {}).depth;

  //Si viene el shelf como parámetro calcula los items que entran en total
  let $totalItems = shelfDepth ? 0 : null;
  let $totalItemsMax = shelfDepth ? 0 : null;

  if (hasRotation) {
    //Si está rotado calcula de nuevo el width y height y completa valores para imgSize
    const totalBound = _calcRotateTransforms(tmpWidth, tmpHeight, prod.rotation || 0);
    imgSize = {
      ...totalBound,
      w: tmpWidth,
      h: tmpHeight,
    };
    tmpWidth = totalBound.w;
    tmpHeight = totalBound.h;
  }

  const rvItems = [];

  // Calcula subitems
  let _accWidth = 0; /* Va acumulando el width total */
  let _accHeight = 0; /* Va acumulando el height total */
  for (let i = 0; i < facings; i++) {
    _accHeight = 0; // Por cada columna nueva resetea el total del height
    for (let j = 0; j < stacks; j++) {
      const _id = `${prod.id}${i}${j}`;
      let _img = defaultImage ? defaultImage : null;
      let _w = tmpWidth;
      let _h = tmpHeight;

      let _isLyingDown = stacksLyingDown && j > 0; // Solo están voltados los de abajo
      if (!_stacksLyingDownFirst) _isLyingDown = stacksLyingDown && true; // Todos están voltados los de abajo //DES-4510

      let _forcedImg = false;
      if (_isLyingDown) {
        // Si está tumbado la altura es la profundidad
        _h = prod.itemData.size.d || prod.itemData.size.w;
        if (lyingImage) {
          _img = lyingImage; // Si tiene la imagen la usa
        } else {
          _forcedImg = true; // Marca la imagen como forzada
        }

        // va sumando la cantidad de items que entran en profundidad
        if (shelfDepth) $totalItems += Math.floor(shelfDepth / prod.itemData.size.h) || 1;
      } else if (isRotateY) {
        // Rotado en Y

        _w = tmpDepth;
        _img = rotateYImage;

        // va sumando la cantidad de items que entran en profundidad
        if (shelfDepth) $totalItems += Math.floor(shelfDepth / prod.itemData.size.w) || 1;
      } else {
        // va sumando la cantidad de items que entran en profundidad
        if (shelfDepth) $totalItems += Math.floor(shelfDepth / (prod.itemData.size.d || prod.itemData.size.w)) || 1;
      }

      const _x = _round(_accWidth);
      if (j === stacks - 1) _accWidth += _w + sepX; // Acumula el ancho solo con el último producto de la fila

      const _y = _round(_accHeight);
      _accHeight += _h;

      rvItems.push({
        imageSrc: _img || null,
        size: { w: _w, h: _h },
        position: { x: _x, y: _y },
        id: _id,
        isLyingDown: _isLyingDown,
        forcedImg: _forcedImg,
      });
    }
  }

  $totalItemsMax = $totalItems;

  //Limite de carga
  if (prod.limitLoadSize && $totalItems > prod.limitLoadSize) $totalItems = prod.limitLoadSize;

  let unMed = _toNumber(prod.itemData.conversion || prod.itemData.unMed || 1);

  //DES-3945 -> Unidad de medida mayor a 1
  const TOTAL_ITEMS_WHITH_CONVERSION_RATIO = true;
  if (TOTAL_ITEMS_WHITH_CONVERSION_RATIO && unMed != null && unMed > 1) {
    $totalItems *= unMed;
    $totalItemsMax *= unMed;
  }

  const totalSize = { w: _round(_accWidth - sepX), h: _round(_accHeight) };

  return { items: rvItems, totalSize, imgSize, $totalItems, $totalItemsMax, __auxCalcGroup: true };
};

export const _auxGetmaxStacks = (prd, parentShelfH, stacksLyingDown = false, rotation = 0) => {
  if (!prd || !prd.itemData) return 1;

  let maxStacksProd = null;

  if (prd.limitStackSize) {
    maxStacksProd = prd.limitStackSize;
  } else {
    maxStacksProd = prd.itemData.maxStacks;

    if (maxStacksProd === 0) maxStacksProd = 1;

    if (maxStacksProd == null) maxStacksProd = 1000; //infinito

    if (!prd.itemData.stackable) maxStacksProd = 1;
  }

  if (false) _log('\n\n[maxStacksProd]', maxStacksProd, prd.itemData.maxStacks);

  if (maxStacksProd == null || maxStacksProd < 2) return 1;

  let _h = prd.itemData.size.h;
  if (rotation % 180) _h = prd.itemData.size.w; /*Si la rotación es horizontal da vuelta w/h*/
  if (rotation % 180 && prd.rotateY) _h = prd.itemData.size.d; /*Si la rotación es horizontal y además hay rotación en Y da vuelta w/h*/ //DES-4510
  if (prd.stacksLyingDown && !_stacksLyingDownFirst) {
    /*Si la rotación es horizontal y además hay rotación en Y da vuelta w/h*/ //DES-4510
    _h = rotation % 180 ? prd.itemData.size.w : prd.itemData.size.d;
  }

  let maxStacksPerShelfH;

  let pallet_height = prd?.isPallet ? DEFAULT_PALLET_HEIGHT : 0;

  /* TODO: id:Vnl7odhSPz  Tener en cuenta productos como "sillas plegables" Porcentaje de apilado / dirección / no stacksLyingDown */
  if (!stacksLyingDown || !_stacksLyingDownFirst) {
    //Apilado normal
    maxStacksPerShelfH = Math.floor((parentShelfH - (DEFAULT_FINGER_SPACE + pallet_height)) / _h);
  } else {
    //Apilado stacksLyingDown pero con el 1er prod de frente
    let _h2 = prd.itemData.size.d || prd.itemData.size.w;
    maxStacksPerShelfH = Math.floor((parentShelfH - (DEFAULT_FINGER_SPACE + pallet_height) - _h) / _h2) + 1;
  }

  return Math.min(maxStacksProd, maxStacksPerShelfH);
};
