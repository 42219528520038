import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { _log } from '@shared/aux_helper_environment';
import {
  _debounceDecorator,
  _separateStringByDelimiters,
  _strSplitIntegers,
  _strSplitStrings,
  _strSplitUserName,
} from '@shared/aux_helper_functions';
import { IanTranslateService, PrismaDynamicEnv } from 'core/services/ian-core-singleton.service';

@Component({
  selector: 'input-clipboard',
  templateUrl: './clipboard.component.html',
  styleUrls: ['./clipboard.component.scss'],
})
export class ClipboardComponent implements OnInit {
  @ViewChild('clipboard', { static: true }) clipboard: HTMLInputElement;
  @Input() title = 'M_SHOPPER.MISSIONS.CLIPBOARD_TITLE_DEFUALT';
  @Input() supportStringCode = false;
  @Input() supportUserCode = false;
  @Output() codesLoaded = new EventEmitter<Array<number>>();
  maxCount = this.prismaDynamicEnv.getConf('brandCustomization.clipboard.maximunNumberOfCodesToPaste');
  MAXCODES_MESSAGE;

  constructor(private cd: ChangeDetectorRef, private prismaDynamicEnv: PrismaDynamicEnv, private translate: IanTranslateService) {
    this.MAXCODES_MESSAGE = this.translate.instant('M_SHOPPER.MISSIONS.CLIPBOARD_MAX', this.maxCount);
  }

  spinner = false;
  input = null;
  inputTextArea = null;
  codes = new Array<any>();
  maxCodes = false;
  inputFocused = false;
  inputBackup = null;

  focusInput(element) {
    _log('FOCUS');
    this.inputFocused = !!this.input || true;
    this.input = null;
    this.inputTextArea = ' ';
    element.focus();
  }

  blurInput() {
    this.inputFocused = !!this.inputBackup;
    this.inputTextArea = this.inputBackup;
    this.input = this.inputBackup;
  }

  @_debounceDecorator(256)
  formatearData(event) {
    this.inputTextArea = event.target.value;
    this.input = event.target.value;
    this.inputBackup = this.input;
    let _auxCodes;
    _auxCodes = _separateStringByDelimiters(this.input);
    // si es igual o mayor al numero maximo de registros permitidos, invalido la operacion, si el numero maximo es 0 permito todas las operaciones sin limite de registros pegados.
    this.maxCodes = this.maxCount === 0 ? false : _auxCodes && _auxCodes.length >= this.maxCount;
    if (!this.maxCodes) {
      // si hay codigos y cumple con la cantidad permitida almaceno y emito
      this.codes = [...new Set(_auxCodes)];
      this.codes = this.codes.filter(x => !!x && x !== ',');
      this.codesLoaded.emit(this.codes);
    }
  }

  ngOnInit() {}
}
