import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterModule, Routes, UrlTree } from '@angular/router';
import { UnauthorizedComponent } from '@prisma/unauthorized/unauthorized.component';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { APP_EMPTY_PATH_REDIRECT_TO_DEFAULT_ROUTE } from './app.config';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { DynReportComponent } from '@prisma/components/dyn-report/dyn-report.component';
import { _isDev, _isFeDev, _isLab, _isStaging, _log } from '@shared/aux_helper_environment';
import { Observable, of } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map } from 'rxjs/operators';
import { byPassPermissionsAccessModule } from './services/permissions-manager.service';
const byPassPermissions = byPassPermissionsAccessModule;
@Injectable()
export class PrismaCanActivateRoute implements CanActivate {
  constructor(private oidcSecurityService: OidcSecurityService) {}

  /*
  Custom canActivate // se fija si el usuario tiene algún rol, si no, se usa para deshabilitar las rutas DES-486
  */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.oidcSecurityService.getUserData().pipe(
      map(data => {
        let hasRole = Boolean(data?.role?.length) || _isDev();

        if (!hasRole && (_isDev() || _isLab())) console.warn('user has no role');

        return hasRole;
      })
    );
  }
}

const routes: Routes = [
  APP_EMPTY_PATH_REDIRECT_TO_DEFAULT_ROUTE,
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
  },
  {
    path: 'prices',
    loadChildren: () => import('./prices/prices.module').then(m => m.PricesModule),
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
    data: byPassPermissions
      ? {}
      : {
          permissions: {
            only: ['prices_view'],
            redirectTo: {
              default: '/unauthorized',
            },
          },
        },
  },
  {
    path: 'assortment',
    loadChildren: () => import('./assortment/assortment.module').then(m => m.AssortmentModule),
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
    data: byPassPermissions
      ? {}
      : {
          permissions: {
            only: ['assortment_view'],
            redirectTo: {
              default: '/unauthorized',
            },
          },
        },
  },
  {
    path: 'supply',
    loadChildren: () => import('./supply/supply.module').then(m => m.SupplyModule),
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
    data: byPassPermissions
      ? {}
      : {
          permissions: {
            only: ['vendor_view'],
            redirectTo: {
              default: '/unauthorized',
            },
          },
        },
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin-core.module').then(m => m.AdminCoreModule),
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
    data: byPassPermissions
      ? {}
      : {
          permissions: {
            only: ['admin_view'],
            redirectTo: {
              default: '/unauthorized',
            },
          },
        },
  },
  {
    path: 'mystery-shopper',
    loadChildren: () => import('./mystery-shopper/mystery-shopper.module').then(m => m.MysteryShopperModule),
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
    data: byPassPermissions
      ? {}
      : {
          permissions: {
            only: ['mysteryShopper_view'],
            redirectTo: {
              default: '/unauthorized',
            },
          },
        },
  },
  {
    path: 'item-master',
    loadChildren: () => import('./item-master-v2/item-masterv2.module').then(m => m.ItemMasterV2Module),
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
    data: byPassPermissions
      ? {}
      : {
          permissions: {
            only: ['itemmaster_view'],
            redirectTo: {
              default: '/unauthorized',
            },
          },
        },
  },
  {
    path: 'promotionsV2',
    loadChildren: () => import('./promotionsv2/promotionsv2.module').then(m => m.PromotionsV2Module),
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
    data: byPassPermissions
      ? {}
      : {
          permissions: {
            only: ['promotion_view'],
            redirectTo: {
              default: '/unauthorized',
            },
          },
        },
  },
  {
    path: 'reports/general-reports',
    loadChildren: () => import('./prisma/components/prisma-reports-v2/prisma-reports-v2.module').then(m => m.PrismaReportsV2Module),
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
  },
  {
    path: 'report/:id',
    component: DynReportComponent,
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
  },
  {
    path: 'report/:id/:params',
    component: DynReportComponent,
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'admin/master/stores',
    loadChildren: () => import('./admin/master/master.module').then(m => m.MasterModule),
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
    data: byPassPermissions
      ? {}
      : {
          permissions: {
            only: ['storemaster_view'],
            redirectTo: {
              default: '/unauthorized',
            },
          },
        },
  },
  {
    path: 'spaces',
    loadChildren: () => import('./spaces/spaces.module').then(m => m.SpacesModule),
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
    data: byPassPermissions
      ? {}
      : {
          permissions: {
            only: ['spaces_view'],
            redirectTo: {
              default: '/unauthorized',
            },
          },
        },
  },
  {
    path: 'test',
    loadChildren: () => import('./test/test.module').then(m => m.TestModule),
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
    data:
      _isDev() || _isLab() || _isStaging()
        ? {}
        : {
            permissions: {
              only: ['not_show'],
              redirectTo: {
                default: '/unauthorized',
              },
            },
          },
  },
  {
    path: 'selling',
    loadChildren: () => import('./selling/selling.module').then(m => m.SellingModule),
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
  },
  {
    path: 'helps',
    loadChildren: () => import('./helps/helps.module').then(m => m.HelpsModule),
    canActivate: [NgxPermissionsGuard, PrismaCanActivateRoute],
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
