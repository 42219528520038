import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AttributeItemModel } from 'core/models/attribute.model';
import { _containNormaliceStrings } from '@shared/aux_helper_functions';

import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { dinamicTagsAttributeName } from '../dinamic-tags/dinamic-tags.component';

@Component({
  selector: 'ian-add-attribute-modal',
  templateUrl: './ian-add-attribute-modal.component.html',
  styleUrls: ['./ian-add-attribute-modal.component.scss'],
})
export class IanAddAttributeModalComponent {
  selection: Array<AttributeItemModel> = [];
  contains = _containNormaliceStrings;
  _lookUp = '';
  options: Array<AttributeItemModel> = [];
  constructor(
    private translate: IanTranslateService,
    public dialogRef: MatDialogRef<IanAddAttributeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalDataAddAttribute
  ) {
    this.options = this.data.list;
  }

  setSelected(event): void {
    this.selection = event.value;
  }

  close(action: string): void {
    this.dialogRef.close(action);
  }

  getTranslate(option): string {
    return dinamicTagsAttributeName(option, this.translate);
  }

  filterOptions(searchValue: string): void {
    const _searchValue = searchValue.toLowerCase();
    this.options = this.data.list.filter(option => {
      const _option = this.getTranslate(option);
      return _option.toLowerCase().includes(_searchValue);
    });
  }

  toggleSelection(): void {
    if (this.selection.length) {
      if (this.selection.length === this.data.list.length) {
        this.selection = [];
      } else {
        this.selection = [...this.data.list];
      }
    } else {
      this.selection = [...this.data.list];
    }
  }
}

export interface ModalDataAddAttribute {
  selectAll: boolean;
  list: Array<AttributeItemModel>;
}
