import { IanTranslateService } from 'core/services/ian-core-singleton.service';

/**
 * toggle para cambiar icono y traduccion de tooltip al maximizar o minimizar tabla
 * @param _toolbarActionButtons listado de acciones a modificar
 * @param _translate servicio para traducir
 * @param elementStr tag/id/clase del elemento a maximizar o minimizar
 */
export const _toggleFullscreen = (
  _toolbarActionButtons: Array<any>,
  translate: IanTranslateService,
  elementStr = 'mat-sidenav-container'
): void => {
  const doc = window.document as any;
  const docEl = document.querySelector(elementStr) as any;

  const requestFullScreen =
    docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
  const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

  const actionFullScreen = _toolbarActionButtons.find(action => action.type === 'fullScreen');
  if (actionFullScreen != undefined) {
    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement &&
      actionFullScreen.icon == 'fullscreen'
    ) {
      requestFullScreen.call(docEl);

      actionFullScreen.icon = 'fullscreen_exit';
      actionFullScreen.tooltip = translate.instant('PR.STP.COMP.EXITFULLSCREEN_TTIP');
    } else {
      if (doc.fullscreenElement || doc.mozFullScreenElement || doc.webkitFullscreenElement || doc.msFullscreenElement) {
        cancelFullScreen.call(doc);
      }

      actionFullScreen.icon = 'fullscreen';
      actionFullScreen.tooltip = translate.instant('PR.STP.COMP.FULLSCREEN_TTIP');
    }

    const listactionbtn = _toolbarActionButtons.map(action => (action.type === 'fullscreen' ? actionFullScreen : action));

    _toolbarActionButtons = [...listactionbtn];
  }
};

/**
 * toggle para cambiar icono y traduccion de tooltip al maximizar o minimizar tabla
 * @param _toolbarActionButtons listado de acciones a modificar
 * @param _translate servicio para traducir
 * @param _tooltip array<string> titulos tooltip
 * @param elementStr tag/id/clase del elemento a maximizar o minimizar
 */
export const _switchAction = (
  _toolbarActionButtons: Array<any>,
  translate: IanTranslateService,
  _tooltip: Array<string>,
  _action: any
): void => {
  const actionButton = _toolbarActionButtons.find(action => action.type === 'switch');
  if (!actionButton) return;
  if (actionButton.icon === 'toggle_off') {
    actionButton.icon = 'toggle_on';
    actionButton.iconClass = 'ian-green';
    actionButton.classActive = true;
    actionButton.tooltip = translate.instant(_tooltip[0]);
  } else {
    actionButton.icon = 'toggle_off';
    actionButton.classActive = false;
    actionButton.tooltip = translate.instant(_tooltip[1]);
  }
  _action(actionButton.icon);

  const listactionbtn = _toolbarActionButtons.map(action => (action.type === 'switch' ? actionButton : action));

  _toolbarActionButtons = [...listactionbtn];
};
