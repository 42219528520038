<div [attr.data-test-id]="'modal_attribute'">
  <h1 mat-dialog-title>{{'COMP.ADD_ATTRIBUTE.TITLE_MODAL' | pTranslate}}</h1>
  <div mat-dialog-content fxLayout="column" class="mb-20">
    <mat-form-field data-test-id="select_add_tags">
      <mat-select
        placeholder="{{'COMP.ADD_ATTRIBUTE.SELECT_ATTRIBUTE' | pTranslate}}"
        [value]="selection"
        (selectionChange)="setSelected($event)"
        multiple
        [attr.data-test-id]="'item-master-select-dinamic-tags'"
      >
        <div class="lookUpInput">
          <input
            placeholder="{{'COMP.ADD_ATTRIBUTE.SEARCH_ATTRIBUTE' | pTranslate}}"
            [value]="_lookUp"
            (input)="_lookUp = $event.target.value; filterOptions(_lookUp)"
            (keydown)="$event.stopPropagation()"
          />
        </div>
        <mat-checkbox
          *ngIf="data.selectAll && (options.length === data.list.length)"
          class="mat-option"
          [checked]="selection && selection.length === data.list.length"
          [indeterminate]="selection && selection.length && selection.length < data.list.length"
          (click)="$event.stopPropagation()"
          (change)="toggleSelection()"
          data-test-id="checkbox_select_all_dinamic_tags"
        >
          {{'COMP.ADD_ATTRIBUTE.SELECT_ALL' | pTranslate}}
        </mat-checkbox>
        <mat-divider></mat-divider>

        <ng-container *ngFor="let option of options">
          <mat-option
            [value]="option"
            color="accent"
            [attr.data-test-id]="option.id"
            [attr.data-test-id-text-translate]="(getTranslate(option)| lowercase)"
          >
            {{ getTranslate(option) }}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions fxLayoutAlign="end">
    <button mat-button color="secondary" (click)="close('cancel')">
      {{'COMP.ADD_ATTRIBUTE.CANCEL_BTN' | pTranslate}}
    </button>
    <button
      mat-raised-button
      color="accent"
      (click)="close('add')"
      [disabled]="!selection"
      data-test-id="add_tags_button"
    >
      {{'COMP.ADD_ATTRIBUTE.ADD_BTN' | pTranslate}}
    </button>
  </div>
</div>
