import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionsV2_StatusEnum, ActionsV2_ValidationStatus } from 'app/promotionsv2/actions/actions-edit/store/actionsV2-FormStateModel';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';

const _aux_getGenericStatusOptionsMap = (data): GenericStatusItemMasterModel[] => {
  return data;
};

export const _aux_getEnumStringByIdStatus = (id, upcase = true) => {
  const _enum = ABM_StatusEnum_Obj[id];
  const trad = _enum.toString();
  return upcase ? trad.toUpperCase() : trad;
};

export const _aux_getEnumStringByIdValidationStatus = (id, upcase = true) => {
  const _enum = ABM_ValidationStatusEnum_Obj[id];
  const trad = _enum.toString();
  return upcase ? trad.toUpperCase() : trad;
};

export const _aux_getEnumStringByIdStatusActions = (id, upcase = true) => {
  const _enum = ABM_StatusEnum_ObjActions[id];
  const trad = _enum.toString();
  return upcase ? trad.toUpperCase() : trad;
};

export enum ABM_StatusEnum {
  Current = 'Current', // > no edita
  New = 'New',
  Endorsed = 'Endorsed',
  Informed = 'Informed',
  Deleted = 'Deleted',
  Modified = 'Modified', // > no edita, Permite volver a publicar
  Cancelled = 'Cancelled',
}

export enum ABM_publishStatus {
  None = 'None',
  Processing = 'Processing',
  Error = 'Error',
  Deleting = 'Deleting',
}

export interface GenericStatusItemMasterModel {
  id: number;
  name: string;
}

export const ABM_StatusEnum_Obj = Object.keys(ABM_StatusEnum);
export const ABM_StatusEnum_ObjActions = Object.keys(ActionsV2_StatusEnum);
export const ABM_ValidationStatusEnum_Obj = Object.keys(ActionsV2_ValidationStatus);

const dummyGenericStatusOptionsFilter = (idsToConserve = [0, 1, 5]) => {
  const _ABM_StatusEnum = ABM_StatusEnum_Obj.map((status, index) => {
    return { id: index, name: status.toString().toUpperCase() };
  });
  return _ABM_StatusEnum.filter(x => idsToConserve.some(i => i === x.id));
};

const dummyGenericStatusOptions = [
  {
    id: 0,
    name: ABM_StatusEnum.Current.toString().toUpperCase(),
  },
  {
    id: 1,
    name: ABM_StatusEnum.New.toString().toUpperCase(),
  },
  {
    id: 5,
    name: ABM_StatusEnum.Modified.toString().toUpperCase(),
  },
];

const evaluateEnum = (value, key, status) => (key === 'name' ? value.toLowerCase() === status.toLowerCase() : value === status);
export const getGenericStatusOptionName = (status): string => {
  const key = typeof status === 'number' ? 'id' : 'name';
  return dummyGenericStatusOptions.find(en => (en[key], key, status))?.name;
};

@Injectable({
  providedIn: 'root',
})
export class GenericItemStatusService {
  constructor(public translate: IanTranslateService, private http: HttpClient, private permissionService: NgxPermissionsService) {}

  getGenericStatusOptions(idsToConserve): Observable<Array<GenericStatusItemMasterModel>> {
    const configStatus = dummyGenericStatusOptionsFilter(idsToConserve);
    const dummyGenericStatusOptionsObs = _aux_getGenericStatusOptionsMap(configStatus);

    return new Observable(data => {
      data.next(dummyGenericStatusOptionsObs);
      data.complete();
    });
  }
}
