import { Injectable } from '@angular/core';
import { _isFeDev, _isLab, _log, _warn } from '@shared/aux_helper_environment';
import { _roundDec, _strToHash, _throttleDecorator, _timeout, _toNumber } from '@shared/aux_helper_functions';
import { _getAppZoomScale, _setAppZoomScale } from 'app/layout/components/global-settings-panel/global-settings-panel.component';
import localforage from 'localforage';
import packageJson from '../../../package.json';
import { _getStaticBrandCustomization } from './ian-core-singleton.service';
const pako = require('pako');

const packageJsonVersion = packageJson?.version || '3.1.1';

const __downloadBlob = (data, fileName, mimeType) => {
  let blob = new Blob([data], { type: mimeType });
  let url = window.URL.createObjectURL(blob);

  __downloadURL(url, fileName);

  setTimeout(function () {
    return window.URL.revokeObjectURL(url);
  }, 1000);
};

const __downloadURL = (data, fileName) => {
  let a: any = document.createElement('a');
  a.href = data;
  a.download = fileName;
  document.body.appendChild(a);
  a.style = 'display: none';
  a.click();
  a.remove();
};

const __loadDynamicScript = (url, callback) => {
  let scriptId: string = String(_strToHash(url));

  const existingScript = document.getElementById(scriptId);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = url;
    script.id = scriptId;
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  }

  if (existingScript && callback) callback();
};

const __captrureAppScreen = (saveImg = true) => {
  return new Promise((resolve, reject) => {
    const html2canvasURL = './assets/html2canvas.min.js';

    const scale = _getAppZoomScale();
    const modalError = document?.querySelector?.('app-error-message-modal')?.closest?.('.cdk-overlay-container');

    const html2canvasCallBack = function () {
      let html2canvas = (window as any).html2canvas;

      if (scale !== 1) _setAppZoomScale(1, false);
      if (modalError) modalError.classList.add('__hidden');

      html2canvas(document.querySelector('html'), {
        logging: false,
        letterRendering: 1,
        width: window.innerWidth,
        height: window.innerHeight,
      })
        .then(function (canvas) {
          let image = canvas.toDataURL('image/png', 0.5);
          if (saveImg) __downloadURL(image, 'appCapture.png');
          resolve(image);
        })
        .catch(e => {
          reject(e);
        })
        .finally(function () {
          if (scale !== 1) _setAppZoomScale(scale, false);
          if (modalError) modalError.classList.remove('__hidden');
        });
    };

    __loadDynamicScript(html2canvasURL, html2canvasCallBack);
  });
};

@Injectable({
  providedIn: 'root',
})
export class ConsoleLogger {
  private initied = false;
  private localForageStore = null;
  private localForageStoreKey = 'ConsoleLogger';
  private maxMsg = 512;
  private initAppTimeStamp = null;

  private config = {
    enabled: true,
    logRuntimeErrors: true,
    logConsoleErrors: true,
  };

  constructor() {}

  public get isInitied(): boolean {
    return this.initied;
  }

  public async init(config?) {
    this.initAppTimeStamp = Date.now();

    if (config) this.config = { ...this.config, ...config };
    if (config?.maxMsg > 0) this.maxMsg = config.maxMsg;

    await this.initLocalForage();

    if (this.initied || this.config?.enabled !== true) return;
    this.initied = this.localForageStore != null;
    if (!this.initied) return;

    this.extendErrors();

    const _window: any = window;

    if (_window != null && true) {
      _window.__printAllLoggerKeys = (size?) => {
        this.printAllKeys(size);
      };

      _window.__printLastLoggerKey = () => {
        this.printLastKey();
      };

      _window.__downloadAllLoggerKeys = (size?, withImage?) => {
        this.downloadAllKeys(size, withImage);
      };

      _window.__clearAllLoggerKeys = () => {
        this.clearAllKeys();
      };

      if (_isFeDev() || _isLab()) {
        _window.__createRunTimeError = () => {
          this.createRunTimeError();
        };

        _window.__captureScreen = () => {
          this.captureScreen();
        };
      }
    }

    if (_isFeDev()) _log('[INIT ConsoleLogger]');
  }

  private extendErrors() {
    if (this.config?.logRuntimeErrors && window != null) {
      window.onerror = (event, url, line, col, error) => {
        const timeStamp = Date.now();

        this.addError(
          {
            type: 'runtime_error',
            error: { url, line, col, error: error?.toLocaleString?.(), event: event?.toString?.() || event },
            errorRaw: { error: error, event },
            timeStamp,
          },
          timeStamp
        );

        if (true && _isFeDev()) {
          _log('[RUNTIME_ERROR]', {
            event: event,
            error: error,
            url,
            line,
            col,
          });
        }
      };
    }

    if (this.config?.logConsoleErrors && console?.error != null) {
      const _error = console.error?.bind?.(console);

      console.error = (...args) => {
        const timeStamp = Date.now();

        const rvError: any = { type: 'console_error', timeStamp };

        if (args != null) {
          try {
            rvError.error = args?.at?.(-1) || JSON.stringify([...args]);
          } catch (e) {
            _warn('[onErrorLogger] args[1] >', e, args);
          }

          if (rvError?.error?.headers != null && rvError?.error?.url?.length) rvError.type = 'endpoint-error';

          if (rvError?.type !== 'endpoint-error') {
            try {
              rvError.errorRaw = JSON.stringify([...args]);
            } catch (e) {
              _warn('[onErrorLogger] ...args >', e, args);
            }
          }
        }

        if (_error != null && args != null) {
          try {
            _error(...args);
          } catch (e) {
            _warn('[onErrorLogger] trigger error >', e, args);
          }
        }

        this.addError(rvError, timeStamp);
      };
    }
  }

  private async initLocalForage() {
    this.localForageStore = await localforage.createInstance({
      name: this.localForageStoreKey,
      driver: localforage.WEBSQL,
    });

    await _timeout(1);

    return this.localForageStore != null;
  }

  private getMetadata(timeStamp) {
    return {
      date: new Date(_toNumber(timeStamp)).toUTCString(),
      initAppTimeStamp: this.initAppTimeStamp,
      initAppTimeDate: new Date(_toNumber(this.initAppTimeStamp)).toUTCString(),
      deltaInitAppMin: _roundDec((_toNumber(timeStamp) - _toNumber(this.initAppTimeStamp)) / (60 * 1000), 2),
      appVersion: packageJsonVersion,
      appUrl: document?.URL,
      appIsOnLine: navigator?.onLine,
    };
  }

  byPassError(err) {
    let errorRaw0 = err?.errorRaw?.[0];

    if (errorRaw0 && errorRaw0.indexOf?.('webpack') !== -1) return true;
    if (err?.errorRaw && err.errorRaw?.indexOf?.('webpack') !== -1) return true;
    if (err && err?.indexOf?.('webpack') !== -1) return true;
    if (err?.error?.code == -100) return true;

    return false;
  }

  @_throttleDecorator(256, { trailing: false })
  private async addError(err, timeStamp = null) {
    if (this.localForageStore == null) return;

    if (this.byPassError(err)) return;

    if (false) console.log('[ERR]', err, JSON.stringify(err));

    const key = String(timeStamp || Date.now());

    await this.localForageStore.setItem(key, {
      isError: true,
      ...this.getMetadata(key),
      ...err,
    });

    this.checkForClean();
  }

  private async addData(data, timeStamp = null) {
    if (this.localForageStore == null) return;

    if (false) console.log('[ERR]', data, JSON.stringify(data));

    const key = String(timeStamp || Date.now());

    await this.localForageStore.setItem(key, {
      isError: false,
      ...this.getMetadata(key),
      ...data,
    });

    this.checkForClean();
  }

  private async checkForClean() {
    if (this.localForageStore == null) return;

    let length = await this.localForageStore.length();

    if (length < this.maxMsg + 10) return;

    this.cleanMaxMsgs();
  }

  private _isInClean = false;
  private async cleanMaxMsgs() {
    if (this.localForageStore == null) return;

    if (this._isInClean) return;
    this._isInClean = true;

    const keys = await this.localForageStore.keys();
    if (!(keys && keys?.length > this.maxMsg + 10)) return (this._isInClean = false);

    const keysSorted = [...keys].sort();
    const maxDelta = keys?.length - this.maxMsg;
    const keysToDel = keysSorted.slice(0, maxDelta);

    if (!(keysToDel && keysToDel?.length > 0)) return (this._isInClean = false);

    await Promise.all(
      keysToDel.map(async key => {
        await this.localForageStore.removeItem(key);
      })
    );

    this._isInClean = false;
  }

  private async printAllKeys(size?) {
    let allKeys = await this.getAllKeysWithMetadata(size);

    console.log('[ConsoleLogger] errors:\n', allKeys);
  }

  private async clearAllKeys() {
    return await this.localForageStore.clear();
  }

  private async downloadAllKeys(size?, withImage = false, extendObj?) {
    let captureTimeStamp = Date.now();
    let captureDate = new Date(_toNumber(captureTimeStamp)).toUTCString();

    let allKeys: any = await this.getAllKeysWithMetadata(size);
    if (allKeys == null) return;

    if (withImage && true) {
      //Decode with: https://base64.guru/converter/decode/image/png
      let img = null;

      try {
        img = await this.captureScreen(false);
      } catch (e) {}

      if (img) {
        allKeys.appCapture = img;
      }

      await _timeout(1);
    }

    allKeys = { captureTimeStamp, captureDate, ...allKeys };

    if (extendObj != null) allKeys = { ...allKeys, ...extendObj };

    const compressed = pako.gzip(JSON.stringify(allKeys, null, 2), { to: 'string' });
    const timeStamp = Date.now();
    const filename = `error_log_${timeStamp}.gzip`;

    if (_isFeDev()) console.log('[downloadErrorLog] errors:\n', compressed);

    __downloadBlob(compressed, filename, 'application/octet-stream');
  }

  public downloadConsoleLog(size?, withImage?) {
    return this.downloadAllKeys(size, withImage);
  }

  private async printLastKey() {
    let lastKey = await this.getLastKey();

    console.log('[ConsoleLogger] last error:\n', lastKey);
  }

  private async getAllKeys() {
    let keys = await this.localForageStore.keys();
    let rv = {};

    if (!(keys && keys.length > 0)) return {};

    await Promise.all(
      [...keys].sort().map(async key => {
        rv[key] = await this.localForageStore.getItem(key);
      })
    );

    return rv || {};
  }

  private async getAllKeysWithMetadata(size = 128) {
    let allKeys = await this.getAllKeys();

    let metaData = {
      navigator: navigator?.appVersion,
      appInfo: (window as any)?._info,
    };

    let keysArr = Object.keys(allKeys || {})
      .sort()
      .reverse()
      .slice(0, size)
      .reverse();
    let keys = {};

    keysArr.forEach(k => {
      keys[k] = allKeys[k];
    });

    return { allKeys: keys, metaData };
  }

  private async getLastKey() {
    let keys = await this.localForageStore.keys();
    let rv = {};

    if (!(keys && keys.length > 0)) return {};

    let lastKey = [...keys].sort().at(-1);
    if (lastKey) rv[lastKey] = await this.localForageStore.getItem(lastKey);

    return rv || {};
  }

  private async createRunTimeError() {
    await _timeout(100);

    let test = null;

    _log('[ERROR:]', (test as any).a);
  }

  private async captureScreen(saveImg?) {
    return __captrureAppScreen(saveImg);
  }
}
