<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
  <div class="logo" fxLayoutGap="8px">
    <img class="logo-icon" src="assets/Prisma_NewLogo_2024.svg" />
    <!-- <span class="logo-text">prisma</span> -->
  </div>

  <div class="buttons">
    <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-md>
      <mat-icon class="secondary-text">menu</mat-icon>
    </button>

    <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-sm>
      <mat-icon class="secondary-text">arrow_back</mat-icon>
    </button>
  </div>
</div>

<div class="navbar-scroll-container noOverflowX" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
  <div class="user" fxLayout="column" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
    <!-- <div class="h3 username">Miguel Gonzales</div>
        <div class="h5 email hint-text mt-8">miguel.gonzales@mail.com</div> -->
    <div class="avatar-container" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
      <img class="avatar" src="assets/images/avatars/profile.jpg" />
    </div>
  </div>

  <span>
    <div class="navbar-content">
      <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>
  </span>
</div>
