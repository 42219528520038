import { style } from '@angular/animations';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { _log } from '@shared/aux_helper_environment';
import { _cloneDeep, _timeout } from '@shared/aux_helper_functions';
import { first } from 'rxjs/operators';
import { GenericTagsSelectV2Service } from './generic-tag-select-v2.service';
import { TagTreeSelectedModel } from './generic-tags-select-v2.component';

@Component({
  selector: 'generic-tags-select-multiple-v2-attribute-modal',
  template: `
    <div [attr.data-test-id]="'modal_attribute'">
      <h1 mat-dialog-title>{{ 'COMP.ADD_ATTRIBUTE.TITLE_MODAL' | pTranslate }}</h1>
      <div mat-dialog-content fxLayout="column" class="mb-20">
        <!-- -->
        <div style="min-height: 80px;">
          <form *ngIf="attrList?.length && listReady">
            <generic-lookup
              [values]="attrList"
              [selected]="selected"
              [multiple]="true"
              [required]="false"
              (onChange)="onChange($event)"
              propId="tagId"
              propValue="tagName"
              [placeholder]="'COMP.ADD_ATTRIBUTE.SELECT_ATTRIBUTE' | pTranslate"
              [attr.data-test-id]="'generic-attr-select-v2'"
            ></generic-lookup>
          </form>
        </div>
        <!-- -->
      </div>
      <div mat-dialog-actions fxLayoutAlign="end">
        <button mat-button color="secondary" (click)="close()">
          {{ 'COMP.ADD_ATTRIBUTE.CANCEL_BTN' | pTranslate }}
        </button>
        <button mat-raised-button color="accent" (click)="save()" data-test-id="add_tags_button">
          {{ 'COMP.ADD_ATTRIBUTE.ADD_BTN' | pTranslate }}
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .test {
      }
    `,
  ],
})
export class GenericTagsSelectMultiplev2AttributeModal implements OnInit {
  attrList: TagTreeSelectedModel[] = null;
  selected: number[] = null;
  excludeList: number[] = [];
  tagsModuleType = null;
  alreadyAdded: number[] = null;
  listReady = false;

  constructor(
    public dialogRef: MatDialogRef<GenericTagsSelectMultiplev2AttributeModal>,
    private service: GenericTagsSelectV2Service,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.tagsModuleType = this.data.tagsModuleType || 1;
    this.alreadyAdded = this.data.alreadyAdded || [];
    this.excludeList = this.data?.excludeList || [];
    if (this.data.selected) this.selected = _cloneDeep(this.data.selected);
  }

  ngOnInit(): void {
    this.service
      .geTagsTree(this.tagsModuleType || 1)
      .pipe(first())
      .subscribe(async data => {
        if (data?.length) this.makeArrList(data);
      });
  }

  async makeArrList(data) {
    if (this.attrList?.length) return;

    let rv = [];
    let values = data[0]?.values;

    values.forEach(obj => {
      if (!obj?.tagId) return;
      let finded = (this.alreadyAdded || []).includes(obj.tagId) || rv.find(tag => tag.tagId === obj.tagId);
      if (!finded) rv.push({ tagId: obj.tagId, tagName: obj.tagName });
    });
    if (this.excludeList?.length > 0) rv = rv.filter(x => this.excludeList.includes(x.tagId));
    this.attrList = rv;

    if (true) _log('[makeArrList]', this.attrList);

    await _timeout(256);
    this.listReady = true;
    this.cd && this.cd.detectChanges();
  }

  save(): void {
    this.dialogRef.close(this.selected);
  }

  close(): void {
    this.dialogRef.close(null);
  }

  onChange(ev) {
    this.selected = ev;
  }
}
