<h1 mat-dialog-title>{{ title | pTranslate }}</h1>
<div mat-dialog-content fxLayout="column" class="mb-20">
  <div fxLayout="column" class="mb-24">
    <span fxFlex="100" fxFlex.gt-md="calc(50%-32px)" class="mb-24">
      {{ 'M_SHOPPER.MISSIONS.CLIPBOARD_STEP1' | pTranslate }}
    </span>

    <mat-form-field fxFlex="100" fxFlex.gt-md="calc(50%-32px)">
      <mat-label>{{ 'M_SHOPPER.MISSIONS.TYPE_CODE' | pTranslate }}</mat-label>

      <mat-select tabindex="1" [ngModel]="type" (selectionChange)="type = $event.value" required [disabled]="true">
        <mat-option *ngFor="let typeCode of typeCodes" [value]="typeCode.value" [disabled]="typeCode.disabled">
          {{ typeCode.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="column" class="mb-24">
    <span fxFlex="100">{{ 'M_SHOPPER.MISSIONS.CLIPBOARD_STEP2' | pTranslate }}</span>
    <img src="assets/images/clipboard.png" class="clipboard-img" />
  </div>

  <div fxLayout="column" class="mb-24">
    <input-clipboard
      [title]="'M_SHOPPER.MISSIONS.CLIPBOARD_STEP3'"
      [supportStringCode]="true"
      (codesLoaded)="getDataStore($event)"
    ></input-clipboard>
  </div>

  <div class="spinner-container" style="min-height: 100px; margin-top: -50px" *ngIf="loading">
    <custom-loader></custom-loader>
  </div>

  <div
    fxLayout="column"
    *ngIf="!loading && dataSource && dataSource.data && dataSource.data.length > 0"
    (scroll)="onScroll($event)"
    class="overflow-table"
  >
    <prisma-table
      [fxHideMainRow]="hiddenMainRow"
      [dataSource]="dataSource"
      [columnsConfig]="columnsType"
      [responsive]="{minCellWidth: 70}"
    >
      <!-- COLUMNA DE ELEMENTOS NO ENCONTRADOS  -->
      <ng-container matColumnDef="notfoundColumn">
        <div *matCellDef="let element" fxFlex="100">
          <prisma-table-sub-row
            *ngIf="element.notfound"
            [addSpace]="false"
            [dataSource]="element.notFoundElement"
            [columnsConfig]="ColumnsTypeNotFound"
          ></prisma-table-sub-row>
        </div>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: ['notfoundColumn']" class="collaspsed-sub-row"></tr>
    </prisma-table>
  </div>
</div>

<div mat-dialog-actions fxLayoutAlign="end">
  <div fxLayoutAlign="end">
    <button mat-button color="secondary" (click)="closeDialog()">
      {{ 'M_SHOPPER.MISSIONS.CANCEL_BTN' | pTranslate }}
    </button>
    <button mat-raised-button color="accent" (click)="addCodes()" [disabled]="!(codes?.length > 0 && type != null)">
      {{ 'M_SHOPPER.MISSIONS.ADD_BTN' | pTranslate }}
    </button>
  </div>
</div>
