<div *ngIf="!text && useCustomLoader" [ngStyle]="{'transform': 'scale('+size+')', 'margin-top': top}">
  <div class="custom-dot-pulse"></div>
</div>

<div *ngIf="text" [ngStyle]="{'transform': 'scale('+size+')'}">
  <div class="custom-spinner-text">{{text}}</div>
  <div [style.margin-top]="top" class="custom-dot-pulse"></div>
</div>

<mat-spinner *ngIf="!text && !useCustomLoader" [diameter]="diameter || 32" [color]="color"></mat-spinner>
