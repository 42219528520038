import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { _log } from '@shared/aux_helper_environment';
import { _cloneDeep } from '@shared/aux_helper_functions';
const JSON5 = require('json5');

@Component({
  selector: 'edit-json-modal',
  template: `
    <h1 mat-dialog-title>{{ title }}</h1>

    <div mat-dialog-content fxLayout="column" class="mb-20">
      <ng-container *ngIf="description?.length">
        <p>{{ description }}</p>
      </ng-container>
      <textarea matInput [value]="json || ''" (input)="onJsonChange($event?.target?.value)" [disabled]="readOnly"></textarea>
    </div>

    <div mat-dialog-actions fxLayoutAlign="end">
      <ng-container *ngIf="readOnly">
        <button mat-button color="secondary" (click)="close()">{{ 'GENERIC_CLOSE' | pTranslate }}</button>
      </ng-container>
      <ng-container *ngIf="!readOnly">
        <button mat-button color="secondary" (click)="close()">{{ 'GENERIC_CANCEL' | pTranslate }}</button>
        <button mat-raised-button color="accent" [disabled]="!jsonOutputValid" (click)="_acceptConfirmation()">
          {{ 'GENERIC_SAVE' | pTranslate }}
        </button>
      </ng-container>
    </div>
  `,
  styles: [
    `
      :host textarea {
        height: 400px;
        font-family: 'SFMono-Medium', 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', 'Ubuntu Mono', Menlo, Consolas, Courier, monospace;
        line-height: 1.4;
        font-size: 13px;
        margin: 0;
        padding: 0;
        background: #f8f9fc;
        color: #222 !important;
      }
    `,
  ],
})
export class EditJsonModal implements OnInit {
  title = null;
  json = null;
  jsonOutput = null;
  jsonOutputValid = null;
  readOnly = false;
  description = null;

  constructor(public dialogRef: MatDialogRef<EditJsonModal>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data == null) return;

    this.title = data.title;
    this.description = data.description;
    this.readOnly = data.readOnly;
    this.json = typeof data.json === 'string' ? JSON.stringify(JSON5.parse(data.json), null, 2) : JSON.stringify(data.json, null, 2);

    if (this.json == 'null' || this.json == null) this.json = '';

    this.onJsonChange(this.json);
  }

  ngOnInit(): void {}

  _acceptConfirmation(): void {
    this.dialogRef.close(this.jsonOutput);
  }

  close(): void {
    this.dialogRef.close(false);
  }

  onJsonChange(value): void {
    let _jsonObj = null;

    try {
      _jsonObj = value?.length ? JSON5.parse(value) : null;
      this.jsonOutputValid = true;
    } catch (e) {
      this.jsonOutputValid = false;
    }

    this.jsonOutput = _jsonObj;
  }
}
