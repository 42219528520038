import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { _log } from '@shared/aux_helper_environment';
import { _cloneDeep, _throwError } from '@shared/aux_helper_functions';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError, map } from 'rxjs/operators';

export interface AttributeItemModel {
  id: number;
  parentId: number;
  value: string | AttributeItemModel[];
  name?: string;
  _isCategory?: boolean;
  isRequired?: boolean;
  isGeo?: boolean;
}

const cudOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class DinamicTagsFormIncludedExcludedService {
  private config = environment.tagService.api;
  private configBase = environment.apiBaseUrl_prices;

  private cacheGetAllTagsItems = null;
  private cacheGetAllTagsStores = null;
  private cacheGetAllTagsCompetitors = null;

  constructor(private http: HttpClient) {}

  getAllTagsItems(): Observable<Array<AttributeItemModel>> {
    /*Si tiene cache lo devuelve y no va a BE*/
    if (this.cacheGetAllTagsItems) {
      return new Observable(observer => {
        observer.next(_cloneDeep(this.cacheGetAllTagsItems));
        observer.complete(); /* se marca al observable como completo, indicando que termino la transaccion y tiene los datos listos, necesario para forkJoin  */
      });
    }

    const url = `${this.configBase}${this.config.resources.dinamicTags}/` + 1; //1 items - 2 Stores
    if (true) _log('[getAllTagsItems generic]', url);

    return this.http.get<Array<AttributeItemModel>>(url).pipe(
      tap(data => _log(`${DinamicTagsFormIncludedExcludedService.name}::getAllTagsItems (tap)\n\tdata: %o`, data)),
      tap(data => {
        if (data) this.cacheGetAllTagsItems = data;
      }),
      catchError(error => _throwError(error, DinamicTagsFormIncludedExcludedService))
    );
  }

  getAllTagsStores(): Observable<Array<AttributeItemModel>> {
    /*Si tiene cache lo devuelve y no va a BE*/
    if (this.cacheGetAllTagsStores) {
      return new Observable(observer => {
        observer.next(this.cacheGetAllTagsStores);
        observer.complete(); /* se marca al observable como completo, indicando que termino la transaccion y tiene los datos listos, necesario para forkJoin  */
      });
    }

    const url = `${this.configBase}${this.config.resources.dinamicTags}/` + 2; //1 items - 2 Stores
    if (true) _log('[getAllTagsStores generic]', url);

    return this.http.get<Array<AttributeItemModel>>(url).pipe(
      tap(data => _log(`${DinamicTagsFormIncludedExcludedService.name}::getAllTagsStores (tap)\n\tdata: %o`, data)),
      tap(data => {
        if (data) this.cacheGetAllTagsStores = data;
      }),
      catchError(error => _throwError(error, DinamicTagsFormIncludedExcludedService))
    );
  }

  /**
   * actualmente este servicio no fue abstraido en BE, por lo que se usa del servicio creado para parent store en price rules
   */
  getAllTagsCompetitors(): Observable<Array<AttributeItemModel>> {
    /*Si tiene cache lo devuelve y no va a BE*/
    if (this.cacheGetAllTagsCompetitors) {
      return new Observable(observer => {
        observer.next(this.cacheGetAllTagsCompetitors);
      });
    }
    const config = environment.prices.setup.priceRules.api;
    const url = `${this.configBase}${config.resources.competenceRuleAttributes}/`;
    if (true) _log('[getAllTagsStores generic]', url);

    return this.http.get<Array<AttributeItemModel>>(url).pipe(
      map(data => _getCompetitorsTags(data)),
      tap(data => _log(`${DinamicTagsFormIncludedExcludedService.name}::getAllTagsCompetitors (tap)\n\tdata: %o`, data)),
      tap(data => {
        if (data) this.cacheGetAllTagsCompetitors = data;
      }),
      catchError(error => _throwError(error, DinamicTagsFormIncludedExcludedService))
    );
  }
}
const _getCompetitorsTags = (data: any): Array<AttributeItemModel> => {
  const _data = _cloneDeep(data);
  if (!_data || !_data.newRuleStepOneAttributes) return;
  return _data.newRuleStepOneAttributes;
};
