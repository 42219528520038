import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { _isDev, _log } from '@shared/aux_helper_environment';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';

@Component({
  selector: 'generic-modal-report',
  template: `
    <div *ngIf="content">
      {{ content }}
    </div>
  `,
})

//DES-1651
export class GenericModalWarningDelete implements OnInit {
  @Input() dataWarnings: any;
  @Input() tradBase: any;
  content = null;
  _isDev = _isDev();
  constructor(protected translate: IanTranslateService) {}

  ngOnInit(): void {
    this.setWarnings();
  }

  ngOnChanges(changes) {
    if (changes.tradBase && changes.tradBase.currentValue) {
      this.tradBase;
    }
    if (this.tradBase && changes.dataWarnings && changes.dataWarnings.currentValue) {
      this.setWarnings();
    }
  }
  setWarnings() {
    const $data = this.dataWarnings;

    let deleteAction = $data.deleteAction;
    let itemNotFound = $data.itemNotFound;
    let responseMessages = $data.responseMessages;
    let $content = !!$data.content ? $data.content + '\n\n' : this.translate.instant('GENERIC_ACTION_ROW_CONTENT_FAILED') + '\n\n';

    if (!itemNotFound) {
      $content += deleteAction ? this.getDeleteWarning(responseMessages) : this.getUndeleteWarning(responseMessages);
    } else {
      $content += this.translate.instant(this.tradBase + '.LIST.ENTITIES.ITEM_NOT_FOUND') + '\n\n';
    }
    this.content = $content;
  }

  getDeleteWarning(message) {
    const entities = message.filter(([key, value]) => value > 0);
    return (entities || [])
      .map(([key, value]) => this.translate.instant(this.tradBase + '.LIST.ENTITIES.IMPEDIMENT.' + key.toUpperCase(), value))
      .join('\n');
  }

  getUndeleteWarning(message) {
    let msg = message.length > 0 ? `${this.translate.instant(this.tradBase + '.LIST.ACTION_FAIL.DESCRIPTION_ERROR')}\n\n` : '';
    msg += (message || [])
      .map(([key, value]) => {
        const _value = this.translate.instant(this.tradBase + '.LIST.ACTION_FAIL.' + value.toUpperCase(), null, value);
        return this.translate.instant(this.tradBase + '.LIST.ACTION_FAIL.' + key.toUpperCase(), _value);
      })
      .join('\n');
    return msg;
  }
}

@Component({
  selector: 'generic-modal-warning',
  template: `
    <div>
      {{ dataWarnings }}
    </div>
  `,
})

//DES-1651
export class GenericModalReporte implements OnInit {
  @Input() dataWarnings: any;
  _isDev = _isDev();
  constructor(protected translate: IanTranslateService) {}

  ngOnInit(): void {}

  ngOnChanges(changes) {}
}
