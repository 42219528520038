import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { transitions } from '@prisma/animations/animations';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToolbarItemModel } from '@prisma/components/ian-table-components/ian-default-toolbar/ian-default-toolbar.model';
import { AppCloseAdvancedSearchAction, AppOpenAdvancedSearchAction } from 'app/store/store.actions';
import { PrismaListBaseComponent } from '@prisma/components/prisma-list-base.component';
import { PagedList } from 'core/models/paged-list.model';
import { Observable, Subject } from 'rxjs';

import { Select, Store } from '@ngxs/store';
import { AppState } from 'app/store/store.state';
import { InsightsListModel, InsightTypesModel } from '../insights-store/insights-store.model';
import {
  InsightsGetAttributesAction,
  InsightsGetListAction,
  InsightsSetPageEventAction,
  InsightsSetQueryAction,
  InsightsStoreState,
} from '../insights-store/insights-store.state';
import { _toggleFullscreen } from '@shared/action_toolbar_functions';
import { _setTextFilterValue } from '@shared/aux_helper_functions';
import { PageEvent } from '@angular/material/paginator';
import { _warnProduction } from '@shared/aux_helper_environment';
import { InsightsService } from '../insights.service';
import { SetDashBoardUrl } from 'app/home/dashboard/store/dashboard.state';
import {
  actionConfigTable,
  actionConfigToolBarConfigTable,
  ColumnsType,
  createDialogConfigTable,
  localStorageToColumnsConfig,
} from '@prisma/components/table-components/prisma-table.model';
import { _getUseUXV2 } from 'app/layout/components/global-settings-panel/global-settings-panel.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

export const _navigateToAnotherPage = (element, store): string => {
  let path;
  path = element?.actionUrl?.includes('price-decisions') ? '/prices/decisions/price-decisions/list/' : path;
  if (!path) {
    _warnProduction('[navigateToAnotherPage] row without actionUrl');
    return;
  }
  return path;
};

export const hasReport = (element): boolean => {
  return element?.actionUrl?.toLowerCase().includes('api/dashboard');
};

export const _getParams = (element): any => {
  let $params;
  $params = element.category ? { ...$params, categoryId: element.category } : $params;
  $params = element.store ? { ...$params, storeId: element.store } : $params;
  $params = element.storeTag ? { ...$params, tagId: element.storeTag, tagValue: element.storeTagValue, tagType: 'store' } : $params;
  return $params;
};

@Component({
  selector: 'app-insights-list',
  templateUrl: './insights-list.component.html',
  styleUrls: ['./insights-list.component.scss'],
  animations: [transitions],
})
export class InsightsListComponent extends PrismaListBaseComponent implements OnInit, OnDestroy {
  UseUXV2 = _getUseUXV2();
  columnsType: Array<ColumnsType>;
  initColumn = _columnsType(this.translate);

  @Select(AppState.IsAdvancedSearch)
  isAdvancedSearch$: Observable<boolean>;

  @ViewChild('sidenav', { static: true })
  sidenav: any;

  query = null;

  public $unsuscribreAll: Subject<void> = new Subject<void>();

  pageTitle = this.translate.instant('ADMIN.MASTER.INSIGHTS.TITLE');

  dataSource = new MatTableDataSource<InsightsListModel>();

  subscribers: any = {};

  pagination = {
    length: this.$paginationData.length,
    pageSize: this.$paginationData.pageSize,
    pageIndex: this.$paginationData.activePageIndex,
    pageSizeOptions: this.$paginationData.pageSizeOptions,
  };
  typeList$ = [];
  $typeIsLoading = false;

  $navigateToAnotherPage = _navigateToAnotherPage;
  $getParams = _getParams;
  $hasReport = hasReport;

  constructor(
    private translate: IanTranslateService,
    private store: Store,
    private insightService: InsightsService,
    public dialog: MatDialog,
    private router: Router,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.getList();
    this.getAttributesForFilter();

    this.$typeIsLoading = true;
    this.insightService.getTypes().subscribe((data: Array<InsightTypesModel>) => {
      this.typeList$ = data;
      this.$typeIsLoading = false;
    });

    this.store.select(InsightsStoreState.getInsights).subscribe((data: PagedList<InsightsListModel>) => {
      if (data) {
        this.pagination.length = data.totalItemCount;
        this.pagination.pageSize = data.pageSize;
        this.pagination.pageIndex = data.pageIndex;
        this.dataSource.data = data.items;
      }
      this.$isLoading = false;
    });

    this.store.select(InsightsStoreState.getQuery).subscribe(val => {
      this.query = val ? val : null;
      _setTextFilterValue(this.query);
    });

    /** X default seteamos Advanced Search inactiva */
    this.store.dispatch(new AppCloseAdvancedSearchAction());

    this.columnsType = (localStorageToColumnsConfig(this.initColumn, 'configColumnsInsights') as Array<ColumnsType>) || this.initColumn;
  }

  onSearch(evt) {
    this.$isLoading = true;
    this.store.dispatch(new InsightsSetQueryAction(evt));
  }

  onPageEvent($event: PageEvent): void {
    this.$isLoading = true;
    const payload = { pageIndex: $event.pageIndex, pageSize: $event.pageSize };
    const action = new InsightsSetPageEventAction(payload);
    this.store.dispatch(action);
  }

  getList(): void {
    this.$isLoading = true;
    const action = new InsightsGetListAction();
    this.store.dispatch(action);
  }

  getAttributesForFilter(): void {
    const action = new InsightsGetAttributesAction();
    this.store.dispatch(action);
  }

  setDashBoardUrl(element) {
    if (hasReport(element)) {
      this.store.dispatch(new SetDashBoardUrl(element?.actionUrl));
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  toolbarActionButtons = _toolbarActionButtons(this, this.translate);

  executeAction(action: ToolbarItemModel): void {
    let actionExec;
    switch (action.type) {
      case 'filter':
        const isOpen = this.store.selectSnapshot(AppState.IsAdvancedSearch);
        actionExec = isOpen ? new AppCloseAdvancedSearchAction() : new AppOpenAdvancedSearchAction();
        this.store.dispatch(actionExec);
        this.sidenav.toggle();
        break;
      case 'fullScreen':
        _toggleFullscreen(this.toolbarActionButtons, this.translate);
        break;
      case 'config-table-columns':
        createDialogConfigTable(this.dialog, this.columnsType)
          .afterClosed()
          .subscribe(data => {
            this.columnsType = actionConfigTable(data, this.columnsType, this.initColumn, 'configColumnsInsights');
          });
        this.cd.detectChanges();
        break;
    }
  }
}
const _toolbarActionButtons = (that, translate): Array<ToolbarItemModel> =>
  [
    {
      type: 'filter',
      name: translate.instant('ADMIN.MASTER.INSIGHTS.FILTER_TTIP'),
      disabled: false,
      icon: 'filter_list',
      tooltip: translate.instant('ADMIN.MASTER.INSIGHTS.FILTER_TTIP'),
      iconClass: 'grey-icon',
      children: [],
    },
    that.UseUXV2 ? actionConfigToolBarConfigTable(translate) : null,
    {
      type: 'fullScreen',
      name: translate.instant('PR.STP.COMP.FULLSCREEN_TTIP'),
      disabled: false,
      icon: 'fullscreen',
      tooltip: translate.instant('PR.STP.COMP.FULLSCREEN_TTIP'),
      iconClass: 'grey-icon',
      children: [],
    },
  ].filter(toolbar => toolbar != null);

const _columnsType = (translate): Array<ColumnsType> => [
  {
    id: 0,
    name: '', // null para que no se muestre ni titulo ni se renderice el diseño del titulo en la vista
    type: [
      {
        column: 'isSubscribed',
        markOnNull: false,
        colSpan: 0.64,
        type: '',
        class: 'pr-24',
        headerTitle: '',
        isChecked: true,
        isEditable: false,
      },
      {
        column: 'name',
        markOnNull: true,
        type: 'text',
        colSpan: 1.5,
        class: 'pr-24',
        headerTitle: translate.instant('ADMIN.MASTER.INSIGHTS.NAME'),
        isChecked: true,
        isEditable: true,
      },
      {
        column: 'description',
        markOnNull: true,
        type: 'text',
        colSpan: 2,
        class: 'pr-24 pt-16 pb-16',
        headerTitle: translate.instant('ADMIN.MASTER.INSIGHTS.DESCRIPTION'),
        isChecked: true,
        isEditable: true,
      },
      {
        column: 'margin',
        markOnNull: true,
        type: 'number',
        class: 'pr-24 align-r',
        fxLayoutAlign: 'end center',
        headerTitle: translate.instant('ADMIN.MASTER.INSIGHTS.MARGIN'),
        isChecked: true,
        isEditable: true,
      },
      {
        column: 'createDate',
        markOnNull: true,
        type: 'date',
        class: 'pr-24',
        headerTitle: translate.instant('ADMIN.MASTER.INSIGHTS.CREATE_DATE'),
        isChecked: true,
        isEditable: true,
      },
      {
        column: 'action',
        markOnNull: true,
        type: 'string',
        class: 'pr-24',
        headerTitle: translate.instant('ADMIN.MASTER.INSIGHTS.ACTIONS'),
        isChecked: true,
        isEditable: false,
      },
    ],
  },
];
