import { _isFeDev, _log } from '@shared/aux_helper_environment';
import { _orderBy, _timeout, _cloneDeep, _toNumber } from '@shared/aux_helper_functions';
import { AddProdToShelf } from 'app/spaces/planograms-editor/store/planogram.store.state';
import {
  _getTmpProdId,
  DEFAULT_OFFSET_MIN_PRD_SEP_X,
  DEFAULT_FINGER_SPACE,
  _round,
  _calcRotateTransforms,
} from 'app/spaces/static_common/spaces_aux_helpers';

export const pitShelfDecorator = _this => {
  _this.addElement = async (data, $position) => {
    let prodId = data.id;
    const newId = data && data.isList ? _getTmpProdId() : prodId;

    //Si es un item ya insertado
    let prodInShelf = _this.baseObj.findChildById(prodId);

    //Si es un item ya insertado copia la rotacion
    let rotation = prodInShelf ? prodInShelf.rotation : 0;
    if (rotation % 90 !== 0) rotation = 0; //Solo rotaciones múltiplo de 90

    let rotateY = prodInShelf ? !!prodInShelf.rotateY : false;

    let limitLoadSize = prodInShelf ? prodInShelf.limitLoadSize : null;

    if (true) _log('\n\n[addElement (pig)]', { data, $position, prodId, prodInShelf });

    //Ajuste de nueva posicion
    let position = { x: $position.x - data.size.w / 2, y: $position.y - data.size.h / 2 };
    let margin = (_this.marginLeft || 0) + (_this.marginRight || 0);
    position = _this._fitPosInBox(position, data.size, { x: _this.marginLeft || 0, y: 0 }, { w: _this.size.w - margin, h: _this.size.h });
    const newProd = { ...data, position, rotation, rotateY, limitLoadSize, id: newId };

    //Posición inválida > Restea la posición del drag y aborta
    if (!_this.isValidPos(newProd.position, newProd.size, newProd.id)) {
      let prodObj = _this.baseObj.findChildById(prodId);
      if (prodObj) prodObj.resetPosition();
      return;
    }

    if (!_this.isValidPromoItem(data, true)) {
      _this.baseObj.genericAlert({
        message: 'El item no se encuentra alcanzado por ninguna de las acciones promocionales asociadas al estante.',
        title: 'Aviso',
        isOnlyWarning: true,
      });

      let prodObj = _this.baseObj.findChildById(prodId);
      if (prodObj) prodObj.resetPosition();
      return;
    }

    //Add
    await _this.store.dispatch(new AddProdToShelf(newProd, _this.id, prodId));

    //Selecciona
    _this.baseObj.deselectLast();
    await _timeout(0);
    _this.baseObj.selectObjBaseById(newId, _this.getThisScreenOffset());
  };

  _this.showGuides = (data, $position) => {
    if (!data || !$position) return;

    let position = { x: $position.x - data.size.w / 2, y: $position.y - data.size.h / 2 };
    let margin = (_this.marginLeft || 0) + (_this.marginRight || 0);
    position = _this._fitPosInBox(position, data.size, { x: _this.marginLeft || 0, y: 0 }, { w: _this.size.w - margin, h: _this.size.h });

    _this._shelfGuideWarning = !_this.isValidPos(position, data.size, data.id);

    if (!_this._shelfGuideWarning) {
      _this._shelfGuideWarning = !_this.isValidPromoItem(data, false);
    }

    _this._shelfGuide.position = { x: position.x * _this.scale, y: position.y * _this.scale };
    _this._shelfGuide.size = { w: data.size.w * _this.scale, h: data.size.h * _this.scale };
  };

  _this.isValidPos = (position, $size, id) => {
    let sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;
    let sepY = DEFAULT_OFFSET_MIN_PRD_SEP_X;
    let size = { w: $size.w + sepX, h: $size.h + sepY };

    let margin = (_this.marginLeft || 0) + (_this.marginRight || 0);

    if (size.w > _this.size.w - margin) return false;
    if (size.h + DEFAULT_FINGER_SPACE > _this.size.h) return false;

    return !_this._collidesWithSomeChildProd(position, size, id);
  };

  _this.isNewWidthValid = (newW: number) => {
    if (!_this.prods || !_this.prods.length || newW > (_this as any).objDataShelf.width) return true;

    let rv = true;
    let sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;

    _this.prods.forEach(prod => {
      if (prod.position.x + prod.size.w + sepX > newW) rv = false;
    });

    return rv;
  };

  //DES-1576
  _this.isNewMarginValid = ($newValue: number, LEFT: boolean = true) => {
    if (!_this.prods || !_this.prods.length) return true;

    let otherMargin = LEFT ? _this.marginRight || 0 : _this.marginLeft || 0;
    if ($newValue >= _this.size.w - otherMargin) return false;

    let rv = true;
    let sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;

    _this.prods.forEach(prod => {
      if (LEFT) {
        if (prod.position.x < $newValue) rv = false;
      } else {
        let prodPos = prod.position.x + prod.size.w + sepX;
        if (prodPos > _this.size.w - $newValue) rv = false;
      }
    });

    return rv;
  };

  _this.isNewDepthValid = (newD: number) => {
    if (!_this.prods || !_this.prods.length || newD > (_this as any).objDataShelf.depth) return true;

    let rv = true;
    let sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;

    _this.prods.forEach(prod => {
      if (Math.max(prod.position.y, 0) + prod.size.h + sepX > newD) rv = false;
    });

    return rv;
  };

  _this.isNewHeightValid = (newH: number) => {
    if (!_this.prods || !_this.prods.length || newH > (_this as any).objDataShelf.height) return true;

    let rv = true;

    _this.prods.forEach(prod => {
      let _h = (prod.itemData.size.d || prod.itemData.size.w) + DEFAULT_FINGER_SPACE;
      if (_h > newH) rv = false;
    });

    return rv;
  };
};

export const _calcSizeProdPItShelf = obj => {
  let tmpSize = _cloneDeep(obj && obj.itemData && obj.itemData.size ? obj.itemData.size : null);

  if (!tmpSize) {
    if (true) console.warn('[_calcSizeProd] no itemData size', obj);
    return { w: 0, h: 0 };
  }

  if (obj.rotateY) {
    [tmpSize.w, tmpSize.d] = [tmpSize.d, tmpSize.w];
  }

  let size = { w: 0, h: 0 };
  let imgSize = { w: 0, h: 0, originX: 0, originY: 0, offsetX: 0, offsetY: 0 };

  if (!obj.rotation) {
    //sin rotacion
    size = { w: _round(tmpSize.w), h: _round(tmpSize.h) };
    imgSize = { ...imgSize, ...size };
  } else {
    //con rotacion
    const totalBound = _calcRotateTransforms(tmpSize.w, tmpSize.h, obj.rotation || 0);
    size = { w: _round(totalBound.w), h: _round(totalBound.h) };
    imgSize = {
      ...totalBound,
      w: tmpSize.w,
      h: tmpSize.h,
    };
  }

  return { size, imgSize };
};

//Auxiliar para reacomodar/calcular poscion/tamaño/filtrado de items
export const _recalcPosAndSize_pit = shelf => {
  let prods = shelf.prods || [];

  //FIX DES-2028: Como es pozo hay q swapear la profundidad por el alto
  let shelfDepth = (shelf || {}).height;
  let $totalItems = shelfDepth ? 0 : null;

  prods = _orderBy(prods, ['order']).map(($prod, i) => {
    if (!$prod || !$prod.itemData) return $prod;

    let prod = _cloneDeep({ ...$prod, itemData: null });
    prod.itemData = $prod.itemData;

    //Borrar todas las propiedades que no pertenezcan a este tipo
    if (prod.facings) delete prod.facings;
    if (prod.stacks) delete prod.stacks;
    if (prod.limitStackSize) delete prod.limitStackSize;
    if (prod.stacksLyingDown) delete prod.stacksLyingDown;

    //Calcula boundingBox y transformaciones de la imagen si hay rotaciones
    const sizeProdPigShelf = _calcSizeProdPItShelf(prod);

    //ReadOnly de cantidad de items
    let _tmpDepth = prod.itemData.size.d || prod.itemData.size.w;
    if (prod.rotateY) _tmpDepth = prod.itemData.size.w;
    if (shelfDepth) $totalItems = Math.floor(shelfDepth / _tmpDepth) || 1;

    //Limite de carga
    if (prod.limitLoadSize && $totalItems > prod.limitLoadSize) $totalItems = prod.limitLoadSize;

    //DES-3945 -> Unidad de medida mayor a 1
    let unMed = _toNumber(prod.itemData.unMed);
    if (true && unMed != null && unMed > 1) {
      $totalItems *= unMed;
    }

    const rv = {
      ...prod,
      order: i,
      size: sizeProdPigShelf.size,
      imgSize: sizeProdPigShelf.imgSize,
      $totalItems,
      $parenShelf: shelf.id,
    };
    return rv;
  });

  return prods;
};
