import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { kayshOperator } from '@karacas/kaysh';
import { FormModel_scopeItems_FE, mapItemScopeToBE } from '@prisma/containers/model/scope.models';
import { _isDev, _isFeDev, _log, _logTap, _useDummyData } from '@shared/aux_helper_environment';
import { _cloneDeep, _ms, _sortArrayTreeAlpha, _throwError } from '@shared/aux_helper_functions';
import { environment } from 'environments/environment';
import { Observable, isObservable, of } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError, map, shareReplay } from 'rxjs/operators';

export interface TagFlatModelGen {
  id: number;
  name: string;
  level: number;
  parentId: number;
  value?: string;
  tagName?: string;
  tagId?: string;
  values: Array<TagFlatModelGen>;
  isRequired?: boolean;
  isDefaultValue?: boolean;
}

export const _aux_FlatTagsToThreeTags = ($data: TagFlatModelGen[]): TagFlatModelGen[] => {
  let data = _cloneDeep($data);

  const _aux_FlatTagsToThreeTagsInsertInParent = $data => {
    let data = [...$data];

    const getValueFromId = id => {
      return (
        (data || []).find(obj => {
          return String(obj?.id) === String(id);
        }) || null
      );
    };

    (data || []).forEach(obj => {
      if (obj?.parentId !== null) {
        let parent = getValueFromId(obj.parentId);
        parent.values = Array.isArray(parent.values) ? [...parent.values, obj] : [obj];
      }
    });

    return data;
  };

  const mapChilds = (child, level) => {
    if (child?.values?.length) child.values = child.values.map(c => mapChilds(c, level + 1));
    return { ...child, level: level };
  };

  let dataValues = [];
  data.forEach($obj => {
    let size = $obj.values?.length;
    if ($obj?.id && size > 0) {
      for (let i = 0; i < size; i++) {
        dataValues.push({ ...$obj.values[i], tagName: $obj.name, tagId: $obj.id, isRequired: $obj.isRequired });
      }
    }
  });

  let rv = {
    id: 0,
    name: 'Level 0',
    values: [],
    level: 0,
    parentId: null,
  };

  //First Level
  _aux_FlatTagsToThreeTagsInsertInParent(dataValues).forEach($obj => {
    let obj = $obj;
    if (obj?.id) {
      if (obj.parentId == null) {
        obj = mapChilds(obj, 1);
        if (obj?.id && obj.value) rv.values.push({ ...obj, parentId: 0 });
      }
    }
  });

  return [rv];
};

const cudOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class GenericTagsSelectV2Service {
  private configBasePrices = environment.apiBaseUrl_prices;
  private urls = environment.tagService.api;
  private cacheObj = {};

  constructor(private http: HttpClient) {
    this.geTags(1).subscribe(data => {
      _log(['geTags'], data);
    });
  }

  setCache(key, data) {
    if (this.cacheObj[key] || !data) return;
    this.cacheObj[key] = data;
  }

  getCache(key) {
    if (!key) return null;
    return this.cacheObj[key] || null;
  }

  resetCache() {
    this.cacheObj = {};
    if (true) _log('RESET TAGS CACHE');
  }

  getAllTagsIdsByCategory(id: number): Observable<number[]> {
    if (id == null) return of(null);

    const url = `${this.configBasePrices}${this.urls.resources.getAllByDepartment}?departmentId=${id}`;

    let rv = this.http.get<number[]>(url).pipe(
      tap(data => _logTap(`${GenericTagsSelectV2Service.name}::getAllTagsIdsBySubcategory (tap)\n\tdata: %o`, data)),
      kayshOperator('getTagsIdsBySubcategory', id, { localStorage: false, maxTime: _ms('3h') }),
      catchError(error => {
        if (_useDummyData()) {
          return of(dummy_getByDepartment);
        }
        _throwError(error, GenericTagsSelectV2Service);
      })
    );

    return rv as Observable<number[]>;
  }

  getTagsIdsBySubcategory(id: number): Observable<number[]> {
    if (id == null) return of(null);

    const url = `${this.configBasePrices}${this.urls.resources.getByDepartment}?departmentId=${id}`;

    let rv = this.http.get<number[]>(url).pipe(
      tap(data => _logTap(`${GenericTagsSelectV2Service.name}::getTagsIdsBySubcategory (tap)\n\tdata: %o`, data)),
      kayshOperator('getTagsIdsBySubcategory', id, { localStorage: false, maxTime: _ms('3h') }),
      catchError(error => {
        if (_useDummyData()) {
          return of(dummy_getByDepartment);
        }
        _throwError(error, GenericTagsSelectV2Service);
      })
    );

    return rv as Observable<number[]>;
  }

  /**
   *
   * @param moduleId el id del modulo 1 = items, 2 = store,
   */
  geTags(moduleId): Observable<TagFlatModelGen[]> {
    if (false && _isDev()) return of(_aux_FlatTagsToThreeTags(exampleTags));

    let cache = this.getCache(moduleId);
    if (cache != null) {
      return (isObservable(cache) ? cache : of(cache)).pipe(map(data => _cloneDeep(data)));
    }

    const url = `${this.configBasePrices}${this.urls.resources.dinamicTags}/` + moduleId;

    let rv = this.http.get<any[]>(url).pipe(
      /*TMP*/ map(data => (false && _isFeDev() ? exampleTags : data)),
      tap(data => _logTap(`${GenericTagsSelectV2Service.name}::geTags (tap)\n\tdata: %o`, data)),
      map(data => (data?.length ? _sortArrayTreeAlpha(data, 'value', 'values') : data)),
      tap(data => (data?.length ? this.setCache(moduleId, data) : data)),
      catchError(error => _throwError(error, GenericTagsSelectV2Service))
    );

    let cacheReplay = rv.pipe(shareReplay());

    this.setCache(moduleId, cacheReplay);

    return cacheReplay;
  }

  geTagsTree(moduleId): Observable<TagFlatModelGen[]> {
    return this.geTags(moduleId).pipe(
      map(data => {
        if (false && _isFeDev()) data[3].values[1].isDefaultValue = true; //DEV PREC-221 Casuística harcoded
        return data;
      }),
      map(data => _aux_FlatTagsToThreeTags(false && moduleId === 1 && _isFeDev() ? [...exampleTags, ...data] : data))
    );
  }

  //usado en tagsValues-tree-included-excluded-v2.component.ts
  getTagsTreeSimplified(moduleId, useDummyData = false): Observable<TagFlatModelGen[] | any[]> {
    if (useDummyData) {
      return of(DUMMY_DATA);
    }
    return this.geTags(moduleId).pipe(
      tap(data => _logTap(`${GenericTagsSelectV2Service.name}::getTagsTreeSimplified (tap)\n\tdata: %o`, data))
    );
  }

  getTagsAndCatsByItemScope($itemScope: FormModel_scopeItems_FE): Observable<any> {
    if ($itemScope == null) return of(null);

    const url = `${this.configBasePrices}${this.urls.resources.getTagsAndCatsByItemScope}`;

    const itemScope = mapItemScopeToBE({ itemScope: $itemScope }, true, undefined, true);

    let rv = this.http.post<any>(url, itemScope).pipe(
      tap(data => _logTap(`${GenericTagsSelectV2Service.name}::getTagsAndCatsByItemScope (tap)\n\tdata: %o`, data)),
      kayshOperator('getTagsAndCatsByItemScope', itemScope, { localStorage: false, maxItems: 3, maxTime: _ms('15m') })
    );

    return rv as Observable<any>;
  }
}

const DUMMY_DATA = [
  {
    id: 16,
    name: 'Tipo',
    values: [
      {
        id: 77,
        value: 'A',
        parentId: null,
      },
      {
        id: 78,
        value: 'B',
        parentId: null,
      },
    ],
    parentId: null,
    isRequired: false,
    isGeo: false,
  },
  {
    id: 19,
    name: 'País',
    values: [
      {
        id: 84,
        value: 'Argentina',
        parentId: null,
      },
      {
        id: 85,
        value: 'Perú',
        parentId: null,
      },
    ],
    parentId: null,
    isRequired: false,
    isGeo: false,
  },
  {
    id: 20,
    name: 'Provincia',
    values: [
      {
        id: 86,
        value: 'Buenos Aires',
        parentId: 84,
      },
      {
        id: 89,
        value: 'Cuzco',
        parentId: 85,
      },
      {
        id: 88,
        value: 'Lima',
        parentId: 85,
      },
      {
        id: 87,
        value: 'Mendoza',
        parentId: 84,
      },
    ],
    parentId: 19,
    isRequired: false,
    isGeo: false,
  },
  {
    id: 21,
    name: 'Localidad',
    values: [
      {
        id: 96,
        value: 'Callao',
        parentId: 88,
      },
      {
        id: 90,
        value: 'Castelar',
        parentId: 86,
      },
      {
        id: 93,
        value: 'Godoy Cruz',
        parentId: 87,
      },
      {
        id: 91,
        value: 'Luján',
        parentId: 86,
      },
      {
        id: 92,
        value: 'Maipú',
        parentId: 87,
      },
      {
        id: 98,
        value: 'Pisac',
        parentId: 89,
      },
      {
        id: 94,
        value: 'Potrerillos',
        parentId: 87,
      },
      {
        id: 95,
        value: 'San Borja',
        parentId: 88,
      },
      {
        id: 97,
        value: 'Yucay',
        parentId: 89,
      },
    ],
    parentId: 20,
    isRequired: false,
    isGeo: false,
  },
  {
    id: 18,
    name: 'Size',
    values: [
      {
        id: 83,
        value: '25',
        parentId: null,
      },
      {
        id: 82,
        value: '5',
        parentId: null,
      },
    ],
    parentId: null,
    isRequired: false,
    isGeo: false,
  },
];

export const dummy_getByDepartment = [
  {
    id: 23,
    isRequired: false,
    numberOfValues: 151,
  },
  {
    id: 22,
    isRequired: false,
    numberOfValues: 28,
  },
  {
    id: 21,
    isRequired: false,
    numberOfValues: 1,
  },
  {
    id: 32,
    isRequired: false,
    numberOfValues: 564,
  },
  {
    id: 33,
    isRequired: false,
    numberOfValues: 564,
  },
  {
    id: 2,
    isRequired: true,
  },
  {
    id: 3,
  },
  {
    id: 11,
  },
  {
    isRequired: true,
    id: 992002,
  },
];

let exampleTags: any[] = [
  {
    id: 992002,
    name: 'Provincias',
    parentId: null,
    values: [
      {
        id: 997,
        value: 'Provincia de Buenos Aires',
        parentId: null,
      },
      {
        id: 9940,
        value: 'Entre Ríos',
        parentId: null,
      },
      {
        id: 9941,
        value: 'Misiones',
        parentId: null,
      },
      {
        id: 9943,
        value: 'Salta',
        parentId: null,
      },
      {
        id: 9944,
        value: 'Formosa',
        parentId: null,
      },
      {
        id: 9981,
        value: 'Córdoba',
        parentId: null,
      },
    ],
  },
  {
    id: 994002,
    name: 'Localidades',
    parentId: null,
    values: [
      {
        id: 99370,
        value: 'Azul',
        parentId: 997,
      },
      {
        id: 99371,
        value: 'Junin',
        parentId: 997,
      },
      {
        id: 99372,
        value: 'Rio Cuarto',
        parentId: 9981,
      },
    ],
  },
  {
    id: 995002,
    name: 'Ciudades',
    parentId: null,
    values: [
      {
        id: 99170,
        value: 'Azul Capital',
        parentId: 99370,
      },
      {
        id: 99171,
        value: 'Junin Capital',
        parentId: 99370,
      },
      {
        id: 99175,
        value: 'Rio Cuarto Capital',
        parentId: 99372,
      },
    ],
  },
  {
    id: 995001,
    name: 'Barrios',
    parentId: null,
    values: [
      {
        id: 995006,
        value: 'Palermo Azul',
        parentId: 99170,
      },
      {
        id: 995003,
        value: 'Belgrano Azul',
        parentId: 99170,
      },
      {
        id: 995009,
        value: 'Rio Cuarto Barrio',
        parentId: 99175,
      },
    ],
  },
];

const _aux_FlatTagsToThreeTagsTest2 = () => {
  _log(['ExampleTags2'], _aux_FlatTagsToThreeTags(exampleTags));
};

if (!true) _aux_FlatTagsToThreeTagsTest2();
