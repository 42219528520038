<div fxLayout="column" fxFlex>
  <mat-list class="date" cdkFocusRegionStart>
    <mat-icon (click)="closeQuickPanel()" class="closeQuickPanel">close</mat-icon>
    <h3 matSubheader cdkFocusInit class="notification-title">
      <b>{{name | titlecase }}</b>
    </h3>
  </mat-list>

  <div class="assistant-intro">
    <p>Como puedo ayudarte?</p>
    <p>Toca el icono del microfono para activar el asistente virtual de Prisma</p>
  </div>

  <div class="assistant-buttons">
    <button mat-icon-button (click)="recordCommand()">
      <mat-icon [ngStyle]="{ 'color' : isRecording ? 'red' : 'inherit' }">
        {{isRecording ? 'mic' : 'mic_none'}}
      </mat-icon>
    </button>
  </div>

  <mat-list class="body-notification">
    <mat-list-item *ngFor="let value of messages" matTooltip="{{value.title}}">
      <p matLine class="secondary-text secondary-text-date">{{value.detail}}</p>
      <h4 matLine class="title-body-notif" style="margin-top: 10px">
        <b>{{value.title}}</b>
      </h4>
      <mat-divider></mat-divider>
    </mat-list-item>
  </mat-list>
</div>
