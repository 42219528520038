import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { _logTap, _useDummyData } from '@shared/aux_helper_environment';
import { _get, _handleErrorWithDummyData } from '@shared/aux_helper_functions';
import { Observable } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { InsightFilterModel, InsightsListModel, InsightTypesModel } from './insights-store/insights-store.model';
import { dummyInsights } from './insights-store/insights.dummyData';
import { PagedList } from 'core/models/paged-list.model';
import { dummyInsightsPanel } from 'app/layout/components/insights-panel/insights-panel.dummyData';
import { GenericCategorySelectionMiniV2Service } from '@prisma/components/generic-category-selection-mini-v2/generic-category-selection-mini-v2.service';

const IS_DEMO = _useDummyData();

export const _InsightsMapFilterModelForBE = filters => {
  return {
    query: filters.query,
    types: filters.types,
    categories: filters.categories,
    storeTags: filters.storeTags ? filters.storeTags : [],
    stores: filters.stores,
    dateFrom: filters.dateFrom,
  };
};

@Injectable({
  providedIn: 'root',
})
export class InsightsService {
  //TODO: VER CON BACK POR QUE MODULO SE MANDA
  private configBase = _get(environment, 'apiBaseUrl_prices');
  private config = _get(environment, 'admin.masters.insights.api');

  cachedTypeListOptions = null;

  constructor(private http: HttpClient, private serviceCategory: GenericCategorySelectionMiniV2Service) {}

  getList(filters: InsightFilterModel, pageIndex?: number, pageSize?: number): Observable<PagedList<InsightsListModel>> {
    let url;

    const qs: Array<string> = ['?'];
    if (pageIndex) {
      qs.push(`pageIndex=${pageIndex}`);
    }

    if (pageSize) {
      qs.push(`pageSize=${pageSize}`);
    }

    url = `${this.configBase}${this.config.resources.getList}${qs.join('&')}`;
    const _filters = _InsightsMapFilterModelForBE(filters);
    const lowLevel = this.serviceCategory.getLowLevel({
      level4: filters.categories,
    });

    return this.http.post<PagedList<InsightsListModel>>(url, { ..._filters, categories: lowLevel?.level4 || [] }).pipe(
      tap(data => _logTap(`${InsightsService.name}::getList (tap)\n\tdata: %o`, data)),
      catchError(error => {
        return _handleErrorWithDummyData(
          true && _useDummyData(),
          error,
          InsightsService,
          dummyInsights.insightsList,
          'dummyInsights.insightsList'
        );
      })
    );
  }

  getListOwner(filters: InsightFilterModel, pageIndex?: number, pageSize?: number): Observable<PagedList<InsightsListModel>> {
    let url;

    const qs: Array<string> = ['?'];
    if (pageIndex) {
      qs.push(`pageIndex=${pageIndex}`);
    }

    if (pageSize) {
      qs.push(`pageSize=${pageSize}`);
    }

    url = `${this.configBase}${this.config.resources.getPanelList}${qs.join('&')}`;

    const _filters = _InsightsMapFilterModelForBE(filters);

    return this.http.post<PagedList<InsightsListModel>>(url, _filters).pipe(
      tap(data => _logTap(`${InsightsService.name}::getPanelList (tap)\n\tdata: size`, data)),
      catchError(error => {
        return _handleErrorWithDummyData(
          true && _useDummyData(),
          error,
          InsightsService,
          dummyInsightsPanel.insightsPanelList,
          'dummyInsightsPanel.insightsPanelList'
        );
      })
    );
  }

  getTypes(): Observable<Array<InsightTypesModel>> {
    if (this.cachedTypeListOptions) {
      return new Observable(data => {
        data.next(this.cachedTypeListOptions);
        data.complete();
      });
    }
    const url = this.configBase + this.config.resources.getTypes;
    return this.http.get<Array<InsightTypesModel>>(url).pipe(
      map(data => (this.cachedTypeListOptions = data)),
      tap(data => _logTap(`${InsightsService.name}::getTypes (tap)\n\tdata: %o`, data)),
      catchError(error => {
        const dummyDateTypes = _handleErrorWithDummyData(
          true && _useDummyData(),
          error,
          InsightsService,
          dummyInsights.insightsTypes,
          'dummyInsights.insightsTypes'
        );
        dummyDateTypes.subscribe(data => (this.cachedTypeListOptions = data));
        return dummyDateTypes;
      })
    );
  }

  subscription(params): Observable<boolean> {
    const url = this.configBase + this.config.resources.subscription;
    return this.http.post<boolean>(url, params).pipe(
      tap(data => _logTap(`${InsightsService.name}::subscription (tap)\n\tdata: %o`, data)),
      catchError(error => {
        return _handleErrorWithDummyData(true && _useDummyData(), error, InsightsService, true, 'dummyInsights.subscribe');
      })
    );
  }

  unsubscribe(params): Observable<boolean> {
    const url = this.configBase + this.config.resources.unsubscribe;
    return this.http.post<boolean>(url, params).pipe(
      tap(data => _logTap(`${InsightsService.name}::unsubscribe (tap)\n\tdata: %o`, data)),
      catchError(error => {
        return _handleErrorWithDummyData(true && _useDummyData(), error, InsightsService, true, 'dummyInsights.unsubscribe');
      })
    );
  }
}
