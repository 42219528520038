<ng-container *ngIf="!item.hidden">
  <div class="group-title" [ngClass]="item.classes">
    <ng-container *ngIf="!item.forceGroupUrl">
      <span class="hint-text" [translate]="item.translate">{{ item.title }}</span>
    </ng-container>
    <ng-container *ngIf="item.forceGroupUrl">
      <!-- id:If8oLkW589  -->
      <!-- <a [routerLink]="[item.forceGroupUrl]">
        <span class="hint-text hint-text-spc" [translate]="item.translate">{{ item.title }}</span>
      </a> -->
    </ng-container>
  </div>

  <div class="group-items">
    <ng-container *ngFor="let item of item.children">
      <fuse-nav-vertical-group *ngIf="item.type=='group'" [item]="item"></fuse-nav-vertical-group>
      <fuse-nav-vertical-collapsable
        (click)="clickItem(item)"
        *ngIf="item.type=='collapsable'"
        [item]="item"
      ></fuse-nav-vertical-collapsable>
      <fuse-nav-vertical-item
        (click)="clickItem(item)"
        *ngIf="item.type=='item'"
        [item]="item"
      ></fuse-nav-vertical-item>
    </ng-container>
  </div>
</ng-container>
