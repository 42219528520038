// https://stackblogger.com/dynamic-components-angular/

import { Directive, ViewContainerRef } from '@angular/core';
import { _log } from '@shared/aux_helper_environment';

@Directive({
  selector: '[dynamicchildloader]',
})
export class DynamicChildLoaderDirective {
  //debe estar declarado en shared para poder utilizarlo
  constructor(public viewContainerRef: ViewContainerRef) {
    if (false) _log('[dynamicchildloader]');
  }
}
