<div
  id="notification-panel-wrapp"
  *ngIf="notifPanelOpened"
  fxLayout="column"
  fxFlex
  [ngClass]="{oldNotifClosed: !oldNotifShowList, noNewNotif: (notifications$ | async) !== null && (notificationsSize$ | async) === 0}"
>
  <div>
    <mat-list class="date" cdkFocusRegionStart>
      <mat-icon (click)="refreshBENotificatioons()" class="refreshNotif">refresh</mat-icon>
      <h3 matSubheader cdkFocusInit>
        <b class="notification-title" (click)="_printMsgInfo()">{{ translates?.TITLE }}</b>
        &nbsp;
        <b class="notification-title-number" *ngIf="(notifications$ | async) !== null">
          &nbsp;({{(notificationsSize$ | async)}})
        </b>
      </h3>
    </mat-list>

    <mat-list class="body-notification">
      <ng-container *ngIf="getAlertEnabledNotifications()">
        <div class="_enabledNotifications">{{translates?.NOT_ENABLED}}</div>
      </ng-container>

      <ng-container *ngIf="(notifications$ | async) === null">
        <div class="_noItems">
          <br />
          <br />
          <custom-loader [size]="0.8"></custom-loader>
        </div>
      </ng-container>

      <ng-container
        *ngIf="(notifications$ | async) !== null && (notificationsSize$ | async) === 0 && !oldNotifShowList"
      >
        <div class="_noItems">
          <img src="assets/emtyState/not-notifications.png" alt="No se encontraron Notificaciones" width="138" />
          <b>{{translates?.NO_NEW_ITEMS}}</b>
        </div>
      </ng-container>

      <!-- NOTIFICACIONES NO MARCARDAS COMO LEIDAS -->
      <div *ngIf="(notificationsSize$ | async) > 0 && !oldNotifShowList" id="notification-panel-list-wrapp-new">
        <ng-container>
          <mat-list-item *ngFor="let value of (notifications$ | async);" class="notifPanelItem">
            <a
              *ngIf="value.title"
              [attr.href]="value.href ? value.href : null"
              [attr.target]="value.hrefBlank !== false ? '_blank': null"
              [ngStyle]="{'cursor': (value.href ? 'pointer' : 'default') }"
              class="{{!value._readed ? 'new-notification ' : ''}} color-black"
            >
              <p matLine class="secondary-text secondary-text-date" title="{{date2string(value.date)}}">
                {{date2dateago(value.date)}}
              </p>

              <h4
                matLine
                matTooltipPosition="above"
                style="margin-top: 0px; cursor: pointer"
                matTooltip="{{translates?.MARK_AS_READ}}"
                (click)="markAsReady(value);"
              >
                <button mat-icon-button *ngIf="!value._readed" class="menuOptions">
                  <mat-icon>done_all</mat-icon>
                </button>
                {{value.title}}
              </h4>

              <!-- //Sin html/link -->
              <p *ngIf="!value.urlToDownload && !value.urlToGo" matLine class="secondary-text _preline">
                {{value.detail}}
              </p>
              <!-- //Con html/link //DES-4382 -->
              <p
                *ngIf="value.urlToDownload || value.urlToGo"
                matLine
                class="secondary-text _preline"
                [innerHTML]="value.detail"
                matTooltip="{{ value.urlToDownload ? translates?.DOWNLOAD : translates?.TOGO}}"
              ></p>

              <p matLine class="secondary-text-large" *ngIf="value._viewinfo === true">{{value.largeDetail}}</p>
            </a>
          </mat-list-item>
        </ng-container>
      </div>

      <!-- NOTIFICACIONES MARCARDAS COMO LEIDAS -->
      <div *ngIf="oldNotifShowList" id="notification-panel-list-wrapp-old">
        <ng-container>
          <span class="_noItems" *ngIf="((oldNotifications$ | async) || []).length === 0">
            <b>{{ translates?.NO_OLD_ITEMS}}</b>
          </span>
          <mat-list-item *ngFor="let value of (oldNotifications$ | async);" class="notifPanelItem">
            <a
              *ngIf="value.title"
              [attr.href]="value.href ? value.href : null"
              [attr.target]="value.hrefBlank !== false ? '_blank': null"
              [ngStyle]="{'cursor': (value.href ? 'pointer' : 'default') }"
              class="{{!value._readed ? 'new-notification ' : ''}}"
            >
              <p matLine class="secondary-text secondary-text-date" title="{{date2string(value.date)}}">
                {{date2dateago(value.date)}}
              </p>

              <h4 matLine style="margin-top: 0px" (click)="closeNotifications()">
                <mat-icon *ngIf="!value._readed">markunread_mailbox</mat-icon>
                <mat-icon *ngIf="false && value._readed" style="vertical-align: middle">markunread</mat-icon>
                <b>{{value.title}}</b>
              </h4>

              <!-- //Sin html/link -->
              <p *ngIf="!value.urlToDownload && !value.urlToGo" matLine class="secondary-text _preline">
                {{value.detail}}
              </p>
              <!-- //Con html/link //DES-4382 -->
              <p
                *ngIf="value.urlToDownload || value.urlToGo"
                matLine
                class="secondary-text _preline"
                [innerHTML]="value.detail"
                matTooltip="{{ value.urlToDownload ? translates?.DOWNLOAD : translates?.TOGO}}"
              ></p>

              <p matLine class="secondary-text-large" *ngIf="value._viewinfo === true">{{value.largeDetail}}</p>
            </a>
          </mat-list-item>
        </ng-container>
      </div>
      <!-- READED -->
    </mat-list>
  </div>

  <div class="container-footer">
    <!-- Marcar como leidas -->
    <button
      *ngIf="(notificationsSize$ | async) > 1"
      mat-menu-item
      style="border-top: 1px solid rgba(0, 0, 0, 0.2)"
      (click)="markAllAsReady(value);"
    >
      <mat-icon style="vertical-align: middle; padding-right: 6px; margin-left: 0">playlist_add_check</mat-icon>
      <span>{{translates?.MARK_ALL_AS_READ}}</span>
    </button>

    <button mat-menu-item (click)="oldNotifShowList = !oldNotifShowList">
      <mat-icon style="vertical-align: middle; padding-right: 6px; margin-left: 0">
        {{!oldNotifShowList ? 'done_all' : 'close'}}
      </mat-icon>
      <span>{{!oldNotifShowList ? translates?.VIEW_LAST : translates?.CLOSE_LAST}}</span>
    </button>
  </div>

  <div class="versionContainer" *ngIf="false">
    <span [innerHTML]="_versionData"></span>
    <span *ngIf="isDev">
      <br />
      lang: {{ "TESTLANG" | translate }}
    </span>
  </div>
</div>
