import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { _log, _warn } from '@shared/aux_helper_environment';
import { BehaviorSubject, Observable } from 'rxjs';

const _global = window as any;

//Para testear notificación
/*

curl -X POST https://fcm.googleapis.com/fcm/send -H 'Authorization:key=AAAAbO1V2WU:APA91bHtSd5HHFE4zdpY1DnnKI4tM93KtpDhC1e_ifbbveWLNey06uEedAVt6OIRWlV9l970Qjo7RGZWWFCEd9DBIghR6pqDlmObTSKVo3YXaVH6_IcPQkyMQX7er2ODFHFKNRLvu3rd' -H 'Content-Type: application/json' -d '{  "notification": { "title": "Título Push\ncon 2 líneas.", "body": "Body Push\ncon 2 líneas."  },  "to" : "e4M1naTAABwMpdkfNU7mXl:APA91bFC8KpzY2CaH5j6CPT0Nrkw25v_pQJUrnV0v3wtAQ6z98TvtfMQ9v-Hc6l-TEztNHXQ-0wx4pI4dBz21Qu8NzRKtvVIFZsUPR5nQa0oYFX_1BuGo5DbT9Yb0ZATWKcyGphKSN8k" }'

curl -X POST https://fcm.googleapis.com/fcm/send -H 'Authorization:key=AAAAbO1V2WU:APA91bHtSd5HHFE4zdpY1DnnKI4tM93KtpDhC1e_ifbbveWLNey06uEedAVt6OIRWlV9l970Qjo7RGZWWFCEd9DBIghR6pqDlmObTSKVo3YXaVH6_IcPQkyMQX7er2ODFHFKNRLvu3rd' -H 'Content-Type: application/json' -d '{  "notification": { "title": "Título Push\ncon 2 líneas.", "body": "{\"message\":\"Descargue el reporte de la tienda ST100\",\"urlToDownload\":\"https://iantechdevstorage.blob.core.windows.net/megademolab/Development/Pricing/reports/PosItemsDataWithAssortment/2023-09-21_15-05-11-PosItemsDataReport.xlsx\"}"  },  "to" : "dcOXjsPo3kCMs843NwnERq:APA91bHDbLibulj0xCnM6HqDyyrWbLxkhLxvTIxG5eLvZX_LUphj9ofRFK3e3M2nR4hY3wF6_TkkAlvcQzQ5JCAs6bRef9SQFDdT1Y1tbSsTHtcugVcQQjgEK5C5nBU-owdTQw-bWgWB" }'

curl.exe -X POST https://fcm.googleapis.com/fcm/send -H 'Authorization:key=AAAAbO1V2WU:APA91bHtSd5HHFE4zdpY1DnnKI4tM93KtpDhC1e_ifbbveWLNey06uEedAVt6OIRWlV9l970Qjo7RGZWWFCEd9DBIghR6pqDlmObTSKVo3YXaVH6_IcPQkyMQX7er2ODFHFKNRLvu3rd' -H 'Content-Type: application/json' -d '{  "notification": { "title": "Título Push\ncon 2 líneas.", "body": "{\"message\":\"Descargue el reporte de la tienda ST100\",\"urlToDownload\":null}"  },  "to" : "e36LPUSm_CMQfm_noMIr8E:APA91bGBfKsPAqfPf51hftiwYkg3dB91nfKfKE97i0vFP66W4GIjeSCoNr4XP7h7ACN7IxgWarhmbRrj1fsXUGgsQinP9t_YLfp9dfi-vTzr7mopyUbRTHlJlfn3NShABaLTSyANA6IJ" }'

curl.exe -X POST https://fcm.googleapis.com/fcm/send -H 'Authorization:key=AAAAbO1V2WU:APA91bHtSd5HHFE4zdpY1DnnKI4tM93KtpDhC1e_ifbbveWLNey06uEedAVt6OIRWlV9l970Qjo7RGZWWFCEd9DBIghR6pqDlmObTSKVo3YXaVH6_IcPQkyMQX7er2ODFHFKNRLvu3rd' -H 'Content-Type: application/json' -d '{  "notification": { "title": "Título Push\ncon 2 líneas.", "body": "{"message":"Descargue el reporte de la tienda ST100","urlToDownload":null}"  },  "to" : "e36LPUSm_CMQfm_noMIr8E:APA91bGBfKsPAqfPf51hftiwYkg3dB91nfKfKE97i0vFP66W4GIjeSCoNr4XP7h7ACN7IxgWarhmbRrj1fsXUGgsQinP9t_YLfp9dfi-vTzr7mopyUbRTHlJlfn3NShABaLTSyANA6IJ" }'

*/

//Basado en: https://medium.com/@mayurkadampro/angular-8-firebase-cloud-messaging-push-notifications-cc80d9b36f82
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);

  currentToken: string = null;

  hasFirebaseRequestPermissionToken = 'NONE';

  firebaseRequestPermissionError = null;

  hasNotificationPermission = this.checkHasNotificationPermission();

  constructor(private angularFireAuth: AngularFireAuth, private angularFireMessaging: AngularFireMessaging) {
    try {
      (this.angularFireMessaging as any).onMessage(payload => {
        _log('🔔 [MessagingService Push Notification]', payload);
        this.currentMessage.next(payload);
      });
    } catch (e) {
      _warn('🔔💥💥💥 [MessagingService Push Notification]', e);
    }
  }

  checkHasNotificationPermission() {
    return window?.Notification?.permission === 'granted';
  }

  requestPermission() {
    this.hasFirebaseRequestPermissionToken = 'PENDING';

    this.angularFireMessaging.requestToken.subscribe(
      token => {
        _log('\n', ['firebase requestPermission ok, token:'], token, '\n\n');
        this.currentToken = token;

        this.hasFirebaseRequestPermissionToken = token?.length ? 'OK' : 'FAIL';

        this.firebaseRequestPermissionError = token?.length ? null : 'token is empty';

        if (_global) {
          _global._info = _global._info ? _global._info : {};
          _global._info._FBtoken = token;
        }
      },
      err => {
        console.error(
          '\n',
          '[firebase requestPermission fail]',
          { error: err, hasNotificationPermission: this.hasNotificationPermission },
          '\n'
        );

        if (_global) {
          _global._info = _global._info ? _global._info : {};
          _global._info._FBtokenError = err;
        }

        this.hasFirebaseRequestPermissionToken = 'FAIL';

        this.firebaseRequestPermissionError = err;
      }
    );
  }

  getToken(): string {
    return this.currentToken;
  }

  getHasFirebaseRequestPermissionToken(): string {
    return this.hasFirebaseRequestPermissionToken;
  }

  getHasNotificationPermission(): boolean {
    return this.hasNotificationPermission;
  }

  getAlertEnabledNotifications(): boolean {
    return this.hasFirebaseRequestPermissionToken === 'FAIL' && this.hasNotificationPermission === false;
  }

  getFirebaseRequestPermissionError(): any {
    return this.firebaseRequestPermissionError;
  }
}
