import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@prisma/components/erasure-confirmation-modal/erasure-confirmation-modal.component';

@Component({
  selector: 'can-deactivate.dialog',
  templateUrl: './can-deactivate.dialog.html',
  styleUrls: ['./can-deactivate.dialog.scss'],
})
export class DeactivateFormDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeactivateFormDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
}
