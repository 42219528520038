<ian-header-toolbar
  heading="{{ 'ADMIN.MASTER.INSIGHTS.ROUTE' | pTranslate }}"
  [title]="pageTitle"
  [toolbarType]="$toolbarType$ | async"
  [style.margin-top]="($marginSubject$ | async) + 'px'"
  toolbarType="1"
  class="animation-toolbar"
></ian-header-toolbar>

<mat-sidenav-container fxFlex>
  <mat-sidenav
    #sidenav
    class="filter"
    fxLayout="column"
    mode="side"
    [autoFocus]="false"
    [opened]="isAdvancedSearch$ | async"
  >
    <app-insights-advanced-search
      (newSearch)="$isLoading = $event"
      fxLayout="column"
      fxFlex
    ></app-insights-advanced-search>
  </mat-sidenav>
  <mat-sidenav-content
    fxFlex
    (scroll)="$onScroll($event)"
    class="UXv2_withFixedScrollTable UXv2_noScroll"
    style="--mat-table-offset: -52px"
  >
    <ian-card [contentElevation]="true" class="background-fullsecreen">
      <ian-card-header>
        <ian-header-masive-action-and-filter-v2
          [isLoading]="$isLoading && $typeIsLoading"
          [buttons]="toolbarActionButtons"
          (actionExecuted)="executeAction($event)"
          (filterSearch)="onSearch($event)"
        ></ian-header-masive-action-and-filter-v2>

        <mat-divider></mat-divider>
      </ian-card-header>
      <ian-card-content>
        <div fxLayout="column">
          <div class="spinner-container" *ngIf="$isLoading === true || $typeIsLoading === true">
            <custom-loader></custom-loader>
          </div>

          <ian-no-items-found
            *ngIf="$isLoading === false && $typeIsLoading === false && dataSource && dataSource.data && dataSource.data.length === 0"
            [dataSource]="dataSource"
            class="noElements"
          ></ian-no-items-found>

          <div
            fxLayout="column"
            *ngIf="$isLoading === false && $typeIsLoading === false && dataSource && dataSource.data && dataSource.data.length > 0"
          >
            <prisma-table
              [dataSource]="dataSource"
              [columnsConfig]="columnsType"
              [paginationConfig]="pagination"
              (page)="onPageEvent($event)"
              [stickyHeader]="true"
              [clickeable]="false"
            >
              <!-- CUSTOM COLUMNS -->
              <ng-container matColumnDef="isSubscribed">
                <mat-header-cell
                  *matHeaderCellDef
                  class="checkbox-width"
                  PrismaTableFlexColumn
                  [PrismaTableSize]="{ column: columnsType, columnHeader: 'isSubscribed'}"
                ></mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  class="checkbox-width morePaddingForClick"
                  PrismaTableFlexColumn
                  [PrismaTableSize]="{ column: columnsType, columnHeader: 'isSubscribed'}"
                >
                  <prisma-context-menu-insights
                    [insight]="element"
                    [alreadySubscripted]="element.isSubscribed"
                    [typeList]="typeList$"
                    (subscriptionDone)="$event ? getList() : null"
                  ></prisma-context-menu-insights>
                </mat-cell>
              </ng-container>
              <ng-container matColumnDef="action">
                <mat-header-cell
                  *matHeaderCellDef
                  PrismaTableFlexColumn
                  [PrismaTableSize]="{ column: columnsType, columnHeader: 'action'}"
                >
                  {{ 'ADMIN.MASTER.INSIGHTS.ACTIONS' | pTranslate }}
                </mat-header-cell>
                <mat-cell
                  *matCellDef="let element"
                  PrismaTableFlexColumn
                  [PrismaTableSize]="{ column: columnsType, columnHeader: 'action' }"
                >
                  <ng-container *ngIf="!element?.actionUrl">
                    <prisma-route-to-another-page
                      *ngIf="!$hasReport(element)"
                      [pathFx]="$navigateToAnotherPage"
                      [paramsFx]="$getParams"
                      [data]="element"
                    >
                      {{ 'ADMIN.MASTER.INSIGHTS.' + element.actionName | pTranslate }}
                    </prisma-route-to-another-page>
                    <prisma-route-to-another-page
                      *ngIf="$hasReport(element)"
                      path="home/dashboard/"
                      [params]="{ hasUrl: true }"
                      (navigationDone)="setDashBoardUrl(element)"
                    >
                      {{ 'ADMIN.MASTER.INSIGHTS.' + element.actionName | pTranslate }}
                    </prisma-route-to-another-page>
                  </ng-container>
                </mat-cell>
              </ng-container>
              <!-- //CUSTOM COLUMNS -->
            </prisma-table>
          </div>
        </div>
      </ian-card-content>
    </ian-card>
  </mat-sidenav-content>
</mat-sidenav-container>
