<div fxFlex fxLayout="column" class="mt-16" fxLayoutAlign="end end">
  <color-circle
    [attr.data-test-id]="dataTestId"
    fxFlex="calc(100%+0px)"
    [color]="color"
    [colors]="colors"
    (onChangeComplete)="_hex = $event.color.hex; setColor()"
    style="display: contents"
  ></color-circle>
  <color-slider
    [className]="'w-100-p mt-16'"
    [color]="color"
    (onChangeComplete)="_hex = $event.color.hex; setColor()"
    style="display: contents"
  ></color-slider>
</div>
