import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { _log } from '@shared/aux_helper_environment';
import { _cloneDeep, _timeout } from '@shared/aux_helper_functions';
import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { _round, _getTmpProdId } from 'app/spaces/static_common/spaces_aux_helpers';
import { nanoid } from 'nanoid';
import { _getAppZoomScale } from 'app/layout/components/global-settings-panel/global-settings-panel.component';

export const svg_base_template = `
<svg:g
  class="psvg-baseRect-base"
  *ngIf="!hideOnDrag && !hideOnZoom"
  cdkDragPreviewClass="_drag"
  cdkDrag
  [id]="id"
  [cdkDragDisabled]="dragDisabled || !selected || dragDisabledForced === true"
  [cdkDragStartDelay]="0"
  [cdkDragFreeDragPosition]="renderPosition"
  (cdkDragStarted)="_startMoveElement($event)"
  (cdkDragMoved)="_moveElement($event)"
  (cdkDragEnded)="_dropElement($event)"
  [ngClass]="{ _disableEvents: !canSelect && !canHasChilds && (tabSelected !== 'ANALYSIS' && tabSelected !== 'CONSTRAINTS' && tabSelected !== 'FP_ANALISYS')}"
>
  <svg:g class="selectRoot" >
    <!--// SELECCION-->

    <!--// AREA DRAGUEABLE-->
    <svg:rect
      [id]="'DRG_' + id_drag + 'BOX'"
      class="psvg-baseRect-drop"
      [attr.data-psvg-type]="svgCompType"
      [attr.data-psvg-comp-id]="id"
      [attr.width]="renderSize.w"
      [attr.height]="renderSize.h + getOffsetY()"
      [attr.y]="- getOffsetY()"
      [attr.fill]="fill || 'transparent'"
      (click)="onClick($event)"
      (contextmenu)="onRightClick($event)"
      [matTooltip]="!selected ? tooltipTxt : null"
      [matTooltipShowDelay]="tooltipTxt ? 750 : null"
    >
    </svg:rect>


    <!--// ChildsTemplate-->
    <ng-container *ngTemplateOutlet="childsTemplate"></ng-container>

    <svg:rect
      class="psvg-baseRect-selected psvg-baseRect-selected-highlighted"
      *ngIf="selectedHighlighted && !selected"
      [attr.width]="renderSize.w"
      [attr.height]="renderSize.h + getOffsetY()"
      [attr.y]="- getOffsetY()"
      [style.stroke-width]="strokeWidth"
      [style.stroke-dasharray]="'16px,4px'"
    ></svg:rect>

    <svg:rect
      class="psvg-baseRect-selected"
      *ngIf="selected"
      [attr.width]="renderSize.w"
      [attr.height]="renderSize.h + getOffsetY()"
      [attr.y]="- getOffsetY()"
      [style.stroke-width]="strokeWidth"
      stroke-linecap="butt"
    ></svg:rect>

  </svg:g>
</svg:g>
`;

export const svg_base_styles = `
.cdk-drag-dragging {
  cursor: move;
}
.psvg-baseRect-selected {
  fill: none;
  pointer-events: none;
  stroke: var(--space-selectedColor);
  stroke-align: inside;
}
.psvg-baseRect-img,
._disableEvents {
  pointer-events: none;
}
`;

@Component({
  selector: '[app-svg-base]',
  template: svg_base_template,
  styles: [svg_base_styles],
})
export class SvgBaseComponent implements OnInit, OnDestroy {
  ngOnInit() {
    this.updateRenderValues();
  }

  ngOnDestroy() {}

  @ViewChild('panelRightContent') panelRightContent: ElementRef;

  @Output() onSelected: EventEmitter<SvgBaseComponent> = new EventEmitter();
  @Output() onStartDrag: EventEmitter<{ event: CdkDragEnd; objData: any }> = new EventEmitter();
  @Output() onStopDrag: EventEmitter<{ event: CdkDragEnd; objData: any }> = new EventEmitter();
  @Output() onMoved: EventEmitter<{ event: CdkDragEnd; objData: any }> = new EventEmitter();

  @Input() id = _getTmpProdId();
  @Input() outsideElementInDrag = null;

  @Input() dragDisabled = true;
  @Input() dragDisabledForced = false; //Para floorplans
  @Input() strokeWidth = '3px';
  @Input() canSelect = true;
  @Input() menuOnSelect = true;
  @Input() fill = null;
  @Input() tabSelected = null;

  id_drag = nanoid();

  position: { x: number; y: number } = { x: 0, y: 0 };
  canHasChilds = false;

  svgCompType = 'base';
  self = this;
  backPosition: { x: number; y: number } = { x: 0, y: 0 };

  postSelect: Function = null;

  tooltipTxt = null;

  doFoo() {
    _log('doFoo!');
  }

  testEv(ev, label, enabled = true) {
    if (enabled) _log('[testEv]', label, ev);
  }

  @Input('position')
  set set_position(value: { x: number; y: number }) {
    if (!value) return;
    if (this.position.x === value.x && this.position.y === value.y) return;
    this.backPosition = _cloneDeep(this.position);
    this.position = value;
    this.updateRenderValues();
  }

  resetPosition() {
    this.set_position = this.backPosition;
  }

  size: { w: number; h: number } = { w: 100, h: 100 };
  @Input('size')
  set set_size(value: { w: number; h: number }) {
    if (!value || !value.w || !value.h) return;
    if (this.size.w === value.w && this.size.h === value.h) return;
    this.size = value;
    this.updateRenderValues();
  }

  scale = 1;
  @Input('scale')
  set set_scale(value: number) {
    if (value === undefined || this.scale === value) return;
    this.scale = value;
    this.updateRenderValues();
  }

  @Input() testVal = 0;
  increaseTestVal = () => this.testVal++;

  renderPosition: { x: number; y: number } = { x: 0, y: 0 };
  renderSize: { w: number; h: number } = { w: 0, h: 0 };
  relativeBoundingBox: { x: number; y: number; top: number; bottom: number; left: number; right: number } = {
    x: 0,
    y: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };

  hideOnDrag = false;
  hideOnZoom = false;

  selected = false;
  selectedHighlighted = false;

  //CACHES
  el_canvasMainSvg = null;
  el_this = null;
  el_menu = null;

  public floatMenuItemList = [];

  trackById = item => item.id;

  offsetPosY(offset) {
    if (!offset) return;
    let newY = this.round(this.position.y + offset);
    this.set_position = { x: this.position.x, y: newY };
  }

  getObjDataCloned() {
    //Propiedad para extender
    return null;
  }

  async _dropElement(ev) {
    let offsetX = ev.distance.x / this.scale;
    let offsetY = ev.distance.y / this.scale;
    let newX = this.round(this.position.x + offsetX);
    let newY = this.round(this.position.y + offsetY);
    this.set_position = { x: newX, y: newY };

    await _timeout(1);

    this.onStopDrag.emit({
      event: ev,
      objData: this.getObjDataCloned(),
    });
  }

  _moveElement(ev) {
    this.onMoved.emit({
      event: ev,
      objData: this.getObjDataCloned(),
    });
  }

  _startMoveElement(ev) {
    this.onStartDrag.emit({
      event: ev,
      objData: this.getObjDataCloned(),
    });
  }

  getOffsetY() {
    return 0;
  }

  updateRenderValues() {
    this.renderPosition = { x: this.position.x * this.scale, y: this.position.y * this.scale };
    this.renderSize = { w: this.size.w * this.scale, h: this.size.h * this.scale };
    if (this.selected) this.updateRelativeBoundingBox();
  }

  getSelfBounding(): DOMRect {
    if (!document) return null;
    let id = String(this.id_drag);
    let elementSelector = String('#DRG_' + id + 'BOX');
    if (!this.el_this || !this.el_this.parentNode) this.el_this = document.querySelector(elementSelector) as HTMLElement;
    if (!this.el_this) return null;
    return this.el_this.getBoundingClientRectCustomScale(true);
  }

  async updateRelativeBoundingBox() {
    if (!document) return null;
    if (!this.selected) return;
    await _timeout(0);

    if (!this.el_canvasMainSvg || !this.el_canvasMainSvg.parentNode) {
      this.el_canvasMainSvg = document.querySelector('#psvg-canvas-main-svg') as HTMLElement;
      await _timeout(0);
    }
    if (!this.el_canvasMainSvg) return;

    let this_bb = this.getSelfBounding();
    let el_main_pos = this.el_canvasMainSvg.getBoundingClientRectCustomScale(true) as DOMRect;
    if (!this_bb || !el_main_pos) return;

    let new_bb = _cloneDeep(this.relativeBoundingBox);

    new_bb.x = this_bb.x - el_main_pos.x;
    new_bb.y = this_bb.y - el_main_pos.y;
    new_bb.top = this_bb.top - el_main_pos.y;
    new_bb.bottom = this_bb.bottom - el_main_pos.y;
    new_bb.left = this_bb.left - el_main_pos.x;
    new_bb.right = this_bb.right - el_main_pos.x;

    this.relativeBoundingBox = new_bb;
    this.reposFloatMenu();
  }

  reposFloatMenu() {
    if (!document) return null;
    if (!this.selected) return;
    if (!this.el_menu || !this.el_menu.parentNode) this.el_menu = document.querySelector('#psvg-float-menuItem') as HTMLElement;
    if (!this.el_menu) return;

    this.el_menu.classList.remove('psvg-hidden');
    this.el_menu.style.top = this.relativeBoundingBox.bottom + 0 + 'px';

    let halfWidth = (this.relativeBoundingBox.right - this.relativeBoundingBox.left) * 0.5;
    this.el_menu.style.left = this.relativeBoundingBox.left + halfWidth + 'px';
  }

  public select(v = true) {
    if (this.selected === v) return;
    this.selected = v;
    this.updateRelativeBoundingBox();
    if (this.selected && this.postSelect) this.postSelect();
  }

  onClick(ev) {
    if (this.canSelect && !this.selected) {
      this.onSelected.emit(this);
    }
  }

  async onRightClick(ev) {
    if (this.canSelect) {
      this.onSelected.emit(this);
      setTimeout(async () => {
        await this.updateRelativeBoundingBox();
        let menu = document.getElementById('psvg-float-menuItem');
        if (menu) menu.click();
        setTimeout(() => {
          this.disablebackdrop();
        }, 1);
        setTimeout(() => {
          this.disablebackdrop();
        }, 48);
      }, 16);
    }
    ev.preventDefault();
    ev.stopPropagation();
    return false;
  }

  disablebackdrop() {
    let backdrop = document.getElementsByClassName('cdk-overlay-backdrop');
    if (!backdrop) return;
    backdrop = (backdrop as any)[0];
    if (!backdrop || !(backdrop as any).addEventListener) return;
    (backdrop as any).addEventListener('contextmenu', (offEvent: any) => {
      (backdrop as any).click();
      offEvent.stopPropagation();
      offEvent.preventDefault();
    });
  }

  /*AUX*/

  _isPointInRectangle(x, y, pos, size) {
    return (
      x > pos.x /***********************/ &&
      x < pos.x + size.w /**************/ &&
      y > pos.y /***********************/ &&
      y < pos.y + size.h
    );
  }

  _isRectsinRect(r1pos, r1size, r2pos, r2size) {
    const check = this._isPointInRectangle;
    return (
      check(r1pos.x, r1pos.y, r2pos, r2size) /**/ &&
      check(r1pos.x + r1size.w, r1pos.y, r2pos, r2size) /**/ &&
      check(r1pos.x, r1pos.y + r1size.h, r2pos, r2size) /**/ &&
      check(r1pos.x + r1size.w, r1pos.y + r1size.h, r2pos, r2size) /**/
    );
  }

  _isRectsColliding(r1pos, r1size, r2pos, r2size) {
    return !(
      r1pos.x > r2pos.x + r2size.w /**/ ||
      r1pos.x + r1size.w < r2pos.x /**/ ||
      r1pos.y > r2pos.y + r2size.h /**/ ||
      r1pos.y + r1size.h < r2pos.y
    );
  }

  _checkBoxIsInThisBox(pos, size, checkAll = true) {
    let check = checkAll
      ? this._isRectsinRect(pos, size, { x: 0, y: 0 }, this.size)
      : this._isRectsColliding(pos, size, { x: 0, y: 0 }, this.size);
    return check;
  }

  _fitPosInBox(r1pos, r1size, r2pos, r2size) {
    let rv = _cloneDeep(r1pos);
    if (rv.x < r2pos.x) rv.x = r2pos.x;
    if (rv.x + r1size.w > r2pos.x + r2size.w) rv.x = r2pos.x + r2size.w - r1size.w;
    if (rv.y < r2pos.y) rv.y = r2pos.y;
    if (rv.y + r1size.h > r2pos.y + r2size.h) rv.y = r2pos.y + r2size.h - r1size.h;
    rv.x = this.round(rv.x);
    rv.y = this.round(rv.y);
    return rv;
  }

  round = _round;
}
