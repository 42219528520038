<ng-container>
  <iframe
    width="100%"
    height="600"
    [src]="dataVideoUrl"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen="true"
    allownetworking="internal"
  ></iframe>
</ng-container>
