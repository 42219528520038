import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
const FEATURE_ID = 'DashBoardState';

export class SetDashBoardUrl {
  static readonly type = `${FEATURE_ID} SetDashBoardUrl`;
  constructor(readonly payload: string) {}
}
export class ResetDashBoardUrl {
  static readonly type = `${FEATURE_ID} ResetDashBoardUrl`;
}
export interface DashBoardStateModel {
  url: string;
}
@Injectable({ providedIn: 'root' })
@State<DashBoardStateModel>({
  name: FEATURE_ID,
  defaults: {
    url: '',
  },
})
export class DashBoardState {
  constructor() {}

  @Selector()
  static getUrl(state: DashBoardStateModel): string {
    return state.url;
  }

  @Action(SetDashBoardUrl)
  setDashboardUrl(context: StateContext<DashBoardStateModel>, action: SetDashBoardUrl): void {
    context.patchState({
      url: action.payload,
    });
  }

  @Action(ResetDashBoardUrl)
  resetDashboardUrl(context: StateContext<DashBoardStateModel>, action: ResetDashBoardUrl): void {
    context.patchState({
      url: '',
    });
  }
}
