import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { _log } from '@shared/aux_helper_environment';
import { AppARemoveErrorAction } from 'app/store/store.actions';
import { ErrorMessageModalComponent } from '../components/error-message-modal/error-message-modal.component';
import { _debounce } from '@shared/aux_helper_functions';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  private queue: Array<any> = [];

  private isShowing = false;

  private showInternal = _debounce(this._showInternal, 64, {});

  private checkQueue = _debounce(this._checkQueue, 1, {});

  private enabled = _debounce(this._enabled, 1024, {});

  constructor(private readonly store: Store, private readonly dialog: MatDialog) {}

  show(data: { forceShow?: boolean; forceMsg?: string; [key: string]: any } | string): void {
    // Agrega a la cola.
    let dataAux;
    if (data instanceof HttpErrorResponse) {
      const dataHttp = data as HttpErrorResponse;
      if (dataHttp.error) {
        dataAux = {
          message: dataHttp.error.message,
          stackTrace: dataHttp.error.stackTrace,
          success: dataHttp.error.success,
          exception: dataHttp.error.exception,
          original: dataHttp,
        };
      } else {
        dataAux = {
          message: `${dataHttp.status} - ${dataHttp.statusText}: ${dataHttp.message}`,
          stackTrace: '',
          success: false,
          exception: null,
          original: dataHttp,
        };
      }
    } else {
      dataAux = data;
    }

    this.queue.push(dataAux || {});
    this.checkQueue();
  }

  showNoQueue(data: any, err?): void {
    if (!this.isShowing) {
      this.showInternal(data, false, err);
    }
  }

  private _showInternal(data: any, queue = true, error?): void {
    const matches = document.querySelectorAll('#_errorGenericModal');
    if (matches && matches[0]) {
      return;
    }

    if (false) _log('[showInternal]');

    const dialogRef = this.dialog.open(ErrorMessageModalComponent, {
      width: '512px',
      data: { ...data, error },
    });
    dialogRef.afterClosed().subscribe(result => {
      const action = new AppARemoveErrorAction(data);
      this.store.dispatch(action);
      if (queue) {
        this.checkQueue();
      }
      this.enabled();
    });
  }

  private _checkQueue(): void {
    if (false) _log('[_checkQueue]');

    const matches = document.querySelectorAll('#_errorGenericModal');
    if (matches && matches[0]) {
      return;
    }

    if (!this.isShowing) {
      const localData = this.queue.shift();
      if (localData) {
        this.isShowing = true;
        this.showInternal(localData);
      }
    }
  }

  private _enabled(): void {
    this.isShowing = false;
  }
}
