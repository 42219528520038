import { _log } from '@shared/aux_helper_environment';
import {
  _FPA_clearAllCaches,
  _FPA_getMetaDataTotals,
  _FPA_getMetaDataTotalsByPlanograms,
} from 'app/spaces/floorplans-editor/auxsFloorplans/auxsFloorplansAnalytics';
import { dummyDataFloorplansAnalytics, floorplan_obj_dummyData_A } from 'app/spaces/floorplans-editor/stores/floorplans-editor_dummy_data';

const fastCheckDes2023 = () => {
  let prods = new Array(false ? 1 : 20 * 1000).fill(dummyDataFloorplansAnalytics.planograms[0].prods[0]);
  let itemsData = dummyDataFloorplansAnalytics.itemsData;

  console.time();
  //   let rv = _FPA_getMetaDataTotals(prods, itemsData);
  //   let rv = _FPA_getMetaDataTotalsByPlanogram(dummyDataFloorplansAnalytics, [1]);
  //   let rv = _FPA_getMetaDataTotalsByLevel(floorplan_obj_dummyData_A, dummyDataFloorplansAnalytics, 1);
  let rv = _FPA_getMetaDataTotalsByPlanograms(floorplan_obj_dummyData_A, dummyDataFloorplansAnalytics, []);
  console.timeEnd();

  _log(['fastCheckDes2023'], rv);
};

const main = () => {
  _log('FEDEV');

  if (false) fastCheckDes2023();
};

export { main as mainFeDev };
