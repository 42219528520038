const insightsList = {
  items: [
    {
      id: 1,
      description: 'Se detectó un aumento de 3,2% del Margen de la Categoría Gaseosas, puede estar asociado a  reglas de margen.',
      name: 'Aumento el margen en Cat. Gaseosas',
      margin: 5000,
      createDate: new Date(2020, 7, 6),
      type: 1,
      store: null,
      storeName: '',
      storeTag: null,
      storeTagName: '',
      category: 55,
      categoryName: 'Gaseosas',

      actionName: 'REVIEW_PRICES',
      actionUrl: 'api/dashboard?level4=8&store_name=1',

      isSubscribed: false,
      hasAllSuscriptionTypes: false,
      hasAllSuscriptionCategories: false,
      hasAllSuscriptionStore: false,
      hasAllSuscriptionStoreTag: false,
    },
    {
      id: 2,
      name: 'Bajo el margen en Tienda G. Paz',
      description: 'Se detectó una baja de -4,3% del Margen en el punto de venta  Tienda G. Paz.',
      margin: -9000,
      createDate: new Date(2020, 6, 20),
      type: 2,
      store: 2,
      storeName: 'G. Paz',
      storeTag: null,
      storeTagName: null,
      category: null,
      categoryName: null,

      actionName: 'REVIEW_PRICES',
      actionUrl: '/prices/decisions/price-decisions/list',

      isSubscribed: true,
      hasAllSuscriptionTypes: false,
      hasAllSuscriptionCategories: false,
      hasAllSuscriptionStore: true,
      hasAllSuscriptionStoreTag: false,
    },
    {
      id: 3,
      name: 'Aumento el margen en Etiqueta Provincia ||',
      description: 'Se detectó un aumento de 10% del Margen en Etiqueta Provincia, puede estar asociado a una regla de margen',
      margin: 10000,
      createDate: new Date(2020, 1, 6),
      type: 1,
      store: null,
      storeName: null,
      storeTag: 77,
      storeTagName: 'Provincia',
      category: null,
      categoryName: '',

      actionName: 'REVIEW_PRICES',
      actionUrl: '/home/dashboard',

      isSubscribed: true,
      hasAllSuscriptionTypes: false,
      hasAllSuscriptionCategories: false,
      hasAllSuscriptionStore: false,
      hasAllSuscriptionStoreTag: true,
    },
    {
      id: 4,
      name: 'Bajo el margen en Tienda 9 de Julio',
      description:
        'Se detectó una baja de -4,3% del Margen en el punto de venta  Tienda 9 de Julio para la categoria Bebidas etiqueta Filtros',
      margin: -9000,
      createDate: new Date(2020, 5, 10),
      type: 2,
      store: 66,
      storeName: '9 de Julio',
      storeTag: null,
      storeTagName: null,
      category: null,
      categoryName: null,

      actionName: 'REVIEW_PRICES',
      actionUrl: '/prices/decisions/price-decisions/list',

      isSubscribed: true,
      hasAllSuscriptionTypes: true,
      hasAllSuscriptionCategories: false,
      hasAllSuscriptionStore: false,
      hasAllSuscriptionStoreTag: false,
    },
    {
      id: 5,
      name: 'Aumento el margen en Categoria Gaseosas',
      description: 'Se detectó una aumento de 6,3% del Margen en la categoria Gaseosas, Calidad Superior',
      margin: 100,
      createDate: new Date(2020, 5, 10),
      type: 1,
      store: null,
      storeName: '',
      storeTag: 11,
      storeTagName: 'Calidad',
      category: 55,
      categoryName: 'Gaseosas',

      actionName: 'REVIEW_PRICES',
      actionUrl: '/prices/decisions/price-decisions/list',

      isSubscribed: true,
      hasAllSuscriptionTypes: false,
      hasAllSuscriptionCategories: false,
      hasAllSuscriptionStore: false,
      hasAllSuscriptionStoreTag: false,
    },
  ],
  totalItemCount: 5,
  pageIndex: 0,
  pageSize: 10,
};

const insightsTypes = [
  {
    id: 1,
    type: 'Aumento de margen',
    frequency: null,
    actionName: null,
    actionUrl: null,
  },
  {
    id: 2,
    type: 'Baja de margen',
    frequency: null,
    actionName: null,
    actionUrl: null,
  },
];

export const dummyInsights = {
  insightsList,
  insightsTypes,
};
