import { ScrollDispatcher } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { _isDev, _log, _warn } from '@shared/aux_helper_environment';
import { _cloneDeep, _equal, _getNewNumberId, _uniqueElementsByKey } from '@shared/aux_helper_functions';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { GenericTagsSelectV2Service, TagFlatModelGen } from './generic-tag-select-v2.service';

let _aux_treeTags: TagFlatModelGen[] = null;

export interface TagTreeWithRootSelectedModal {
  tagId: number;
  tagValues: number[];
}

export const getTagTreeRootAndValuesSelectedv2 = (tagValues: number[], treeTags: TagFlatModelGen[]): TagTreeWithRootSelectedModal[] => {
  let list = [];
  if (tagValues && tagValues.length === 0) return list;
  tagValues.forEach(tv => {
    const rootId = _aux_getTagRootFromId(tv, treeTags);
    const value = {
      tagId: rootId,
      tagValues: [tv],
    };
    const _value = list.findIndex(x => x.tagId === rootId);
    if (_value !== -1) {
      const aux = list[_value];
      list[_value] = { ...aux, tagValues: [...aux.tagValues, ...value.tagValues] };
    } else {
      list.push(value);
    }
  });
  return list;
};

/*
 Método Global de etiquetas/tags, se le pasa un array de tagsValues y devuelve uno igual pero filtrando por el último nivel (quita los padres/abuelos)
*/
// PRO-357
export const _aux_cleanParentTags = (tagValues: number[], treeTags: TagFlatModelGen[]) => {
  if (!(tagValues?.length > 0)) return tagValues;
  if (!(treeTags?.length > 0)) return tagValues;

  //Optimización > Filtro de arbol con tags que si tienen hijos
  const tagsWithChilds = treeTags.filter(masterTag => masterTag?.values?.length > 0);
  if (!(tagsWithChilds?.length > 0)) return tagValues; //Si no hay tags con valores hijos no modifica nada

  let rv = [...tagValues];
  let toRemove = [];

  tagValues.forEach(tagValue => {
    tagsWithChilds.forEach(masterTag => {
      (masterTag?.values).forEach(childTag => {
        //Encontró tag hijo, remueve al padre
        if (childTag.id === tagValue) toRemove.push(childTag.parentId);

        (childTag?.values || []).forEach(grandChildTag => {
          if (grandChildTag.id === tagValue) {
            //Encontró tag nieto, remueve al padre y abuelo
            toRemove.push(grandChildTag.parentId);
            toRemove.push(childTag.parentId);
          }
        });
      });
    });
  });

  rv = rv.filter(id => !toRemove.includes(id));

  if (false) _log({ tagValues, rv, tagsWithChilds, toRemove });

  return [...new Set([...rv])];
};

/*
 Método Global de etiquetas/tags, se le pasa un array de tagsValues y devuelve uno igual pero con el agregado de los tags padres/abuleos (agrega los padres/abuelos)
*/
// PRO-357
export const _aux_addParentTags = (tagValues: number[], treeTags: TagFlatModelGen[]) => {
  if (!(tagValues?.length > 0)) return tagValues;
  if (!(treeTags?.length > 0)) return tagValues;

  //Optimización > Filtro de arbol con tags que si tienen hijos
  const tagsWithChilds = treeTags.filter(masterTag => masterTag?.values?.length > 0);
  if (!(tagsWithChilds?.length > 0)) return tagValues; //Si no hay tags con valores hijos no modifica nada

  let rv = [...tagValues];
  let toAdd = [];

  tagValues.forEach(tagValue => {
    tagsWithChilds.forEach(masterTag => {
      (masterTag?.values).forEach(childTag => {
        //Encontró tag hijo, agrega al padre
        if (childTag.id === tagValue) toAdd.push(childTag.parentId);

        (childTag?.values || []).forEach(grandChildTag => {
          if (grandChildTag.id === tagValue) {
            //Encontró tag nieto, agrega al padre y abuelo
            toAdd.push(grandChildTag.parentId);
            toAdd.push(childTag.parentId);
          }
        });
      });
    });
  });

  rv = [...new Set([...rv, ...toAdd])];

  if (false) _log({ tagValues, rv, tagsWithChilds, toAdd: toAdd });

  return rv;
};

/*
 Método Global de etiquetas/tags, se le pasa un array de tagsValues y devuelve uno igual pero con el agregado de los tags padres/abuleos (agrega los padres/abuelos) y
 sin valores de 2do nivel > útil para comparar solo padres
*/
// PRO-357
export const _aux_addParentTagsAndRemoveChilds = (tagValues: number[], treeTags: TagFlatModelGen[]) => {
  if (!(tagValues?.length > 0)) return tagValues;
  if (!(treeTags?.length > 0)) return tagValues;

  //Optimización > Filtro de arbol con tags que si tienen hijos
  const tagsWithChilds = treeTags.filter(masterTag => masterTag?.values?.length > 0);
  if (!(tagsWithChilds?.length > 0)) return tagValues; //Si no hay tags con valores hijos no modifica nada

  let rv = [...tagValues];
  let toAdd = [];
  let toRemove = [];

  tagValues.forEach(tagValue => {
    tagsWithChilds.forEach(masterTag => {
      (masterTag?.values).forEach(childTag => {
        //Encontró tag hijo, agrega al padre
        if (childTag.id === tagValue) {
          toAdd.push(childTag.parentId);
          toRemove.push(childTag.id);
        }

        (childTag?.values || []).forEach(grandChildTag => {
          if (grandChildTag.id === tagValue) {
            //Encontró tag nieto, agrega al al abuelo y remueve el nieto y padre
            toAdd.push(grandChildTag.parentId);
            toRemove.push(childTag.parentId);
            toRemove.push(childTag.id);
          }
        });
      });
    });
  });

  rv = [...new Set([...rv, ...toAdd])].filter(id => !toRemove.includes(id));

  if (false) _log({ tagValues, rv, tagsWithChilds, toAdd: toAdd });

  return rv;
};

/*
 Método Global de etiquetas/tags, se le pasa un array de tagsValues y devuelve si hay valores de etiquetas anidadas
*/
// PRO-357
export const _aux_hasNestedValues = (tagValues: number[], treeTags: TagFlatModelGen[]) => {
  if (!(tagValues?.length > 0)) return false;
  if (!(treeTags?.length > 0)) return false;

  //Optimización > Filtro de arbol con tags que si tienen hijos
  const tagsWithChilds = treeTags.filter(masterTag => masterTag?.values?.length > 0);
  if (!(tagsWithChilds?.length > 0)) return false; //Si no hay tags con valores hijos no modifica nada

  let rv = false;

  tagValues.forEach(tagValue => {
    tagsWithChilds.forEach(masterTag => {
      (masterTag?.values).forEach(childTag => {
        //Encontró tag hijo, devuelve true
        if (childTag.id === tagValue) {
          rv = true;
        }
      });
    });
  });

  return rv;
};

export const getTagTreeRootAndValuesSelected = (
  tagValues: TagTreeSelectedModel[],
  treeTags: TagFlatModelGen[],
  addWithOutIndex = false
): TagTreeWithRootSelectedModal[] => {
  const _selectedTags: TagTreeWithRootSelectedModal[] = [];
  tagValues.forEach(tv => {
    const rootId = _aux_getTagRootFromId(tv.id, treeTags);
    if (!addWithOutIndex) {
      _selectedTags[rootId] = {
        tagId: rootId,
        tagValues: _selectedTags[rootId] && _selectedTags[rootId]?.tagValues ? [..._selectedTags[rootId]?.tagValues, tv.id] : [tv.id],
      };
    } else {
      _selectedTags.push({
        tagId: rootId,
        tagValues: _selectedTags[rootId] && _selectedTags[rootId]?.tagValues ? [..._selectedTags[rootId]?.tagValues, tv.id] : [tv.id],
      });
    }
  });
  return _selectedTags;
};

export const _aux_tagTreeGroupFormat = (tags: TagTreeSelectedModelInternal[]): TagTreeWithRootSelectedModal[] => {
  return tags.map(t => {
    return { tagId: t.rootTagId, tagValues: t.selected.map(tv => tv.id) };
  });
};

export const _aux_tagTreeGroupToFE_MAP = (tags: TagTreeWithRootSelectedModal[]): TagTreeSelectedModel[] => {
  return (
    tags
      .map(t => t.tagValues) // obtengo tagValues
      .flat() // aplano en un solo listado de ids
      .map(t => {
        return { id: t }; // mapeo a formando {id:number}
      }) || []
  );
};

export interface TagTreeSelectedModel {
  id: number;
  value?: string;
}

export interface TagTreeSelectedModelInternal {
  rootTagId: number;
  selected?: TagTreeSelectedModel[];
  disabled?: boolean;
}

export const _aux_getTagItemObjFromId = ($ids: number | number[], treeTags: TagFlatModelGen[] = _aux_treeTags): TagFlatModelGen[] => {
  if ($ids == null) return [];

  if (treeTags == null || !treeTags.length) {
    _warn('No treeTags', treeTags);
    return [];
  }

  let ids = typeof $ids === 'number' ? [$ids] : _cloneDeep($ids);

  let rv: TagFlatModelGen[] = [];

  treeTags.forEach(obj => {
    if (obj !== null && ids.includes(obj.id)) {
      rv.push(obj);
    } else {
      if (obj && obj.values && obj.values.length) {
        const childs: any = _aux_getTagItemObjFromId($ids, obj.values);
        if (childs && childs.length) rv = [...rv, ...childs];
      }
    }
  });

  rv = _uniqueElementsByKey(rv as [], 'id');

  return (typeof $ids === 'number' ? (rv || [])[0] : rv) as any;
};

export const _aux_filterTagsRootExistByRootId = (rootIds: any[], treeTags: TagFlatModelGen[] = _aux_treeTags) => {
  let tagsFlats = [...new Set(_aux_flatternTagsTreeModelGen(treeTags, 0).map(t => t.tagId))].filter(Boolean);
  let rv = [];

  (rootIds || []).forEach(obj => {
    if (tagsFlats.includes(obj?.rootTagId)) {
      rv.push(obj);
    } else {
      if (false) _warn(obj.rootTagId, 'no exist');
    }
  });

  return rv;
};

export const _aux_getTagParentIdFromChildId = ($ids: number | number[], treeTags: TagFlatModelGen[] = _aux_treeTags): number | number[] => {
  if ($ids == null) return [];

  if (treeTags == null || !treeTags.length) {
    _warn('No treeTags', treeTags);
    return [];
  }

  let ids = typeof $ids === 'number' ? [$ids] : _cloneDeep($ids);
  let parentsObjs: any = _aux_getTagItemObjFromId(ids, treeTags);
  let rv = parentsObjs.map(obj => obj.parentId).filter(obj => obj != null);
  rv = [...new Set(rv)];

  return typeof $ids === 'number' ? (rv || [])[0] : rv;
};

export const _aux_getTagRootFromId = ($id: number, treeTags: TagFlatModelGen[] = _aux_treeTags) => {
  if (Array.isArray($id)) ($id as any) = $id[0];
  let obj = _aux_getTagItemObjFromId([$id], treeTags);

  let rv = null;

  if (obj && obj[0] && obj[0].id) {
    if (obj[0].parentId === 0 || obj[0].parentId == null) {
      rv = obj[0].tagId;
    } else {
      rv = _aux_getTagRootFromId(obj[0].parentId, treeTags);
    }
  }

  return rv;
};

export const _findTagObjById = (id: number, tags: TagFlatModelGen[] = _aux_treeTags) => {
  if (!tags?.length || !tags[0] || !tags[0]?.values || id == null) return null;

  const rv: any = tags[0].values.find(_tag => String(_tag.tagId) === String(id));

  return rv;
};

export const _aux_flatternTagsTreeModelGen = (
  treeTags: TagFlatModelGen[],
  level: number,
  group: boolean = true,
  clone: boolean = false,
  filter: (string | number)[] = null,
  intersectChilds: number[] = null,
  intersectParents: number[] = null,
  isMainCall: boolean = true
): TagFlatModelGen[] => {
  /*
      convierte de TagFlatModelGen[] a un array plano (sin hijos de hijos) solo con los padres (si es group) e hijos hermanados
      puede devolver clonado, y filtrado
    */

  if (!treeTags || !treeTags.length) return [];

  let rv: TagFlatModelGen[] = [];

  const _aux_objWithoutChilds = obj => {
    return { ...obj, values: null };
  };

  treeTags.forEach(obj => {
    if (obj.level === level && (!(filter && filter.length) || filter.includes(obj.parentId) || filter.includes(obj.id))) {
      // Insrta padre
      rv.push(_aux_objWithoutChilds(obj));
      // Insrta hijos
      if (obj.values && obj.values.length) {
        const values = obj.values.map(_aux_objWithoutChilds);
        values.forEach(child => rv.push(child));
      }
    } else {
      if (obj.level < level) {
        // otro nivel, prueba con sus hijos
        const childs = _aux_flatternTagsTreeModelGen(obj.values, level, group, false, filter, null, null, false);
        if (childs.length) {
          childs.forEach(child => rv.push(child));
        }
      }
    }
  });

  // se realiza el filtrado de id unico al final con todos los datos planos
  if (isMainCall) {
    rv = _uniqueElementsByKey(rv as [], 'id');
  }

  if (intersectChilds) {
    rv = rv.filter(obj => {
      if (group && obj.level === level) return true; /*los padres no los toca*/
      return intersectChilds.includes(obj.id);
    });
  }

  if (intersectParents) {
    rv = rv.filter(obj => {
      if (group) {
        if (obj.level === level) {
          // Si es padre lo filtra
          return intersectParents.includes(obj.id);
        }
        if (obj.level > level) {
          // Si es hijo se fija q el padre no esté filtrado
          return intersectParents.includes(obj.parentId);
        }
      }

      if (!group && obj.level === level) {
        // Como no es grupo, solo se fija que el item no tenga el padre filtrado
        return intersectParents.includes(obj.parentId);
      }

      return true;
    });
  }

  // Si no es agrupado, saca nivel padre
  if (!group) rv = rv.filter(obj => obj.level === level);

  return !clone ? rv : _cloneDeep(rv);
};

export interface TagFlatModelGenSelected {
  level1?: (number | string)[];
  level2?: (number | string)[];
  level3?: (number | string)[];
  level4?: (number | string)[];
  level5?: (number | string)[];
}

@Component({
  selector: 'generic-tags-select-v2',
  template: `
    <span *ngIf="showLoader && !loaderIsReady && !tagListReady">
      <mat-spinner [diameter]="20" color="accent" [attr.data-test-id]="'generic-tag-select-mini-v2-loader'"></mat-spinner>
    </span>
    <span *ngIf="tagListReady && loaderIsReady" class="generic-tag-select-span-wrapper" [ngClass]="width">
      <ng-container *ngFor="let item of [0, 1, 2, 3, 4]; let i = index">
        <!-- // TODO: traducción de más niveles -->
        <generic-lookup
          *ngIf="
            i >= _minLevel &&
            this['level' + i + 'Config'] &&
            this['level' + i + 'Config'].visible &&
            (this['level' + i + 'Config'].parentDependent && i > _minLevel ? this['tagLevel' + (i - 1)]?.length : true) &&
            (i == 1 || this['tagFlatLevel' + (i - 1)]?.length) &&
            this['tagFlatLevel' + i].length
          "
          [values]="this['tagFlatLevel' + i]"
          [visibleTagsValues]="this['tagFlatFiltredLevel' + i]"
          [selected]="getSelected(i)"
          [multiple]="this['level' + i + 'Config'].multiple"
          [required]="required || this['level' + i + 'Config'].required"
          [itemSize]="itemSize != null ? itemSize : this['level' + i + 'Config'].itemSize"
          [readOnly]="readOnly != null ? readOnly : !!this['level' + i + 'Config'].readOnly"
          [disabled]="disabled != null ? disabled : !!this['level' + i + 'Config'].disabled"
          [group]="this['level' + i + 'Config'].group"
          (onChange)="onChangeLevelKey(i, $event)"
          propId="id"
          propValue="value"
          [showClearAllBtn]="showClearAllBtn"
          propGroupParentId="parentId"
          [placeholder]="
            placeholder != null ? placeholder : prefixPlaceholder + this['tagFlatLevel' + i][0]?.tagName + ' ' + postfixPlaceholder
          "
          [panelClass]="panelClass"
          [attr.data-test-id]="'generic-tags-select-v2-level' + i"
          [attr.data-test-id-wrapp]="'generic-tags-select-v2-wrapp-' + this['tagFlatLevel' + i][0]?.tagName"
          [test-id]="this['tagFlatLevel' + i][0]?.tagName"
          [data-test-id]="testId"
        ></generic-lookup>
      </ng-container>
    </span>
  `,
  styles: [``],
})
export class GenericTagsSelectV2Component implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  $unsuscribreAll: Subject<void> = new Subject<void>();
  destroyed = false;

  tagTree: TagFlatModelGen[] = null;
  tagTreeInitial: TagFlatModelGen[] = null;
  tagListReady = false;

  _minLevel = 1;
  _maxLevel = 4;

  loaderIsReady = false;

  levelConfig = {
    readOnly: false,
    cascade: true,
    multiple: true,
    required: false,
    group: false,
    itemSize: 5,
    intersect: null,
    placeholder: null,
    parentDependent: false,
    disabled: false,
  };

  /**/
  tagFlatLevel1: TagFlatModelGen[] = null;
  tagFlatFiltredLevel1: TagFlatModelGen[] = null;
  tagLevel1 = [];
  tagIdLevel1 = null;
  tagFlatLevel2: TagFlatModelGen[] = null;
  tagFlatFiltredLevel2: TagFlatModelGen[] = null;
  tagLevel2 = [];
  tagIdLevel2 = null;
  tagFlatLevel3: TagFlatModelGen[] = null;
  tagFlatFiltredLevel3: TagFlatModelGen[] = null;
  tagLevel3 = [];
  tagIdLevel3 = null;
  tagFlatLevel4: TagFlatModelGen[] = null;
  tagFlatFiltredLevel4: TagFlatModelGen[] = null;
  tagLevel4 = [];
  tagIdLevel4 = null;
  tagFlatLevel5: TagFlatModelGen[] = null;
  tagFlatFiltredLevel5: TagFlatModelGen[] = null;
  tagLevel5 = [];
  tagIdLevel5 = null;
  /**/

  tagValuesWarningText = null;

  /**/
  @Input('tagsModuleType') tagsModuleType = 1;
  @Input('placeholder') placeholder: string = null;
  @Input('prefixPlaceholder') prefixPlaceholder = '';
  @Input('postfixPlaceholder') postfixPlaceholder = '';
  @Input('rootTagId') rootTagId = null;
  @Input('required') required = false;

  @Input('level1Config') level1Config = { parentDependent: false };
  @Input('level2Config') level2Config = { parentDependent: !true };
  @Input('level3Config') level3Config = { parentDependent: !true };
  @Input('level4Config') level4Config = { parentDependent: !true };
  @Input('level5Config') level5Config = { parentDependent: !true };
  /**/

  parentSelected: TagFlatModelGenSelected = null;
  @Input('selected') parentSelectedTags: TagTreeSelectedModel[] = null;

  @Input('verbose') verbose = false;
  @Input('showLoader') showLoader = true;
  @Input('refilterChilds') refilterChilds = true;
  @Input('autoFillParentsOnStart') autoFillParentsOnStart = false;
  @Input('autoFillParentsOnAlways') autoFillParentsOnAlways = false;
  @Output() onChange: EventEmitter<TagTreeSelectedModel[]> = new EventEmitter();

  @Input('readOnly') readOnly = null;
  @Input('disabled') disabled = null;
  @Input('itemSize') itemSize = null;
  @Input('levels') levels: (1 | 2 | 3 | 4)[] = [1, 2, 3];
  @Input('panelClass') panelClass: string = 'panelTags' + _getNewNumberId();
  @Input('test-id') testId: string;
  @Input('width') width = null;
  @Input('showClearAllBtn') showClearAllBtn = true;

  @Input('onFilterTagsValues') onFilterTagsValues = false; //activar o desactivar filtrado de opciones, el filtrado viene en el intersec de configLevels en las props level1Config, etc...
  @Input('configLevels') configLevels = null;
  @Input('concatManualTagTreeValues') concatManualTagTreeValues: TagFlatModelGen[] = null;

  @Input('tagTreeInitialFiltered') tagTreeInitialFiltered: TagFlatModelGen[] = null; //CAT-90 Árbol de tags pre-filtrado(parcial) (en lugar de usar el global/inicial/entero)

  @Input('autoSelectRequiredTagByDefaultTagValue') autoSelectRequiredTagByDefaultTagValue = false; //PREC-221

  _avoidEmitChanges = false;
  _initied = false;

  /**/
  constructor(
    private cd: ChangeDetectorRef,
    readonly sd: ScrollDispatcher,
    public translate: IanTranslateService,
    private service: GenericTagsSelectV2Service
  ) {}

  ngOnInit(): void {
    this.service
      .geTagsTree(this.tagsModuleType)
      .pipe(first())
      .subscribe(async data => {
        if (!_aux_treeTags && data) _aux_treeTags = _cloneDeep(data);

        if (this.tagTreeInitialFiltered?.length && true /*CAT-90*/) {
          this.tagTreeInitial = this.tagTreeInitialFiltered;
        } else {
          this.tagTreeInitial = data;
        }

        this.makeTagTree();
      });

    this.loaderIsReady = true;
  }

  getSelected(i) {
    let val = this['level' + i + 'Config'].multiple ? this['tagLevel' + i] : this['tagLevel' + i].slice(0, 1);
    return val;
  }

  makeTagTree() {
    if (this.tagTreeInitial == null) return;

    this.tagTree = _cloneDeep(this.tagTreeInitial);

    //Si tiene concatManualTagTreeValues se los agrega //DES-1061
    if (this.concatManualTagTreeValues?.length) this.tagTree = [...this.tagTree, ...this.concatManualTagTreeValues];

    if (this.rootTagId && this.tagTree[0]?.values) {
      this.tagTree[0].values = this.tagTree[0].values.filter(val => val.tagId === this.rootTagId);
    }

    this.populateCascadeCombos();

    this.tagListReady = this.tagTree != null;

    if (true && this.verbose) _log('[tagsTree]', this.tagTree);

    this.cd && !this.destroyed && this.cd.detectChanges();
  }

  setLevelConfig(level) {
    let parentConfig = this['level' + level + 'Config'] || {};
    let levelConfig = _cloneDeep(this.levelConfig);
    let visible = this.levels.includes(level);

    let rv = Object.assign({}, levelConfig, parentConfig, { visible: visible });

    if (false && this.verbose) _log(parentConfig, rv);

    if (level === 1 || level === 0) {
      rv.parentDependent = false;
      rv.group = false;
    }

    return rv;
  }

  parentSelectedTags2parentSelected(autoFillParents = false) {
    const addParent = (i, values) => {
      if (!values) return;

      let baseVals = rv[`level${i}`];
      let parentVal = _aux_getTagParentIdFromChildId(values, this.tagTree);
      if (!Array.isArray(parentVal)) parentVal = [parentVal];

      if (parentVal && parentVal.length) {
        let res = [...new Set([...baseVals, ...parentVal])];
        rv[`level${i}`] = res;
      }
    };

    if (this['tagIdLevel' + 1] == null) {
      if (false) _warn('no this[tagIdLevel + 1]');
      return;
    }

    let rv = {
      level1: [],
      level2: [],
      level3: [],
      level4: [],
    };

    (this.parentSelectedTags || []).forEach(val => {
      if (!val || !this.tagTree) return;

      let obj: any = _aux_getTagItemObjFromId([val.id], this.tagTree);
      if (obj && obj[0]) obj = obj[0];
      if (obj?.id) {
        let i = obj.level;
        rv[`level${i}`] = [...new Set([...(rv[`level${i}`] || []), obj.id])];
      }
    });

    if (autoFillParents || this.autoFillParentsOnAlways) {
      for (let j = this._minLevel; j < this._maxLevel + 1; j++) {
        let i = this._maxLevel + 1 - j;
        if (i > this._minLevel) {
          addParent(i - 1, rv[`level${i}`]);
        }
      }
    }

    if (false && this.verbose) _log(['parentSelectedTags2parentSelected'], rv, this.parentSelectedTags, this['tagIdLevel' + 1]);
    this.parentSelected = rv;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.tagListReady) {
      setTimeout(() => {
        this.ngOnChanges(changes);
      }, 16);
      return;
    }

    if (changes.rootTagId && changes.parentSelectedTags && this._initied) {
      if (false && _isDev()) _log('[ReChangeTag]', { rootTagId: this.rootTagId, parentSelectedTags: _cloneDeep(this.parentSelectedTags) });
      this._avoidEmitChanges = true;
    }

    if (changes.rootTagId) this.makeTagTree();

    this.checkLevelChanges(changes);

    //First populate
    let firstRun = this['tagIdLevel' + 1] == null;
    if (firstRun) this.populateCascadeCombos();

    //OnChangeTags: Convierte parentSelectedTags (formato usario) a parentSelected (formato interno)
    if (changes.parentSelectedTags && this.parentSelectedTags) {
      let _change = false;

      this.parentSelectedTags2parentSelected(firstRun && this.autoFillParentsOnStart);

      for (let i = this._minLevel; i < this._maxLevel + 1; i++) {
        if (
          this[`level${i}Config`] &&
          this[`level${i}Config`].visible &&
          this.parentSelected &&
          !_equal(this.parentSelected[`level${i}`], this[`tagLevel${i}`])
        ) {
          this[`tagLevel${i}`] = this.parentSelected[`level${i}`];

          if (true) this.checkMultipleValuesOnNoMultipleTag(i);

          _change = true;
        }
      }

      if (_change) {
        this.populateCascadeCombos();
        this.cd.detectChanges();
      }
    }

    // || !_equal(changes.configLevels?.previousValue, changes.configLevels?.currentValue)
    if (!_equal(changes.onFilterTagsValues?.currentValue, changes.onFilterTagsValues?.previousValue)) {
      if (this.onFilterTagsValues) {
        this.populateCascadeCombos('tagFlatFiltredLevel'); // seteo opciones con intersec
      } else {
        this.populateCascadeCombos();
        for (let i = this._minLevel; i < this._maxLevel + 1; i++) {
          const key_tagFlatLevel = `tagFlatFiltredLevel${i}`;
          this[key_tagFlatLevel] = null; // reseteo opciones para que se usen las por default en componente hijo
        }
      }
    }

    //PREC-221
    if (!this._initied && true) this.setDefaultValues();

    this._initied = true;
  }

  checkMultipleValuesOnNoMultipleTag(i) {
    //Checqueo de que no pueda haber más de dos valores para un tag no múltiple
    if (this[`level${i}Config`].multiple !== true && this[`tagLevel${i}`]?.length > 1) {
      console.log('\n');
      console.error('[Más de un valor para una etiqueta no múltiple]', {
        level: i,
        tagValues: _cloneDeep(this[`tagLevel${i}`]),
        config: _cloneDeep(this[`level${i}Config`]),
        rootTagId: _cloneDeep(this.rootTagId),
        placeholder: _cloneDeep(this.placeholder || this['tagFlatLevel' + i][0]?.tagName),
      });
      console.log('\n');

      this[`tagLevel${i}`] = this[`tagLevel${i}`].slice(0, 1);
    }
  }

  checkLevelChanges(changes?: SimpleChanges) {
    if (!changes) return;
    for (let i = this._minLevel; i < this._maxLevel + 1; i++) {
      if (changes[`level${i}Config`] || !this[`level${i}Config`]) {
        this[`level${i}Config`] = this.setLevelConfig(i);

        if (this[`level${i}Config`].intersect && this[`level${i}Config`].intersect.length) {
          const key_all_tags_values = `key_all_tags_values${i}`;
          this.populateAndFilterLevelByKey(i, key_all_tags_values && this[key_all_tags_values] ? this[key_all_tags_values] : null);
        }
      }
    }
  }

  populateCascadeCombos(key = 'tagFlatLevel') {
    if (!this.tagTree || !this.tagTree.length) return;

    /*Busca el primer combo (root) para cascadear, al popular el padre van populando los hijos*/
    for (let i = this._minLevel; i < this._maxLevel + 1; i++) {
      const key_levelConfig = `level${i}Config`;
      if (this[key_levelConfig] && this[key_levelConfig].visible) {
        this._minLevel = i;

        this[key_levelConfig].parentDependent = false; // root no puede ser parentDependent

        if (i === this.tagTree[0].level) {
          /*Si el root combo es igual al root de los tags no tiene padres*/
          this[key_levelConfig].group = false;
        }

        if (this.tagTree.length === 1 && i - 1 === this.tagTree[0].level) {
          /*Si el root solo tiene un padre*/
          this[key_levelConfig].group = false;
        }

        this.populateAndFilterLevelByKey(i, null, key);
        break; // Los siguientes se populan a partir del primero
      }
    }
  }

  populateAndFilterLevelByKey(keyId: number, parentFilter = null, key = 'tagFlatLevel') {
    const key_levelConfig = `level${keyId}Config`;
    const key_tagFlatLevel = `${key}${keyId}`;
    //const key_tagFlatLevel = `tagFlatLevel${keyId}`;
    const key_parentLevelConfig = `level${keyId - 1}Config`;
    const key_ParentLevel = `tagLevel${keyId - 1}`;

    const key_all_tags_values = `key_all_tags_values${keyId}`;

    if (!this[key_levelConfig] || !this[key_levelConfig].visible) return null;

    if (this[key_levelConfig].parentDependent && keyId > this._minLevel && (!this[key_ParentLevel] || !this[key_ParentLevel].length)) {
      return (this[key_tagFlatLevel] = []);
    }

    //Flag si hace falta cascadear con el padre
    const parentCascadeIsAvailable =
      this[key_levelConfig].cascade &&
      this[key_parentLevelConfig] &&
      this[key_parentLevelConfig].visible &&
      this[key_ParentLevel] &&
      Array.isArray(this[key_ParentLevel]);

    // Filtro por selección del padre
    const selectCascade = parentCascadeIsAvailable && this[key_ParentLevel].length ? this[key_ParentLevel] : null;

    // Filtro por propiedad (@input) intersect
    let intersect =
      this[key_levelConfig].intersect && this[key_levelConfig].intersect.length ? this[key_levelConfig].intersect.map(Number) : null;

    // values filtrados, son los q se van a mostrar
    this[key_tagFlatLevel] = _aux_flatternTagsTreeModelGen(
      this.tagTree,
      this[key_levelConfig].group && keyId + 1 > this._minLevel ? keyId - 1 : keyId,
      this[key_levelConfig].group,
      true,
      selectCascade,
      intersect,
      parentFilter
    );

    // Saca ids seleccionables para filtrar al hijo (se cachea para usar tmb fuera de esta función)
    this[key_all_tags_values] = parentCascadeIsAvailable
      ? _aux_flatternTagsTreeModelGen(this[key_tagFlatLevel], keyId, false, true).map(el => el.id)
      : null;

    // Si hay nivel hijo lo repopula con el filtro
    if (keyId < this._maxLevel) {
      this.populateAndFilterLevelByKey(keyId + 1, this[key_all_tags_values]);
    }

    //Asigna el .tagId para cada nivel (nuevo, solo de tags)
    if (this['tagIdLevel' + keyId] == null) {
      if (this['tagFlatLevel' + keyId] && this['tagFlatLevel' + keyId][0]) {
        this['tagIdLevel' + keyId] = this['tagFlatLevel' + keyId][0]?.tagId;
      }
    }
  }

  onChangeLevelKey(keyId: number, val: number | string) {
    if (this._avoidEmitChanges) {
      this._avoidEmitChanges = false;
      return;
    }

    const key_all_tags_values = `key_all_tags_values${keyId}`;

    const keyLevel = 'tagLevel' + keyId;

    if (!this[keyLevel]) return;

    if (true && this.verbose) _log(`[onChange${keyLevel}]`, val);

    this[keyLevel] = _cloneDeep(val);
    if (keyId < this._maxLevel) {
      // Fuerza el repopulado del select siguiente
      this.populateAndFilterLevelByKey(keyId + 1, key_all_tags_values && this[key_all_tags_values] ? this[key_all_tags_values] : null);
    }

    this.cd && !this.destroyed && this.cd.detectChanges();

    this.emitChanges();
  }

  async emitChanges() {
    let rv: TagFlatModelGenSelected = {};

    for (let i = this._minLevel; i < this._maxLevel + 1; i++) {
      if (this[`level${i}Config`] && this[`level${i}Config`].visible) {
        rv[`level${i}`] =
          this[`level${i}Config`].parentDependent && i > this._minLevel && !this['tagLevel' + (i - 1)]?.length ? [] : this[`tagLevel${i}`];
      }

      // ReFiltra los hijos (si no tienen la posibilidad de tener ese valor (x padre) se remueven)
      if (this.refilterChilds) {
        if (rv[`level${i}`]?.length) {
          rv[`level${i}`].forEach(v => {
            if (!this['tagFlatLevel' + i].map(t => t.id).includes(v)) {
              if (false && this.verbose) _log('REMOVE', v);
              rv[`level${i}`] = rv[`level${i}`].filter(c2 => c2 !== v);
            }
          });
        }
      }
    }

    if (_equal(rv, this.parentSelectedTags)) return;

    //En lugar de emitir el formato interno (this.parentSelected) emite el convertido (emitChangesTags)
    this.emitChangesTags(rv);
  }

  emitChangesTags(newVals) {
    let rv = [];
    for (let i = this._minLevel; i < this._maxLevel + 1; i++) {
      let obj = _aux_getTagItemObjFromId(newVals[`level${i}`], this.tagTree);
      if (obj) {
        obj.forEach($el => {
          if ($el?.id == null) return;

          let el = $el;
          if (el.value == null) {
            el = _cloneDeep($el);
            let val = _aux_getTagItemObjFromId([el.id], this.tagTree);
            if (val && val[0]) el.value = val[0].value;
          }

          rv.push({
            id: el.id,
            value: el.value || '',
          });
        });
      }
    }
    if (true && this.verbose) _log('[emitChangesTags]', { output: rv, internal: newVals });

    this.onChange.emit(rv);
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.$unsuscribreAll.next();
    this.$unsuscribreAll.complete();
  }

  ngAfterViewInit(): void {}

  aux_getTagRootFromId(id) {
    return _aux_getTagRootFromId(id, this.tagTree);
  }

  //PREC-221
  setDefaultValues(checkIfRequired = true) {
    for (let i = this._minLevel; i < this._maxLevel + 1; i++) {
      this.setDefaultValue(i, checkIfRequired);
    }
  }

  //PREC-221
  setDefaultValue(index, checkIfRequired = true) {
    if (this.autoSelectRequiredTagByDefaultTagValue !== true) return;
    if (this.tagListReady == null) return;

    let val = this.getSelected(index);

    if (!(val?.length > 0)) {
      let required = this.required || this['level' + index + 'Config']?.required;
      if (!required && checkIfRequired) return;

      let defaultValue = this.getDefaultValue(index);

      if (defaultValue?.id != null) {
        this.onChangeLevelKey(index, [defaultValue?.id] as any);
      }
    }
  }

  //PREC-221
  getDefaultValue(index) {
    let tagFlatLevel = this['tagFlatLevel' + index];
    if (!(tagFlatLevel?.length > 0)) return null;

    let rv = tagFlatLevel.find(el => el?.isDefaultValue);

    return rv;
  }
}
