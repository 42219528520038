import { defaultEnvironment } from './environment.defaults';

function getHostForBackend(path) {
  return `${location.protocol}//${location.host}${path}`;
}

defaultEnvironment.production = true;
defaultEnvironment.inMemoryData = false;
defaultEnvironment.isVerbose = false;
defaultEnvironment.isVerboseTaps = false;
defaultEnvironment.isVerboseDeSubscribe = false;
defaultEnvironment.useDummyData = false;
defaultEnvironment.oidc_configuration = getHostForBackend(`/api/config/get-authconfig?host=${getHostForBackend('')}`);
defaultEnvironment.firebase = {
  apiKey: 'AIzaSyBq4KkB74tuz0LFyojk8mMfVtrA2NHIjUQ',
  authDomain: 'prisma-firebase.firebaseapp.com',
  databaseURL: 'https://prisma-firebase.firebaseio.com',
  projectId: 'prisma-firebase',
  storageBucket: 'prisma-firebase.appspot.com',
  messagingSenderId: '467838294373',
  appId: '1:467838294373:web:72d41ff3fb24b5e1',
};
defaultEnvironment.local = false;

defaultEnvironment.frontendUrl = '/';
defaultEnvironment.identityUrl = '/';
defaultEnvironment.pricesUrl = '/';
defaultEnvironment.apiBaseUrl_prices = '/api/';
defaultEnvironment.apiBaseUrl_reports = '/api/';
defaultEnvironment.apiBaseUrl_assortment = '/api/';
defaultEnvironment.apiBaseUrl_identity = '/api/';
defaultEnvironment.apiBaseUrl_promotionV2 = '/api/promotion/';
defaultEnvironment.apiBaseUrl_notification = '/api/notification/';
defaultEnvironment.apiBaseUrl_spaces = '/api/';
defaultEnvironment.apiBaseUrl_interfaces = '/api/';

defaultEnvironment.prismaDynamicEnv.brandCustomization = 'api/config/get-brand-customization';

export const environment = defaultEnvironment;
