import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { kayshFlushOperator } from '@karacas/kaysh';
import {
  GenericListPayloadFE,
  _mapPayloadClipBoard,
} from '@prisma/components/general-clipboard-list-modal/general-clipboard-list-modal.component';
import { MAX_PAGE_SIZE } from '@prisma/models/defined-data';
import { _isDev, _log, _logTap, _useDummyData } from '@shared/aux_helper_environment';
import { _cloneDeep, _equal, _get, _handleErrorWithDummyData, _noVal, _objToHash, _throwError } from '@shared/aux_helper_functions';
import { GenericEntity } from 'core/models/generic-entity.model';
import { PagedList } from 'core/models/paged-list.model';
import { isObservable, Observable, of } from 'rxjs';
import { tap, catchError, map, shareReplay } from 'rxjs/operators';
import { StoreV2RowBEModel, StoreV2RowModel } from './stores/STOREV2-list.model';
import { StoreV2ListDummyData } from './STOREV2_list.dummyData';

const IS_DEMO = _useDummyData();
const getStoreV2ListFromBE_Map = (list: any): any => {
  return list;
};

export const hiddenFeatureDES1473 = true;
@Injectable({
  providedIn: 'root',
})
export class StoreV2_list_service {
  private configBase = _get(environment, 'apiBaseUrl_prices');
  private config = _get(environment, 'admin.masters.storesv2.api');

  constructor(private http: HttpClient) {}

  getList(filters): Observable<PagedList<StoreV2RowModel>> {
    const qs: Array<string> = ['?'];
    if (filters.pageIndex) {
      qs.push(`pageIndex=${filters.pageIndex}`);
    }

    if (filters.pageSize) {
      qs.push(`pageSize=${filters.pageSize}`);
    }

    if (IS_DEMO && false) {
      return _handleErrorWithDummyData(
        true && _useDummyData(),
        'error',
        StoreV2_list_service,
        getStoreV2ListFromBE_Map(StoreV2ListDummyData.StoreV2ListWithoutMap),
        'StoreV2ListDummyData.tagsList'
      );
    }

    const url = `${this.configBase}${this.config.resources.list}${qs.join('&')}`;

    return this.http.post<PagedList<StoreV2RowBEModel>>(url, filters).pipe(
      map(data => getStoreV2ListFromBE_Map(data)),
      tap(data => _logTap(`${StoreV2_list_service.name}::getList (tap)\n\tdata: %o`, data)),
      catchError(error =>
        _handleErrorWithDummyData(
          true && _useDummyData(),
          error,
          StoreV2_list_service,
          getStoreV2ListFromBE_Map(StoreV2ListDummyData.StoreV2ListWithoutMap),
          'StoreV2ListDummyData.tagsList'
        )
      )
    );
  }

  delete(data): Observable<any> {
    this.reset_cache_stores();

    if (!hiddenFeatureDES1473) {
      const url = this.configBase + this.config.resources.delete;
      return this.http.post<any>(url, data).pipe(
        tap(data => _logTap(`${StoreV2_list_service.name}::delete (tap)\n\tdata: %o`, data)),
        catchError(error => _throwError(error, StoreV2_list_service))
      );
    } else {
      const url = this.configBase + this.config.resources.feature + '/' + data;
      return this.http.delete<any>(url).pipe(
        tap(data => _logTap(`${StoreV2_list_service.name}::delete (tap)\n\tdata: %o`, data)),
        catchError(error => _throwError(error, StoreV2_list_service))
      );
    }
  }

  undelete(data): Observable<any> {
    const url = this.configBase + this.config.resources.undelete;

    this.reset_cache_stores();

    return this.http.post<any>(url, data).pipe(
      tap(data => _logTap(`${StoreV2_list_service.name}::undelete (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, StoreV2_list_service))
    );
  }

  clone(data): Observable<any> {
    const url = this.configBase + this.config.resources.clone;

    this.reset_cache_stores();

    return this.http.post<any>(url, data).pipe(
      tap(data => _logTap(`${StoreV2_list_service.name}::clone (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, StoreV2_list_service))
    );
  }

  getListForExport(filters): Observable<Array<any>> {
    const url = `${this.configBase}${this.config.resources.listForExport}`;

    return this.http.post<Array<StoreV2_list_service>>(url, filters).pipe(
      tap(data => _logTap(`${StoreV2_list_service.name}::getListForExport (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, StoreV2_list_service))
    );
  }

  cacheStoreList: any = {};
  getStoresIdByCode(payload: GenericListPayloadFE): Observable<PagedList<any>> {
    let filterHash = payload ? _objToHash(payload) : 'NULL';

    /*Si tiene cache lo devuelve y no va a BE*/
    if (this.cacheStoreList[filterHash]) {
      return isObservable(this.cacheStoreList[filterHash]) ? this.cacheStoreList[filterHash] : of(this.cacheStoreList[filterHash]);
    }

    const _maxSize = MAX_PAGE_SIZE;
    const _data = _mapPayloadClipBoard(payload);
    // remplazado getGenericStoreList por getGenericStoreListV2 para devolver nada si no tiene filtros
    const url = `${this.configBase}${this.config.resources.getGenericStoreListV2}?pageSize=${_maxSize}`;
    let rv = this.http.post<PagedList<any>>(url, _data).pipe(
      tap(data => _logTap(`${StoreV2_list_service.name}::getIdsByInternalCodeStore (tap)\n\tdata: %o`, data)),
      catchError(error => _handleErrorWithDummyData(true && _useDummyData(), error, StoreV2_list_service, [16, 17, 19], '[16, 17, 19]', []))
    );

    let cacheReplay = rv.pipe(shareReplay());

    this.cacheStoreList[filterHash] = cacheReplay;

    return cacheReplay;
  }

  resetStoresIdByCode() {
    this.cacheStoreList = {};
  }

  getSelectedVendors(id: number): Observable<Array<GenericEntity>> {
    const url = `${this.configBase}${this.config.resources.vendors}?storeId=${id}`;

    return this.http.get<any>(url).pipe(
      tap(data => _logTap(`${StoreV2_list_service.name}::getSelectedVendors (tap)\n\tdata: %o`, data)),
      catchError(error =>
        _handleErrorWithDummyData(true && _useDummyData(), error, StoreV2_list_service, null, 'storeFormDummyData.getSelectedVendors')
      )
    );
  }

  getStoreList(filters = { ids: [], externalCode: [], internalCode: [] }): Observable<Array<GenericEntity>> {
    /*Si tiene cache lo devuelve y no va a BE*/
    if (this.cacheStoreList) {
      return new Observable(observer => {
        observer.next(this.cacheStoreList);
        observer.complete();
      });
    }

    const url = `${this.configBase}${this.config.resources.getStoreList}`;

    if (true) _log('[getClusterings url]', url);

    return this.http.post<any>(url, filters).pipe(
      tap(data => _logTap(`${StoreV2_list_service.name}::getStoreList (tap)\n\tdata: %o`, data)),
      tap(data => {
        if (data) this.cacheStoreList = data;
      }),
      catchError(error =>
        _handleErrorWithDummyData(true && _useDummyData(), error, StoreV2_list_service, null, 'storeFormDummyData.getStoreList')
      )
    );
  }

  reset_cache_stores() {
    _log('[reset_cache_stores]');
    return kayshFlushOperator('getOptionsSelectedOnStoreParent');
  }
}
