<form
  *ngIf="ready"
  [formGroup]="form"
  novalidate
  fxLayout="column"
  class="content-form dinamic-tags-form"
  [ngClass]="{'_disableEvents' : _disabled || readOnly}"
>
  <!-- DINAMIC COMBOS SELECTED -->
  <div
    *ngFor="let tag of selectedTags; let i = index; trackBy: trackByRootTagId"
    fxLayout="row wrap"
    fxLayoutGap="32px"
    class="dinamic-tags-form-rowTags"
  >
    <!-- PRO-353 -->
    <ng-container>
      <div fxFlex="calc(100%+0px)" fxFlex.gt-sm="calc(50%-32px)" class="_isIncluded">
        <div class="selectContainer">
          <generic-tags-select-v2
            [tagsModuleType]="getModuleType()"
            [rootTagId]="tag?.id"
            [required]="required"
            [level1Config]="{ multiple: true, required: this.required, parentDependent: false, readOnly: this.disableTag(tag) || this.readOnly }"
            [disabled]="disableTag(tag) || _disabled || readOnly"
            [placeholder]="_getPlaceholder(tag, form)"
            [selected]="getSelectedValueTags(tag)"
            (onChange)="setSelectedValueTags(tag, $event)"
            [levels]="[1]"
          ></generic-tags-select-v2>
        </div>
      </div>
    </ng-container>

    <!-- PRO-353 -->
    <ng-container>
      <div fxFlex="calc(100%+0px)" fxFlex.gt-sm="calc(50%-32px)" class="_isExcluded">
        <div class="selectContainer">
          <generic-tags-select-v2
            [tagsModuleType]="getModuleType()"
            [rootTagId]="tag?.id"
            [required]="required"
            [level1Config]="{ multiple: true, required: this.required, parentDependent: false, readOnly: this.disableTag(tag, true) || this.readOnly }"
            [disabled]="disableTag(tag, true) || _disabled || readOnly"
            [placeholder]="_getPlaceholder(tag, form, true)"
            [selected]="getSelectedValueTags(tag, true)"
            (onChange)="setSelectedValueTags(tag, $event, true)"
            [levels]="[1]"
          ></generic-tags-select-v2>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- AGREGAR ATRIBUTOS -->
  <div fxLayout="row" class="mt-20 mb-20">
    <div fxFlex fxLayoutAlign="end start">
      <!-- //DES-4462 -->
      <span
        [matTooltip]="!isDisabledAddtags()
        ? ('COMP.GEN_TAGS.ADD_ATRIBUTE_BTN' | pTranslate)
        : ((isEdit || _disabled || readOnly ) ? null
                                              : ('COMP.GEN_TAGS.NO_TAGS' | pTranslate))"
      >
        <button
          mat-icon-button
          color="accent"
          (click)="openNewtagDialog({ selectAll: true, list: avaibleTags })"
          data-test-id="add_tags"
          [ngClass]="{'disableOnEdit' : isEdit || _disabled || readOnly}"
          [disabled]="isDisabledAddtags()"
        >
          <span class="material-icons font-size-30">add_circle_outline</span>
        </button>
      </span>
    </div>
  </div>
</form>

<div class="spinner-container" *ngIf="!isLoading()">
  <custom-loader></custom-loader>
</div>
