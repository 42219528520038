<ng-container *ngIf="existInsight">
  <button mat-button [matMenuTriggerFor]="menu" class="menuOptions suscriptions-btn">
    <mat-icon
      matTooltip="{{'COMP.INSIGHT.CONTEXT_MENU.UNSUBSCRIBED' | pTranslate}}"
      *ngIf="_alreadySubscripted !== true"
    >
      unsubscribe
    </mat-icon>
    <mat-icon matTooltip="{{'COMP.INSIGHT.CONTEXT_MENU.SUBSCRIBED' | pTranslate}}" *ngIf="_alreadySubscripted === true">
      mark_email_unread
    </mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      class="fullText"
      *ngIf="subscriptionType"
      (click)="changeSubscription(subscriptionType.params, subscriptionType.conditionalAction)"
    >
      {{subscriptionType.btn_description}}
    </button>
    <ng-container *ngIf="subscriptionEntities?.length > 0">
      <ng-container *ngFor="let entity of subscriptionEntities">
        <button mat-menu-item class="fullText" (click)="changeSubscription(entity.params, entity.conditionalAction)">
          {{entity.btn_description}}
        </button>
      </ng-container>
    </ng-container>
    <button
      mat-menu-item
      class="fullText"
      *ngIf="subscriptionInsight"
      (click)="changeSubscription(subscriptionInsight.params, subscriptionInsight.conditionalAction)"
    >
      {{subscriptionInsight.btn_description}}
    </button>
  </mat-menu>
</ng-container>
<div class="pl-16 pr-16" *ngIf="$isLoading">
  <custom-loader [size]="0.8"></custom-loader>
</div>
