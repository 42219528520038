import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { _log } from '@shared/aux_helper_environment';
import { _extendObjDepp, _timeout } from '@shared/aux_helper_functions';
import { DynamicChildLoaderDirective } from '../dynamicChildLoaderDirective/DynamicChildLoader.directive';

export interface DialogData {
  type: string;
  selectedItems: number;
}

@Component({
  selector: 'app-generic-confirmation-modal',
  templateUrl: './generic-confirmation-modal.component.html',
  styleUrls: ['./generic-confirmation-modal.component.scss'],
})
export class GenericConfirmationModalComponent implements OnInit {
  @ViewChild(DynamicChildLoaderDirective, { static: true }) dynamicchildloader: DynamicChildLoaderDirective;

  //Parámetros por default
  data = {
    component: null,
    dataComponent: null,
    message: '¿Desea continuar con la eliminación?',
    title: 'Confirmación',
    labelCancel: null,
    labelReport: null,
    labelConfirm: null,
    disableClose: true,
    disabledConfirm: false,
    timeToConfirm: 0,
    needForceDetectChangesByClick: false,
    isOnlyWarning: false,
    reconfirmationMsg: null,
    reconfirmationMsg2: null,
  };

  canConfirm = false;
  reConfirmationCheckbox = false;
  reConfirmationCheckbox2 = false;
  constructor(
    public dialogRef: MatDialogRef<GenericConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public $data: DialogData,
    private cdr: ChangeDetectorRef
  ) {
    this.data = _extendObjDepp(this.data, $data);
    this.dialogRef.disableClose = this.data.disableClose;

    this.loadDynamicComponent().then(() => {
      //Espera a ver si tiene que cargar contenido dinámico y recién ahí corre el hacl alternativo al cd.dedectChanges() // 2049
      if (this.data.needForceDetectChangesByClick) this.forceDetectChangesByClick();
    });
  }

  private async loadDynamicComponent() {
    if (this.data.component == null) return;

    await _timeout(1);

    if (this.dynamicchildloader == null) await _timeout(128);

    const componentRef = this.dynamicchildloader.viewContainerRef.createComponent(this.data.component);

    if ((this.data as any)?.dataToInject != null) {
      Object.keys((this.data as any).dataToInject).forEach(key => {
        (componentRef.instance as any)[key] = (this.data as any).dataToInject[key];
      });
    }
  }

  //Triggerea un Up keydown para forzar el detectChanges() que no se puede hacer por no estar en un componente
  private async forceDetectChangesByClick() {
    await _timeout(16);

    if (document?.querySelector == null) {
      console.error('No document.querySelector'); // 2049 LOG
      return;
    }

    let matDialogContainer: HTMLElement = document.querySelector('mat-dialog-container');

    if (matDialogContainer == null) {
      console.error('No mat-dialog-container'); // 2049 LOG
      return;
    }

    let body: HTMLElement = document.querySelector('body');

    if (body?.dispatchEvent != null) {
      let keydown = body.dispatchEvent(
        new KeyboardEvent('keydown', {
          key: 'Up',
        })
      );

      _log('Trigger Up keydown', keydown);

      return;
    }

    console.error('body not found'); // 2049 LOG
    return;
  }

  ngOnInit() {
    this.changeCanConfirm();
  }

  private async changeCanConfirm() {
    // para los casos en los que el modal necesite un checkbox (warning) de lectura obligatoria antes de guardar
    if (this.data.reconfirmationMsg && this.reConfirmationCheckbox) {
      if (!this.data.reconfirmationMsg2) {
        this.canConfirm = true;
      } else if (this.data.reconfirmationMsg2 && this.reConfirmationCheckbox2) {
        this.canConfirm = true;
      }
    } else if (this.data.reconfirmationMsg2 && this.reConfirmationCheckbox2) {
      if (!this.data.reconfirmationMsg) {
        this.canConfirm = true;
      } else if (this.data.reconfirmationMsg && this.reConfirmationCheckbox) {
        this.canConfirm = true;
      }
    } else {
      this.canConfirm = false;
    }
    //
    if (this.data.reconfirmationMsg || this.data.reconfirmationMsg2) return;

    let timeToConfirm = this.data.timeToConfirm || 0;
    if (this.data.needForceDetectChangesByClick) timeToConfirm += 256;

    await _timeout(timeToConfirm);

    this.canConfirm = true;
    if (this.cdr) this.cdr.detectChanges();
  }

  close(): void {
    this.data.labelReport === null ? this.dialogRef.close(false) : this.dialogRef.close('close');
  }

  _cancel(): void {
    this.data.labelReport === null ? this.dialogRef.close(false) : this.dialogRef.close('close');
  }

  _accept(): void {
    this.data.labelReport === null ? this.dialogRef.close(true && this.canConfirm) : this.dialogRef.close('accept');
  }

  _report(): void {
    this.dialogRef.close('report');
  }

  get isComponentRender() {
    return this.data && this.data.component != null;
  }
}
