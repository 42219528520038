import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { _log } from '@shared/aux_helper_environment';
import { PRISMA_TABLE_COL_SPAN, PRISMA_TABLE_MIN_CELL_WIDTH } from '../prisma-table-parent.component';
import { ColumnsType } from '../prisma-table.model';

interface PrismaTableFlexColumnSize {
  column: Array<ColumnsType>;
  columnHeader: string;
  minCellWidth?: number;
}

interface PrismaTableFlexColumnMinWidth {
  colSpan: number;
  minCellWidth: number;
}
/**
 * directiva para setear style Flex y Min-Width en base a calculos de la tabla
 */
@Directive({
  selector: '[PrismaTableFlexColumn]',
})
export class PrismaTableFlexColumn implements OnChanges {
  /** utilizado en columnas custom para encontrar datos y calcularlos
   * @param column es el objeto que configura las columnas en prisma table columnsType
   * @param columnHeader es el objeto que configura las columnas en prisma table columnsType
   * @param minCellWidth si tiene cellWidth global utilizado en prisma-table, no puede ser personalizado.
   */
  @Input() PrismaTableSize: PrismaTableFlexColumnSize = { column: null, columnHeader: null, minCellWidth: PRISMA_TABLE_MIN_CELL_WIDTH };
  /**
   * utilizado cuando ya tengo el dato colSpan
   * @param colSpan number cantida de espacios que ocupa la columna
   * @param minCellWidth si tiene cellWidth global utilizado en prisma-table, no puede ser personalizado.
   */
  @Input() PrismaTableMinWidth: PrismaTableFlexColumnMinWidth = {
    colSpan: PRISMA_TABLE_COL_SPAN,
    minCellWidth: PRISMA_TABLE_MIN_CELL_WIDTH,
  };
  /**
   * seteo en la columna el colSpan de cuantos espacios ocupa la columna
   */
  @Input() PrismaTableFlex: number = PRISMA_TABLE_COL_SPAN;

  flex = PRISMA_TABLE_COL_SPAN;
  minWidth: PrismaTableFlexColumnMinWidth = { colSpan: PRISMA_TABLE_COL_SPAN, minCellWidth: PRISMA_TABLE_MIN_CELL_WIDTH };
  size: PrismaTableFlexColumnMinWidth = { colSpan: PRISMA_TABLE_COL_SPAN, minCellWidth: PRISMA_TABLE_MIN_CELL_WIDTH };

  constructor(private el: ElementRef) {
    if (false) _log('[ElementRef]', el.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['PrismaTableSize']) {
      if (this.PrismaTableSize.column && this.PrismaTableSize.columnHeader) {
        this.size = {
          colSpan: this.getColSpanForCell(),
          minCellWidth: this.PrismaTableSize?.minCellWidth ? this.PrismaTableSize.minCellWidth : PRISMA_TABLE_MIN_CELL_WIDTH,
        };
        this.flex = this.size.colSpan;
        this.minWidth = this.size;
        this.setFlex();
        this.setWidthSize();
      }
    }
    if (changes['PrismaTableMinWidth']) {
      this.minWidth = this.PrismaTableMinWidth;
      this.minWidth.colSpan = this.minWidth?.colSpan ? this.minWidth.colSpan : PRISMA_TABLE_COL_SPAN;
      this.setWidthSize();
    }
    if (changes['PrismaTableFlex']) {
      this.flex = this.PrismaTableFlex;
      this.flex = this.flex ? this.flex : PRISMA_TABLE_COL_SPAN;
      this.setFlex();
    }
  }
  setWidthSize() {
    this.el.nativeElement.style.minWidth = this.minWidth.colSpan * this.minWidth.minCellWidth + 'px';
  }
  setFlex() {
    this.el.nativeElement.style.flex = this.flex;
  }

  getColumn($column) {
    let _columns = [];
    // obtengo un solo listado de columnas de los diferentes grupos
    _columns = $column.column
      .flatMap(group => group.type)
      .map(item => item)
      .concat();
    return _columns.find(col => col.column === $column.columnHeader);
  }

  getColSpanForCell(): number {
    const column = this.getColumn(this.PrismaTableSize);
    return column !== undefined && column?.colSpan ? column.colSpan : PRISMA_TABLE_COL_SPAN;
  }
}
