<h1 mat-dialog-title *ngIf="data.title">
  <ng-container *ngIf="data?.isOnlyWarning">
    <mat-icon *ngIf="data?.isOnlyWarning" class="warning-icon warning-color">warning</mat-icon>
  </ng-container>
  {{data.title}}
</h1>

<div mat-dialog-content>
  <ng-container *ngIf="data && !isComponentRender">
    <p [innerHTML]="data.message"></p>
    <mat-checkbox
      *ngIf="data.reconfirmationMsg"
      class="mt-24"
      [(ngModel)]="reConfirmationCheckbox"
      (change)="changeCanConfirm()"
    >
      <p class="color-warning" [innerHTML]="data.reconfirmationMsg"></p>
    </mat-checkbox>
    <mat-checkbox
      *ngIf="data.reconfirmationMsg2"
      class="mt-24"
      [(ngModel)]="reConfirmationCheckbox2"
      (change)="changeCanConfirm()"
    >
      <p class="color-warning" [innerHTML]="data.reconfirmationMsg2"></p>
    </mat-checkbox>
  </ng-container>

  <div [class._superHidden]="!isComponentRender">
    <div style="max-width: calc(100%); white-space: pre-wrap">
      <ng-template dynamicchildloader=""></ng-template>
    </div>
  </div>

  <br />
</div>

<div mat-dialog-actions fxLayoutAlign="end">
  <button
    [attr.data-test-id]="'cancel-generic-modal'"
    mat-button
    [mat-dialog-close]="true"
    (click)="_report()"
    *ngIf="data.labelReport"
  >
    {{data.labelReport}}
  </button>
  <button
    [attr.data-test-id]="'cancel-generic-modal'"
    mat-button
    [mat-dialog-close]="true"
    (click)="_cancel()"
    *ngIf="data.labelCancel"
  >
    {{data.labelCancel}}
  </button>
  <button
    [attr.data-test-id]="'confirm-generic-modal'"
    mat-button
    *ngIf="data.labelConfirm"
    [mat-dialog-close]="true"
    (click)="_accept()"
    color="accent"
    [disabled]="!canConfirm || data.disabledConfirm"
  >
    {{data.labelConfirm}}
  </button>
</div>
