import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterContentInit, ChangeDetectorRef, Component, ContentChildren, Input, OnChanges, QueryList, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatColumnDef, MatHeaderRowDef, MatRowDef } from '@angular/material/table';
import { transitions } from '@prisma/animations/animations';
import { __log, _log } from '@shared/aux_helper_environment';
import { Router } from '@angular/router';
import { _mem } from '@shared/aux_helper_functions';

@Component({
  selector: 'chips-prisma-table',
  template: `
    <mat-chip-list class="mt-16 mb-16 w-100-p">
      <div *ngFor="let chip of chips">
        <mat-chip
          *ngIf="!!chip?.value"
          [matTooltip]="chip?.tooltip || null"
          (click)="chip?.click(element); $event.preventDefault(); $event.stopPropagation()"
        >
          {{ chip.value }}
        </mat-chip>
      </div>
    </mat-chip-list>
  `,
  styles: [
    `
      .mat-standard-chip {
        min-height: 20px;
        font-size: 12px;
        margin: 8px;
      }
    `,
  ],
})
export class ChipsPrismaTable implements OnChanges {
  @Input() column: any;
  @Input() element: any;
  chips = [];
  constructor(private cdr: ChangeDetectorRef, private route: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['column']) {
      this.chips = this.column.chips(this.element);
    }
  }
}
