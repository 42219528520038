import { Component, OnInit } from '@angular/core';
import { transitions } from '@prisma/animations/animations';
import { _log } from '@shared/aux_helper_environment';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { VideosModalComponent } from '../../videos-modal/videos-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-help-price-rule',
  templateUrl: './help-price-rule.component.html',
  styleUrls: ['./help-price-rule.component.scss'],
  animations: [transitions],
})
export class HelpPriceRuleComponent implements OnInit {
  constructor(public dialog: MatDialog, private translate: IanTranslateService) {}

  ngOnInit() {}

  openVideo(url?) {
    if (url) _log(url);

    const dialogRef = this.dialog.open(VideosModalComponent, {
      panelClass: 'backgroung-video',
      width: '900px',
      data: { url },
    });
  }
}
