import { MatDialog } from '@angular/material/dialog';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { Action, Actions, ofActionCompleted, Selector, State, StateContext, Store } from '@ngxs/store';
import { OperationSuccessModalComponent } from '@prisma/components/operation-success-modal/operation-success-modal.component';
import { ErrorMessageService } from '@prisma/services/error-message.service';
import { _getEnv, _log, _warn } from '@shared/aux_helper_environment';
import { AppStateModel } from './app-state.model';
import {
  AppAddErrorAction,
  AppARemoveErrorAction,
  AppCloseAdvancedSearchAction,
  AppHideProgressBarAction,
  AppInSave,
  AppInSaveTimeOut,
  AppOpenAdvancedSearchAction,
  AppShowProgressBarAction,
  AppShowSuccessModal,
  AppTimeOutProgressBarAction,
} from './store.actions';
import { _get } from '@shared/aux_helper_functions';

export const FEATURE_ID = 'app';

import { Injectable } from '@angular/core';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
@Injectable({ providedIn: 'root' })
@State<AppStateModel>({
  name: FEATURE_ID,
  defaults: {
    showProgressBar: false,
    inAdvancedSearch: true,
    inSave: false,
    progressBarCounter: 0,
    errors: [],
  },
})
export class AppState {
  @Selector()
  static GetProgressBarStatus(state: AppStateModel): boolean {
    return state.showProgressBar;
  }

  @Selector()
  static IsAdvancedSearch(state: AppStateModel): boolean {
    return state.inAdvancedSearch;
  }

  @Selector()
  static isProgressBarShowing(state: AppStateModel): boolean {
    return state.progressBarCounter > 0;
  }

  @Selector()
  static getErrors(state: AppStateModel): Array<any> {
    return state.errors;
  }

  @Selector()
  static isInSave(state: AppStateModel): boolean {
    return !!state.inSave;
  }
  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly progressBarService: FuseProgressBarService,
    private readonly errorMessageService: ErrorMessageService,
    private translate: IanTranslateService,
    public dialog: MatDialog
  ) {
    this.actions$.pipe(ofActionCompleted(AppShowProgressBarAction)).subscribe(() => {
      const showProgressBar = this.store.selectSnapshot(AppState.isProgressBarShowing);
      if (showProgressBar) {
        this.progressBarService.show();
      }
    });

    this.actions$.pipe(ofActionCompleted(AppHideProgressBarAction)).subscribe(() => {
      const showProgressBar = this.store.selectSnapshot(AppState.isProgressBarShowing);
      if (!showProgressBar) {
        this.progressBarService.hide();
      }
    });

    this.actions$.pipe(ofActionCompleted(AppAddErrorAction)).subscribe(() => {
      const errors = this.store.selectSnapshot(AppState.getErrors);
      if (errors && errors.length) {
        if (true) _warn('ofActionCompleted', errors[0]);

        let forceMsg = errors && errors.length ? errors[0]?.statusText : null;

        if (errors[0]?.status === 403) {
          forceMsg = this.translate.instant('CUSTOM_ERRORS.UNAUTHORIZED_ACCESS'); //DES-2555
        }

        if (forceMsg?.length) {
          //Si es error manejado
          this.errorMessageService.show({ forceMsg: forceMsg });
          this.store.dispatch(new AppInSave(false));
          this.store.dispatch(new AppHideProgressBarAction());
        } else {
          this.errorMessageService.show(errors[0]);
        }
      }
    });
  }

  @Action(AppShowProgressBarAction)
  show(context: StateContext<AppStateModel>, action: AppShowProgressBarAction): void {
    const currentState = context.getState();
    const timeOut = _get(action, 'payload.timeout') || _getEnv('timeOutAppShowProgressBarAction') || 1000;

    context.patchState({
      progressBarCounter: currentState.progressBarCounter + 1,
    });

    // TimeOut AppShowProgressBarAction
    setTimeout(() => {
      this.store.dispatch(new AppTimeOutProgressBarAction());
    }, timeOut);
  }

  @Action(AppInSave)
  appInSave(context: StateContext<AppStateModel>, action: AppInSave): void {
    let timeOut = _getEnv('timeOutAppIsInSave') || 1000;

    if (action.timeOut != null) timeOut = action.timeOut;

    if (context.getState().inSave === action.payload) {
      return;
    }

    context.patchState({
      inSave: action.payload,
    });

    // TimeOut AppShowProgressBarAction
    setTimeout(() => {
      if (context.getState().inSave) {
        this.store.dispatch(new AppInSaveTimeOut());
      }
    }, timeOut);
  }

  @Action(AppTimeOutProgressBarAction)
  timeOut(context: StateContext<AppStateModel>, action: AppHideProgressBarAction): void {
    const currentState = context.getState();
    if (currentState.progressBarCounter > 0) {
      const newVal = Math.max(currentState.progressBarCounter - 1, 0);
      if (newVal === 0) {
        this.progressBarService.hide();
      }
      context.patchState({
        showProgressBar: newVal === 0 ? false : currentState.showProgressBar,
        progressBarCounter: newVal,
      });
    }
  }

  @Action(AppHideProgressBarAction)
  hide(context: StateContext<AppStateModel>, action: AppHideProgressBarAction): void {
    const currentState = context.getState();
    context.patchState({
      progressBarCounter: Math.max(currentState.progressBarCounter - 1, 0),
    });
  }

  @Action(AppCloseAdvancedSearchAction)
  closeAdvancedSearch(context: StateContext<AppStateModel>, action: AppCloseAdvancedSearchAction): void {
    context.patchState({
      inAdvancedSearch: false,
    });
  }

  @Action(AppOpenAdvancedSearchAction)
  openAdvancedSearch(context: StateContext<AppStateModel>, action: AppOpenAdvancedSearchAction): void {
    context.patchState({
      inAdvancedSearch: true,
    });
  }

  // Todo: agregar InProgress con selector. usar accion SetFormDisabled
  @Action(AppAddErrorAction)
  addError(context: StateContext<AppStateModel>, action: AppAddErrorAction): void {
    const curretnState = context.getState();
    const errors = curretnState.errors;
    context.patchState({
      errors: [...errors, action.payload],
    });
  }

  @Action(AppARemoveErrorAction)
  removeError(context: StateContext<AppStateModel>, action: AppARemoveErrorAction): void {
    // TODO: Ver la opción de borrar el primer mensaje.
    const curretnState = context.getState();
    const errors = curretnState.errors;
    const idx = errors.indexOf(action.payload);
    if (idx > -1) {
      const newErrors = [...errors.slice(0, idx), ...errors.slice(idx + 1)];
      context.patchState({
        errors: newErrors,
      });
    }
  }

  @Action(AppShowSuccessModal)
  showSuccessDialog(context: StateContext<AppStateModel>, action: AppShowSuccessModal): void {
    if (false) {
      const dialogRef = this.dialog.open(OperationSuccessModalComponent, {
        width: '480px',
      });
    } else {
      // TODO: No usar modales dentro de los Stores
    }
  }

  @Action(AppInSaveTimeOut)
  private appInSaveTimeOut(context: StateContext<AppStateModel>, action: AppInSaveTimeOut): void {
    const currentState = context.getState();

    context.patchState({
      inSave: false,
    });
  }
}
