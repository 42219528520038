import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  IanAddAttributeModalComponent,
  ModalDataAddAttribute,
} from '@prisma/components/ian-add-attribute-modal/ian-add-attribute-modal.component';
import { EntityEnumType } from '@prisma/containers/model/scope.models';
import { __log, _isFeDev, _log, _warn } from '@shared/aux_helper_environment';
import { _equal, _get, _sortArrayAlpha } from '@shared/aux_helper_functions';
import { AttributeItemModel } from 'core/models/attribute.model';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { first } from 'rxjs/operators';
import { DinamicTagsFormIncludedExcludedService } from '../dinamic-tags-form-included-excluded/dinamic-tags-form-included-excluded.service';
import { DinamicTagsComponent, dinamicTagsAttributeName } from '../dinamic-tags.component';
import { ItemTagsSelection, TagAttributeModel } from '../model/dinamicTagsModel';

export const stringExcluded = '-excluded';
export const _getBrandSelection = tagsAsObj => {
  let brandsIdsToInclude,
    brandsIdsToExclude = [];
  if (tagsAsObj['6000000_Banderas'] && tagsAsObj['6000000_Banderas'].length > 0) {
    brandsIdsToInclude = tagsAsObj['6000000_Banderas'];
    delete tagsAsObj['6000000_Banderas'];
  }
  if (tagsAsObj['6000000_Banderas-excluded'] && tagsAsObj['6000000_Banderas-excluded'].length > 0) {
    brandsIdsToExclude = tagsAsObj['6000000_Banderas-excluded'];
    delete tagsAsObj['6000000_Banderas-excluded'];
  }

  return [brandsIdsToInclude, brandsIdsToExclude, tagsAsObj];
};
export const _map_form_to_IncludedExcludedComponent_selectedTags = (value): ItemTagsSelection[] => {
  let rv = [];

  Object.entries(value || {}).forEach(([key, value]) => {
    if (!key || !key.length) return;

    const tagId = Number(key.split('_')[0]);
    if (!tagId) return;

    let tag = key.split('_')[1];
    if (!tag) return;
    tag = tag.replace(stringExcluded, '');

    const isExcluded = key.indexOf(stringExcluded) !== -1;
    const includedValues = !isExcluded ? value : null;
    const excludedValues = isExcluded ? value : null;

    if (includedValues === null && excludedValues === null) return;

    let hasValue = (rv || []).find(el => el.tagId === tagId);

    if (hasValue) {
      if (includedValues) hasValue.includedValues = includedValues;
      if (excludedValues) hasValue.excludedValues = excludedValues;
    } else {
      rv.push({
        tagId,
        tag,
        includedValues: includedValues || [],
        excludedValues: excludedValues || [],
      });
    }
  });

  if (!true) _log('map selectedTags', { value, rv });

  return rv;
};

export const _map_tag_to_IncludedExcludedComponent_selectedTags = (tags, isExcluded = false): ItemTagsSelection[] => {
  if (tags && tags.length === 0) return [];
  return tags.map(tag => {
    return {
      tagId: tag.tagId,
      tag: tag.tagId,
      includedValues: !isExcluded ? tag.tagValues || [] : [],
      excludedValues: isExcluded ? tag.tagValues || [] : [],
    };
  });
};

export const _aux_filterTagsByIntersects = (tags: TagAttributeModel[], intersectTags, $intersectTagsValues): TagAttributeModel[] => {
  if (!tags || !tags.length) return [];

  let intersectTagsValues = $intersectTagsValues.flat();

  if ((!intersectTags || !intersectTags.length) && (!intersectTagsValues || !intersectTagsValues.length)) return [];

  let rv = [];

  tags.forEach(tag => {
    if (intersectTags && intersectTags.length && !intersectTags.includes(tag.id)) return;

    let values =
      intersectTagsValues && intersectTagsValues.length ? tag.values.filter(val => intersectTagsValues.includes(val.id)) : tag.values;

    if (values && values.length) rv.push({ ...tag, values: values });
  });

  return rv;
};

export interface SelectedValueTagsModel {
  [key: string]: { id: number; value: string }[] | any[];
}

export interface ObjForm {
  value: {
    [key: string]: (number[] | null)[] | null;
  };
}

//PRO-353
@Component({
  selector: 'dinamic-tags-form-included-excluded-V2',
  templateUrl: './dinamic-tags-form-included-excluded-V2.component.html',
  styleUrls: ['./dinamic-tags-form-included-excluded-V2.component.scss'],
})
export class DinamicTagsFormIncludedExcludedV2Component extends DinamicTagsComponent implements OnInit {
  @Input('typeTags') typeTags: EntityEnumType;

  // deshabilita los combos si es true
  @Input('isEdit') isEdit = false;
  @Input() onlyParentTags = false;
  // deshabilita los botones accionables de este componente
  @Input('disabled') _disabled = false;
  // deshabilita los botones accionables de este componente
  @Input('readOnly') readOnly = false;
  // marca como requerido los campos desplegados
  @Input() required = false;
  @Output('getDinamicForm') dinamicForm: EventEmitter<UntypedFormGroup> = new EventEmitter();
  // Propiedades para refiltrar (por intersección) toda la lista de tags
  @Input('intersectTags') intersectTags: number[] = null; // filtra tagIds
  @Input('intersectTagsValues') intersectTagsValues: number[] = null; // filtra tagValuesIds

  tagsList: TagAttributeModel[] = null;
  ready = false;

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public fBuilder: UntypedFormBuilder,
    public translate: IanTranslateService,
    public service: DinamicTagsFormIncludedExcludedService,
    public cd: ChangeDetectorRef
  ) {
    super(dialog, router, fBuilder, translate);
  }

  ngOnInit(): void {
    if (!this.typeTags) {
      _warn(`\n\nNo se pasó propiedad con typeTags, las opciones son 'items' o 'stores' o 'competitors'`);
    }

    if (this.tagsList) {
      this.tagsList = null;
      _warn(`\n\nEste componente no necesita tagsList, trae todos los tags automáticamente, si pasarle typeTags`);
    }

    if (this.typeTags === 'items') {
      this.service
        .getAllTagsItems()
        .pipe(first())
        .subscribe(data => {
          this.fillTagList(data);
        });
    }

    if (this.typeTags === 'stores') {
      this.service
        .getAllTagsStores()
        .pipe(first())
        .subscribe(data => {
          this.fillTagList(data);
        });
    }

    if (this.typeTags === 'competitors') {
      this.service
        .getAllTagsCompetitors()
        .pipe(first())
        .subscribe(data => {
          this.fillTagList(data);
        });
    }
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  //DES-4462
  __tagsListIsLoaded = false;
  isLoading() {
    return this.tagsList != null && this.__tagsListIsLoaded;
  }

  fillTagList(data) {
    this.tagsList = data || [];

    if (this.onlyParentTags) this.tagsList = this.tagsList.filter(t => t && t.parentId == null);

    // caracter no permitido _
    this.tagsList = this.tagsList.map(t => {
      return { ...t, name: t.name.replace('_', '') };
    });

    if (this.intersectTags || this.intersectTagsValues) {
      //Si alguna de estas propiedades existen, filtra los tags interectando con las mismas
      this.tagsList = _aux_filterTagsByIntersects(this.tagsList, this.intersectTags, this.intersectTagsValues);
    }

    //Orden alfabético
    if (true) this.tagsList.forEach(obj => _sortArrayAlpha(obj.values, 'value'));

    this.__tagsListIsLoaded = true;

    this.onReadyTagsList();

    this.cd.detectChanges();
  }

  onReadyTagsList() {
    if (this.isLoading()) {
      if (this.activeTags?.length > 0) {
        this.initWithPreselected();
      } else {
        this.initTags();
      }

      this.initSelectedValueTags();

      this.ready = true;

      _log(['onReadyTagsList']);
    }
  }

  //#region Atributos dinamico
  openNewtagDialog(nonRequiredAttributes: ModalDataAddAttribute): void {
    const dialogRef = this.dialog.open(IanAddAttributeModalComponent, {
      width: '550px',
      data: nonRequiredAttributes,
    });

    this.subscribers.dialog$ = dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(data => {
        if (data === 'add' && dialogRef.componentInstance.selection) {
          dialogRef.componentInstance.selection.forEach(attribute => {
            this.DinamicTagsAddUserRequiredAttribute(attribute);
            this.addUserAttribute(attribute);
          });
        }
      });
  }

  /**
   * addUserAttribute  Agrega atributos al formulario
   * @param {AttributeItemModel} item
   * @returns {void}
   */
  private addUserAttribute(item: AttributeItemModel, isEdit: boolean = false): void {
    const formObject = this.form;
    const _item = { id: item.id, name: item.name };

    formObject.addControl(this.getFormControlName(_item), new UntypedFormControl([]));

    formObject.addControl(this.getFormControlName(_item, true), new UntypedFormControl([]));
  }

  /**
   * addUserAttribute  Agrega atributos al formulario
   * @param {AttributeItemModel} item
   * @returns {void}
   */
  private addUserAttributeWithValue(item: ItemTagsSelection): void {
    // item: ItemTagsSelection deberia ser asi cuando es un tag seleccionado en edit
    const formObject = this.form;
    const _item = { id: item.tagId || (item as any).id, name: item.tag || (item as any).name };
    const tag = this.preSelectedTags.find(x => x.tagId === _item.id) as any;

    formObject.addControl(this.getFormControlName(_item), new UntypedFormControl(tag.includedValues));

    formObject.addControl(this.getFormControlName(_item, true), new UntypedFormControl(tag.excludedValues));
  }

  /**
   * getFormControlName Setea el nombre del control de form acorde al atributo.
   * @param id
   */
  getFormControlName(attribute: any, isExcluded = false): string {
    const excludedString = isExcluded ? stringExcluded : '';

    const rv = `${attribute.id}_${attribute.name}${excludedString}`;

    return rv;
  }

  _getPlaceholder(attribute: AttributeItemModel, form: UntypedFormGroup, excluded = false): string {
    const controlnameAux = this.getFormControlName(attribute);
    const controlname = `${controlnameAux}${excluded ? stringExcluded : ''}`;
    const name = dinamicTagsAttributeName(attribute, this.translate);

    return `${name} ${excluded ? this.translate.instant('COMP.GEN_TAGS.EXCLUDED') : ''}`;
  }

  //Valores para this.selectedTags ()
  selectedValueTags: SelectedValueTagsModel = {};

  trackByRootTagId = rTag => rTag.rootTagId;

  getSelectedValues(values, excluded = false) {
    let rv = {};
    values.forEach(activeTag => {
      this.tagsList.forEach(tagItem => {
        let values = tagItem?.values || [];
        values.forEach(value => {
          if (value.id === activeTag) {
            let tagName = this.getTagKeyName(tagItem, excluded);
            rv[tagName] = { id: value.id };
          }
        });
      });
    });
    return rv;
  }

  getTagNameById(inputs, valueType, excluded = false) {
    const rv = {};

    inputs.forEach(item => {
      const tag = this.tagsList.find(t => t.id === item.tagId);
      if (tag) {
        const values = item[valueType];
        values.forEach(value => {
          let tagName = this.getTagKeyName(tag, excluded);
          if (!rv[tagName]) {
            rv[tagName] = [];
          }
          rv[tagName].push({ id: value });
        });
      }
    });

    return rv;
  }

  initSelectedValueTags() {
    let rv: SelectedValueTagsModel = {};

    let included = this.getTagNameById(this.activeTags, 'includedValues');
    let excluded = this.getTagNameById(this.activeTags, 'excludedValues', true);

    rv = { ...included, ...excluded };

    this.selectedValueTags = rv;
  }

  disableTag(tag, isExcluded = false) {
    const tagKeyName = this.getTagKeyName(tag, !isExcluded);
    if (isExcluded && this.selectedValueTags[tagKeyName]?.length > 0) {
      return true;
    } else if (!isExcluded && this.selectedValueTags[tagKeyName]?.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getTagKeyName(tag: any, isExcluded = false): string {
    if (tag == null) return undefined;

    const excludedString = isExcluded ? '-excluded' : '';

    const rv = `${tag.id}_${tag.name}${excludedString}`;

    return rv;
  }

  getSelectedValueTags(tag, isExcluded = false) {
    if (tag == null) return undefined;

    const tagKeyName = this.getTagKeyName(tag, isExcluded);

    const selected = this.selectedValueTags[tagKeyName];

    return selected;
  }

  setSelectedValueTags(tag, value, isExcluded = false) {
    if (tag == null) return undefined;

    let canEmit = true;

    const tagKeyName = this.getTagKeyName(tag, isExcluded);

    if (_equal(this.selectedValueTags[tagKeyName], value)) return;

    //PRO-353 Chequeo estricto de igualdad
    if (
      value?.length > 0 &&
      this.selectedValueTags[tagKeyName]?.length === value?.length &&
      _equal([...this.selectedValueTags[tagKeyName].map(el => el?.id)].sort(), [...value.map(el => el?.id)].sort())
    ) {
      return;
    }

    if (!(Object.keys(this.selectedValueTags)?.length > 0)) canEmit = false;

    //PRO-353 Chequeo de nuevo valor es [] y el actual es undefined no emite
    if (this.selectedValueTags[tagKeyName] == undefined && value?.length === 0) canEmit = false;

    if (canEmit && true && _isFeDev()) {
      _log('[setSelectedValueTags]', {
        selectedValueTags: this.selectedValueTags,
        tagKeyName,
        selectedValueTag: this.selectedValueTags[tagKeyName],
        selectedValueTagNewVal: value,
      });
    }

    this.selectedValueTags[tagKeyName] = value;

    if (canEmit) this.emitSelectedValueTags();
  }

  emitSelectedValueTags() {
    let values = {};
    Object.keys(this.selectedValueTags).forEach(key => {
      values[key] = this.selectedValueTags[key].map(el => el?.id).filter(e => e != null);
    });

    let rvObj: ObjForm = {
      value: {
        ...values,
        query: this.form.controls['query'].value,
      },
    };

    this.dinamicForm.emit(rvObj as any);
  }

  getModuleType() {
    if (this.typeTags === EntityEnumType.items) return 1;
    if (this.typeTags === EntityEnumType.stores) return 2;
    return 1;
  }

  getValuetagForm(form: UntypedFormGroup, attr: AttributeItemModel, isExcluded = false) {
    if (!form || !attr) return undefined;

    const selected = form.get(this.getFormControlName(attr, isExcluded))?.value?.map(el => ({ id: el }));

    return selected;
  }
}
