import { Pipe, PipeTransform } from '@angular/core';
import { __log } from '@shared/aux_helper_environment';

import { _scoreBetweenStringsWithSpaces, _containNormaliceStrings, _filterLookupMultipleProps } from '@shared/aux_helper_functions';
import { getDateFormat } from 'app/app.config';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';

// [PIPES] id:G9CARoR-PR
@Pipe({
  name: '_auxfilterlookUp',
})
export class AuxFilterLookUp implements PipeTransform {
  transform($valueArr: any, search?: any, objValueKey?: any, fast?: boolean): any {
    if (!$valueArr) {
      return null;
    }
    if (!search || !objValueKey) {
      return $valueArr;
    }

    return $valueArr.filter($val => {
      const val = $val[objValueKey];
      if (!val) {
        return false;
      }

      if (fast === true) {
        return _containNormaliceStrings(val, search);
      }

      return _scoreBetweenStringsWithSpaces(val, search) > 0;
    });
  }
}

@Pipe({
  name: '_auxfilterlookUpMultipleProps',
})
export class AuxFilterLookUpMultiProps implements PipeTransform {
  transform($valueArr: any, search?: any, objValueKey?: string[], fast?: boolean): any {
    return _filterLookupMultipleProps($valueArr, search, objValueKey, fast);
  }
}

@Pipe({
  name: '_auxfilterlookUpAndSort',
})
export class AuxFilterLookUpAndSort implements PipeTransform {
  transform($valueArr: any, search?: any, objValueKey?: any): any {
    if (!$valueArr) {
      return null;
    }
    if (!search || !objValueKey) {
      return $valueArr;
    }

    //Genera un mapa valor / score
    let mapOder = new Map();
    $valueArr.forEach($val => {
      const val = $val[objValueKey];
      if (!val) {
        return 0;
      }

      mapOder.set(val + '', _scoreBetweenStringsWithSpaces(val, search));
    });

    //Filtra y ordena score
    $valueArr = $valueArr
      .slice()
      .filter($val => {
        const val = $val[objValueKey];
        if (!val) {
          return false;
        }
        return mapOder.get(val + '') > 0;
      })
      .sort((a, b) => {
        let valA = mapOder.get(a[objValueKey] + '');
        let valB = mapOder.get(b[objValueKey] + '');
        return valB - valA;
      });

    return $valueArr;
  }
}

/*
    Filtra las categorias padre (solo las padre) segun las hijas
*/
@Pipe({
  name: '_auxFilterLookUptWithSubCat',
})
export class AuxFilterLookUpWithSubCat implements PipeTransform {
  transform($parentGroupArr: any, searchTxt?: any, valueTxtKey?: any, childGroupArrKey?: any): any {
    if (!$parentGroupArr) {
      return null;
    }
    if (!searchTxt || !valueTxtKey || !childGroupArrKey) {
      return $parentGroupArr;
    }

    return $parentGroupArr.slice().filter($val => {
      const val = $val[valueTxtKey];
      if (!val) return false;

      const group = $val[childGroupArrKey];
      if (!group) return false;

      return group.some(v => v && v.value && _containNormaliceStrings(v.value, searchTxt));
    });
  }
}

@Pipe({
  name: '_paginate',
})
export class PaginatePipe implements PipeTransform {
  transform(array: Array<any>, pageSize: number, pageNumber: number): Array<any> {
    if (!array.length) {
      return [];
    }

    pageSize = pageSize || 10;
    pageNumber = pageNumber || 1;

    pageNumber -= 1;

    return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
  }
}

/*Prisma Translate aux id:FwkB6slaKt */
@Pipe({
  name: 'pTranslate',
})
export class AuxPrismaTranslate implements PipeTransform {
  constructor(private translate: IanTranslateService) {}

  transform($str: string, vals: string | number | (string | number)[] = null, fallback: string = null): string {
    let rv = this.translate.instant($str, vals, fallback);
    return rv;
  }
}

@Pipe({
  name: '_tr',
})
export class AuxPrismaTranslateB extends AuxPrismaTranslate implements PipeTransform {}

@Pipe({
  name: 'pTranslateEnum',
})
export class AuxPrismaTranslateEnum implements PipeTransform {
  constructor(private translate: IanTranslateService) {}

  transform($str: string, prefix: string, vals: string | number | (string | number)[] = null, fallback: string = null): string {
    let key = String(prefix) + '.' + String($str);
    let rv = this.translate.instant(key, vals, fallback);
    return rv;
  }
}

@Pipe({ name: 'trim' })
export class TrimPipe implements PipeTransform {
  transform(inValue: any): string {
    let value = '';
    try {
      value = inValue.toString();
      return value.replace(/\s+/g, '');
    } catch (err) {
      console.log(err);
      return value;
    }
  }
}

@Pipe({ name: 'maxLenghtText' })
export class MaxLenghtTextPipe implements PipeTransform {
  transform(value: any, maxLength: number = 255): string {
    let $value = '';
    try {
      $value = value.toString();
      $value = $value.length > maxLength ? `${$value.slice(0, maxLength)}...` : $value;
      return $value;
    } catch (err) {
      console.log(err);
      return value;
    }
  }
}

@Pipe({
  name: 'dateLenguage',
})
export class DateLenguaguePipe implements PipeTransform {
  transform(value: any): any {
    let $value = value;
    if (typeof $value === 'string') $value = new Date($value);
    if (!$value || !($value instanceof Date)) return;
    return $value.toLocaleDateString(dateFormat);
  }
}

@Pipe({
  name: 'dateTimeLenguage',
})
export class DateTimeLenguaguePipe implements PipeTransform {
  transform(value: any): any {
    let $value = value;
    if (typeof $value === 'string') $value = new Date($value);
    if (!$value || !($value instanceof Date)) return;
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    } as any;
    return $value.toLocaleDateString(dateFormat, options);
  }
}
const dateFormat = getDateFormat();

export const dateLenguague = value => {
  let $value = value;
  if (typeof $value === 'string') $value = new Date($value);
  if (!$value || !($value instanceof Date)) return;
  return $value.toLocaleDateString(dateFormat);
};
