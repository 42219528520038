import { environment } from '@env';
import { NotificationModel } from './notifications-panel.model';

export const FEATURE_KEY = '[NotificationsState]';
export const FEATURE_ID = 'NotificationsState';

export const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';
export const _keyLabelLS = _keyVer + 'oldNotificationsIds';

// INIT STATE
export const tmpNotifications = [
  {
    id: 3,
    title: 'Nueva Notificación 3 (test)',
    detail: 'Lorem ipsum notification 3',
    largeDetail:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    date: new Date(new Date().valueOf() - 120000000),
    // href: '/#/prices/setup/competitors/list',
    // hrefBlank: true,
    observations: '',
    _readed: true,
  },
  {
    id: 2,
    title: 'Nueva Notificación 2 (test)',
    detail: 'Lorem ipsum notification 3',
    largeDetail: 'Sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    date: new Date(new Date().valueOf() - 2000000),
    // href: '/#/prices/setup/competitors/list',
    // hrefBlank: false,
    observations: '',
    _readed: false,
  },
  {
    id: 1,
    title: 'Nueva Notificación 1 (test)',
    detail: 'Lorem ipsum notification 3',
    largeDetail: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    date: new Date(new Date().valueOf() - 1000000),
    // href: '/#/prices/setup/competitors/list',
    // hrefBlank: false,
    observations: '',
    _readed: false,
  },
];

// AUXS
export const _getPermanentReadys = (): Array<number> => {
  // READ LS
  const permanentReadys = localStorage.getItem(_keyLabelLS);

  const _permanentReadys = permanentReadys && permanentReadys.length ? JSON.parse(permanentReadys) : [];

  _permanentReadys - _permanentReadys.map(Number);

  return _permanentReadys;
};

export const _checkIsReaded = (items: NotificationModel): NotificationModel => {
  const permanentReadys = _getPermanentReadys();
  items = items.map(obj => {
    if (permanentReadys.includes(obj.id)) {
      obj._readed = true;
    }

    return obj;
  });

  return items;
};

export const _forceMarkIsReaded = (items: NotificationModel): NotificationModel => {
  items = items.map(obj => {
    obj._readed = true;
    return obj;
  });

  return items;
};

export const _filterNoReaded = (items: NotificationModel): NotificationModel => _checkIsReaded(items || []).filter(obj => !obj._readed);
