import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NGXS_PLUGINS } from '@ngxs/store';
import { FormDirective } from './directive';
import { NgxsFormPlugin } from './form.plugin';

@NgModule({
  imports: [ReactiveFormsModule],
  declarations: [FormDirective],
  exports: [FormDirective],
})
export class NgxsFormPluginModule {
  static forRoot(): ModuleWithProviders<NgxsFormPluginModule> {
    return {
      ngModule: NgxsFormPluginModule,
      providers: [
        {
          provide: NGXS_PLUGINS,
          useClass: NgxsFormPlugin,
          multi: true,
        },
      ],
    };
  }
}
