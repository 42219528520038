import { SharedModule } from 'app/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpPriceRuleComponent } from '../prices/help-price-rule/help-price-rule.component';
import { HelpRoundingRuleComponent } from '../prices/help-rounding-rule/help-rounding-rule.component';
import { HelpGuidelinesAndStrategiesComponent } from '../prices/help-guidelines-and-strategies/help-guidelines-and-strategies.component';
import { HelpPriceDecisionsComponent } from '../prices/help-price-decisions/help-price-decisions.component';

@NgModule({
  declarations: [HelpPriceRuleComponent, HelpRoundingRuleComponent, HelpGuidelinesAndStrategiesComponent, HelpPriceDecisionsComponent],
  imports: [CommonModule, SharedModule],
  exports: [
    HelpPriceRuleComponent,
    HelpRoundingRuleComponent,
    HelpGuidelinesAndStrategiesComponent,
    HelpPriceDecisionsComponent,
    SharedModule,
  ],
})
export class HelpMainContentModule {}
