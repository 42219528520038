import { _isFeDev, _log } from '@shared/aux_helper_environment';
import { _orderBy, _timeout, _cloneDeep, _toNumber } from '@shared/aux_helper_functions';
import { AddProdToShelf } from 'app/spaces/planograms-editor/store/planogram.store.state';
import {
  _getTmpProdId,
  DEFAULT_OFFSET_MIN_PRD_SEP_X,
  DEFAULT_FINGER_SPACE,
  _round,
  _calcRotateTransforms,
} from 'app/spaces/static_common/spaces_aux_helpers';

export const simplePegShelfDecorator = _this => {
  _this.addElement = async (data, $position) => {
    let prodId = data.id;
    const newId = data && data.isList ? _getTmpProdId() : prodId;

    //Si es un item ya insertado
    let prodInShelf = _this.baseObj.findChildById(prodId);

    //Si es un item ya insertado copia la rotacion
    let rotation = prodInShelf ? prodInShelf.rotation : 0;
    let limitLoadSize = prodInShelf ? prodInShelf.limitLoadSize : null;
    let rotateY = prodInShelf ? !!prodInShelf.rotateY : false;

    if (true) _log('\n\n[addElement (peg)]', { data, $position, prodId, prodInShelf });

    //Ajuste de nueva posicion
    let position = { x: $position.x - data.size.w / 2, y: $position.y - data.size.h / 2 };
    let margin = (_this.marginLeft || 0) + (_this.marginRight || 0);
    position = _this._fitPosInBox(position, data.size, { x: _this.marginLeft || 0, y: 0 }, { w: _this.size.w - margin, h: _this.size.h });
    const newProd = { ...data, position, rotation, rotateY, limitLoadSize, id: newId };

    //Posición inválida > Restea la posición del drag y aborta
    if (!_this.isValidPos(newProd.position, newProd.size, newProd.id)) {
      let prodObj = _this.baseObj.findChildById(prodId);
      if (prodObj) prodObj.resetPosition();
      return;
    }

    if (!_this.isValidPromoItem(data, true)) {
      _this.baseObj.genericAlert({
        message: 'El item no se encuentra alcanzado por ninguna de las acciones promocionales asociadas al estante.',
        title: 'Aviso',
        isOnlyWarning: true,
      });

      let prodObj = _this.baseObj.findChildById(prodId);
      if (prodObj) prodObj.resetPosition();
      return;
    }

    //Add
    await _this.store.dispatch(new AddProdToShelf(newProd, _this.id, prodId));

    //Selecciona
    _this.baseObj.deselectLast();
    await _timeout(0);
    _this.baseObj.selectObjBaseById(newId, _this.getThisScreenOffset());
  };

  _this.showGuides = (data, $position) => {
    if (!data || !$position) return;

    let position = { x: $position.x - data.size.w / 2, y: $position.y - data.size.h / 2 };
    let margin = (_this.marginLeft || 0) + (_this.marginRight || 0);
    position = _this._fitPosInBox(position, data.size, { x: _this.marginLeft || 0, y: 0 }, { w: _this.size.w - margin, h: _this.size.h });

    _this._shelfGuideWarning = !_this.isValidPos(position, data.size, data.id);

    if (!_this._shelfGuideWarning) {
      _this._shelfGuideWarning = !_this.isValidPromoItem(data, false);
    }

    _this._shelfGuide.position = { x: position.x * _this.scale, y: position.y * _this.scale };
    _this._shelfGuide.size = { w: data.size.w * _this.scale, h: data.size.h * _this.scale };
  };

  _this.isValidPos = (position, $size, id) => {
    let sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;
    let sepY = DEFAULT_OFFSET_MIN_PRD_SEP_X;
    let size = { w: $size.w + sepX, h: $size.h + sepY };

    let margin = (_this.marginLeft || 0) + (_this.marginRight || 0);

    if (size.w > _this.size.w - margin) return false;
    if (size.h + DEFAULT_FINGER_SPACE > _this.size.h) return false;

    return true;
  };

  _this.isNewWidthValid = (newW: number) => {
    if (!_this.prods || !_this.prods.length || newW > (_this as any).objDataShelf.width) return true;

    let rv = true;
    let sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;

    _this.prods.forEach(prod => {
      if (prod.position.x + prod.size.w + sepX > newW) rv = false;
    });

    return rv;
  };

  //DES-1576
  _this.isNewMarginValid = ($newValue: number, LEFT: boolean = true) => {
    if (!_this.prods || !_this.prods.length) return true;

    let otherMargin = LEFT ? _this.marginRight || 0 : _this.marginLeft || 0;
    if ($newValue >= _this.size.w - otherMargin) return false;

    let rv = true;
    let sepX = DEFAULT_OFFSET_MIN_PRD_SEP_X;

    _this.prods.forEach(prod => {
      if (LEFT) {
        if (prod.position.x < $newValue) rv = false;
      } else {
        let prodPos = prod.position.x + prod.size.w + sepX;
        if (prodPos > _this.size.w - $newValue) rv = false;
      }
    });

    return rv;
  };

  _this.isNewHeightValid = (newH: number) => {
    if (!_this.prods || !_this.prods.length || newH > (_this as any).objDataShelf.height) return true;

    let rv = true;

    _this.prods.forEach(prod => {
      if (Math.max(prod.position.y, 0) + prod.size.h > newH) rv = false;
    });

    return rv;
  };

  _this.isNewDepthValid = (newD: number) => {
    if (!_this.prods || !_this.prods.length || newD > (_this as any).objDataShelf.depth) return true;

    let rv = true;

    _this.prods.forEach(prod => {
      let _d = prod.itemData.size.d || prod.itemData.size.w;
      if (_d > newD) rv = false;
    });

    return rv;
  };
};

export const _calcSizeProdPegShelf = obj => {
  let tmpSize = obj && obj.itemData && obj.itemData.size ? obj.itemData.size : null;

  if (!tmpSize) {
    if (true) console.warn('[_calcSizeProd] no itemData size', obj);
    return { w: 0, h: 0 };
  }

  let size = { w: 0, h: 0 };
  let imgSize = { w: 0, h: 0, originX: 0, originY: 0, offsetX: 0, offsetY: 0 };

  if (!obj.rotation) {
    //sin rotacion
    size = { w: _round(tmpSize.w), h: _round(tmpSize.h) };
    imgSize = { ...imgSize, ...size };
  } else {
    //con rotacion
    const totalBound = _calcRotateTransforms(tmpSize.w, tmpSize.h, obj.rotation || 0);
    size = { w: _round(totalBound.w), h: _round(totalBound.h) };
    imgSize = {
      ...totalBound,
      w: tmpSize.w,
      h: tmpSize.h,
    };
  }

  return { size, imgSize };
};

//Auxiliar para reacomodar/calcular poscion/tamaño/filtrado de items
export const _recalcPosAndSize_simplePeg = shelf => {
  let prods = shelf.prods || [];

  let shelfDepth = (shelf || {}).depth;
  let $totalItems = shelfDepth ? 0 : null;

  prods = _orderBy(prods, ['order']).map(($prod, i) => {
    if (!$prod || !$prod.itemData) return $prod;

    let prod = _cloneDeep({ ...$prod, itemData: null });
    prod.itemData = $prod.itemData;

    //Borrar todas las propiedades que no pertenezcan a este tipo
    if (prod.facings) delete prod.facings;
    if (prod.stacks) delete prod.stacks;
    if (prod.limitStackSize) delete prod.limitStackSize;
    if (prod.stacksLyingDown) delete prod.stacksLyingDown;

    //Calcula boundingBox y transformaciones de la imagen si hay rotaciones
    const sizeProdPegShelf = _calcSizeProdPegShelf(prod);

    //ReadOnly de cantidad de items
    if (shelfDepth) $totalItems = Math.floor(shelfDepth / (prod.itemData.size.d || prod.itemData.size.w)) || 1;

    //Limite de carga
    if (prod.limitLoadSize && $totalItems > prod.limitLoadSize) $totalItems = prod.limitLoadSize;

    //DES-3945 -> Unidad de medida mayor a 1
    let unMed = _toNumber(prod.itemData.unMed);
    if (true && unMed != null && unMed > 1) {
      $totalItems *= unMed;
    }

    const rv = {
      ...prod,
      order: i,
      size: sizeProdPegShelf.size,
      imgSize: sizeProdPegShelf.imgSize,
      $totalItems,
      $parenShelf: shelf.id,
    };
    return rv;
  });

  return prods;
};

export const _auxAvoidCollisions_simplePeg = (shelfProds: any[], _id: number | string, shelf): any[] => {
  if (!(shelfProds?.length > 0)) return shelfProds;

  let rv_shelfProds = [...shelfProds];
  let prodToEdit = null;
  let prodsToCompare = null;

  rv_shelfProds = rv_shelfProds.map(prod => {
    if (String(prod.id) === String(_id)) {
      prodsToCompare = rv_shelfProds.filter(e => String(e.id) !== String(_id));
      prodToEdit = { ...prod, position: { ...prod.position } };

      if (true) prodToEdit = _auxAvoidCollisionProdWhitRestProds(prodToEdit, prodsToCompare, false);
      if (true) prodToEdit = _auxAvoidCollisionProdWhitRestProds(prodToEdit, prodsToCompare, true);
      if (true) prodToEdit = _auxAvoidCollisionProdAndShelf(prodToEdit, shelf);

      return prodToEdit;
    }

    return prod;
  });

  if (false && _isFeDev()) _log('[_auxAvoidCollisions_simplePeg]', _cloneDeep({ prodsToEdit: prodToEdit, prodsToCompare }));

  return rv_shelfProds;
};

const _auxAvoidCollisionProdWhitRestProds = (prod: any, prodsToCompare: any[], reverse = true): any => {
  let rv_prod = { ...prod, position: { ...prod.position } };

  const _compareProds = prodComp => {
    let rot = rv_prod.rotation || 0;
    let x = prod.position.x;
    let y = prod.position.y;
    let x2 = prod.position.x + rv_prod.size.w;
    let y2 = prod.position.y + rv_prod.size.h;

    if (Math.round(rot) % 90 !== 0) return;

    let cRot = prodComp.rotation || 0;
    let cx = prodComp.position.x;
    let cy = prodComp.position.y;
    let cx2 = prodComp.position.x + prodComp.size.w;
    let cy2 = prodComp.position.y + prodComp.size.h;

    if (Math.round(cRot) % 90 !== 0) return;

    let overlapX = (x >= cx && x <= cx2) || (x2 >= cx && x2 <= cx2) || (cx >= x && cx <= x2) || (cx2 >= x && cx2 <= x2);
    let overlapY = (y >= cy && y <= cy2) || (y2 >= cy && y2 <= cy2) || (cy >= y && cy <= y2) || (cy2 >= y && cy2 <= y2);
    let overlap = overlapX && overlapY;

    //POR ANGULO
    if (overlap && false) {
      let centerPord = { x: x + (x2 - x) / 2, y: y + (y2 - y) / 2 };
      let centerComp = { x: cx + (cx2 - cx) / 2, y: cy + (cy2 - cy) / 2 };
      let angle = _auxAnglBetweenTwoPoints(centerComp, centerPord);
      let angleP = Math.round((angle / 360) * 4);

      let offset = 0.1 + _auxAddRandomPos();
      if (angleP === 0 || angleP === 4) rv_prod.position.x = cx2 + offset; //RIGHT
      if (angleP === 1) rv_prod.position.y = cy2 + offset; //BOTTOM
      if (angleP === 2) rv_prod.position.x = cx - (rv_prod.size.w + offset); //LEFT
      if (angleP === 3) rv_prod.position.y = cy - (rv_prod.size.h + offset); //TOP
    }

    //POR MAS CERCANO
    if (overlap && true) {
      let offset = 0.1 + _auxAddRandomPos();
      const moves = [];

      moves.push([rv_prod.position.x - (cx2 + offset), 'RIGHT']); //RIGHT
      moves.push([rv_prod.position.y - (cy2 + offset), 'BOTTOM']); //BOTTOM
      moves.push([rv_prod.position.x - (cx - (rv_prod.size.w + offset)), 'LEFT']); //LEFT
      moves.push([rv_prod.position.y - (cy - (rv_prod.size.h + offset)), 'TOP']); //TOP

      const minMove = Math.min(...moves.map(m => Math.abs(m[0])));
      let minMoveIndex = moves.findIndex(m => Math.abs(m[0]) === Math.abs(minMove));
      if (minMoveIndex === -1) return;
      let minMoveCase = moves[minMoveIndex];
      if (minMoveCase == null) return;

      if (minMoveCase[1] == 'RIGHT') rv_prod.position.x = cx2 + offset; //RIGHT
      if (minMoveCase[1] == 'BOTTOM') rv_prod.position.y = cy2 + offset; //BOTTOM
      if (minMoveCase[1] == 'LEFT') rv_prod.position.x = cx - (rv_prod.size.w + offset); //LEFT
      if (minMoveCase[1] == 'TOP') rv_prod.position.y = cy - (rv_prod.size.h + offset); //TOP
    }
  };

  (reverse ? [...prodsToCompare].reverse() : [...prodsToCompare]).forEach(prod => _compareProds(prod));

  return rv_prod;
};

const _auxAvoidCollisionProdAndShelf = (prod, shelf) => {
  let rv_modoule = { ...prod, position: { ...prod.position } };

  //BOTTOM-RIHGT
  let prodSize = { ...rv_modoule.size };
  let stageW = shelf._size.w;
  let stagwH = shelf._size.h;
  let offset = 0.1 + _auxAddRandomPos();

  if (rv_modoule.position.x + prodSize.w > stageW - (shelf.marginRight || 0))
    rv_modoule.position.x = stageW - (shelf.marginRight || 0) - (prodSize.w + offset);
  if (rv_modoule.position.y + prodSize.h > stagwH) rv_modoule.position.y = stagwH - (prodSize.h + offset);

  //UP-LEFT
  if (rv_modoule.position.x < 0 + (shelf.marginLeft || 0)) rv_modoule.position.x = 0 + (shelf.marginLeft || 0) + offset;
  if (rv_modoule.position.y < 0) rv_modoule.position.y = 0 + offset;

  return rv_modoule;
};

let _auxAnglBetweenTwoPoints = (p1, p2) => {
  let theta = Math.atan2(p2.y - p1.y, p2.x - p1.x);

  theta *= 180 / Math.PI;

  if (theta < 0) theta = 360 + theta;

  return theta;
};

const _auxAddRandomPos = () => {
  return 0 + Math.random() / 100000;
};
