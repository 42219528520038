import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { FuseSharedModule } from '@fuse/shared.module';
import { InsightsPanelComponent } from './insights-panel.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { InsightsPanelService } from './insights-panel.service';
import { NgxsModule } from '@ngxs/store';
import { InsightsPanelStoreState } from '../insights-panel/insights-panel-store.state';
import { NgxsFormPluginModule } from 'core/form-plugin/public_api';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared.module';
import { InsightsService } from 'app/admin/master/insights/insights.service';
import { HttpClientModule } from '@angular/common/http';
import { DashBoardState } from 'app/home/dashboard/store/dashboard.state';

@NgModule({
  declarations: [InsightsPanelComponent],
  imports: [
    HttpClientModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    FuseSharedModule,
    MatIconModule,
    MatMenuModule,
    CommonModule,
    NgxsFormPluginModule,
    SharedModule,
    NgxsModule.forFeature([InsightsPanelStoreState, DashBoardState]),
    RouterModule,
  ],
  exports: [TranslateModule, InsightsPanelComponent, MatIconModule, MatMenuModule, MatTooltipModule],
  providers: [InsightsPanelService, InsightsService],
})
export class InsightsPanelModule {}
