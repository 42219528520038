<h6 class="title">Sugeridos para esta pantalla:</h6>
<button
  mat-menu-item
  matTooltip="¿Cómo definir Lineamientos y Estrategias?"
  (click)="openVideo('guidelinesAndStrategies')"
>
  <mat-icon>play_circle_outline</mat-icon>
  ¿Cómo definir Lineamientos y Estrategias?
</button>
<!-- <button mat-menu-item
        routerLink="/helps/price-help/faqs">
    <mat-icon>
        help_outline
    </mat-icon>
    Preguntas Frecuentes
</button> -->
