import { Subject, BehaviorSubject } from 'rxjs';
import { OnInit, OnDestroy, Directive } from '@angular/core';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { _debounceDecorator, _printObj, _setTextFilterValue, _timeout } from '@shared/aux_helper_functions';
import { _log, _isDev, _isFeDev } from '@shared/aux_helper_environment';

@Directive()
export class PrismaListBaseComponent implements OnInit, OnDestroy {
  public $unsuscribreAll: Subject<void> = new Subject<void>();

  public $_printObj = _printObj;
  public $_log = _log;
  public $_isDev = _isDev();

  public $paginationData: any = {
    length: 0,
    activePageIndex: 0,
    pageSize: 10,
    pageSizeOptions: [10, 20, 50, 100],
  };

  public $pageTitle = '';
  public $isLoading = true;
  public $filterOpened = false;

  public $toolbarType$ = new BehaviorSubject<number>(1);
  public $marginSubject$ = new BehaviorSubject<number>(0);
  public $scroll$ = new BehaviorSubject<number>(0);
  public $toolbarTypeMinForced = false;

  ngOnInit(): void {
    this.$scroll$.pipe(takeUntil(this.$unsuscribreAll), distinctUntilChanged()).subscribe(value => {
      if (value > 0 || this.$toolbarTypeMinForced === true) {
        this.$marginSubject$.next(-64);
        this.$toolbarType$.next(3);
      } else {
        this.$marginSubject$.next(0);
        this.$toolbarType$.next(1);
      }
    });
  }

  @_debounceDecorator(64)
  public $onScroll($event): void {
    if (false) {
      _log(`${PrismaListBaseComponent.name}::onScroll (scroll)\n\t$event: %o\n\tscrollTop: %o`, $event, $event.srcElement.scrollTop);
    }
    const target = $event.srcElement;
    if (target.scrollHeight - target.clientHeight > 64) {
      const value = Math.round(target.scrollTop);
      this.$scroll$.next(value);
    }
  }
  public onScroll = this.$onScroll;

  public async $resetTextQueryFilter(txt = '', selector = 'ian-default-toolbar .mat-form-field-infix input', pause = 0) {
    await _timeout(pause);

    const matches = document.querySelectorAll(selector);

    if (matches && matches[0]) {
      const el = matches[0] as HTMLInputElement;
      if (el.value !== txt) el.value = txt;
    }
  }

  initInputSearch(text, force = false) {
    let elementStr = 'ian-default-toolbar .mat-form-field-infix input';
    const matches = document.querySelector(elementStr) as HTMLInputElement;

    //Si los dos valores de querySearch tienen texto no se resetea, manda el del input //DES-688
    //a menos que force esté en true
    if (text?.length > 0 && matches?.value?.length > 0 && force === false) return;

    if (true && _isFeDev()) _log('[reset initInputSearch]', { elTxt: matches?.value, neVal: text, force });

    _setTextFilterValue(text);
  }

  _refreshListAfterDelete(actions, store) {
    this.$isLoading = true;
    store.dispatch(actions);
  }

  ngOnDestroy(): void {
    if (true && _isDev()) _log('[DESTROY LIST]');
    this.$unsuscribreAll.next();
    this.$unsuscribreAll.complete();
  }
}
