import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterContentInit, ChangeDetectorRef, Component, ContentChildren, QueryList } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatColumnDef, MatHeaderRowDef, MatRowDef } from '@angular/material/table';
import { transitions } from '@prisma/animations/animations';
import { _log } from '@shared/aux_helper_environment';
import { PrismaTableParentComponent } from '../prisma-table-parent.component';
import { Router } from '@angular/router';

@Component({
  selector: 'prisma-table',
  templateUrl: './prisma-table.component.html',
  styleUrls: ['./prisma-table.component.scss'],
  animations: [
    transitions,
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PrismaTableComponent<T> extends PrismaTableParentComponent<T> implements AfterContentInit {
  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;
  pageEvent: PageEvent;
  constructor(private cdr: ChangeDetectorRef, private route: Router) {
    super();
  }

  goTo(url) {
    this.route.navigate([url]);
  }

  _hiddenMainRow(_row) {
    if (!this.conditionToHiddenMainRow) return null;
    const response = this.conditionToHiddenMainRow(_row);
    return response ? 'none' : 'visible';
  }

  pageEventChange(paginationConfig) {
    if (paginationConfig) {
      this.length.next(paginationConfig.length);
      this.pageSize = paginationConfig.pageSize;
      this.pageSizeOptions = paginationConfig.pageSizeOptions;
      this.pageIndex = paginationConfig.pageIndex;
    }
    if (this.pageEvent) {
      this.pageEvent.pageIndex = this.pageIndex;
      this.pageEvent.pageSize = this.pageSize;
      this.length.subscribe(val => (this.pageEvent.length = val));
      this.cdr.markForCheck();
    }
  }

  ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
    this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    this.headerRowDefs.forEach(headerRowDef => this.table.addHeaderRowDef(headerRowDef));
  }

  //Setea una variable de CSS con el offset del scroll para sumarselo a UXv2_actions-btns
  onScrollPrismaTable($event): void {
    const target = $event?.srcElement;

    if (target == null) return;

    const scrollTop = target?.scrollTop != null ? target?.scrollTop : 0;

    target.style.setProperty('--scroll-top-offset-prisma-table', `${-scrollTop}px`);

    if (false) _log(scrollTop);
  }
}
