import { _log } from '@shared/aux_helper_environment';
import { _cloneDeep, _set } from '@shared/aux_helper_functions';

// data

const defaultCheckBoxData = {
  hasBulkSelection: false,
  selectedItems: [],
  list: [],
  totalItemCount: 0,
};

// classes

export class CheckBoxData {
  state: CheckBoxDataModel = defaultCheckBoxData;
  propId = 'id';

  constructor(protected _propId: string = null) {
    this.propId = _propId ? _propId : 'id';
  }

  getIsBulkSelection(): boolean {
    return this.state.hasBulkSelection;
  }

  getSelectedItems(): Array<any> {
    return this.state.selectedItems;
  }

  getHasBulkSelection(): boolean {
    return this.state.hasBulkSelection || this.state.selectedItems.length > 0;
  }

  setIsBulkSelection(value: boolean) {
    this.updateState({ key: 'hasBulkSelection', value });
  }

  setList(_list: Array<any>) {
    _list = _list && _list.length > 0 ? _list : [];
    this.updateState({ key: 'list', value: _list });
  }

  setSelectedItems(values: Array<any>) {
    this.updateState({ key: 'selectedItems', value: values });
  }

  setTotalItemsSelected(value: number) {
    value = value && value > 0 ? value : 0;
    this.updateState({ key: 'totalItemCount', value });
  }

  resetSelection() {
    this.state = { ...defaultCheckBoxData, list: this.state.list, totalItemCount: this.state.totalItemCount };
  }

  isItemSelected(item: any): any {
    return (
      this.state.hasBulkSelection ||
      (this.state.selectedItems && this.state.selectedItems.some(_item => _item[this.propId] === item[this.propId]))
    );
  }

  updateState(action) {
    const { key, value } = action;
    let _state = _cloneDeep(this.state);
    _set(_state, key, value);
    this.state = { ..._state };
  }

  //abstract methods
  getHasTotalItemsSelected(): any {}
  getIsBulkSelectionIndetermined(): any {}
  getTotalItemsSelected(): any {}
  toggleIndividualSelection(item: any) {}
  toggleBulkSelection() {}
}

export class CheckBoxForPagination extends CheckBoxData {
  constructor(protected _propId: string = null) {
    super(_propId);
  }

  getHasTotalItemsSelected(): boolean {
    return this.getIsBulkSelection();
  }

  getIsBulkSelectionIndetermined(): boolean {
    const length = this.state.selectedItems.length;
    return !this.state.hasBulkSelection && length < this.state.totalItemCount && length > 0;
  }

  getTotalItemsSelected(): number {
    return this.state.hasBulkSelection ? this.state.totalItemCount : this.state.selectedItems.length;
  }

  /**
   * Cambiar estado de de check, seleccionado o no
   */
  toggleIndividualSelection(item: any): void {
    let isBulk = false;

    let bulkSelectedItems: Array<any>;

    if (this.state.hasBulkSelection) {
      bulkSelectedItems = [];
    } else {
      // Busca el item para determinar si hay que agregar o eliminarlo.
      const foundItem = this.state.selectedItems.some(_item => _item[this.propId] === item[this.propId]);
      if (foundItem) {
        // Si lo encontro, elimina la seleccion del item.
        bulkSelectedItems = this.state.selectedItems.filter(_item => _item[this.propId] !== item[this.propId]);
      } else {
        // Verifica si la cantidad total de elementos del listado es igual a la cantidad seleccionada + el interactuado, proximo a agregar (payload).
        if (this.state.totalItemCount === this.state.selectedItems.length + 1) {
          isBulk = true;
        }
        bulkSelectedItems = [...this.state.selectedItems, item];
      }
    }
    this.updateState({ key: 'hasBulkSelection', value: isBulk });
    this.updateState({ key: 'selectedItems', value: bulkSelectedItems || [] });
  }

  /**
   * Cambiar estado de seleccion, seleccionar todos, los que faltan, resetear seleccion
   */
  toggleBulkSelection(): void {
    const toggledBulkSelection = !this.state.hasBulkSelection;
    const _newSelectedItems = toggledBulkSelection ? this.state.list : [];

    // Invierte el valor de la selección masiva.
    // Limpia todos los ids seleccionados.
    this.updateState({ key: 'hasBulkSelection', value: toggledBulkSelection });
    this.updateState({ key: 'selectedItems', value: _newSelectedItems });
  }
}

export class CheckBoxForList extends CheckBoxData {
  constructor(protected _propId: string = null) {
    super(_propId);
  }

  getHasTotalItemsSelected(): boolean {
    return this.state.selectedItems.length === this.state.list.length;
  }

  getIsBulkSelectionIndetermined(): boolean {
    const length = this.state.selectedItems.length;
    return !this.state.hasBulkSelection && length < this.state.list.length && length > 0;
  }

  getTotalItemsSelected(): number {
    return this.state.hasBulkSelection ? this.state.list.length : this.state.selectedItems.length;
  }

  /**
   * Cambiar estado de de check, seleccionado o no
   */
  toggleIndividualSelection(item: any): void {
    let isBulk = false;

    let bulkSelectedItems: Array<any>;

    if (this.state.hasBulkSelection) {
      // Si esta el bulk select activo, filtra los items sacando el que dispara la accion.
      bulkSelectedItems = this.state.list.filter(_item => _item[this.propId] !== item[this.propId]).map(_item => _item);
    } else {
      // Busca el item para determinar si hay que agregar o eliminarlo.
      const foundItem = this.state.selectedItems.some(_item => _item[this.propId] === item[this.propId]);
      if (foundItem) {
        // Si lo encontro, elimina la seleccion del item.
        bulkSelectedItems = this.state.selectedItems.filter(_item => _item[this.propId] !== item[this.propId]);
      } else {
        // Verifica si la cantidad total de elementos del listado es igual a la cantidad seleccionada + el interactuado, proximo a agregar (payload).
        if (this.state.list.length === this.state.selectedItems.length + 1) {
          isBulk = true;
        }
        bulkSelectedItems = [...this.state.selectedItems, item];
      }
    }
    this.updateState({ key: 'hasBulkSelection', value: isBulk });
    this.updateState({ key: 'selectedItems', value: bulkSelectedItems || [] });
  }

  /**
   * Cambiar estado de seleccion, seleccionar todos, los que faltan, resetear seleccion
   * por reglas de dominio en back por mas que agreguemos el listado completo, en caso que toggled = true, se mapea y se envia distinto
   * pero visualmente lo seleccionamos todo
   */
  toggleBulkSelection(): void {
    const toggledBulkSelection = !this.state.hasBulkSelection;
    const _newSelectedItems = toggledBulkSelection ? this.state.list : [];

    // Invierte el valor de la selección masiva.
    // Limpia todos los ids seleccionados.
    this.updateState({ key: 'hasBulkSelection', value: toggledBulkSelection });
    this.updateState({ key: 'selectedItems', value: _newSelectedItems });
  }
}

// aux functions
/**
 * devuelvo el objeto persistido en store
 * @param state state para obtener los datos del store
 * @param propCheckBox personalizar si se llama distinto el atributo en store que persiste los items seleccionados
 */
export const _currentCheckBoxData = (state: any, propCheckBox = 'checkBoxData'): CheckBoxData => {
  return _cloneDeep(state[propCheckBox]);
};

/**
 * Actualizo en el stado del store los datos del objeto checkBoxData
 * @param context contexto para obtener los datos del store
 * @param _data tipo PagedList o List: [any] o {items: [any], ...}
 * @param propCheckBox personalizar si se llama distinto el atributo en store que persiste los items seleccionados
 */
export const _updateCheckBoxData = (context: any, _data: CheckBoxData, propCheckBox = 'checkBoxData'): void => {
  context.patchState({ [propCheckBox]: _data });
};

/**
 * inicializa datos del objeto de checkBox con los datos del listado, items encontrados y numero total de resultados
 * @param context contexto para obtener los datos del store
 * @param _data type initCheckBoxModel {list: Array<any>;totalItemsCount: number;}
 * @param propCheckBox personalizar si se llama distinto el atributo en store que persiste los items seleccionados
 */
export const _initCheckBoxData = (context: any, _data: initCheckBoxModel, propCheckBox = 'checkBoxData'): void => {
  const _checkBoxData = _currentCheckBoxData(context.getState(), propCheckBox);
  _checkBoxData.setTotalItemsSelected(_data.totalItemsCount);
  _checkBoxData.setList(_data.list);
  _updateCheckBoxData(context, _checkBoxData, propCheckBox);
};

/**
 * reinicia con datos default vacios, deseleccionando todo
 * @param context contexto para obtener los datos del store
 * @param propCheckBox personalizar si se llama distinto el atributo en store que persiste los items seleccionados
 */
export const _resetCheckBoxData = (context: any, propCheckBox = 'checkBoxData', checkBox = null): void => {
  const _checkBoxData = context && propCheckBox ? _currentCheckBoxData(context.getState(), propCheckBox) : checkBox;
  _checkBoxData.resetSelection();
  _updateCheckBoxData(context, _checkBoxData, propCheckBox);
};

/**
 * usado para enviar info a la api, devuelve segun dominio BE [] si se seleccionaron todos, sino el listado seleccionado
 * @param context contexto para obtener los datos del store
 * @param propCheckBox personalizar si se llama distinto el atributo en store que persiste los items seleccionados
 * @returns devuelve vacio si se seleccionaron todos, sino los datos seleccionados
 */
export const _getSelectedCheckBoxDataAsFilter = (context: any, propCheckBox = 'checkBoxData', checkBox = null): Array<any> => {
  const _checkBoxData = context && propCheckBox ? _currentCheckBoxData(context.getState(), propCheckBox) : checkBox;
  const _selected = _checkBoxData.getSelectedItems();
  const hasTotalItemSelected = _checkBoxData.getHasTotalItemsSelected();
  return !hasTotalItemSelected ? _selected : [];
};

/**
 * usado para enviar info a la api, devuelve items del listado que son seleccionados ya que la funcionalidad seleccionar todos no se implementa
 * @param context contexto para obtener los datos del store
 * @param propCheckBox personalizar si se llama distinto el atributo en store que persiste los items seleccionados
 * @returns devuelve vacio si se seleccionaron todos, sino los datos seleccionados
 */
export const _getSelectedCheckBoxDataAsFilterWithOutSelectAll = (context: any, propCheckBox = 'checkBoxData'): Array<any> => {
  const _checkBoxData = _currentCheckBoxData(context.getState(), propCheckBox);
  const _selected = _checkBoxData.getSelectedItems();
  return _selected;
};

// models
export interface CheckBoxDataModel {
  hasBulkSelection: boolean;
  selectedItems: Array<any>;
  list: Array<any>;
  totalItemCount: number;
}

export interface initCheckBoxModel {
  list: Array<any>;
  totalItemsCount?: number;
}
