import { ScrollDispatcher } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { _isDev, _log, _warn } from '@shared/aux_helper_environment';
import {
  _cloneDeep,
  _debounceDecorator,
  _equal,
  _getNewNumberId,
  _orderBy,
  _timeout,
  _uniqueElements,
  _uniqueElementsByKey,
} from '@shared/aux_helper_functions';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { Subject } from 'rxjs';
import { delay, first, filter } from 'rxjs/operators';
import { GenericTagsSelectV2Service } from './generic-tag-select-v2.service';
import { GenericTagsSelectMultiplev2AttributeModal } from './generic-tags-select-multiple-v2-attribute.modal';
import {
  TagTreeSelectedModel,
  TagTreeSelectedModelInternal,
  TagTreeWithRootSelectedModal,
  _aux_filterTagsRootExistByRootId,
  _aux_getTagItemObjFromId,
  _aux_getTagRootFromId,
  _aux_tagTreeGroupFormat,
} from './generic-tags-select-v2.component';

//sirve para cuando se usa @output onChangeGroup y ingresas los datos seleccionados @input selectedTags
export const _mapTagIdTagValuesToIds = (tags: TagTreeWithRootSelectedModal[]): TagTreeSelectedModel[] => {
  return (tags || [])
    .map(t => t.tagValues)
    .flat()
    .map(id => {
      return { id: id };
    });
};

@Component({
  selector: 'generic-tags-select-multiple-v2',
  template: `
    <ng-container>
      <ng-container *ngIf="groupTags == null && showLoader">
        <div class="spinner-container-mini">
          <custom-loader></custom-loader>
        </div>
      </ng-container>

      <div
        *ngIf="true && groupTags != null"
        [class]="deafaultParentClass"
        [ngClass]="{
          'generic-tags-select-multiple-root': !useAsAdvancedFilter,
          'generic-tags-select-multiple-advanced-filter-root': useAsAdvancedFilter
        }"
      >
        <p *ngIf="!!title" class="form-filter title-filter">
          {{ title }}
          <span [matTooltip]="'COMP.GEN_TAGS.STORES.FILTER_TAG_VALUES' | pTranslate">
            <button mat-icon-button *ngIf="filterTagsValuesSwitch" (click)="filterTagsValues(!tagValuesByResultsOnList)">
              <mat-icon [ngClass]="{ 'status-black': !tagValuesByResultsOnList, 'status-green': tagValuesByResultsOnList }">
                filter_list
              </mat-icon>
            </button>
          </span>
        </p>

        <div *ngFor="let tag of groupTags; let i = index; trackBy: trackByRootTagId" class="generic-tags-select-multiple-celd">
          <ng-container *ngIf="false">
            {{ tag | json }}
          </ng-container>
          <generic-tags-select-v2
            *ngIf="tag?.rootTagId != null"
            [tagsModuleType]="tagsModuleType"
            [rootTagId]="tag.rootTagId"
            [required]="visibleTagsRootsRequired?.length && visibleTagsRootsRequired.includes(tag.rootTagId)"
            [levels]="configLevelsSize"
            [level1Config]="configLevel1 || configLevels"
            [level2Config]="configLevel2 || configLevels"
            [level3Config]="configLevel3 || configLevels"
            [level4Config]="configLevel4 || configLevels"
            [configLevels]="configLevels"
            [onFilterTagsValues]="tagValuesByResultsOnList"
            [verbose]="verbose"
            [autoFillParentsOnStart]="autoFillParentsOnStart"
            [autoFillParentsOnAlways]="autoFillParentsOnAlways"
            [selected]="tag.selected || []"
            [placeholder]="i == 0 ? placeholder : null"
            [prefixPlaceholder]="prefixPlaceholder"
            [postfixPlaceholder]="postfixPlaceholder"
            [showClearAllBtn]="showClearAllBtn"
            (onChange)="changeSelected($event, i)"
            [autoSelectRequiredTagByDefaultTagValue]="autoSelectRequiredTagByDefaultTagValue"
          >
          </generic-tags-select-v2>
        </div>
      </div>

      <div fxLayout="row" class="mt-20 mb-20" *ngIf="groupTags != null && (addMoreItemsButton || useAsAdvancedFilter)">
        <div fxFlex fxLayoutAlign="end start">
          <button
            mat-icon-button
            color="accent"
            matTooltip="{{ 'ADMIN.MASTER.STORES.ADD_ATRIBUTE_BTN' | pTranslate }}"
            (click)="openNewtagDialog()"
            data-test-id="add_tags"
            [disabled]="configLevels?.disabled == true || noMoreTagsToSelect()"
          >
            <span class="material-icons font-size-30">add_circle_outline</span>
          </button>
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .generic-tags-select-multiple-root {
        display: flex;
        flex-wrap: wrap;
        row-gap: 24px;
      }

      .generic-tags-select-multiple-advanced-filter-root {
        display: flex;
        flex-wrap: wrap;
      }

      .generic-tags-select-multiple-celd {
        flex: 1 1 calc(100%);
        max-width: calc(100%);
      }

      @media (min-width: 960px) {
        .generic-tags-select-multiple-root {
          column-gap: 32px;
        }

        .generic-tags-select-multiple-root.flex-33,
        .generic-tags-select-multiple-root.flex-50 {
          row-gap: 32px;
        }

        ::ng-deep .generic-tags-select-multiple-root.flex-33 .generic-lookup,
        ::ng-deep .generic-tags-select-multiple-root.flex-50 .generic-lookup {
          max-height: 56px;
        }

        .generic-tags-select-multiple-root.flex-33 > .generic-tags-select-multiple-celd {
          flex: 1 1 calc(33% - 32px);
          max-width: calc(33% - 32px);
        }
        .generic-tags-select-multiple-root.flex-33 > .generic-tags-select-multiple-celd:nth-child(3n) {
          flex: 1 1 33%;
          max-width: 33%;
        }

        .generic-tags-select-multiple-root.flex-50 > .generic-tags-select-multiple-celd {
          flex: 1 1 calc(50% - 32px);
          max-width: calc(50% - 32px);
        }
        .generic-tags-select-multiple-root.flex-50 > .generic-tags-select-multiple-celd:nth-child(2n) {
          flex: 1 1 50%;
          max-width: 50%;
        }
      }
    `,
  ],
})
export class GenericTagsSelectMultipleV2Component implements OnInit, OnDestroy {
  $unsuscribreAll: Subject<void> = new Subject<void>();
  destroyed = false;
  tagTree = null;
  allFirstLevelTags = [];
  groupTags: TagTreeSelectedModelInternal[] = null;

  @Input('tagsModuleType') tagsModuleType = 1;
  @Input('placeholder') placeholder: string = null;
  @Input('prefixPlaceholder') prefixPlaceholder = '';
  @Input('postfixPlaceholder') postfixPlaceholder = '';
  @Input('showLoader') showLoader = true;

  @Input('configLevels') configLevels: any = { multiple: true, parentDependent: false };
  @Input('configLevel1') configLevel1 = null;
  @Input('configLevel2') configLevel2 = null;
  @Input('configLevel3') configLevel3 = null;
  @Input('configLevel4') configLevel4 = null;

  @Input('configLevelsSize') configLevelsSize = [1, 2, 3];
  @Input('visibleTagsRoots') visibleTagsRoots: number[] | { id: number; isRequired?: boolean }[] = null;
  @Input('visibleTagsValues') visibleTagsValues: string[] = null;
  @Input('visibleTagsValuesIds') visibleTagsValuesIds: number[] = null;
  @Input('filterTagsValuesSwitch') filterTagsValuesSwitch = false;
  @Input('tagValuesByResultsOnList') tagValuesByResultsOnList = false;
  @Input('selectedTags') selectedTags: TagTreeSelectedModel[] = null;
  @Input('title') title: string = null;
  @Input('verbose') verbose = false;
  @Input('autoFillParentsOnStart') autoFillParentsOnStart = false;
  @Input('autoFillParentsOnAlways') autoFillParentsOnAlways = false;
  @Input('deafaultParentClass') deafaultParentClass: 'flex-33' | 'flex-50' | 'flex-100' = 'flex-33';
  @Input('addMoreItemsButton') addMoreItemsButton = true;
  @Input('useAsAdvancedFilter') useAsAdvancedFilter = false;
  @Input('showClearAllBtn') showClearAllBtn = true;
  @Input('autoVisibleTagsRoots') autoVisibleTagsRoots = false; //obtiene los tags requeridos/obligatorios para mostrar por pantalla del primer nivel. Se activa cuando no se quiere enviar visibleTagsRoots
  @Input('autoSelectRequiredTagByDefaultTagValue') autoSelectRequiredTagByDefaultTagValue = false;

  @Output() onChange: EventEmitter<TagTreeSelectedModel[]> = new EventEmitter();
  @Output() onChangeGroup: EventEmitter<TagTreeWithRootSelectedModal[]> = new EventEmitter();
  @Output() onChangeList: EventEmitter<boolean> = new EventEmitter();
  @Output() isLoaded: EventEmitter<boolean> = new EventEmitter();
  @Output() onChangeFilterTagValues: EventEmitter<boolean> = new EventEmitter();

  userVisibleTagsRoots: number[] = null;
  visibleTagsRootsRequired = [];
  visibleTagsIds = [];

  trackByRootTagId = rTag => rTag.rootTagId;

  /**/
  constructor(
    protected cd: ChangeDetectorRef,
    readonly sd: ScrollDispatcher,
    public translate: IanTranslateService,
    protected service: GenericTagsSelectV2Service,
    protected dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.service
      .geTagsTree(this.tagsModuleType)
      .pipe(first())
      .subscribe(async data => {
        this.tagTree = data || [];

        const _visibleTagsRoots = [];

        this.allFirstLevelTags =
          this.tagTree?.length && this.tagTree[0]?.values?.length
            ? this.tagTree[0].values.reduce((acc, element) => {
                if (element?.parentId === 0 && !acc.includes(element.tagId)) {
                  acc.push(element.tagId);
                  if (element.isRequired && (!this.visibleTagsRoots || this.visibleTagsRoots?.length > 0) && this.autoVisibleTagsRoots) {
                    _visibleTagsRoots.push(element.tagId);
                  }
                }
                return acc;
              }, [])
            : [];
        if ((!this.visibleTagsRoots || this.visibleTagsRoots?.length > 0) && this.autoVisibleTagsRoots) {
          this.visibleTagsRoots = _visibleTagsRoots;
        }
        this.creatGroupTagsFromImputs();
        if (
          ((this.filterTagsValuesSwitch && this.tagValuesByResultsOnList) || !this.filterTagsValuesSwitch) &&
          !!this.visibleTagsValues &&
          this.visibleTagsValues?.length > 0
        )
          this.setTagValuesIdsByTagValueNames();
        if (
          ((this.filterTagsValuesSwitch && this.tagValuesByResultsOnList) || !this.filterTagsValuesSwitch) &&
          !!this.visibleTagsValuesIds &&
          this.visibleTagsValues?.length > 0
        ) {
          this.setIntersectGeneralConfigLevel();
        }
        this.isLoaded.emit(true);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (false && changes.selectedTags) _log('[GenericTagsSelectMultipleV2Component]', this.selectedTags);

    const changeVisibleTagsValues =
      ((this.filterTagsValuesSwitch && this.tagValuesByResultsOnList) || !this.filterTagsValuesSwitch) &&
      !!this.tagTree &&
      !!this.visibleTagsValues &&
      changes.visibleTagsValues &&
      !_equal(changes.visibleTagsValues.currentValue, changes.visibleTagsValues.previousValue);

    if (changeVisibleTagsValues) {
      this.setTagValuesIdsByTagValueNames();
    }

    const changeVisibleTagsValuesIds =
      ((this.filterTagsValuesSwitch && this.tagValuesByResultsOnList) || !this.filterTagsValuesSwitch) &&
      this.tagTree &&
      !!this.visibleTagsValuesIds &&
      changes.visibleTagsValuesIds &&
      !_equal(changes.visibleTagsValuesIds.currentValue, changes.visibleTagsValuesIds.previousValue);

    if (changeVisibleTagsValuesIds) {
      this.setIntersectGeneralConfigLevel();
    }

    const changeSelectedTags =
      this.tagTree && changes.selectedTags && !_equal(changes.selectedTags.currentValue, changes.selectedTags.previousValue);

    if (changeSelectedTags) {
      this.creatGroupTagsFromImputs();
      return;
    }

    //

    const changeVisibleTagsRoots =
      this.tagTree && changes.visibleTagsRoots && !_equal(changes.visibleTagsRoots.currentValue, changes.visibleTagsRoots.previousValue);

    if (changeVisibleTagsRoots) {
      this.creatGroupTagsFromImputs();
      return;
    }
  }

  setTagValuesIdsByTagValueNames() {
    const flatTagTreeValues = this.tagTree.flatMap(tag => tag.values);

    this.visibleTagsValuesIds = flatTagTreeValues
      .filter(tagValue => this.visibleTagsValues.includes(tagValue.value))
      .map(tagValue => {
        this.visibleTagsIds = [...new Set([...this.visibleTagsIds, tagValue.tagId])];
        return tagValue.id;
      });
    this.setIntersectGeneralConfigLevel();
  }

  setIntersectGeneralConfigLevel() {
    if (!_equal(this.configLevels?.intersect, this.visibleTagsValuesIds)) {
      const selectedValues = this.selectedTags.flatMap(tag => tag.id);
      this.visibleTagsValuesIds = [...new Set([...this.visibleTagsValuesIds, ...selectedValues])];
      this.configLevels = { ...this.configLevels, intersect: this.visibleTagsValuesIds };
    }
  }

  getFlatvisibleTagsRoots() {
    let visibleTagsRoots =
      this.visibleTagsRoots?.length && (this.visibleTagsRoots as any)[0]?.id
        ? (this.visibleTagsRoots as any).map(v => v.id)
        : this.visibleTagsRoots;

    return visibleTagsRoots || [];
  }

  noMoreTagsToSelect(): boolean {
    if (!this.userVisibleTagsRoots?.length) return false;
    return this.visibleTagsIds?.length > 0
      ? this.groupTags?.length >= this.visibleTagsIds?.length
      : this.groupTags?.length >= this.allFirstLevelTags?.length;
  }

  //WARNINH: Este método demora considerablemente si el set de datos es grande (sobre todo _aux_filterTagsRootExistByRootId) / DES-2214
  creatGroupTagsFromImputs() {
    if (this.tagTree == null) return;

    if (false) _log(_aux_getTagRootFromId(13, this.tagTree));

    let rv = [];

    //Agrega todos los roots visibles (mandatorios)
    let visibleTagsRoots = this.getFlatvisibleTagsRoots();
    let allMergeTags = _uniqueElements([...visibleTagsRoots, ...(this.userVisibleTagsRoots || [])]);

    allMergeTags.forEach(tagRoot => {
      const rootGroup = rv.find(tag => tag.rootTagId === tagRoot);

      if (!rootGroup) {
        rv.push({ rootTagId: tagRoot, selected: [] });
      }
    });

    rv = _uniqueElementsByKey(rv as any, 'rootTagId');

    //Convert TagTreeSelectedModel[] 2 TagTreeSelectedModelInternal[]
    if (this.selectedTags?.length) {
      this.selectedTags.forEach(tag => {
        const rootId = tag?.id ? _aux_getTagRootFromId(tag.id, this.tagTree) : null;
        if (!rootId) return;

        let newEl = null;
        let rootGroup = rv.find(tag => tag.rootTagId === rootId);

        if (!rootGroup) {
          newEl = { rootTagId: rootId, selected: [] };
          rootGroup = newEl;
        }

        rootGroup.selected = [...(rootGroup.selected || []), { id: tag.id }];
        rootGroup.selected = _uniqueElementsByKey(rootGroup.selected, 'id');

        if (newEl) rv.push(newEl);
      });
    }

    rv = _uniqueElementsByKey(rv as any, 'rootTagId');

    const rvFiltered = _aux_filterTagsRootExistByRootId(rv, this.tagTree);

    this.makeRequieredTags();

    if (_equal(_orderBy(rvFiltered, ['id']), _orderBy(this.groupTags, ['id']))) return;

    this.groupTags = rvFiltered;
    if (this.tagsModuleType === 2) this.groupTags.reverse();
    if (true && _isDev()) _log('[creatGroupTagsFromImputs]', this.groupTags?.length);

    this.onChangeList.emit(true);
  }

  makeRequieredTags() {
    let rv = [];

    (this.visibleTagsRoots || []).forEach(obj => {
      if (obj?.isRequired) rv.push(obj.id);
    });

    if (!_equal(rv, this.visibleTagsRootsRequired)) {
      this.visibleTagsRootsRequired = rv;
    }
  }

  changeSelected(ev: TagTreeSelectedModel[], key) {
    if (this.groupTags && this.groupTags[key]) {
      this.groupTags[key].selected = ev;
      this.convertAndemitSelecteds();
    }
  }

  convertAndemitSelecteds() {
    let rv_plainIds: TagTreeSelectedModel[] = [];

    //Convert TagTreeSelectedModelInternal[] 2 TagTreeSelectedModel[]
    (this.groupTags || []).forEach(tag => {
      (tag?.selected || []).forEach($sel => {
        if ($sel?.id == null) return;

        let sel = $sel;
        if (sel.value == null) {
          sel = _cloneDeep($sel);
          let val = _aux_getTagItemObjFromId([sel.id], this.tagTree);
          if (val && val[0]) sel.value = val[0].value;
        }

        rv_plainIds = [...rv_plainIds, sel];
      });
    });

    rv_plainIds = _uniqueElementsByKey(rv_plainIds as any, 'id');

    if (_equal(_orderBy(rv_plainIds, ['id']), _orderBy(this.selectedTags, ['id']))) return;

    this.emitChanges(rv_plainIds);
  }

  @_debounceDecorator(0)
  emitChanges(rv_plainIds) {
    if (true && this.verbose) _log('\n\n[emitSelected Multiple Tags]', { groupTags: this.groupTags, plainIds: rv_plainIds });
    this.onChange.emit(rv_plainIds);
    const rv_group = _aux_tagTreeGroupFormat(this.groupTags);
    this.onChangeGroup.emit(rv_group);
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.$unsuscribreAll.next();
    this.$unsuscribreAll.complete();
  }

  ngAfterViewInit(): void {}

  openNewtagDialog() {
    this.dialog
      .open(GenericTagsSelectMultiplev2AttributeModal, {
        width: '550px',
        data: {
          tagsModuleType: this.tagsModuleType,
          alreadyAdded: this.getFlatvisibleTagsRoots(),
          excludeList: this.visibleTagsIds || [],
          selected: this.userVisibleTagsRoots,
        },
      })
      .afterClosed()
      .pipe(first())
      .subscribe(data => {
        if (true) _log('AttributeModal data:', data);
        if (data !== null) {
          this.userVisibleTagsRoots = data;
          this.creatGroupTagsFromImputs();
        }
      });
  }

  filterTagsValues(toogle: boolean) {
    if (toogle) {
      if (this.visibleTagsValues?.length > 0) {
        this.setTagValuesIdsByTagValueNames();
      }
      if (!this.visibleTagsValues && this.visibleTagsValuesIds?.length > 0) {
        this.setIntersectGeneralConfigLevel();
      }
    } else {
      const { intersect, ...rest } = this.configLevels;
      this.configLevels = { ...rest };
    }
    this.tagValuesByResultsOnList = toogle;
    this.onChangeFilterTagValues.emit(toogle);
  }
}
