import { Injectable } from '@angular/core';
import { DisplayedColumnsObject } from './displayed-columns-service';

@Injectable()
export class ExportToCsvFileServices {
  /**
   * Export to .CSV file
   * @param {array}   items array of objects with table rows
   * @param {array}   displayedColumnsObject  array of columns Objects
   * @param   {string}  name  csv file name
   */
  ExportAsCSV(items, displayedColumnsObject: Array<DisplayedColumnsObject>, name) {
    const finalItems = this.RemoveNotDisplayedColumnsFromCsv(items, displayedColumnsObject);

    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(finalItems[0]);
    const csv = finalItems.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'));
    csv.unshift(header.join(';'));
    const csv2 = csv.join('\r\n');
    const BOM = '\uFEFF';
    const csv3 = BOM + csv2;
    this.SaveAs(new Blob([csv3], { type: 'text/csv' }), name);
  }

  /**
   * Function to make sure that only screen displayed columns are added to csv
   * @param {array}   data        array of objects with table rows
   * @param   {array}   displayedColumnsObject    array of allowed columns
   * @return  {array}
   */
  RemoveNotDisplayedColumnsFromCsv(data: any, displayedColumnsObject: Array<DisplayedColumnsObject>) {
    const resp = [];

    data.forEach((objVal, objKey) => {
      const item = {};
      displayedColumnsObject.forEach((colVal, colKey) => {
        // Consulta si la columna debe mostrarse "showColumnCsv: true"
        if (colVal.showColumnCsv) {
          item[colVal.columnCsv] = data[objKey][colVal.columnValue];
        }
      });
      resp.push(item);
    });

    return resp;
  }

  /**
   * Invokes a save dialog for a Blob or an Url object or strings target.
   * @param  {Blob|Url|string}  content  The Blob or url to save.
   * @param  {string}           name     The suggested file name.
   */
  private SaveAs(content, name) {
    const isBlob = typeof content.type === 'string';

    const date = new Date().getDate().toString() + '-' + (new Date().getMonth() + 1).toString() + '-' + new Date().getFullYear().toString();
    name += '_' + date + '.csv';

    const link = Object.assign(window.document.createElement('a'), {
      download: name,
      target: '_blank', // fallback
      href: isBlob ? window.URL.createObjectURL(content) : content,
    });
    this.ClickElement.call(window, link);
    isBlob &&
      setTimeout(() => {
        window.URL.revokeObjectURL(link.href);
      }, 1000);
  }

  private ClickElement(element) {
    const evt = window.document.createEvent('MouseEvents');
    evt.initEvent('click', true, true);
    element.dispatchEvent(evt);

    return element;
  }
}
