import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { AttributeItemModel } from 'core/models/attribute.model';
import { OnChanges, OnDestroy, EventEmitter, Input, Output, SimpleChanges, Directive } from '@angular/core';
import { PayloadToSendTags, TagAttributeModel } from './model/dinamicTagsModel';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { IanAddAttributeModalComponent, ModalDataAddAttribute } from '../ian-add-attribute-modal/ian-add-attribute-modal.component';
import { _containNormaliceStrings } from '@shared/aux_helper_functions';
import { first } from 'rxjs/operators';
import { _log } from '@shared/aux_helper_environment';

export const DES_1161_DISABLE_TRADUCTION = true;

export const dinamicTagsAttributeName = (attribute: AttributeItemModel | TagAttributeModel, translate = null): string => {
  let name = attribute.name;
  if (!DES_1161_DISABLE_TRADUCTION && translate) {
    const translateKey = 'GLOBAL.' + attribute.name;
    name = translate.instant(translateKey, null, attribute.name);
  }
  return name;
};

@Directive()
export class DinamicTagsComponent implements OnChanges, OnDestroy {
  form: UntypedFormGroup;
  subscribers: any = {};
  _lookUpB = '';
  _lookUp = '';

  // lista de todos los tags, se recibe por endpoint
  allTags: Array<AttributeItemModel>;

  // lista de los tags obligatorios
  requiredTags: Array<AttributeItemModel>;

  // lista con todos los tags para usar en el front // id_sk3ds para el nuevo componente tiene que ser ItemTagsSelection y era AttributeItemModel
  selectedTags: Array<any>;

  // lista con todos los tags para pedir el uso
  avaibleTags: Array<AttributeItemModel>;

  preSelectedTags: Array<PayloadToSendTags>;

  contains = _containNormaliceStrings;

  formControlList = [];

  private unsuscribeAll: Subject<void> = new Subject<void>();

  @Input() tagsList: any;
  @Input('activeTags') activeTags: any;
  @Input('multipleSelection') multipleSelection = true;
  @Output() dinamicTagsForm: EventEmitter<Array<PayloadToSendTags>> = new EventEmitter();
  @Output() resetTagsForm: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    public router: Router,
    public fBuilder: UntypedFormBuilder,
    public translate: IanTranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tagsList']) {
      if (this.isLoading()) {
        if (this.activeTags.length === 0) {
          this.initTags();
        } else {
          this.initWithPreselected();
        }
      }
    }
  }

  createForm() {
    this.form = null;
    this.form = this.fBuilder.group({
      query: '',
    });
  }

  initList() {
    this.allTags = [];
    this.requiredTags = [];
    this.avaibleTags = [];
    this.selectedTags = [];
    this.preSelectedTags = [];
  }

  initForm() {
    this.setFormControlList();
    this.selectedTags.map(tag => this.initFormControlName(tag));
  }

  initFormPreselected() {
    this.setFormControlList();
    this.selectedTags.map(tag => this.initFormControlNameWithPreselected(tag));
  }

  isLoading() {
    return this.tagsList && this.tagsList.length > 0;
  }

  private setTagsList() {
    if (this.isLoading()) {
      const _requiredTags = this.tagsList.filter(x => x?.isRequired);
      const _avaibleTags = this.tagsList.filter(x => !x?.isRequired && x?.values?.length > 0);
      this.allTags = this.tagsList;
      this.requiredTags = _requiredTags;
      this.selectedTags = _requiredTags;
      this.avaibleTags = _avaibleTags;
    }
  }

  setTagsListWithPreselected() {
    if (this.isLoading()) {
      const _requiredTags = this.tagsList.filter(x => x?.isRequired);
      const _selectedTags = this.tagsList.filter(x => this.preSelectedTags?.some?.(tag => tag?.tagId === x?.id));
      const _avaibleTags = this.tagsList.filter(x => !this.preSelectedTags?.some?.(tag => tag?.tagId === x?.id) && x?.values?.length > 0);
      this.allTags = this.tagsList;
      this.requiredTags = _requiredTags;
      this.selectedTags = _selectedTags;
      this.avaibleTags = _avaibleTags;
    }
  }

  // TODO: initFormPreselected tiene que funcionar con el que esta definido en el hijo id_2l3ks
  initWithPreselected() {
    this.initList();
    this.preSelectedTags = this.activeTags;
    this.setTagsListWithPreselected();
    this.createForm();
    this.initFormPreselected();
  }

  // TODO: initForm tiene que funcionar con el que esta definido en el hijo id_2l3ks2
  initTags() {
    // vuelve a estado inicial listados
    this.initList();
    // seteas el formulario si existe lista de tags
    this.setTagsList();
    // crea el formulario y el grupo que contiene los controllers
    this.createForm();
    // inicializa cada controller para los inputs activos
    this.initForm();
    // reseteas el formulario
    this.form.reset();
  }

  public reset() {
    this.initTags();
    // forza a resetear el filtro avanzado completo en la pantalla padre
    this.emitResetTagsForm();
  }

  isDisabledAddtags(): boolean {
    const count = this.avaibleTags ? this.avaibleTags.length : 0;
    return count !== 0 ? false : true;
  }

  //#region Atributos dinamico
  openNewtagDialog(nonRequiredAttributes: ModalDataAddAttribute): void {
    const dialogRef = this.dialog.open(IanAddAttributeModalComponent, {
      width: '550px',
      data: nonRequiredAttributes,
    });

    this.subscribers.dialog$ = dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(data => {
        if (data === 'add' && dialogRef.componentInstance.selection) {
          dialogRef.componentInstance.selection.forEach(attribute => {
            this.DinamicTagsAddUserRequiredAttribute(attribute);
            this.initFormControlName(attribute);
            this.emitForm();
          });
        }
      });
  }
  protected DinamicTagsAddUserRequiredAttribute(attribute) {
    const allTags = this.allTags;
    const currentTags = this.selectedTags;
    this.selectedTags = [...currentTags, attribute];
    this.avaibleTags = allTags.filter(x => !this.selectedTags.some(selectedTag => selectedTag.id === x.id));
  }

  /**
   * getFormControlName Setea el nombre del control de form acorde al atributo.
   * @param id
   */
  getFormControlName(attribute: AttributeItemModel): string {
    const rv = `${attribute.id}_${attribute.name}`;
    return rv;
  }

  setFormControlList() {
    this.formControlList = [];
    // Usa una función de flecha para mantener el contexto de la clase
    Object.keys(this.form.controls).map(index => {
      this.formControlList.push(index);
    });
  }

  /**
   * initFormControl crea el FormController para el tag enviado
   * @param AttributeItemModel
   */
  initFormControlName(attribute: AttributeItemModel): void {
    const rv = `${attribute.id}_${attribute.name}`;
    this.form.addControl(rv, new UntypedFormControl([]));
    if (this.formControlList.length > 0) {
      this.form.addControl(rv, new UntypedFormControl([]));
    } else {
      if (!this.formControlList.some(control => control.name === rv)) this.form.addControl(rv, new UntypedFormControl([]));
    }
  }

  initFormControlNameWithPreselected(attribute: AttributeItemModel): void {
    const rv = `${attribute.id}_${attribute.name}`;
    if (this.preSelectedTags.length !== 0) {
      const tag = this.preSelectedTags.find(x => x.tagId === attribute.id);
      //si es single option solo enviamos el primer valor del listado
      let values = null;
      if (this.multipleSelection && tag && tag.tagValues && tag.tagValues.length > 0) values = tag.tagValues;
      if (!this.multipleSelection && tag && tag.tagValues && tag.tagValues.length > 0) values = tag.tagValues[0];
      this.form.addControl(rv, new UntypedFormControl({ value: tag !== undefined ? values : null, disabled: false }));
    } else {
      this.form.addControl(rv, new UntypedFormControl([]));
    }
  }

  getPlaceholder(attribute: AttributeItemModel): string {
    return dinamicTagsAttributeName(attribute, this.translate);
  }

  getTagName(attribute: AttributeItemModel): string {
    if (!attribute) return '';
    const name = (attribute.name || '').toLowerCase();
    return name;
  }

  getPlaceholderLookUp(attribute: AttributeItemModel): string {
    const name = this.getPlaceholder(attribute);
    const buscar = this.translate.instant('PR.STP.RULES.SEARCH');
    return (buscar + ' ' + name).replace(/\s\s/g, ' ');
  }

  // modelado con PayloadToSendTags
  _mapAuxFormToPayloadTags(form: UntypedFormGroup): any {
    const _tags = form.value;
    const payloadTags = [];
    const self = this;
    Object.keys(_tags).map(function (index) {
      let optionsSelected = _tags[index];
      // si es single option lo envio igual como un listado de una opcion
      if (optionsSelected) optionsSelected = self.multipleSelection ? optionsSelected : [optionsSelected];
      const indexSplited = index.split('_');
      const _index = indexSplited[0];
      if (optionsSelected) payloadTags.push({ tagId: Number(_index), tagValues: optionsSelected });
    });
    if (true) _log('[_mapAuxFormToPayloadTags]', { _tags, payloadTags });
    return payloadTags;
  }

  emitForm() {
    let form = this.form;
    delete form.value['query'];
    const data = this._mapAuxFormToPayloadTags(form);
    data.query = this.form.controls['query'].value;
    this.dinamicTagsForm.emit(data);
  }

  emitResetTagsForm() {
    this.emitForm();
    this.resetTagsForm.emit(true);
  }

  close(): void {}
  ngOnDestroy(): void {
    this.unsuscribeAll.next();
    this.unsuscribeAll.complete();
  }
}
