import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { IanTranslateService } from './ian-core-singleton.service';

@NgModule({
  imports: [CommonModule],
  providers: [IanTranslateService],
})
export class IanCoreSingletonsModule {
  static forRoot(): ModuleWithProviders<IanCoreSingletonsModule> {
    return {
      ngModule: IanCoreSingletonsModule,
      providers: [{ provide: 'IanTranslate', useClass: IanTranslateService }],
    };
  }
  constructor(@Optional() @SkipSelf() parentModule: IanCoreSingletonsModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
