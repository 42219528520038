import { Injectable } from '@angular/core';
import { _isDev, _log } from '@shared/aux_helper_environment';
import { OidcSecurityStorage } from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';

const _devTest = false;
const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';

const OidcSecurityStorageKeys = [
  '_isAuthorized',
  'authNonce',
  'authStateControl',
  'authorizationData',
  'authorizationDataIdToken',
  'authorizationResult',
  'code_verifier',
  'session_state',
  'storage_silent_renew_running',
  'userData',
];

const _writeLS = (key: string, value: any): void => {
  if (!key || key.length === 0) {
    return null;
  }

  if (value !== null && typeof value === 'object' && typeof value !== 'string') {
    value = JSON.stringify(value);
  }

  if (_devTest) {
    _log('%c[localStorage.setItem(codeUrl]', 'color:#c792ea', { key, value });
  }

  localStorage.setItem(_keyVer + key, value);
};

const _readLS = (key: string): any => {
  if (!key || key.length === 0) {
    return null;
  }

  let rv = localStorage.getItem(_keyVer + key);

  try {
    rv = JSON.parse(rv);
  } catch (e) {}

  if (_devTest) {
    _log('%c[localStorage.getItem(codeUrl]', 'color:#c792ea', { key, rv });
  }

  return rv;
};

const getStoreAuthAsObj = () => {
  let rv = {};

  [...OidcSecurityStorageKeys].forEach(k => {
    rv[_keyVer + k] = _readLS(k.replace(_keyVer, ''));
  });

  return rv;
};

const setStoreAuthAsObj = $obj => {
  let obj = typeof $obj === 'string' ? JSON.parse($obj) : $obj;
  if (!obj) return;

  for (let [key, value] of Object.entries(obj)) {
    if ([...OidcSecurityStorageKeys].includes(key.replace(_keyVer, ''))) {
      _writeLS(key.replace(_keyVer, ''), value);
    }
  }
};

if (true && _isDev()) {
  let win = window as any;
  if (win) {
    win._getStoreAuthAsObj = getStoreAuthAsObj;
    win._getStoreAuthAsStr = () => JSON.stringify(getStoreAuthAsObj());
    win._setStoreAuthAsObj = setStoreAuthAsObj;

    win._getenvironmentAsObj = environment;
    win._getenvironmentAsStr = () => JSON.stringify(environment);
  }
}

@Injectable()
export class CustomStorage implements OidcSecurityStorage {
  read(key: string): any {
    return _readLS(key);
  }

  write(key: string, value: any): void {
    _writeLS(key, value);
  }
}
