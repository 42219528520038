/**
 * CanDeactivateGuard
 * @description Guard to prompt user before leaving form component if form is dirty
 * @author Lucas Frecia <lucas.frecia@iantech.net>
 */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CanDeactivate } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { DeactivateFormDialogComponent } from './can-deactivate.dialog/can-deactivate.dialog';
import { ComponentCanDeactivateDirective } from './component-can-destroy-form';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivateDirective> {
  constructor(public dialog: MatDialog) {}

  /**
   * canDeactivate Guards main method, will show dialog if canDeactivate is set to false
   * @param { ComponentCanDeactivateDirective } component
   * @returns { boolean }
   */
  canDeactivate(component: ComponentCanDeactivateDirective): boolean {
    if (component.canDeactivate && typeof component.canDeactivate === 'function' && component.canDeactivate()) {
      return true;
    }

    const dialog: MatDialogRef<DeactivateFormDialogComponent> = this.openDialog();

    const rv: boolean = this.preparedResponse(dialog);

    return rv;
  }

  /**
   * openDialog Open dialog instance if canDeactivate is false
   * @returns { MatDialogRef<DeactivateFormDialogComponent> } rv
   */
  private openDialog(): MatDialogRef<DeactivateFormDialogComponent> {
    const rv = this.dialog.open(DeactivateFormDialogComponent, {
      width: '550px',
      panelClass: ['top-align'], // TODO: LF -> Class is in styles.scss -> for some reason it does not find the clas if added to can-deactivate.dialog.scss
    });

    return rv;
  }

  /**
   * preparedResponse Converts the observable result to a boolean value to feed canDeactivate()
   * @param { MatDialogRef<DeactivateFormDialogComponent> } dialog
   * @returns { MatDialogRef<DeactivateFormDialogComponent> } rv
   */
  private preparedResponse(dialog: MatDialogRef<DeactivateFormDialogComponent>): boolean {
    const rv = dialog.afterClosed().pipe(
      first(),
      map(result => result)
    ) as any;

    return rv;
  }
}
