import { Component, Inject, OnInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-operation-success-modal',
  templateUrl: './operation-success-modal.component.html',
  styleUrls: ['./operation-success-modal.component.scss'],
})
export class OperationSuccessModalComponent implements OnInit {
  @Input() title = 'COMP.OPERATION_SUCCESS.TITLE';
  @Input() subtitle = 'COMP.OPERATION_SUCCESS.SUBTITLE';

  constructor(public dialogRef: MatDialogRef<OperationSuccessModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
    this.title = data?.title || 'COMP.OPERATION_SUCCESS.TITLE';
    this.subtitle = data?.subtitle || 'COMP.OPERATION_SUCCESS.SUBTITLE';
  }
  close(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {}
}
