const convertStringToDate = dateString => {
  let fechaArray = dateString.split('/');
  return new Date(fechaArray[2] + '-' + fechaArray[1] + '-' + fechaArray[0]);
};

const forcedPeriod = [
  {
    code: 'P1',
    name: 'Periodo 1',
    startingDayOfWeek: 4,
    durationInDays: 21,
    promotionTypeId: 1,
    promotionType: null,
    validFrom: '2023-01-05T03:00:00+00:00',
    validTo: '2023-01-26T03:00:00+00:00',
    order: 1,
    isSpecialEvent: false,
    extraPlanningDays: 7,
    createdBy: 'PRISMA',
    createdOn: '2023-04-12T16:16:12.502653+00:00',
    updatedBy: 'PRISMA',
    updatedOn: '2023-04-12T16:16:12.502653+00:00',
    id: 1,
    isDeleted: false,
    domainEvents: null,
  },
  {
    code: 'P2',
    name: 'Periodo 2',
    startingDayOfWeek: 4,
    durationInDays: 21,
    promotionTypeId: 1,
    promotionType: null,
    validFrom: '2023-01-26T03:00:00+00:00',
    validTo: '2023-02-16T03:00:00+00:00',
    order: 2,
    isSpecialEvent: false,
    extraPlanningDays: 7,
    createdBy: 'PRISMA',
    createdOn: '2023-04-12T16:16:12.502653+00:00',
    updatedBy: 'PRISMA',
    updatedOn: '2023-04-12T16:16:12.502653+00:00',
    id: 2,
    isDeleted: false,
    domainEvents: null,
  },
  {
    code: 'P3',
    name: 'Periodo 3',
    startingDayOfWeek: 4,
    durationInDays: 28,
    promotionTypeId: 1,
    promotionType: null,
    validFrom: '2023-02-16T03:00:00+00:00',
    validTo: '2023-03-16T03:00:00+00:00',
    order: 3,
    isSpecialEvent: false,
    extraPlanningDays: 0,
    createdBy: 'PRISMA',
    createdOn: '2023-04-12T16:16:12.502653+00:00',
    updatedBy: 'PRISMA',
    updatedOn: '2023-04-12T16:16:12.502653+00:00',
    id: 3,
    isDeleted: false,
    domainEvents: null,
  },
  {
    code: 'P4',
    name: 'Periodo 4',
    startingDayOfWeek: 4,
    durationInDays: 35,
    promotionTypeId: 1,
    promotionType: null,
    validFrom: '2023-03-16T03:00:00+00:00',
    validTo: '2023-04-20T03:00:00+00:00',
    order: 4,
    isSpecialEvent: false,
    extraPlanningDays: 0,
    createdBy: 'PRISMA',
    createdOn: '2023-04-12T16:16:12.502653+00:00',
    updatedBy: 'PRISMA',
    updatedOn: '2023-04-12T16:16:12.502653+00:00',
    id: 4,
    isDeleted: false,
    domainEvents: null,
  },
  {
    code: 'P5',
    name: 'Periodo 5',
    startingDayOfWeek: 4,
    durationInDays: 28,
    promotionTypeId: 1,
    promotionType: null,
    validFrom: '2023-04-20T03:00:00+00:00',
    validTo: '2023-05-18T03:00:00+00:00',
    order: 5,
    isSpecialEvent: false,
    extraPlanningDays: 0,
    createdBy: 'PRISMA',
    createdOn: '2023-04-12T16:16:12.502653+00:00',
    updatedBy: 'PRISMA',
    updatedOn: '2023-04-12T16:16:12.502653+00:00',
    id: 5,
    isDeleted: false,
    domainEvents: null,
  },
  {
    code: 'P6',
    name: 'Periodo 6',
    startingDayOfWeek: 4,
    durationInDays: 28,
    promotionTypeId: 1,
    promotionType: null,
    validFrom: '2023-05-18T03:00:00+00:00',
    validTo: '2023-06-15T03:00:00+00:00',
    order: 6,
    isSpecialEvent: false,
    extraPlanningDays: 0,
    createdBy: 'PRISMA',
    createdOn: '2023-04-12T16:16:12.502653+00:00',
    updatedBy: 'PRISMA',
    updatedOn: '2023-04-12T16:16:12.502653+00:00',
    id: 6,
    isDeleted: false,
    domainEvents: null,
  },
  {
    code: 'P7',
    name: 'Periodo 7',
    startingDayOfWeek: 4,
    durationInDays: 28,
    promotionTypeId: 1,
    promotionType: null,
    validFrom: '2023-06-15T03:00:00+00:00',
    validTo: '2023-07-13T03:00:00+00:00',
    order: 7,
    isSpecialEvent: false,
    extraPlanningDays: 0,
    createdBy: 'PRISMA',
    createdOn: '2023-04-12T16:16:12.502653+00:00',
    updatedBy: 'PRISMA',
    updatedOn: '2023-04-12T16:16:12.502653+00:00',
    id: 7,
    isDeleted: false,
    domainEvents: null,
  },
  {
    code: 'P8',
    name: 'Periodo 8',
    startingDayOfWeek: 4,
    durationInDays: 35,
    promotionTypeId: 1,
    promotionType: null,
    validFrom: '2023-07-13T03:00:00+00:00',
    validTo: '2023-08-17T03:00:00+00:00',
    order: 8,
    isSpecialEvent: false,
    extraPlanningDays: 0,
    createdBy: 'PRISMA',
    createdOn: '2023-04-12T16:16:12.502653+00:00',
    updatedBy: 'PRISMA',
    updatedOn: '2023-04-12T16:16:12.502653+00:00',
    id: 8,
    isDeleted: false,
    domainEvents: null,
  },
  {
    code: 'P9',
    name: 'Periodo 9',
    startingDayOfWeek: 4,
    durationInDays: 21,
    promotionTypeId: 1,
    promotionType: null,
    validFrom: '2023-08-17T03:00:00+00:00',
    validTo: '2023-09-07T03:00:00+00:00',
    order: 9,
    isSpecialEvent: false,
    extraPlanningDays: 0,
    createdBy: 'PRISMA',
    createdOn: '2023-04-12T16:16:12.502653+00:00',
    updatedBy: 'PRISMA',
    updatedOn: '2023-04-12T16:16:12.502653+00:00',
    id: 9,
    isDeleted: false,
    domainEvents: null,
  },
  {
    code: 'P10',
    name: 'Periodo 10',
    startingDayOfWeek: 4,
    durationInDays: 28,
    promotionTypeId: 1,
    promotionType: null,
    validFrom: '2023-09-07T03:00:00+00:00',
    validTo: '2023-10-05T03:00:00+00:00',
    order: 10,
    isSpecialEvent: false,
    extraPlanningDays: 0,
    createdBy: 'PRISMA',
    createdOn: '2023-04-12T16:16:12.502653+00:00',
    updatedBy: 'PRISMA',
    updatedOn: '2023-04-12T16:16:12.502653+00:00',
    id: 10,
    isDeleted: false,
    domainEvents: null,
  },
  {
    code: 'P11',
    name: 'Periodo 11',
    startingDayOfWeek: 4,
    durationInDays: 28,
    promotionTypeId: 1,
    promotionType: null,
    validFrom: '2023-10-05T03:00:00+00:00',
    validTo: '2023-11-02T03:00:00+00:00',
    order: 11,
    isSpecialEvent: false,
    extraPlanningDays: 0,
    createdBy: 'PRISMA',
    createdOn: '2023-04-12T16:16:12.502653+00:00',
    updatedBy: 'PRISMA',
    updatedOn: '2023-04-12T16:16:12.502653+00:00',
    id: 11,
    isDeleted: false,
    domainEvents: null,
  },
  {
    code: 'P12',
    name: 'Periodo 12',
    startingDayOfWeek: 4,
    durationInDays: 28,
    promotionTypeId: 1,
    promotionType: null,
    validFrom: '2023-11-02T03:00:00+00:00',
    validTo: '2023-11-30T03:00:00+00:00',
    order: 12,
    isSpecialEvent: false,
    extraPlanningDays: 0,
    createdBy: 'PRISMA',
    createdOn: '2023-04-12T16:16:12.502653+00:00',
    updatedBy: 'PRISMA',
    updatedOn: '2023-04-12T16:16:12.502653+00:00',
    id: 12,
    isDeleted: false,
    domainEvents: null,
  },
  {
    code: 'P13',
    name: 'Periodo 13',
    startingDayOfWeek: 4,
    durationInDays: 35,
    promotionTypeId: 1,
    promotionType: null,
    validFrom: '2023-11-30T03:00:00+00:00',
    validTo: '2024-01-04T03:00:00+00:00',
    order: 13,
    isSpecialEvent: false,
    extraPlanningDays: 0,
    createdBy: 'PRISMA',
    createdOn: '2023-04-12T16:16:12.502653+00:00',
    updatedBy: 'PRISMA',
    updatedOn: '2023-04-12T16:16:12.502653+00:00',
    id: 13,
    isDeleted: false,
    domainEvents: null,
  },
];

export const dummyPeriodsMockup = {
  forcedPeriod,
};
