<h6 class="title">Sugeridos para esta pantalla:</h6>
<button mat-menu-item matTooltip="¿Cómo crear una Regla de Redondeo?" (click)="openVideo('roundingRule')">
  <mat-icon>play_circle_outline</mat-icon>
  ¿Cómo crear una Regla de Redondeo?
</button>
<!-- <button mat-menu-item
        disabled
        routerLink="/helps/price-help/faqs">
    <mat-icon>
        help_outline
    </mat-icon>
    Preguntas Frecuentes
</button> -->
