import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { _log } from '@shared/aux_helper_environment';
import { _cloneDeep, _toNumber, _uniqueElements } from '@shared/aux_helper_functions';
import { ActionsV2_list_service } from 'app/promotionsv2/actions/actions-list/ActionsV2_list.service';
import { EditShelfProp } from 'app/spaces/planograms-editor/store/planogram.store.state';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'shared-promotions-shelf-modal',
  template: `
    <h1 mat-dialog-title>Acciones Promocionales Asociadas</h1>

    <h2 *ngIf="dataObj != null && false" style="font-size: 15px; margin-top:-24px">
      El siguiente listado corresponde a productos inactivos en las tiendas
    </h2>

    <div mat-dialog-content fxLayout="column" class="mt-32">
      <ng-container *ngIf="isLoading()">
        <div class="spinner-container spinner-container-mini">
          <custom-loader></custom-loader>
        </div>
      </ng-container>

      <ng-container *ngIf="!isLoading()">
        <div *ngIf="getCurrentActionsSelected()?.length" class="materialPopList">
          <ng-container *ngFor="let item of getCurrentActionsSelected(); let i = index; trackBy: trackById">
            <mat-card class="materialPopListCard">
              <div class="materialPopListCeld">
                <!-- == -->
                <div style="max-width: 90%;">
                  {{ getPromotionById(item)?.name }}
                </div>
                <!-- == -->
                <button mat-button mat-icon-button color="accent" matTooltip="Eliminar" (click)="deleteRow(i)">
                  <mat-icon class="delete-icon">delete</mat-icon>
                </button>
              </div>
            </mat-card>
          </ng-container>

          <br />
          <br />
        </div>

        <mat-card class="materialAddPopListCard">
          <div>
            <!-- //Tipo de selección -->
            <h2 style="margin-top:0; padding-top:0;">Agregar Acción Promocional</h2>

            <div class="mt-16 mb-16">
              <generic-lookup
                [values]="promotions_list"
                [selected]="selectedToAdd != null ? [selectedToAdd] : null"
                (onChange)="selectedToAdd = $event?.[0]"
                [multiple]="false"
                [showLookup]="true"
                [propId]="'id'"
                [propValue]="'name'"
                placeholder="Acciones Promocionales"
              ></generic-lookup>
            </div>

            <button
              *ngIf="selectedToAdd != null"
              class="addUnitButton"
              style="margin-left: -2px"
              mat-stroked-button
              (click)="addAction()"
              color="accent"
            >
              Agregar
            </button>
          </div>
        </mat-card>
      </ng-container>
    </div>

    <div mat-dialog-actions fxLayoutAlign="end" class="mt-64">
      <button mat-button color="secondary" (click)="close(false)" [attr.data-test-id]="'cancel_clone_item'">
        {{ 'GENERIC_CLOSE' | pTranslate }}
      </button>

      <button mat-raised-button color="accent" [disabled]="this.newValActions == null" (click)="save()">
        {{ 'GENERIC_SAVE' | pTranslate }}
      </button>
    </div>
  `,

  styles: [
    `
      .toolTipOnInput {
        position: absolute;
        right: 0;
        font-size: 18px;
      }

      .popover {
        position: relative;
        margin-right: 0px !important;
        display: inline-block;
        pointer-events: visible;
      }

      .materialPopListCeld {
        margin: 0px 0;
      }

      .materialPopListCeld {
        flex-flow: row wrap;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
      }

      ::ng-deep .materialPopListCard,
      ::ng-deep .materialAddPopListCard {
        padding: 10px 16px !important;
        margin: 8px 0;
        box-shadow: 0px 0px 3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 8%), 0px 1px 2px rgb(0 0 0 / 6%) !important;
        border: solid 1px #00000014 !important;
      }

      ::ng-deep .materialAddPopListCard {
        padding: 16px !important;
      }

      ::ng-deep .mat-select-panel {
        min-width: 560px !important;
      }
    `,
  ],
})
export class SharedPromotionsShelfModal implements OnInit {
  dataObj: any = null;

  promotions: any = null;
  promotionsIds: any = null;

  parentModuleId: any = null;
  shelfId: any = null;

  promotions_list: any = null;
  promotions_list_map: any = new Map();

  selectedToAdd = null;
  newValActions = null;

  trackById = item => item.id;

  constructor(
    public dialogRef: MatDialogRef<SharedPromotionsShelfModal>,
    private service: ActionsV2_list_service,
    public store: Store,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = false;

    this.dataObj = _cloneDeep(data);
    this.shelfId = this.dataObj?.shelfId;
    this.parentModuleId = this.dataObj?.parentModuleId;

    this.promotions = this.dataObj?.promotions;
    this.promotionsIds = (this.promotions || []).map(el => {
      return el.actionId;
    });

    if (true)
      _log('[ActionsV2PromotionsCoverageDetailModal]', {
        dataObj: this.dataObj,
        parentModuleId: this.parentModuleId,
        promotions: this.promotions,
        promotionsIds: this.promotionsIds,
      });
  }

  isLoading() {
    return this.promotions_list == null;
  }

  ngOnInit(): void {
    this.getPromotionList();
  }

  getPromotionList() {
    return this.service
      .getListItems()
      .pipe(delay(256))
      .subscribe(data => {
        this.promotions_list = data?.items || [];

        this.promotions_list.map(el => {
          this.promotions_list_map.set(String(el.id), el);
        });
      });
  }

  getPromotionById(id) {
    return this.promotions_list_map.get(String(id));
  }

  close(val): void {
    this.dialogRef.close(val);
  }

  save(): void {
    this.saveToStore();

    this.dialogRef.close(this.getCurrentActionsSelected());
  }

  saveToStore(): void {
    let newVal = [...this.getCurrentActionsSelected()];

    let newValObj = newVal.map(id => {
      return { moduleLevelId: this.parentModuleId, actionId: _toNumber(id) };
    });

    this.store.dispatch(new EditShelfProp(this.shelfId, 'promotions', newValObj));
  }

  deleteRow(index) {
    let newVal = [...this.getCurrentActionsSelected()];

    delete newVal[index];

    newVal = newVal.filter(el => el != null);

    _log('[deleteRow]', newVal);

    this.newValActions = _uniqueElements(newVal);

    this.selectedToAdd = null;
  }

  getCurrentActionsSelected() {
    if (this.newValActions == null) return this.promotionsIds;

    return this.newValActions;
  }

  addAction() {
    let newVal = [...this.getCurrentActionsSelected(), this.selectedToAdd].filter(el => el != null);

    _log('[addAction]', newVal);

    this.selectedToAdd = null;

    this.newValActions = _uniqueElements(newVal);
  }
}
