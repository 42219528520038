import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericConfirmationModalComponent } from '@prisma/components/generic-confirmation-modal/generic-confirmation-modal.component';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { DevSettingsService } from 'core/services/dev-settings.service';
import { _log } from '@shared/aux_helper_environment';

@Component({
  selector: 'global-dev-settings-local-storage',
  template: `
    <ng-container>
      <mat-selection-list [multiple]="false">
        <mat-list-option (click)="reset()"> Reset Local Storage </mat-list-option>
      </mat-selection-list>
    </ng-container>
  `,
  styles: [
    `
      :host ::ng-deep .mat-list-item-content {
        padding: 0px !important;
      }

      :host ::ng-deep .mat-list-base {
        padding-top: 0px !important;
      }

      :host ::ng-deep .mat-list-option {
        background: transparent !important;
      }
    `,
  ],
})
export class GlobalSettingsLocalStorageComponent implements OnInit {
  lang = null as any;

  constructor(private translate: IanTranslateService, private devSettingsService: DevSettingsService, public dialog: MatDialog) {
    this.setLangs();
  }

  async ngOnInit() {}

  setLangs() {
    if (this.lang != null) return;

    this.lang = {};
  }

  clipboardWriteText(text) {
    (navigator as any).clipboard?.writeText?.(text);
  }

  async promptConfirmation() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '550px',
      data: {
        message: 'Para cambiar este seteo hace falta reiniciar la aplicación',
        title: 'Aviso',
        labelConfirm: 'Reiniciar',
        labelCancel: 'Cancelar',
        disableClose: true,
        timeToConfirm: 720,
      },
    });

    return dialogRef.afterClosed().toPromise();
  }

  test() {
    _log('TEST');
  }

  async reset() {
    let confirm = await this.promptConfirmation();
    if (!confirm) return;
    this.devSettingsService?.__resetLocalStorage?.(true);
  }
}
