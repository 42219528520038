import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { PrismaDynamicEnv } from 'core/services/ian-core-singleton.service';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { _log } from '@shared/aux_helper_environment';
import { DashBoardState } from 'app/home/dashboard/store/dashboard.state';
const JSON5 = require('json5');

@Component({
  selector: 'app-dyn-report',
  templateUrl: './dyn-report.component.html',
  styleUrls: ['./dyn-report.component.scss'],
})
export class DynReportComponent implements OnInit, OnChanges {
  @Input()
  id: string;

  urlLoaded = false;

  urlSafe: SafeResourceUrl;
  private _reports: [{ id: string; description: string; enable: boolean; url: string }];
  private report: { id: string; description: string; enable: boolean; url: string };

  constructor(
    private sanitizer: DomSanitizer,
    private prismaDynamicEnv: PrismaDynamicEnv,
    private activeRoute: ActivatedRoute,
    private store: Store
  ) {}

  ngOnInit(): void {
    if (this.id == null) this.getReport();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['id']) {
      if (this.id === DashboardTypes.customParams) this.getCustomDashboard();
      if (this.id === DashboardTypes.reports) this.getReport();
    }
  }

  getReport() {
    this._reports = this.prismaDynamicEnv.getConf('brandCustomization.reports');
    _log('[getReport]', { _reports: this._reports, 'this.id': this.id });

    this.activeRoute.params.subscribe(routeParams => {
      let params = routeParams.params;
      this.setReport(routeParams.id, params);
    });
  }

  getCustomDashboard() {
    let urlFilters = this.activeRoute.snapshot.params.urlFilters;
    // si tengo parametros, reviso tener la url del reporte a consultar.
    if (urlFilters) {
      let params = JSON5.parse(urlFilters);
      // verifico tener url en store, en caso de tener este parametro en url
      if (params?.hasUrl === true) this.store.select(DashBoardState.getUrl).subscribe(url => this.setReportUrl(url));
    }
  }

  setReport(reportId, params = null) {
    if (this.id) {
      this.report = this._reports.find(x => x.id === this.id);
    } else {
      if (reportId) {
        this.report = this._reports.find(x => x.id === reportId);
      } else {
        throw new Error('Report non existent');
      }
    }
    if (!this.report) {
      throw new Error('Report non existent');
    }

    let url = params != null ? this.report.url + params : this.report.url;

    if (true) _log('[setReport]', this.report, { reportId, 'this.id': this.id, url, params });

    if (true && url.at(0) === '/') url = window.location.origin + url;
    if (false) url = url.replace('http://', 'https://');

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    this.urlLoaded = true;

    if (true) _log(['urlSafe'], { urlSafe: this.urlSafe, url });
  }

  setReportUrl(url) {
    if (true && url.at(0) === '/') url = window.location.origin + url;
    if (false) url = url.replace('http://', 'https://');

    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);

    this.urlLoaded = true;

    if (true) _log(['urlSafe'], { urlSafe: this.urlSafe, url });
  }
}

export enum DashboardTypes {
  customParams = 'CUSTOMDASHBOARD',
  reports = 'DASHBOARD',
}
