const list = {
  items: [
    {
      id: 6,
      name: 'Gaseosas Principal',
      type: 'Góndola',
      lastModif: '5/7/2019',
      categories: 2,
      charge: 75,
      front: 4.9,
      linealMeters: 24.5,
      layout: 3,
      store: 81,
      sku: 192,
      sales: 2698.2,
      var: 0.3,
    },
    {
      id: 9,
      name: 'Almacén Principal',
      type: 'Góndola',
      lastModif: '7/5/2019',
      categories: 6,
      charge: 51,
      front: 3.3,
      linealMeters: 13,
      layout: 2,
      store: 48,
      sku: 38,
      sales: 10117.8,
      var: -0.02,
    },
  ],
  totalItemCount: 2,
  pageSize: 10,
};

export const dummyPlanogramsList = {
  list,
};
