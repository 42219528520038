import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { Select, Store } from '@ngxs/store';
import { _getEnv, _isDev, _isFeDev, _log } from '@shared/aux_helper_environment';
import { _debounceDecorator, _prettyDateEsMem, _timeout } from '@shared/aux_helper_functions';
import { Observable } from 'rxjs';
import { MarkReadNotification, NotificationsState, MarkReadAllNotification } from './notifications-panel.state';
import { NotificationModel } from './notifications-panel.model';
import { PrismaDynamicEnv, IanTranslateService } from 'core/services/ian-core-singleton.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from './notifications-panel.service';
import { MessagingService } from 'app/services/messaging.service';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationsComponent implements OnInit {
  @Select(NotificationsState.getNotificationsNew)
  notifications$: Observable<NotificationModel>;

  @Select(NotificationsState.getNotificationsNewSize)
  notificationsSize$: Observable<number>;

  @Select(NotificationsState.getNotificationsNewSize)
  notificationsNewSize$: Observable<number>;

  @Select(NotificationsState.getOldNotifications)
  oldNotifications$: Observable<NotificationModel>;

  @Select(NotificationsState.getOldNotificationsSize)
  oldNotificationsSize$: Observable<number>;

  constructor(
    private store: Store,
    private _fuseSidebarService: FuseSidebarService,
    private prismaDynamicEnv: PrismaDynamicEnv,
    private toastrService: ToastrService,
    private translate: IanTranslateService,
    private notificationServicem: NotificationService,
    private messagingService: MessagingService,
    private cdRef: ChangeDetectorRef
  ) {}

  public _versionData: string = [`FE v: ${_getEnv('_versionFE')}`, `BE v: ${this.prismaDynamicEnv.getConf('versions.backEnd')}`].join(
    '<br>'
  );

  public isDev = _isDev();

  oldNotifShowList = false;

  notifPanelOpened = false;

  translates = {} as any;

  ngOnInit(): void {
    this.markForCheckDelayed();

    // id:of0O_o2aha
    this._fuseSidebarService.getSidebar('notificationsPanel').openedChanged.subscribe(data => {
      this.notifPanelOpened = data;
      if (this.notifPanelOpened) this.setTranslates();
      if (data) this.toastrService.clear();
      this.markForCheckDelayed();
    });
  }

  setTranslates() {
    //Las traducciones se setean acá y de esta manera, pq cuando se instancia el componente todavía no está definido el idioma
    this.translates.TITLE = this.translate.instant('NOTIFICATIONS.TITLE');
    this.translates.NO_NEW_ITEMS = this.translate.instant('NOTIFICATIONS.NO_NEW_ITEMS');
    this.translates.MARK_AS_READ = this.translate.instant('NOTIFICATIONS.MARK_AS_READ');
    this.translates.MARK_ALL_AS_READ = this.translate.instant('NOTIFICATIONS.MARK_ALL_AS_READ');
    this.translates.VIEW_LAST = this.translate.instant('NOTIFICATIONS.VIEW_LAST');
    this.translates.CLOSE_LAST = this.translate.instant('NOTIFICATIONS.CLOSE_LAST');
    this.translates.NO_OLD_ITEMS = this.translate.instant('NOTIFICATIONS.NO_OLD_ITEMS');
    this.translates.NOT_ENABLED = this.translate.instant('NOTIFICATIONS.NOT_ENABLED');
    this.translates.DOWNLOAD = this.translate.instant('NOTIFICATIONS.DOWNLOAD');
    this.translates.TOGO = this.translate.instant('NOTIFICATIONS.TOGO');
  }

  date2dateago(date: any, format = true): string {
    const dateStr = _prettyDateEsMem(new Date(date || ''));

    this.markForCheckDelayed();

    return format ? dateStr.charAt(0).toUpperCase() + dateStr.slice(1) + '.' : dateStr;
  }

  @_debounceDecorator(64)
  markForCheckDelayed() {
    if (this.notifPanelOpened && this.cdRef) {
      this.cdRef.markForCheck();
    }
  }

  date2string(date: any): string {
    return new Date(date || '').toString();
  }

  closeNotifications(): void {
    if (true) {
      setTimeout(() => {
        this._fuseSidebarService.getSidebar('notificationsPanel') && this._fuseSidebarService.getSidebar('notificationsPanel').close();
      }, 640);
    }
  }

  markAsReady(item): void {
    item._readed = true;
    this.store.dispatch(new MarkReadNotification(item));
  }

  markAllAsReady(): void {
    this.store.dispatch(new MarkReadAllNotification());
  }

  refreshBENotificatioons(): void {
    this.notificationServicem.setNotifItemsFromBE();
  }

  getAlertEnabledNotifications(): boolean {
    if (false && _isFeDev()) return true;

    return this.messagingService.getAlertEnabledNotifications();
  }

  _printMsgInfo() {
    console.log('\n\n\n', {
      hasFirebaseRequestPermissionToken: this.messagingService.getHasFirebaseRequestPermissionToken(),
      hasNotificationPermission: this.messagingService.getHasNotificationPermission(),
      token: this.messagingService.getToken(),
      firebaseRequestPermissionError: this.messagingService.getFirebaseRequestPermissionError(),
      hasNotificationPermissionStatus: window?.Notification?.permission,
      ver: (window as any)?._info?._environment?._versionFEpck,
    });
  }
}
