<div class="navbar-header" fxLayout="row" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
  <!-- <div class="logo"
         fxFlex
         fxLayout="row"
         fxLayoutAlign="start center">
        <img class="logo-icon"
             src="assets/isotipo_prisma.png">
        <div fxFlex
             fxLayout="row"
             fxLayoutAlign="center center">
            <span class="logo-text">prisma</span>
        </div>
    </div> -->

  <div class="logo" fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <img class="logo-icon" src="assets/isotipo_prisma.png" />
    <span class="logo-text">prisma</span>
  </div>

  <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
    <mat-icon>menu</mat-icon>
  </button>

  <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
    <mat-icon>arrow_back</mat-icon>
  </button>
</div>

<div class="navbar-content noOverflowX" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
  <fuse-navigation layout="vertical"></fuse-navigation>
</div>
