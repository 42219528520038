import { ApplicationRef, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { _getEnv, _isDev, _isLab, _isStaging, _log } from '@shared/aux_helper_environment';
import { _compareNormaliceStrings, _get } from '@shared/aux_helper_functions';
import { getEnabledBlockVal } from '@shared/disabled_blocks';
import { ConsoleLogger } from 'core/services/console_logger.service';
import { PrismaDynamicEnv } from 'core/services/ian-core-singleton.service';

@Component({
  selector: 'app-error-message-modal',
  templateUrl: './error-message-modal.component.html',
  styleUrls: ['./error-message-modal.component.scss'],
})
export class ErrorMessageModalComponent implements OnInit {
  forceMsg = null;
  error = null;
  errorExceptionMessage = null;

  constructor(
    public dialogRef: MatDialogRef<ErrorMessageModalComponent>,
    private cd: ChangeDetectorRef,
    public applicationRef: ApplicationRef,
    private consoleLogger: ConsoleLogger,
    private prismaDynamicEnv: PrismaDynamicEnv,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (false) _log('[ErrorMessageModalComponent]', data, data?.error);

    if (data?.error != null) {
      this.error = data.error;
      this.seErrorExceptionMessage(data);
    }

    dialogRef.disableClose = true;

    // Force Update
    setTimeout(() => {
      let body: HTMLElement = document.querySelector('body');
      if (body?.dispatchEvent != null) {
        let keydown = body.dispatchEvent(
          new KeyboardEvent('keydown', {
            key: 'Up',
          })
        );

        _log('Trigger Up keydown', keydown);

        return;
      }
    }, 1);
  }

  seErrorExceptionMessage(data) {
    let rv = data.error?.error?.exception?.Message;

    if (_isDev() && rv == null) {
      rv = data.error?.message;
    }

    if (rv?.length > 0) {
      if (rv?.length > 256) {
        this.errorExceptionMessage = rv.slice(0, 256) + '...';
      } else {
        this.errorExceptionMessage = (rv + '.').replace('..', '.');
      }
    }
  }

  get canShowDetailedError() {
    if (this.errorExceptionMessage == null) return false;

    return _isDev() || _isLab() || _isStaging();
  }

  getforceMsg() {
    let msg = this.forceMsg;
    if (msg == null) return null;

    msg = String(msg).trim();

    if (!msg) return null;
    if (!(String(msg).length > 3)) return null;
    if (_compareNormaliceStrings(msg, 'ok')) return null;
    if (_compareNormaliceStrings(msg, 'switching protocols')) return null;
    if (_compareNormaliceStrings(msg, 'not modified')) return null;

    return msg || null;
  }

  ngOnInit(): void {
    //si hay data.forceMsg usa esa prop com msg
    this.forceMsg = this.data?.forceMsg;
    const conditionDefault = (_isDev() && _getEnv('disableErrorModals')) || getEnabledBlockVal('DEMO-DISABLE-ERROR-MODALS');

    if (conditionDefault) {
      //si hay data.forceShow no se autocierra con disableErrorModals
      if (_get(this, 'data.forceShow')) return;

      if (getEnabledBlockVal('DEMO-DISABLE-ERROR-MODALS') === true) {
        //des-177
        if (!_isDev()) console.warn('\n\n[DEMO-DISABLE-ERROR-MODALS]');
      }

      this.close();
    }
  }

  close() {
    //https://github.com/angular/components/issues/13640 fix para refrescar el modal al cerrarlo. Para que se destruya.
    //Aparenetemente problema con ngZone, error por fuera de dominio controlable de angular
    this.dialogRef.close(true);
    setTimeout(() => {
      this.applicationRef.tick();
    }, 50);
  }

  get canDownloadErrorLog() {
    const permission =
      _isDev() ||
      _isLab() ||
      _isStaging() ||
      this.prismaDynamicEnv.getConf('brandCustomization.featuresEnabled.blocksEnabled.CAN_DOWNLOAD_CONSOLE_LOG');

    const consoleLogIniteid = this.consoleLogger?.isInitied === true;

    return permission && consoleLogIniteid;
  }

  downloadConsoleLog(size?, withImage?) {
    if (!this.canDownloadErrorLog) return null;

    return this.consoleLogger?.downloadConsoleLog?.(size, withImage);
  }
}
