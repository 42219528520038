import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { AppState } from 'app/store/store.state';
import { Observable, Subject } from 'rxjs';

import { transitions } from '@prisma/animations/animations';
import { DestroySubscribers } from 'core/decorators/destroy-subscribers.decorator';
import { takeUntil } from 'rxjs/operators';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';

@DestroySubscribers()
@Component({
  selector: 'ian-no-items-found',
  templateUrl: './ian-no-items-found.component.html',
  styleUrls: ['./ian-no-items-found.component.scss'],
  animations: [transitions],
})
export class NoItemsFoundComponent implements OnInit, OnDestroy {
  constructor(private cd: ChangeDetectorRef, private translate: IanTranslateService) {}

  @Select(AppState.isProgressBarShowing)
  public isLoading$: Observable<boolean>;

  public showNoItems = false;

  private unsuscribreAll: Subject<void> = new Subject<void>();

  @Input() textToShow: String = this.translate.instant('COMP.ITEMS_NO_FOUND.NO_RESULTS_FILTERS');

  ngOnInit(): void {
    this.showNoItems = false;

    this.isLoading$.pipe(takeUntil(this.unsuscribreAll)).subscribe(isLoading => {
      if (isLoading) {
        this.showNoItems = false;
      } else {
        setTimeout(() => {
          this.cd.markForCheck();
        }, 128);
        setTimeout(() => {
          this.showNoItems = true;
          this.cd.markForCheck();
        }, 512);
      }
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.unsuscribreAll.next();
    this.unsuscribreAll.complete();
  }
}
