import { ScrollDispatcher } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { _log, _warn, __log } from '@shared/aux_helper_environment';
import { _cloneDeep, _debounceDecorator, _equal, _getNewNumberId, _uniqueElementsByKey } from '@shared/aux_helper_functions';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  CategoryTreeModelGen,
  GenericCategorySelectionMiniV2Service,
  _mapGenericCatSelectModelV1ToV2,
} from './generic-category-selection-mini-v2.service';

let _aux_treeCats: CategoryTreeModelGen[] = null;

export const _aux_getCatItemObjFromId = (
  $ids: number | number[],
  treeCats: CategoryTreeModelGen[] = _aux_treeCats
): CategoryTreeModelGen | CategoryTreeModelGen[] => {
  if ($ids == null) return [];
  if (treeCats == null || !treeCats.length) {
    _warn('No treeCats', treeCats);
    return [];
  }

  let ids = typeof $ids === 'number' ? [$ids] : _cloneDeep($ids);
  let rv: CategoryTreeModelGen[] = [];

  treeCats.forEach(obj => {
    if (obj !== null && ids.includes(obj.categoryId)) {
      rv.push(obj);
    } else {
      if (obj && obj.items && obj.items.length) {
        const childs: any = _aux_getCatItemObjFromId($ids, obj.items);
        if (childs && childs.length) rv = [...rv, ...childs];
      }
    }
  });

  rv = _uniqueElementsByKey(rv as [], 'categoryId');
  return (typeof $ids === 'number' ? (rv || [])[0] : rv) as any;
};

export const _aux_treeCats_ready = () => {
  const recheck = () => {
    return !!_aux_treeCats;
  };

  let inter = null;

  return new Promise((res, err) => {
    if (_aux_treeCats) return res(true);
    inter = setInterval(() => {
      if (recheck()) {
        clearInterval(inter);
        inter = null;
        return res(true);
      }
    }, 16);
  });
};

export const _aux_getCatParentIdFromChildId = (
  $ids: number | number[],
  treeCats: CategoryTreeModelGen[] = _aux_treeCats
): number | number[] => {
  if ($ids == null) return [];

  if (treeCats == null || !treeCats.length) {
    _warn('No treeCats', treeCats);
    return [];
  }

  let ids = typeof $ids === 'number' ? [$ids] : _cloneDeep($ids);
  let parentsObjs: any = _aux_getCatItemObjFromId(ids, treeCats);
  let rv = parentsObjs.map(obj => obj.parentId).filter(obj => obj != null);
  rv = [...new Set(rv)];

  return typeof $ids === 'number' ? (rv || [])[0] : rv;
};

export const _aux_flatternCategoryTreeModelGen = (
  treeCats: CategoryTreeModelGen[],
  level: number,
  group: boolean = true,
  clone: boolean = false,
  filter: (string | number)[] = null,
  intersectChilds: number[] = null,
  intersectParents: number[] = null
): CategoryTreeModelGen[] => {
  /*
    convierte de CategoryTreeModelGen[] a un array plano (sin hijos de hijos) solo con los padres (si es group) e hijos hermanados
    puede devolver clonado, y filtrado
  */

  if (!treeCats || !treeCats.length) return [];

  let rv: CategoryTreeModelGen[] = [];

  const _aux_objWithoutChilds = obj => {
    return { ...obj, items: null };
  };

  treeCats.forEach(obj => {
    if (obj.level === level && (!(filter && filter.length) || filter.includes(obj.parentId) || filter.includes(obj.categoryId))) {
      // Insrta padre
      rv.push(_aux_objWithoutChilds(obj));
      // Insrta hijos
      if (obj.items && obj.items.length) rv = [...rv, ...obj.items.map(_aux_objWithoutChilds)];
    } else {
      if (obj.level < level) {
        // otro nivel, prueba con sus hijos
        const childs = _aux_flatternCategoryTreeModelGen(obj.items, level, group, false, filter);
        if (childs.length) rv = [...rv, ...childs];
      }
    }
  });

  rv = _uniqueElementsByKey(rv as [], 'categoryId');

  if (intersectChilds) {
    rv = rv.filter(obj => {
      if (group && obj.level === level) return true; /*los padres no los toca*/
      return intersectChilds.includes(obj.categoryId);
    });
  }

  if (intersectParents) {
    rv = rv.filter(obj => {
      if (group) {
        if (obj.level === level) {
          // Si es padre lo filtra
          return intersectParents.includes(obj.categoryId);
        }
        if (obj.level > level) {
          // Si es hijo se fija q el padre no esté filtrado
          return intersectParents.includes(obj.parentId);
        }
      }

      if (!group && obj.level === level) {
        // Como no es grupo, solo se fija que el item no tenga el padre filtrado
        return intersectParents.includes(obj.parentId);
      }

      return true;
    });
  }

  // Si no es agrupado, saca nivel padre
  if (!group) rv = rv.filter(obj => obj.level === level);

  return !clone ? rv : _cloneDeep(rv);
};

export interface CategoryTreeModelGenSelected {
  level2?: (number | string)[];
  level3?: (number | string)[];
  level4?: (number | string)[];
  level5?: (number | string)[];
}

export interface CategoryTreeModelGenObj {
  level2?: CategoryTreeModelGen[];
  level3?: CategoryTreeModelGen[];
  level4?: CategoryTreeModelGen[];
  level5?: CategoryTreeModelGen[];
}

@Component({
  selector: 'generic-cat-select-mini-v2',
  template: `
    <span *ngIf="showLoader && !loaderIsReady && !categoryListReady">
      <mat-spinner [diameter]="20" color="accent" [attr.data-test-id]="'generic-cat-select-mini-v2-loader'"></mat-spinner>
    </span>
    <span *ngIf="categoryListReady && loaderIsReady" class="generic-cat-select-span-wrapper">
      <ng-container *ngFor="let item of [0, 1, 2, 3, 4, 5]; let i = index">
        <!-- // TODO: traducción de más niveles / showSelectAllGroupBtn en true -->
        <generic-lookup
          *ngIf="
            i >= _minLevel &&
            this['level' + i + 'Config'] &&
            this['level' + i + 'Config'].visible &&
            (this['level' + i + 'Config'].parentDependent && i > _minLevel ? this['selectLevel' + (i - 1)]?.length : true) &&
            this['categoryFlatLevel' + i]?.length
          "
          [values]="this['categoryFlatLevel' + i]"
          [selected]="this['selectLevel' + i]"
          (onChange)="onChangeLevelKey(i, $event)"
          [multiple]="this['level' + i + 'Config'].multiple"
          [required]="this['level' + i + 'Config'].required"
          [itemSize]="itemSize != null ? itemSize : this['level' + i + 'Config'].itemSize"
          [readOnly]="readOnly != null ? readOnly : !!this['level' + i + 'Config'].readOnly"
          [disabled]="disabled != null ? disabled : !!this['level' + i + 'Config'].disabled"
          [group]="this['level' + i + 'Config'].group"
          [showSelectAllGroupBtn]="true"
          propId="categoryId"
          propValue="categoryName"
          propGroupParentId="parentId"
          [placeholder]="this['level' + i + 'Config'].placeholder || this.translate.instant('GENERIC_COMP.CAT_SELECT_V2.LEVEL' + i)"
          [panelClass]="panelClass"
          [verbose]="verbose"
          [attr.data-test-id-text-placeholder]="
            this['level' + i + 'Config'].placeholder || this.translate.instant('GENERIC_COMP.CAT_SELECT_V2.LEVEL' + i)
          "
          [attr.data-test-id-text-translate]="this.translate.instant('GENERIC_COMP.CAT_SELECT_V2.LEVEL' + i)"
          [attr.data-test-id]="'generic-cat-select-mini-v2-level' + i"
          [attr.data-test-id-wrapp]="
            'generic-cat-select-mini-v2-wrapp-' + this['level' + i + 'Config'].placeholder ||
            this.translate.instant('GENERIC_COMP.CAT_SELECT_V2.LEVEL' + i)
          "
          [test-id]="this['level' + i + 'Config'].placeholder || this.translate.instant('GENERIC_COMP.CAT_SELECT_V2.LEVEL' + i)"
        ></generic-lookup>
      </ng-container>
    </span>
  `,
  styles: [``],
})
export class GenericCategorySelectionMiniV2Component implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  $unsuscribreAll: Subject<void> = new Subject<void>();
  destroyed = false;

  categoryTree: CategoryTreeModelGen[] = null;
  categoryListReady = false;

  _minLevel = 1;
  _maxLevel = 5;

  loaderIsReady = false;

  levelConfig = {
    readOnly: false,
    cascade: true,
    multiple: true,
    required: false,
    group: true,
    itemSize: 5,
    intersect: null,
    placeholder: null,
    parentDependent: false,
    disabled: false,
  };

  /**/
  categoryFlatLevel2: CategoryTreeModelGen[] = null;
  selectLevel2 = [];
  categoryFlatLevel3: CategoryTreeModelGen[] = null;
  selectLevel3 = [];
  categoryFlatLevel4: CategoryTreeModelGen[] = null;
  selectLevel4 = [];
  categoryFlatLevel5: CategoryTreeModelGen[] = null;
  selectLevel5 = [];
  /**/

  /**/
  @Input('categoryTreeFiltred') categoryTreeFiltred = [];
  @Input('level2Config') level2Config = null;
  @Input('level3Config') level3Config = null;
  @Input('level4Config') level4Config = null;
  @Input('level5Config') level5Config = null;
  /**/
  @Input('verbose') verbose = false;
  @Input('delay2Ready') delay2Ready = 128;
  @Input('showLoader') showLoader = true;
  @Input('refilterChilds') refilterChilds = true;
  @Input('autoFillParentsOnStart') autoFillParentsOnStart = false;
  @Input('autoFillParentsEver') autoFillParentsEver = false;
  @Input('selected') parentSelectedInput: CategoryTreeModelGenSelected = null;
  @Output() onChange: EventEmitter<CategoryTreeModelGenSelected> = new EventEmitter();
  @Output() onChangeObj: EventEmitter<any> = new EventEmitter();
  @Output() isLoaded = new EventEmitter<boolean>();

  @Input('readOnly') readOnly = null;
  @Input('disabled') disabled = null;
  @Input('itemSize') itemSize = null;
  @Input('levels') levels: (1 | 2 | 3 | 4 | 5)[] = [4, 5];
  @Input('panelClass') panelClass: string = 'panelCats' + _getNewNumberId();
  @Input() mapV1ToV2 = { active: false, isExcluded: false };

  parentSelected: CategoryTreeModelGenSelected = null;
  concatLevel2ToLevel3Name = false;

  /**/
  constructor(
    private cd: ChangeDetectorRef,
    readonly sd: ScrollDispatcher,
    public translate: IanTranslateService,
    private service: GenericCategorySelectionMiniV2Service
  ) {}

  ngOnInit(): void {
    this.service
      .getCategoryTree()
      .pipe(first())
      .subscribe(async data => {
        if (!_aux_treeCats && data) _aux_treeCats = _cloneDeep(data);

        this.categoryTree = this.categoryTreeFiltred.length
          ? this.categoryTreeFiltred
          : this.concatLevel2ToLevel3Name
          ? ((await this.concatenateLevel2toLevel3Name(data)) as any)
          : data;

        this.populateCascadeCombos();
        this.categoryListReady = this.categoryTree != null;

        if (true && this.verbose) _log('[categoryTree]', this.categoryTree);
        if (this.autoFillParentsOnStart || this.autoFillParentsEver) this.fillParentsOnStart();

        this.isLoaded.emit(true);

        if (!this.destroyed && this.cd?.detectChanges) this.cd.detectChanges();
      });

    setTimeout(() => {
      this.loaderIsReady = true;
      if (!this.destroyed && this.cd?.detectChanges) this.cd.detectChanges();
    }, this.delay2Ready);
  }

  // DES-2852
  async concatenateLevel2toLevel3Name(catTree) {
    if (catTree?.length !== 1 || !(catTree?.[0]?.items?.length > 0) || this.concatLevel2ToLevel3Name !== true) return catTree;

    let categoryTree = await this.getCategoryTree();

    let rvItems = [...catTree[0].items].map(element => {
      if (element == null) return element;
      if (String(element?.parentId) !== '2') return element;

      let categoryName = element.categoryName;

      let parentId = element.parentId;

      let catParent = parentId != null ? (categoryTree || []).find(c => String(parentId) === String(c.categoryId)) : null;

      if (catParent == null && categoryTree?.length)
        catParent = parentId != null ? (categoryTree[0]?.items || []).find(c => String(parentId) === String(c.categoryId)) : null;

      if (catParent?.categoryName) categoryName = catParent.categoryName + ' > ' + categoryName;

      return { ...element, categoryName: categoryName };
    });

    let rv = [{ ...catTree[0], items: rvItems }];

    return rv;
  }

  private __categoryTreeCache = null;
  async getCategoryTree() {
    if (this.categoryTreeFiltred.length) return this.categoryTreeFiltred;
    if (this.__categoryTreeCache == null) this.__categoryTreeCache = await this.service.getCategoryTree().toPromise();
    return await this.service.getCategoryTree().toPromise();
  }

  setLevelConfig(level) {
    let parentConfig = this['level' + level + 'Config'] || {};
    let levelConfig = _cloneDeep(this.levelConfig);
    let visible = this.levels.includes(level);

    let rv = Object.assign({}, levelConfig, parentConfig, { visible: visible });

    if (false) _log(parentConfig, rv);

    return rv;
  }

  _firsRuned = false;
  fillParentsOnStart() {
    let firstRun = this._firsRuned === false && this.categoryTree != null;
    if ((this.autoFillParentsOnStart || this.autoFillParentsEver) && firstRun) {
      this._firsRuned = true;
      this.fillParents();
    }
  }

  checkFillParentsEver() {
    if (this.autoFillParentsEver !== true) return;
    if (this.categoryTree == null || !this.parentSelected || !this.parentSelected[`level${5}`]?.length) return;
    if (this.parentSelected[`level${4}`]?.length || this.parentSelected[`level${3}`]?.length) return;
    this.fillParents();
  }

  fillParents() {
    if (this.categoryTree == null || !this.parentSelected || !this.parentSelected[`level${5}`]?.length) return;

    let rvParentSelected = _cloneDeep(this.parentSelected);
    let subCat = rvParentSelected[`level${5}`];
    let tmpCategory: any = _aux_getCatParentIdFromChildId(subCat, this.categoryTree);
    let tmpDdepartament: any = tmpCategory?.length ? _aux_getCatParentIdFromChildId(tmpCategory, this.categoryTree) : null;

    if (tmpCategory?.length && tmpDdepartament?.length) {
      rvParentSelected[`level${4}`] = [...new Set([...(rvParentSelected[`level${4}`] || []), ...tmpCategory])];
      rvParentSelected[`level${3}`] = [...new Set([...(rvParentSelected[`level${3}`] || []), ...tmpDdepartament])];
    }

    if (!_equal(rvParentSelected, this.parentSelected)) {
      this.parentSelected = rvParentSelected;
      this.reMakeLevels();
    }
  }

  reMakeLevels() {
    let _change = false;

    for (let i = this._minLevel; i < this._maxLevel + 1; i++) {
      if (
        this[`level${i}Config`] &&
        this[`level${i}Config`].visible &&
        !_equal(this.parentSelected[`level${i}`], this[`selectLevel${i}`])
      ) {
        this[`selectLevel${i}`] = this.parentSelected[`level${i}`];

        if (true) this.checkMultipleValuesOnNoMultipleCategory(i);

        _change = true;
      }
    }

    if (_change) {
      this.populateCascadeCombos();
      this.cd.markForCheck();
      this.cd.detectChanges();
    }
  }

  checkMultipleValuesOnNoMultipleCategory(i) {
    //Checqueo de que no pueda haber más de dos valores para una categoria no múltiple
    if (this[`level${i}Config`].multiple !== true && this[`selectLevel${i}`]?.length > 1) {
      console.log('\n');
      console.error('[Más de una valor para una categoría no múltiple]', {
        level: i,
        values: _cloneDeep(this[`selectLevel${i}`]),
        config: _cloneDeep(this[`level${i}Config`]),
      });
      console.log('\n');

      this[`selectLevel${i}`] = this[`selectLevel${i}`].slice(0, 1);
    }
  }

  mapV1ToV2Selected() {
    const [categoriesSelected, _isexcluded] = _mapGenericCatSelectModelV1ToV2(this.parentSelectedInput, false, true, this.categoryTree);
    _log(this.mapV1ToV2['isExcluded'], _isexcluded, this.mapV1ToV2['isExcluded'] === _isexcluded, this.parentSelectedInput);
    return this._empty(categoriesSelected) || this.mapV1ToV2['isExcluded'] === _isexcluded ? categoriesSelected : [];
  }

  _empty(ev) {
    return _equal(ev, { level4: [], level5: [] });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkLevelChanges(changes);

    if (changes.parentSelectedInput && this.parentSelectedInput) {
      this.parentSelected = this.mapV1ToV2['active'] ? this.mapV1ToV2Selected() : _cloneDeep(this.parentSelectedInput);
      this.reMakeLevels();
    }

    if (
      this.autoFillParentsEver === true &&
      changes?.parentSelectedInput?.currentValue?.level5?.length > 0 &&
      !_equal(changes?.parentSelectedInput?.currentValue?.level5, changes?.parentSelectedInput?.previousValue?.level5)
    ) {
      this.checkFillParentsEver();
    }
  }

  checkLevelChanges(changes?: SimpleChanges) {
    if (!changes) return;

    for (let i = this._minLevel; i < this._maxLevel + 1; i++) {
      if (changes[`level${i}Config`] || !this[`level${i}Config`]) {
        this[`level${i}Config`] = this.setLevelConfig(i);

        if (this[`level${i}Config`].intersect && this[`level${i}Config`].intersect.length) {
          const key_all_cat_values = `key_all_cat_values${i}`;
          this.populateAndFilterLevelByKey(i, key_all_cat_values && this[key_all_cat_values] ? this[key_all_cat_values] : null);
        }
      }
    }
  }

  populateCascadeCombos() {
    if (!this.categoryTree || !this.categoryTree.length) return;

    /*Busca el primer combo (root) para cascadear, al popular el padre van populando los hijos*/
    for (let i = this._minLevel; i < this._maxLevel + 1; i++) {
      const key_levelConfig = `level${i}Config`;
      if (this[key_levelConfig] && this[key_levelConfig].visible) {
        this._minLevel = i;
        this[key_levelConfig].parentDependent = false; // root no puede ser parentDependent

        if (i === this.categoryTree[0].level) {
          /*Si el root combo es igual al root de las categorias no tiene padres*/
          this[key_levelConfig].group = false;
        }

        if (false && this.categoryTree.length === 1 && i - 1 === this.categoryTree[0].level) {
          /*Si el root solo tiene un padre*/
          this[key_levelConfig].group = false;
        }

        this.populateAndFilterLevelByKey(i);
        break; // Los siguientes se populan a partir del primero
      }
    }
  }

  populateAndFilterLevelByKey(keyId: number, parentFilter = null) {
    const key_levelConfig = `level${keyId}Config`;
    const key_categoryFlatLevel = `categoryFlatLevel${keyId}`;

    const key_parentLevelConfig = `level${keyId - 1}Config`;
    const key_ParentLevel = `selectLevel${keyId - 1}`;

    const key_all_cat_values = `key_all_cat_values${keyId}`;

    if (!this[key_levelConfig] || !this[key_levelConfig].visible) return null;

    if (this[key_levelConfig].parentDependent && keyId > this._minLevel && (!this[key_ParentLevel] || !this[key_ParentLevel].length)) {
      return (this[key_categoryFlatLevel] = []);
    }

    //Flag si hace falta cascadear con el padre
    const parentCascadeIsAvailable =
      this[key_levelConfig].cascade &&
      this[key_parentLevelConfig] &&
      this[key_parentLevelConfig].visible &&
      this[key_ParentLevel] &&
      Array.isArray(this[key_ParentLevel]);

    // Filtro por selección del padre
    const selectCascade = parentCascadeIsAvailable && this[key_ParentLevel].length ? this[key_ParentLevel] : null;

    // Filtro por propiedad (@input) intersect
    let intersect =
      this[key_levelConfig].intersect && this[key_levelConfig].intersect.length ? this[key_levelConfig].intersect.map(Number) : null;

    // items filtrados, son los q se van a mostrar
    this[key_categoryFlatLevel] = _aux_flatternCategoryTreeModelGen(
      this.categoryTree,
      this[key_levelConfig].group && keyId + 1 > this._minLevel ? keyId - 1 : keyId,
      this[key_levelConfig].group,
      true,
      selectCascade,
      intersect,
      parentFilter
    );

    // Saca ids seleccionables para filtrar al hijo (se cachea para usar tmb fuera de esta función)
    this[key_all_cat_values] = parentCascadeIsAvailable
      ? _aux_flatternCategoryTreeModelGen(this[key_categoryFlatLevel], keyId, false, true).map(el => el.categoryId)
      : null;

    // Si hay nivel hijo lo repopula con el filtro
    if (keyId < this._maxLevel) {
      this.populateAndFilterLevelByKey(keyId + 1, this[key_all_cat_values]);
    }
  }

  onChangeLevelKey(keyId: number, val: number | string) {
    const key_all_cat_values = `key_all_cat_values${keyId}`;
    const keyLevel = 'selectLevel' + keyId;

    if (!this[keyLevel]) return;

    if (true && this.verbose) _log(`[onChange${keyLevel}]`, val);

    this[keyLevel] = _cloneDeep(val);
    if (keyId < this._maxLevel) {
      // Fuerza el repopulado del select siguiente
      this.populateAndFilterLevelByKey(keyId + 1, key_all_cat_values && this[key_all_cat_values] ? this[key_all_cat_values] : null);
    }

    this.cd && !this.destroyed && this.cd.markForCheck();

    this.emitChanges();
  }

  async emitChanges() {
    let rv: CategoryTreeModelGenSelected = {};

    for (let i = this._minLevel; i < this._maxLevel + 1; i++) {
      if (this[`level${i}Config`] && this[`level${i}Config`].visible) {
        rv[`level${i}`] =
          this[`level${i}Config`].parentDependent && i > this._minLevel && !this['selectLevel' + (i - 1)]?.length
            ? []
            : this[`selectLevel${i}`];
      }

      // ReFiltra los hijos (si no tienen la posibilidad de tener ese valor (x padre) se remueven)
      if (this.refilterChilds) {
        if (rv[`level${i}`]?.length) {
          rv[`level${i}`].forEach(v => {
            if (!this['categoryFlatLevel' + i].map(t => t.categoryId)?.includes(v)) {
              if (false && this.verbose) _log('REMOVE', v);
              rv[`level${i}`] = rv[`level${i}`].filter(c2 => c2 !== v);
            }
          });
        }
      }
    }

    if (_equal(rv, this.parentSelectedInput)) return;

    if (true && this.verbose) _log('[cat emitChanges]', rv);

    this.onChange.emit(rv);

    this.emitChangesObj();
  }

  emitChangesObj() {
    let rv: any = {};

    for (let i = this._minLevel; i < this._maxLevel + 1; i++) {
      if (this[`level${i}Config`] && this[`level${i}Config`].visible) {
        rv[`level${i}`] =
          this[`level${i}Config`].parentDependent && i > this._minLevel && !this['selectLevel' + (i - 1)]?.length
            ? []
            : _aux_getCatItemObjFromId(this[`selectLevel${i}`], _cloneDeep(this.categoryTree));
      }
    }
    if (_equal(rv, this.parentSelectedInput)) return;

    if (true && this.verbose) _log('[emitChangesObj]', rv);
    this.onChangeObj.emit(rv);
  }

  ngOnDestroy(): void {
    this.destroyed = true;
    this.$unsuscribreAll.next();
    this.$unsuscribreAll.complete();
  }

  ngAfterViewInit(): void {}
}
