export enum StateCheckboxTypesItems {
  Todos = 'Todos',
  Checked = 'Revisadas',
  Unchecked = 'Sin revisar',
}

export enum ActiveCheckboxTypesItems {
  Todos = 'Todos',
  Checked = 'Listado',
  Unchecked = 'Delistado',
}

export enum ActionTypeDropdownItems {
  UnCheckedSuggestion = 'Sugerencia sin revisar',
  PriceRaised = 'Precios que aumentaron',
  NoSuggestions = 'Sin sugerencias',
  NotCheckedSimulation = 'Simulación sin revisar', // DESACTIVADO DESDE FE
  SuggestionAccepted = 'Sugerencia aceptada',
  ManualPriceEdited = 'Editados manualmente',
  SimulationAcepted = 'Simulación aceptada', // DESACTIVADO DESDE FE
  oldPrices = 'Precios Antiguos',
  aboutToExpire = 'Precios por vencer',
}

export enum PriceTyeDropdownItems {
  Regular = 'Regular',
  Competition = 'Competencia',
  Current = 'Vigente',
}

export enum RegionTypeDropdownItems {}

// OCULTAR FILTRO DE SENSIBILIDADES EN TODOS LOS FILTROS
export enum SensitivitieTypeDropdownItems {}

export enum PositionAgainstDropdownItems {
  Minimum = 'Mínimo',
  Average = 'Promedio',
  Maximum = 'Máximo',
  Made = 'Hecho',
}

export enum ModulesTagId {
  ITEMS = 1,
  STORES = 2,
}

export enum KeyTypeList {
  Store = 'store',
  Item = 'item',
  Competence = 'competence',
  Brand = 'brand',
  Vendors = 'vendors',
}
