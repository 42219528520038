import { State, Action, Selector, StateContext, Store } from '@ngxs/store';
import { InsightsPanelService } from './insights-panel.service';
import { Injectable } from '@angular/core';
import { _cloneDeep } from '@shared/aux_helper_functions';
import { _getEnv } from '@shared/aux_helper_environment';
import { InsightsListModel } from 'app/admin/master/insights/insights-store/insights-store.model';
//import { _getPermanentReadys } from './notifications-panel.auxs';

export const FEATURE_KEY = 'InsightsPanelListStore';

export const MAX_NOTIFICATIONS_OLD = _getEnv('notifications.maxServerReadNotif', 100);
export const MAX_NOTIFICATIONS_NEW = _getEnv('notifications.maxServerNoReadNotif', 100);

const _devTest = false;

export class InsightsPanelSetErrorAction {
  static readonly type = `[${FEATURE_KEY}] Set Errors`;
  constructor(readonly payload: any) {}
}

export class InsightsPanelGetListAction {
  static readonly type = `${FEATURE_KEY} Get list`;
}

export class InsightsPanelRestListAction {
  static readonly type = `${FEATURE_KEY} Reset list`;
}

export class InsightsPanelGetListSuccessAction {
  static readonly type = `${FEATURE_KEY} Get list success`;
  constructor(readonly payload: any) {}
}

export class UnsubscribeInsights {
  static readonly type = `${FEATURE_KEY} Unsubscribe from Insights`;
  constructor(readonly payload: any) {}
}

@Injectable({ providedIn: 'root' })
@State<any>({
  name: FEATURE_KEY,
  defaults: {
    insights: null,
    totalItemCount: 0,
  },
})
export class InsightsPanelStoreState {
  @Selector()
  public static getState(state: any): any {
    return state;
  }

  @Selector()
  static getPanelInsights(state: any): Array<InsightsListModel> {
    return _cloneDeep(state.insights);
  }

  @Selector()
  static getInsightsSize(state: any): number {
    return state.totalItemCount;
  }

  constructor(private insightsPanelService: InsightsPanelService, private store: Store) {}

  @Action(InsightsPanelRestListAction)
  insightsPanelResetList(context: StateContext<any>, action: InsightsPanelRestListAction): void {
    context.patchState({
      insights: null,
    });
    return;
  }

  @Action(InsightsPanelGetListAction)
  getListOwner(context: StateContext<any>, action: InsightsPanelGetListAction): void {
    this.insightsPanelService.getPanelList().subscribe(items => {
      const newAction = new InsightsPanelGetListSuccessAction(items);
      this.store.dispatch(newAction);
    });
  }

  @Action(InsightsPanelGetListSuccessAction)
  insightsPanelGetList(context: StateContext<any>, action: InsightsPanelGetListSuccessAction): void {
    let subscribedInsights = action.payload;

    if (subscribedInsights === null) {
      context.patchState({
        insights: null,
        totalItemCount: 0,
      });
      return;
    }

    context.patchState({
      insights: _cloneDeep(subscribedInsights.items),
      totalItemCount: subscribedInsights.totalItemCount,
    });
  }
}
