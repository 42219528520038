import { _log } from '@shared/aux_helper_environment';
import { _arrIntersection, _containNormaliceStrings, _get, _krange, _renameKeysPrefix } from '@shared/aux_helper_functions';
import { PlanogramModel } from '../planograms-editor/store/planogram.interfaces';
import { svgSimpleProd } from '../svgComponents/svgProds/svgSimpleProd.component';
import { svgBaseShelf, _auxGetMaxRemainingWidthSpace } from '../svgComponents/svgShelfs/svgBaseShelf.component';
import { _round } from './spaces_aux_helpers';

/* Calcula data de productos a partir de .itemData */

const _prdInfo_getExhibDays = prdData => {
  //Dias exh > carga real / unidades venta diaria
  if (!prdData || !prdData.salesUnits) return 0;

  const rv = prdData.$totalItemsPerEan / (prdData.salesUnits / 30);

  if (!true) _log('[getExhibDays]', { prd: prdData, rv });
  return rv;
};

const _prdInfo_getStockDays = prdData => {
  //Dias stock > Stock actual (no promedio en unidades) / venta diaria (en unidades)
  if (!prdData || !prdData.salesUnits || !prdData.stockUnits) return 0;

  const rv = prdData.daysInStock != undefined ? prdData.daysInStock : prdData.stockUnits / (prdData.salesUnits / 30);

  if (!true) _log('[getStockDays]', { prd: prdData, rv });
  return rv;
};

const _prdInfo_getNetContent = prdData => {
  if (!prdData) return 0;
  //Fake
  return Math.round((prdData.size.w * prdData.size.h * (prdData.size.d || 0)) / 10) * 10 || null;
};

const _prdInfo_getImgFront = prdData => {
  if (!prdData) return '';
  return prdData.imagePlanogramFront;
};

const calcMin = (actual, $min) => {
  if ($min == null) return actual || 0;
  const rv = Math.min($min, actual) || 0;
  return isNaN(rv) || !rv ? 0 : rv;
};

const calcMax = (actual, $max) => {
  if ($max == null) return actual || 0;
  const rv = Math.max(actual || 0, $max || 0);
  return isNaN(rv) || !rv ? 0 : rv;
};

/*calcula mínimos y máximos pasándole un array de productos*/
export const _addProdPrecalcDataArrayProdsMinMax = (prods: svgSimpleProd[], ignoreFilterProds = true, isPlanogram = false) => {
  let sumPrice = 0;

  let minPrice = null;
  let maxPrice = 0;
  let minSales = null;
  let maxSales = 0;
  let minSalesUnits = null;
  let maxSalesUnits = 0;
  let minMargin = null;
  let maxMargin = 0;
  let minMarginP = null;
  let maxMarginP = 0;
  let minMarginUnits = null;
  let maxMarginUnits = 0;
  let minGmroi = null;
  let maxGmroi = 0;
  let minGmros = null;
  let maxGmros = 0;

  let totalItems = 0;
  let totalProds = 0;
  let totalSales = 0;
  let totalSalesUnits = 0;
  let totalFacings = 0;
  let totalMargin = 0;
  let totalMarginP = 0;
  let totalMarginUnits = 0;
  let totalUsedLinealEsp = 0;
  let totalUsedAreaEsp = 0;
  let totalStock = 0;
  let totalUsedAreaEspRatio = 0;
  let totalUsedAreaEspBase = 0;
  let totalPlanogramsLineSpace = 0;

  //NEW
  let totalCost = 0;
  let totalCurrentInventory = 0;
  let totalCurrentInventoryUnits = 0;
  let totalDaysOutOfStock = 0;
  let totalDaysInStock = 0;
  let totalGmroi = 0;
  let totalGmros = 0;
  let totalInventory = 0;
  let totalInventoryDays = 0;
  let totalInventoryUnits = 0;
  let totalRegularPrice = 0;
  let totalUnits = 0;
  let totalListed = 0;

  let totalTags = [];
  let totalTagsValues = [];

  let totalDepartments = [];
  let totalCategorys = [];
  let totalSubCategorys = [];

  let totalFacingsPerEan = {};
  let totalSpacePerEan = {};
  let totalItemsPerEan = {};

  let planogramUnionTags = [];
  let _planogramUnionTags = new Map();

  let totalDepartmentsNoUnique = [];
  let totalCategorysNoUnique = [];
  let totalSubCategorysNoUnique = [];

  prods.forEach((prod: any, i) => {
    if (!prod.itemData) return;
    if (ignoreFilterProds && prod._isFiltered) return;

    const itemData = prod.itemData;
    const price = itemData.price || 0;
    const eanK = itemData ? itemData.code || itemData.ean : null;

    if (itemData?.isEmptySpace) return; //CAT-19

    if (!true && i === 0) _log('[price 1er prod]', price);

    let _tmpDataWidthToCalcEspWidthToCalcEsp = prod.itemData.size.w;

    if (prod.rotateY) {
      _tmpDataWidthToCalcEspWidthToCalcEsp = prod.itemData.size.d;
    }

    if (prod.rotation % 180 !== 0) {
      _tmpDataWidthToCalcEspWidthToCalcEsp = prod.itemData.size.h;
    }

    if (eanK) {
      totalItems += isPlanogram ? prod.calc_TotalItems || prod.$totalItems || 1 : prod.$totalItems || 1;
      totalFacings += prod.facings || 1;

      let usedLinealEsp = (prod.facings || 1) * _tmpDataWidthToCalcEspWidthToCalcEsp;
      totalUsedLinealEsp += usedLinealEsp;

      //Solo para layout analitica DES-2023
      if (isPlanogram) {
        let baseAreaPlanogram = prod.parentShelfDepth * prod.parentShelfWidth;

        let totalPlanogramLineSpace = prod?.itemDataPlanogram?.calc_TotalLineSpace;

        //calcular el ratio con la proporción ocupada de metros líneales
        let areaProdPlanogramRatio = usedLinealEsp / totalPlanogramLineSpace;

        //Calculo del espacio ocupado por el producto contando solo la area base de su planograma
        let totalUsedAreaEspOnlyBase = areaProdPlanogramRatio * baseAreaPlanogram;

        totalUsedAreaEspRatio += areaProdPlanogramRatio;

        totalUsedAreaEsp += totalUsedAreaEspOnlyBase;

        totalUsedAreaEspBase += baseAreaPlanogram;

        totalPlanogramsLineSpace += totalPlanogramLineSpace;

        if (false)
          _log('[PROD CALC]', {
            eanK,
            areaProdPlanogramRatio,
            usedLinealEsp,
            totalPlanogramLineSpace,
            totalUsedAreaEspOnlyBase,
          });
      }

      if (totalItemsPerEan[eanK]) {
        //[Por cada instancia]

        totalFacingsPerEan[eanK] += prod.facings || 1;
        totalItemsPerEan[eanK] += prod.$totalItems || 1;
        totalSpacePerEan[eanK] += (prod.facings || 1) * _tmpDataWidthToCalcEspWidthToCalcEsp;
      } else {
        //[Por cada ean]

        totalFacingsPerEan[eanK] = prod.facings || 1;
        totalItemsPerEan[eanK] = isPlanogram ? prod.calc_TotalItems || prod.$totalItems || 1 : prod.$totalItems || 1;
        totalSpacePerEan[eanK] = (prod.facings || 1) * _tmpDataWidthToCalcEspWidthToCalcEsp;

        totalProds += 1;

        //los  datos salen del promedio de las tiendas filtradas id:z7_camn_8M (ej: Stock/Ventas)
        sumPrice += price;

        totalSales += itemData.sales || 0;
        totalSalesUnits += itemData.salesUnits || 0;

        totalMargin += itemData.margin || 0;
        totalMarginP += itemData.marginP || 0;
        totalMarginUnits += (itemData.margin || 0) * itemData.salesUnits;

        totalStock += itemData.stockUnits * itemData.price;

        //NEW
        totalCost += itemData.cost || 0;
        totalCurrentInventory += itemData.currentInventory || 0;
        totalCurrentInventoryUnits += itemData.currentInventoryUnits || 0;
        totalDaysOutOfStock += itemData.daysOutOfStock || 0;
        totalDaysInStock += itemData.daysInStock || 0;
        totalGmroi += itemData.gmroi || 0;
        totalGmros += itemData.gmros || 0;
        totalInventory += itemData.inventory || 0;
        totalInventoryDays += itemData.inventoryDays || 0;
        totalInventoryUnits += itemData.inventoryUnits || 0;
        totalRegularPrice += itemData.regularPrice || 0;
        totalUnits += itemData.units || 0;
        totalListed += itemData.isListed ? 1 : 0;

        minPrice = calcMin(price, minPrice);
        maxPrice = calcMax(price, maxPrice);
        minSales = calcMin(itemData.sales, minSales);
        maxSales = calcMax(itemData.sales, maxSales);
        minSalesUnits = calcMin(itemData.salesUnits, minSalesUnits);
        maxSalesUnits = calcMax(itemData.salesUnits, maxSalesUnits);
        minMargin = calcMin(itemData.margin, minMargin);
        maxMargin = calcMax(itemData.margin, maxMargin);
        minMarginP = calcMin(itemData.marginP, minMarginP);
        maxMarginP = calcMax(itemData.marginP, maxMarginP);
        minMarginUnits = calcMin((itemData.margin || 0) * itemData.salesUnits, minMarginUnits);
        maxMarginUnits = calcMax((itemData.margin || 0) * itemData.salesUnits, maxMarginUnits);
        minGmroi = calcMin(itemData.gmroi, minGmroi);
        maxGmroi = calcMax(itemData.gmroi, maxGmroi);
        minGmros = calcMin(itemData.gmros, minGmros);
        maxGmros = calcMax(itemData.gmros, maxGmros);

        /*TAGS*/
        const tags = itemData.tags;
        if (tags?.length) {
          tags.forEach(tag => {
            if (tag.tagId != null && tag.includedValues?.length > 0) {
              totalTags.push(tag.tagId);
              totalTagsValues.push(tag.includedValues);

              // calc _tags sumatoria de includedValues para analitica de layouts
              if (isPlanogram && false) {
                const tagFound = _planogramUnionTags.get(tag.tagId);
                const arrConacat = tagFound?.length > 0 ? tagFound.concat(tag.includedValues) : tag.includedValues;
                _planogramUnionTags.set(tag.tagId, arrConacat);
              }
            }
          });
        }

        /*CATS*/
        const dep = itemData.departmentId;
        if (dep != null) totalDepartments.push(dep);

        const cat = itemData.categoryId;
        if (cat != null) totalCategorys.push(cat);

        const scat = itemData.subCategoryId;
        if (cat != null) totalSubCategorys.push(scat);
      }
    }
  });

  if (isPlanogram) {
    _planogramUnionTags.forEach((value, key) => {
      planogramUnionTags.push({ tagId: key, includedValues: value });
    });
  }

  if (false) _log('[PROD CALC TOTAL]', { totalUsedAreaEspRatio, totalUsedAreaEsp });

  const totalExhibDays = totalSalesUnits ? totalProds / (totalSalesUnits / 30) : 0;
  const promPrice = totalProds ? sumPrice / totalProds : 0;
  const promMargin = totalProds ? totalMargin / totalProds : 0;
  const promMarginP = promPrice ? promMargin / promPrice : 0;
  const promMarginUnits = totalSales ? totalMarginUnits / totalSales : 0;

  minPrice = minPrice || 0;
  minSales = minSales || 0;
  minSalesUnits = minSalesUnits || 0;
  minMargin = minMargin || 0;
  minMarginP = minMarginP || 0;
  minGmroi = minGmroi || 0;
  minGmros = minGmros || 0;

  totalDepartmentsNoUnique = [...totalDepartments];
  totalCategorysNoUnique = [...totalCategorys];
  totalSubCategorysNoUnique = [...totalSubCategorys];

  if (totalTags.length) totalTags = [...new Set(totalTags)];

  //FIX DES-2897
  if (true && totalTagsValues.length) totalTagsValues = totalTagsValues.flat();

  if (totalTagsValues.length) totalTagsValues = [...new Set(totalTagsValues)];

  if (totalDepartments.length) totalDepartments = [...new Set(totalDepartments)];
  if (totalCategorys.length) totalCategorys = [...new Set(totalCategorys)];
  if (totalSubCategorys.length) totalSubCategorys = [...new Set(totalSubCategorys)];

  if (!true) _log('[min/maxPrice]', minPrice, maxPrice);

  return {
    minPrice,
    maxPrice,
    minSales,
    maxSales,
    minSalesUnits,
    maxSalesUnits,
    minMargin,
    maxMargin,
    minMarginP,
    maxMarginP,
    minMarginUnits,
    maxMarginUnits,
    minGmroi,
    maxGmroi,
    minGmros,
    maxGmros,
    promPrice,
    totalItems,
    totalSales,
    totalFacings,
    totalProds,
    totalSalesUnits,
    totalMargin,
    totalMarginP,
    promMargin,
    promMarginP,
    promMarginUnits,
    totalUsedLinealEsp,
    totalUsedAreaEsp,
    totalUsedAreaEspRatio,
    totalUsedAreaEspBase,
    totalPlanogramsLineSpace,
    totalExhibDays,
    sumPrice,
    totalMarginUnits,
    totalStock,
    /**/
    totalCost,
    totalCurrentInventory,
    totalCurrentInventoryUnits,
    totalDaysOutOfStock,
    totalDaysInStock,
    totalGmroi,
    totalGmros,
    totalInventory,
    totalInventoryDays,
    totalInventoryUnits,
    totalRegularPrice,
    totalUnits,
    totalListed,
    totalTags,
    totalTagsValues,
    totalDepartments,
    totalCategorys,
    totalSubCategorys,
    totalFacingsPerEan,
    totalItemsPerEan,
    totalSpacePerEan,
    planogramUnionTags,
    totalDepartmentsNoUnique,
    totalCategorysNoUnique,
    totalSubCategorysNoUnique,
  };
};

/*
    Calcula totales de estantes productos
    param: estantes a calcuar

    los promedios/averages se calculan en: spaces_aux_helpers.ts/itemDataAverages
*/
export const _addShelfsPrecalcData = (shelfs: svgBaseShelf[], ignoreFilterProds = true) => {
  if (!shelfs || !shelfs.length) return null;

  const prods = [];

  let totalLineSpace = 0;
  let totalRemainLineSpace = 0;
  let totalVolume = 0;

  let lastMod = null;
  let difModules = 0;
  let modMaxHeight = 0;
  let modMaxDepth = 0;
  let totalModWidth = 0;
  let assignedStores = null;

  shelfs.forEach(shelf => {
    if (!shelf) return;

    if (shelf.parentModule && lastMod !== shelf.parentModule) {
      difModules++;
      lastMod = shelf.parentModule;
      if (lastMod.objData) {
        let modW = lastMod.objData.width;
        totalModWidth += modW;

        let modH = lastMod._calcModuleHeight ? lastMod._calcModuleHeight(lastMod.objData) : 0;
        if (modH > modMaxHeight) modMaxHeight = modH;

        let modD = lastMod.objData.depth;
        if (modD > modMaxDepth) modMaxDepth = modD;
      }

      if (!assignedStores) assignedStores = _get(lastMod, 'baseObj.objData.storesSelected');
    }

    let prodsObj = _get(shelf, 'prdChilds._results') || [];
    (shelf.prods || []).forEach((p, i) => {
      if (p && p.itemData) {
        if (prodsObj && prodsObj[i]) prods.push({ ...p, _isFiltered: prodsObj[i]._isFiltered });
      }
    });

    /**/
    let margins = (shelf.marginLeft || 0) + (shelf.marginRight || 0); //DES 1576
    let _width = shelf.size ? Math.max((shelf.size.w || 0) - margins, 0) : 0;

    totalLineSpace += _width;

    let _height = _get(shelf, 'objDataShelf.height', 0) || shelf.size.h;

    let _depthProp = _get(shelf, 'objDataShelf.depth', 0);
    let _depth = _depthProp || _get(shelf.parentModule, 'objData.depth', 0);

    totalVolume += _round((_width * _height * _depth) / 1000); // Litros

    if ((shelf.prods || []).length) {
      totalRemainLineSpace += _round(shelf._getMaxRemainingWidthSpace ? shelf._getMaxRemainingWidthSpace() : 0);
    } else {
      totalRemainLineSpace += _width;
    }
  });

  //Junta la data total de los shelfs y le agrega un prefijo
  let shelfsData = { totalLineSpace, totalRemainLineSpace, totalVolume, data: true, assignedStores: assignedStores };
  if (difModules > 0) shelfsData = { ...(shelfsData as any), modMaxHeight, modMaxDepth, totalModWidth, cantMod: difModules };
  shelfsData = _renameKeysPrefix(shelfsData, 'shelfs_');

  //Junta la data total de los productos de los shelfs
  const prodsData = _addProdPrecalcDataArrayProdsMinMax(prods, ignoreFilterProds);

  return { ...shelfsData, ...prodsData };
};

/*
    Calcula totales de estantes productos
    param: Planograma a calcuar
*/
export const _addShelfsPrecalcDataNoModules = (planoGram: PlanogramModel, ignoreFilterProds = true) => {
  if (!planoGram || !planoGram.modules) return null;

  const allShelfs = _reduceShelfs(planoGram);

  if (!allShelfs || !allShelfs.length) return null;

  const prods = [];

  let totalLineSpace = 0;
  let totalRemainLineSpace = 0;
  let totalVolume = 0;

  let difModules = 0;
  let modMaxHeight = 0;
  let modMaxDepth = 0;
  let totalModWidth = 0;
  let assignedStores = planoGram.storesSelected;

  //Calc totalLineSpace
  allShelfs.forEach(shelf => {
    if (!shelf) return;

    (shelf.prods || []).forEach((p, i) => {
      if (p && p.itemData) {
        prods.push({ ...p, _isFiltered: !!p.itemData._isFiltered });
      }
    });

    let margins = (shelf.marginLeft || 0) + (shelf.marginRight || 0); //DES 1576
    totalLineSpace += shelf._size ? Math.max((shelf._size.w || 0) - margins, 0) : 0;
  });

  if (true) {
    //Calc totalRemainLineSpace //DES-2029
    allShelfs.forEach((shelf: any, i) => {
      if (!shelf) return;

      let margins = (shelf.marginLeft || 0) + (shelf.marginRight || 0); //DES 1576
      let shelfSpace = shelf._size ? Math.max((shelf._size.w || 0) - margins, 0) : 0;

      if (!(shelfSpace > 0)) return;

      if ((shelf.prods || []).length) {
        let total = _auxGetMaxRemainingWidthSpace(shelf.prods, shelfSpace, shelf.type);
        totalRemainLineSpace += total;
      } else {
        totalRemainLineSpace += shelfSpace;
      }
    });
  }

  if (planoGram.floats && planoGram.floats.length) {
    planoGram.floats.forEach(float => {
      if (!float || !float.prods) return;
      (float.prods || []).forEach((p, i) => {
        if (p && p.itemData) {
          prods.push({ ...p, _isFiltered: !!p.itemData._isFiltered });
        }
      });
    });
  }

  //Junta la data total de los shelfs y le agrega un prefijo
  let shelfsData = { totalLineSpace, totalRemainLineSpace, totalVolume, data: true, assignedStores: assignedStores };
  if (difModules > 0) shelfsData = { ...(shelfsData as any), modMaxHeight, modMaxDepth, totalModWidth, cantMod: difModules };
  shelfsData = _renameKeysPrefix(shelfsData, 'shelfs_');

  //Junta la data total de los productos de los shelfs
  const prodsData = _addProdPrecalcDataArrayProdsMinMax(prods, ignoreFilterProds);

  shelfsData.totalRemainLineSpace = (totalLineSpace || 0) - (prodsData.totalUsedLinealEsp || 0);

  return { ...shelfsData, ...prodsData };
};

const _reduceShelfs = planogram => {
  if (!planogram || !planogram.modules) return [];
  let shelfs = planogram.modules.reduce((acc, mod) => {
    return [...acc, ...(mod.shelfs || [])];
  }, []);
  return shelfs;
};

/*
    CALC PRODUCTOS
*/
export const _addProdPrecalcData = (prdObj: svgSimpleProd, minMax = null) => {
  if (!prdObj.itemData || prdObj.itemData?.isEmptySpace) {
    if (prdObj.itemData?.isEmptySpace) {
      let specialProd = { ...prdObj.itemData, _totalWidth: prdObj.size.w };
      return specialProd;
    }
    return prdObj.itemData || {};
  }

  const eanK = prdObj.itemData.code || prdObj.itemData.ean || null;

  const simpleData = {
    ...prdObj.itemData,
    /**/
    $totalItems: prdObj.$totalItems || 0,
    $totalItemsPerEan: minMax ? minMax.totalItemsPerEan[eanK] || 0 : 0,
    $parentType: prdObj.parentShelf.type,
    $isGroup: !!prdObj.isGroup,
    $facings: (prdObj as any).facings || 1,
    $stacks: (prdObj as any).stacks || 1,
    $canStack: !!(prdObj as any).canStack,
    $stacksLyingDown: !!(prdObj as any).stacksLyingDown,
    $isFiltered: (prdObj as any)._isFiltered,
    $constraints: (prdObj as any).itemData._constraints,
  };

  //Limite de carga (no puede haber más facings que carga limitada)
  if (prdObj.limitLoadSize && simpleData.$facings * simpleData.$stacks > prdObj.limitLoadSize)
    simpleData.$stacks = Math.min(Math.floor(prdObj.limitLoadSize / simpleData.$facings) || 1, simpleData.$stacks);

  if (prdObj.limitLoadSize && simpleData.$facings * simpleData.$stacks > prdObj.limitLoadSize)
    simpleData.$facings = Math.min(prdObj.limitLoadSize, simpleData.$facings);

  //Si hay minMax (indices máximos y minimos) los agrega / simpre van de 0 a 1 (min-max)
  const indexes = {
    $indx_price: !minMax ? null : _krange(simpleData.price, minMax.minPrice, minMax.maxPrice, 0, 1),
  };

  let _tmpDataWidyhToCalcEsp = prdObj.itemData.size.w;
  if (prdObj.rotateY) {
    _tmpDataWidyhToCalcEsp = prdObj.itemData.size.d;
  }
  if (prdObj.rotation % 180 !== 0) {
    _tmpDataWidyhToCalcEsp = prdObj.itemData.size.h;
  }

  /* Espacio lineal ocupado / Espacio total ocupado */
  let shareSpcOnlyLinealProds = minMax.totalUsedLinealEsp ? (simpleData.$facings * _tmpDataWidyhToCalcEsp) / minMax.totalUsedLinealEsp : 0;

  /* Espacio lineal ocupado / Espacio total lineal */
  let shareSpc = minMax.shelfs_totalLineSpace ? (simpleData.$facings * _tmpDataWidyhToCalcEsp) / minMax.shelfs_totalLineSpace : 0;

  /* Espacio lineal ocupado pero teniendo en cuenta todas las instancias / Espacio total lineal */
  let shareSpcPerEan = minMax.shelfs_totalLineSpace ? (minMax.totalSpacePerEan[eanK] || 0) / minMax.shelfs_totalLineSpace : 0;

  /* ventas / ventas totales (suma) */
  let salesShare = minMax.totalSales ? simpleData.sales / minMax.totalSales : 0;

  /* participacion de espacio / participacion de ventas */
  let ratioSpcSales = salesShare ? (true ? shareSpcPerEan : shareSpc) / salesShare : salesShare;

  if (false) _log('[SHARE]', ratioSpcSales, shareSpc * 100, salesShare * 100);

  const calcData = {
    $netContent: _prdInfo_getNetContent(simpleData),
    $imgFront: _prdInfo_getImgFront(simpleData),
    $remainStockDays: _prdInfo_getExhibDays(simpleData),
    $exhibDays: _prdInfo_getExhibDays(simpleData),
    $stockDays: _prdInfo_getStockDays(simpleData),
    /**/
    $shareSpc: true ? shareSpcPerEan : shareSpc,
    $salesShare: salesShare,
    $ratioSpcSales: ratioSpcSales,
    $shareSpcOnlyLinealProds: shareSpcOnlyLinealProds,
  };

  return {
    ...simpleData,
    ...indexes,
    ...calcData,
    /**/
    calcData: true,
    minMaxData: minMax,
    atrrTypesSelected: prdObj.atrrTypesSelected || null,
  };
};

/* devuleve la data de un planograma mapeada por producto*/
export const _mapProdWithAction = (planogram: PlanogramModel, mapFunction) => {
  if (!planogram || !planogram.modules || !mapFunction) return planogram;

  let newModules = planogram.modules.map(mod => {
    let newShelfs = (mod.shelfs || []).map(shelf => {
      if (!shelf || !shelf.prods || !shelf.prods.length) return shelf;
      return { ...shelf, prods: shelf.prods.map(mapFunction) };
    });
    return { ...mod, shelfs: newShelfs };
  });

  let newFloats = (planogram.floats || []).map(float => {
    if (!float || !float.prods || !float.prods.length) return float;
    return { ...float, prods: float.prods.map(mapFunction) };
  });

  return { ...planogram, modules: newModules, floats: newFloats };
};

/* devuelve si un producto está filtrado */
export const _checkProdIsFiltered = ($prod, $filters) => {
  if (!$filters) return false;

  if ($prod && $prod.itemData && $prod.itemData._isNotProd === true) return false;

  if (!$prod || !$prod.itemData || !($prod.itemData.code || $prod.itemData.ean)) return true;

  let actualFilters = $filters;

  //Por searchQueary
  if (actualFilters.query && actualFilters.query.length > 2) {
    let searchQueary = actualFilters.query;
    let prdName = $prod.itemData.name || '';
    let brand = $prod.itemData.brand || '';
    let ean = $prod.itemData.ean || '';
    let code = $prod.itemData.code || '';

    let hasSearchText = _containNormaliceStrings(`${prdName} ${brand} ${code} ${ean}`, searchQueary, true, true);
    if (!hasSearchText) {
      return true;
    }
  }

  //Por departments /  DES-2023
  if (actualFilters?.departments?.length > 0 && $prod.itemData.departmentId) {
    const departments = actualFilters.departments.map(String);
    const prd_departments = String($prod.itemData.departmentId);

    if (departments.indexOf(prd_departments) === -1) {
      return true;
    }
  }

  //Por cat
  if (actualFilters.categories && actualFilters.categories.length && $prod.itemData.categoryId) {
    const categories = actualFilters.categories.map(String);
    const prd_category = String($prod.itemData.categoryId);

    if (categories.indexOf(prd_category) === -1) {
      return true;
    }
  }

  //Por subCat
  if (actualFilters.categories && actualFilters.subCategories && actualFilters.subCategories.length) {
    const subCategories = actualFilters.subCategories.map(String);
    const prd_subCategory = String($prod.itemData.subCategoryId);

    if (subCategories.indexOf(prd_subCategory) === -1) {
      return true;
    }
  }

  //Por isNew/newItems
  if (actualFilters.newItems && $prod.itemData.isNew != undefined) {
    if (actualFilters.newItems !== $prod.itemData.isNew) {
      return true;
    }
  }

  //Por tags
  if (actualFilters.selectedTags && actualFilters.selectedTags.length && $prod.itemData.tags) {
    const selectedTags = actualFilters.selectedTags;
    const prd_tags = $prod.itemData.tags;
    let _isFilter = false;

    selectedTags.forEach(tag => {
      if (_isFilter) return;

      let selectedTagId = tag.tagId;
      let selectedIncludedValues = tag.includedValues;

      //La selección no tiene valores, aborta
      if (!selectedIncludedValues || !selectedIncludedValues.length || selectedTagId == undefined) return;

      //No matchea con el tag directamente
      if (!prd_tags.map(ptag => ptag.tagId).includes(selectedTagId)) {
        _isFilter = true;
        return;
      }

      prd_tags.forEach(prdtag => {
        if (_isFilter) return;

        let prdtagId = prdtag.tagId;

        //Compara tag-tag solamente
        if (prdtagId === selectedTagId) {
          let prdIncludedValues = prdtag.includedValues;

          //No tiene tags
          if (!prdIncludedValues || !prdIncludedValues.length) _isFilter = true;

          //tiene tags pero ninguno corresponde al seleccionado
          const intersec = _arrIntersection(selectedIncludedValues, prdIncludedValues).length;
          if (!intersec) _isFilter = true;
        }
      });
    });

    if (!true) _log('[selectedTags]', selectedTags, prd_tags);

    return _isFilter;
  }

  return false;
};
