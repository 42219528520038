import { Injectable } from '@angular/core';
import { _cloneDeep, _throwError } from '@shared/aux_helper_functions';
import { AttributeItemModel } from 'core/models/attribute.model';
import { TagAsAttributeSelectionItem } from 'core/models/tags.model';
import { catchError, tap } from 'rxjs/operators';
import { _log, _logTap } from '@shared/aux_helper_environment';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { GenericEntity } from 'core/models/generic-entity.model';

@Injectable()
export class TagsService {
  private config = environment.tagService.api;
  private configBase = environment.apiBaseUrl_prices;

  constructor(private http: HttpClient) {}

  /**
   * createHierarchicalAttributesModel Used to display hierarchical elements in dropdowns.
   * Adds the optional 'groups' object to AttributeItemModel that is then used in html's dropdown component
   * @param attributes {AttributeItemModel[]}
   */
  createHierarchicalAttributesModel($attributes: Array<AttributeItemModel>): Array<AttributeItemModel> {
    const rv = [];

    const attributes = true ? _cloneDeep($attributes) : $attributes;

    if (Array.isArray(attributes) && attributes.length) {
      attributes.forEach(attr => {
        const used = [];
        if (attr && attr.parentId) {
          const attrParent = _cloneDeep(attributes.find(x => x.id === attr.parentId));
          if (attrParent && attrParent.values && Array.isArray(attrParent.values)) {
            attr.groups = _cloneDeep(
              attrParent.values
                .map(value => {
                  const values = _cloneDeep(attr.values.filter(v => v.parentId === value.id));
                  values.forEach(x => {
                    used.push(x.id);
                  });

                  const rvAux = {
                    id: value.id,
                    value: value.value,
                    parentId: value.parentId,
                    values,
                  };

                  return rvAux.values.length ? rvAux : null;
                })
                .filter(x => x !== null)
            );
            attr.parentName = attrParent.name;
            if (used.length !== attr.values.length) {
              // Agregar un grupo con los datos no coincidentes (es un error de parametria interno).
            }
          } else {
            delete attr.parentId;
          }
        }

        rv.push(attr);
      });
    }

    return rv;
  }

  /**
   * setAttributes
   * @param stepObject
   * @param selection
   * @param step
   */
  setAttributes(stepObject, selection: Array<TagAsAttributeSelectionItem>, step: number): Array<TagAsAttributeSelectionItem> {
    let priorTagId = 0;

    for (const key in stepObject) {
      if (key.includes('_') && priorTagId !== +key.split('_')[0]) {
        const tagId = key.split('_')[0];
        const tag = key.split('_')[1];

        const attribute = {
          tagId: +tagId,
          tag,
          includedValues: stepObject[key] || [],
          excludedValues: stepObject[key + '-excluded'] || [],
          step,
        } as TagAsAttributeSelectionItem;

        if (
          stepObject[key] &&
          (stepObject[key].length > 0 || (stepObject[key + '-excluded'] !== undefined && stepObject[key + '-excluded'].length > 0))
        ) {
          selection.push(attribute);
        }
        priorTagId = +tagId;
      }
    }

    return selection;
  }

  // Se adapta seleccion multiple a seleccion simple para presentacion de 26/11 - luego se hace refactor
  setAttributes_ToDo(stepObject, selection: Array<TagAsAttributeSelectionItem>, step: number): Array<TagAsAttributeSelectionItem> {
    let priorTagId = 0;
    _log(stepObject);
    for (const key in stepObject) {
      if (key.includes('_') && priorTagId !== +key.split('_')[0]) {
        const tagId = key.split('_')[0];
        const tag = key.split('_')[1];

        const attribute = {
          tagId: +tagId,
          tag,
          includedValues: [stepObject[key]],
          step,
        } as TagAsAttributeSelectionItem;

        _log(stepObject[key]);

        if (stepObject[key]) {
          selection.push(attribute);
        }
        priorTagId = +tagId;
      }
    }

    return selection;
  }

  /**
   *
   * @param moduleId el id del modulo 1 = items, 2 = store,
   */
  getDinamicttribute(moduleId): Observable<AttributeItemModel[]> {
    const url = `${this.configBase}${this.config.resources.dinamicTags}/` + moduleId;

    return this.http.get<AttributeItemModel[]>(url).pipe(
      tap(data => _logTap(`${TagsService.name}::getStoreABMAttribute (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, TagsService))
    );
  }

  cacheGetFormByIdData = null;
  cacheGetFormByIdParam = null;
  /** se reinicia el cache para que se haga el llamado, esta funcion se utiliza al inicializar el abm de tags para refrescar los creados / editados el resto de llamadas no hace falta limpiar cache */
  resetTagCache() {
    this.cacheGetFormByIdData = null;
    this.cacheGetFormByIdParam = null;
  }

  /*
  devuelve listas de tags, para combo de opciones con Modelo generico id,name/value
  modelId = > 1 - item, 2 - Store, 3 - Category
*/
  getTagsByModuleId(moduleId: number): Observable<Array<GenericEntity>> {
    if (this.cacheGetFormByIdData && this.cacheGetFormByIdParam === moduleId) return of(this.cacheGetFormByIdData);
    let url;

    url = `${this.configBase}${this.config.resources.tagsByModele}/${moduleId}`;

    return this.http.get<Array<GenericEntity>>(url).pipe(
      tap(data => {
        this.cacheGetFormByIdParam = moduleId;
        return data;
      }),
      tap(data => (this.cacheGetFormByIdData = data)),
      tap(data => _logTap(`${TagsService.name}::getTagsByModuleId (tap)\n\tdata: %o`, { data })),
      catchError(error => _throwError(error, TagsService))
    );
  }
}
