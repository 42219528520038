<h6 class="title">Sugeridos para esta pantalla:</h6>
<button mat-menu-item matTooltip="¿Cómo funcionan las Reglas de Margen?" (click)="openVideo('marginRule')">
  <mat-icon>play_circle_outline</mat-icon>
  ¿Cómo funcionan las Reglas de Margen?
</button>
<button mat-menu-item matTooltip="¿Cómo crear una Regla de Competencia?" (click)="openVideo('competitionRule')">
  <mat-icon>play_circle_outline</mat-icon>
  ¿Cómo crear una Regla de Competencia?
</button>
<button mat-menu-item matTooltip="¿Cómo crear una Regla de Margen?" (click)="openVideo('marginRuleZone')">
  <mat-icon>play_circle_outline</mat-icon>
  ¿Cómo crear una Regla de Margen?
</button>
<button
  mat-menu-item
  matTooltip="¿Cómo crear una Regla Parentesco de Productos?"
  (click)="openVideo('parentRuleProduct')"
>
  <mat-icon>play_circle_outline</mat-icon>
  ¿Cómo crear una Regla P. de Productos?
</button>
<button
  mat-menu-item
  matTooltip="¿Cómo crear una Regla Parentesco de Puntos de Venta?"
  (click)="openVideo('parentRuleStore')"
>
  <mat-icon>play_circle_outline</mat-icon>
  ¿Cómo crear una Regla P. de Puntos de Venta?
</button>
<button
  mat-menu-item
  matTooltip="¿Cómo generar Sugerencias de Competencia?"
  (click)="openVideo('competitionSuggestions')"
>
  <mat-icon>play_circle_outline</mat-icon>
  ¿Cómo generar Sugerencias de Competencia?
</button>
<button mat-menu-item matTooltip="¿Cómo generar Sugerencias de Margen?" (click)="openVideo('marginSuggestions')">
  <mat-icon>play_circle_outline</mat-icon>
  ¿Cómo generar Sugerencias de Margen?
</button>
<button mat-menu-item routerLink="/helps/price-help/faqs">
  <mat-icon>help_outline</mat-icon>
  Preguntas Frecuentes
</button>
