import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getShowPermissionName } from 'app/app.config';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {
  permissionName = '';
  showFlag = getShowPermissionName();
  constructor(private dataAnauthorizedService: DataAnauthorizedService) {}
  ngOnInit(): void {
    this.permissionName = this.dataAnauthorizedService.getCustomData();
  }
}

import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class DataAnauthorizedService {
  private customData: any;

  setCustomData(data: any) {
    this.customData = data;
  }

  getCustomData(): string {
    return Array.isArray(this.customData) ? this.customData.join(', ') : '';
  }
}
