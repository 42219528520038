import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { _getEnv, _log } from '@shared/aux_helper_environment';
import { _cloneDeep, _uniqueElementsByKey } from '@shared/aux_helper_functions';
import { NotificationService } from './notifications-panel.service';
import { NotificationModel } from './notifications-panel.model';
import { _getPermanentReadys, FEATURE_ID, FEATURE_KEY } from './notifications-panel.auxs';

export const MAX_NOTIFICATIONS_OLD = _getEnv('notifications.maxServerReadNotif', 100);
export const MAX_NOTIFICATIONS_NEW = _getEnv('notifications.maxServerNoReadNotif', 100);

const _devTest = false;

// ACTIONS
export class AddNewNotifications {
  static readonly type = `${FEATURE_KEY} Add Notifications`;
  constructor(readonly payload: any) {}
}

export class MarkReadNotification {
  static readonly type = `${FEATURE_KEY} Mark Readed Notifications`;
  constructor(readonly payload: any) {}
}

export class MarkReadAllNotification {
  static readonly type = `${FEATURE_KEY} Mark Readed All Notifications`;
}

// STORE
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
@State<any>({
  name: FEATURE_ID,
  defaults: {
    notifications: null,
    oldNotifications: null,
    newNotifications: null,
  },
})
export class NotificationsState {
  @Selector()
  static getNotifications(state: any): NotificationModel {
    return _cloneDeep(state.notifications);
  }

  @Selector()
  static getNotificationsSize(state: any): number {
    return NotificationsState.getNotifications(state).length;
  }

  @Selector()
  static getOldNotifications(state: any): NotificationModel {
    return _cloneDeep(state.oldNotifications);
  }

  @Selector()
  static getOldNotificationsSize(state: any): number {
    return NotificationsState.length;
  }

  @Selector()
  static getNotificationsNew(state: any): NotificationModel {
    // return _cloneDeep(
    //   state.newNotifications.map(notif => {
    //     return { ...notif, detail: notif.detail + '1\n2' };
    //   })
    // );
    return _cloneDeep(state.newNotifications);
  }

  @Selector()
  static getNotificationsNewSize(state: any): number {
    return state.newNotifications.length;
  }

  constructor(private notificationService: NotificationService, private store: Store) {
    notificationService.getItems().subscribe(items => {
      if (false && _devTest) {
        _log('[NotificationsState getItems]', items);
      }

      if (!Array.isArray(items) && items !== null && typeof items === 'object') {
        items = [items];
      }

      const newAction = new AddNewNotifications(items);
      this.store.dispatch(newAction);
    });
  }

  @Action(AddNewNotifications)
  addNewNotifications(context: StateContext<any>, action: AddNewNotifications): void {
    let currentNotifications = action.payload;

    if (currentNotifications === null) {
      //Loading
      context.patchState({
        notifications: null,
        oldNotifications: null,
        newNotifications: null,
      });
      return;
    }

    currentNotifications = _uniqueElementsByKey(currentNotifications, 'id');
    currentNotifications = currentNotifications.sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf());

    const newNotifications = currentNotifications.filter(n => !n._readed).slice(0, MAX_NOTIFICATIONS_NEW);
    const oldNotifications = currentNotifications.filter(n => n._readed).slice(0, MAX_NOTIFICATIONS_OLD);

    if (true && _devTest) {
      _log('[addNewNotifications]', {
        oldNotifications,
        payload: action.payload,
        newNotifications,
        permanentReadys: _getPermanentReadys(),
      });
    }

    context.patchState({
      notifications: _cloneDeep(currentNotifications),
      oldNotifications: _cloneDeep(oldNotifications),
      newNotifications: _cloneDeep(newNotifications),
    });
  }

  @Action(MarkReadNotification)
  markReadNotification(context: StateContext<any>, action: MarkReadNotification): void {
    this.notificationService.clearItem(action.payload);
  }

  @Action(MarkReadAllNotification)
  markReadAllNotification(context: StateContext<any>, action: MarkReadAllNotification): void {
    this.notificationService.clearAllItems();
  }
}
