import { IMV2_ItemAssortmentBehavior, IMV2_priceinitializationmethod } from './stores/IMV2_FormStateModel';

export const dummy_IM_item = {
  id: 1,
  name: 'Test Retail CocaCola',
  shortName: 'Test para CocaCola',
  discriminator: 'Item',
  categoryId: 202,
  categoryCode: null,
  code: '123123123',
  ean: '24242424',
  itemCreatedOn: '2021-06-14T00:00:00',
  tagValues: [
    {
      id: 9,
      value: '50',
    },
    {
      id: 9940,
      value: 'Entre Ríos',
    },
  ],
  unitsOfMeasure: [
    {
      id: -1,
      code: 'U',
      name: 'Unidad',
      conversion: '12',
      isSellingUnit: true,
      isBaseUnitOfMeasure: true,
      initialPrice: '1',
      hasExhibition: false,
    },
    {
      id: -2,
      code: 'B',
      name: 'Bulto',
      conversion: '6',
      isSellingUnit: false,
      isBaseUnitOfMeasure: false,
      hasExhibition: false,
    },
    {
      id: -3,
      code: 'U',
      name: 'Unidad',
      conversion: '24',
      isSellingUnit: false,
      isBaseUnitOfMeasure: false,
    },
  ],
  vendors: [
    {
      vendorId: 1,
      vendorCode: '5_ITEMS',
      vendorItemNumber: 'testCode1',
      isSecondary: false,
      orderUnitOfMeasureId: -3,
      orderUnitOfMeasureCode: 'U',
      minimumOrder: 100,
      maximumOrder: 500,
      tagValueId: 6,
      costs: [
        {
          cost1Code: 'ITEMCOST',
          cost: 1,
          cost2Code: 'FLETE',
          cost2: 2,
          tax1Code: 'TAX',
          tax: 3,
          tax2Code: 'IVA',
          tax2: 4,
          tagValueId: 35,
          tagValueName: 'Capital alta ',
          cost3: null,
          tax3: null,
        },
        {
          cost1Code: 'ITEMCOST',
          cost: 5,
          cost2Code: 'FLETE',
          cost2: 6,
          tax1Code: 'TAX',
          tax: 7,
          tax2Code: 'IVA',
          tax2: 8,
          tagValueId: 36,
          tagValueName: 'Capital media ',
          cost3: null,
          tax3: null,
        },
      ],
      orderUnitOfMeasureConversion: 24,
    },
    {
      vendorId: 3,
      vendorCode: 'FULL',
      isSecondary: true,
      orderUnitOfMeasureId: -2,
      orderUnitOfMeasureCode: 'B',
      minimumOrder: 1000,
      maximumOrder: 15000,
      tagValueId: 7,
      costs: [
        {
          cost1Code: 'ITEMCOST',
          cost: 9,
          cost2Code: 'FLETE',
          cost2: 10,
          tax1Code: 'TAX',
          tax: 11,
          tax2Code: 'IVA',
          tax2: 12,
          tagValueId: 25,
          tagValueName: 'Buenos aires',
          cost3: null,
          tax3: null,
        },
        {
          cost1Code: 'ITEMCOST',
          cost: 13,
          cost2Code: 'FLETE',
          cost2: 14,
          tax1Code: 'TAX',
          tax: 15,
          tax2Code: 'IVA',
          tax2: 16,
          tagValueId: 26,
          tagValueName: 'Capital federal',
          cost3: null,
          tax3: null,
        },
      ],
      orderUnitOfMeasureConversion: 6,
      vendorItemNumber: '1',
    },
    {
      vendorId: 2,
      vendorCode: 'HALF',
      isSecondary: true,
      orderUnitOfMeasureId: -2,
      orderUnitOfMeasureCode: 'B',
      minimumOrder: 1,
      maximumOrder: 50,
      tagValueId: 6,
      costs: [
        {
          cost1Code: 'ITEMCOST-1',
          cost: 17,
          cost2Code: 'FLETE-2',
          cost2: 18,
          cost3: 0,
          tax1Code: 'TAX-1',
          tax: 19,
          tax2Code: 'IVA-2',
          tax2: 20,
          tax3: 0,
          tagValueId: null,
          tagValueName: 'Costo único',
        },
      ],
      vendorItemNumber: 'CodeInterno2',
      orderUnitOfMeasureConversion: 6,
    },
    {
      vendorId: 100,
      vendorCode: 'FMS',
      isSecondary: true,
      orderUnitOfMeasureId: -1,
      orderUnitOfMeasureCode: 'U',
      minimumOrder: 10,
      maximumOrder: 10,
      tagValueId: 0,
      costs: [
        {
          cost1Code: 'ITEMCOST-1',
          cost: 21,
          cost2Code: 'FLETE-2',
          cost2: 22,
          tax1Code: 'TAX-1',
          tax: 23,
          tax2Code: 'IVA-2',
          tax2: 24,
          tagValueId: null,
          tagValueName: 'Costo único',
          cost3: null,
          tax3: null,
        },
      ],
      vendorItemNumber: '1',
      orderUnitOfMeasureConversion: 12,
    },
  ],
  codes: [
    {
      id: 5730,
      itemId: 7214,
      unitOfMeasureCode: 'U',
      unitOfMeasureId: -1,
      code: '7791274198991',
      isMain: true,
      typeId: 1,
    },
  ],
  assortmentBehavior: IMV2_ItemAssortmentBehavior.BasedOnAssortment,
  isBlockedForSales: true,
  isBlockedForPurchase: true,
};

export const dummy_IM_item_real = {
  id: 103,
  code: 'S001.TEST4',
  name: 'Description S001.TEST4',
  shortName: null,
  isRegionalProduct: true,
  ean: '1234578902',
  imageUrl: null,
  categoryId: 538,
  categoryCode: 'FPH338505',
  categoryName: 'Horse Nutrition & Supplements',
  parentCategoryId: 49,
  parentCategoryName: 'Horse Supplies',
  grandParentCategoryId: 6,
  grandParentCategoryName: 'Pets',
  baseUnitOfMeasureCode: 'U',
  isBlockedForSales: false,
  isBlockedForPurchase: false,
  assortmentBehaviorHasChanged: false,
  salesTaxRate: 0.21,
  tax: null,
  purchaseTaxRate: null,
  itemCreatedOn: '2022-07-06T21:00:00-03:00',
  status: 'Current',
  publishStatus: 'None',
  clonedFromItemId: null,
  clonePricesFromItemId: null,
  recipe: null,
  combo: null,
  tagValues: [],
  tagValuesId: [],
  unitsOfMeasure: [
    {
      id: 102,
      code: 'U',
      name: 'Unidad',
      conversion: 1,
      isSellingUnit: true,
      itemCode: 'S001.TEST4',
      isBaseUnitOfMeasure: true,
      isConsumeUnitOfMeasure: false,
      itemId: 103,
      imageUrl: null,
      height: null,
      width: null,
      depth: null,
      stackable: null,
      maxStacks: null,
      canLayDown: null,
      canRotate: null,
      imagePlanogramFront: null,
      imagePlanogramLateral: null,
      imagePlanogramTop: null,
      canRotateY: null,
      hasExhibition: false,
      initialPrice: 20.4,
    },
  ],
  codes: [
    {
      id: 102,
      itemId: 103,
      itemCode: null,
      unitOfMeasureCode: 'U',
      unitOfMeasureConversion: 1,
      unitOfMeasureId: 102,
      code: '1234578902',
      isMain: true,
      typeId: 1,
    },
  ],
  vendors: [
    {
      id: 120,
      vendorId: 1,
      vendorCode: 'NOVENDOR',
      isSecondary: false,
      orderUnitOfMeasureId: 102,
      orderUnitOfMeasureCode: 'U',
      orderUnitOfMeasureConversion: 1,
      vendorItemNumber: null,
      minimumOrder: 0,
      maximumOrder: 0,
      costs: [
        {
          vendorId: 1,
          vendorCode: 'NOVENDOR',
          vendorName: '',
          itemId: 103,
          itemCode: 'S001.TEST4',
          itemName: null,
          cost: 10.2,
          discount: null,
          cost2: null,
          cost3: null,
          tax: null,
          tax2: null,
          tax3: null,
          validFrom: '2022-07-07T00:00:00-03:00',
          validTo: null,
          orderUnitOfMeasureCode: 'U',
          orderUnitOfMeasureId: 102,
          tagValueName: null,
          tagValueId: null,
          cost1Code: 'ITEMCOST',
          cost2Code: null,
          cost3Code: null,
          tax1Code: null,
          tax2Code: null,
          tax3Code: null,
        },
      ],
    },
  ],
  discriminator: 'Item',
  assortmentBehavior: 'OnlyStoreAssortment',
  assortmentBehaviorNew: null,
  priceInitializationMethod: 'NationalPrice',
  customItemOfStoreId: 2,
  pricesPublishedDate: '2022-07-07T05:05:04-03:00',
  assortmentPublishedDate: '2022-07-07T05:04:58-03:00',
  promotionsPublishedDate: null,
  spacesPublishedDate: null,
  supplyPublishedDate: null,
  mysteryShopperPublishedDate: null,
  byPassWarning: null,
  warningCosts: null,
  responseSuccess: true,
  responseMessages: [],
};

/* 
{
  id: 9217,
  code: 'AT212345',
  name: 'AleTest2',
  shortName: 'AleTest Short Desc2',
  ean: '123456789',
  imageUrl: '',
  categoryId: 202,
  categoryCode: null,
  baseUnitOfMeasureCode: 'U',
  salesTaxRate: null,
  tax: null,
  purchaseTaxRate: null,
  itemCreatedOn: '2021-06-18T17:50:17.032',
  status: 1,
  clonedFromItemId: null,
  recipe: null,
  combo: null,
  tagValues: [
    {
      id: 9,
      value: '50',
    },
    {
      id: 9940,
      value: 'Entre Ríos',
    },
    {
      id: 174,
      value: 'Molinos',
    },
  ],
  unitsOfMeasure: [
    {
      id: 12266,
      code: 'U',
      name: 'Unidad',
      conversion: 1.0,
      isSellingUnit: true,
      itemCode: 'AT212345',
      isBaseUnitOfMeasure: true,
      isConsumeUnitOfMeasure: false,
      itemId: 9217,
      imageUrl: null,
      height: null,
      width: null,
      depth: null,
      stackable: false,
      maxStacks: null,
      canLayDown: null,
      canRotate: null,
      imagePlanogramFront: null,
      imagePlanogramLateral: null,
      imagePlanogramTop: null,
      canRotateY: null,
      hasExhibition: false,
      initialPrice: 120.0,
    },
    {
      id: 12267,
      code: 'B',
      name: 'Bulto',
      conversion: 6.0,
      isSellingUnit: false,
      itemCode: 'AT212345',
      isBaseUnitOfMeasure: false,
      isConsumeUnitOfMeasure: false,
      itemId: 9217,
      imageUrl: null,
      height: null,
      width: null,
      depth: null,
      stackable: false,
      maxStacks: null,
      canLayDown: null,
      canRotate: null,
      imagePlanogramFront: null,
      imagePlanogramLateral: null,
      imagePlanogramTop: null,
      canRotateY: null,
      hasExhibition: false,
      initialPrice: null,
    },
  ],
  codes: [
    {
      id: 5730,
      itemId: 7214,
      unitOfMeasureCode: 'B',
      unitOfMeasureId: 12267,
      code: '7791274198991',
      isMain: true,
      typeId: 1,
    },
  ],
  vendors: [
    {
      id: 713935,
      vendorId: 4,
      vendorCode: 'NOVENDOR',
      isSecondary: false,
      orderUnitOfMeasureId: 12267,
      orderUnitOfMeasureCode: 'B',
      orderUnitOfMeasureConversion: 6,
      vendorItemNumber: '1111',
      minimumOrder: 5,
      maximumOrder: 100,
      costs: [
        {
          vendorId: 48,
          vendorCode: '3054775125',
          vendorName: '',
          itemId: 9217,
          itemCode: 'AT212345',
          itemName: null,
          cost: 45.6,
          discount: null,
          cost2: 0,
          cost3: 0,
          tax: 4.2,
          tax2: 0.21,
          tax3: null,
          validFrom: '2021-06-18T00:00:00',
          validTo: null,
          orderUnitOfMeasureCode: 'B',
          orderUnitOfMeasureId: -1,
          tagValueName: 'Costo único',
          tagValueId: null,
          cost1Code: 'ITEMCOST',
          cost2Code: null,
          cost3Code: null,
          tax1Code: 'TAX',
          tax2Code: 'TAXRATE',
          tax3Code: null,
        },
      ],
      tagValueId: 6,
      _vendorName: 'SIN VENDOR',
    },
    {
      _vendorName: 'FEMSA ',
      vendorId: 100,
      vendorCode: 'FMS',
      isSecondary: true,
      orderUnitOfMeasureId: 12266,
      orderUnitOfMeasureCode: 'U',
      orderUnitOfMeasureConversion: 1,
      minimumOrder: 0,
      maximumOrder: 0,
      tagValueId: 7,
      costs: [
        {
          cost1Code: 'ITEMCOST-1',
          cost: '1',
          cost2Code: 'FLETE-2',
          cost2: 0,
          cost3: 0,
          tax1Code: 'TAX-1',
          tax: 0,
          tax2Code: 'IVA-2',
          tax2: 0.21,
          tax3: 0,
          tagValueId: 25,
          tagValueName: 'Buenos aires',
        },
        {
          cost1Code: 'ITEMCOST-1',
          cost: '2',
          cost2Code: 'FLETE-2',
          cost2: 0,
          cost3: 0,
          tax1Code: 'TAX-1',
          tax: 0,
          tax2Code: 'IVA-2',
          tax2: 0.21,
          tax3: 0,
          tagValueId: 26,
          tagValueName: 'Capital federal',
        },
        {
          cost1Code: 'ITEMCOST-1',
          cost: '3',
          cost2Code: 'FLETE-2',
          cost2: 0,
          cost3: 0,
          tax1Code: 'TAX-1',
          tax: 0,
          tax2Code: 'IVA-2',
          tax2: 0.21,
          tax3: 0,
          tagValueId: 27,
          tagValueName: 'Cordoba',
        },
      ],
      tagValueName: 'Region',
    },
  ],
  discriminator: 'Item',
  assortmentBehavior: IMV2_ItemAssortmentBehavior.BasedOnAssortment,
  isBlockedForSales: true,
  isBlockedForPurchase: true,
  priceInitializationMethod: IMV2_priceinitializationmethod.NationalPrice,
  customItemOfStoreId: null,
  clonePricesFromItemId: null,
  responseSuccess: true,
  responseMessages: [],
}; */

export const dummy_dropdown_vendors_item_master = [
  {
    id: 4,
    code: 'NOVENDOR',
    value: 'SIN VENDOR',
    name: 'SIN VENDOR',
    usesOwnItemNumbers: false,
    tagValueId: 6,
  },
  {
    id: 1,
    code: '5_ITEMS',
    name: 'VENDOR 5 ITEMS',
    usesOwnItemNumbers: false,
    tagValueId: 6,
  },
  {
    id: 3,
    code: 'FULL',
    name: 'VENDOR FULL',
    usesOwnItemNumbers: true,
    tagValueId: 7,
  },
  {
    id: 2,
    code: 'HALF',
    name: 'VENDOR HALF',
    usesOwnItemNumbers: false,
    tagValueId: 6,
  },
  {
    id: 100,
    code: 'FMS',
    name: 'FEMSA ',
    usesOwnItemNumbers: false,
    tagValueId: null,
  },
];

// /api/taxes/get-taxes
export const dummy_get_taxes = [
  {
    code: 'TAX-1',
    name: 'IMPUESTO INTERNO',
    calculationType: 1, //uno number 2/3 percent
    isRequired: false,
  },
  {
    code: 'IVA-2',
    name: 'IVA',
    calculationType: 2, //uno number 2/3 percent
    defaultValue: 0.21,
    isRequired: true,
  },
];

export const dummy_get_taxes_2 = [
  {
    code: 'TAXRATE',
    name: 'Tax Rate',
    calculationType: 3,
    calculationTypeCostId: null,
    isNational: true,
    defaultValue: 0.21,
    isRequired: true,
  },
  { code: 'TAX', name: 'Tax', calculationType: 1, calculationTypeCostId: null, isNational: false, defaultValue: null, isRequired: false },
];

export const dummy_get_taxes_3 = [
  { calculationType: 1, calculationTypeCostId: null, code: 'TAX', defaultValue: null, isNational: false, isRequired: false, name: 'Tax_C' },
];

// api/costs/get-costs
export const dummy_get_costs = [
  {
    code: 'ITEMCOST-1',
    name: 'COSTO',
    calculationType: 1, //uno number 2/3 percent
    isRequired: true,
  },
  {
    code: 'FLETE-2',
    name: 'FLETE',
    calculationType: 3, //uno number 2/3 percent
    isRequired: false,
  },
];

export const dummy_getAssortmentSegmentByCategory = {
  categoryId: 395,
  categoryName: 'Bebidas / Bebidas sin Alcohol',
  assortmentTagId: null,
  clusterizationId: 19,
  level: 4,
};

export const dummy_get_item_code_types = [
  {
    id: 1,
    type: 'Ean',
    isUnique: true,
    hasUnitOfMeasure: true,
    regularExpression: '^[0-9]{9,13}?$', //regex para EAN probar: ^(\d{8}|\d{13}|\d{14})$
    isRequired: false,
  },
  {
    id: 2,
    type: 'ExternalCode',
    isUnique: true,
    isRequired: true,
    hasUnitOfMeasure: false,
    regularExpression: '',
  },
  {
    id: 3,
    type: 'ExternalCode2',
    isUnique: false,
    isRequired: false,
    hasUnitOfMeasure: false,
    regularExpression: '',
    defaultValue: '123-444',
  },
];

let _null = {
  id: 5730,
  itemId: 7214,
  unitOfMeasureCode: 'U',
  unitOfMeasureId: 8832,
  code: '7791274198991',
  isMain: true,
  typeId: 1,
};
