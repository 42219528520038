import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterContentInit, Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { MatColumnDef, MatRowDef } from '@angular/material/table';
import { transitions } from '@prisma/animations/animations';
import { _log } from '@shared/aux_helper_environment';
import { _cloneDeep } from '@shared/aux_helper_functions';
import { PrismaTableParentComponent } from '../prisma-table-parent.component';

import { collapseColumnConfig, collapseColumnName, _getColumns, _getDinamicColumnSelected } from '../prisma-table-parent.component';
@Component({
  selector: 'prisma-table-sub-row',
  templateUrl: './prisma-table-sub-row.component.html',
  styleUrls: ['../prisma-table/prisma-table.component.scss'],
  animations: [
    transitions,
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PrismaTableSubRowComponent<T> extends PrismaTableParentComponent<T> implements OnInit, AfterContentInit {
  /**
   * agrega componente para tener subrow la subrow
   */
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  // sirve para calcular el margen que denota el nivel de la subrow como tambien para saber en que nivel se encuentra.

  // El que inicia en la tabla es 0, este seria el nivel 1 porque seria la primer llamada recursiva del componente

  @Input('levelTree') levelTree = 1;
  @Input('hasLeftSpace') hasLeftSpace = true;

  ngOnInit() {
    // si la subrow no tiene desplegable agrego un espacio
    if (!this.attrSubRows && this.addSpace && this.hasLeftSpace) this.addColumnLeftSpace();
  }

  /**
   * Agrega una columna vacia con un ancho fx predefinido para agregar un espacio en la subrow y que se diferencie o alinie con la fila superior
   */
  addColumnLeftSpace() {
    const subRowSpaceColumnName = 'spaceColumn';

    const subRowSpaceColumn = {
      column: subRowSpaceColumnName,
      markOnNull: true,
      type: 'text',
      colSpan: this._colSpanDropDown,
      class: '',
      headerTitle: '',
      isChecked: true,
      isEditable: true,
    };

    const _columnsConfig = this.columnsConfig.map((group, index) => {
      if (index === 0) {
        let _group = _cloneDeep(group);
        return this.addColumnAfterSelect(_group, subRowSpaceColumn, subRowSpaceColumnName);
      } else {
        return group;
      }
    });

    this.totalColSpan++;

    // definicion de data principal de la tabla, headerDef columnDef

    this.headerValues = _getDinamicColumnSelected(_columnsConfig, false);

    this.displayedColumns = _getColumns(_getDinamicColumnSelected(_columnsConfig, true));
  }

  ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
    this.rowDefs.forEach(rowDef => this.table.addRowDef(rowDef));
    _log('[subrow level: ' + this.levelTree + ']', this.headerValues, this.displayedColumns, this.columnDefs, this.rowDefs);
  }
}
