import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule, MatSelectionList } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSharedModule } from '@fuse/shared.module';
import { SharedModule } from 'app/shared.module';
import { EditJsonModal } from './components/edit-json-modal.component';
import { GlobalSettingsBrandCustomizationComponent } from './components/global-settings-brand-customization';
import { GlobalSettingsCasuistryComponent } from './components/global-settings-casuistry';
import { GlobalSettingsDevSettingsComponent } from './components/global-settings-dev-settings';
import { GlobalSettingsFeatureFlagsComponent } from './components/global-settings-feature-flags';
import { GlobalSettingsLocalStorageComponent } from './components/global-settings-localStorage';
import { GlobalSettingsPanelComponent } from './global-settings-panel.component';

@NgModule({
  declarations: [
    GlobalSettingsPanelComponent,
    GlobalSettingsDevSettingsComponent,
    GlobalSettingsFeatureFlagsComponent,
    GlobalSettingsBrandCustomizationComponent,
    GlobalSettingsLocalStorageComponent,
    GlobalSettingsCasuistryComponent,
    EditJsonModal,
  ],
  imports: [
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    FuseSharedModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatSelectModule,
    CommonModule,
    SharedModule,
  ],
  exports: [
    MatSelectionList,
    GlobalSettingsPanelComponent,
    GlobalSettingsDevSettingsComponent,
    GlobalSettingsFeatureFlagsComponent,
    GlobalSettingsBrandCustomizationComponent,
    GlobalSettingsLocalStorageComponent,
    GlobalSettingsCasuistryComponent,
    EditJsonModal,
  ],
  providers: [],
})
export class GlobalSettingsPanelModule {}
