import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { _log, _isDev, _warn, _isFeDev, _error } from '@shared/aux_helper_environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpCancelService } from './http-cancel.service';

const _devTest = false;
const _devTestErrors = false && _isFeDev();
const _devTestTimeOut = false && _isFeDev();
const _devTestErrorsTO: number = 1024 * 5;
const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';

const _saveToken = true;
let _tokenIsSaved = false;

import { ErrorMessageService } from '@prisma/services/error-message.service';
import { MessagingService } from './messaging.service';
import { _get } from '@shared/aux_helper_functions';
import { StatusConecctionService } from './status_conecction.service';
import { GlobalAlertService, IanTranslateService } from 'core/services/ian-core-singleton.service';
import { DevSettingsService } from 'core/services/dev-settings.service';

const _getFormatDate = (date?: any, outputArray = false): Date | any => {
  let dateString: string = null;

  if (date == null) {
    dateString = new Date().toISOString();
  } else if (typeof date === 'string') {
    dateString = date;
  } else if (date?.toISOString != null) {
    dateString = (date as Date).toISOString();
  } else if (typeof date === 'number') {
    dateString = new Date(date).toISOString();
  }

  if (!(typeof dateString === 'string') || !dateString.includes('T') || !(dateString?.length > 0)) {
    _error('Fecha no valida, se toma el día de hoy, fecha: ' + date + ' / ' + dateString);
    return new Date();
  }

  dateString = new Date(Date.now()).toISOString();
  const dateStringArr = dateString.split('T')[0].replace(/-/g, '/').split('/');

  if (outputArray) return dateStringArr;

  if (dateStringArr.length < 3) {
    _error('Fecha no valida, se toma el día de hoy, fecha: ' + date + ' / ' + dateString);
    return new Date();
  }

  return new Date(Number(dateStringArr[0]), Number(dateStringArr[1]) - 1, Number(dateStringArr[2]));
};

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  [x: string]: any;
  private oidcSecurityService: OidcSecurityService;

  constructor(
    private http: HttpClient,
    private injector: Injector,
    private router: Router,
    private httpCancelService: HttpCancelService,
    private permissionsService: NgxPermissionsService,
    private errorMessageService: ErrorMessageService,
    private messagingService: MessagingService,
    private statusConecctionService: StatusConecctionService,
    private translate: IanTranslateService,
    private globalAlertService: GlobalAlertService,
    private devSettingsService: DevSettingsService
  ) {
    if (_devTestErrors) this.testErrorsEP();
    if (_devTestTimeOut) this.testTimeOutEP();
  }

  testErrorsEP() {
    setTimeout(() => {
      this.__testEP_errorCode();
    }, _devTestErrorsTO);
  }

  testTimeOutEP() {
    setTimeout(() => {
      this.__testEP_timeOut();
    }, _devTestErrorsTO);
  }

  private __testEP_errorCode() {
    let urlsErrorToTest = ['assets/test.error_warning.json', 'assets/test.error_no_ok.json', 'assets/test.error.json'];

    this.http
      .get(urlsErrorToTest[0])
      .pipe(
        tap(data => (false ? _log('\n\n[__testEP_error] ok', data) : null)),
        catchError(error => {
          if (false) _log('\n\n[__testEP_error] fail', error);
          return null;
        })
      )
      .subscribe();
  }

  private __testEP_timeOut() {
    const cudOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', timeout: `${120000}` }),
    };

    let payLoad =
      '{"states":[1,2],"actionTypes":[],"priceTypes":[],"itemType":null,"onlyParents":true,"categories":[],"subcategories":[],"query":"","storeTags":[],"itemTags":[],"ruleTypes":[],"groupId":null}';

    let URL = _get(environment, 'apiBaseUrl_prices') + 'decisions/kpis';

    console.time();

    this.http
      .post(URL, payLoad, cudOptions)
      .pipe(
        tap(data => {
          _log('\n\n\n__testEP_timeOut', data);
          console.timeEnd();
        }),
        catchError(error => {
          _log('\n\n\n__testEP_timeOut', error);
          console.timeEnd();
          return null;
        })
      )
      .subscribe();
  }

  private checkCustomError($event) {
    const eventError = $event;

    const responseSuccess = eventError?.body?.responseSuccess !== false;

    if (false) console.warn('[checkCustomError]', eventError);

    let responseMessages = eventError?.body?.responseMessages;
    if (responseMessages && responseMessages.length) {
      responseMessages = responseMessages[0];
    }

    let forcedErrorMsg = eventError?.body?.errorMessage || null;

    let _type = String(responseMessages?.type || '')
      .trim()
      .toLowerCase();

    // si se envia customError como type se evita el interceptor para usar
    // uno custom en el componente ejemplo modalMassivePublishError
    if (_type === 'customerror') return null;
    if (_type !== 'error' && _type !== 'warning' && _type !== 'information' && forcedErrorMsg == null) {
      return null;
    }

    let _errorMsg = null;
    let errorKey = 'CUSTOM_ERRORS.' + responseMessages?.keyword;
    let errorKeyAlt = 'INTERFACE_LOG_MSG_KEYS.' + responseMessages?.keyword;
    let errorValues = responseMessages?.values || null;

    //Si es un error code, intenta traducir
    if (responseMessages?.keyword) {
      errorValues = typeof errorValues === 'string' ? errorValues.split(',').map(ev => (ev ? ev.trim() : null)) : errorValues;
      _errorMsg = this.translate.instant(errorKey, errorValues) || this.translate.instant(errorKeyAlt, errorValues) || errorKey;
    }

    //Es solo un warning
    let _isWarning = _type === 'warning' || _type === 'information';

    if (_isWarning && _errorMsg) {
      _warn('\n\n[TokenInterceptorService] ONLY WARNING');
      this.globalAlertService.simpleWarning(_errorMsg, null, null, _type === 'warning');
      return;
    }

    //Si no puede traducir intenta con errorMessage
    if (_errorMsg == null) {
      _errorMsg = forcedErrorMsg && typeof forcedErrorMsg === 'string' && forcedErrorMsg.length ? forcedErrorMsg : null;
    }

    //Si no puede le asigna un msg genérico
    if (!_errorMsg?.length) {
      _warn(
        '\n\n[TokenInterceptorService] no errorMsg Text:',
        _errorMsg,
        { errorKey, errorValues, responseSuccess, responseMessages },
        '\n\n'
      );

      _errorMsg = this.translate.instant('COMP.ERROR_MESSAGE.SUBTITLE') || null;
    }

    //Mensaje Custom ok, lo envía como 406
    let statusId = 406;
    const error = new HttpErrorResponse({
      error: { ...eventError.body, status: statusId, forceMsg: _errorMsg, errorLink: responseMessages?.link || null, type: _type },
      status: statusId,
      statusText: _errorMsg,
    } as any);

    if (true) {
      console.warn(
        '\n\n[TokenInterceptorService] checkCustomError',
        {
          errorCode: eventError.body.errorCode,
          eventError,
          responseSuccess,
          responseMessages,
          errorMsg: _errorMsg,
          errorKey,
          errorValues,
          type: _type,
        },
        '\n\n'
      );
    }

    return error;
  }

  intercept<T>(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<T>> {
    let requestToForward = req;

    if (this.oidcSecurityService === undefined) {
      this.oidcSecurityService = this.injector.get(OidcSecurityService);
    }

    if (this.oidcSecurityService !== undefined) {
      const tmpBrowserId: string = this.messagingService.getToken();

      if (!req.url.includes('assets/') /* No inyecta para locales */) {
        const token = this.oidcSecurityService.getToken();
        if (token !== '') {
          const tokenValue = 'Bearer ' + token;
          const header = { Authorization: tokenValue };

          if (tmpBrowserId) (header as any).browserId = tmpBrowserId;

          if (true) (header as any).featureFlags = this.devSettingsService?.__getAllEnabledFeatureFlagsValues?.()?.join?.(','); //2076

          if (true) (header as any)['Client-Date'] = new Date().toISOString();
          if (true) (header as any)['Client-Date-Array'] = _getFormatDate(Date.now(), true);

          requestToForward = req.clone({ setHeaders: header });

          if (_saveToken && !_tokenIsSaved) {
            // Save Token
            localStorage.setItem(_keyVer + '__token', tokenValue);
            _tokenIsSaved = true;
          }
        } else {
          // id:8EqrPjg3oD
          const _token = localStorage.getItem(_keyVer + '__token');
          if (_saveToken && _token) {
            // Load Token
            const header = { Authorization: _token };
            if (tmpBrowserId) (header as any).browserId = tmpBrowserId;
            requestToForward = req.clone({ setHeaders: header });

            if (_devTest) {
              _log('[USE SAVED TOKEN]', req.url);
            }
          } else {
            if (_devTest) {
              console.log('[NO TOKEN]', req.url);
            }
          }
        }
      }
    } else {
      console.log('OidcSecurityService undefined: NO auth header!');
    }

    return next.handle(requestToForward).pipe(
      map(event => {
        if (!environment || !event) return event;

        const error = this.checkCustomError(event);
        if (error != null) throw error;

        /*
          Para dev-azd hace un replace de las props de get-authconfig
          redirect_url
          silent_renew_url
          post_logout_redirect_uri

          remplaza forceOidcSecurityServiceRedirect (url) por forceOidcSecurityServiceRedirectBy
        */
        const acutalServiceRedirect = environment.forceOidcSecurityServiceRedirect;
        if (!acutalServiceRedirect) return event;

        const newServiceRedirect = environment.forceOidcSecurityServiceRedirectBy;
        if (!newServiceRedirect) return event;

        if (event instanceof HttpResponse) {
          const redirect_url = _get(event, 'body.redirect_url');
          const silent_renew_url = _get(event, 'body.silent_renew_url');
          const post_logout_redirect_uri = _get(event, 'body.post_logout_redirect_uri');

          if (redirect_url) event.body.redirect_url = redirect_url.replace(acutalServiceRedirect, newServiceRedirect);
          if (silent_renew_url) event.body.silent_renew_url = silent_renew_url.replace(acutalServiceRedirect, newServiceRedirect);
          if (post_logout_redirect_uri)
            event.body.post_logout_redirect_uri = post_logout_redirect_uri.replace(acutalServiceRedirect, newServiceRedirect);
        }
        return event;
      }),

      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // Load user permissions
            if (event.body && event.body.permission) {
              // TODO: check for an standard api response
              const perm = event.body.permission;
              this.permissionsService.loadPermissions(perm);
            }
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (
              this.router.url.includes('Logout') ||
              this.router.url.includes('user-missing-permission') ||
              (err?.url || '').includes('notification-log') /*No echar si es por notificaciones*/
            ) {
              return;
            }

            if (err.status === 401) {
              this.httpCancelService.cancelPendingRequests();

              if (this.oidcSecurityService === undefined) {
                this.oidcSecurityService = this.injector.get(OidcSecurityService);
              }

              if (this.oidcSecurityService !== undefined) {
                this.oidcSecurityService.authorize();
              }

              return;
            }

            if (err.status === 403) {
              this.httpCancelService.cancelPendingRequests();
              setTimeout(() => {
                let dataMsg = {};
                if (true) {
                  (dataMsg as any).forceMsg = this.translate.instant('CUSTOM_ERRORS.UNAUTHORIZED_ACCESS');
                  (dataMsg as any).error = null;
                  (dataMsg as any).responseMessages = null;
                  if (true) console.log(['ERRORS_CUSTOM_BE_ERRORS'], { dataMsg: dataMsg });
                }

                if (true) console.warn('[dataMsg]', { dataMsg, err });

                this.errorMessageService.showNoQueue(dataMsg);
              }, 256);

              return;
            }

            // intercept Generic Errors
            // https://developer.mozilla.org/es/docs/Web/HTTP/Status
            if (
              (err.status >= 500 && err.status <= 515) ||
              err.status === 404 || // Not Found
              err.status === 405 || // Method Not Allowed
              err.status === 406 || // Not Acceptable (custom errors)
              err.status === 413 || // Payload Too Large
              err.status === 414 || // URI Too Long
              err.status === 423 || // Locked (WebDAV)
              err.status === 429 || // Too Many Requests
              err.status === 432 || // Unassigned
              err.status === 451 // Unavailable For Legal Reasons
            ) {
              console.error('%c[error intercept]', 'color:#e93f3b', err);

              if (true && err.status === 413) {
                setTimeout(() => {
                  this.errorMessageService.showNoQueue({ forceMsg: this.translate.instant('CUSTOM_ERRORS.FileIsTooBig'), error: err }, err);
                }, 256);
                return;
              }

              // Wait 2 dev error & show error modal
              setTimeout(() => {
                if (true && this.statusConecctionService.delegateError() === true) return;

                let dataMsg = {};
                if (err?.error?.forceMsg) {
                  (dataMsg as any).forceMsg = err.error.forceMsg;
                  (dataMsg as any).error = err.error;
                  (dataMsg as any).responseMessages = err.error.responseMessages;
                  if (true) console.log(['ERRORS_CUSTOM_BE_ERRORS'], { dataMsg: dataMsg });
                }

                if (true) console.warn('[dataMsg]', { dataMsg, forceMsg: err?.error?.forceMsg, err });

                this.errorMessageService.showNoQueue(dataMsg, err);
              }, 256);

              return;
            }

            //Sin conexion
            if (err.status === 0 && true) {
              this.statusConecctionService.delegateError();
            }
          }
        }
      )
    );
  }
}
