import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env';
import { kayshFlushOperator, kayshOperator } from '@karacas/kaysh';
import { filterCharged } from '@prisma/components/filterChargedCombo/filter-charged-combo.modal';
import { ImporterTypeEnum } from '@prisma/components/ian-importer/store/ian-importer.model';
import { _isDev, _isFeDev, _log, _logTap, _useDummyData, _warn } from '@shared/aux_helper_environment';
import {
  _cloneDeep,
  _convertoDecimalToPercent,
  _convertPercentToDecimal,
  _ms,
  _objToHash,
  _orderBy,
  _roundDec,
  _throwError,
  _timeout,
  _toNumber,
} from '@shared/aux_helper_functions';
import { GlobalAlertService, _getStaticBrandCustomization } from 'core/services/ian-core-singleton.service';
import { from, Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import {
  dummy_dropdown_vendors_item_master,
  dummy_getAssortmentSegmentByCategory,
  dummy_get_costs,
  dummy_get_item_code_types,
  dummy_get_taxes,
  dummy_IM_item_real,
} from './IMV2_edit.dummyData';
import {
  IMV2_AssortmentSegment,
  IMV2_discriminator,
  IMV2_discriminatorArrStr2int,
  IMV2_discriminatorInt,
  IMV2_FormModel,
  IMV2_PermissionModel,
  IMV2_priceinitializationmethod,
  IMV2_UnitsOfMeasureModel,
} from './stores/IMV2_FormStateModel';

const _toCurrency = (num, per = false): number => {
  if (num === 0) return 0;
  if (num == null) return null;

  let rv = _toNumber(num, per);
  if (!per && rv > 0) rv = _roundDec(num, 2);

  return rv;
};

const flagDES1918TaxRateGeneral = () => true;

@Injectable({
  providedIn: 'root',
})
export class IMV2_edit_service {
  private configBase = environment.apiBaseUrl_prices;
  private config = environment.itemmasterv2.api;

  private costsAndTaxes = { costs: null, taxes: null };

  constructor(private http: HttpClient, private globalAlertService: GlobalAlertService) {}

  getAssortmentStatusFutureReport(itemIds = [], storeIds = []): Observable<any> {
    if (!itemIds?.length) return of(null);

    const _configBase = environment.apiBaseUrl_assortment;

    const url = `${_configBase}${this.config.resources.assortmentStatusFutureReport}`;

    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');

    return this.http.post(url, { itemIds, storeIds }, { headers, responseType: 'text' });
  }

  _cache_checkInternalCodeAlreadyExist = {};
  checkInternalCodeAlreadyExist($code: string, type?: any): Observable<boolean> {
    let code = String($code).trim();
    if (!code?.length) return of(false);

    let cache = this._cache_checkInternalCodeAlreadyExist[code];
    if (cache != null) return of(cache);

    const qs: Array<string> = ['?'];
    qs.push(`code=${code}`);
    if (type) {
      qs.push(`type=${type}`);
    }

    const url = `${this.configBase}${this.config.resources.exist}${qs.join('&')}`;

    return of(true).pipe(
      delay(128 * 3),
      switchMap(data => this.http.get<boolean>(url)),
      tap(data => _logTap(`${IMV2_edit_service.name}::checkInternalCodeNotTaken (tap)\n\tdata: %o`, data)),
      tap(data => (this._cache_checkInternalCodeAlreadyExist[code] = data)),
      catchError(error => _throwError(error, IMV2_edit_service))
    );
  }
  reset_cache_checkInternalCodeAlreadyExist() {
    this._cache_checkInternalCodeAlreadyExist = {};
  }

  getItemForm(id): Observable<IMV2_FormModel> {
    const url = `${this.configBase}${this.config.resources.getItem}${id}`;

    return this.getCostsAndTaxes().pipe(
      switchMap(costAndTaxes => {
        if (true) _log('[getItemForm]', { url, id, costAndTaxes });

        return this.http.get<any>(url).pipe(
          tap(_data => _logTap(`${IMV2_edit_service.name}::getItemForm (tap)\n\tdata: %o`, _data)),
          map(_data => _IMV2Map_ItemFormDataFromBe(_data, costAndTaxes)),
          catchError(error => {
            if (_useDummyData()) {
              return of(_IMV2Map_ItemFormDataFromBe({ ...dummy_IM_item_real, id }, costAndTaxes)).pipe(delay(1000));
            }
            _throwError(error, IMV2_edit_service);
          })
        );
      })
    );
  }

  _cache_costsTaxes = {};
  getItemCostsTaxes(id, unitOfMeasureId, quantity, wastage) {
    const setCache = (key, val) => {
      this._cache_costsTaxes[key] = val;
    };

    const getCache = key => {
      return this._cache_costsTaxes[key];
    };

    const qs: Array<string> = ['?'];
    qs.push(`itemId=${id}`);
    qs.push(`unitOfMeasureId=${unitOfMeasureId}`);
    qs.push(`quantity=${quantity}`);
    qs.push(`wastage=${wastage}`);

    const url = `${this.configBase}${this.config.resources.get_item_costs_taxes}${qs.join('&')}`;

    let cacheHash = _objToHash(url);
    let cache = getCache(cacheHash);
    if (cache != null) return of(cache);

    return this.http.get<any>(url).pipe(
      tap(_data => _logTap(`${IMV2_edit_service.name}::getItemCostsTaxes (tap)\n\tdata: %o`, _data)),
      tap(_data => setCache(cacheHash, _data)),
      catchError(error => {
        setCache(cacheHash, null);
        return of(null);
      })
    );
  }

  getItemsLookup(
    queryText: string,
    itemTypes: IMV2_discriminator | IMV2_discriminator[],
    itemIds: number[] = [],
    limitBy: number = 100,
    onlyItemIds = false
  ) {
    itemTypes = Array.isArray(itemTypes) ? itemTypes : [itemTypes];
    let _itemTypes: IMV2_discriminatorInt[] = IMV2_discriminatorArrStr2int(itemTypes as IMV2_discriminator[]).filter(t => t != null);

    const payLoad = {
      queryText,
      itemTypes: _itemTypes,
      itemIds,
      limitBy,
      onlyItemIds,
    };

    const url = `${this.configBase}${this.config.resources.lookup}`;
    const payloadToHash = { url, payLoad };

    let rv = this.http.post<any>(url, payLoad).pipe(
      tap(_data => _logTap(`${IMV2_edit_service.name}::getItemsLookup (tap)\n\tdata: %o`, _data)),
      map(arr =>
        arr.map(el => {
          return { ...el, value: el.name };
        })
      ),
      kayshOperator('IMgetItemsLookup', payloadToHash, {
        localStorage: false,
        maxItems: 20,
        maxTime: _ms('3h'),
      }),
      catchError(error => _throwError(error, IMV2_edit_service))
    );

    return rv;
  }
  resetCachegetItemsLookup() {
    return kayshFlushOperator('IMgetItemsLookup');
  }

  saveItemForm(data: IMV2_FormModel, isPublish = false, byPassWarning = true): Observable<IMV2_FormModel> {
    const url = !isPublish
      ? `${this.configBase}${this.config.resources.saveItem}`
      : `${this.configBase}${this.config.resources.publishItem}`;

    const printName = !isPublish ? '[saveItemForm]' : '[publishItemForm]';

    return this.getCostsAndTaxes().pipe(
      switchMap(costAndTaxes => {
        let payload = _IMV2Map_ItemFormDataToBe(data, costAndTaxes);

        if (isPublish === true) payload.byPassWarning = byPassWarning; //DES-1688

        if (true) _log(printName, { url, payload, costAndTaxes });

        return this.http.post(url, payload).pipe(
          tap(_data => _logTap(`${IMV2_edit_service.name}::${printName} (tap)\n\tdata: %o`, _data)),
          tap(_data => this.resetCachegetItemsLookup()),
          map(_data => _IMV2Map_ItemFormDataFromBe(_data, costAndTaxes, data)),
          catchError(error => _throwError(error, IMV2_edit_service))
        );
      })
    );
  }

  //DES-1688
  private async _internal_publishItemFormAsync(data: IMV2_FormModel, byPassWarning): Promise<IMV2_FormModel> {
    const inTest1688 = false && _isDev() && !byPassWarning;
    const dummyTest = of({ ...data, warningCosts: { urlBlob: 'https://prismaretail.ai/', translateCode: 'TEST' } } as IMV2_FormModel).pipe(
      delay(3000)
    );

    let rv = !inTest1688 ? await this.saveItemForm(data, true, byPassWarning).toPromise() : await dummyTest.toPromise();

    if (byPassWarning === false && rv?.warningCosts != null) {
      const userConfirm = await this.globalAlertService.promptAlertWarningCosts(rv.warningCosts);

      if (userConfirm === true) {
        rv = await this.saveItemForm(data, true, true).toPromise();
      } else {
        rv = { ...rv, _userNotConfirmPublishWarnings: true };
      }
    }

    return rv;
  }

  publishItemForm(data: IMV2_FormModel, byPassWarning): Observable<IMV2_FormModel> {
    return from(this._internal_publishItemFormAsync(data, byPassWarning));
  }

  getVendors(): Observable<Array<any>> {
    const url = `${this.configBase}${this.config.resources.getVendors}`;

    let rv = this.http.get<Array<any>>(url).pipe(
      tap(data => _logTap(`${IMV2_edit_service.name}::getVendors (tap)\n\tdata: %o`, data)),
      /*TMP*/ map(data =>
        false && _isFeDev()
          ? data.map(v => {
              return { ...v, tagValueId: false ? 9940 : 997 };
            })
          : data
      ),
      kayshOperator('getVendors', url, { localStorage: false, maxTime: _ms('3h') }),
      catchError(error => {
        if (_useDummyData()) {
          return of(dummy_dropdown_vendors_item_master).pipe(delay(100));
        }
        _throwError(error, IMV2_edit_service);
      })
    );

    return rv;
  }

  reset_cache_getVendors() {
    _log('[reset_cache_getVendors]');
    return kayshFlushOperator('getVendors');
  }

  getTaxes(): Observable<Array<any>> {
    const url = `${this.configBase}${this.config.resources.getTaxes}`;

    let rv = this.http.get<Array<any>>(url).pipe(
      tap(data => _logTap(`${IMV2_edit_service.name}::getTaxes (tap)\n\tdata: %o`, data)),
      kayshOperator('getTaxes', url, { localStorage: false, maxTime: _ms('8h') }),
      catchError(error => {
        if (_useDummyData() && true) {
          return of(dummy_get_taxes);
        }
        _throwError(error, IMV2_edit_service);
      })
    );

    return rv;
  }

  getCosts(): Observable<Array<any>> {
    const url = `${this.configBase}${this.config.resources.getCosts}`;

    let rv = this.http.get<Array<any>>(url).pipe(
      tap(data => _logTap(`${IMV2_edit_service.name}::getCosts (tap)\n\tdata: %o`, data)),
      kayshOperator('getCosts', url, { localStorage: false, maxTime: _ms('8h') }),
      catchError(error => {
        if (_useDummyData() && true) {
          return of(dummy_get_costs);
        }
        _throwError(error, IMV2_edit_service);
      })
    );

    return rv;
  }

  _cache_getAssortmentSegmentByCategory = null;
  _cache_getAssortmentSegmentByCategoryId = null;
  getAssortmentSegmentByCategory(categoryId: number, force = false): Observable<IMV2_AssortmentSegment> {
    if (categoryId == null) return of(null);

    const url = `${this.configBase}${this.config.resources.assortment_category_setup}?categoryId=${categoryId}`;

    if (force) {
      this._cache_getAssortmentSegmentByCategory = null;
      this._cache_getAssortmentSegmentByCategoryId = null;
    } else if (this._cache_getAssortmentSegmentByCategoryId === categoryId) {
      return of(this._cache_getAssortmentSegmentByCategory);
    }

    return this.http.get<IMV2_AssortmentSegment>(url).pipe(
      tap(data => _logTap(`${IMV2_edit_service.name}::getAssortmentSegmentByCategory (tap)\n\tdata: %o`, data)),
      tap(data => {
        this._cache_getAssortmentSegmentByCategoryId = categoryId;
        return (this._cache_getAssortmentSegmentByCategory = data);
      }),
      catchError(error => {
        if (_useDummyData()) {
          this._cache_getAssortmentSegmentByCategory = dummy_getAssortmentSegmentByCategory;
          this._cache_getAssortmentSegmentByCategoryId = categoryId;
          return of(this._cache_getAssortmentSegmentByCategory).pipe(delay(100));
        }
        _throwError(error, IMV2_edit_service);
      })
    );
  }

  _reset_cache_getAssortmentSegmentByCategory() {
    this._cache_getAssortmentSegmentByCategory = null;
    this._cache_getAssortmentSegmentByCategoryId = null;
  }

  notifyUserPermission(permission: IMV2_PermissionModel): Observable<IMV2_PermissionModel> {
    const url = `${this.configBase}${this.config.resources.notifyUserPermission}`;
    return this.http.post<IMV2_PermissionModel>(url, permission).pipe(
      tap(data => _logTap(`${IMV2_edit_service.name}::notifyUserPermission (tap)\n\tdata: %o`, data)),
      catchError(error => {
        if (_useDummyData()) {
          return of(true);
        }
        return of(null);
      })
    );
  }

  /**
   * Buscar maximo 100 items que coincidan con el query ingresado por lookup
   * @param _query: string
   * @returns Array<ItemParam> listado de items
   */
  getItemOptions(_query: string): Observable<Array<filterCharged>> {
    const url = `${this.configBase}${this.config.resources.searchItemsParent}`;
    const _data = { query: _query };
    return this.http.post<Array<filterCharged>>(url, _data).pipe(
      tap(data => _logTap(`${IMV2_edit_service.name}::getItemOptions (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, IMV2_edit_service))
    );
  }

  /**
   * Devuelve modelo con nombre codigo id de los items requeridos
   * @param optionsSelected array de items ids
   * @returns
   */
  getOptionsSelectedOnItemParent(optionsSelected: Array<number>): Observable<Array<filterCharged>> {
    const url = `${this.configBase}${this.config.resources.getOptionsSelected}`;
    const data = { ids: optionsSelected };
    return this.http.post<Array<filterCharged>>(url, data).pipe(
      tap(data => _logTap(`${IMV2_edit_service.name}::add (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, IMV2_edit_service))
    );
  }

  /**
   * Desactiva un producto regional para pasar a ser un item retail
   * @param optionsSelected array de items ids
   * @returns
   */
  toStandardItem(id: number): Observable<any> {
    const url = `${this.configBase}${this.config.resources.toStandardItem}`;
    return this.http.put<any>(url, { itemId: id }).pipe(
      tap(data => _logTap(`${IMV2_edit_service.name}::toStandardItem (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, IMV2_edit_service))
    );
  }

  // utilizado para ingredientes
  /*   getItemOptions(): Observable<Array<filterCharged>> {
    let url;
    url = `${this.configBase}${this.config.resources.itemsToDropDownCombo}`;

    return this.http.get<Array<filterCharged>>(url).pipe(
      tap(data => _logTap(`${IMV2_edit_service.name}::getItemOptions (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, IMV2_edit_service))
    );
  } */

  cache_downloadTemplate = null;
  //TODO: migrar a otro servicio cuando se cree importadores para surtido
  getAssortmentTemplate(): Observable<any> {
    const _configBase = environment.apiBaseUrl_assortment;
    const _config = environment.assortment.admin.importer.storeAssortment.api;
    if (this.cache_downloadTemplate) return of(this.cache_downloadTemplate);
    const templateUrl = `${_configBase}${_config.resources.templateUrl}`;
    const importerType = ImporterTypeEnum.storeAssortment;
    const url = `${templateUrl}/${importerType}`;
    return this.http.get(url, { responseType: 'text' }).pipe(data => (this.cache_downloadTemplate = data));
  }

  getCostsAndTaxes(): Observable<any> {
    if (this.costsAndTaxes.costs && this.costsAndTaxes.taxes) {
      return of(this.costsAndTaxes);
    }

    return of(true).pipe(
      switchMap(bol => this.getCosts()),
      switchMap(cost => {
        this.costsAndTaxes.costs = cost;
        return this.getTaxes();
      }),
      switchMap(taxes => {
        this.costsAndTaxes.taxes = taxes;
        if (false) _log('getCostsAndTaxes', this.costsAndTaxes);
        return of(this.costsAndTaxes);
      })
    );
  }

  getItemCodeTypes(): Observable<Array<any>> {
    const url = `${this.configBase}${this.config.resources.getItemCodeTypes}`;

    if (false) _log('getItemCodeTypes');

    let rv = this.http.get<Array<any>>(url).pipe(
      tap(data => _logTap(`${IMV2_edit_service.name}::getItemCodeTypes (tap)\n\tdata: %o`, data)),
      map(data =>
        data.map(code => {
          if (false && _isDev()) code.isRequired = true;
          return code;
        })
      ),
      kayshOperator('getItemCodeTypes', url, { localStorage: false, maxTime: _ms('3h') }),
      catchError(error => {
        if (_useDummyData()) {
          return of(dummy_get_item_code_types);
        }
        _throwError(error, IMV2_edit_service);
      })
    );

    return rv;
  }

  _cache_checkCodeExist = {};
  checkCodeExist(itemCodeTypeId: number, $code: string): Observable<boolean> {
    let code = String($code).trim();
    if (!code?.length) return of(false);

    let cache = this._cache_checkCodeExist[itemCodeTypeId + '-' + code];
    if (cache != null) return of(cache);

    const qs: Array<string> = ['?'];
    qs.push(`itemCodeTypeId=${itemCodeTypeId}`);
    qs.push(`code=${code}`);

    const url = `${this.configBase}${this.config.resources.codeExists}${qs.join('&')}`;

    return of(true).pipe(
      delay(128),
      switchMap(data => this.http.get<boolean>(url)),
      tap(data => _logTap(`${IMV2_edit_service.name}::checkInternalCodeNotTaken (tap)\n\tdata: %o`, data)),
      tap(data => (this._cache_checkCodeExist[itemCodeTypeId + '-' + code] = data)),
      catchError(error => {
        if (_useDummyData()) {
          let rv = !(String(code || '').length > 5);
          this._cache_checkCodeExist[itemCodeTypeId + '-' + code] = rv;
          return of(rv);
        }
        _throwError(error, IMV2_edit_service);
      })
    );
  }

  reset_cache_checkCodeExist() {
    this._cache_checkCodeExist = {};
  }

  validateUomsToClonePrices(data: IMV2_FormModel): Observable<boolean> {
    let url;
    url = `${this.configBase}${this.config.resources.validateUomsToClonePrices}`;

    return this.getCostsAndTaxes().pipe(
      switchMap(costAndTaxes => {
        let payload = _IMV2Map_ItemFormDataToBe(data, costAndTaxes);

        if (true) _log('validateUomsToClonePrices', { url, payload });

        return this.http.post<Array<any>>(url, payload).pipe(
          tap(data => _logTap(`${IMV2_edit_service.name}::validateUomsToClonePrices (tap)\n\tdata: %o`, data)),
          catchError(error => {
            if (_useDummyData()) {
              return of(false);
            }
            return _throwError(error, IMV2_edit_service);
          })
        );
      })
    );
  }
}

export const _IMV2Map_ItemFormDataToBe = ($data: IMV2_FormModel, costAndTaxes) => {
  let data: IMV2_FormModel = _cloneDeep($data);

  const _getCost = (i, isTax = false) => {
    if (!costAndTaxes?.costs) _warn('NO costAndTaxes');
    if (isTax) return costAndTaxes?.taxes[i] || null;
    return costAndTaxes?.costs[i] || null;
  };

  const _getCostIsPercentMult = (i, isTax = false) => {
    if (!costAndTaxes?.costs) _warn('NO costAndTaxes');
    let rv = _getCost(i, isTax)?.calculationType;
    return rv || null;
  };

  let rv: any = _cloneDeep({ ...data.mainData });

  rv.id = rv.id > 0 ? rv.id : 0;

  delete rv._inSave;
  delete rv._isDirty;
  delete rv._publishOk;
  delete rv._L3categoryId;
  delete rv._L4categoryId;
  delete rv.testTabData;
  delete rv.status;
  delete rv.publishStatus;
  delete rv._userNotConfirmPublishWarnings;
  delete rv.warningCosts;

  rv.categoryId = rv.categoryId?.length ? rv.categoryId[0] : null;

  rv.tagValues = data.clasificationData?.tags || null;

  //Limpia y ordena los tagValues
  if (rv?.tagValues?.length) {
    rv.tagValues = _orderBy(
      rv.tagValues.map(tag => {
        return { id: tag.id, value: tag.value };
      }),
      ['id']
    );
  }

  rv.unitsOfMeasure = data.unitsOfMeasure || null;
  if (rv.unitsOfMeasure?.length) {
    rv.unitsOfMeasure = rv.unitsOfMeasure
      .map((_uom: IMV2_UnitsOfMeasureModel) => {
        let uom = _cloneDeep(_uom);

        uom.id = _toNumber(uom.id) || 0;
        uom.id = uom.id > 0 ? uom.id : 0;
        uom.conversion = _toNumber(uom.conversion);

        if (uom?.regulated_price?.[0]?.regulatedCost != null) {
          uom.regulated_price[0].regulatedCost = _toNumber(uom.regulated_price[0].regulatedCost);
        }

        if (uom?.regulated_price?.[0]?.regulatedPrice != null) {
          uom.regulated_price[0].regulatedPrice = _toNumber(uom.regulated_price[0].regulatedPrice);
        }

        if (uom?.regulated_price?.[0]?.margin != null) {
          uom.regulated_price[0].margin = _roundDec(_toNumber(uom.regulated_price[0].margin) / 100, 2);
        }

        return {
          ...uom,
          isSellingUnit: !!uom.isSellingUnit,
          hasExhibition: !!uom.hasExhibition,
          isBaseUnitOfMeasure: !!uom.isBaseUnitOfMeasure,
          //
          regulated_price: !!uom.isSellingUnit
            ? [
                {
                  regulatedPrice: null,
                  forceRegulatedPrice: null,
                  regulatedCost: null,
                  forceRegulatedCost: null,
                  tagValueId: null,
                  margin: null,
                  ...uom.regulated_price?.[0],
                },
              ]
            : null,
        };
      })
      .filter(v => {
        return v.name?.length && v.conversion > 0;
      });

    //Remueve code/conversion duplicados
    if (true && rv.unitsOfMeasure?.length > 1) {
      let duplicateUoms = rv.unitsOfMeasure.filter((_uom, i) => {
        let sameCodeConv = rv.unitsOfMeasure.find((_uom2, i2) => {
          let dupe =
            i != i2 &&
            _uom.code == _uom2.code &&
            _uom.conversion == _uom2.conversion &&
            !_uom._deleted &&
            !_uom2._deleted &&
            _uom.conversion != null;

          if (dupe) {
            if (_uom.isBaseUnitOfMeasure) {
              _uom2._deleted = true;
            } else {
              _uom._deleted = true;
            }
          }

          return dupe;
        });

        return sameCodeConv;
      });

      if (duplicateUoms?.length) {
        rv.unitsOfMeasure = rv.unitsOfMeasure.filter(obj => !obj._deleted);
      }
    }
  }

  rv.vendors = data.vendors || null;

  if (rv.vendors?.length) {
    rv.vendors = rv.vendors
      .map(_vendor => {
        let vendor: any = _cloneDeep(_vendor);

        delete vendor._vendorName;

        vendor.id = _toNumber(vendor.id) || 0;
        vendor.vendorId = _toNumber(vendor.vendorId) || 0;
        vendor.tagValueId = _toNumber(vendor.tagValueId) || null;

        vendor.orderUnitOfMeasureConversion = _toNumber(vendor.orderUnitOfMeasureConversion) || 0;

        vendor.orderUnitOfMeasureId = _toNumber(vendor.orderUnitOfMeasureId) || 0;
        vendor.orderUnitOfMeasureId = vendor.orderUnitOfMeasureId > 0 ? vendor.orderUnitOfMeasureId : 0;

        vendor.minimumOrder = _toNumber(vendor.minimumOrder) || 0;
        vendor.maximumOrder = _toNumber(vendor.maximumOrder) || 0;

        delete vendor.tagValueName;

        //Divide los valores si son %
        vendor.costs = (vendor.costs || []).map(c => {
          if (!c) return c;

          if (c.tagValueId === -1) c.tagValueId = null; //Si es costo nacional convierte el tagValueId a null / DES-1061

          if (c.cost != null) c.cost = _toCurrency(c.cost);
          if (c.cost2 != null) c.cost2 = _toCurrency(c.cost2);
          if (c.cost3 != null) c.cost3 = _toCurrency(c.cost3);

          if (c.tax != null) c.tax = _toCurrency(c.tax);
          if (c.tax2 != null) c.tax2 = _toCurrency(c.tax2);
          if (c.tax3 != null) c.tax3 = _toCurrency(c.tax3);

          delete c.tagValueName;
          return c;
        });

        //Matchea con UOMS si cambiaron
        if (rv.unitsOfMeasure != null) {
          let someUOM = rv.unitsOfMeasure.find(UOM => {
            return UOM.id !== 0 && String(UOM.id) === String(vendor.orderUnitOfMeasureId);
          });

          if (!someUOM)
            someUOM = rv.unitsOfMeasure.find(UOM => {
              return (
                String(UOM.code) === String(vendor.orderUnitOfMeasureCode) &&
                String(UOM.conversion) === String(vendor.orderUnitOfMeasureConversion)
              );
            });

          if (!someUOM)
            someUOM = rv.unitsOfMeasure.find(UOM => {
              return String(UOM.id) === String(vendor.orderUnitOfMeasureId);
            });

          if (someUOM) {
            vendor.orderUnitOfMeasureCode = someUOM.code;
            vendor.orderUnitOfMeasureConversion = someUOM.conversion;
            vendor.orderUnitOfMeasureId = someUOM.id;
          } else {
            vendor = null;
          }
        }

        return vendor;
      })
      .filter(v => {
        return v?.vendorId > 0 && v?.vendorCode != null;
      });
  }

  if (rv.discriminator === IMV2_discriminator.Recipe || rv.discriminator === IMV2_discriminator.Combo) delete rv.vendors;

  rv.assortmentBehavior = data?.assortmentBehavior?.assortmentBehavior || null;
  rv.isBlockedForSales = data?.assortmentBehavior?.isBlockedForSales || false;
  rv.cloneStoreAssortment = data?.assortmentBehavior?.cloneStoreAssortment === false ? false : true;
  rv.isBlockedForPurchase = data?.assortmentBehavior?.isBlockedForPurchase || false;

  rv.priceInitializationMethod = data?.prices?.priceInitializationMethod || null;
  rv.clonePricesFromItemId = data?.prices?.clonePricesFromItemId || null;
  rv.codes = data.codes || null;

  if (data.matchings != null) {
    let matchings = (data.matchings || [])
      .map(el => {
        let matching = { ...el, code: el?.itemCode };

        if (
          matching?.id == null ||
          matching?.brandId == null ||
          matching?.itemCode == null ||
          matching?.codeTypeId == null ||
          !(matching?.name?.length > 0)
        ) {
          return null;
        }

        if (Number(matching.id) < 1) {
          matching.id = 0;
        }

        delete matching.itemCode;

        return matching;
      })
      .filter(el => el != null);

    rv.matchings = matchings;
  }

  if (!flagDES1918TaxRateGeneral()) {
    rv.salesTaxRate = _toNumber(data?.prices?._salesTaxRate || 0) / 100;
    if (data?.prices?._salesTaxRate) delete data?.prices?._salesTaxRate;
  } else {
    rv.salesTaxRate = _toNumber(data?.mainData?._salesTaxRate || 0) / 100;
    if (data?.mainData?._salesTaxRate) delete data?.mainData?._salesTaxRate;
  }

  rv.priceType = data?.prices?.priceType;
  rv.fixedPrice = data?.prices?.fixedPrice;

  if (rv.codes?.length) {
    rv.codes = rv.codes
      .map(_code => {
        let code: any = _cloneDeep(_code);

        delete code._isRequired;

        code.id = code.id > 0 ? _toNumber(code.id) : 0;
        code.typeId = _toNumber(code.typeId) || 0;

        if (code.unitOfMeasureId) code.unitOfMeasureId = _toNumber(code.unitOfMeasureId) || 0;
        if (code.unitOfMeasureConversion) code.unitOfMeasureConversion = _toNumber(code.unitOfMeasureConversion) || 0;

        //Matchea con UOMS si cambiaron
        if (code.unitOfMeasureId != null && rv.unitsOfMeasure != null) {
          let someUOM = rv.unitsOfMeasure.find(UOM => {
            return UOM.id !== 0 && String(UOM.id) === String(code.unitOfMeasureId);
          });

          if (!someUOM)
            someUOM = rv.unitsOfMeasure.find(UOM => {
              return String(UOM.code) === String(code.unitOfMeasureCode) && String(UOM.conversion) === String(code.unitOfMeasureConversion);
            });

          if (!someUOM)
            someUOM = rv.unitsOfMeasure.find(UOM => {
              return String(UOM.id) === String(code.unitOfMeasureId);
            });

          if (someUOM) {
            code.unitOfMeasureCode = someUOM.code;
            code.unitOfMeasureConversion = someUOM.conversion;
            code.unitOfMeasureId = someUOM.id;
          } else {
            code = null;
          }
        }

        return code;
      })
      .filter(c => {
        return c?.code?.length > 0 && c?.typeId > 0;
      });
  }

  rv.recipe = data.recipe || null;
  rv.recipe = rv.recipe
    ? {
        ...rv.recipe,
        items: (rv.recipe.items || [])
          .map((r, i) => {
            if (true) delete r.id;

            if (r.itemId == null || r.consumeUnitOfMeasureId == null || !(_toNumber(r.quantity || 0) > 0)) return null;

            return {
              ...r,
              quantity: _toNumber(r.quantity || 0),
              wastePercentage: _toNumber(r.wastePercentage || 0) / 100,
            };
          })
          .filter(r => r != null),
      }
    : null;
  if (rv.discriminator !== IMV2_discriminator.Recipe) delete rv.recipe;

  rv.combo = data.combo || null;
  rv.combo = rv.combo
    ? {
        ...rv.combo,
        discount: _convertPercentToDecimal(data?.prices?.discountRate),
        comboBlocks: (rv.combo.comboBlocks || [])
          .map((c, i) => {
            c.id = c.id > 0 ? c.id : 0;

            if (c.name == null || !(_toNumber(c.quantity || 0) > 0)) return null;

            let comboItems = (c.comboItems || [])
              .map((ci, j) => {
                if (ci.itemId == null || ci.salesUnitOfMeasureId == null) return null;

                if (true) ci.id = ci.id > 0 ? ci.id : 0;

                return { ...ci };
              })
              .filter(c => c != null);

            if (!(comboItems.length > 0)) return null;

            return {
              ...c,
              quantity: _toNumber(c.quantity || 0),
              comboItems: comboItems,
              comboItemIds: (comboItems || []).map((item, j) => item?.itemId).filter(c => c != null),
            };
          })
          .filter(c => c != null),
      }
    : null;
  if (rv.discriminator !== IMV2_discriminator.Combo) delete rv.combo;

  return rv;
};

export const _IMV2Map_ItemFormDataFromBe = ($data, costAndTaxes, payload = null): IMV2_FormModel => {
  let data = _cloneDeep($data);
  let salesTaxRateDefault = _getStaticBrandCustomization()?.itemMaster?.salesTaxRateDefault ?? 0;

  const _getCost = (i, isTax = false) => {
    if (!costAndTaxes?.costs) _warn('NO costAndTaxes');
    if (isTax) return costAndTaxes?.taxes[i] || null;
    return costAndTaxes?.costs[i] || null;
  };

  const _getCostIsPercentMult = (i, isTax = false) => {
    if (!costAndTaxes?.costs) _warn('NO costAndTaxes');
    let rv = _getCost(i, isTax)?.calculationType;
    return rv || null;
  };

  let rv: any = {
    mainData: _cloneDeep({
      ...data,
      publishStatus: data.publishStatus,
      ...(flagDES1918TaxRateGeneral() ? { _salesTaxRate: _toNumber(data.salesTaxRate ?? salesTaxRateDefault) * 100 } : null),
    }),
    warningCosts: data.warningCosts,

    clasificationData: { tags: data.tagValues || null },

    unitsOfMeasure: (
      (data.unitsOfMeasure?.length > 0
        ? _orderBy(data.unitsOfMeasure, ['isBaseUnitOfMeasure', 'isSellingUnit'], ['desc', 'desc'])
        : data.unitsOfMeasure) || []
    )?.map?.((uom: IMV2_UnitsOfMeasureModel) => {
      const hasTemplateValues =
        (uom.templateMinFrontXSku !== null && uom.templateMinFrontXSku !== undefined) ||
        (uom.templateMaxFrontXSku !== null && uom.templateMaxFrontXSku !== undefined);
      return {
        ...uom,
        useFrontLimitsPerItem: hasTemplateValues,
        regulated_price:
          uom.regulated_price?.length > 0
            ? uom.regulated_price.map(m => {
                return { ...m, margin: m.margin != null ? _roundDec(_toNumber(m.margin) * 100, 2) : null };
              })
            : [],
      };
    }),

    assortmentBehavior: {
      assortmentBehavior: data.assortmentBehavior || null,
      originalAssortmentBehavior: data.assortmentBehavior || null,
      isBlockedForSales: data.isBlockedForSales || false,
      cloneStoreAssortment: data?.cloneStoreAssortment === false ? false : true,
      isBlockedForPurchase: data.isBlockedForPurchase || false,
      hasAssortmentModuleActive: payload?.assortmentBehavior?.hasAssortmentModuleActive || false,
      hasPermission: payload?.assortmentBehavior?.hasPermission || false,
      segmentTagId: payload?.assortmentBehavior?.segmentTagId || null,
      hasSegment: payload?.assortmentBehavior?.hasSegment || false,
      categoryName: payload?.assortmentBehavior?.categoryName || null,
      forceGetAssormentSegment: payload?.forceGetAssormentSegment || true,
      greenButtons: payload?.assortmentBehavior?.greenButtons || null,
      tagMatch: payload?.assortmentBehavior?.tagMatch || null,
      originalTagMatch: payload?.assortmentBehavior?.tagMatch || null,
      categoryId: payload?.assortmentBehavior?.categoryId || null,
      _isLoading: payload?.assortmentBehavior?._isLoading || null,
    },

    prices: {
      priceInitializationMethod: data.priceInitializationMethod || IMV2_priceinitializationmethod.NationalPrice,
      clonePricesFromItemId: data.clonePricesFromItemId || null,
      ...(!flagDES1918TaxRateGeneral() ? { _salesTaxRate: _toNumber(data.salesTaxRate ?? salesTaxRateDefault) * 100 } : null),
      priceType: data.priceType || null,
      fixedPrice: data.fixedPrice || null,
      discountRate: _convertoDecimalToPercent(data?.combo?.discount),
    },

    codes: data.codes || null,

    //Multiplica los valores si son %
    vendors: (_cloneDeep(data.vendors) || []).map(vendor => {
      vendor.costs = (vendor.costs || []).map(c => {
        if (!c) return c;

        if (c.tagValueId == null) c.tagValueId = -1; //Si es costo nacional convierte el tagValueId a null / DES-1061

        if (c.cost != null) c.cost = _toCurrency(c.cost);
        if (c.cost2 != null) c.cost2 = _toCurrency(c.cost2);
        if (c.cost3 != null) c.cost3 = _toCurrency(c.cost3);

        if (c.tax != null) c.tax = _toCurrency(c.tax);
        if (c.tax2 != null) c.tax2 = _toCurrency(c.tax2);
        if (c.tax3 != null) c.tax3 = _toCurrency(c.tax3);

        return c;
      });
      return vendor;
    }),

    combo: data.combo
      ? {
          ...data.combo,
          comboBlocks: (data.combo.comboBlocks || []).map((c, i) => {
            return {
              ...c,
              id: c.id || -(i + 1),
              comboItems: (c.comboItems || []).map((ci, j) => {
                return { ...ci, id: ci.id || -((i + 1) * (j + 1)) };
              }),
            };
          }),
        }
      : null,

    recipe: data.recipe
      ? {
          ...data.recipe,
          items: (data.recipe.items || []).map((r, i) => {
            return {
              ...r,
              id: r.id || -(i + 1),
              wastePercentage: _roundDec(_toNumber(r?.wastePercentage || 0) * 100, 2),
              quantity: _toNumber(r?.quantity || 0),
            };
          }),
        }
      : null,
  };

  if (data.matchings?.length > 0) {
    rv.matchings = data.matchings.map(el => {
      const tmpElement = { ...el, itemCode: el.code };
      delete tmpElement.code;
      return tmpElement;
    });
  }

  if (false && _isFeDev()) {
    rv.matchings = [
      {
        id: 1,
        codeTypeId: 2,
        itemCode: '2',
        name: '2',
        brandId: 74,
      },
      {
        id: 2,
        codeTypeId: 2,
        itemCode: '2',
        name: '2',
        brandId: 73,
      },
    ];
  }

  delete rv.mainData.codes;
  delete rv.mainData.tagValues;
  delete rv.mainData.unitsOfMeasure;
  delete rv.mainData.vendors;
  delete rv.mainData.assortmentBehavior;
  delete rv.mainData.prices;
  delete rv.mainData.clasificationData;
  delete rv.mainData.responseMessages;
  delete rv.mainData.imageUrl;
  delete rv.mainData.responseSuccess;
  delete rv.mainData.isBlockedForSales;
  delete rv.mainData.isBlockedForPurchase;
  delete rv.mainData.cloneStoreAssortment;
  delete rv.mainData.priceInitializationMethod;
  delete rv.mainData.recipe;
  delete rv.mainData.combo;
  delete rv.mainData._userNotConfirmPublishWarnings;
  delete rv.mainData.warningCosts;

  rv.mainData.categoryId = rv.mainData.categoryId ? [rv.mainData.categoryId] : [];

  if (payload?.mainData) {
    rv.mainData._L3categoryId = payload.mainData._L3categoryId || null;
    rv.mainData._L4categoryId = payload.mainData._L4categoryId || null;
  }

  return rv;
};
