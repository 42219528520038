import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { _isDev, _log } from '@shared/aux_helper_environment';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';

@Component({
  selector: 'response-messages-generic-print-component',
  template: `
    <div>
      <!-- //InvalidStoreScope -->
      <ng-container *ngIf="dataWarnings_InvalidStoreScope != null">
        <h2 class="warnTitle">{{ 'CUSTOM_ERRORS.INVALID_STORE_SCOPE' | pTranslate }}</h2>
      </ng-container>

      <!-- //InvalidManualStoreScopes -->
      <ng-container *ngIf="dataWarnings_InvalidManualStoreScopes != null">
        <div style="margin: 12px 0;" *ngIf="dataWarnings_InvalidStoreScope != null">
          <mat-divider></mat-divider>
        </div>

        <h2 class="warnTitle">{{ 'CUSTOM_ERRORS.INVALID_MANUAL_STORE_SCOPES' | pTranslate }}:</h2>
        <div style="margin: 12px 0;">
          <mat-divider></mat-divider>
        </div>
        <response-messages-generic-print-warning-list-component [warnings]="dataWarnings_InvalidManualStoreScopes">
        </response-messages-generic-print-warning-list-component>
      </ng-container>

      <ng-container *ngIf="false && _isDev">{{ dataWarnings | json }}</ng-container>
    </div>
  `,
  styles: [
    `
      .warnTitle {
        font-size: 16px;
      }
    `,
  ],
})

//DES-1651
export class ResponseMessagesGenericPrintComponent implements OnInit, OnChanges {
  @Input() dataWarnings: any;

  _isDev = _isDev();

  dataWarnings_InvalidManualStoreScopes = null;
  dataWarnings_InvalidStoreScope = null;

  constructor(protected translate: IanTranslateService) {}

  ngOnInit(): void {
    this.setWarnings();
  }

  ngOnChanges(changes) {
    if (changes.dataWarnings && changes.dataWarnings.currentValue) {
      this.setWarnings();
    }
  }

  setWarnings() {
    if (true) _log('[setWarnings] dataWarnings:', this.dataWarnings);

    const dataWarnings_InvalidStoreScope = this.dataWarnings.find(e => e?.keyword === 'InvalidStoreScope');
    if (dataWarnings_InvalidStoreScope != null) {
      this.dataWarnings_InvalidStoreScope = dataWarnings_InvalidStoreScope;
    }

    const dataWarnings_InvalidManualStoreScopes = this.dataWarnings.find(e => e?.keyword === 'InvalidManualStoreScopes');
    if (dataWarnings_InvalidManualStoreScopes?.values?.length) {
      this.dataWarnings_InvalidManualStoreScopes = dataWarnings_InvalidManualStoreScopes.values.split(',');
    }
  }
}
