import { PossibleErrorType } from 'app/store/app-state.model';

export interface ImporterStateModel {
  fileAccepted: boolean;
  isLoading: boolean;
  featureTitle: string;
  returnButtonEnabled: boolean;
  activeIndex: number;
  errors: PossibleErrorType;
  fileAsJson?: any;
  endPoint: string;
  data: { [key: string]: any };
  templateEndPoint: string;
  urlTemplate: string;
}

export interface ImporterResponseModel {
  hasErrors: boolean;
  results: Array<ErrorItemModel>;
  totalRowsSaved: number;
  totalRowsFailure: number;
}

export interface ErrorItemModel {
  category: string;
  description: string;
  isError: boolean;
  row: number;
  rowsString: string;
  step: number;
  totalRows: number;
  uri: string;
}

export enum ImporterTypeEnum {
  storeAverageCosts = 0,
  storeCosts = 1,
  priceIndex = 2,
  priceSurveys = 3,
  prices = 4,
  spaces = 5,
  marketData = 6,
  monthlySales = 7,
  dailySales = 8,
  margin2 = 9,
  storeAssortment = 10,
  inventory = 11,
  dailyInventory = 12,
  items = 13,
  vendors = 14,
  vendorItems = 15,
  vendorItemsOld = 16,
  unitsOfMeasures = 17,
  categoryCodeMappers = 18,
  itemBarCodes = 19,
  stores = 20,
  newPrices = 21,
  oxxoCosts = 22,
  places = 23,
  aging = 24,
  itemSensitivityPriceLists = 25,
  itemTags = 26,
  handMadeMarkdowns = 27,
  itemsToExcludeFromRounding = 28,
  expirationMarkdowns = 29,
  editUnitOfMeasures = 30,
  ypfTransportCostsAndComissions = 31,
  ypfImportParity = 32,
  yPF314 = 33,
  CostAndTaxes = 34,
  //
  DailyMissionReport = -1,
  CategoriesV2 = 35,
}
