import { Component, OnInit } from '@angular/core';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { transitions } from '@prisma/animations/animations';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';

import { InsightsPanelGetListAction, InsightsPanelRestListAction, InsightsPanelStoreState } from './insights-panel-store.state';
import { InsightsListModel, InsightTypesModel } from 'app/admin/master/insights/insights-store/insights-store.model';
import { InsightsService } from 'app/admin/master/insights/insights.service';
import { SetDashBoardUrl } from 'app/home/dashboard/store/dashboard.state';
import { _navigateToAnotherPage, _getParams, hasReport } from 'app/admin/master/insights/insights-list/insights-list.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-insights-panel',
  templateUrl: './insights-panel.component.html',
  styleUrls: ['./insights-panel.component.scss'],
  animations: [transitions],
})
export class InsightsPanelComponent implements OnInit {
  @Select(InsightsPanelStoreState.getPanelInsights)
  insightsPanel$: Observable<Array<InsightsListModel>>;

  @Select(InsightsPanelStoreState.getInsightsSize)
  insightsSize$: Observable<number>;

  subscribers: any = {};
  $navigateToAnotherPage = _navigateToAnotherPage;
  $getParams = _getParams;
  $hasReport = hasReport;

  insightsPanelOpened = false;
  translates = {} as any;

  insightsPanel: Array<InsightsListModel>;
  typeList$ = [];
  $typeIsLoading = false;
  $isLoading = false;

  constructor(
    private _fuseSidebarService: FuseSidebarService,
    private insightService: InsightsService,
    private translate: IanTranslateService,
    private toastrService: ToastrService,
    private store: Store,
    private router: Router
  ) {}

  ngOnInit(): void {
    this._fuseSidebarService.getSidebar('insightsPanel').openedChanged.subscribe(data => {
      this.insightsPanelOpened = data;
      if (this.insightsPanelOpened) {
        this.$typeIsLoading = true;
        this.insightService.getTypes().subscribe((data: Array<InsightTypesModel>) => {
          this.typeList$ = data;
          this.$isLoading = true;
          this.store.dispatch(new InsightsPanelGetListAction());
          this.$typeIsLoading = false;
        });
        this.setTranslates();
      }
      if (data) this.toastrService.clear();
    });

    this.store.select(InsightsPanelStoreState.getPanelInsights).subscribe(data => {
      this.insightsPanel = data;
      this.$isLoading = false;
    });
  }

  closeInsights(): void {
    if (true) {
      setTimeout(() => {
        this._fuseSidebarService.getSidebar('insightsPanel') && this._fuseSidebarService.getSidebar('insightsPanel').close();
        this.store.dispatch(new InsightsPanelRestListAction());
      });
    }
  }

  setTranslates() {
    //Las traducciones se setean acá y de esta manera, pq cuando se instancia el componente todavía no está definido el idioma
    this.translates.TITLE = this.translate.instant('INSIGHTS.TITLE');
    this.translates.GO_TO_INSIGHTS = this.translate.instant('INSIGHTS.GO_TO_INSIGHTS');
  }

  setDashBoardUrl(element) {
    if (hasReport(element)) {
      this.store.dispatch(new SetDashBoardUrl(element?.actionUrl));
    }
  }
}
