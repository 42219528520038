import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { kayshOperator } from '@karacas/kaysh';
import { _log, _logTap } from '@shared/aux_helper_environment';
import { _cloneDeep, _isArray, _ms, _sortArrayTreeAlpha, _throwError, _timeout } from '@shared/aux_helper_functions';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError, delay, first, map } from 'rxjs/operators';
import {
  CategoryTreeModelGenObj,
  CategoryTreeModelGenSelected,
  _aux_getCatItemObjFromId,
  _aux_getCatParentIdFromChildId,
} from './generic-category-selection-mini-v2.component';

export const _getLowLevelGenericCategory = (data: CategoryTreeModelGenObj): CategoryTreeModelGenSelected => {
  let lvl2 = data?.level2;
  let lvl3 = data?.level3;
  let lvl4 = data?.level4;
  let lvl5 = data?.level5;

  const _lvl4 = _filterHighLevelGenericCategoryInUse(lvl5, lvl4);
  const _lvl3 = _filterHighLevelGenericCategoryInUse(lvl4, lvl3);
  const _lvl2 = _filterHighLevelGenericCategoryInUse(lvl3, lvl2);

  let obj = {};
  if (data?.level2) obj = { ...obj, level2: _lvl2.map(x => x.categoryId) };
  if (data?.level3) obj = { ...obj, level3: _lvl3.map(x => x.categoryId) };
  if (data?.level4) obj = { ...obj, level4: _lvl4.map(x => x.categoryId) };
  if (data?.level5) obj = { ...obj, level5: lvl5.map(x => x.categoryId) };

  return obj;
};

export const _filterHighLevelGenericCategoryInUse = (child: CategoryTreeModelGen[], parent: CategoryTreeModelGen[]) => {
  if (child?.length && parent?.length) {
    const parentFromLowLevel = child.map(x => x.parentId);
    let arrayWithOutDuplicates = Array.from(new Set(parentFromLowLevel));
    return parent.filter(parent => !arrayWithOutDuplicates.includes(parent.categoryId));
  } else {
    return parent || [];
  }
};

export const _validGenericCatSelectModelV2ToV1 = (data, hasLvl3 = false, hasLvl4 = true) => {
  const validationLow = hasLvl4 ? data?.level4?.length > 0 && data?.level5?.length > 0 : data?.level5?.length > 0;
  return hasLvl3 ? data?.level3?.length > 0 && validationLow : validationLow;
};

export const _mapGenericCatSelectModelV2ToV1 = (data, excluded) => {
  return {
    categorys: excluded ? [] : data.level4,
    categorysExcluded: !excluded ? [] : data.level4,
    subCategorys: excluded ? [] : data.level5,
    subCategorysExcluded: !excluded ? [] : data.level5,
  };
};

export const _filterParentLevelGenericCatSelectModel = (data, _level: number, treeCat, onlyIds = false): any => {
  let categories = _aux_getCatItemObjFromId(data, treeCat) as Array<CategoryTreeModelGen>;
  categories = categories.filter(c => c.level === _level);
  if (onlyIds) {
    const categoriesIds = categories.map(c => c.categoryId);
    return [...new Set(categoriesIds)];
  } else {
    return [...new Set(categories)];
  }
};

export const _getGenericCatSelectModelSeparateIds = (data, treeCat = null): any => {
  const categories = _filterParentLevelGenericCatSelectModel(data.subCategorys, 4, treeCat, true);
  const categoriesExcluded = _filterParentLevelGenericCatSelectModel(data.subCategorysExcluded, 4, treeCat, true);

  const subcategories = data.subCategorys.filter(c => !categories.some(x => x === c));
  const subcategoriesExcluded = data.subCategorysExcluded.filter(c => !categoriesExcluded.some(x => x === c));

  return {
    categorys: categories.length > 0 ? categories : [],
    categorysExcluded: categoriesExcluded.length > 0 ? categoriesExcluded : [],
    subCategorys: subcategories.length > 0 ? subcategories : [],
    subCategorysExcluded: subcategoriesExcluded.length > 0 ? subcategoriesExcluded : [],
  };
};

export const _mapGenericCatSelectModelV1ToV2_Scope = (data, hasLvl3 = false, obtainParent = false, treeCat = null): [any, boolean] => {
  let _level3 = [];
  let _level4 = [];
  let _level5 = [];
  let excluded = false;

  if (data) {
    _level4 = data.categorys && data.categorys.length > 0 ? data.categorys : _level4;
    _level4 = data.categorysExcluded && data.categorysExcluded.length > 0 ? data.categorysExcluded : _level4;
    _level5 = data.subCategorys && data.subCategorys.length > 0 ? data.subCategorys : _level5;
    _level5 = data.subCategorysExcluded && data.subCategorysExcluded.length > 0 ? data.subCategorysExcluded : _level5;

    excluded =
      (data.categorysExcluded && data.categorysExcluded.length > 0) || (data.subCategorysExcluded && data.subCategorysExcluded.length > 0);

    if (obtainParent && _level5 && _level5.length > 0) {
      let tmpCategory = treeCat ? _aux_getCatParentIdFromChildId(_level5, treeCat) : _aux_getCatParentIdFromChildId(_level5);
      _level4 =
        typeof tmpCategory === 'number' ? [...new Set([..._level4, tmpCategory])] : [...new Set([..._level4, ...tmpCategory])] || [];
    }
    if (obtainParent && hasLvl3 && _level4?.length > 0) {
      let tmpDept = treeCat ? _aux_getCatParentIdFromChildId(_level4, treeCat) : _aux_getCatParentIdFromChildId(_level4);
      _level3 = typeof tmpDept === 'number' ? [...new Set([..._level3, tmpDept])] : [...new Set([..._level3, ...tmpDept])] || [];
    }
  }

  const categorySelected = hasLvl3
    ? {
        level3: _level3,
        level4: _level4,
        level5: _level5,
      }
    : {
        level4: _level4,
        level5: _level5,
      };

  return [categorySelected, excluded];
};

export const _mapGenericCatSelectModelV1ToV2 = (data, hasLvl3 = false, obtainParent = false, treeCat = null): [any, boolean] => {
  let _level3 = [];
  let _level4 = [];
  let _level5 = [];
  let excluded = false;

  if (data) {
    _level4 = data.categorys && data.categorys.length > 0 ? data.categorys : _level4;
    _level4 = data.categorysExcluded && data.categorysExcluded.length > 0 ? data.categorysExcluded : _level4;
    _level5 = data.subCategorys && data.subCategorys.length > 0 ? data.subCategorys : _level5;
    _level5 = data.subCategorysExcluded && data.subCategorysExcluded.length > 0 ? data.subCategorysExcluded : _level5;

    excluded =
      (data.categorysExcluded && data.categorysExcluded.length > 0) || (data.subCategorysExcluded && data.subCategorysExcluded.length > 0);

    if (obtainParent && _level5 && _level5.length > 0) {
      let tmpCategory = treeCat ? _aux_getCatParentIdFromChildId(_level5, treeCat) : _aux_getCatParentIdFromChildId(_level5);
      _level4 = typeof tmpCategory === 'number' ? [tmpCategory] : tmpCategory || [];
    }
    if (obtainParent && hasLvl3 && _level4?.length > 0) {
      let tmpDept = treeCat ? _aux_getCatParentIdFromChildId(_level4, treeCat) : _aux_getCatParentIdFromChildId(_level4);
      _level3 = typeof tmpDept === 'number' ? [tmpDept] : tmpDept || [];
    }
  }

  const categorySelected = hasLvl3
    ? {
        level3: _level3,
        level4: _level4,
        level5: _level5,
      }
    : {
        level4: _level4,
        level5: _level5,
      };

  return [categorySelected, excluded];
};

export interface CategoryTreeModelGen {
  categoryId: number;
  categoryName: string;
  level: number;
  parentId?: number;
  items?: Array<CategoryTreeModelGen>;
}

const cudOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class GenericCategorySelectionMiniV2Service {
  private configBasePrices = environment.apiBaseUrl_prices;
  private urls = environment.tagService.api;
  categoriesLoaded = false;
  constructor(private http: HttpClient) {}

  setCategories() {
    this.getCategoryTree()
      .pipe(first())
      .subscribe(async data => {
        this.categoryTree = data;
        this.categoriesLoaded = true;
      });
  }

  getCategoryTreeState() {
    return of(this.categoriesLoaded);
  }

  getCategoryTree(): Observable<Array<CategoryTreeModelGen>> {
    const url = `${this.configBasePrices}${this.urls.resources.gereicCategoryTree}`;

    if (true) _log('[getCategoryTree generic]', url);

    let rv = this.http.get<Array<CategoryTreeModelGen>>(url).pipe(
      delay(1),
      tap(data => _logTap(`${GenericCategorySelectionMiniV2Service.name}::getCategoryTree (tap)\n\tdata: %o`, data)),
      map(data => (data?.length ? _sortArrayTreeAlpha(data, 'categoryName', 'items') : data)),
      map(data => _cloneDeep(data)),
      kayshOperator('getCategoryTree', url, { localStorage: false, maxTime: _ms('3h') }),
      catchError(error => {
        _throwError(error, GenericCategorySelectionMiniV2Service);
        return [];
      })
    );

    return rv;
  }

  categoryTree = null;

  //solo funciona devolviendo CategoryTreeModelGen[] por lo que se fuerza.
  getCategoryObjByCategoryId = async (categoryId): Promise<CategoryTreeModelGen[]> => {
    const $categoryId = Number(categoryId);
    if (this.categoryTree == null) {
      await _timeout(1000);
      return this.getCategoryObjByCategoryId($categoryId);
    }
    return _aux_getCatItemObjFromId([$categoryId], this.categoryTree) as CategoryTreeModelGen[];
  };

  getCategoryLvlById = async ($catObj): Promise<number> => {
    let catObj = $catObj;
    if (typeof catObj === 'number') {
      const $categoryId = Number($catObj);
      catObj = this.getCategoryObjByCategoryId([$categoryId]) as any;
    }
    return _isArray(catObj) ? catObj[0]?.level : catObj?.level;
  };

  setLowLevelV1(data, excluded = false) {
    let categoryModelV1 = {
      categorys: [],
      subCategorys: [],
      categorysExcluded: [],
      subCategorysExcluded: [],
    };
    return (categoryModelV1 = !excluded
      ? { ...categoryModelV1, categorys: data?.level4 || [], subCategorys: data?.level5 || [] }
      : { ...categoryModelV1, categorysExcluded: data?.level4 || [], subCategorysExcluded: data?.level5 || [] });
  }
  /**
   * WARNING: Si se esta aplicando a algun endpoint este filtro es porque su finalidad es para filtrar de manera correcta una consulta que involucre mas de un nivel de categoria.
   * niveles de categorias por id y devuelvo niveles de categorias por objeto con toda la info de esa categoria
   * @param data level2: number[], level3: number[]...
   * @returns level2: [{categoryId, parentId, lvl}...], level3: [{categoryId, parentId, lvl}...]
   */
  getLowLevel(data: CategoryTreeModelGenSelected) {
    let obj = {};
    if (data?.level2) obj = { ...obj, level2: _aux_getCatItemObjFromId(data.level2 as number[], this.categoryTree) };
    if (data?.level3) obj = { ...obj, level3: _aux_getCatItemObjFromId(data.level3 as number[], this.categoryTree) };
    if (data?.level4) obj = { ...obj, level4: _aux_getCatItemObjFromId(data.level4 as number[], this.categoryTree) };
    if (data?.level5) obj = { ...obj, level5: _aux_getCatItemObjFromId(data.level5 as number[], this.categoryTree) };
    return _getLowLevelGenericCategory(obj);
  }
  /**
   * modelo de categorias V1 y devuelvo el mismo modelo pero filtrados los ids por lowLevel. El nivel mas bajo de esa herencia (0 alto, 5 bajo)
   * @param data {categorys, categorysExcluded, subCategorys, subCategorysExcluded}
   * @returns level2: [{categoryId, parentId, lvl}...], level3: [{categoryId, parentId, lvl}...]
   */
  getLowLevelIncludedExcludedV1(data) {
    const [mappedToLevelModel, isExcluded] = _mapGenericCatSelectModelV1ToV2(data, false, false, this.categoryTree);
    const lowLevelData = this.getLowLevel(mappedToLevelModel);
    return this.setLowLevelV1(lowLevelData, isExcluded);
  }

  getCategoriesObjByIds(ids: number[]) {
    return _aux_getCatItemObjFromId(ids, this.categoryTree);
  }
}
