import { _log } from '@shared/aux_helper_environment';
import { ConstraintData } from '../planograms-editor/store/planogram.interfaces';
import { _checkProdIsFiltered } from './spaces_aux_calc_prod_data';

const _auxCalcFacigsPerEan = prods => {
  let rv = {};
  (prods || []).forEach(prod => {
    if (!prod?.itemData?.ean) return;
    if (rv[prod.itemData.ean]) {
      rv[prod.itemData.ean] += prod.facings || 1;
    } else {
      rv[prod.itemData.ean] = prod.facings || 1;
    }
  });
  return rv;
};

const _auxCalcSpacePerEan = prods => {
  let rv = {};
  (prods || []).forEach(prod => {
    if (!prod || !prod.itemData.ean || !prod.size.w) return;
    if (rv[prod.itemData.ean]) {
      rv[prod.itemData.ean] += prod.size.w || 1;
    } else {
      rv[prod.itemData.ean] = prod.size.w || 1;
    }
  });
  return rv;
};

const _auxMapEans = prods => {
  let eans = [
    ...new Set(
      prods.map(prod => {
        return prod.itemData.ean;
      })
    ),
  ];
  return eans;
};

export const _auxAccumCheckConstraintProds = ($prods, cons: ConstraintData, minMaxData, accumEans = null, verbose = false) => {
  if (!cons || !$prods || !minMaxData) return false;

  let prods = Array.isArray($prods) ? $prods : [$prods];

  if (verbose) _log({ prods, cons, minMaxData });

  let eans = _auxMapEans(prods);

  let shelfs_totalLineSpace = minMaxData.shelfs_totalLineSpace;
  if (cons.selectionType === 'shelf' && cons.shelfSpace) shelfs_totalLineSpace = cons.shelfSpace;
  if (cons.selectionType === 'module' && cons.moduleSpace) shelfs_totalLineSpace = cons.moduleSpace;

  let facigsPerEan = _auxCalcFacigsPerEan(accumEans ? accumEans : prods);
  let spacePerEan = _auxCalcSpacePerEan(accumEans ? accumEans : prods);

  if (cons.ruleType === 'MIN_FACINGS') {
    return eans.some(ean => {
      let eanFacings = facigsPerEan[ean as any];
      let rv = eanFacings < cons.ruleValue;
      return rv;
    });
  }

  if (cons.ruleType === 'MAX_FACINGS') {
    return eans.some(ean => {
      let eanFacings = facigsPerEan[ean as any];
      let rv = eanFacings > cons.ruleValue;
      return rv;
    });
  }

  if (cons.ruleType === 'MIN_LINE_SPACE') {
    return eans.some(ean => {
      let eanSpace = spacePerEan[ean as any];
      let rv = eanSpace < cons.ruleValue;
      return rv;
    });
  }

  if (cons.ruleType === 'MAX_LINE_SPACE') {
    return eans.some(ean => {
      let eanSpace = spacePerEan[ean as any];
      let rv = eanSpace > cons.ruleValue;
      return rv;
    });
  }

  if (cons.ruleType === 'MIN_SHARE_SPACE') {
    return eans.some(ean => {
      let eanSpace = spacePerEan[ean as any];
      let rv = eanSpace < shelfs_totalLineSpace * (cons.ruleValue / 100);
      return rv;
    });
  }

  if (cons.ruleType === 'MAX_SHARE_SPACE') {
    return eans.some(ean => {
      let eanSpace = spacePerEan[ean as any];
      let rv = eanSpace > shelfs_totalLineSpace * (cons.ruleValue / 100);
      return rv;
    });
  }

  return false;
};

export const _auxAccumCheckConstraintProdsGroup = ($prods, cons: ConstraintData, minMaxData, verbose = false) => {
  if (!cons || !$prods || !minMaxData) return false;

  let prods = Array.isArray($prods) ? $prods : [$prods];

  if (verbose) _log({ prods, cons, minMaxData });

  let eans = _auxMapEans(prods);

  let shelfs_totalLineSpace = minMaxData.shelfs_totalLineSpace;
  if (cons.selectionType === 'shelf' && cons.shelfSpace) shelfs_totalLineSpace = cons.shelfSpace;
  if (cons.selectionType === 'module' && cons.moduleSpace) shelfs_totalLineSpace = cons.moduleSpace;

  let facigsPerEan = _auxCalcFacigsPerEan(prods);
  let spacePerEan = _auxCalcSpacePerEan(prods);

  if (cons.ruleType === 'MIN_FACINGS') {
    let eanFacings = eans.reduce((acc, ean) => {
      let eanFacings = facigsPerEan[ean as any];
      return acc + eanFacings;
    }, 0);

    let rv = eanFacings < cons.ruleValue;
    return rv;
  }

  if (cons.ruleType === 'MAX_FACINGS') {
    let eanFacings = eans.reduce((acc, ean) => {
      let eanFacings = facigsPerEan[ean as any];
      return acc + eanFacings;
    }, 0);

    let rv = eanFacings > cons.ruleValue;
    return rv;
  }

  if (cons.ruleType === 'MIN_LINE_SPACE') {
    let eanSpace = eans.reduce((acc, ean) => {
      let eanSpace = spacePerEan[ean as any];
      return acc + eanSpace;
    }, 0);

    let rv = eanSpace < cons.ruleValue;
    return rv;
  }

  if (cons.ruleType === 'MAX_LINE_SPACE') {
    let eanSpace = eans.reduce((acc, ean) => {
      let eanSpace = spacePerEan[ean as any];
      return acc + eanSpace;
    }, 0);

    let rv = eanSpace > cons.ruleValue;
    return rv;
  }

  if (cons.ruleType === 'MIN_SHARE_SPACE') {
    let eanSpace = eans.reduce((acc, ean) => {
      let eanSpace = spacePerEan[ean as any];
      return acc + eanSpace;
    }, 0);

    let rv = eanSpace < shelfs_totalLineSpace * (cons.ruleValue / 100);
    return rv;
  }

  if (cons.ruleType === 'MAX_SHARE_SPACE') {
    let eanSpace = eans.reduce((acc, ean) => {
      let eanSpace = spacePerEan[ean as any];
      return acc + eanSpace;
    }, 0);

    let rv = eanSpace > shelfs_totalLineSpace * (cons.ruleValue / 100);
    return rv;
  }

  return false;
};

export const _auxInsertInvalidConstrainstsPerProd = (prod, constrainstFilters: any[], minMaxData) => {
  let tmpConstraintsFiltered = constrainstFilters.filter(c => !!c.prods.find(p => String(p.id) === String(prod.id)));

  //Chequea que constraints se cumplen
  let tmpConstraints = tmpConstraintsFiltered.filter(cons => {
    let prods = cons.prods; /* manda como parametro los productos filtrados del Constrainst para calcular facings y espacios por eans */
    //TODO: Ver si hay q checkear q prod esté incluido en prods
    let rv = cons.isGrouped ? true : _auxAccumCheckConstraintProds(prod, cons.constraint, minMaxData, prods);
    return rv;
  });

  return { ...prod, itemData: { ...prod.itemData, _constraints: tmpConstraints.map(c => c?.constraint) } };
};

export const _auxCheckAllConstraintsProdFiltered = (
  prod,
  constrainstFilters: ConstraintData[],
  constraintsProdFilteredAccum,
  verbose = false
) => {
  constrainstFilters.forEach((cons: any) => {
    let filters = cons.filters;
    let hasFilters = filters && filters.categories.length + filters.selectedTags.length + filters.subCategories.length > 0;
    let filterInclude = !hasFilters || !_checkProdIsFiltered(prod, cons.filters);

    if (cons.selectionType === 'shelf' && prod.$parenShelf !== cons.selectionId) filterInclude = false;
    if (cons.selectionType === 'module' && prod.$moduleId !== cons.selectionId) filterInclude = false;

    if (filterInclude) {
      constraintsProdFilteredAccum[cons.id] = [
        ...(constraintsProdFilteredAccum[cons.id] ? constraintsProdFilteredAccum[cons.id] : []),
        prod,
      ];
    }
  });
};

/*
  Le agrega a cada producto del planograma la propiedad _constraints con las reglas que no se cumplen
*/
export const _auxCheckConstraints = (planogram, minMaxData, onlyConstraints = null) => {
  if (!planogram || !minMaxData) return planogram;

  let verbose = false;
  let constraints = planogram.constraints;

  if (onlyConstraints !== null) {
    //Filtra constraints si viene distinto de null
    constraints = constraints.filter(c => onlyConstraints.map(String).includes(String(c.id)));
  }

  //Si no hay constraints aborta
  if (!constraints?.length) return planogram;

  constraints = constraints.map(cons => {
    //Si la regla es para un shelf aplica shelfSpace como espacio máximo
    if (cons.selectionType === 'shelf') {
      let shelf = null;
      planogram.modules.forEach(m => {
        (m.shelfs || []).forEach((s, i) => {
          if (s.id === cons.selectionId) shelf = s;
        });
      });
      let shelfSpace = shelf?.width || null;
      if (shelfSpace) {
        return { ...cons, shelfSpace };
      }
    }

    //Si la regla es para un module aplica moduleSpace como espacio máximo
    if (cons.selectionType === 'module') {
      let module = planogram.modules.find(m => m.id === cons.selectionId);
      if (module) {
        let moduleSpace = (module.width || 0) * (module.shelfs?.length || 0);
        if (moduleSpace > 0) return { ...cons, moduleSpace };
      }
    }

    return { ...cons };
  });

  //Agrupa productos que cumplan los filtros de los constraints > constraintsProdFiltered //OK
  const constraintsProdFiltered = {};
  (planogram.modules || []).forEach(m => {
    (m.shelfs || []).forEach((s, i) => {
      (s.prods || []).forEach((p, i) => {
        _auxCheckAllConstraintsProdFiltered(
          { ...p, $moduleId: m.id, $shelfId: s.id },
          constraints,
          constraintsProdFiltered,
          i === 0 || true
        );
      });
    });
  });

  //Checkea todos los productos conra la regla para ver si hay algún warning (si la regla se cumple) //OK
  const constraintWarnings = [];
  Object.entries(constraintsProdFiltered as any).forEach(([key, value]) => {
    let prods = value;
    let cons = constraints.find(c => String(c.id) === String(key));

    if (!cons) return;

    let ruleIsFulfilled = cons.isGrouped
      ? _auxAccumCheckConstraintProdsGroup(prods, cons, minMaxData, verbose)
      : _auxAccumCheckConstraintProds(prods, cons, minMaxData, null, verbose);
    if (!ruleIsFulfilled) return;

    //Llena un array con el constraint y los eans q aplican
    constraintWarnings.push({ constraint: cons, prods: value });
  });

  //Le agrega a los productos los constraint que no está cumpliendo
  planogram.modules = (planogram.modules || []).map(m => {
    return {
      ...m,
      shelfs: m.shelfs.map(s => {
        return {
          ...s,
          prods: s.prods.map((p, i) => {
            let rvProd = _auxInsertInvalidConstrainstsPerProd(p, constraintWarnings, minMaxData);

            //Aplica los productos con warnings a constraintWarnings
            const prdConstr = rvProd.itemData._constraints;
            if (prdConstr && prdConstr.length) {
              prdConstr.forEach(con => {
                let id = con?.id ? String(con.id) : null;
                if (id === null) return;
                let matchCons = (constraintWarnings || []).find(c => String(c.constraint?.id) === id);
                if (matchCons) matchCons.prodsFilters = matchCons.prodsFilters ? [...matchCons.prodsFilters, rvProd] : [rvProd];
              });
            }

            return rvProd;
          }),
        };
      }),
    };
  });

  if (false && verbose) _log('[constraintWarnings]', constraintWarnings);

  return { ...planogram, $constraintWarnings: constraintWarnings };
};
