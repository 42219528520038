import { animate, group, state, style, transition, trigger } from '@angular/animations';
import { _getEnv } from '@shared/aux_helper_environment';

const emptyTrigger = transition(':enter', [
  style({}), // initial
  animate('0s', style({})), // final
]);

// id:rrv5Rw_muO
export const transitions = [
  trigger('enter-item-OLD', [
    transition(':enter', [
      style({ transform: 'scale(0.5)', opacity: 0 }), // initial
      animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'scale(1)', opacity: 1 })), // final
    ]),
  ]),
  trigger('enter-item', [
    _getEnv('disabledEnterItemTransition')
      ? emptyTrigger
      : transition(':enter', [
          style({ transform: 'scale3d(0.5, 0.5, 0.5)', opacity: 0 }), // initial
          animate('0.65s cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'scale3d(1 ,1 , 1)', opacity: 1 })), // final
        ]),
  ]),
  trigger('left-to-right-enter', [
    transition(':enter', [
      style({ 'margin-left': '-200px', opacity: 0 }),
      animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ 'margin-left': '0px', opacity: 1 })),
    ]),
  ]),
  trigger('right-to-left-enter', [
    transition(':enter', [
      style({ 'margin-right': '-200px', opacity: 0 }),
      animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ 'margin-left': '0px', opacity: 1 })),
    ]),
  ]),
  trigger('fade', [state('in', style({ opacity: 1 })), transition(':enter', [style({ opacity: 0 }), animate(600)])]),
  trigger('up-down-enter-leave', [
    state('in', style({ opacity: '1', visibility: 'visible', 'max-height': '500px' })),
    state('out', style({ opacity: '0', visibility: 'hidden', 'max-height': '0px' })),
    transition('in => out', [
      group([
        animate(
          '400ms ease-in-out',
          style({
            opacity: '0',
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '0px',
          })
        ),
        animate(
          '700ms ease-in-out',
          style({
            visibility: 'hidden',
          })
        ),
      ]),
    ]),
    transition('out => in', [
      group([
        animate(
          '1ms ease-in-out',
          style({
            visibility: 'visible',
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '500px',
          })
        ),
        animate(
          '800ms ease-in-out',
          style({
            opacity: '1',
          })
        ),
      ]),
    ]),
  ]),
];

export const slideInOutAnimation = [
  trigger('slideInOut', [
    state(
      'in',
      style({
        'max-height': '500px',
        opacity: '1',
        visibility: 'visible',
      })
    ),
    state(
      'out',
      style({
        'max-height': '0px',
        opacity: '0',
        visibility: 'hidden',
      })
    ),
    transition('in => out', [
      group([
        animate(
          '400ms ease-in-out',
          style({
            opacity: '0',
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '0px',
          })
        ),
        animate(
          '700ms ease-in-out',
          style({
            visibility: 'hidden',
          })
        ),
      ]),
    ]),
    transition('out => in', [
      group([
        animate(
          '1ms ease-in-out',
          style({
            visibility: 'visible',
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '500px',
          })
        ),
        animate(
          '800ms ease-in-out',
          style({
            opacity: '1',
          })
        ),
      ]),
    ]),
  ]),
];
export const slideInOutAnimationV2 = [
  trigger('slideInOut', [
    state(
      'in',
      style({
        opacity: '1',
        visibility: 'visible',
      })
    ),
    state(
      'out',
      style({
        'max-height': '0px',
        opacity: '0',
        visibility: 'hidden',
      })
    ),
    transition('in => out', [
      group([
        animate(
          '400ms ease-in-out',
          style({
            opacity: '0',
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '0px',
          })
        ),
        animate(
          '700ms ease-in-out',
          style({
            visibility: 'hidden',
          })
        ),
      ]),
    ]),
    transition('out => in', [
      group([
        animate(
          '1ms ease-in-out',
          style({
            visibility: 'visible',
          })
        ),
        animate(
          '600ms ease-in-out',
          style({
            'max-height': '500px',
          })
        ),
        animate(
          '800ms ease-in-out',
          style({
            opacity: '1',
          })
        ),
      ]),
    ]),
  ]),
];

export const rotate180 = [
  trigger('rotatedState', [
    state(
      'default',
      style({
        transform: 'rotate(0)',
      })
    ),
    state(
      'rotated',
      style({
        transform: 'rotate(-180deg)',
      })
    ),
    transition('rotated => default', animate('400ms ease-out')),
    transition('default => rotated', animate('400ms ease-in')),
  ]),
];
