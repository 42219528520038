import { AvailablePromotionalSpacesDto } from './available-promotional-spaces.model';

export const availablePromotionalSpacesDummyData = [
  {
    promotionalSpace: 'Generico > Electro Gigante',
    storeSegment: 'Estado de Mexico',
    availabilityPercentage: 0.2,
    availableSlots: 20,
    totalSlots: 80,
  },
  {
    promotionalSpace: 'Fashion > Clothing',
    storeSegment: 'California',
    availabilityPercentage: 0.4,
    availableSlots: 40,
    totalSlots: 100,
  },
  {
    promotionalSpace: 'Technology > Gadgets',
    storeSegment: 'New York',
    availabilityPercentage: 0.15,
    availableSlots: 15,
    totalSlots: 50,
  },
  {
    promotionalSpace: 'Home & Garden > Furniture',
    storeSegment: 'Texas',
    availabilityPercentage: 0.25,
    availableSlots: 25,
    totalSlots: 75,
  },
  {
    promotionalSpace: 'Beauty > Skincare',
    storeSegment: 'Florida',
    availabilityPercentage: 0.3,
    availableSlots: 30,
    totalSlots: 60,
  },
  {
    promotionalSpace: 'Food > Snacks',
    storeSegment: 'Illinois',
    availabilityPercentage: 0.5,
    availableSlots: 50,
    totalSlots: 100,
  },
  {
    promotionalSpace: 'Sports > Equipment',
    storeSegment: 'Arizona',
    availabilityPercentage: 0.1,
    availableSlots: 10,
    totalSlots: 100,
  },
  {
    promotionalSpace: 'Books > Fiction',
    storeSegment: 'Massachusetts',
    availabilityPercentage: 0.7,
    availableSlots: 70,
    totalSlots: 100,
  },
  {
    promotionalSpace: 'Automotive > Parts',
    storeSegment: 'Michigan',
    availabilityPercentage: 0.2,
    availableSlots: 20,
    totalSlots: 80,
  },
  {
    promotionalSpace: 'Health > Supplements',
    storeSegment: 'Washington',
    availabilityPercentage: 0.45,
    availableSlots: 45,
    totalSlots: 90,
  },
];
export const dummyDataSpaces: AvailablePromotionalSpacesDto = {
  items: availablePromotionalSpacesDummyData,
  totalItemCount: 10,
  pageIndex: 0,
  pageSize: 1,
};

// store segment value (región para oxxo)

// % de disponibilidad

// cantidad de slots disponibles

// cantidad total de slots
