<app-help-price-rule *ngIf="canShow_priceRuleHelp"></app-help-price-rule>
<app-help-rounding-rule *ngIf="canShow_roundingRuleHelp"></app-help-rounding-rule>
<app-help-guidelines-and-strategies *ngIf="canShow_guidelinesAndStrategiesHelp"></app-help-guidelines-and-strategies>
<app-help-price-decisions *ngIf="canShow_priceDecisionsHelp || canShow_decisionsSummaryHelp"></app-help-price-decisions>

<div
  *ngIf="!canShow_priceRuleHelp && !canShow_roundingRuleHelp && !canShow_guidelinesAndStrategiesHelp && !canShow_priceDecisionsHelp && !canShow_decisionsSummaryHelp"
  class="soon-container"
>
  <mat-icon>av_timer</mat-icon>
  &nbsp; &nbsp;
  <span class="soon">Próximamente</span>
</div>
