import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { _log, _warnProduction } from '@shared/aux_helper_environment';

@Component({
  selector: 'prisma-route-to-another-page',
  templateUrl: './prisma-route-to-another-page.component.html',
  styleUrls: ['./prisma-route-to-another-page.component.scss'],
})
export class PrismaRouteToAnotherPageComponent implements OnChanges {
  /**
   * @param path url literal de url relativa, no hace falta agregar dominio toma el de prisma
   */
  @Input() path: string;
  /**
   * @param path objeto con parametros que se agregan al final de la url, transformando obj => JSON
   */
  @Input() params: any; //obj
  /**
   * @param pathFx closure para obtener la url dependiendo de condiciones externas al componente
   */
  @Input() pathFx: any; //function
  /**
   * @param pathFx closure para obtener objeto con parametros dependiendo de condiciones externas al componente
   */
  @Input() paramsFx: any; //function
  /**
   * @param data datos utilizados para closure, param de closure
   */
  @Input() data: any; //parametros es un objeto
  /**
   * @param withoutParams flag para habilitar la navegacion sin parametros
   */
  @Input() withoutParams = false;
  /**
   * @param navigationDone //para comunicar al padre que el ruteo fue realizado
   */
  @Output() navigationDone = new EventEmitter<boolean>();

  _path = null;
  _params = null;
  _pathFx = null;
  _paramsFx = null;
  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['path']) this._path = this.path;
    if (changes['params']) this._params = this.params;
    if (changes['pathFx']) {
      this._pathFx = this.pathFx;
    }
    if (changes['paramsFx']) {
      this._paramsFx = this.paramsFx;
    }
  }

  navigateToAnotherPage(_data) {
    if (!this._path && !this._pathFx) {
      _warnProduction('[navigateToAnotherPage] undefined path');
      return;
    }
    if (!this._params && !this._paramsFx && !this.withoutParams) {
      _warnProduction('[navigateToAnotherPage] undefined params');
      return;
    }
    const _path = this._path ? this._path : this._pathFx ? this._pathFx(_data) : null;
    const _params =
      this._params && !this.withoutParams ? this._params : this._paramsFx && !this.withoutParams ? this._paramsFx(_data) : null;
    this.navigateWithParams(_path, _params, this.withoutParams);
  }

  navigateWithParams(path, $params, withoutParams = false) {
    this.navigationDone.emit(true);
    if (!$params || withoutParams) {
      this.router.navigate([path]);
      return;
    }
    let params = $params;
    if (typeof params === 'object') params = JSON.stringify(params);
    let filters = params;
    let urlWhitFilters = `${path}${filters}`;
    _log('[navigateWithParams]', urlWhitFilters);
    this.router.navigate([urlWhitFilters]);
  }
}
