const insightsPanelList = {
  items: [
    {
      id: 1,
      name: 'Aumento el margen en Cat. Gaseosas',
      description: 'Se detectó un aumento de 3,2% del Margen de la Categoría Gaseosas, puede estar asociada a una regla de Margen',
      createDate: new Date(2021, 2, 3),
      margin: 100,

      type: 1,
      store: 2,
      storeName: '1ero de Mayo',
      storeTag: 1,
      storeTagName: 'Filtros',
      category: 2,
      categoryName: 'Bebidas',

      actionName: 'REVIEW_PRICES',
      actionUrl: '/prices/decisions/price-decisions/list',

      isSubscribed: true,
      hasAllSuscriptionTypes: true,
      hasAllSuscriptionCategories: true,
      hasAllSuscriptionStore: false,
      hasAllSuscriptionStoreTag: false,
    },
    {
      id: 2,
      name: 'Bajo el margen en Tienda G. Paz',
      description:
        'Se detectó una baja de -4,3% del Margen en el punto de venta  Tienda G. Paz, puede estar asociado a una regla de Margen',
      createDate: new Date(2021, 2, 3),
      margin: 6667,

      type: 2,
      store: 3,
      storeName: 'G. Paz',
      storeTag: 1,
      storeTagName: 'Provincia',
      category: null,
      categoryName: '',

      actionName: 'REVIEW_PRICES',
      actionUrl: '/prices/decisions/price-decisions/list',

      isSubscribed: true,
      hasAllSuscriptionTypes: false,
      hasAllSuscriptionCategories: false,
      hasAllSuscriptionStore: false,
      hasAllSuscriptionStoreTag: false,
    },
    {
      id: 3,
      name: 'Bajo el margen en Tienda Carranza 1900',
      description:
        'Se detectó una baja de -4,3% del Margen en el punto de venta  Tienda Carranza 1900, puede estar asociado a una regla de Margen',
      createDate: new Date(2021, 2, 3),
      margin: 100,
      type: 2,
      store: 3,
      storeName: 'Carranza1900',
      storeTag: null,
      storeTagName: '',
      category: null,
      categoryName: '',

      actionName: 'REVIEW_PRICES',
      actionUrl: '/prices/decisions/price-decisions/list',

      isSubscribed: true,
      hasAllSuscriptionTypes: false,
      hasAllSuscriptionCategories: false,
      hasAllSuscriptionStore: false,
      hasAllSuscriptionStoreTag: false,
    },
    {
      id: 4,
      name: 'Bajo el margen en Bebidas',
      description: 'Se detectó una baja de -4,3% del Margen en la categoria Bebidas, puede estar asociado a una regla de Margen',
      createDate: new Date(2021, 2, 3),
      margin: 555,
      type: 2,
      store: null,
      storeName: '',
      storeTag: null,
      storeTagName: '',
      category: 2,
      categoryName: 'Bebidas',

      actionName: 'REVIEW_PRICES',
      actionUrl: '/prices/decisions/price-decisions/list',

      isSubscribed: true,
      hasAllSuscriptionTypes: false,
      hasAllSuscriptionCategories: true,
      hasAllSuscriptionStore: false,
      hasAllSuscriptionStoreTag: false,
    },
    {
      id: 5,
      name: 'Aumento el margen en Tienda G. Paz',
      description:
        'Se detectó un Aumento de 14,3% del Margen en el punto de venta Tienda G. Paz, puede estar asociado a una regla de Margen',
      createDate: new Date(2021, 2, 3),
      margin: 1234,
      type: 1,
      store: 2,
      storeName: 'G. Paz',
      storeTag: null,
      storeTagName: '',
      category: null,
      categoryName: '',

      actionName: 'REVIEW_PRICES',
      actionUrl: '/prices/decisions/price-decisions/list',

      isSubscribed: true,
      hasAllSuscriptionTypes: true,
      hasAllSuscriptionCategories: false,
      hasAllSuscriptionStore: false,
      hasAllSuscriptionStoreTag: false,
    },
  ],
  totalItemCount: 5,
  pageIndex: 0,
  pageSize: 10,
};

export const dummyInsightsPanel = {
  insightsPanelList,
};
