import { getDateFormat, LenguageDateFormatType } from 'app/app.config';

import { AttributeItemModel } from 'core/models/attribute.model';
import * as deepmerge from 'deepmerge';
import { deepEqual } from 'fast-equals';
import debounce from 'lodash.debounce';
import * as _mem from 'mem';
import * as _ms from 'ms';
import numeral from 'numeral-es6';
import { Observable, Subject, throwError } from 'rxjs';

import { klona } from 'klona';
import * as debounceFn from 'lodash.debounce';
import * as _lGet from 'lodash.get';
import isEmpty from 'lodash.isempty';
import set from 'lodash.set';
import sumBy from 'lodash.sumby';
import * as throttleFn from 'lodash.throttle';
import * as immutable from 'object-path-immutable';

const delve = require('dlv');
const diacritics = require('diacritics');

// Rename the keys of a JavaScript Object simply by using a map/dict to do it
// https://github.com/daviddias/remap-keys
import { StateContext } from '@ngxs/store';
import json5 from 'json5';
import { _log, _warnProduction } from './aux_helper_environment';

const deburr = diacritics.remove;
const _sumBy = sumBy;
const _equal = deepEqual;
const _isArray = val => Array.isArray(val);

const dateFormat = getDateFormat();

/**
 * utility to "deep clone" Objects, Arrays, Dates, RegExps, etc
 *
 * https://github.com/lukeed/klona
 *
 * @param value The value to recursively clone.
 * @return Returns the deep cloned value.
 */
const _cloneDeep = (obj: {} | any): {} | any => {
  return klona(obj);
};

// const _cloneDeepOld = (obj: {} | any): {} | any => {
//   if (!obj || typeof obj === 'string' || typeof obj === 'number') {
//     return obj;
//   }
//   if (typeof obj !== 'object') {
//     return cloneDeep(obj);
//   }

//   return JSON.parse(JSON.stringify(obj));
// };

/**
 * Devuelve único id númérico Negativo / para devolver al server usar _reset_getNewNumberId para convertir de nuevo a 0
 *
 * @returns {number}
 */
let _getNewNumberIdCounter = 0;

const _getNewNumberId = (negative = true): number => {
  _getNewNumberIdCounter++;
  const id = Number(String(_getNewNumberIdCounter) + String(Math.random()).slice(2, 6) + String(Date.now())) * (negative ? -1 : 1);

  return id;
};

/**
 * Convierte todos los ids de un objeto a 0 si está generado con _getNewNumberId
 * El primer parametro es el objeto
 * El segundo parametros, si es null checkea si la propiedad contiene el substring id y es negatico
 *    si es un string o un array de strings se fija que se exatamente igual
 *
 * ej:
 *      let objA = {
 *          id: 1,
 *          items: [{ id: _getNewNumberId() }, { idmayor: 2 }, { idb: _getNewNumberId(), items: [{ id: -1 }, { idmayor: 2 }, { idb: -10 }] }],
 *      };
 *
 *      console.log(_reset_getNewNumberId(objA, ['id', 'idb']));
 *
 * @param {{}} obj
 * @param {(string[] | string | null)} [keys=null]
 * @param {boolean} [clone=true]
 * @param {any} [newVal=0]
 * @returns {{}}
 */
const _reset_getNewNumberId = (obj: any, keys: Array<string> | string | null = null, clone = true, newVal: any = 0): {} => {
  if (obj === null || obj === undefined) {
    return obj;
  }

  const _obj = clone ? _cloneDeep(obj) : obj;

  if (Array.isArray(_obj)) {
    _obj.forEach((item: any): void => {
      _reset_getNewNumberId(item, keys, false, newVal);
    });
  } else if (_obj && typeof _obj === 'object') {
    Object.getOwnPropertyNames(_obj as object).forEach((key): void => {
      const valIsNumberNegative = typeof _obj[key] === 'number' && _obj[key] < 0;
      if (valIsNumberNegative && keys && typeof keys === 'string' && key === keys) {
        _obj[key] = newVal;
      } else if (valIsNumberNegative && keys && Array.isArray(keys) && keys.includes(key)) {
        _obj[key] = newVal;
      } else if (valIsNumberNegative && keys === null && key.toLocaleLowerCase().indexOf('id') !== -1) {
        _obj[key] = newVal;
      } else {
        _reset_getNewNumberId(_obj[key], keys, false, newVal);
      }
    });
  }

  return _obj;
};

const _getDaysBetweenDates = (date1, date2) => {
  if (!date1 || !date2) return 0;
  // Convert both dates to milliseconds
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);

  let date1_ms = date1.getTime();
  let date2_ms = date2.getTime();

  // Calculate the difference in milliseconds
  let difference_ms = date2_ms - date1_ms;

  // Convert back to days and return
  return Math.round(difference_ms / (1000 * 60 * 60 * 24));
};

const parseStringToLocaleDate = $value => {
  if (typeof $value === 'string') {
    return splitStringDate($value);
  } else {
    return $value;
  }
};
const _parseLocalDateFromString = (dateStr: string, onlyDateString: boolean = false): Date | string => {
  const date = new Date(dateStr);
  const offsetMinutes = date.getTimezoneOffset();
  date.setMinutes(date.getMinutes() + offsetMinutes);
  date.setHours(0, 0, 0, 0);

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Asegura que siempre tenga dos dígitos
  const day = date.getDate().toString().padStart(2, '0');

  return onlyDateString ? `${year}-${month}-${day}` : date;
};

//
// para este formato de fecha "2024-10-31T03:00:00.000Z"; devuelve 2024/10/31
//
const _getFormatDate = (date: string | Date): string => {
  let dateString: string = typeof date !== 'string' ? (date as Date)?.toISOString() : date;
  dateString = dateString?.split('T')[0].replace(/-/g, '/');
  return dateString;
};

const splitStringDate = $value => {
  if (dateFormat !== LenguageDateFormatType.DDMMYYYY) return $value;
  const [day, month, year] = $value.split('/');
  return new Date(`${month}/${day}/${year}`);
};

const _MomentToDate = date => {
  if (!date) return null;
  const $date = parseStringToLocaleDate(date);
  return $date instanceof Date ? $date : $date?._d;
};

/*TEST _reset_getNewNumberId*/
/*
let obj = {
  id: 0,
  name: 'a',
  shortName: 'a',
  typeDesc: 'Combo',
  code: 'a',
  creationDate: null,
  suggestedPrice: 0,
  discount: 0,
  fixedPrice: 10,
  priceType: 2,
  comboBlocks: [{ id: -104411572889502110, comboId: 0, name: 'A', comboItemIds: [2504] }],
  subCategoryId: 106,
  listBaseUnitOfMeasure: [{ code: 'U', name: 'Unidad', isBaseUnitOfMeasure: true, conversion: 1 }],
};
let objB = _reset_getNewNumberId(obj);
console.log(objB);
*/

/**
 * Gets the property value at path of object. If the resolved value is undefined the defaultValue is used
 * in its place.
 *
 * https://github.com/developit/dlv
 *
 * @param {object} o
 * @param {string} path
 */
const _get = delve;

/**
 * Sets the value at path of object. If a portion of path doesn't exist, it's created. Arrays are created for missing
 * index properties while objects are created for all other missing properties.
 *
 * @param object (Object): The object to modify.
 * @param path (Array|string): The path of the property to set.
 * @param value (*): The value to set.
 */
const _set = set;

/**
 * Mapea un número (value) entre su valor oldMin/oldMax con newMin/newMax
 * ej: _krange(0.5, 0, 1, 100, 200) // 150
 *
 * @param {number} [value=0]
 * @param {number} [oldMin=0]
 * @param {number} [oldMax=1]
 * @param {number} [newMin=0]
 * @param {number} [newMax=1]
 * @param {boolean} [outPutLimit=false]
 * @returns {number}
 */
const _krange = (value = 0, oldMin = 0, oldMax = 1, newMin = 0, newMax = 1, outPutLimit = false): number => {
  const divider = oldMax - oldMin;
  if (divider === 0) return 0;

  if (outPutLimit) {
    if (value < oldMin) {
      value = oldMin;
    }
    if (value > oldMax) {
      value = oldMax;
    }
  }

  const RANGE1: number = (value - oldMin) / divider;
  let rangeOut: number = (newMax - newMin) * RANGE1 + newMin;

  return rangeOut;
};

/**
 * Devuelve el horario en formato JSON con GTM correspondiente a el usuario. Para persistir en la base date + time
 * @input date: Date
 * @returns string => datetime as JSON with local Time Zone. Example 2021-09-14T23:59:00.000Z
 */
const _dateTimeJSONFormatAtLocalTimeZone = (date: Date): string => {
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}T${date.toLocaleTimeString()}.000Z`;
};
/**
 * Devuelve true si el parámetro es una función
 *
 * @param {*} val
 * @returns {boolean}
 */
const _isFunction = (val: any): boolean => val && typeof val === 'function';

/*
 * Devuelve una función nula
 */
const _NULL_FUNCT = () => null;

/*
 * Ejecuta una función considerando que puede devolver un error
 */
const _safeResult = (func: any, params: any): any => {
  if (!_isFunction(func)) {
    return null;
  }
  try {
    return params ? func(params) : func();
  } catch (e) {
    return null;
  }
};

/*
 * Plancha un array
 */
const _arrFlatten = (arr: [], depth = 1) =>
  arr.reduce((a, v) => a.concat(depth > 1 && Array.isArray(v) ? _arrFlatten(v, depth - 1) : v), []);

/*
 * setTimeout como promesa
 */
const _timeout = (ms = 0) => new Promise(res => setTimeout(res, ms));

/**
 * Convierte un string en otro sin acentos y en minúscula para poder compararlo
 *
 * @param {string} [str='']
 * @returns {string}
 */
const _normaliceString = (str = ''): string => deburr(str.toLowerCase());

const _memNormaliceString = _mem(_normaliceString);

const _filterLookupArrayKey = ($valueArr: any, search?: any, objValueKey?: any, fast?: boolean) => {
  if (!$valueArr) {
    return null;
  }
  if (!search) {
    return $valueArr;
  }

  return $valueArr.filter($val => {
    const val = !objValueKey ? $val : $val[objValueKey];
    if (!val) {
      return false;
    }

    if (fast === true) {
      return _containNormaliceStrings(val, search);
    }

    return _scoreBetweenStringsWithSpaces(val, search) > 0;
  });
};

const _sortParentId = list => {
  let $list = list;
  $list.sort((a, b) => {
    if (!a?.parentId) return -1;
    if (!b?.parentId) return 1;
    if (a?.parentId > b?.parentId && a?.id < a?.parentId) return -1;
  });

  return $list;
};

const _sortAscendentByName = list => {
  let $list = list;
  $list.sort((a, b) => {
    const nameA = _normaliceString(a.name);
    const nameB = _normaliceString(b.name);
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return $list;
};

/**
 * Compara 2 strings sin acentos y en minúscula
 *
 * @param {string} [str1='']
 * @param {string} [str2='']
 * @returns {boolean}
 */
const _compareNormaliceStrings = (str1 = '', str2 = ''): boolean => {
  if (str2.length !== str1.length) {
    return false;
  }

  return _normaliceString(str1) === _normaliceString(str2);
};

const _filterLookupMultipleProps = ($valueArr: any, search?: any, objValueKey?: string[], fast?: boolean) => {
  if (!$valueArr) {
    return null;
  }
  if (!search || !objValueKey) {
    return $valueArr;
  }

  return $valueArr.filter($val => {
    const values = objValueKey.map(x => $val[x]);
    if (!values) {
      return false;
    }

    if (fast === true) {
      return values.some(val => _containNormaliceStrings(val, search));
    }
    return values.some(val => _scoreBetweenStringsWithSpaces(val, search) > 0);
  });
};

/**
 * Compara si un string contiene a otro normalizado
 *
 * @param {string} [str1='']
 * @param {string} [str2='']
 * @returns {boolean}
 */
const _containNormaliceStrings = (str1 = '', str2 = '', memStr2 = true, normSpcs = false): boolean => {
  if (normSpcs) {
    //Saca espacios
    str1 = str1.replace(/\s/g, '');
    str2 = str2.replace(/\s/g, '');
  } else {
    str2 = str2.trim();
  }

  if (!str1 || !str1.length) return false;

  if (!str2 || !str2.length) return true;

  if (str2 && str2.length > str1.length) return false;

  return memStr2
    ? _normaliceString(str1).indexOf(_memNormaliceString(str2)) !== -1
    : _normaliceString(str1).indexOf(_normaliceString(str2)) !== -1;
};

/**
 * Checkea si un objeto o array está vacío
 *
 * @param {*} [obj='']
 * @returns {boolean}
 */
const _isEmpty = (obj: any = ''): boolean => {
  if (obj === undefined) {
    return false;
  }

  if (obj !== null && obj !== undefined && typeof obj === 'object') {
    return isEmpty(obj);
  }
  if (obj !== null && obj !== undefined && Array.isArray(obj)) {
    return isEmpty(obj);
  }

  return false;
};

const _hasValue = (obj: any = ''): boolean => {
  if (!obj) return false;

  if (obj === true) return true;

  if (Array.isArray(obj) || typeof obj === 'string') {
    return !!obj.length;
  }

  //Todo: hacer un _hasValueDeep
  if (typeof obj === 'object') {
    return !!Object.keys(obj).length;
  }

  return false;
};

/**
 * Checkea sin un objeto está vacío
 *
 * @param {*} [obj='']
 * @returns {boolean}
 */
const _isEmptyObject = (obj: any = {}): boolean => {
  if (!obj) {
    return true;
  }

  return Object.keys(obj).length === 0;
};

/**
 * Remueve todas las keys de un objeto que empiecen con el parámetro keys
 *
 * @param {*} $obj
 * @param {string} [keys='_']
 * @param {boolean} [clone=false]
 * @returns {({} | void)}
 */
const _removeUnderscores = ($obj: any, keys = '_', clone = false): {} | void => {
  if ($obj === null || $obj === undefined) {
    return $obj;
  }
  const obj = clone ? _cloneDeep($obj) : $obj;

  if (Array.isArray(obj)) {
    obj.forEach((item: any): void => {
      _removeUnderscores(item, keys, false);
    });
  } else if (obj && typeof obj === 'object') {
    Object.getOwnPropertyNames(obj as object).forEach((key): void => {
      if (key.indexOf(keys) === 0) {
        delete obj[key];
      } else {
        _removeUnderscores(obj[key], keys, false);
      }
    });
  }

  return obj;
};

/**
 * Mueve un elemento de posición en un array
 *
 * @param {[]} arr
 * @param {number} [old_index=0]
 * @param {number} [new_index=0]
 * @returns {[]}
 */
function _arrayMoveEl($arr: [], old_index = 0, new_index = 0): [] {
  if (old_index === new_index) {
    return $arr;
  }
  const arr = _cloneDeep($arr);
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

  return arr;
}

/**
 * Returns a list of elements that exist in both arrays.
 *
 * @param {[]} a
 * @param {[]} b
 * @returns {[]}
 */
const _arrIntersection = (a: [], b: [], unique = true) => {
  const s = new Set(b);

  return unique ? a.filter(x => s.has(x)) : a.filter(x => b.includes(x));
};

/**
 * Returns a list of elements that exist in both arrays, after applying the provided function to each array element of both.
 *
 * @param {[]} a
 * @param {[]} b
 * @param {*} fn
 * @returns {[]}
 */
const _arrIntersectionBy = (a: [], b: [], fn) => {
  const s = new Set(b.map(fn));

  return a.filter(x => s.has(fn(x)));
};

/**
 * Returns a list of elements that exist in both arrays, using a provided comparator function.
 *
 * @param {[]} a
 * @param {[]} b
 * @param {*} fn
 */
const _arrIntersectionWith = (a: [], b: [], fn) => a.filter(x => b.findIndex(y => fn(x, y)) !== -1);

/**
 * Randomizes the order of the values of an array, returning a new array.
 *
 * @param {*} [...arr]
 * @returns
 */
const _shuffleArr = _shuffle;

/**
 * Capitalizes the first letter of a string.
 *
 * @param {*} [first, ...rest]
 * @param {boolean} [lowerRest=false]
 */
const _StrCapitalize = ([first, ...rest], lowerRest = false) =>
  first.toUpperCase() + (lowerRest ? rest.join('').toLowerCase() : rest.join(''));

/**
 * Returns the native type of a value.
 *
 * @param {*} v
 */
const _getType = v => (v === undefined ? 'undefined' : v === null ? 'null' : v.constructor.name.toLowerCase());

/**
 * Checks if the provided value is of the specified type.
 * ej: is(Array, [1]); // true
 * ej:is(String, ''); // true
 *
 * @param {*} type
 * @param {*} val
 */
const _is = (type: any, val: any): boolean => ![, null].includes(val) && val.constructor === type;

/**
 * Casts the provided value as an array if it's not one.
 * castArray('foo'); // ['foo']
 * castArray([1]); // [1]
 *
 * @param {*} val
 */
const _castArray = val => (Array.isArray(val) ? val : val ? [val] : []);

/**
 * Returns the difference between two arrays.
 * difference([1, 2, 3], [1, 2, 4]); // [3]
 *
 * @param {*} a
 * @param {*} b
 * @returns
 */
const _arrDifference = (a, b) => {
  const s = new Set(b);

  return a.filter(x => !s.has(x));
};

/**
 * Devuelve un objeto como string para imprimir en los templates
 *
 * @param {*} obj
 * @returns
 */
const _printObj = obj => {
  if (obj === null || obj === undefined) {
    return obj + '';
  }

  if (_is(Object, obj)) {
    return JSON.stringify(obj, null, 3);
  }

  return obj + '';
};

const _pick = (obj, arr) => arr.reduce((acc, curr) => (curr in obj && (acc[curr] = obj[curr]), acc), {});

const _auxStrToKeys = function (str) {
  return String(str)
    .replaceAll('\r', ' ')
    .replaceAll('\n', ' ')
    .replaceAll('  ', ' ')
    .split(' ')
    .map(st => st.trim())
    .filter(st => st?.length > 0);
};

// TODO: Documentar
function _debounceDecorator(milliseconds: number = 0, options = {}) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const map = new WeakMap();
    const originalMethod = descriptor.value;
    descriptor.value = function (...params) {
      let debounced = map.get(this);
      if (!debounced) {
        debounced = debounceFn(originalMethod, milliseconds, options).bind(this);
        map.set(this, debounced);
      }
      debounced(...params);
    };
    return descriptor;
  };
}

function _throttleDecorator(milliseconds: number = 0, options = {}): any {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = throttleFn(originalMethod, milliseconds, options);
    return descriptor;
  };
}

// TODO: Documentar
const _filterNonUniqueBy = (arr, fn) => {
  if (!arr || !arr.length) {
    return [];
  }
  if (!_isFunction(fn)) {
    return arr;
  }

  return arr.filter((v, i) => arr.every((x, j) => (i === j) === fn(v, x, i, j))) || [];
};

// TODO: Documentar
const _uniqueElementsBy = (arr: [], fn) => {
  if (!arr || !arr.length) {
    return [];
  }
  if (!_isFunction(fn)) {
    return arr;
  }

  return arr.reduce((acc, v) => {
    if (!acc.some(x => fn(v, x))) {
      acc.push(v);
    }

    return acc;
  }, []);
};

const _uniqueElementsByKey = ($arr: [], key: string) => {
  if (!$arr || !$arr.length) return [];

  const rv = [];
  const arrKeys = [];
  const size = $arr?.length || 0;

  for (let i = 0; i < size; i++) {
    const item = $arr[i];
    const itemKey = item[key];
    if (itemKey !== undefined && !arrKeys.includes(itemKey)) {
      rv.push(item);
      arrKeys.push(itemKey);
    }
  }

  return rv;
};

/**
 * Devuelve un valor entre 0 y 1 con la similitud de un string y otro
 *
 * @param {string} str
 * @param {string} search
 * @returns {number}
 */
const _scoreBetweenStrings = (str: string, search: string): number => {
  // EQUAL

  str = _normaliceString(str);
  search = _memNormaliceString(search);

  if (str === search) {
    return 1;
  }

  const strLength = str.length;
  const searchLength = search.length;

  if (0 === strLength) {
    return 0;
  }

  // LENGTH 0 OR SEARCH IS LARGER
  if (searchLength >= strLength) {
    return 0;
  }

  // BREAKS FUZZY BY LENGHT
  if (100 <= searchLength && -1 === str.indexOf(search)) {
    return 0;
  }

  const strHasSpaces = -1 !== str.indexOf(' ');

  let runningScore = 0,
    charScore = 0,
    subStrPrev = '',
    idxOf = 0,
    i = 0,
    subStr = str,
    letter = '',
    cont_letter = true,
    cont_space = true,
    finalScore = 0;

  for (i; searchLength > i; i += 1) {
    letter = search[i];
    idxOf = subStr.slice(i).indexOf(letter);

    if (-1 === idxOf) {
      return 0;
    }

    if (0 === idxOf) {
      if (0 === i) {
        charScore = 1;
      } else {
        cont_space = false;
        if (3 >= i) {
          charScore = 0.5;
        } else {
          charScore = 0.7;
        }
      }
    } else {
      subStrPrev = subStr;
      subStr = subStr.slice(idxOf);
      if (0 < i) {
        if (true === strHasSpaces && searchLength <= 4 && -1 !== subStrPrev.indexOf(` ${letter}`)) {
          if (true === cont_space) {
            charScore = 0.1;
          } else {
            charScore = 0.05;
          }
          cont_space = true;
          cont_letter = false;
        } else if (true === cont_space ? 1 === i : true && -1 !== subStrPrev.indexOf(search[i - 1] + letter)) {
          if (true === cont_letter) {
            charScore = 0.4;
          } else {
            charScore = 0.1;
          }
          cont_space = false;
          cont_letter = true;
        } else {
          charScore = 0.1;
          cont_space = false;
          cont_letter = false;
        }
      } else {
        charScore = 0.1;
        cont_space = false;
        cont_letter = false;
      }
    }

    runningScore += charScore;
  }

  finalScore = 0.5 * (runningScore / strLength + runningScore / searchLength);

  return finalScore;
};

/**
 * Devuelve un valor entre 0 y 1 con la similitud de un string y otro (Acepta Espacios)
 *
 * @param {string} str
 * @param {string} search
 * @returns {number}
 */
const _scoreBetweenStringsWithSpaces = (str: string, search: string): number => {
  if (search.indexOf(' ') === -1 || str.indexOf(' ') === -1) {
    return _scoreBetweenStrings(str, search);
  }

  const searchs = search.split(' ').filter(s => s.length > 0);

  let result = 0;

  searchs.forEach($search => {
    if (result < 0) {
      return 0;
    }
    const partRes = _scoreBetweenStrings(str, $search);
    if (partRes <= 0) {
      result = -1;

      return 0;
    }
    result += partRes;
  });

  if (result < 0) {
    return 0;
  }
  if (result > 0) {
    result = result / searchs.length;
  }

  return result;
};

/**
 * deepmerge > https://www.npmjs.com/package/deepmerge
 * Merges the enumerable properties of two or more objects deeply.
 */
const _extendObjDepp = deepmerge;

/**
 * Verifica que los valores de un grupo contengan una cadena de texto
 *
 * @param {AttributeItemModel} [group]
 * @param {string} [str='']
 * @returns {boolean}
 */
const _containValuesInGroups = (group: AttributeItemModel, str = ''): boolean => {
  let contain = false;

  if (str === '') {
    contain = true;
  } else {
    group.values.forEach(element => {
      if (_containNormaliceStrings(element.value, str)) {
        contain = true;

        return contain;
      }
    });
  }

  return contain;
};

/**
 * Auxiliar para filtrar subCategorías (Padres e hijos)
 *
 * @param {string} catVal
 * @param {any[]} cat
 * @param {string} lookup
 * @returns {boolean}
 */
const _subCatContainsLookUp = (catVal: string, cat: Array<any>, lookup: string): boolean => {
  if (!lookup || lookup.length === 0) {
    return true;
  }
  if (_containNormaliceStrings(catVal, lookup)) {
    return true;
  }

  return cat.some(val => val && val.value && _containNormaliceStrings(val.value, lookup));
};

const _setInputValue = (text = '', elementStr: string, refocus = true, maintainRefocus = false): void => {
  if (!elementStr || elementStr === '') {
    return;
  }
  const matches = document.querySelectorAll(elementStr);

  if (matches && matches[0]) {
    const el = matches[0] as HTMLInputElement;
    if (el.value !== text) {
      el.value = text;
      if (refocus && !maintainRefocus) {
        el.focus();
        el.blur();
      }
      if (maintainRefocus) {
        el.focus();
      }
    }
  }
};

/**
 * Setea el valor del input genérico de búsqueda de texto de los listados
 *
 * @param {string} [text='']
 * @param {string} [elementStr='ian-default-toolbar .mat-form-field-infix input']
 * @param {number} [$time=1]
 * @returns {void}
 */
const _setTextFilterValue = (text = '', elementStr = null, $time = 1, refocus = true, maintainRefocus = true): void => {
  if (elementStr == null) elementStr = 'ian-default-toolbar .mat-form-field-infix input';

  if ($time === 0) {
    return _setInputValue(text, elementStr, refocus, maintainRefocus);
  }

  setTimeout(() => {
    _setInputValue(text, elementStr, refocus, maintainRefocus);
  }, $time);
};

/**
 * Setea scrollTop generico para el contenido de la pantalla en el template fuse
 *
 * @param {string} [elementStr='._scrollTopPageContent']
 * @param {number} [$time=1]
 * @returns {void}
 */
const _setScrollTopPageContent = (elementStr = '._scrollTopPageContent', $time = 1): void => {
  setTimeout(() => {
    if (!elementStr || elementStr === '') {
      return;
    }
    const matches = document.querySelectorAll(elementStr);
    if (matches && matches[0]) {
      const el = matches[0] as HTMLInputElement;
      el.scrollTop = 0;
    }
  }, $time);
};

/**
 * Convierte un número a fixed sin redondear
 *
 * @param {number} num
 * @param {number} fixed
 * @returns {number}
 */
const _toFixed = (num: number, fixed: number): string => {
  const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');

  return num.toString().match(re)[0];
};

/**
 * Devuelve la cantidad de decimales que tiene el número evaluado
 *
 * @param {number} number
 * @returns {number}
 */
const _decimalLenght = (number: number): number => {
  if (Math.floor(number) === number) {
    return 0;
  }

  return number.toString().split('.')[1].length || 0;
};

const _dateUtcToLocal = (time: string): Date => {
  return new Date((new Date(time) as any) - new Date().getTimezoneOffset() * 60000);
};

/**
 * Devuleve un string con el tiempo que paso desde el date que se le pasa como parámetro
 * ej: gace 30 minutos.
 *
 * @param {*} time //valid date
 * @returns {string}
 */
const _prettyDateEs = (time: any): string => {
  const date = new Date(time || ''),
    diff = (new Date().getTime() - date.getTime()) / 1000,
    day_diff = Math.floor(diff / 86400);

  if (isNaN(day_diff) || day_diff < 0) {
    return 'ahora';
  }
  if (day_diff >= 31) {
    return 'más de 1 mes';
  }

  const res =
    (day_diff === 0 &&
      ((diff < 60 && 'ahora') ||
        (diff < 120 && 'hace 1 minuto') ||
        (diff < 3600 && 'hace ' + Math.floor(diff / 60) + ' minutos') ||
        (diff < 7200 && 'hace 1 hora') ||
        (diff < 86400 && 'hace ' + Math.floor(diff / 3600) + ' horas'))) ||
    (day_diff === 1 && 'ayer') ||
    (day_diff < 7 && 'hace ' + day_diff + ' días') ||
    (day_diff < 31 && 'hace ' + Math.ceil(day_diff / 7) + ' semanas');

  return res;
};

/**
 * Devuelve un observable con data mock
 *
 * @param {any | string } _error // error ocurrido en http
 * @param {any} _service // se envia el servicio para obtener su nombre
 * @param {any} _dummyData // data a mockear
 * @param {string} _dummyDataName // nombre para el log de errores
 * @param {number} _timeOut // default 128
 * @param {any} _throwInitialState // mayormente se setea [] o {} para el caso que devuelva un listado o un item
 * @returns {Observable<any>} // devuelve el observable con la data mock
 **/
const _throwDummyData = (
  _error: any,
  _service: any,
  _dummyData: any,
  _dummyDataName: string,
  _throwInitialState: any = null,
  _timeOut = 128
): Observable<any> => {
  console.error(`Service: ${_service.name}`, { error: _error });
  console.log(`🚧🚧🚧 %c[FAKE DATA]:`, 'color:#e93f3b', _dummyDataName, { data: _dummyData }, '\n\n');

  return new Observable(observer => {
    if (_throwInitialState !== null) {
      observer.next(_throwInitialState);
    }
    setTimeout(() => {
      observer.next(_dummyData);
      observer.complete();
    }, _timeOut);
  });
};

/**
 * Emite una notificacion de error
 *
 * @param {any | string } _error // error ocurrido en http
 * @param {any} _service // se envia el servicio para obtener su nombre
 * @returns {Observable<any>} // emite un error notification
 */
const _throwError = (_error: any, _service?: any): Observable<any> => {
  console.error(`${_service.name}::handleError\n\tdata: %o`, _error);

  return throwError(_error);
};

/**
 * Devuelve data mockeada dependiendo si se encuentra en ambiente de desarrollo o prod, en el segundo caso emite el error
 *
 * @param {boolean} _enable // habilita o deshabilita el uso de data mockeada
 * @param {any | string } _error // error ocurrido en http
 * @param {any} _service // se envia el servicio para obtener su nombre
 * @param {any} _dummyData // data a mockear
 * @param {string} _dummyDataName // nombre para el log de errores
 * @param {any} _throwInitialState // mayormente se setea [] o {} para el caso que devuelva un listado o un item
 * @param {number} _timeOut // default 128
 * @returns {Observable<any>} // devuelve el observable con la data mock
 */
const _handleErrorWithDummyData = (
  _enable: boolean,
  _error: any,
  _service: any,
  _dummyData: any,
  _dummyDataName: string,
  _throwInitialState: any = null,
  _timeOut = 128
): Observable<any> =>
  _enable ? _throwDummyData(_error, _service, _dummyData, _dummyDataName, _throwInitialState, _timeOut) : _throwError(_error, _service);

/**
 * Devuelveun objeto con el nombre del atributo
 *
 * @param {any} attr // recibe un atributo dentro de un objeto, debe ser un unico atributo {} (no permite caracteres invalidos en el namespace, solo una palabra)
 * @returns {any} // devuelve un objeto tipo {name: string, value: any} con el nombre y valor de su contenido
 */
const _getNameSpace = (attr: any): any => {
  const _name = Object.keys(attr).pop();

  return { name: _name, value: attr[_name] };
};

/**
 * igual que _prettyDateEs pero memorizado por 1 minuto
 * ej: gace 30 minutos.
 *
 * @param {*} time //valid date
 * @returns {string}
 */
const _prettyDateEsMem = _mem(_prettyDateEs, { maxAge: _ms('1m') });

/**
 * Formatea para la libreria numeral, con la cantidad de decimales necesarios
 * Ejemplo: '0%' es sin decimales, '0,000%' es con 3 decimales.
 *
 * @param {number} cantidadDeDecimales cantidad de decimales que quiero.
 * @returns {string}
 */
const _formatPercentInternal = (cantidadDeDecimales: number, sufix: string = '%'): string => {
  let _format = '0';
  if (cantidadDeDecimales > 0) {
    for (let _i = 0; cantidadDeDecimales > _i; _i++) {
      _format += _i === 0 ? '.0' : '0';
    }
  }
  _format += sufix;

  return _format;
};

/**
 *
 * @param number recibe un numero entero y lo convienrte en un decimal
 */
const _convertPercentToDecimal = (number: number): number => (number !== null && number !== undefined ? number / 100 : number);

/**
 *
 * @param number recibe un numero decimal y lo convienrte en un entero sin %, eso se resuelve en la vista.
 */
const _convertoDecimalToPercent = ($number: number, decimals: number = 1): number => {
  if ($number !== null && $number !== undefined && $number !== 0) {
    const _decimals = decimals != null ? decimals : 1; //2q03hc : porcentajes con un solo decimal
    const _format = _formatPercentInternal(_decimals);
    const _number = numeral($number).format(_format);

    // elimina el % que agrega la libreria y envia el número final
    return _number.replace('%', '');
  } else {
    return $number;
  }
};

/**
 *
 * @param number recibe un numero decimal y lo convierte en uno entero redondeado
 */
const _convertDecimalToPerRound = (number: number, places = 2): number => {
  return number !== null && number !== undefined ? _roundDec(number * 100, places) : number;
};

const _roundDec = (value, places = 2) => {
  let multiplier = Math.pow(10, places);
  if (multiplier < 1) multiplier = 1;
  return Math.round(value * multiplier) / multiplier;
};

/**
 * Devuelve si un numero está expresado en string
 *
 * @param {*} val
 * @returns {boolean}
 */
const _isNumberStr = (val): boolean => {
  if (val === '0') return true;
  return val !== undefined && val !== null && val !== '';
};

/**
 * Devuelve si un numero que está como string a numero, si no era número devuelve null
 *
 * @param {*} val
 * @returns {Number}
 */
const _numberStr2Number = (val): Number => {
  return _isNumberStr(val) ? Number(val) : null;
};

type _forceTypeTypes = 'number' | 'string';
/**
 * Convierte un valor string o numerico a otro string o numerico
 * ej _forceType('0', 'number') // 0
 * ej _forceType(0, 'string') // '0'
 *
 * @param {*} val
 * @param {(_forceTypeTypes | string ('number' | 'string'))} type
 * @returns {(number | string)}
 */
const _forceType = (val, type: _forceTypeTypes | string): number | string => {
  if (!type) return val;
  if (type === 'number') val = _isNumberStr(val) ? Number(val) : val;
  if (type === 'string') val = String(val);
  return val;
};

/**
 * si la url está en localHost
 */
const _isLocalHost = String(_get(window ? window : {}, 'location.href')).includes('//localhost');

/**
 * da vuelta los [key]/values de un {}
 *
 * @param {*} $obj
 * @param {boolean} [clone=true]
 * @returns
 */
const _objFlipKeyVal = ($obj, clone: boolean = true) => {
  let obj = clone ? _cloneDeep($obj) : $obj;
  const ret = {};
  Object.keys(obj).forEach(key => {
    ret[obj[key]] = key;
  });
  return ret;
};

/**
 * Devuelve si un objeto es null o undefined
 *
 * @param {*} obj
 * @returns
 */
const _noVal = obj => {
  return obj === null || obj === undefined;
};

/**
 * Devuelve si el valor es de tipo numerico
 */
const _isNumber = value => {
  return typeof value === 'number';
};
/**
 * Devuelve boolean sobre si el dato es de tipo numerico y si existe.
 * @param value: any
 * @returns boolean exist data as type number
 */
const _hasNumber = value => {
  return !_noVal(value) && _isNumber(value);
};

/**
 * Patchea el valor del state con _set, ej: _patchNgXsSatateWithSet(context, 'form.name', 'test')
 *
 * @param {*} context
 * @param {*} path
 * @param {*} val
 * @param {boolean} [verbose=false]
 * @returns
 */
const _patchNgXsSatateWithSet = (context: StateContext<any>, path: string, val: any, verbose = false, _checkEqual = false) => {
  // No hay path
  if (!path) {
    if (verbose) console.warn('[patchSatateWithSet]', 'No Path');
    return null;
  }

  // No hay context
  if (!context || !context.getState) {
    if (verbose) console.warn('[patchSatateWithSet]', 'context');
    return null;
  }

  const actualState = context.getState();
  const actualStateVal = _lGet(actualState, path);

  // Mismo valor
  if (val === actualStateVal) return null;
  if (_checkEqual && _equal(val, actualStateVal)) return null;

  const pathIsUndefined = actualStateVal === undefined;
  if (pathIsUndefined && verbose) {
    console.warn('path no exist:', path);
  }

  // Patch
  let _path = path.replace(/[\[']+/g, '.').replace(/[\]']+/g, ''); //saca los corchetes que a immutable.set no le gustan
  const newState = immutable.set(actualState, _path, val);

  //LOG
  if (verbose) console.log('[patchSatateWithSet]', { actualState, path, value: val, newState });

  return context.patchState({
    ...newState,
  });
};

/**
 * trae un elemento de HTML por el id / util pq los componentes no tienen document
 *
 * @param {string} elementID
 * @returns {HTMLFormElement}
 */
const _getElementById = (elementID: string): HTMLElement => {
  if (!document || !elementID) return null;
  return document.getElementById(elementID) as HTMLElement;
};

/**
 * Devuelve sin un form (by id) tiene .ng-valid
 *
 * @param {string} formId
 * @param {boolean} [verbose=false]
 * @param {boolean} [checkValidity=false]
 * @returns
 */
const _isFormValidByquerySelector = (formId: string, verbose = false, checkValidity = false, forceNoInvalidChilds = false): boolean => {
  if (!document) return null;
  const formElement = document.querySelector(formId) as HTMLFormElement;

  if (!formElement) {
    if (verbose) console.warn('[_isFormValidByquerySelector] no element:', formId);
    return false;
  }

  let isValid = !forceNoInvalidChilds
    ? formElement?.classList?.contains && !formElement.classList.contains('ng-invalid')
    : document.querySelector(`${formId} .ng-invalid`) == null;

  if (verbose) console.log({ form: formElement, isValid, current_step: formId });

  if (checkValidity) formElement.checkValidity();

  return isValid;
};

/**
 * Devuelve sin un form (by id) tiene .ng-dirty
 *
 * @param {string} formId
 * @param {boolean} [verbose=false]
 * @param {boolean} [checkValidity=false]
 * @returns
 */
const _isFormDirtyByquerySelector = (formId: string, verbose = false, forceNoInvalidChilds = false): boolean => {
  if (!document) return null;
  const formElement = document.querySelector(formId) as HTMLFormElement;

  if (!formElement) {
    if (verbose) console.warn('[_isFormDirtyByquerySelector] no element:', formId);
    return false;
  }

  let isDirty = !forceNoInvalidChilds
    ? formElement?.classList?.contains && formElement.classList.contains('ng-dirty')
    : document.querySelector(`${formId} .ng-dirty`) != null;

  if (verbose) console.log({ form: formElement, isValid: isDirty, current_step: formId });

  return isDirty;
};

const _equalArrays = (arr1, arr2, key = null): boolean => {
  if (arr1 == arr2) return true;
  if (arr1 == null || arr2 == null) return false;

  let as1 = [];
  let as2 = [];

  if (!key) {
    as1 = [...arr1].sort((a, b) => a - b);
    as2 = [...arr2].sort((a, b) => a - b);
  } else {
    as1 = [...arr1].sort((a, b) => a[key] - b[key]);
    as2 = [...arr2].sort((a, b) => a[key] - b[key]);
  }

  return _equal(as1, as2);
};

/**
 * Convierte un valor numerico a otro formateado //TODO: Documentar opciones
 *
 * @param {number} val
 * @param {number} [maxDecimals=2]
 * @param {boolean} [fixed=false]
 * @param {{}} [$options={}]
 * @returns {(number | string)}
 */
/*
  console.log(_formatDecimals(10.133));
  console.log(_formatDecimals(10));
  console.log(_formatDecimals(10, 3, true));
  console.log(_formatDecimals(10, 2, false, { multiply: true, stringify: true }));
*/
const _formatDecimals = (val: number, maxDecimals: number = 2, fixed: boolean = false, $options: {} = {}): number | string => {
  if (typeof val !== 'number') return 0;

  const opt = Object.assign(
    {},
    {
      multiply: false, // Multiplica * 100
      stringify: false, // le agrega %
      stringifyWithSpace: true, // le suma un espacio
      toLocalString: true, // formatea segun local
    },
    $options
  );

  let presition = Math.pow(10, maxDecimals);
  if (opt.multiply) val = val * 100;
  let $val = !fixed ? Math.round(val * presition) / presition : val.toFixed(maxDecimals);

  if (opt.toLocalString && opt.stringify && !fixed) {
    const lang = window ? _get(window, '_info._lang') : null;
    if (lang) $val = $val.toLocaleString(lang);
  }

  return !opt.stringify ? $val : $val + (opt.stringifyWithSpace ? ' ' : '') + '%';
};

/* TEST IN QUOKKA */
if (true) {
  // console.log('[]', _normaliceString('áá'));
  // console.log(_formatDecimals(10.133));
}

const notBase64 = /[^A-Z0-9+\/=]/i;

const _isBase64 = str => {
  const len = str.length;
  if (!len || len % 4 !== 0 || notBase64.test(str)) {
    return false;
  }
  const firstPaddingChar = str.indexOf('=');
  return firstPaddingChar === -1 || firstPaddingChar === len - 1 || (firstPaddingChar === len - 2 && str[len - 1] === '=');
};

const _orderBy = (arr, props, orders = []) => {
  if (!arr || !arr.length || !props || !props.length) return arr;

  return [...arr].sort((a, b) =>
    props.reduce((acc, prop, i) => {
      if (acc === 0) {
        const [p1, p2] = orders && orders[i] === 'desc' ? [b[prop], a[prop]] : [a[prop], b[prop]];
        acc = p1 > p2 ? 1 : p1 < p2 ? -1 : 0;
      }
      return acc;
    }, 0)
  );
};

const _sortArrayAlpha = (arr, key = null) => {
  if (!arr || !arr.length) return arr;

  arr.sort((a, b) => {
    const textA = ((key ? a[key] : a) || '').toUpperCase();
    const textB = ((key ? b[key] : b) || '').toUpperCase();

    if (textA === String(Number(textA)) && textB === String(Number(textB))) return Number(textA) - Number(textB);

    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  return arr;
};

const _sortArrayTreeAlpha = (arr, key = null, childProp = 'items') => {
  if (!arr || !arr.length) return arr;

  arr = _sortArrayAlpha(arr, key).map(child => {
    if (child && child[childProp] && child[childProp].length) child[childProp] = _sortArrayTreeAlpha(child[childProp], key, childProp);
    return child;
  });

  return arr;
};

const _insertArrayItemAtPos = (arr, index, newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index),
];

function _shuffle(array) {
  return array.sort(() => Math.random() - 0.5);
}

function _randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function _setElementAttributes(elem, obj) {
  if (!elem) return;
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      elem[prop] = obj[prop];
    }
  }
}

function _setElementStyles(elem, propertyObject) {
  if (!elem) return;
  for (let property in propertyObject) elem.style[property] = propertyObject[property];
}

/*
  Calcula la distancia entre dos puntos de un mapa
  http://www.geodatasource.com/developers/javascript
*/
function _getGeoDistance(lat1, lon1, lat2, lon2, unit = 'M') {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const radtheta = (Math.PI * (lon1 - lon2)) / 180;

    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) dist = 1;

    dist = (Math.acos(dist) * 180) / Math.PI;

    dist = dist * 60 * 1.1515;

    if (unit && unit.toUpperCase() === 'M') return dist * 1.609344 * 1000;
    if (unit && unit.toUpperCase() === 'K') return dist * 1.609344;
    if (unit && unit.toUpperCase() === 'N') return dist * 0.8684;
    return dist;
  }
}

/*
    COLORS AUXS
*/
const _colorToRGBA = hex => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return [(c >> 16) & 255, (c >> 8) & 255, c & 255];
  }
  throw new Error('Bad Hex');
};
const _colorToRGBInterpolate = hex => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return 'rgb(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ')';
  }
  throw new Error('Bad Hex');
};
const _rgbToHex = (r, g, b) => {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);
  if (r.length === 1) r = '0' + r;
  if (g.length === 1) g = '0' + g;
  if (b.length === 1) b = '0' + b;
  return '#' + r + g + b;
};
const _rgbToHexInterpolate = ($color = null) => {
  if ($color == null) return null;
  let color = $color.replace('rgba(', '').replace('rgb(', '').replace(')', '').split(',');
  return _rgbToHex(Number(color[0] || 0), Number(color[1] || 0), Number(color[2] || 0));
};

/*Obtiene el color legible pata un texto pasándole el background-color*/
//adaptado de: https://stackoverflow.com/questions/35969656/how-can-i-generate-the-opposite-color-according-to-current-color
function _getTextColorFromBackColor(hex: string, bw = true) {
  const padZero = (str, len = 2) => {
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  };

  if (hex.indexOf('#') === 0) hex = hex.slice(1);
  if (hex.length === 3) hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  if (hex.length !== 6) throw new Error('Invalid HEX color.');

  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);

  if (bw) return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';

  r = Number((255 - r).toString(16));
  g = Number((255 - g).toString(16));
  b = Number((255 - b).toString(16));

  return '#' + padZero(r) + padZero(g) + padZero(b);
}

// Changes the RGB/HEX temporarily to a HSL-Value, modifies that value
// and changes it back to RGB/HEX.

function changeHue(rgb, degree) {
  let hsl = rgbToHSL(rgb);
  hsl.h += degree;
  if (hsl.h > 360) {
    hsl.h -= 360;
  } else if (hsl.h < 0) {
    hsl.h += 360;
  }
  return hslToRGB(hsl);
}

// exepcts a string and returns an object
function rgbToHSL(rgb) {
  // strip the leading # if it's there
  rgb = rgb.replace(/^\s*#|\s*$/g, '');

  // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
  if (rgb.length == 3) {
    rgb = rgb.replace(/(.)/g, '$1$1');
  }

  let r = parseInt(rgb.substr(0, 2), 16) / 255,
    g = parseInt(rgb.substr(2, 2), 16) / 255,
    b = parseInt(rgb.substr(4, 2), 16) / 255,
    cMax = Math.max(r, g, b),
    cMin = Math.min(r, g, b),
    delta = cMax - cMin,
    l = (cMax + cMin) / 2,
    h = 0,
    s = 0;

  if (delta == 0) {
    h = 0;
  } else if (cMax == r) {
    h = 60 * (((g - b) / delta) % 6);
  } else if (cMax == g) {
    h = 60 * ((b - r) / delta + 2);
  } else {
    h = 60 * ((r - g) / delta + 4);
  }

  if (delta == 0) {
    s = 0;
  } else {
    s = delta / (1 - Math.abs(2 * l - 1));
  }

  return {
    h: h,
    s: s,
    l: l,
  };
}

// expects an object and returns a string
function hslToRGB(hsl) {
  let h = hsl.h,
    s = hsl.s,
    l = hsl.l,
    c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r,
    g,
    b;

  if (h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (h < 300) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  r = normalize_rgb_value(r, m);
  g = normalize_rgb_value(g, m);
  b = normalize_rgb_value(b, m);

  return _rgbToHex(r, g, b);
}

function normalize_rgb_value(color, m) {
  color = Math.floor((color + m) * 255);
  if (color < 0) {
    color = 0;
  }
  return color;
}

/******/

function _renameKeysPrefix(obj, prefix) {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = prefix + key || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

/*Plancha un array de objetos a un array plano de ids ej [1,2,3]*/
const _array2plainId = (arr, id = 'id') => {
  return arr
    .map(el => {
      if (typeof el === 'number') return el;
      if (typeof el === 'object' && el[id] !== undefined) return el[id];
      return null;
    })
    .filter(el => el !== null && el !== undefined);
};

const _toggleArrayItem = (arr: any[], value: any) => {
  let i = arr.indexOf(value);
  if (i === -1) arr.push(value);
  else arr.splice(i, 1);

  arr = arr.filter(el => el != null);

  return arr;
};

const _formatCurrency = ($num, dec = 2) => {
  let num = $num;
  if (!num || isNaN(num)) num = 0;

  if (num > 1000) num = Math.round(num);
  if (num > 1000000) num = Math.round(num * 1000) / 1000;

  return new Intl.NumberFormat().format(_roundDec(Number(num), dec));
};

const _formatCurrencyDec = ($num, dec = 2) => {
  let num = $num;
  if (!num || isNaN(num)) num = 0;

  return Number(_roundDec(Number(num), dec)).toFixed(dec);
};

const _formatPercent = ($num, dec = 2) => {
  let num = $num;
  if (!num || isNaN(num)) num = 0;

  return _roundDec(Number(num) * 100, dec);
};

/**
 * chequear si la segunda fecha es mayor a la primera, en caso de no ingresar una sera verdad ya que no existe.
 * @param dateStart fecha inicial
 * @param dateStart fecha final
 * @param _callback funcion de comparacion entre dos parametros, por default a < b
 */
const _compareTwoDates = (dateStart = null, dateEnds = null, _callback = null, hasHours = false): boolean => {
  if (!dateStart || !dateEnds) return true;

  const start = _convertToDate(dateStart, hasHours);
  const end = _convertToDate(dateEnds, hasHours);
  return !_callback ? start < end : _callback(start, end);
};

/**
 * chequear si la segunda hora es mayor a la primera, en caso de no ingresar una sera verdad ya que no existe.
 * @param dateStart hora inicial
 * @param dateStart hora final
 * @param _callback funcion de comparacion entre dos parametros, por default a < b
 */
const _compareTwoTimes = (dateStart = null, dateEnds = null, _callback = null): boolean => {
  if (!dateStart || !dateEnds) return true;

  const start = _convertToTime(dateStart);
  const end = _convertToTime(dateEnds);
  return !_callback ? start < end : _callback(start, end);
};

/**
 * para comparar exactamente se setea el tiempo a 0 y solo tener encuenta dd/mm/yyyy
 * @param _date fecha a convertir
 */
const _convertToDate = (_date: Date, hasHours = false): number => {
  if (!_date) return null;
  let auxDate = new Date(_date);
  if (!hasHours) auxDate.setHours(0, 0, 0, 0);
  return auxDate.getTime();
};

const _addDaysToDate = (days: number, _date) => {
  if (!_date) return null;
  if (!days) days = 0;
  let fecha = _cloneDeep(_date);
  fecha = parseStringToLocaleDate(fecha);
  // Sumar días
  fecha.setDate(fecha.getDate() + days);
  return fecha;
};

const findNextDateDay = (fechaInicio, diaSemanaDeseado) => {
  try {
    if (!fechaInicio || !diaSemanaDeseado) {
      new Error('Cannot resolve findNextDateDay some params are null');
      return null;
    }
    // Obtener el día de la semana de la fecha de inicio (0 = domingo, 1 = lunes, ..., 6 = sábado)
    let diaSemanaInicio = fechaInicio.getDay();
    // diaSemanaDeseado  (1 = lunes, ..., 6 = sábado, 7 = domingo) a que tengo que emparejar a diaSemanaInicio
    diaSemanaInicio = diaSemanaInicio === 0 ? 7 : diaSemanaInicio;

    // Calcular la cantidad de días para llegar al día de la semana deseado
    let diasParaDiaSemanaDeseado = (diaSemanaDeseado - diaSemanaInicio + 7) % 7;

    // Si la fecha de inicio coincide con el día de la semana deseado, devolver la fecha de inicio
    if (diaSemanaInicio === diaSemanaDeseado) {
      return fechaInicio;
    } else {
      // Crear una nueva fecha sumando la cantidad de días para llegar al día de la semana deseado
      let fechaMasCercana = new Date(fechaInicio);
      fechaMasCercana.setDate(fechaInicio.getDate() + diasParaDiaSemanaDeseado);
      return fechaMasCercana;
    }
  } catch (e) {
    _warnProduction(e);
  }
};

/**
 * Convierte una fecha en cantidad de milisegundos, numero unico y comparable en el tiempo
 */
const _convertToTime = (time: string): number => {
  if (!time) return null;
  const _time = time ? time.split(':') : null;
  return _time ? Number(_time[0]) * 3600 + Number(_time[1]) * 60 : null;
};

function _logScale(x, max = 1) {
  if (x === 0) return x;
  if (x === max) return max;
  let _x = x / max;
  return (1 - Math.pow(10, Math.log(1 - _x))) * max;
}

function _strSplitIntegers(str) {
  if (!str || typeof str !== 'string') return str;
  return str.split(/(\d+)/).filter(Number);
}

function _strSplitStrings(str) {
  return str
    .split(/(\w+)/)
    .filter(String)
    .filter(d => /(\S)/.test(d));
}

function _strSplitUserName(str) {
  return str
    .split(/([\w-\.]+@([\w-]+\.)+[\w-]{2,4})|([\w]+)/)
    .filter(String)
    .filter(d => /(\S)/.test(d));
}

function _separateStringByDelimiters(str, fullSeparatorWithSlashes = false) {
  const strSeparator = fullSeparatorWithSlashes === true ? /\n|\,|\s|\t|\r|\||\;|\\|\// : /\n|\,|\s|\t|\r|\||\;/;
  return str.split(strSeparator).filter(d => /(\S)/.test(d));
}

const _emptyNumber = (value: number): boolean => {
  return value !== null && value !== undefined;
};

function __nuimberSort(a, b): number {
  return a - b;
}
const _sortNumber = (arr: number[]): number[] => {
  if (!Array.isArray(arr)) return arr;
  return arr.sort(__nuimberSort);
};

const _uniqueElements = (arr: any[]): any[] => {
  if (!Array.isArray(arr)) return arr;
  return [...new Set(arr)];
};

/**
 *
 * Computa un método a partir del cambio de una propiedad de una clase
 * devuleve un observable
 *
 * @param {Function} callBack
 * @param {*} [propToCheck=null]
 * @param {{
 *       timeToCheck?: number;
 *       noCheckDiffProp?: boolean;
 *       verbose?: boolean;
 *       onChangeProp?: Function;
 *       onChangeCallBackValue?: Function;
 *       avoidCompareLastValue?: boolean;
 *       manualUpdate?: boolean;
 *     }} [$options=null]
 * @memberof computedProp
 *
 * EJ: isValid$ = new _computedProp(() => this.isValid(), this.formData);
 * Html:
 *      [disabled]="!(isValid$.value$ | async)"
 */
class computedProp {
  private callBack = null;
  private propToCheck = null;

  private lastPropChange = null;
  private currentValue = null;

  private timeToCheck = null;
  private noCheckDiffProp = null;
  private verbose = null;
  private onChangeProp = null;
  private onChangeCallBackValue = null;
  private avoidCompareLastValue = null;
  private manualUpdate = null;

  private valueSubject$: Subject<any>;
  private isInProcess = false;

  constructor(
    callBack: Function,
    propToCheck: any = null,
    /**/
    $options: {
      timeToCheck?: number;
      noCheckDiffProp?: boolean;
      verbose?: boolean;
      avoidCompareLastValue?: boolean;
      manualUpdate?: boolean;
      //**/
      onChangeCallBackValue?: Function;
      onChangeProp?: Function;
    } = null
  ) {
    const options = {
      ...{
        timeToCheck: 256, //intervalo de tiempo para re-checkeo
        noCheckDiffProp: false, //Si está en true no checkea el diff de propToCheck
        verbose: false, //imprime console.log
        avoidCompareLastValue: false, //Cambia el valor del subject por más q el valor del callBack sea el mismo
        manualUpdate: false, //no checkea diff x intervalo, solo con forceUpdateVal()
      },
      ...($options !== null ? $options : {}),
    };

    this.callBack = callBack;
    this.propToCheck = propToCheck;

    this.timeToCheck = options.timeToCheck;
    this.noCheckDiffProp = options.noCheckDiffProp === true || propToCheck == null;
    this.verbose = options.verbose;
    this.onChangeProp = options.onChangeProp;
    this.onChangeCallBackValue = options.onChangeCallBackValue;
    this.avoidCompareLastValue = options.avoidCompareLastValue;
    this.manualUpdate = options.manualUpdate;

    if (this.callBack == null) {
      console.warn('no callBack', { callBack, $options, options });
    }

    if (this.noCheckDiffProp && this.onChangeProp) {
      console.warn('onChangeProp but noCheckDiffProp', { $options, options });
    }

    if (this.avoidCompareLastValue && this.onChangeCallBackValue) {
      console.warn('onChangeCallBackValue but avoidCompareLastValue', { $options, options });
    }

    this.valueSubject$ = new Subject();
    this.valueSubject$.next(this.currentValue);
  }

  private _isEqualProp(): boolean {
    const actualProp = this.propToCheck;

    const rv = _equal(this.lastPropChange, actualProp);

    if (this.verbose) console.log(['check'], rv);

    if (!rv) {
      if (this.onChangeProp) this.onChangeProp();
      this.lastPropChange = _cloneDeep(actualProp);
    }

    return rv;
  }

  private async _updateVal() {
    let currentValue = this.callBack ? await this.callBack() : null;

    if (!this.avoidCompareLastValue && !_equal(currentValue, this.currentValue)) {
      if (this.onChangeCallBackValue) this.onChangeCallBackValue(currentValue);
      this.currentValue = currentValue;
      this.valueSubject$.next(currentValue);
    }

    if (this.avoidCompareLastValue) {
      this.currentValue = currentValue;
      this.valueSubject$.next(currentValue);
    }

    if (this.verbose) console.log(['callBack'], this.currentValue);
  }

  private async _isInProcessTimeOut() {
    this.isInProcess = true;
    await _timeout(this.timeToCheck || 1);
    this.isInProcess = false;
  }

  private _checkDiff(): void {
    if (this.isInProcess) return;

    const isEqualProp = this.noCheckDiffProp ? false : this._isEqualProp();

    if (!isEqualProp) this._updateVal();

    this._isInProcessTimeOut();
  }

  public get value$(): Subject<any> {
    if (!this.manualUpdate) this._checkDiff();
    return this.valueSubject$;
  }

  public forceUpdateVal(): void {
    if (!this.manualUpdate) this._checkDiff();
    this._updateVal();
  }

  public destroy(): void {
    this.valueSubject$.complete();
  }
}

function _strToHash(str: string) {
  //https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
  let hash = 0,
    i,
    chr;

  if (str.length === 0) return hash;

  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }

  return hash;
}

function _objToHash(str: any) {
  return _strToHash(JSON.stringify(str));
}

const _updateFormData_KeyVal = (value = null, key = null, obj = null, verbose = false) => {
  if (key == null) key = value?.target?.name;
  if (!key == null) throw new Error('No key');
  value = value?.target ? value.target?.value : value;

  let newObj = _cloneDeep(obj);
  if (newObj) newObj[key] = value;

  if (verbose) console.log(['changedForm'], { key, value, newObj });

  return newObj;
};

const _updateFormData_IndexKeyValOLD = (index: number = 0, value = null, key = null, arr = null, verbose = false): any[] => {
  if (key == null) key = value?.target?.name;
  if (!key == null) throw new Error('No key');

  if (typeof arr[index] !== 'object') {
    console.error(arr[index], arr, index);
    throw new Error('No element');
  }

  value = value?.target ? value.target?.value : value;

  let newArr = _cloneDeep(arr);
  if (newArr) newArr[index][key] = value;

  if (verbose) console.log(['changedFormArr'], { key, value, newObj: newArr });

  return newArr;
};

const _updateFormData_IndexKeyVal = (index: number = 0, value = null, key = null, arr = null, verbose = false): any[] => {
  if (key == null) key = value?.target?.name;
  if (!key == null) throw new Error('No key');

  if (typeof arr[index] !== 'object') {
    console.error(arr[index], arr, index);
    throw new Error('No element');
  }

  value = value?.target ? value.target?.value : value;

  let newArr = _cloneDeep(arr);

  if (newArr != null) {
    if (key.indexOf('.') === -1) {
      newArr[index][key] = value;
    } else {
      //Si es un KEY compuesto
      let _path = key.replace(/[\[']+/g, '.').replace(/[\]']+/g, ''); //saca los corchetes que a immutable.set no le gustan
      newArr[index] = immutable.set(newArr[index], _path, value);
    }
  }

  if (verbose) console.log(['changedFormArr'], { key, value, newObj: newArr });

  return newArr;
};

const _toNumber = (num, per = false): number => {
  if (num == null) return null;
  let rv = numeral(num).value();
  return !per ? rv : rv * 100;
};

function _arrayToCSV(objArray) {
  if (objArray == null) return '';

  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

  if (!(array.length > 0)) return '';

  let str =
    `${Object.keys(array[0])
      .map(value => `"${value}"`)
      .join(',')}` + '\r\n';

  return array.reduce((str, next) => {
    str +=
      `${Object.values(next)
        .map(value => `"${value}"`)
        .join(',')}` + '\r\n';
    return str;
  }, str);
}

function _parese_and_normalize_notification_body_msg($msg, urlToDownload = null, urlToGo = null) {
  let msg = $msg;

  if (msg == null) return null;
  if (msg === '') return '';

  let obj: any = null;

  try {
    //DES-4382
    // Si llega un objeto lo parsea y lo usa
    obj = json5.parse($msg);
    if (obj?.message?.length > 0) msg = obj.message;
  } catch (e) {
    // Es solo un texto, se trata como tal
  }

  let rv = String(msg)
    .replace(/\\n/g, '\n')
    .replace(/\n\n+|\n(\s\s+)|(\s\s+)\n|\r\n|\n\r|\r|\n/g, '<br>')
    .replace(/\s\s+/g, ' ')
    .replace(/<br ?\/?>/gi, '\n')
    .trim();

  if (obj?.urlToDownload?.length > 0) urlToDownload = obj?.urlToDownload;
  //DES-4382 Si tiene 'urlToDownload' hace el wrapp de un href
  if (urlToDownload?.length > 0) {
    rv = '<a class="linkInNotification" href="' + urlToDownload + '" download>' + rv + '</a>';
  }

  if (obj?.urlToGo?.length > 0) urlToGo = obj?.urlToGo;
  //DES-4382 Si tiene 'urlToGo' hace el wrapp de un href
  if (urlToGo?.length > 0 && !(urlToDownload?.length > 0)) {
    rv = '<a class="linkInNotification linkInNotificationUrlToGo" href="' + urlToGo + '">' + rv + '</a>';
  }

  return rv;
}

function _changeLatLng($event: Event | any, latOrLong: 'latitude' | 'longitude', isOnBlur = false) {
  if (!$event?.target) return null;

  let value = String($event.target.value).trim();

  let hasNumberLength = value.length > 2;

  let valueNum = !hasNumberLength ? null : _roundDec(_toNumber($event.target.value), 6);

  let newVal = valueNum != null ? valueNum : value;

  if (!isOnBlur && !hasNumberLength) return null;

  let maxValAbsNum = latOrLong === 'latitude' ? 90 : 180;

  if (valueNum != null && Math.abs(valueNum) > maxValAbsNum) {
    if (isOnBlur) {
      valueNum %= maxValAbsNum;
      newVal = valueNum;
    } else {
      return null;
    }
  }

  return {
    value: !isOnBlur ? String(newVal) : String(newVal).slice(0, 12),
    latOrLong,
    blur: isOnBlur,
  };
}

const _getDeltaSeconds = (dateA, dateB): number | null => {
  if (dateA == null || dateB == null) return null;

  const _dateA = dateA?.getTime ? dateA.getTime() : new Date(dateA).getTime();
  const _dateB = dateB?.getTime ? dateB.getTime() : new Date(dateB).getTime();

  return Math.abs(_dateA - _dateB) / 1000;
};

const _getDatesDuration = (dateA, dateB): { diff: number; unit: string } | null => {
  const diffSecconds = _getDeltaSeconds(dateA, dateB);
  if (diffSecconds == null) return null;

  if (diffSecconds < 60) {
    return { diff: Math.round(diffSecconds) || 1, unit: 'seconds' };
  }

  if (diffSecconds < 3600) {
    const diffMinutes = Math.round(diffSecconds / 60) || 1;
    return { diff: diffMinutes, unit: 'minutes' };
  }

  if (diffSecconds < 86400) {
    const diffHours = Math.round(diffSecconds / 3600) || 1;
    return { diff: diffHours, unit: 'hours' };
  }

  const diffDays = Math.round(diffSecconds / 86400) || 1;
  return { diff: diffDays, unit: 'days' };
};

const _diffObjectFromAToB = ($objA, $objB, accObj?) => {
  if ($objA == null) return null;

  let objA = _cloneDeep($objA);
  let objB = _cloneDeep($objB);

  Object.keys(objA).forEach(key => {
    if (objA[key] == null) return null;
    if (_equal(objA[key], objB?.[key])) return null;

    //ARR
    if (Array.isArray(objA[key])) {
      if (!(objA[key].length > 0)) return null;

      objA[key].forEach(item => {
        if (item != null && (objB?.[key] == null || !objB?.[key].includes(item))) {
          if (accObj == null) accObj = {};
          accObj[key] = [...(accObj?.[key] || []), item];
        }
      });

      return accObj?.[key] || [];
    }

    //OBJ
    if (typeof objA[key] === 'object') {
      if (!(Object.keys(objA[key] || {}).length > 0)) return null;

      if (accObj == null) accObj = {};
      if (accObj[key] == null) accObj[key] = {};
      return _diffObjectFromAToB(objA[key], objB?.[key], accObj[key]);
    }

    if (accObj == null) accObj = {};
    accObj[key] = objA[key];
  });

  //Purge
  Object.keys(accObj || {}).forEach(key => {
    if (
      accObj[key] == null ||
      (typeof accObj[key] === 'object' && !(Object.keys(accObj[key]).length > 0)) ||
      (Array.isArray(objA[key]) && !(objA[key].length > 0))
    ) {
      delete accObj[key];
    }
  });

  return _cloneDeep(accObj || null);
};

const _forEachKeyDeep = ($obj, callBack?: (obj: any, key: string, value: any) => any) => {
  if ($obj == null) return $obj;

  const obj = { ...$obj };

  Object.keys(obj).forEach(key => {
    if (obj[key] != null) {
      if (Array.isArray(obj[key])) {
        obj[key] = obj[key].map(item => (item != null && typeof item === 'object' ? _forEachKeyDeep(item, callBack) : item));
      } else if (typeof obj[key] === 'object') {
        _forEachKeyDeep(obj[key], callBack);
      }
    }

    callBack?.(obj, key, obj[key]);
  });

  return obj;
};

function _camelCaseToSnakeCase(str: string) {
  if (str == null) return str;

  let rv = str.replace(/[A-Z]/g, c => '_' + c.toLowerCase());

  if (rv[0] === '_') rv = rv.substring(1);

  return rv;
}

function _modeArray($arr) {
  if (!($arr?.length > 0)) return null;

  let arr = [...$arr];

  return arr.sort((a, b) => arr.filter(v => v === a).length - arr.filter(v => v === b).length).pop();
}

function _filterNullValues(obj: object, recursive = false): object {
  if (obj == null) return obj;

  if (!recursive) {
    const filteredEntries = Object.entries(obj).filter(([key, value]) => value !== null);
    return Object.fromEntries(filteredEntries);
  }

  return Object.keys(obj).reduce((acc, key) => {
    if (obj[key] !== null) {
      if (typeof obj[key] === 'object') {
        return { ...acc, [key]: _filterNullValues(obj[key], true) };
      }
      return { ...acc, [key]: obj[key] };
    }
    return acc;
  }, {});
}

const _auxArrOfNumbersOrIdsToNumbers = (arr: any[], key: string = 'id') => {
  return (arr || [])
    .map(el => {
      if (el == null) return null;
      if (typeof el === 'number') return el;
      if (typeof el === 'string') return _toNumber(el);

      if (el[key] == null) return null;
      if (typeof el[key] === 'number') return el[key];
      if (typeof el[key] === 'string') return _toNumber(el[key]);

      return null;
    })
    .filter(e => e != null);
};

const _getAvoidCacheQueryString = (): string => {
  const avoid_cache_query_string = '?' + new Date().getTime();
  return avoid_cache_query_string;
};

const _normaliceArrayToCompare = (arr: any[]): any[] => {
  if (!Array.isArray(arr)) return arr;
  if (!(arr?.length > 0)) return arr;

  let rv = [...new Set(arr)].sort();

  return rv;
};

function _areOverlapping(
  A: { from?: number; to?: number },
  B: { from?: number; to?: number },
  keyFrom: string = 'from',
  keyTo: string = 'to'
) {
  if (A[keyFrom] == null || A[keyTo] == null || B[keyFrom] == null || B[keyTo] == null) {
    return false;
  }

  const _A_from = _toNumber(A[keyFrom]);
  const _A_to = _toNumber(A[keyTo]);
  const _B_from = _toNumber(B[keyFrom]);
  const _B_to = _toNumber(B[keyTo]);

  if (_B_from < _A_from) {
    return _B_to > _A_from;
  } else {
    return _B_from < _A_to;
  }
}

function _checkOverlapRanges(ranges: { from: number; to: number }[]): number {
  let rv = -1;

  (ranges || []).forEach((rangeA, i) => {
    if (rv === -1) {
      ranges.forEach((rangeB, j) => {
        if (rv === -1 && i !== j) {
          if (_areOverlapping(rangeA, rangeB) === true) rv = i;
        }
      });
    }
  });

  return rv;
}

const _levenshteinDistance = ($s, $t) => {
  if (!$s.length) return $t.length;
  if (!$t.length) return $s.length;

  let s = _normaliceString($s);
  let t = _normaliceString($t);

  const arr = [];

  for (let i = 0; i <= t.length; i++) {
    arr[i] = [i];
    for (let j = 1; j <= s.length; j++) {
      arr[i][j] = i === 0 ? j : Math.min(arr[i - 1][j] + 1, arr[i][j - 1] + 1, arr[i - 1][j - 1] + (s[j - 1] === t[i - 1] ? 0 : 1));
    }
  }

  return arr[t.length][s.length];
};

const _downloadURL = (data, fileName = null) => {
  if (data == null || document == null) return null;

  let a: any = document.createElement('a');
  a.href = data;

  if (true) a.target = '_blank';
  if (fileName != null) a.download = fileName;

  document.body.appendChild(a);
  a.style = 'display: none';

  if (true) _log(['_downloadURL'], a);

  a.click();
  a.remove();
};

const _downloadURLAlt = (url, fileName = null, target = '_blank') => {
  if (url == null || document == null) return null;

  let a: any = document.createElement('a');
  a.href = url;

  if (target != null) a.target = target;
  if (fileName != null) a.download = fileName;

  document.body.appendChild(a);
  a.style = 'display: none';

  if (true) _log(['_downloadURLAlt'], a);

  a.click(true);

  setTimeout(() => a.remove(), 100);
};

const _downloadURLasync = (url, fileName = null, target = '_blank') => {
  if (true) _log(['_downloadURLasync'], url);
  location.href = url;
};

let _calcDateOfYearToPer = ($date = null) => {
  let date = new Date($date);
  let startDate = new Date('2024/1/1');
  let endDate = new Date('2024/12/12');

  date.setFullYear(2024);
  startDate.setFullYear(2024);
  endDate.setFullYear(2024);

  let rv = (startDate.valueOf() - date.valueOf()) / (startDate.valueOf() - endDate.valueOf());

  return Math.min(Math.max(rv, 0), 1);
};

function _dateDiffInDays($a, $b) {
  let a = new Date($a);
  let b = new Date($b);

  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.abs(Math.floor((utc2 - utc1) / _MS_PER_DAY));
}

function _createUnicId() {
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return array[0].toString(16);
}

function _capitalizeFirstLetter(string: String) {
  if (string == null || string === '') return string;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function _lowerFirstLetter(string: String) {
  if (string == null || string === '') return string;
  return string.charAt(0).toLowerCase() + string.slice(1);
}

function _copyListAsExcelRows(list: any[]) {
  const items = list.join('\n'); // Usamos \n para que cada ID esté en una fila nueva en Excel
  const el = document.createElement('textarea');
  el.value = items;
  document.body.appendChild(el);
  el.select();
  if (!navigator.clipboard) {
    // use old commandExec() way
    document.execCommand('copy');
  } else {
    navigator.clipboard.writeText(el.value);
  }
  document.body.removeChild(el);
}

function _traverseObject(object: any, callback, aplyToSubProps: boolean = false) {
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      const value = object[key];
      callback(key, value);

      if (typeof value === 'object' && value !== null && aplyToSubProps) {
        _traverseObject(value, callback, aplyToSubProps); // Recursive call for subobjects
      }
    }
  }
}

/*
 Recorre un objeto buscando en las ramas arrays que se puedan ordenar, útil para hacer comparaciones (Deja intacto el objeto original)
 lessStrict = true trata strings/numeros como strings y (undefined/null/objetos y arrays vacíos) como null
*/
function _normalizeObjectToCompare(obj, lessStrict = false) {
  if (obj == null || typeof obj !== 'object') return lessStrict ? (obj != null ? String(obj) : null) : obj;

  if (Array.isArray(obj)) {
    const normalizedArray = obj
      .map(item => _normalizeObjectToCompare(item, lessStrict))
      .filter(item => (lessStrict ? item != null : true))
      .sort((a, b) => JSON.stringify(a).localeCompare(JSON.stringify(b)));

    return lessStrict && normalizedArray.length === 0 ? null : normalizedArray;
  }

  const normalizedObject = Object.keys(obj)
    .sort()
    .reduce((acc, key) => {
      const normalizedValue = _normalizeObjectToCompare(obj[key], lessStrict);
      if (!lessStrict || normalizedValue != null) acc[key] = normalizedValue;
      return acc;
    }, {});

  return lessStrict && Object.keys(normalizedObject).length === 0 ? null : normalizedObject;
}

export {
  _addDaysToDate,
  _areOverlapping,
  _array2plainId,
  _arrayMoveEl,
  _arrayToCSV,
  _arrDifference,
  _arrFlatten,
  _arrIntersection,
  _arrIntersectionBy,
  _arrIntersectionWith,
  _auxArrOfNumbersOrIdsToNumbers,
  _auxStrToKeys,
  _calcDateOfYearToPer,
  _camelCaseToSnakeCase,
  _capitalizeFirstLetter,
  _castArray,
  changeHue as _changeHue,
  _changeLatLng,
  _checkOverlapRanges,
  _cloneDeep,
  _colorToRGBA,
  _colorToRGBInterpolate,
  _compareNormaliceStrings,
  _compareTwoDates,
  _compareTwoTimes,
  computedProp as _computedProp,
  _containNormaliceStrings,
  _containValuesInGroups,
  _convertDecimalToPerRound,
  _convertoDecimalToPercent,
  _convertPercentToDecimal,
  _convertToDate,
  _convertToTime,
  _copyListAsExcelRows,
  _createUnicId,
  _dateDiffInDays,
  _dateTimeJSONFormatAtLocalTimeZone,
  _dateUtcToLocal,
  debounce as _debounce,
  _debounceDecorator,
  _decimalLenght,
  _diffObjectFromAToB,
  _downloadURL,
  _downloadURLAlt,
  _downloadURLasync,
  _emptyNumber,
  _equal,
  _equalArrays,
  _extendObjDepp,
  _filterLookupArrayKey,
  _filterLookupMultipleProps,
  _filterNonUniqueBy,
  _filterNullValues,
  findNextDateDay as _findNextDateDay,
  _forceType,
  _forEachKeyDeep,
  _formatCurrency,
  _formatCurrencyDec,
  _formatDecimals,
  _formatPercent,
  _get,
  _getAvoidCacheQueryString,
  _getDatesDuration,
  _getDaysBetweenDates,
  _getDeltaSeconds,
  _getElementById,
  _getFormatDate,
  _getGeoDistance,
  _getNameSpace,
  _getNewNumberId,
  _getTextColorFromBackColor,
  _getType,
  _handleErrorWithDummyData,
  _hasNumber,
  _hasValue,
  immutable as _immutable,
  _insertArrayItemAtPos,
  _is,
  _isArray,
  _isBase64,
  _isEmpty,
  _isEmptyObject,
  _isFormDirtyByquerySelector,
  _isFormValidByquerySelector,
  _isFunction,
  _isLocalHost,
  _isNumberStr,
  _krange,
  _levenshteinDistance,
  _lGet /* lodash.get / es como _get pero permite usar indíces de arrays y funciones (pero más lento)*/,
  _logScale,
  _lowerFirstLetter,
  _mem,
  _memNormaliceString,
  _modeArray,
  _MomentToDate,
  _ms,
  _normaliceArrayToCompare,
  _normaliceString,
  _normalizeObjectToCompare,
  _noVal,
  _NULL_FUNCT,
  _numberStr2Number,
  numeral as _numeral,
  _objFlipKeyVal,
  _objToHash,
  _orderBy,
  _parese_and_normalize_notification_body_msg,
  _parseLocalDateFromString,
  _patchNgXsSatateWithSet,
  _pick,
  _prettyDateEs,
  _prettyDateEsMem,
  _printObj,
  _randomInteger,
  _removeUnderscores,
  _renameKeysPrefix,
  _reset_getNewNumberId,
  _rgbToHex,
  _rgbToHexInterpolate,
  _roundDec,
  _safeResult,
  _scoreBetweenStrings,
  _scoreBetweenStringsWithSpaces,
  _separateStringByDelimiters,
  _set,
  _setElementAttributes,
  _setElementStyles,
  _setInputValue,
  _setScrollTopPageContent,
  _setTextFilterValue,
  _shuffle,
  _shuffleArr,
  _sortArrayAlpha,
  _sortArrayTreeAlpha,
  _sortAscendentByName,
  _sortNumber,
  _sortParentId,
  _StrCapitalize,
  _strSplitIntegers,
  _strSplitStrings,
  _strSplitUserName,
  _strToHash,
  _subCatContainsLookUp,
  _sumBy,
  _throttleDecorator,
  _throwDummyData,
  _throwError,
  _timeout,
  _toFixed,
  _toggleArrayItem,
  _toNumber,
  _traverseObject,
  _uniqueElements,
  _uniqueElementsByKey,
  _updateFormData_IndexKeyVal,
  _updateFormData_KeyVal,
};
