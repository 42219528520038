import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { _cloneDeep, _equal } from '@shared/aux_helper_functions';
import {
  addColumnToAllGroups,
  dropColumnOnAllGroups,
  dropHiddenColumns,
  selectColumnConfig,
  selectColumnName,
} from '../../prisma-table-parent.component';
import { checkDinamicColumn, ColumnsType } from '../../prisma-table.model';

@Component({
  selector: 'prisma-table-user-configure-column',
  template: `
    <div class="spinner-container" *ngIf="isLoading === true">
      <custom-loader></custom-loader>
    </div>
    <div fxLayout="column" fxFlex="calc(100%+0px)">
      <div class="list-drag">
        <div *ngIf="isLoading === false" fxLayout="column" fxFlex="calc(100%+0px)" class="mb-24">
          <div cdkDropList [cdkDropListData]="columns" class="example-list" (cdkDropListDropped)="drop($event, columns)">
            <ng-container *ngFor="let _column of columns; let i = index">
              <div
                class="example-box"
                *ngIf="_column.column !== 'select'"
                cdkDrag
                [cdkDragDisabled]="!_column.isEditable && disabledActive"
              >
                <span fxFlex="90" style="min-width: min-width: 90%;">
                  {{ _column.headerTitle || _column.column }}
                </span>
                <mat-icon class="disabled-icon" fxFlex="5">drag_indicator</mat-icon>
                <mat-checkbox
                  (click)="toggleShowColumn(_column)"
                  [checked]="_column.isChecked"
                  toolTip="PRISMA_TABLE.SHOW_COLUMN | pTranslate"
                >
                </mat-checkbox>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <br />

      <div *ngIf="isLoading === false" fxLayout="row" class="mt-24" fxLayoutAlign="end start">
        <button mat-button color="secondary" (click)="exit()">
          {{ 'GENERIC_CANCEL' | pTranslate }}
        </button>
        <button mat-button mat-flat-button color="accent" class="mr-24" (click)="resetChanges()">
          {{ 'GENERIC_RESET' | pTranslate }} <mat-icon>refresh</mat-icon>
        </button>
        <button mat-button mat-flat-button color="accent" [disabled]="disabledApplyButton()" (click)="applyChanges()">
          {{ 'GENERIC_SAVE' | pTranslate }}
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .container-column-selection li {
        list-style-type: none;
        margin-bottom: 10px;
      }

      .list-drag {
        max-height: 480px;
        overflow-y: auto;
        margin: 0;
        padding: 0;
        border: none;
        display: block !important;
      }

      .example-list {
        width: 500px;
        max-width: 100%;
        border: solid 1px var(--generic-row-border-color);
        min-height: 60px;
        display: block;
        background: white;
        border-radius: 4px;
        overflow: hidden;
      }

      .example-box {
        padding: 10px 10px;
        border-bottom: solid 1px var(--generic-row-border-color);
        color: rgba(0, 0, 0, 0.87);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        cursor: move;
        background: var(--card-color);
        font-size: 14px;
      }

      body.theme-dark :host .example-box {
        color: rgba(255, 255, 255, 0.87);
      }

      body.theme-dark :host .example-list {
        border: solid 1px var(--generic-row-border-color);
        background: transparent;
      }

      .example-box.cdk-drag-disabled {
        background: var(--card-color);
        opacity: 0.6;
        cursor: no-drop;
      }

      .example-box.cdk-drag-disabled .disabled-icon {
        color: gray;
      }

      .cdk-drag-preview {
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
      }

      .cdk-drag-placeholder {
        opacity: 0;
      }

      .cdk-drag-animating {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }

      .example-box:last-child {
        border: none;
      }

      .example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
      }
    `,
  ],
})
export class PrismaTableUserConfigureColumnComponent implements OnInit, OnChanges {
  @Input('columns') _columns: Array<ColumnsType>;
  @Input('disabledDragsActive') disabledDragsActive = false;
  config: Array<ColumnsType>;
  originalColumnsConfig: Array<ColumnsType>;
  originalColumnsConfigWithOutSelect: Array<ColumnsType>;
  columnsConfig: Array<ColumnsType>;
  columns: Array<checkDinamicColumn>;
  columnsOriginal: Array<checkDinamicColumn>;
  hiddenColumnsOriginal = [selectColumnName, 'actions'];
  hiddenColumns = [];
  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<PrismaTableUserConfigureColumnComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogRef.disableClose = true;
    if (this.data?.config) {
      this.hiddenColumns =
        this.data?.hiddenColumns?.length > 0 ? [...this.hiddenColumns, ...this.data.hiddenColumns] : this.hiddenColumnsOriginal;
      this.initDisabledDrags(this.data?.disabledDragsActive);
      this.config = [...this.data.config];
      this.initColumns(this.config);
    }
  }

  initDisabledDrags(disabledDragsActive) {
    this.disabledDragsActive = disabledDragsActive || false;
  }

  initColumns(config) {
    this.originalColumnsConfig = _cloneDeep(config);
    /* elimino de todos los grupso de columnas, la columna select. */
    this.columnsConfig = dropHiddenColumns(_cloneDeep(this.originalColumnsConfig), this.hiddenColumns);
    this.columnsConfig = dropHiddenColumns(this.columnsConfig);
    this.originalColumnsConfigWithOutSelect = this.columnsConfig;
    /* aplano los grupos de columnas por columnas */
    this.columns = this.columnsConfig.flatMap(c => c.type);
    this.columnsOriginal = this.columns;
    this.isLoading = false;
  }

  disabledApplyButton() {
    return _equal(this.columnsConfig, this.originalColumnsConfigWithOutSelect);
  }
  resetChanges(): void {
    this.dialogRef.close(RESET_PRISMA_TABLE_CONFIG);
  }
  applyChanges(): void {
    /* agrego la columna select en los grupos de configuracion que corresponda, comparando con el original */
    const _hiddenColumns = this.hiddenColumns.filter(x => !this.hiddenColumnsOriginal.some(c => c === x));
    if (_hiddenColumns.length > 0) {
      _hiddenColumns.forEach(x => {
        this.columnsConfig = addColumnToAllGroups(this.columnsConfig, x, this.originalColumnsConfig);
      });
    }
    this.columnsConfig = addColumnToAllGroups(this.columnsConfig, selectColumnConfig, this.originalColumnsConfig);
    this.columnsConfig = addColumnToAllGroups(this.columnsConfig, 'actions', this.originalColumnsConfig, true);
    this.dialogRef.close(this.columnsConfig);
  }
  exit(): void {
    this.dialogRef.close(CANCEL_PRISMA_TABLE_CONFIG);
  }

  isLoading = true;

  ngOnInit(): void {}
  ngOnChanges(changes) {
    if (changes['columns']) {
      this.initColumns(changes.columns);
    }
    if (changes['disabledDragsActive']) {
      this.initColumns(changes.disabledDragsActive);
    }
  }

  toggleShowColumn(element) {
    let elementIndex = this.columns.findIndex(c => c.column === element.column);
    const togleShowElement = { ...element, isChecked: !element.isChecked };
    if (elementIndex !== -1) {
      this.columns.splice(elementIndex, 1, togleShowElement);
      this.columnsConfig = [{ ...this.originalColumnsConfig[0], type: this.columns }];
    }
  }

  drop(event: CdkDragDrop<string[]>, list = null) {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
    this.columnsConfig = [{ ...this.originalColumnsConfig[0], type: list }];
  }
}

export const RESET_PRISMA_TABLE_CONFIG = 'reset';
export const CANCEL_PRISMA_TABLE_CONFIG = 'cancel';
