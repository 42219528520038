import { environment } from './../../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenericConfirmationModalComponent } from '@prisma/components/generic-confirmation-modal/generic-confirmation-modal.component';
import { DevSettingsService } from 'core/services/dev-settings.service';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { getShowPermissionName } from 'app/app.config';
import json5 from 'json5';

@Component({
  selector: 'global-dev-settings-casuistry',
  template: `
    <ng-container>
      <mat-selection-list [multiple]="false">
        <mat-list-option (click)="emulateEndPointError()" [value]="null"> Emular Falla de endPoint </mat-list-option>
        <mat-list-option (click)="forceLogs()" [value]="null"> Forzar Logs </mat-list-option>
        <mat-list-option [routerLink]="['/test']" [value]="null"> Screen Tests </mat-list-option>
        <mat-list-option (click)="showPermissionTooltip()" [value]="null"> Ver permisos requeridos en pantalla </mat-list-option>
      </mat-selection-list>
    </ng-container>
  `,
  styles: [
    `
      :host ::ng-deep .mat-list-item-content {
        padding: 0px !important;
      }

      :host ::ng-deep .mat-list-base {
        padding-top: 0px !important;
      }

      :host ::ng-deep .mat-list-option {
        background: transparent !important;
      }
    `,
  ],
})
export class GlobalSettingsCasuistryComponent implements OnInit {
  lang = null as any;

  constructor(private translate: IanTranslateService, private devSettingsService: DevSettingsService, public dialog: MatDialog) {
    this.setLangs();
  }

  async ngOnInit() {}

  setLangs() {
    if (this.lang != null) return;

    this.lang = {};
  }

  clipboardWriteText(text) {
    (navigator as any).clipboard?.writeText?.(text);
  }

  async promptConfirmation() {
    const dialogRef = this.dialog.open(GenericConfirmationModalComponent, {
      width: '550px',
      data: {
        message: 'Para cambiar este seteo hace falta reiniciar la aplicación',
        title: 'Aviso',
        labelConfirm: 'Reiniciar',
        labelCancel: 'Cancelar',
        disableClose: true,
        timeToConfirm: 720,
      },
    });

    return dialogRef.afterClosed().toPromise();
  }

  emulateEndPointError() {
    this.devSettingsService.__emulateEndPointError();
  }

  async forceLogs() {
    let confirm = await this.promptConfirmation();
    if (!confirm) return;
    (window as any)?.__enableVerbose?.();
    (window as any)?.location?.reload?.();
  }

  showPermissionTooltip() {
    const value = getShowPermissionName();
    const _keyVer = environment.localStorageKeyVerPrefix || '_v3_';
    localStorage.setItem(_keyVer + 'showPermissionName', json5.stringify(!value));
    (window as any)?.location?.reload?.();
  }
}
