import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { _timeout } from '@shared/aux_helper_functions';

export interface Locale {
  lang: string;
  data: Object;
}

@Injectable({
  providedIn: 'root',
})
export class FuseTranslationLoaderService {
  /**
   * Constructor
   *
   * @param {TranslateService} _translateService
   */
  constructor(private _translateService: TranslateService, private router: Router) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Load translations
   *
   * @param {Locale} args
   */
  loadTranslations(...args: Array<Locale>): void {
    const locales = [...args];

    locales.forEach(locale => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this._translateService.setTranslation(locale.lang, locale.data, true);
    });
  }

  auxReloadCurrentRoute() {
    if (!this.router) return;

    let currentUrl = this.router.url;
    if (!currentUrl) return;

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(async () => {
      await _timeout(1);
      this.router.navigate([currentUrl]);
    });
  }
}
