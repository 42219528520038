import { NgModule } from '@angular/core';

import { NgxsModule } from '@ngxs/store';
import { VerticalLayout1Module } from 'app/layout/vertical/layout-1/layout-1.module';
import { NotificationsState } from './components/notifications-panel/notifications-panel.state';

// 2019-01-31 - Se sacan los modulos que no se van a utilizar.
// import { VerticalLayout2Module } from 'app/layout/vertical/layout-2/layout-2.module';
// import { VerticalLayout3Module } from 'app/layout/vertical/layout-3/layout-3.module';

// import { HorizontalLayout1Module } from 'app/layout/horizontal/layout-1/layout-1.module';

@NgModule({
  imports: [
    VerticalLayout1Module,
    NgxsModule.forFeature([NotificationsState]),
    // 2019-01-31 - Se sacan los modulos que no se van a utilizar.
    // VerticalLayout2Module,
    // VerticalLayout3Module,
    // HorizontalLayout1Module
  ],
  exports: [
    VerticalLayout1Module,
    // 2019-01-31 - Se sacan los modulos que no se van a utilizar.
    // VerticalLayout2Module,
    // VerticalLayout3Module,
    // HorizontalLayout1Module
  ],
  declarations: [],
})
export class LayoutModule {}
