import { Component, HostListener, OnInit } from '@angular/core';
import { _isDev, _isFeDev, _isLab, _isStaging, _log } from '@shared/aux_helper_environment';
import { ConsoleLogger } from 'core/services/console_logger.service';
import { DevSettingsService } from 'core/services/dev-settings.service';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';

@Component({
  selector: 'global-dev-settings',
  template: `
    <ng-container *ngIf="canUserDevSettings && true">
      <br />
      <mat-divider></mat-divider>
      <h4 class="color-green mt-16">
        <b> Dev Config:</b>
      </h4>
      <mat-accordion multi="false">
        <!-- //FEATURE FLAGS -->
        <mat-expansion-panel expanded="true">
          <mat-expansion-panel-header>
            <mat-panel-title> <b class="color-green">Feature Flags</b> </mat-panel-title>
          </mat-expansion-panel-header>
          <global-dev-settings-feature-flags></global-dev-settings-feature-flags>
        </mat-expansion-panel>

        <mat-expansion-panel expanded="false" *ngIf="_isFeDev">
          <mat-expansion-panel-header>
            <mat-panel-title> <b class="color-green">Brand Customization</b> </mat-panel-title>
          </mat-expansion-panel-header>
          <global-dev-settings-brand-customization> </global-dev-settings-brand-customization>
        </mat-expansion-panel>

        <mat-expansion-panel expanded="false" *ngIf="_isFeDev">
          <mat-expansion-panel-header>
            <mat-panel-title> <b class="color-green">Local Storage</b> </mat-panel-title>
          </mat-expansion-panel-header>
          <global-dev-settings-local-storage> </global-dev-settings-local-storage>
        </mat-expansion-panel>

        <mat-expansion-panel expanded="false" *ngIf="_isFeDev">
          <mat-expansion-panel-header>
            <mat-panel-title> <b class="color-green">Extras</b> </mat-panel-title>
          </mat-expansion-panel-header>
          <global-dev-settings-casuistry> </global-dev-settings-casuistry>
        </mat-expansion-panel>
      </mat-accordion>

      <div *ngIf="true">
        <button mat-button class="mt-16" (click)="downloadConsoleLog(Infinity)">
          <mat-icon class="small-icons">download</mat-icon>
          {{ 'COMP.ERROR_MESSAGE.DOWNLOAD_LOG' | pTranslate }}
        </button>
      </div>
    </ng-container>
  `,
  styles: [
    `
      :host ::ng-deep .mat-list-item-content {
        padding: 0px !important;
      }

      :host ::ng-deep .mat-list-base {
        padding-top: 0px !important;
      }
    `,
  ],
})
export class GlobalSettingsDevSettingsComponent implements OnInit {
  lang = null as any;
  featureFlagsValues = null;
  _isFeDev = _isFeDev();

  constructor(
    private translate: IanTranslateService,
    private devSettingsService: DevSettingsService,
    private consoleLogger: ConsoleLogger
  ) {}

  ngOnInit(): void {
    this.setLangs();
  }

  get canUserDevSettings() {
    return this.devSettingsService?.__canShowDevSettings;
  }

  devHotKeyBuffer = '';
  @HostListener('document:keydown', ['$event'])
  devHotKey(ev) {
    let _ctrlKey = ev?.ctrlKey || ev?.metaKey;

    //Si no es admin y no es ambietente bajo aborta
    if (!this.devSettingsService?.__canUseDevMode?.()) return;

    //CTRL + ALT + D
    if (ev.code === 'KeyD' && (_ctrlKey || ev.altKey)) {
      ev.preventDefault();
      ev.stopPropagation();
      this.devSettingsService?.__setCanShowDevSettings?.(true);
      return;
    }

    this.devHotKeyBuffer = this.devHotKeyBuffer.slice(-10) + ev?.key || '';

    //dev
    if (this.devHotKeyBuffer.indexOf('dev') > -1) {
      this.devSettingsService?.__setCanShowDevSettings?.(true);
      return;
    }

    //iddqd
    if (this.devHotKeyBuffer.indexOf('iddqd') > -1) {
      this.devSettingsService?.__setCanShowDevSettings?.(true);
      this._isFeDev = true;
    }
  }

  setLangs() {
    if (this.lang != null) return;
    this.lang = {};
  }

  get canDownloadErrorLog() {
    const consoleLogIniteid = this.consoleLogger?.isInitied === true;

    return consoleLogIniteid;
  }

  downloadConsoleLog(size?) {
    if (!this.canDownloadErrorLog) return null;

    return this.consoleLogger?.downloadConsoleLog?.(size);
  }
}
