import { ScrollDispatcher } from '@angular/cdk/overlay';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { _isDev, _log, _warn } from '@shared/aux_helper_environment';
import {
  _cloneDeep,
  _debounceDecorator,
  _equal,
  _getNewNumberId,
  _noVal,
  _timeout,
  _uniqueElements,
  _uniqueElementsByKey,
} from '@shared/aux_helper_functions';
import { IanTranslateService } from 'core/services/ian-core-singleton.service';
import { first } from 'rxjs/operators';
import { GenericTagsSelectV2Service } from './generic-tag-select-v2.service';
import { GenericTagsSelectMultiplev2AttributeModal } from './generic-tags-select-multiple-v2-attribute.modal';
import { GenericTagsSelectMultipleV2Component } from './generic-tags-select-multiple-v2.component';
import {
  TagTreeSelectedModel,
  TagTreeSelectedModelInternal,
  TagTreeWithRootSelectedModal,
  _aux_filterTagsRootExistByRootId,
  _aux_getTagItemObjFromId,
  _aux_getTagRootFromId,
  _aux_tagTreeGroupFormat,
} from './generic-tags-select-v2.component';

@Component({
  selector: 'generic-tags-select-multiple-included-excluded-v2',
  template: `
    <ng-container class="w-100-p">
      <ng-container *ngIf="groupTags == null && showLoader">
        <div class="spinner-container-mini">
          <custom-loader></custom-loader>
        </div>
      </ng-container>
      <div fxLayout="column" class="w-100-p">
        <div
          [class]="deafaultParentClass"
          fxLayout="row"
          fxFlex="100"
          [ngClass]="{
            'generic-tags-select-multiple-root': !useAsAdvancedFilter,
            'generic-tags-select-multiple-advanced-filter-root': useAsAdvancedFilter
          }"
        >
          <div fxLayout="row" fxFlex="100" fxLayoutGap="32px">
            <div fxLayout="column" class="w-100-p">
              <div *ngFor="let tag of groupTags; let i = index; trackBy: trackByRootTagId">
                <generic-tags-select-v2
                  fxLayout="row"
                  fxFlex="calc(100%+0px)"
                  *ngIf="tag?.rootTagId != null"
                  [tagsModuleType]="tagsModuleType"
                  [rootTagId]="tag.rootTagId"
                  [required]="visibleTagsRootsRequired?.length && visibleTagsRootsRequired.includes(tag.rootTagId)"
                  [levels]="configLevelsSize"
                  [level1Config]="configLevel1 || configLevels"
                  [level2Config]="configLevel2 || configLevels"
                  [level3Config]="configLevel3 || configLevels"
                  [level4Config]="configLevel4 || configLevels"
                  [verbose]="verbose"
                  [autoFillParentsOnStart]="autoFillParentsOnStart"
                  [selected]="tag.selected || []"
                  [disabled]="tag?.disabled || false"
                  [placeholder]="i == 0 ? placeholder : null"
                  [prefixPlaceholder]="prefixPlaceholder"
                  [postfixPlaceholder]="postfixPlaceholder"
                  (onChange)="changeSelected($event, i, false)"
                  [width]="'w-100-p'"
                  [autoSelectRequiredTagByDefaultTagValue]="autoSelectRequiredTagByDefaultTagValue"
                >
                </generic-tags-select-v2>
              </div>
            </div>
            <div fxLayout="column" class="w-100-p">
              <div *ngFor="let tagExcluded of groupTagsExcluded; let i = index; trackBy: trackByRootTagId">
                <generic-tags-select-v2
                  fxLayout="row"
                  fxFlex="calc(100%+0px)"
                  *ngIf="tagExcluded?.rootTagId != null"
                  [tagsModuleType]="tagsModuleType"
                  [rootTagId]="tagExcluded.rootTagId"
                  [required]="visibleTagsRootsRequired?.length && visibleTagsRootsRequired.includes(tagExcluded.rootTagId)"
                  [levels]="configLevelsSize"
                  [level1Config]="configLevel1 || configLevels"
                  [level2Config]="configLevel2 || configLevels"
                  [level3Config]="configLevel3 || configLevels"
                  [level4Config]="configLevel4 || configLevels"
                  [verbose]="verbose"
                  [autoFillParentsOnStart]="autoFillParentsOnStart"
                  [selected]="tagExcluded.selected || []"
                  [disabled]="tagExcluded?.disabled || false"
                  [placeholder]="i == 0 ? placeholder : null"
                  [prefixPlaceholder]="prefixPlaceholderExcluded"
                  [postfixPlaceholder]="postfixPlaceholderExcluded"
                  (onChange)="changeSelected($event, i, true)"
                  [width]="'w-100-p'"
                >
                </generic-tags-select-v2>
              </div>
            </div>
          </div>
        </div>

        <div fxLayout="row" class="mt-20 mb-20" *ngIf="groupTags != null && (addMoreItemsButton || useAsAdvancedFilter)">
          <div fxFlex fxLayoutAlign="end start">
            <button
              mat-icon-button
              color="accent"
              matTooltip="{{ 'ADMIN.MASTER.STORES.ADD_ATRIBUTE_BTN' | pTranslate }}"
              (click)="openNewtagDialog()"
              data-test-id="add_tags"
              [disabled]="configLevels?.disabled == true || noMoreTagsToSelect()"
            >
              <span class="material-icons font-size-30">add_circle_outline</span>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .generic-tags-select-v2 > .generic-tag-select-span-wrapper {
        width: 100%;
      }
      .generic-tags-select-multiple-root {
        display: flex;
        flex-wrap: wrap;
        row-gap: 24px;
      }

      .generic-tags-select-multiple-advanced-filter-root {
        display: flex;
        flex-wrap: wrap;
      }

      .generic-tags-select-multiple-celd {
        flex: 1 1 calc(100%);
        max-width: calc(100%);
      }

      @media (min-width: 960px) {
        .generic-tags-select-multiple-root {
          column-gap: 32px;
        }

        .generic-tags-select-multiple-root.flex-33,
        .generic-tags-select-multiple-root.flex-50 {
          row-gap: 32px;
        }

        ::ng-deep .generic-tags-select-multiple-root.flex-33 .generic-lookup,
        ::ng-deep .generic-tags-select-multiple-root.flex-50 .generic-lookup {
          max-height: 56px;
        }

        .generic-tags-select-multiple-root.flex-33 > .generic-tags-select-multiple-celd {
          flex: 1 1 calc(33% - 32px);
          max-width: calc(33% - 32px);
        }
        .generic-tags-select-multiple-root.flex-33 > .generic-tags-select-multiple-celd:nth-child(3n) {
          flex: 1 1 33%;
          max-width: 33%;
        }

        .generic-tags-select-multiple-root.flex-50 > .generic-tags-select-multiple-celd {
          flex: 1 1 calc(50% - 32px);
          max-width: calc(50% - 32px);
        }
        .generic-tags-select-multiple-root.flex-50 > .generic-tags-select-multiple-celd:nth-child(2n) {
          flex: 1 1 50%;
          max-width: 50%;
        }
      }
    `,
  ],
})
export class GenericTagsSelectMultipleIncludedExcludedV2Component extends GenericTagsSelectMultipleV2Component {
  @Input('prefixPlaceholderExcluded') prefixPlaceholderExcluded = '';
  @Input('postfixPlaceholderExcluded') postfixPlaceholderExcluded = this.translate.instant('COMP.ADD_ATTRIBUTE.EXCLUDED');
  @Output() onChangeExcluded: EventEmitter<TagTreeSelectedModel[]> = new EventEmitter();
  @Output() onChangeGroupExcluded: EventEmitter<TagTreeWithRootSelectedModal[]> = new EventEmitter();
  @Input('selectedExcludedTags') selectedTagsExcluded: TagTreeSelectedModel[] = null;
  @Input('showSelected') showSelected = true;
  @Input('autoSelectRequiredTagByDefaultTagValue') autoSelectRequiredTagByDefaultTagValue = false; //PREC-221

  groupTagsExcluded: TagTreeSelectedModelInternal[] = null;
  userVisibleTagsRoots = [];
  /**/
  constructor(
    protected cd: ChangeDetectorRef,
    readonly sd: ScrollDispatcher,
    public translate: IanTranslateService,
    protected service: GenericTagsSelectV2Service,
    protected dialog: MatDialog
  ) {
    super(cd, sd, translate, service, dialog);
  }

  ngOnInit(): void {
    this.service
      .geTagsTree(this.tagsModuleType)
      .pipe(first())
      .subscribe(async data => {
        this.tagTree = data;

        this.allFirstLevelTags =
          this.tagTree?.length && this.tagTree[0]?.values?.length
            ? this.tagTree[0].values.reduce((acc, element) => {
                if (element?.parentId === 0 && !acc.includes(element.tagId)) acc.push(element.tagId);
                return acc;
              }, [])
            : [];

        this.creatGroupTagsFromImputs();
        this.creatGroupTagsFromImputs(true);
        this.isLoaded.emit(true);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.tagTree && (changes.visibleTagsRoots || changes.selectedTags)) this.creatGroupTagsFromImputs();
    if (changes.selectedTags && false) _log('[GenericTagsSelectMultipleV2Component]', this.selectedTags);
    if (this.cd) this.cd.detectChanges();
  }

  creatGroupTagsFromImputs(excluded = false) {
    if (this.tagTree == null) return;

    if (false) _log(_aux_getTagRootFromId(13, this.tagTree));

    const list = excluded ? this.selectedTagsExcluded : this.selectedTags;

    let rv = this.showSelected
      ? list.map(t => {
          return {
            rootTagId: _aux_getTagRootFromId(t.id, this.tagTree),
            selected: null,
          };
        })
      : [];

    //Agrega todos los roots visibles (mandatorios)
    let allMergeTags;
    let visibleTagsRoots = this.getFlatvisibleTagsRoots();
    allMergeTags = _uniqueElements([...visibleTagsRoots, ...(this.userVisibleTagsRoots || [])]);
    allMergeTags.forEach(tagRoot => {
      const rootGroup = rv.find(tag => tag.rootTagId === tagRoot);

      if (!rootGroup) {
        rv.push({ rootTagId: tagRoot, selected: [] });
      }
    });
    rv = _uniqueElementsByKey(rv as any, 'rootTagId');

    //Convert TagTreeSelectedModel[] 2 TagTreeSelectedModelInternal[]
    list.forEach(tag => {
      const rootId = tag?.id ? _aux_getTagRootFromId(tag.id, this.tagTree) : null;
      if (!rootId) return;

      let newEl = null;
      let rootGroup = rv.find(tag => tag.rootTagId === rootId);

      if (!rootGroup) {
        newEl = { rootTagId: rootId, selected: [] };
        rootGroup = newEl;
      }

      rootGroup.selected = [...(rootGroup.selected || []), { id: tag.id }];
      rootGroup.selected = _uniqueElementsByKey(rootGroup.selected, 'id');

      if (newEl) rv.push(newEl);
    });

    rv = _uniqueElementsByKey(rv as any, 'rootTagId');

    const rvFiltered = _aux_filterTagsRootExistByRootId(rv, this.tagTree);

    if (excluded) {
      this.groupTagsExcluded = rvFiltered;
      this.groupTags = rvFiltered.map(t => {
        return { ...t, selected: [], disabled: t.selected.length > 0 };
      });
    } else {
      this.groupTags = rvFiltered;
      this.groupTagsExcluded = rvFiltered.map(t => {
        return { ...t, selected: [], disabled: t.selected.length > 0 };
      });
    }
  }

  changeSelected(ev: TagTreeSelectedModel[], key, excluded = false) {
    if (!excluded) {
      if (this.groupTags && this.groupTags[key]) {
        this.groupTags[key].selected = ev;
        if (this.groupTagsExcluded[key]) this.groupTagsExcluded[key].disabled = ev?.length > 0;
        this._convertAndemitSelecteds(excluded);
      }
    } else {
      if (this.groupTagsExcluded && this.groupTagsExcluded[key]) {
        this.groupTagsExcluded[key].selected = ev;
        if (this.groupTags[key]) this.groupTags[key].disabled = ev?.length > 0;
        this._convertAndemitSelecteds(excluded);
      }
    }
  }

  _convertAndemitSelecteds(excluded) {
    let rv_plainIds: TagTreeSelectedModel[] = [];

    const group = excluded ? this.groupTagsExcluded : this.groupTags;

    //Convert TagTreeSelectedModelInternal[] 2 TagTreeSelectedModel[]
    (group || []).forEach(tag => {
      (tag?.selected || []).forEach($sel => {
        if ($sel?.id == null) return;

        let sel = $sel;
        if (sel.value == null) {
          sel = _cloneDeep($sel);
          let val = _aux_getTagItemObjFromId([sel.id], this.tagTree);
          if (val && val[0]) sel.value = val[0].value;
        }

        rv_plainIds = [...rv_plainIds, sel];
      });
    });

    rv_plainIds = _uniqueElementsByKey(rv_plainIds as any, 'id');

    if (_equal(rv_plainIds, group)) return;

    if (excluded) {
      this.onChangeExcluded.emit(rv_plainIds);
      const rv_group = _aux_tagTreeGroupFormat(group);
      this.onChangeGroupExcluded.emit(rv_group);
    } else {
      this.onChange.emit(rv_plainIds);
      const rv_group = _aux_tagTreeGroupFormat(group);
      this.onChangeGroup.emit(rv_group);
    }
  }

  openNewtagDialog() {
    this.dialog
      .open(GenericTagsSelectMultiplev2AttributeModal, {
        width: '550px',
        data: {
          tagsModuleType: this.tagsModuleType,
          alreadyAdded: this.getFlatvisibleTagsRoots(),
          selected: this.userVisibleTagsRoots,
        },
      })
      .afterClosed()
      .pipe(first())
      .subscribe(data => {
        if (true) _log('AttributeModal data:', data);
        this.userVisibleTagsRoots = data;
        this.creatGroupTagsFromImputs();
        this.creatGroupTagsFromImputs(true);
      });
  }
}
