<div [class.fixedTable]="fixedTableClass" [hidden]="!((isLoading != true) && dataSource?.data?.length > 0) ">
  <table
    mat-table
    class="table-responsive responsiveContent UXv2_tableRoot"
    multiTemplateDataRows
    [dataSource]="dataSource"
    [ngStyle]="styleClass"
    [class.stickyHeader]="stickyHeader"
    (scroll)="stickyHeader ? onScrollPrismaTable($event) : null"
  >
    <!-- COLUMNA DESPLEGABLE DE SUB ROWS - la que tiene el icono de desplegable  -->
    <ng-container *ngIf="attrSubRows">
      <ng-container matColumnDef="collapseDetail">
        <mat-header-cell
          *matHeaderCellDef
          class="pl-0"
          PrismaTableFlexColumn
          [PrismaTableMinWidth]="{colSpan: _colSpanDropDown, minCellWidth: _minCellWidth }"
          [PrismaTableFlex]="_colSpanDropDown"
        ></mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          class="pl-8 pr-8"
          PrismaTableFlexColumn
          [PrismaTableMinWidth]="{colSpan: _colSpanDropDown, minCellWidth: _minCellWidth }"
          [PrismaTableFlex]="_colSpanDropDown"
          fxLayoutAlign="center center"
        >
          <ng-container *ngIf="stateOfCollapseRow(element)">
            <span
              class="iconRowClosed"
              [ngClass]="element.disabledDropDown ? 'prisma_disabled' : 'clickeable'"
              *ngIf="!element.expanded && stateOfCollapseRow(element)"
              (click)="toggleExtand(element); $event.stopPropagation()"
            >
              <mat-icon>keyboard_arrow_right</mat-icon>
            </span>
            <span
              class="iconRowExpanded"
              [ngClass]="element.disabledDropDown ? 'prisma_disabled' : 'clickeable'"
              *ngIf="element.expanded && stateOfCollapseRow(element)"
              (click)="toggleExtand(element); $event.stopPropagation()"
            >
              <mat-icon>keyboard_arrow_down</mat-icon>
            </span>
          </ng-container>
        </mat-cell>
      </ng-container>
    </ng-container>

    <!-- CUSTON COLUMNS FOR ACTIONS/CUSTOMS PROPOSES -->
    <ng-content></ng-content>
    <!-- AGRUPADO DE COLUMNAS -->

    <!-- DINAMIC GROUP COLUMNS HEADER -->
    <!--           [ngStyle]="{'min-width': _group.colFlex * _minCellWidth + 'px'}"
              [style.flex]="_group.colFlex" -->
    <ng-container *ngIf="groupedColumns">
      <ng-container *ngFor="let _group of headerValuesGroup; let i = index;">
        <ng-container matColumnDef="{{ _group.name }}">
          <mat-header-cell
            *matHeaderCellDef
            fxLayoutAlign="center center"
            [ngClass]="{'_gris' : isNotDefault(_group)}"
            PrismaTableFlexColumn
            [PrismaTableMinWidth]="{colSpan: _group.colFlex, minCellWidth: _minCellWidth }"
            [PrismaTableFlex]="_group.colFlex"
            class="header-cell-special header-paretoTotal pb-0"
          >
            <b
              *ngIf="isNotDefault(_group)"
              [ngClass]="{'titleNoWrap': noWrapTitle}"
              [matTooltip]="noWrapTitle ? _group.name : null"
            >
              {{_group.name | uppercase}}
            </b>
          </mat-header-cell>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- DINAMIC COLUMNS HEADER -->
    <!-- TODO: CSS utilizado en pareto definir si se usa [ngClass]="{'_col_un _gris minVal align-center' : groupedColumns}" -->
    <!--           [ngStyle]="{'min-width': getColSpan(_column) * _minCellWidth + 'px'}"
              [style.flex]="getColSpan(_column)" -->
    <ng-container *ngFor="let _group of headerValues">
      <ng-container *ngFor="let _column of _group.type; let i = index;">
        <ng-container matColumnDef="{{_column.column}}">
          <mat-header-cell
            PrismaTableFlexColumn
            [PrismaTableMinWidth]="{colSpan: getColSpan(_column), minCellWidth: _minCellWidth }"
            [PrismaTableFlex]="getColSpan(_column)"
            class="{{_column.class}}"
            *matHeaderCellDef
            [fxLayoutAlign]="_column.fxLayoutAlign ? _column.fxLayoutAlign : 'start center'"
            [matTooltip]="!noWrapTitle ? _column.headerToolTip : null"
          >
            <span
              *ngIf="!_column.headerTitleLink"
              [ngClass]="{'titleNoWrap': noWrapTitle}"
              [matTooltip]="noWrapTitle ? _column.headerToolTip ? _column.headerToolTip : _column.headerTitle : null"
            >
              {{_column.headerTitle || ''}}
            </span>
            <span
              *ngIf="_column.headerTitleLink"
              [ngClass]="{'titleNoWrap': noWrapTitle}"
              [matTooltip]="noWrapTitle ? _column.headerToolTip ? _column.headerToolTip : _column.headerTitle : null"
            >
              <a class="clickeable" (click)="goTo(_column.headerTitleLink)">{{_column.headerTitle || ''}}</a>
            </span>
          </mat-header-cell>

          <!-- DINAMIC COLUMNS ROWS -->
          <!-- TODO: CSS utilizado en pareto [ngClass]="{'_col_un minVal align-center' : groupedColumns}" -->
          <!--           [ngStyle]="{'min-width': getColSpan(_column) * _minCellWidth + 'px'}"
                    [style.flex]="getColSpan(_column)" -->
          <mat-cell
            PrismaTableFlexColumn
            [PrismaTableMinWidth]="{colSpan: getColSpan(_column), minCellWidth: _minCellWidth }"
            [PrismaTableFlex]="getColSpan(_column)"
            class="{{_column.class}}"
            *matCellDef="let _element;"
            [fxLayoutAlign]="_column.fxLayoutAlign ? _column.fxLayoutAlign : 'start center'"
            [matTooltip]="_column.cellToolTip ? _column.cellToolTip : _column.customCellTitleToolTip ? _column.customCellTitleToolTip(_element) : null"
            [style.background]="!!_column?.backgroundColor ? _column?.backgroundColor(_element) || null : null"
            [ngStyle]="!!_column?.customStylesContent ? _column.customStylesContent(_element) || null : null"
          >
            <div *ngIf="!!_column.chips" fxLayoutAlign="start center" fxLayout="column">
              <div [fxLayoutAlign]="_column.fxLayoutAlign ? _column.fxLayoutAlign : 'start center'" class="w-100-p">
                <prisma-table-column-content
                  [element]="_element"
                  [configColumn]="_column"
                  [rowWithHover]="rowWithHover"
                ></prisma-table-column-content>
              </div>
              <chips-prisma-table class="w-100-p" [column]="_column" [element]="_element"></chips-prisma-table>
            </div>
            <prisma-table-column-content
              *ngIf="!_column.chips"
              [element]="_element"
              [configColumn]="_column"
              [rowWithHover]="rowWithHover"
            ></prisma-table-column-content>
          </mat-cell>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- DISEÑO DE COLUMNAS -->
    <!--       [ngStyle]="{'min-width': (totalColSpan * _minCellWidth ) + 'px', 'width': _fixedTableWidth}" -->
    <ng-container *ngIf="groupedColumns">
      <mat-header-row
        *matHeaderRowDef="displayedColumnsGroup"
        PrismaTableFlexColumn
        [PrismaTableMinWidth]="{colSpan: totalColSpan, minCellWidth: _minCellWidth }"
        [style.width]="_fixedTableWidth"
      ></mat-header-row>
    </ng-container>

    <!-- [ngStyle]="{'min-width': (totalColSpan * _minCellWidth ) + 'px', 'width': _fixedTableWidth }" -->
    <mat-header-row
      *matHeaderRowDef="displayedColumns"
      PrismaTableFlexColumn
      [PrismaTableMinWidth]="{colSpan: totalColSpan, minCellWidth: _minCellWidth }"
      [style.width]="_fixedTableWidth"
    ></mat-header-row>

    <!-- [ngStyle]="{'min-width': ( totalColSpan * _minCellWidth ) + 'px', 'width': _fixedTableWidth}" -->
    <mat-row
      [ngClass]="{'mat-row-highlight': _clickeable, 'mat-row-highlight-no-pointer': !_clickeable, 'table-without-checkbox':_clickeable  }"
      *matRowDef="let row; columns: displayedColumns; let irow = dataIndex;"
      [attr.data-test-id]="'prisma_table_index_row_'+irow"
      PrismaTableFlexColumn
      [PrismaTableMinWidth]="{colSpan: totalColSpan, minCellWidth: _minCellWidth }"
      [style.width]="_fixedTableWidth"
      [style.display]="_hiddenMainRow(row)"
      (click)="openRow(row)"
      (mouseenter)="onMouseEnter(row)"
      (mouseleave)="onMouseLeave(row)"
    ></mat-row>
  </table>
  <!-- PAGINADO  -->
  <mat-paginator
    *ngIf="paginationConfig && dataSource?.data?.length > 0 && disabledPagination !== true"
    class="clickeable-on-edit"
    [length]="(length | async)"
    [pageSize]="pageSize"
    [pageIndex]="pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="onPageEvent($event)"
    showFirstLastButtons="true"
    [attr.data-test-length]="(length | async)"
  ></mat-paginator>
</div>

<div class="spinner-container" *ngIf="isLoading === true">
  <custom-loader></custom-loader>
</div>

<ian-no-items-found
  [textToShow]="(notFoundItemText | pTranslate)"
  [hidden]="dataSource?.data?.length !== 0 || isLoading === true"
></ian-no-items-found>
