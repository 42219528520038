import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { IanTranslateService } from 'core/services/ian-core-singleton.service';

// Link: https://stackoverflow.com/a/46883528
// Link: https://stackoverflow.com/a/49532776
@Injectable()
export class MatPaginatorIntlEs extends MatPaginatorIntl {
  constructor(private translate: IanTranslateService) {
    super();
  }

  itemsPerPageLabel = this.translate.instant('MAT_PAGINATOR.QUANTITY');
  nextPageLabel = this.translate.instant('MAT_PAGINATOR.NEXT');
  previousPageLabel = this.translate.instant('MAT_PAGINATOR.PREVIUS');

  firstPageLabel = this.translate.instant('MAT_PAGINATOR.FIRST');

  lastPageLabel = this.translate.instant('MAT_PAGINATOR.LAST');

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return '0 de ' + length;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
  };
}
