<h1 mat-dialog-title>
  <mat-icon class="warning-color">warning</mat-icon>
  {{'SERVICES.CAN_DEACTIVATE.TITLE_MODAL' | pTranslate}}
</h1>
<div mat-dialog-content fxLayout="column" class="mb-20">{{'SERVICES.CAN_DEACTIVATE.SUBTITLE_MODAL' | pTranslate}}</div>
<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-button [mat-dialog-close]="true" (deactivate)="true" data-test-id="goToList">
    {{'SERVICES.CAN_DEACTIVATE.GO_OUT_BTN' | pTranslate}}
  </button>
  <button mat-button [mat-dialog-close]="false" (deactivate)="false" cdkFocusInitial color="accent">
    {{'SERVICES.CAN_DEACTIVATE.FOLLOW_BTN' | pTranslate}}
  </button>
</div>
