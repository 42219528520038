import { Component, HostBinding, Input } from '@angular/core';
import { _log } from '@shared/aux_helper_environment';

@Component({
  selector: 'fuse-nav-horizontal-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class FuseNavHorizontalItemComponent {
  @HostBinding('class')
  classes = 'nav-item';

  @Input()
  item: any;

  clickItem(item) {
    _log('\n\n[clickItem]', item?.id);
  }
}
