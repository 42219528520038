<h1 mat-dialog-title>
  <mat-icon>check_circle_outline</mat-icon>
  &nbsp; {{ title | pTranslate }}
</h1>
<div mat-dialog-content>
  <p>{{ subtitle | pTranslate }}</p>
</div>
<div mat-dialog-actions fxLayoutAlign="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial color="accent" data-test-id="operation-success-btn">
    {{ 'COMP.OPERATION_SUCCESS.ACCEPT_BTN' | pTranslate }}
  </button>
</div>
