import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';

import { transitions } from '@prisma/animations/animations';
import { Subject } from 'rxjs';
import { _cloneDeep, _debounceDecorator, _isFormValidByquerySelector, _timeout } from '@shared/aux_helper_functions';
import { _log } from '@shared/aux_helper_environment';

@Component({
  selector: 'pick-color',
  templateUrl: './pick-color.component.html',
  animations: [transitions],
})
export class PickColorComponent implements OnInit, OnDestroy {
  @Input('color') color: string = null;
  @Input('colorsBlock') colors = [
    '#FF323E',
    '#FF7832',
    '#FEF566',
    '#E0FF66',
    '#F4FFA0',
    '#3A1A41',
    '#AC4EC0',
    '#84C1FF',
    '#d6ffff',
    '#441429',
    '#8F3C41',
    '#7772E4',
    '#F6D2ED',
    '#1CA274',
    '#71AB51',
    '#87C155',
    '#A1D1B1',
    '#C9DFA8',
  ];
  @Input('data-test-id') dataTestId = null;
  // colores muy claros, no tienen contraste con el fondo blanco
  //'#F8EFED',
  //'#F9FFF9',
  @Output('colorSelected') colorSelected = new EventEmitter<string>();
  _hex = DEFAULT_PICK_COLOR_COMPONENT;
  advanced = false;
  constructor() {}

  ngOnInit() {}

  setColor() {
    this.color = this._hex;
    this.colorSelected.emit(this.color);
  }

  reset() {
    this.color = DEFAULT_PICK_COLOR_COMPONENT;
    this._hex = DEFAULT_PICK_COLOR_COMPONENT;
    this.colorSelected.emit(this.color);
  }

  public $unsuscribreAll: Subject<void> = new Subject<void>();

  ngOnDestroy(): void {
    this.$unsuscribreAll.next();
    this.$unsuscribreAll.complete();
  }
}

export const DEFAULT_PICK_COLOR_COMPONENT = '#000000';
export const BANNED_PICK_COLOR_COMPONENT_V1 = '#FFFFFF';
export const BANNED_PICK_COLOR_COMPONENT_V2 = '#FFF';
