import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { kayshFlushOperator, kayshOperator } from '@karacas/kaysh';
import { GenericCategorySelectionMiniV2Service } from '@prisma/components/generic-category-selection-mini-v2/generic-category-selection-mini-v2.service';
import { _isDev, _isFeDev, _isLab, _log, _logTap, _useDummyData } from '@shared/aux_helper_environment';
import {
  _array2plainId,
  _cloneDeep,
  _equal,
  _get,
  _handleErrorWithDummyData,
  _mem,
  _ms,
  _normaliceString,
  _throwError,
} from '@shared/aux_helper_functions';
import {
  auditFakeData,
  commentFakeData,
  getTimeLineFake,
  OBJ_DATA_DUMMY,
  _getShufleSpcDummyPops,
  _getShufleSpcDummyProds,
} from 'app/spaces/data_dummy';
import { PagedList } from 'core/models/paged-list.model';
import { DevSettingsService, FeatureFlagsKeysEnum } from 'core/services/dev-settings.service';
import { environment } from 'environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { dummyPlanogramsList } from '../planogram-list/store/dummyPlanogramsList';
import { PlanogramsListFilterModel, PlanogramsListItemModel, PlanogramStatusType } from '../planogram-list/store/planograms-list.model';
import {
  _auxTransformInputListPopData,
  _auxTransformInputListProdsData,
  _auxTransformInputPlanogramData,
  _auxTransformOutputPlanogramData,
  _auxTransValidTimeLine,
} from '../static_common/spaces_aux_data_transform';
import {
  DEFAULT_PRD_LIST_PAGE_SIZE,
  FORCE_DUMMY_POP_LIST,
  FORCE_DUMMY_PROD_LIST,
  _auxReduceProdsStoresInfo,
  _clonePlanogram,
} from '../static_common/spaces_aux_helpers';
import { PlanogramModel } from './store/planogram.interfaces';

const IS_DEMO = _useDummyData();

const _getPlanogramDummy = (featureFlagScope): Observable<any> => {
  const originalObjData = _cloneDeep(OBJ_DATA_DUMMY());
  const objData = _auxTransformInputPlanogramData(originalObjData, featureFlagScope);
  return new Observable(observer => {
    setTimeout(() => {
      if (true && _isDev()) _log('\n[__getPlanogramDummy]', objData, '\n\n');
      observer.next(objData);
    }, 500);
  });
};

const _getPlanogramTimeLineDummy = ($id = 0, featureFlagScope): Observable<any> => {
  let originalObjData = (_cloneDeep(getTimeLineFake()) || []).map(pog => {
    return { ..._auxTransformInputPlanogramData(pog, featureFlagScope), id: $id };
  });
  return new Observable(observer => {
    setTimeout(() => {
      observer.next(originalObjData);
    }, 500);
  });
};

const _getPlanogramAudit = ($id = 0): Observable<any> => {
  let originalObjData = _cloneDeep(auditFakeData);
  return new Observable(observer => {
    setTimeout(() => {
      observer.next(originalObjData);
    }, 500);
  });
};

const _getPlanogramComments = ($id = 0): Observable<any> => {
  let originalObjData = _cloneDeep(commentFakeData);
  return new Observable(observer => {
    setTimeout(() => {
      observer.next(originalObjData);
    }, 500);
  });
};

export const _getSpcDummyListProds = (timeOut = 100): Observable<any> => {
  const originalObjData = _getShufleSpcDummyProds(false).map(obj => {
    return obj.itemData;
  });

  return new Observable(observer => {
    setTimeout(() => {
      if (true && _isDev()) _log('\n[_getSpcDummyListProds]', originalObjData, '\n');

      let prods = [...originalObjData, ...originalObjData, ...originalObjData].slice(0, DEFAULT_PRD_LIST_PAGE_SIZE);

      //DEBUG DES-1381
      //if (true) prods = [casePrd_DES_1381];
      //if (true) prods = [casePrd_DES_1381, ...originalObjData];

      //DEBUG DES-2021
      if (false && _isDev()) {
        prods[0].storesInfo = [];
      }

      const rv = {
        items: _auxReduceProdsStoresInfo(_clonePlanogram(prods), false, null),
        totalItems: DEFAULT_PRD_LIST_PAGE_SIZE * 3,
      };

      observer.next(rv);
    }, timeOut);
  });
};

export const _getSpcDummyListPop = (timeOut = 100): Observable<any> => {
  const originalObjData = _getShufleSpcDummyPops(false).map(obj => {
    return obj;
  });

  return new Observable(observer => {
    setTimeout(() => {
      if (true && _isDev()) _log('\n[_getSpcDummyListPop]', originalObjData, '\n');
      const rv = {
        items: [...originalObjData].slice(0, DEFAULT_PRD_LIST_PAGE_SIZE),
        totalItems: DEFAULT_PRD_LIST_PAGE_SIZE,
      };

      rv.items = rv.items.map((el, i) => {
        return { ...el, id: el.id + '___' + i };
      });

      observer.next(rv);
    }, timeOut);
  });
};

@Injectable({
  providedIn: 'root',
})
export class PlanogramService {
  private configBase = _get(environment, 'apiBaseUrl_spaces');
  private config = _get(environment, 'spaces.planograms.api');

  private spacesPermissions = null;

  DES_4510_SPC_FEATURES_PARA_POC_SODIMAC = this.configFlags.hasFeatureFlag(FeatureFlagsKeysEnum['DES-4510__SPC-FEATURES-PARA-POC-SODIMAC']);

  constructor(
    private http: HttpClient,
    private permissionService: NgxPermissionsService,
    private serviceCategory: GenericCategorySelectionMiniV2Service,
    private configFlags: DevSettingsService
  ) {
    const tmpPrem = this.permissionService?.getPermissions() || {};

    this.spacesPermissions = {
      spaces_planograms: !!tmpPrem.spaces_planograms,
      spaces_planograms_save: !!tmpPrem.spaces_planograms_save,
      spaces_planograms_approve: !!tmpPrem.spaces_planograms_approve, //
      spaces_planograms_clone: !!tmpPrem.spaces_planograms_clone, //
      spaces_planograms_inform: !!tmpPrem.spaces_planograms_inform, //
      spaces_planograms_messaging: !!tmpPrem.spaces_planograms_messaging, //
      spaces_planograms_pop_material: !!tmpPrem.spaces_planograms_pop_material, //
      spaces_planograms_readonly: !!tmpPrem.spaces_planograms_readonly, //
      spaces_planograms_request_approval: !!tmpPrem.spaces_planograms_request_approval, //
      spaces_planograms_upload_audit_photo: !!tmpPrem.spaces_planograms_upload_audit_photo, //
      spaces_planograms_pop_material_readonly: !!tmpPrem.spaces_planograms_pop_material_readonly, //TODO: Ver este q es largo y no hay docu
    };

    if (true) _log('[spacesPermissions]', this.spacesPermissions);
  }

  checkSpacesPlanogramsPermissions(val: string) {
    const isDev = true && (_isDev() || _isLab());

    const _isFETestPermissions = false && isDev;
    if (_isFETestPermissions) return false;

    if (isDev) return true;

    if (!this.spacesPermissions) return false;
    return !!this.spacesPermissions[val];
  }

  getMenuProdList($filters = {}, $defaultfilters = {}, planogramId = null, forceNoFilterCats = false): Observable<any> {
    if (true && this.DES_4510_SPC_FEATURES_PARA_POC_SODIMAC) {
      return this.getMenuProdListWithMultipleQuery($filters, $defaultfilters, planogramId, forceNoFilterCats);
    }

    const url = `${this.configBase}${this.config.resources.getMenuProdList}`;

    if ((planogramId === -1 && IS_DEMO) || (IS_DEMO && FORCE_DUMMY_PROD_LIST)) return _getSpcDummyListProds(258);

    //Unifica filtros
    let filters: any = _cloneDeep(
      Object.assign(
        {
          query: '',
          /**/
          active: false,
          inactive: false,
          newItems: false,
          noExhibition: false,
          exhibition: false,
          /**/
          isBlockedForSales: false,
          isNotBlockedForSales: false,
          /**/
          categories: [],
          subCategories: [],
          selectedTags: {},
          pageIndex: 0,
          pageSize: DEFAULT_PRD_LIST_PAGE_SIZE,
          planogramId: planogramId || 0,
        },
        $defaultfilters,
        $filters
      )
    );

    if (filters.exhibition === false && filters.noExhibition === false) {
      filters.exhibition = null;
      filters.noExhibition = null;
    }

    if (_equal(filters.selectedTags, {})) {
      filters.selectedTags = null;
    }

    const lowLevel = this.serviceCategory.getLowLevel({
      level4: filters?.categories || [],
      level5: filters?.subCategories || [],
    });

    //Si forceNoFilterCats está prendido no filtra categorías
    filters.categories = !forceNoFilterCats ? _array2plainId(lowLevel?.level4 || []) || [] : [];
    filters.subCategories = !forceNoFilterCats ? _array2plainId(lowLevel?.level5 || []) || [] : [];

    filters.tags = _cloneDeep(filters.selectedTags) || null;

    //BE no maneja includedValues vacios
    if (filters.tags) filters.tags = (filters.tags || []).filter(tag => (tag.includedValues || []).length && tag.tagId);
    if (!filters.tags?.length) filters.tags = null; //Si tags está vacio va como null para q no de error

    if (filters.vendorsAsyncList) filters.manufacturer = filters.vendorsAsyncList.map(el => el.id);
    if (filters.brandsAsyncList) filters.brands = filters.brandsAsyncList;

    delete filters.selectedTags;
    delete filters.skus;
    delete filters.vendorsAsyncList;
    delete filters.brandsAsyncList;

    //CAT-588: Fuerza a que categories y subCategories no vayan nunca en null
    if (true) {
      if (filters.categories == null) filters.categories = [];
      if (filters.subCategories == null) filters.subCategories = [];
    }

    if (true) _log('\n[getMenuProdList]', { filters }, '\n');

    const _getList = (url, filters) => {
      return this.http.post<any>(url, filters).pipe(
        tap(data => _logTap(`\n${PlanogramService.name}::get getMenuProdList (tap) \t data: %o`, data, '\n')),
        map(_auxTransformInputListProdsData),
        kayshOperator('getMenuProdList', { url, filters }, { maxTime: _ms('20m') }), //CAT-588
        catchError(error => {
          if (IS_DEMO) {
            return _getSpcDummyListProds(258);
          } else {
            _throwError(error, PlanogramService);
          }
        })
      );
    };

    return _getList(url, filters);
  }

  masiveActions(filters: any, ids: any) {
    let url = `${this.configBase}${this.config.resources.masiveActions}`;

    const _data = { filter: filters, includedIds: ids };

    return this.http.put<any>(url, _data).pipe(
      tap(data => _logTap(`${PlanogramService.name}::masiveActions (tap)\n\tdata: %o`, { data })),
      catchError(error => _throwError(error, PlanogramService))
    );
  }

  getMenuProdListWithMultipleQuery(
    $filters: any = {},
    $defaultfilters = {},
    planogramId = null,
    forceNoFilterCats = false
  ): Observable<any> {
    const url = `${this.configBase}${this.config.resources.getMenuProdList}`;

    if ((planogramId === -1 && IS_DEMO) || (IS_DEMO && FORCE_DUMMY_PROD_LIST)) return _getSpcDummyListProds(258);

    let query = $filters.query || '';
    let queryArr = query.replaceAll(',', ' ').replaceAll(';', ' ').replace(/\s/g, ' ').trim();
    let queryArrIds = queryArr.split(' ').map(_normaliceString);
    let hasQueryArrIds = queryArrIds?.length > 2;
    if (hasQueryArrIds) forceNoFilterCats = true;

    //Unifica filtros
    let filters: any = _cloneDeep(
      Object.assign(
        {
          query: '',
          /**/
          active: false,
          inactive: false,
          newItems: false,
          noExhibition: false,
          exhibition: false,
          /**/
          isBlockedForSales: false,
          isNotBlockedForSales: false,
          /**/
          categories: [],
          subCategories: [],
          selectedTags: {},
          pageIndex: 0,
          pageSize: hasQueryArrIds ? 500 : DEFAULT_PRD_LIST_PAGE_SIZE,
          planogramId: planogramId || 0,
        },
        $defaultfilters,
        hasQueryArrIds ? {} : $filters
      )
    );

    if (_equal(filters.selectedTags, {})) {
      filters.selectedTags = null;
    }

    const lowLevel = this.serviceCategory.getLowLevel({
      level4: filters?.categories || [],
      level5: filters?.subCategories || [],
    });

    //Si forceNoFilterCats está prendido no filtra categorías
    filters.categories = !forceNoFilterCats ? _array2plainId(lowLevel?.level4 || []) || [] : [];
    filters.subCategories = !forceNoFilterCats ? _array2plainId(lowLevel?.level5 || []) || [] : [];

    filters.tags = _cloneDeep(filters.selectedTags) || null;

    //BE no maneja includedValues vacios
    if (filters.tags) filters.tags = (filters.tags || []).filter(tag => (tag.includedValues || []).length && tag.tagId);
    if (!filters.tags?.length) filters.tags = null; //Si tags está vacio va como null para q no de error

    if (filters.vendorsAsyncList) filters.manufacturer = filters.vendorsAsyncList.map(el => el.id);
    if (filters.brandsAsyncList) filters.brands = filters.brandsAsyncList;

    delete filters.selectedTags;
    delete filters.skus;
    delete filters.vendorsAsyncList;
    delete filters.brandsAsyncList;

    if (true) _log('\n[getMenuProdListWithMultipleQuery]', { filters }, '\n');

    const _getList = (url, filters) => {
      return this.http.post<any>(url, filters).pipe(
        tap(data => _logTap(`\n${PlanogramService.name}::get getMenuProdListWithMultipleQuery (tap) \t data: %o`, data, '\n')),
        map(_auxTransformInputListProdsData),
        map(data => {
          if (!hasQueryArrIds) return data;

          let rv = data;

          //DES-4510
          //6886884 6886868 7161557
          rv.items = rv.items.filter(el => {
            return (
              el?.code &&
              (queryArrIds.includes(String(_normaliceString(el?.code)).trim()) ||
                queryArrIds.includes(String(_normaliceString(el?.ean)).trim()))
            );
          });

          return rv;
        }),
        catchError(error => {
          if (IS_DEMO) {
            return _getSpcDummyListProds(258);
          } else {
            _throwError(error, PlanogramService);
          }
        })
      );
    };

    const _getListMem = _mem(_getList);
    const _whitCache = false;
    return _whitCache ? _getListMem(url, filters) : _getList(url, filters);
  }

  _cache_checkInternalCodeAlreadyExist = {};
  checkInternalCodeAlreadyExist($code: string, type?: any): Observable<boolean> {
    let code = String($code).trim();
    if (!code?.length) return of(false);

    let cache = this._cache_checkInternalCodeAlreadyExist[code];
    if (cache != null) return of(cache);

    const qs: Array<string> = ['?'];
    qs.push(`code=${code}`);
    if (type) {
      qs.push(`type=${type}`);
    }

    const url = `${this.configBase}${this.config.resources.exist}${qs.join('&')}`;

    return of(true).pipe(
      delay(128 * 3),
      switchMap(data => this.http.get<boolean>(url)),
      tap(data => _logTap(`${PlanogramService.name}::checkInternalCodeNotTaken (tap)\n\tdata: %o`, data)),
      tap(data => (this._cache_checkInternalCodeAlreadyExist[code] = data)),
      catchError(error => _throwError(error, PlanogramService))
    );
  }
  reset_cache_checkInternalCodeAlreadyExist() {
    this._cache_checkInternalCodeAlreadyExist = {};
  }

  getMenuPopList($filters = {}, $defaultfilters = {}, planogramId = null, isPopMaterialEspecial = false): Observable<any> {
    const url = `${this.configBase}${this.config.resources.getMenuPopList}`;

    //Unifica filtros
    let filters: any = _cloneDeep(
      Object.assign(
        {
          query: '',
          /**/
          active: false,
          inactive: false,
          noExhibition: false,
          exhibition: false,
          /**/
          isBlockedForSales: false,
          isNotBlockedForSales: false,
          /**/
          pageIndex: 0,
          pageSize: DEFAULT_PRD_LIST_PAGE_SIZE,
          planogramId: planogramId || 0,
        },
        $defaultfilters,
        $filters
      )
    );

    delete filters.inactive;
    delete filters.active;
    delete filters.vendorsAsyncList;
    delete filters.brandsAsyncList;
    delete filters.categories;
    delete filters.subCategories;
    delete filters.selectedTags;
    delete filters.skus;
    delete filters.isPopMaterialEspecial;

    if (true) _log('\n[getMenuPopList]', { filters, isPopMaterialEspecial }, '\n');

    if ((planogramId === -1 && IS_DEMO) || (IS_DEMO && FORCE_DUMMY_POP_LIST)) return _getSpcDummyListPop(258);

    // MATARIAL POP ESPECIAL // DES-4993
    if (isPopMaterialEspecial) {
      return this.getListPopMaterialEspecial(filters.pageIndex, 10000).pipe(
        tap(data => _logTap(`\n${PlanogramService.name}::get getMenuPopList (tap) \t data: %o`, data, '\n')),
        map(_auxTransformInputListPopData),
        catchError(error => {
          if (IS_DEMO) {
            return _getSpcDummyListPop(258);
          } else {
            _throwError(error, PlanogramService);
          }
        })
      );
    }

    return this.http.post<any>(url, filters).pipe(
      tap(data => _logTap(`\n${PlanogramService.name}::get getMenuPopList (tap) \t data: %o`, data, '\n')),
      map(_auxTransformInputListPopData),
      catchError(error => {
        if (IS_DEMO) {
          return _getSpcDummyListPop(258);
        } else {
          _throwError(error, PlanogramService);
        }
      })
    );
  }

  getPlanogram(id: number): Observable<any> {
    const url = `${this.configBase}${this.config.resources.get}${id}`;
    const featureFlagScope = !!this.configFlags.hasFeatureFlag(FeatureFlagsKeysEnum['DES-2998__PLANOGRAMAS_ITEM_SCOPE']);
    //DUMMY
    if ((_isDev() || IS_DEMO) && id === -1) return _getPlanogramDummy(featureFlagScope);

    return this.http.get<any>(url).pipe(
      tap(data => _logTap(`${PlanogramService.name}::get Planogram (tap)\tdata: %o`, data)),
      map(data => _auxTransformInputPlanogramData(data, featureFlagScope)),
      catchError(error => {
        if (IS_DEMO) {
          //DUMMY
          return _getPlanogramDummy(featureFlagScope);
        } else {
          catchError(error => _throwError(error, PlanogramService));
        }
      })
    );
  }

  savePlanogram($planogram: PlanogramModel, calcData: any): Observable<any> {
    const featureFlagScope = !!this.configFlags.hasFeatureFlag(FeatureFlagsKeysEnum['DES-2998__PLANOGRAMAS_ITEM_SCOPE']);
    const planogramToSave = _auxTransformOutputPlanogramData($planogram, calcData, featureFlagScope);
    const url = `${this.configBase}${this.config.resources.save}`;

    if (true && _isDev()) _log('\n[savePlanogram]', { $planogram, planogramToSave, calcData }, '\n');

    this.resetGetPlanogramsList();
    this.reset_cache_checkInternalCodeAlreadyExist();

    //CAT-588: Fuerza a que categoriesSelected no vayan nunca en null id:z_cPCzaDNc
    if (true) {
      if (!(planogramToSave.categoriesSelected?.length > 0)) planogramToSave.categoriesSelected = [];
    }

    return this.http.post<any>(url, { ...planogramToSave }).pipe(
      tap(data => _logTap(`${PlanogramService.name}::save Planogram (tap) \t data: %o`, data)),
      map(data => _auxTransformInputPlanogramData(data, featureFlagScope)),
      catchError(error => {
        if (IS_DEMO) {
          return _getPlanogramDummy(featureFlagScope); /* DUMMY */
        } else {
          return _throwError(error, PlanogramService);
        }
      })
    );
  }

  getPlanogramTimeLine(id: number, size = 1): Observable<any> {
    const qs: Array<string> = ['?'];
    if (size) qs.push(`size=${size}`);
    const featureFlagScope = !!this.configFlags.hasFeatureFlag(FeatureFlagsKeysEnum['DES-2998__PLANOGRAMAS_ITEM_SCOPE']);
    const url = `${this.configBase}${this.config.resources.getTimeLine}/${id}${qs.join('&')}`;

    //DUMMY
    if ((_isDev() || IS_DEMO) && id === -1) return _getPlanogramTimeLineDummy(id, featureFlagScope);

    return this.http.get<any>(url).pipe(
      tap(data => _logTap(`${PlanogramService.name}::get PlanogramTimeLine (tap)\tdata: %o`, data)),
      map(data => data.map(x => _auxTransformInputPlanogramData(x, featureFlagScope))),
      map(data => _auxTransValidTimeLine(data).reverse()),
      catchError((error: any) => {
        return _throwError(error, PlanogramService);
      })
    );
  }

  getPlanogramAudit(id: number): Observable<any> {
    const url = `${this.configBase}${this.config.resources.getAudit}/${id}`;

    if ((_isDev() || IS_DEMO) && id === -1) return _getPlanogramAudit(id);

    return this.http.get<any>(url).pipe(
      tap(data => _logTap(`${PlanogramService.name}::get getPlanogramAudit (tap)\tdata: %o`, data)),
      map(dataArr => {
        if (!dataArr || !dataArr.length) return dataArr;
        return dataArr.map(data => {
          if (!data) return data;
          if (data.imageUrl && data.imageUrl.length) data.img = data.imageUrl;
          if (data.uploadedOn) data.uploadDate = data.uploadedOn;
          return data;
        });
      }),
      catchError(error => {
        if (IS_DEMO) {
          //DUMMY
          return _getPlanogramAudit(id);
        } else {
          return _throwError(error, PlanogramService);
        }
      })
    );
  }

  uploadImgAudit(_moduleName: string, file: File, _data = null): Observable<any> {
    const url = `${this.configBase}${this.config.resources.uploadImgAudit}`;

    const formData = new FormData();
    formData.append('planogramId', _data.planogramId);
    formData.append('storeId', _data.storeId);

    if (file) formData.append('file', file, file.name);

    if (true) _log('[uploadImgAudit]', formData);

    return this.http.post<any>(url, formData).pipe(
      tap(data => _logTap(`${PlanogramService.name}::get uploadImgAudit (tap)\tdata: %o`, data)),
      catchError(error => {
        // return _throwError(error, PlanogramService);
        return _handleErrorWithDummyData(true && IS_DEMO, error, PlanogramService, true, 'true');
      })
    );
  }

  getPlanogramComments(id: number): Observable<any> {
    const url = `${this.configBase}${this.config.resources.getPlanogrmaComments}/${id}`;

    if ((_isDev() || IS_DEMO) && id === -1) return _getPlanogramComments(id);

    return this.http.get<any>(url).pipe(
      tap(data => _logTap(`${PlanogramService.name}::get getPlanogramComments (tap)\tdata: %o`, data)),
      catchError(error => {
        if (IS_DEMO) {
          //DUMMY
          return _getPlanogramComments(id);
        } else {
          return _throwError(error, PlanogramService);
        }
      })
    );
  }

  postPlanogramComment(planogramId: string | number, comment: string): Observable<any> {
    const url = `${this.configBase}${this.config.resources.postPlanogramComment}`;

    return this.http.post<any>(url, { planogramId, comment }).pipe(
      tap(data => _logTap(`${PlanogramService.name}::get postPlanogramComment (tap)\tdata: %o`, data)),
      catchError(error => {
        // return _throwError(error, PlanogramService);
        return _handleErrorWithDummyData(true && IS_DEMO, error, PlanogramService, true, 'true');
      })
    );
  }

  getPlanogramsList(
    searchObject: PlanogramsListFilterModel,
    addDeletingStatus = false,
    avoidCache = false
  ): Observable<PagedList<PlanogramsListItemModel>> {
    let url;

    const qs: Array<string> = ['?'];
    if (searchObject?.pageIndex) {
      qs.push(`pageIndex=${searchObject?.pageIndex}`);
    }

    if (searchObject?.pageSize) {
      qs.push(`pageSize=${searchObject?.pageSize}`);
    }

    console.log('Avoid cache:', avoidCache);

    // Agregar el parámetro para evitar el caché solo si avoidCache es true
    if (avoidCache) {
      qs.push(`_=${new Date().getTime()}`);
    }

    url = `${this.configBase}${this.config.resources.list}${qs.join('&')}`;

    const lowLevel = this.serviceCategory.getLowLevel({
      level4: searchObject.categories,
      level5: searchObject.subcategories,
    });

    const payload = {
      ...searchObject,
      status: (addDeletingStatus
        ? [...(searchObject?.status || []), searchObject?.status?.length ? PlanogramStatusType.Deleting : null]
        : [...searchObject.status]
      ).filter(e => e != null),
      categories: (lowLevel?.level4 as number[]) || [],
      subcategories: (lowLevel?.level5 as number[]) || [],
    };

    payload.planogramType = payload.planogramType || 'All';

    if (payload._isDraft === true) {
      delete payload._isDraft;
      payload.status = ['Draft'] as any;
    }

    if (payload?.actionableType) {
      payload.status = [];
    }

    let request$ = this.http.post<PagedList<PlanogramsListItemModel>>(url, payload).pipe(
      tap(data => {
        console.log('Data received:', data);
        _logTap(`${PlanogramService.name}::getList (tap) \t data: %o`, data);
      }),
      catchError(error => {
        console.error('Error:', error);
        return _handleErrorWithDummyData(
          true && IS_DEMO,
          error,
          PlanogramService,
          dummyPlanogramsList.list,
          'dummyPlanogramsList.list',
          []
        );
      })
    );

    // Solo aplica kayshOperator si no se quiere evitar el caché
    if (!avoidCache) {
      request$ = request$.pipe(kayshOperator('getPlanogramsList', payload, { localStorage: false, maxTime: _ms('1m') }));
    }

    return request$;
  }

  resetGetPlanogramsList() {
    _log('[resetGetPlanogramsList]');
    return kayshFlushOperator('getPlanogramsList');
  }

  planogramsListChangeItemStatus(id: number | string, status: number | string): Observable<any> {
    const qs: Array<string> = ['?'];
    qs.push(`planogramId=${id}`);
    qs.push(`action=${status}`);

    const url = `${this.configBase}${this.config.resources.toggleStatus}${qs.join('&')}`;

    return this.http.post<any>(url, {}).pipe(
      tap(data => _logTap(`${PlanogramService.name}::toggleStatus Planogram (tap) \t data: %o`, url, data)),
      catchError(error => _handleErrorWithDummyData(true && IS_DEMO, error, PlanogramService, true, 'true'))
    );
  }

  planogramsListClone(id: number | string, onlyModules: boolean, code: string): Observable<any> {
    const qs: Array<string> = ['?'];
    qs.push(`planogramId=${id}`);
    qs.push(`onlyModules=${onlyModules}`);

    const url = `${this.configBase}${this.config.resources.clonePlanogram}${qs.join('&')}`;

    return this.http.post<any>(url, null).pipe(
      tap(data => _logTap(`${PlanogramService.name}::toggleStatus Planogram (tap) \t data: %o`, url, data)),
      catchError(error => _handleErrorWithDummyData(true && IS_DEMO, error, PlanogramService, true, 'true'))
    );
  }

  planogramsListCloneWithCode(data): Observable<any> {
    const url = `${this.configBase}${this.config.resources.clonePlanogramWithCode}`;

    return this.http.post<any>(url, data).pipe(
      tap(data => _logTap(`${PlanogramService.name}::toggleStatus Planogram with code (tap) \t data: %o`, url, data)),
      catchError(error => _handleErrorWithDummyData(true && IS_DEMO, error, PlanogramService, true, 'true'))
    );
  }

  delete(data): Observable<any> {
    const { filter, ...rest } = _cloneDeep(data);

    //CAT-6 //CAT-123
    if (filter?._isDraft === true) {
      delete filter._isDraft;
      filter.status = ['Draft'] as any;
    }

    const url = this.configBase + this.config.resources.delete;

    const planogramsIds = (rest.planogramsIds || []).filter(el => el != null && String(el) == el && String(el).length > 0);

    let payload = { ...rest, filter, planogramsIds };

    if (false) {
      return of({
        deleted: false,
        itemNotFound: false,
        usedInLayaouts: 7,
        urlReport: 'asd',
      });
    }

    return this.http.post<any>(url, payload).pipe(
      tap(data => _logTap(`${PlanogramService.name}::delete (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, PlanogramService))
    );
  }

  deleteFromLayouts(data): Observable<any> {
    const { filter, ...rest } = _cloneDeep(data);

    const url = this.configBase + this.config.resources.deleteFromLayouts;

    const planogramsIds = (rest.planogramsIds || []).filter(el => el != null && String(el) == el && String(el).length > 0);

    let payLoad = { ...rest, filter, planogramsIds };

    if (false) {
      return of({
        deleted: false,
        itemNotFound: true,
        usedInLayaouts: 7,
      });
    }

    return this.http.post<any>(url, payLoad).pipe(
      tap(data => _logTap(`${PlanogramService.name}::delete (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, PlanogramService))
    );
  }

  planogramsListDeleted(id: number): Observable<any> {
    const qs: Array<string> = ['?'];
    qs.push(`planogramId=${id}`);

    const url = `${this.configBase}${this.config.resources.deletePlanogram}${qs.join('&')}`;

    return this.http.post<any>(url, null).pipe(
      tap(data => _logTap(`${PlanogramService.name}::planogramsListDeleted (tap) \t data: %o`, url, data)),
      catchError(error => _handleErrorWithDummyData(true && IS_DEMO, error, PlanogramService, true, 'true'))
    );
  }

  getListForExport(): Observable<string> {
    const url = `${this.configBase}${this.config.resources.listForExport}`;

    return this.http.get<{ fileUrl: string }>(url).pipe(
      map(data => data.fileUrl),
      tap(data => _logTap(`${PlanogramService.name}::getListForExport (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, PlanogramService))
    );
  }

  planogramForExport(id): Observable<string> {
    const url = `${this.configBase}${this.config.resources.planogramForExport}/${id}`;

    return this.http.get<{ fileUrl: string }>(url).pipe(
      map(data => data.fileUrl),
      tap(data => _logTap(`${PlanogramService.name}::planogramForExport (tap)\n\tdata: %o`, data)),
      catchError(error => _throwError(error, PlanogramService))
    );
  }

  getListPopMaterialEspecial(pageIndex = 0, pageSize = 10000): Observable<any> {
    const url = `${this.configBase}spaces/popmaterial/list?pageIndex=${pageIndex}&pageSize=${pageSize}`;

    return this.http.get<{ fileUrl: string }>(url).pipe(
      map((data: any) => {
        let rv = data;

        if (data?.items?.length > 0) {
          rv.items = data.items.map(el => {
            let rvEl = { ...el, type: 'float-pop-generic-image' };
            return rvEl;
          });
        }

        return rv;
      }),
      tap(data => _logTap(`${PlanogramService.name}::getListPopMaterialEspecial (tap)\n\tdata: %o`, data)),
      kayshOperator('getListPopMaterialEspecial', url),
      catchError(error => _throwError(error, PlanogramService))
    );
  }

  saveTotalSlots(floats) {
    const _url = `${this.configBase}spaces/planograms/save-slot`;

    return this.http.post(_url, floats).pipe(tap(data => _logTap(`${PlanogramService.name}::saveTotalSlots (tap)\n\tdata: %o`, data)));
  }
}
